import React, { useState } from 'react'
import AuthWrapper1 from './AuthWrapper1'
import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar';
import { useDispatch } from 'react-redux';
import { login } from '../../../redux/features/auth/authSlice';
import { setUser, updateUserRole } from '../../../redux/features/user/userSlice';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function RolePermission() {
    const userId = localStorage.getItem('userId');
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch();
  const { permission } = location.state || {};
  const [permissions, setPermissions] = useState(permission)



  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  
      const showSnackbar = (message, severity) => {
          setSnackbarMessage(message);
          setSnackbarSeverity(severity);
          setSnackbarOpen(true);
      };

  const handleContinueClick = async (role) => {
       
      

        try {
            // Make the API request to authenticate the user
            const response = await fetch(`${API_ENDPOINT}route/updaterole/${userId}`, {
              method: 'PUT',
              body: JSON.stringify({ user_role: role }),
              headers: {
                'Content-Type': 'application/json',
              },
            })
      
            if (response.ok) {
                localStorage.setItem('userRole', role)
                dispatch(
                  updateUserRole(role)
                );
                  navigate('/dashboard')
            } else {
               if(response.status === 304) {
                localStorage.setItem('userRole', role)
                navigate('/dashboard')
            } else if(response.status === 404) {
              showSnackbar('Unauthorized SignIn', 'error');
              navigate('/page404')
            } else {
             // setErrorStatus(true)
              showSnackbar('Server error', 'error');
              //setErrorMessage('Network Error')
              navigate('/page404')
            }
          } 
        }catch (error) {
          console.error('An unexpected error occurred:', error);
          showSnackbar('An unexpected error occurred. Please try again later.', 'error');
          navigate('/page404')
        }
       
  }
  return (
    <>
    <Grid container spacing={2}>
      {permissions && permissions.length > 0 ? (
        permissions.map((permission, index) => (
          <Grid item xs={6} md={6} lg={6} key={index}>
            <Card
              variant="outlined"
              sx={{
                borderColor: 'black',
                borderWidth: '2px',
                borderRadius: '5px',
              }}
            >
              <CardContent>
                <Typography variant='h4'>{permission}</Typography>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => handleContinueClick(permission)}
                >
                  Continue
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))
      ) : (
        <Typography variant='h6' sx={{ color: '#000000', fontFamily: '"Poppins-Regular", Helvetica', fontSize: '18px', marginBottom: 2 }}>
          No permissions available
        </Typography>
      )}
    </Grid>

<CustomSnackbar
open={snackbarOpen}
onClose={() => setSnackbarOpen(false)}
message={snackbarMessage}
severity={snackbarSeverity}
/>
</>
  )
}

export default RolePermission