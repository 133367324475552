import {
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import StyledTableContainer from "../../../newTestPrep/newComponent/TableContainer";

export default function EnrollTargetBreakDown({data, chosenDate}) {


  const date = new Date(chosenDate);

const monthName = date.toLocaleString("en-US", { month: "long" });
const year = date.getFullYear();

  

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" textAlign="center">{monthName} {year}</Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledTableContainer component={Paper}>
            <Table style={{ background: "#F9F9F9" }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Total Enrolment Target</TableCell>
                  <TableCell>Achieved Enrolment Target</TableCell>
                  <TableCell>Achieved Enrolment Target(%)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length === 0 ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "11px",
                        color: "#000000",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                ) : (
                  data?.map((row, index) => {
                    const progress =
                      (row.achieved_enrollment / row.target_enrollment) * 100;
                    return (
                      <TableRow
                        key={index}
                        style={{
                          width: "6%",
                        }}
                      >
                        <TableCell>{row?.test_type_id?.name}</TableCell>
                        <TableCell>{row?.target_enrollment}</TableCell>
                        <TableCell>{row?.achieved_enrollment}</TableCell>
                       <TableCell style={{ width: "120px" }}>
                                            {row?.target_enrollment===0  ?<Chip
                            color="primary"
                            size="small"
                            variant="contained"
                            label="No Enrolment Target Available"
                          /> : (
                                            <Box display="flex" alignItems="center" justifyContent='center' gap={1} width='100%'>
                                              <Tooltip title={row?.achieved_enrollment} arrow>
                                                <LinearProgress
                                                  variant="determinate"
                                                  value={progress}
                                                  sx={{
                                                    width: "120px",
                                                    height: 5,
                                                    borderRadius: 5,
                                                    cursor: "pointer",
                                                    justifyContent:'center', 
                                                    alignItems:'center'
                                                  }}
                                                />
                                              </Tooltip>
                                              <Typography variant="h7" sx={{ minWidth: 40 }}>
                                             
                                                {Math.round(progress)}%
                                              </Typography>
                                            </Box>
                                             )}
                                          </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
      </Grid>
    </>
  );
}
