import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainCard from "../../../components/Maincard";
import CollapsibleForm from "../accounts/Paymentfilter";
import AccountsCard from "../../components/ui-component/card/account/AccountsCard";
import { fDate } from "../../../utils/formatTime";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import CashCollectionFilter from "./CashCollectionFilter";
import { useGetTokenOrLogout } from "../../../utils/token";
import StyledTableContainer from "../../newComponent/TableContainer";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CustomNoRowsOverlay from "../../newComponent/TableCustomeComp/CustomNoRowsOverlay";
import { DataGrid } from "@mui/x-data-grid";
import UoloadIconcomp from "../../newComponent/iconsComp/UoloadIconcomp";
import UploadIconcomp from "../../newComponent/iconsComp/UoloadIconcomp";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";
import FilterButtonComp from "../../../pages/AllUserDashboards/Components/FilterButtonComp";
import FilterButtonComponent from "../../newComponent/NewButtonComp/FilterButtonComponent";
import SearchTextFieldComp from "../../newComponent/newtextfieldComp/SearchTextFieldComp";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PATH_URL = process.env.REACT_APP_API_UPLOADS;
function CashCollections() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();

  const userId = localStorage.getItem("userId");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const [originalData, setOriginalData] = useState([]);

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleRowSelect = (row, isSelected) => {
    if (isSelected) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow !== row)
      );
    }
  };

  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    // Implement your upload logic here using selectedFile
    // For example, you can send the file to your server using an API call
    console.log(selectedFile);
    // Close the dialog after uploading
    setOpenUploadDialog(false);
  };

  const handleAddUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("user_id", userId);

      // Check if selectedRows is not empty before appending it
      if (selectedRows.length > 0) {
        selectedRows.forEach((row) => {
          formData.append("provesional_id[]", row); // Append each selected row to the array
        });
      } else {
        // Handle case where selectedRows is empty (optional)
        console.log("No rows selected for upload.");
        showSnackbar("No rows selected for upload", "error");
        return;
      }

      try {
        const response = await fetch(
          `${API_ENDPOINT}uploaddepositslip/updatedepositslip`,
          {
            method: "POST",
            body: formData,
          }
        );

        const result = await response.json();

        if (response.status === 201) {
          console.log("Upload successful:");
          showSnackbar("Upload successful.", "success");

          setOpenUploadDialog(false);

          // Filter out uploaded rows from the payments array
          const updatedPayments = payments.filter(
            (payment) => !selectedRows.includes(payment.id)
          );
          setPayments(updatedPayments);

          // Clear selectedRows state after successful upload
          setSelectedRows([]);
        } else {
          console.error("Upload failed. Server returned:", response.statusText);
          showSnackbar(
            `Upload stopped. Server returned:,${response.statusText}`,
            "error"
          );
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        console.log("Server response:");
        showSnackbar("Error uploading file", "error");
      }
    }
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      const token = getTokenOrLogout();
      if (!token) return;
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      try {
        let url = "";
        // if (!filterCriteria) {
        //   url = `${API_ENDPOINT}route2.0/cashpayments/${userId}`;
        // } else {
        //   url = `${API_ENDPOINT}route2.0/fetchcashpayment/${userId}`;
        // }

        url = `${API_ENDPOINT}route2.0/cashpayments/${userId}`;
        const response = await fetch(url, { headers });
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }

        const data = await response.json();
        if (data.length === 0) {
          showSnackbar(
            "Could'nt find any Cash Payments for past 30 days",
            "error"
          );
          setIsLoading(false);
        } else {
          const sortedData = data.sort(
            (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
          );

          setPayments(sortedData);

          setOriginalData(sortedData);
          setSearchData(sortedData);
          setIsLoading(false);
        }

        const sortedData = data.sort(
          (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
        );

        setPayments(sortedData);
        setIsLoading(false);
        setOriginalData(sortedData);
      } catch (error) {
        if (error.message.includes("401")) {
          navigate("/page401");
        } else if (error.message.includes("403")) {
          navigate("/page403");
        } else if (error.message.includes("404")) {
          navigate("/page404");
        } else if (error.message.includes("440")) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  // View deposite Slip
  const handleViewDepositSlip = (filePath) => {
    window.open(`${PATH_URL}depositSlip/${filePath}`, "_blank");
  };

  const ActionButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: "#E5E4E2",
    width: "20px",
    height: "20px",
  }));
  const columns = [
    {
      field: "select",
      headerName: "Select",
      flex: 0.2,
      sortable: false, // Enable sorting
      disableColumnMenu: true,

      renderCell: (params) => (
        <div>
          <Checkbox
            checked={selectedRows.includes(params?.row.id)}
            disabled={params?.row?.collected_status !== "received"}
            onChange={(event) =>
              handleRowSelect(params?.row.id, event.target.checked)
            }
          />
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.enquiry_id?.first_name || ""} ${
          params?.row?.enquiry_id?.last_name || ""
        }`,
      renderCell: (params) => (
        <div
          style={{
            color: "#0096FF",

            cursor: "pointer",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          onClick={() =>
            window.open(
              `/enquirydetails/${params?.row.enquiry_id.id}`,
              "_blank"
            )
          }
        >
          {" "}
          {`${
            params?.row?.enquiry_id?.first_name
              ? params?.row?.enquiry_id?.first_name
              : ""
          } ${
            params?.row?.enquiry_id?.last_name
              ? params?.row?.enquiry_id?.last_name
              : ""
          }`}{" "}
        </div>
      ),
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row?.enquiry_id?.mobile || "",
      renderCell: (params) => <div>{params?.row.enquiry_id?.mobile}</div>,
    },
    {
      field: "branch",
      headerName: "Branch",
      flex: 1.2,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        params?.row?.enquiry_id?.branch_id?.branch_name || "",
      renderCell: (params) => (
        <div>{params?.row?.enquiry_id?.branch_id?.branch_name}</div>
      ),
    },
    {
      field: "payment_date",
      headerName: "Date",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params?.row?.payment_date)}</div>,
    },
    {
      field: "amount_paid",
      headerName: "Amount Collected",
      flex: 0.7,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.amount_paid}</div>,
    },

    {
      field: "collected_by",
      headerName: "Collected By",
      flex: 1,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.created_by?.first_name || ""} ${
          params?.row?.created_by?.last_name || ""
        }`,
      renderCell: (params) => (
        <div>
          {params?.row?.created_by?.first_name}{" "}
          {params?.row?.created_by?.last_name}
        </div>
      ),
    },

    {
      field: "collected_status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {" "}
          {params?.row?.collected_status === "received" && (
            <Chip
              size="normal"
              variant="contained"
              label="Received"
              color="error"
            />
          )}
          {params?.row?.collected_status === "deposited" && (
            <Chip
              size="normal"
              variant="contained"
              label="Deposited"
              style={{ background: "orange" }}
            />
          )}
          {params?.row?.collected_status === "confirmed" && (
            <Chip
              size="normal"
              variant="contained"
              label="Confirmed"
              color="primary"
            />
          )}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row?.collected_status === "received" && (
            <UploadIconcomp
              tooltipTitle="Upload Deposit Slip"
              onClick={() => {
                setSelectedRows((prevSelectedRows) => {
                  // Check if the ID is already in the selectedRows array
                  if (!prevSelectedRows.includes(params?.row.id)) {
                    // If not, add the ID to the array
                    return [...prevSelectedRows, params?.row.id];
                  }
                  // If the ID is already selected, return the current array without changes
                  return prevSelectedRows;
                });
                setOpenUploadDialog(true);
              }}
            />
          )}

          {params?.row?.collected_status === "deposited" && (
            <ActionButton
              onClick={() =>
                handleViewDepositSlip(
                  params?.row?.deposit_slip_id?.file_uploaded
                )
              }
            >
              <Tooltip title="View Deposit Slip">
                <VisibilityOutlinedIcon
                  fontSize="small"
                  style={{
                    color: "#00b13f",
                    fontSize: "0.8rem",
                  }}
                />
              </Tooltip>
            </ActionButton>
          )}
        </div>
      ),
    },
  ];

  // Search All column data
  const handleSearchByName = (e) => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, ""); // Normalize search value (lowercase and remove spaces)

    let filteredData1 = searchData.filter((data) => {
      // Check each field for a match with the search value
      const fieldsToSearch = [
        `${data?.enquiry_id?.first_name || ""} ${
          data?.enquiry_id?.last_name || ""
        }`,

        `${data?.enquiry_id?.mobile || ""}`,
        `${data?.enquiry_id?.branch_id?.branch_name || ""}`,
        `${data?.payment_date || ""}`,
        `${data?.amount_paid || ""}`,
        `${data?.created_by?.first_name || ""} ${
          data?.created_by?.first_name || ""
        }`,

        `${data?.collected_status || ""}`,
      ];

      // Check if any of the fields contain the search value
      return fieldsToSearch.some((field) =>
        field.toLowerCase().replace(/\s+/g, "").includes(value)
      );
    });

    setPayments(filteredData1); // Update the filtered data
  };
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={6} lg={2} sm={4} md={3}>
          <HeadingCard label="Cash Collections" />
        </Grid>

        <Grid
          item
          xs={6}
          lg={10}
          sm={8}
          md={9}
          container
          gap={1}
          justifyContent="end"
        >
          <Button
            variant="contained"
            size="small"
            sx={{ borderRadius: "8px", padding: "10px" }}
            onClick={() => {
              if (selectedRows.length === 0) {
                // Show popup message for empty selectedRows
                showSnackbar(
                  "Please select at least one cash payment.",
                  "error"
                );
              } else {
                setOpenUploadDialog(true);
              }
            }}
          >
            Mass Upload
          </Button>
          <FilterButtonComponent
            title="Filter"
            onClick={handleToggle}
            open={open}
            showendIcon={true}
          />
        </Grid>

        <Grid item xs={12} lg={12} sm={12} md={12}>
          <CashCollectionFilter
            open={open}
            setOpen={setOpen}
            setPayments={setPayments}
            originalData={originalData}
            setSearchData={setSearchData}
            isLoading={isLoading}
            showSnackbar={showSnackbar}
            setIsLoading={setIsLoading}
          />
        </Grid>

        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="end">
                <SearchTextFieldComp onChange={handleSearchByName} />
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={payments}
                  columns={columns}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[25, 50, 100]}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <Dialog
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
      >
        <DialogTitle variant="h4">Upload Deposit Slip</DialogTitle>
        <DialogContent>
          <TextField type="file" onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUploadDialog(false)}>Cancel</Button>
          <Button onClick={handleAddUpload} variant="contained">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CashCollections;
