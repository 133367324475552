// Author : Ayesha Shaikh
// Date : 30 july 2024

import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MainCard from "../../../components/Maincard";

import { DataGrid } from "@mui/x-data-grid";
import WalkInReportFilter from "./WalkInReportFilter";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import CustomPopup from "../../../components/CustomPopup";
import NoenquiryForm from "../../components/DataTransferComp.jsx/NoenquiryForm";

import { fDate } from "../../../utils/formatTime";
import { CSVExport } from "../../components/ui-component/table/CSVExport";
import NoeMailForm from "../../components/DataTransferComp.jsx/NoMailForm";

import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
import MassTaskAssign from "../../components/DataTransferComp.jsx/MassTaskAssign";
import BulkEmailPopup from "../../components/BulkEmail/BulkEmailPopup";
import BulkMailform from "../../components/BulkEmail/BulkMailform";
import FilterButtonComponent from "../../newComponent/NewButtonComp/FilterButtonComponent";
import MassTaskAssignButton from "../../newComponent/NewButtonComp/MassTaskAssignButton";
import SendEmailButton from "../../newComponent/NewButtonComp/SendEmailButton";
import CustomNoRowsOverlay from "../../newComponent/TableCustomeComp/CustomNoRowsOverlay";
import SearchTextFieldComp from "../../newComponent/newtextfieldComp/SearchTextFieldComp";
import SelectedItemButton from "../../newComponent/NewButtonComp/SelectedItemButton";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function WalkInReport() {
  const userId = localStorage.getItem("userId");
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [value, setvalue] = useState(null);
  const [walkInsData, setWalkInsData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openMask, setOpenMask] = useState(false);
  const [selectedid, setSelectedid] = useState([]);
  const [selectedEnquiryId, setSelectedEnquiryids] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const [isDownloadAccess, setIsDownloadAccess] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [OpenEmailPopup, setOpenEmailPopup] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 100,
  });

  const [visibleRowIds, setVisibleRowIds] = useState([]); // Store current page's row IDs
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [prevPageSize, setPrevPageSize] = useState(pagination.pageSize);
  const [openstages, setOpenStages] = useState(false);
  const [openProspectivelevel, setOpenProspectiveLeve] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleOpenmask = () => {
    setOpenMask(true);
  };

  const handleClosemask = () => {
    setOpenMask(false);
  };

  const handleOpenEmail = () => {
    setOpenEmailPopup(true);
  };

  const handleCloseEmail = () => {
    setOpenEmailPopup(false);
  };

  useEffect(() => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}reports/getwalkinreport/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          if (data.length === 0) {
            showSnackbar("No data Available", "error");
            setLoading(false);
          } else {
            const sortedData = data.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            setWalkInsData(sortedData);
            setOriginalData(sortedData);
            setFilteredData(sortedData);
            setLoading(false);
          }
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        navigate("/page500");
      }
    };

    fetchData();
  }, []);

  // Upadate current page ids when pagination is chnages
  useEffect(() => {
    console.log("pagination", pagination, selectedid);
    setPrevPageSize(pagination.pageSize);

    // new pagesize is less than prev page size then deselct all selected ids first
    if (pagination.pageSize !== prevPageSize) {
      setSelectedid([]);
      return;
    }

    // Update visible row IDs when pagination changes
    const currentPageRows = originalData.slice(
      pagination.page * pagination.pageSize,
      (pagination.page + 1) * pagination.pageSize
    );
    const visibleIds = currentPageRows.map((row) => row.id);
    setVisibleRowIds(visibleIds);

    // If there are no visible rows, set SelectAllChecked to false
    if (visibleIds.length === 0) {
      setIsSelectAllChecked(false);
      setIsIndeterminate(false);
      return;
    }

    // Check if all visible rows are selected
    const allVisibleSelected = visibleIds.every((id) =>
      selectedid.includes(id)
    );
    const someVisibleSelected = visibleIds.some((id) =>
      selectedid.includes(id)
    );

    setIsSelectAllChecked(allVisibleSelected);
    setIsIndeterminate(someVisibleSelected && !allVisibleSelected);
  }, [pagination, originalData, selectedid]);

  // HandleSelectAllCheckBox
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setIsSelectAllChecked(checked);

    if (checked) {
      // Select all visible row IDs
      setSelectedid((prevSelectedIds) => [
        ...new Set([...prevSelectedIds, ...visibleRowIds]),
      ]);
    } else {
      // Deselect all visible row IDs
      setSelectedid((prevSelectedIds) =>
        prevSelectedIds.filter((id) => !visibleRowIds.includes(id))
      );
    }
  };

  // Individual CheckBox
  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;

    setSelectedid((prevSelectedIds) => {
      if (checked) {
        const newSelected = [...prevSelectedIds, id];
        // Check if all visible rows are selected after this checkbox is checked
        const allVisibleSelected = visibleRowIds.every((rowId) =>
          newSelected.includes(rowId)
        );
        setIsSelectAllChecked(allVisibleSelected);
        setIsIndeterminate(!allVisibleSelected);

        return newSelected;
      } else {
        // Deselect the row ID
        const newSelected = prevSelectedIds.filter((rowId) => rowId !== id);
        setIsSelectAllChecked(false);
        setIsIndeterminate(
          visibleRowIds.some((rowId) => newSelected.includes(rowId))
        );

        return newSelected;
      }
    });
  };

  // Select EnquiryIds
  useEffect(() => {
    if (selectedid.length > 0) {
      console.log("taskIds", selectedid);
      const enquiryIds = originalData.filter((id) =>
        selectedid.includes(id?.id)
      );
      const selectedEnquiryids = enquiryIds.map((i) => i.enquiry_id?.id);
      setSelectedEnquiryids(selectedEnquiryids);
    }
  }, [selectedid]);

  const columns = [
    {
      field: "selection",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <Checkbox
          size="small"
          checked={isSelectAllChecked}
          indeterminate={isIndeterminate}
          onChange={handleSelectAllChange}
        />
      ),
      headerClassName: "header-cell",
      width: 70,
      renderCell: (params) => {
        const isChecked = selectedid.includes(params.row?.id);
        return (
          <Checkbox
            size="small"
            checked={isChecked}
            onChange={(event) => handleCheckboxChange(event, params.row?.id)}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params.row?.enquiry_id?.first_name || ""} ${
          params.row?.enquiry_id?.last_name || ""
        }`,
      renderCell: (params) => (
        <Box
          style={{ color: "#2196F3", cursor: "pointer" }}
          onClick={() =>
            window.open(
              `/enquirydetails/${params?.row?.enquiry_id?.id}`,
              "_blank"
            )
          }
        >
          {params.row?.enquiry_id?.first_name}{" "}
          {params.row?.enquiry_id?.last_name}
        </Box>
      ),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "phone",
      headerName: "Mobile",
      width: 100,
      headerClassName: "header-cell",
      valueGetter: (params) => `${params.row?.enquiry_id?.mobile || ""}`,
      renderCell: (params) => <Box> {params.row?.enquiry_id?.mobile} </Box>,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "walkin_type",
      headerName: "Walkin Type",
      width: 100,
      headerClassName: "header-cell",
      valueGetter: (params) => `${params.row?.type_of_walking || ""}`,
      renderCell: (params) => (
        <Typography> {params.row?.type_of_walking} </Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "walk_in_status",
      headerName: "Walkin Staus",
      width: 100,
      headerClassName: "header-cell",
      valueGetter: (params) => params.row?.walk_in_status || "",
      renderCell: (params) => <Box>{params.row?.walk_in_status}</Box>,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "walk_in_on",
      headerName: "Walkin Date",
      width: 100,
      headerClassName: "header-cell",
      valueGetter: (params) => params.row?.appointment_date || "",
      renderCell: (params) => <Box> {params.row?.appointment_date}</Box>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) => params.row?.branch_visited_id?.branch_name || "",
      renderCell: (params) => (
        <Box>{params.row?.branch_visited_id?.branch_name}</Box>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sub_service",
      headerName: "Sub Service",
      width: 250,
      headerClassName: "header-cell",
      valueGetter: (params) => params.row?.sub_service_id?.name || "",
      renderCell: (params) => <Box>{params.row?.sub_service_id?.name}</Box>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "prospective_level",
      headerName: "Prospective Level",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        params.row?.enquiry_id?.prospective_level_id?.prospective_level || "",
      renderCell: (params) => (
        <Box>
          {params.row?.enquiry_id?.prospective_level_id?.prospective_level}
        </Box>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        params.row?.enquiry_id?.stage_id?.stage_name || "",
      renderCell: (params) => (
        <Box>{params.row?.enquiry_id?.stage_id?.stage_name}</Box>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lead_source",
      headerName: "Lead Source",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        params.row?.enquiry_id?.lead_source_type_id?.name || "",
      renderCell: (params) => (
        <Box>{params.row?.enquiry_id?.lead_source_type_id?.name}</Box>
      ),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "reason",
      headerName: "Reason For Walkin",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) => params.row?.event_reason_id?.reason_name || "",
      renderCell: (params) => (
        <Box>{params.row?.event_reason_id?.reason_name}</Box>
      ),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "register_by",
      headerName: "Walk_in Register By",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params.row?.created_by?.first_name || ""} ${
          params.row?.created_by?.last_name || ""
        }`,
      renderCell: (params) => (
        <Box>
          {params.row?.created_by?.first_name}{" "}
          {params.row?.created_by?.last_name}
        </Box>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "reference_by",
      headerName: "Walk_in Reference By",
      width: 200,
      headerClassName: "header-cell",

      valueGetter: (params) => params.row?.reference_walkin_id?.name || "",
      renderCell: (params) => (
        <Box>{params.row?.reference_walkin_id?.name} </Box>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "assin_to",
      headerName: "Assign to",
      width: 200,
      headerClassName: "header-cell",
     
      valueGetter: (params) =>
        `${params.row?.assign_to?.first_name || ""} ${
          params.row?.assign_to?.last_name || ""
        }`,
      renderCell: (params) => (
        <Box>
          {params.row?.assign_to?.first_name} {params.row?.assign_to?.last_name}
        </Box>
      ),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "walk_in_event",
      headerName: "Walkin Event",
      width: 450,
      headerClassName: "header-cell",
      headerClassName: "header-cell",
      valueGetter: (params) =>
        params.row?.event_id?.campaign_name || "",
        
      renderCell: (params) => <Box>{params.row?.event_id?.campaign_name}</Box>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "last_folloup",
      headerName: "Last Follwup",
      width: 100,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        params.row?.enquiry_id?.last_follow_up_date || "",
      renderCell: (params) => (
        <Box>{params.row?.enquiry_id?.last_follow_up_date}</Box>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Next Due Date",
      width: 100,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        params.row?.enquiry_id?.next_follow_up_date || "",
      renderCell: (params) => (
        <Box>{params.row?.enquiry_id?.next_follow_up_date}</Box>
      ),
      headerAlign: "center",
      align: "center",
    },
  ];

//  Search For all columns

    const handleSearch = (e) => {
      const value = e.target.value.toLowerCase().replace(/\s+/g, ""); // Normalize search value (lowercase and remove spaces)
      
      let filteredData1 = walkInsData.filter((data) => {
        // Check each field for a match with the search value
        const fieldsToSearch = [
          `${data?.enquiry_id?.first_name || ""} ${data?.enquiry_id?.last_name || ""}`,
          `${data?.enquiry_id?.mobile || ""}`,
          `${data?.type_of_walking|| ""}`,
          `${data?.walk_in_status || ""}`,
          `${data?.appointment_date || ""}`,
          `${data?.branch_visited_id?.branch_name || ""}`,
          `${data?.sub_service_id?.name || ""}`,
          `${data?.enquiry_id?.prospective_level_id?.prospective_level || ""}`,
          `${data?.enquiry_id?.stage_id?.stage_name|| ""}`,
          `${data?.enquiry_id?.lead_source_type_id?.name|| ""} `,
          `${data?.event_reason_id?.reason_name || ""}`,
          `${data?.created_by?.first_name || ""} ${data?.created_by?.last_name || ""}`,
          `${data?.reference_walkin_id?.name|| ""}`,
          `${data?.assign_to?.first_name || ""} ${data?.assign_to?.last_name || ""}`,
          `${data?.event_id?.campaign_name|| ""}`,
          `${data?.enquiry_id?.last_follow_up_date || ""}`,
          `${data?.enquiry_id?.next_follow_up_date || ""}`,
        ];
    
        // Check if any of the fields contain the search value
        return fieldsToSearch.some(field => field.toLowerCase().replace(/\s+/g, "").includes(value));
      });
    
      setOriginalData(filteredData1); // Update the filtered data
    };

  const TotalWalkins = walkInsData.length;
  const totalBookDemo = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "book demo"
      ? total + 1
      : total;
  }, 0);

  const totalQualifying = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "qualifying"
      ? total + 1
      : total;
  }, 0);

  const totalAssignment = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "assignment"
      ? total + 1
      : total;
  }, 0);

  const totalActivecounselling = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "active counselling"
      ? total + 1
      : total;
  }, 0);

  const totalDemocompleted = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "demo completed"
      ? total + 1
      : total;
  }, 0);

  const totalEnrolled = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "enrolled"
      ? total + 1
      : total;
  }, 0);

  const totalTraining = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "training"
      ? total + 1
      : total;
  }, 0);

  const totalTrainingcompleted = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "training completed"
      ? total + 1
      : total;
  }, 0);

  const totalSuperProspective = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.prospective_level_id?.prospective_level ===
      "Super Prospective"
      ? total + 1
      : total;
  }, 0);

  const totalHot = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.prospective_level_id?.prospective_level === "Hot"
      ? total + 1
      : total;
  }, 0);

  const totalWarm = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.prospective_level_id?.prospective_level === "Warm"
      ? total + 1
      : total;
  }, 0);

  const totalContactInFuture = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.prospective_level_id?.prospective_level ===
      "Contact In Future"
      ? total + 1
      : total;
  }, 0);

  const totalRecycled = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.prospective_level_id?.prospective_level ===
      "Recycled"
      ? total + 1
      : total;
  }, 0);

  const totalNewlead = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.prospective_level_id?.prospective_level ===
      "New lead"
      ? total + 1
      : total;
  }, 0);

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
      .then((response) => {
        if (response.ok) {
          setIsDownloadAccess(true);
        } else {
          setIsDownloadAccess(false);
        }
      })
      .catch((err) => {
        setIsDownloadAccess(false);
      });
  }, [userId]);

  useEffect(() => {
    const exportData = originalData.map((row) => ({
      name: row.enquiry_id?.last_name
        ? `${row.enquiry_id?.first_name} ${row.enquiry_id.last_name}`
        : row.enquiry_id?.first_name,
      mobile: row.enquiry_id?.mobile,
      walk_in_status: row?.walk_in_status,
      walk_in_type: row?.type_of_walking,
      branch_visited: row.branch_visited_id?.branch_name || "",
      registered_by: row?.created_by?.first_name,
      walkin_date: fDate(row?.appointment_date),
      time: row?.appointment_time,
      reason_of_visit: row?.event_reason_id?.reason_name,
      event_name: row?.event_id?.campaign_name || "",
      prospective_level:
        row.enquiry_id?.prospective_level_id?.prospective_level || "",
      sub_service: row.sub_service_id?.name || "",
      assign: row.assign_to?.first_name || "",
      lead_source_type: row.enquiry_id?.lead_source_type_id?.name || "",
      reference_id: row?.reference_walkin_id?.first_name || "",
      stage: row.enquiry_id?.stage_id?.stage_name || "",
    }));
    setExportData(exportData);
  }, [originalData]);

  // Removed Data from state after transfer
  const handleFilterdata = () => {
    const filteredData = originalData.filter(
      (i) => !selectedEnquiryId.includes(i.enquiry_id?.id)
    );
    setOriginalData(filteredData);
    setTimeout(() => {
      setSelectedid([]);
      setSelectedEnquiryids([]);
    }, 1500);
  };

  const handletoggleStages = () => {
    setOpenStages(!openstages);
  };

  const handleTogglProspective = () => {
    setOpenProspectiveLeve(!openProspectivelevel);
  };
  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2} sm={4} md={3}>
          <HeadingCard>
            <Typography variant="h3" textAlign="center">
              {" "}
              Walk-in Report
            </Typography>
          </HeadingCard>
        </Grid>
        <Grid item xs={12} lg={10} sm={8} md={9} textAlign="end">
          <Grid container gap={2} justifyContent="end">
            <MassTaskAssignButton onClick={handleOpenmask} />

            <SendEmailButton onClick={handleOpenEmail} />

            <FilterButtonComponent
              text="Filter"
              onClick={handleToggle}
              open={open}
              showendIcon={true}
            />
            {isDownloadAccess && (
              <CSVExport data={exportData} filename="walkin-report.csv" />
            )}
          </Grid>
        </Grid>
        {open && (
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <WalkInReportFilter
              originalData={originalData}
              setWalkInsData={setWalkInsData}
              setLoading={setLoading}
              setOriginalData={setOriginalData}
              filteredData={filteredData}
            />
          </Grid>
        )}

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <Typography variant="h4">
                  Total Walk-ins :{" "}
                  <span style={{ fontWeight: 400 }}>{TotalWalkins}</span>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box
                  display="fles"
                  alignItems="center"
                  cursor="pointer"
                  style={{ cursor: "pointer" }}
                  onClick={handletoggleStages}
                >
                  <Typography variant="h4">Stages</Typography>
                  {!openstages ? (
                    <KeyboardArrowDownIcon fontSize="small" />
                  ) : (
                    <KeyboardArrowUpIcon fontSize="small" />
                  )}
                </Box>

                {openstages && (
                  <Grid item xs={12} mt={1}>
                    <Box
                      sx={{
                        border: "1px solid #888888",
                        padding: "10px",
                        borderRadius: "10px",
                        display: "flex",
                        gap: 2,
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography variant="h6">
                        Qualifying : {totalQualifying}
                      </Typography>
                      <Box
                        sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                      />
                      <Typography variant="h6">
                        Book Demo : {totalBookDemo}
                      </Typography>
                      <Box
                        sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                      />
                      <Typography variant="h6">
                        Assignment : {totalAssignment}
                      </Typography>
                      <Box
                        sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                      />
                      <Typography variant="h6">
                        Active Counselling : {totalActivecounselling}
                      </Typography>
                      <Box
                        sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                      />
                      <Typography variant="h6">
                        Demo Completed : {totalDemocompleted}
                      </Typography>
                      <Box
                        sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                      />
                      <Typography variant="h6">
                        Enrolled : {totalEnrolled}
                      </Typography>
                      <Box
                        sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                      />
                      <Typography variant="h6">
                        Training : {totalTraining}
                      </Typography>
                      <Box
                        sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                      />
                      <Typography variant="h6">
                        Training Completed : {totalTrainingcompleted}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                <Box
                  display="fles"
                  alignItems="center"
                  cursor="pointer"
                  style={{ cursor: "pointer" }}
                  onClick={handleTogglProspective}
                >
                  <Typography variant="h4">Prospective Level</Typography>
                  {!openProspectivelevel ? (
                    <KeyboardArrowDownIcon fontSize="small" />
                  ) : (
                    <KeyboardArrowUpIcon fontSize="small" />
                  )}
                </Box>

                {openProspectivelevel && (
                  <Grid item xs={12} mt={1}>
                    <Box
                      sx={{
                        border: "1px solid #888888",
                        padding: "10px",
                        borderRadius: "10px",
                        display: "flex",
                        gap: 2,
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography variant="h6">
                        Super Prospective : {totalSuperProspective}
                      </Typography>
                      <Box
                        sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                      />
                      <Typography variant="h6">Hot: {totalHot}</Typography>
                      <Box
                        sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                      />
                      <Typography variant="h6">Warm : {totalWarm}</Typography>
                      <Box
                        sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                      />
                      <Typography variant="h6">
                        Contact In Future : {totalContactInFuture}
                      </Typography>
                      <Box
                        sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                      />
                      <Typography variant="h6">
                        Recycled : {totalRecycled}
                      </Typography>
                      <Box
                        sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                      />
                      <Typography variant="h6">
                        New Lead : {totalNewlead}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Box>
                    <SelectedItemButton value={selectedid.length} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <SearchTextFieldComp
                      // value={value}
                      onChange={handleSearch}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} lg={12} sm={12} md={12}>
                <DataGrid
                  rows={originalData}
                  columns={columns}
                  autoHeight
                  slots={{
                    noRowsOverlay: () => (
                      <CustomNoRowsOverlay message="No Data Available" />
                    ),
                  }}
                  pageSize={pagination.pageSize}
                  onPaginationModelChange={(model) => setPagination(model)}
                  pageSizeOptions={[10, 25, 50, 100]}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {selectedid.length === 0 ? (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={<NoenquiryForm onClose={handleClosemask} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={
            <MassTaskAssign
              onClose={handleClosemask}
              selectedEnquiryIds={selectedEnquiryId}
              setSelectedEnquiryIds={setSelectedEnquiryids}
              handleFilterdata={handleFilterdata}
            />
          }
          title="Mass Task Assign"
          maxWidth="md"
          showDivider={true}
        />
      )}

      {selectedid.length === 0 ? (
        <CustomPopup
          open={OpenEmailPopup}
          onClose={handleCloseEmail}
          component={<NoeMailForm onClose={handleCloseEmail} />}
          maxWidth="xs"
        />
      ) : (
        <BulkEmailPopup
          open={OpenEmailPopup}
          handelclose={handleCloseEmail}
          component={
            <BulkMailform
              handelclose={handleCloseEmail}
              selectedId={selectedEnquiryId}
            />
          }
          title="Send Bulk Emails"
          maxWidth="md"
          showDivider={true}
        />
      )}
    </>
  );
}
