import { Tooltip } from "@mui/material";
import React from "react";

export default function UploadIconcomp({ size, onClick, tooltipTitle }) {
  return (
    //   <img src='/upload-2-64.png' width={size} onClick={onClick}/>
    <Tooltip title={tooltipTitle}>
      <img
        src="/icons8-upload-64.png"
        width={size ? size : 22}
        onClick={onClick}
        style={{ cursor: "pointer" }}
      />
    </Tooltip>
  );
}
