import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, CircularProgress, Collapse, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// project imports
import MainCard from '../../components/ui-component/card/MainCard'; 
import SubCard from '../../components/ui-component/card/SubCard'; 
//import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { CSVExport } from '../../components/ui-component/table/CSVExport';
//import { header } from './TableBasic';

// assets
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { gridSpacing } from '../../store/constant';
import AddBranch from './AddBranch';
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar';
import { fDate } from '../../../utils/formatTime';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
// table data

const header = [
    { label: 'Branch Name', key: 1 },
    { label: 'Calories (g)', key: 2 },
    { label: 'Carbs (g)', key: 3 },
    { label: 'Protein (g)', key: 4 },
    { label: 'Protein (g)', key: 5 },
    { label: 'Protein (g)', key: 6 }
];
function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            { date: '2023-11-08', advisors: 0, trainers: 0, classes: 0, students: 0 },
           
        ]
    };
}

function Row({ row }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ pl: 3 }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.branch_name}
                </TableCell>
                <TableCell >{row.address}</TableCell>
                <TableCell >{row.state}</TableCell>
                <TableCell >{row.city}</TableCell>
                
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <Box sx={{ margin: 1 }}>
                                <TableContainer>
                                    <SubCard
                                        sx={{ bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.50', mb: 2 }}
                                        title="Branch Data"
                                        content={false}
                                        secondary={
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <CSVExport
                                                    data={rows}
                                                    filename="collapse-table.csv"
                                                    header={header}
                                                />
                                            </Stack>
                                        }
                                    >
                                        <Table size="small" aria-label="purchases">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Advisors</TableCell>
                                                    <TableCell>Trainers</TableCell>
                                                    <TableCell>Classes</TableCell>
                                                    <TableCell>Active Students</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                               
                                                    <TableRow key={row.branch_code}>
                                                        <TableCell component="th" scope="row">
                                                            {fDate(row.date)}
                                                        </TableCell>
                                                        <TableCell>{row.no_of_advisor}</TableCell>
                                                        <TableCell >{row.no_of_trainer}</TableCell>
                                                        <TableCell >{row.no_of_classes}</TableCell>
                                                        <TableCell >{row.no_of_student}</TableCell>
                                                    </TableRow>
                                                
                                            </TableBody>
                                        </Table>
                                    </SubCard>
                                </TableContainer>
                            </Box>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [
    createData('New Dehli', 'Ms Hana', 'Dehli', 'New Dehli',),
    
];

// ==============================|| TABLE - COLLAPSIBLE ||============================== //





export default function BranchesTable() {
    const  userId  = localStorage.getItem('userId');
    const [rows, setRows] = useState([]);
    const [userDetails, setUserDetails] = useState(false);
    const [isAddDialogOpen, setAddDialogOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isLoading, setIsLoading] = useState(false)

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };

    const newRow = [];
    rows.forEach((element) => {
        newRow.push({
            ...element,
            history: null
        });
    });

    const fetchBranchData = () => {
       setIsLoading(true)
        fetch(`${API_ENDPOINT}route/getbranchdetail`)
            .then(response => response.json())
            .then(data => {
                setRows(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching branch data:', error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchBranchData();
    }, []); 


    const handleAddBranch = (data) => {
    
        const branchData = {
            branch_pin_code: parseInt(data.pincode),
            state: data.state,
            city: data.city,
            branch_name: data.name,
            address: `${data.building} ${data.floorHouse} ${data?.landmark}`,
            user_id: parseInt(userId)
        }

      
          fetch(`${API_ENDPOINT}branch`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(branchData),
          })
          .then((response) => {
            if(response.status === 201) {
               showSnackbar('Branch created successfully', 'success');
               window.location.reload()
            } else {
              
              showSnackbar('Error creating Branch', 'error');
            }
          })
            .catch((error) => {
              console.error('Error adding branch:', error);
              showSnackbar('Error creating Branch', 'error');
            });
        setAddDialogOpen(false);
      };

    return (
        <>
        <MainCard
            content={false}
            title="Branches"
            secondary={
                <Stack direction="row" spacing={2} alignItems="center">
                    <CSVExport data={rows} filename="basic-table.csv" header={header} />
                    <Button variant='contained' onClick={() => setAddDialogOpen(true)}>Add Branch</Button>
                </Stack>
            }
        >
            {/* table */}
            
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ pl: 3 }} />
                            <TableCell>Branch Name</TableCell>
                            <TableCell >Branch Address</TableCell>
                            <TableCell >City</TableCell>
                            <TableCell >State</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {isLoading ? (
                            <Box
                            style={{minHeight: '50vh'}}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                           
                        >
                            <CircularProgress color="primary" />
                          </Box>
                        ) : (
                            rows.length === 0 ? (
                                <TableRow>
                                    <TableCell sx={{ pl: 3 }} />
                                    <TableCell colSpan={4} align="center">
                                        <Typography variant='h4'>No data available</Typography>
                                    </TableCell>
                                    </TableRow>
                            ) : (
                                <>
                               
                        {rows.map((row) => (
                            <Row key={row.name} row={row} />
                        ))}
                      
                        </>
                            )
                        )}
                      </TableBody>
                </Table>
            </TableContainer>
        </MainCard>  

            <AddBranch
            open={isAddDialogOpen}
            onClose={() => setAddDialogOpen(false)}
            onAdd={handleAddBranch}
            />

        <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            </>
    );
}