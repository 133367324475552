import React, { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Pagination,
  TablePagination,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

// project imports

import { gridSpacing } from "../../../store/constant";

// assets
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import MainCard from "../../../components/ui-component/card/MainCard";
import StudentListCard from "./StudentListCard";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import SideBarFilter from "../../../../pages/adminPages/classes/SideBarFilter";

import { CSVExport } from "../../../components/ui-component/table/CSVExport";

import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import StudentTable from "./StudentTable";
import StudentFilter from "./studentDetails/StudentFilter";
import { string } from "prop-types";
import { useGetTokenOrLogout } from "../../../../utils/token";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../../components/ui-component/snackbar/CustomSnackbar";
import SearchTextFieldComp from "../../../newComponent/newtextfieldComp/SearchTextFieldComp";
import FilterButtonComponent from "../../../newComponent/NewButtonComp/FilterButtonComponent";
import HeadingCard from "../../../newComponent/NewCardsComp/HeadingCard";

// ==============================|| USER LIST STYLE 2 ||============================== //

const StudentsListPage = () => {
  const getTokenOrLogout = useGetTokenOrLogout();

  const navigate = useNavigate();
  const theme = useTheme();
  const userId = localStorage.getItem("userId");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [name, setName] = useState([]);
  const [enrollmentNo, setEnrollmentNo] = useState("");
  const [branch, setBranch] = useState([]);
  const [enrollmentDateFrom, setEnrollmentDateFrom] = useState("");
  const [enrollmentDateTo, setEnrollmentDateTo] = useState("");
  const [branchofEnquiry, setBranchOfEnquiry] = useState([]);

  const [statusname, setstatusname] = useState([]);
  const [examoption, setexamoption] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
   const [page, setPage] = useState(0);
 
 
  const [filterddata, setFilteredData] = useState([]);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [OriginalStudentData, setOriginalStudentData] = useState([]);
  const [searchvalue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(6);
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };



  const handleClose = () => {
    setAnchorEl(null);
  };




  // Toggle betweeen table and list view

  const [view, setView] = useState("list"); // Default view is 'list'

  const handleListClick = (views) => {
    setView(views);
    if (views !== view) {
      setStudents([]);
      setOriginalStudentData([]);
      setName("");
      setEnrollmentNo("");
      setEnrollmentDateFrom("");
      setEnrollmentDateTo("");
      setstatusname([]);
      setBranch([]);
      setexamoption([]);
      setSearchValue("");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { Authorization: `Bearer ${token}` };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route/newstudentinfo/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();

          const sortedStudents = data.sort(
            (a, b) => new Date(b.joining_date) - new Date(a.joining_date)
          );

          setStudents(sortedStudents);
          setOriginalStudentData(sortedStudents);
          setFilteredData(sortedStudents);
          setIsLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId, view]);

  // const startIndex = (currentPage - 1) * selectedRowsPerPage;
  // const endIndex = startIndex + selectedRowsPerPage;

  // // Slice the array to get the students for the current page
  // const studentsForCurrentPage = students.slice(startIndex, endIndex);

  const handlenamefilter = (e) => {
    const value = e.target.value;
    setSearchValue(e.target.value);
    let filteredData1 = filterddata.filter((data) =>
      `${data?.enquiry_id?.first_name} ${data?.enquiry_id?.last_name}`
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );

    setStudents(filteredData1);
  };
  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <HeadingCard label="Students List"/>
      </Grid>
      <Grid  item xs={12} sm={8} md={9} lg={10} container justifyContent='end' gap={1} alignItems="center">
     
              <ToggleButtonGroup
                value={view}
                exclusive
                size="small"
                style={{ height: "20px" }}
              >
                <Tooltip title="Card View">
                  <ToggleButton
                    aria-label="list"
                    onClick={() => handleListClick("list")}
                    sx={{
                      bgcolor: view === "list" ? "#ed7211" : "transparent",
                      "&:hover": {
                        bgcolor:
                          view === "list"
                            ?"#ed7211"
                            : "transparent",
                      },
                    }}
                  >
                    <ViewQuiltIcon
                      style={{
                        fontSize: "1rem",
                        color: view === "list" ? "white" : "black",
                      }}
                    />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="List View">
                  <ToggleButton
                    aria-label="quilt"
                    onClick={() => handleListClick("quilt")}
                    sx={{
                      bgcolor: view === "quilt" ? "#ed7211" : "transparent",
                      "&:hover": {
                        bgcolor:
                          view === "quilt"
                            ? "#ed7211"
                            : "transparent",
                      },
                    }}
                  >
                    <ViewListIcon
                      style={{
                        fontSize: "1rem",
                        color: view === "quilt" ? "white" : "black",
                      }}
                    />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>

              
          
         
             
              
                <FilterButtonComponent
                  onClick={() => setIsOpen(true)}
                  showendIcon={true}
                />
                
            
           
      </Grid>

 <Grid item xs={12}>
      <MainCard
     
      >
        <StudentFilter
      
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          name={name}
          setName={setName}
          enrollmentNo={enrollmentNo}
          setEnrollmentNo={setEnrollmentNo}
          enrollmentDateFrom={enrollmentDateFrom}
          setEnrollmentDateFrom={setEnrollmentDateFrom}
          enrollmentDateTo={enrollmentDateTo}
          setEnrollmentDateTo={setEnrollmentDateTo}
          statusname={statusname}
          setstatusname={setstatusname}
          branch={branch}
          setBranch={setBranch}
          examoption={examoption}
          setexamoption={setexamoption}
          filterddata={filterddata}
          setFilteredData={setFilteredData}
          branchofEnquiry={branchofEnquiry}
          setBranchOfEnquiry={setBranchOfEnquiry}
          setOriginalStudentData={setOriginalStudentData}
          OriginalStudentData={OriginalStudentData}
          setStudents={setStudents}
          setIsLoading={setIsLoading}
        />
<Grid container spacing={2}>
  <Grid item xs={12} textAlign='end'>
  <SearchTextFieldComp
        placeholder="search by student name..."
          size="small"
          value={searchvalue}
          onChange={handlenamefilter}
        
        />

  </Grid>
  <Grid item xs={12}>
        {view === "list" ? (
          <StudentListCard
            // studentsForCurrentPage={studentsForCurrentPage}
        
       
          
            students={students}
            setStudents={setStudents}
            isLoading={isLoading}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        ) : (
          <StudentTable
            // studentsForCurrentPage={studentsForCurrentPage}
        
        
            students={students}
            setStudents={setStudents}
            isLoading={isLoading}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        )}

</Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>
          <Grid container  justifyContent='end'>
         
           
<TablePagination
                    rowsPerPageOptions={[6, 12, 20]}
                    component="div"
                    count={students.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Grid>
          </Grid>
       
          </Grid>
      </MainCard>
      </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default StudentsListPage;
