import { Box, Card, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import NewCardcomp from '../../../components/card/NewCardcomp';


export default function DisplayCurrentDateTime() {
  const [time, setTime] = useState('');
 // Function to update the time
 const updateTime = () => {
  const now = new Date();

  // Options for the time format
  const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Asia/Kolkata',
      timeZoneName: 'short'
  };

  // Format the time
  const formattedTime = now.toLocaleString('en-IN', options);

  // Update the state with the formatted time
  setTime(formattedTime);
};

// Update the time when the component mounts
useEffect(() => {
  // Call updateTime immediately to set initial time
  updateTime();

  // Set interval to update the time every minute
  const intervalId = setInterval(updateTime, 60); // Updates every minute

  // Cleanup interval on component unmount
  return () => clearInterval(intervalId);
}, []); // Empty dependency array to run only once on mount


// Get the current date
const currentDate = new Date();

// Format the date manually to get the weekday at the end
const options1 = {
  weekday: 'long',  // Full name of the weekday (e.g., "Saturday")
  year: 'numeric',
  month: 'short',   // Abbreviated month (e.g., "Dec")
  day: 'numeric'
};

// Format the current date using Intl.DateTimeFormat
const formattedDate = new Intl.DateTimeFormat('en-GB', options1).format(currentDate);

// Split the formatted date string to rearrange it
const [day, month, year, weekday] = formattedDate.split(' ');

// Combine them in the desired order
const finalFormattedDate = ` ${month} ${year} ${weekday}, ${day}`;





  return (
    <NewCardcomp>
        <Box display='flex' alignItems='center' gap={1}>
        <Box
                sx={{
                  background: "#FFF6E2",
                  width: "35px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "7px",
                }}
              >
                <img src="/Group (8).png" alt="img" />
              </Box>
              <Box>
              <Typography variant='subtitle1' color="#000000" fontWeight={600}>{finalFormattedDate}</Typography>
              <Typography variant='h5' sx={{ color:"#4D4D4D"}}>{time}</Typography>
              </Box>
              </Box>
    </NewCardcomp>
  )
}
