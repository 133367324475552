import { Tooltip } from '@mui/material'
import React from 'react'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function ApproveIcon({tooltipTitle,onClick, fontSize}) {
  return (
   <>
   <Tooltip title={tooltipTitle}>
                  <CheckCircleIcon
                    style={{ fontSize: fontSize || "1.2rem", cursor: "pointer" }}
                    variant="contained"
                    color="error"
                    onClick={onClick}
                  />
                </Tooltip>
   </>
  )
}
