import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { fDate } from "../../../utils/formatTime";
import {
  Box,
  Chip,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Backdrop,
  Typography,
  TextField,
  Checkbox,
} from "@mui/material";
import { Person as PersonIcon } from "@mui/icons-material";
//import EnquiriesWidget from './EnquiriesWidget';
import { AddCircle } from "@mui/icons-material";
import "../../advisor/leadpages/enquiryDataGrid.css";
import { Link, useNavigate } from "react-router-dom";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import EnquaryFilter from "../../../newTestPrep/components/Filter/EnquaryFilter";
import { CSVExport } from "../../../newTestPrep/components/ui-component/table/CSVExport";
import ViewTasks from "../../../newTestPrep/pages/enquiry/tasks/ViewTasks";
import CustomPopup from "../../../components/CustomPopup";
import NoEnquiry from "../../../newTestPrep/components/DataTransferComp.jsx/NoEnquiry";

import NoenquiryForm from "../../../newTestPrep/components/DataTransferComp.jsx/NoenquiryForm";
import EmailIcon from "@mui/icons-material/Email";

import NoeMailForm from "../../../newTestPrep/components/DataTransferComp.jsx/NoMailForm";

import { useGetTokenOrLogout } from "../../../utils/token";
import MainCard from "../../../newTestPrep/components/ui-component/card/MainCard";
import MassEnquiryTransfer from "../../../newTestPrep/components/DataTransferComp.jsx/MassEnquiryTransfer";
import MassTaskAssign from "../../../newTestPrep/components/DataTransferComp.jsx/MassTaskAssign";
import BulkEmailPopup from "../../../newTestPrep/components/BulkEmail/BulkEmailPopup";
import BulkMailform from "../../../newTestPrep/components/BulkEmail/BulkMailform";
import SearchTextFieldComp from "../../../newTestPrep/newComponent/newtextfieldComp/SearchTextFieldComp";
import SelectedItemButton from "../../../newTestPrep/newComponent/NewButtonComp/SelectedItemButton";
import SendEmailButton from "../../../newTestPrep/newComponent/NewButtonComp/SendEmailButton";
import MassTaskAssignButton from "../../../newTestPrep/newComponent/NewButtonComp/MassTaskAssignButton";
import MassEnquiryTransferButton from "../../../newTestPrep/newComponent/NewButtonComp/MassEnquiryTransferButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HeadingCard from "../../../newTestPrep/newComponent/NewCardsComp/HeadingCard";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const ViewEnquiriesDataGrid = () => {
  //const { advisorId } = useSelector((state) => state.user)
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const [originalRows, setOriginalRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [exportData, setExportData] = useState([]);

  const [isDownloadAccess, setIsDownloadAccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  //const [filteredEnquiries, setFilteredEnquiries] = useState(enquiriesData);
  const [selectedEnquiryIds, setSelectedEnquiryIds] = useState([]);
  const [openMask, setOpenMask] = useState(false);
  const [openAssignTask, setOpenAssignTask] = useState(false);
  const [OpenEmailPopup, setOpenEmailPopup] = useState(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [originaEnquiryData, setOriginalEnquirydata] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [selectedChip, setSelectedChip] = useState("");
  const [viewprospectiveLevel, setViewProspectiveLevel] = useState(false);

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 100,
  });

  const [visibleRowIds, setVisibleRowIds] = useState([]); // Store current page's row IDs
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [prevPageSize, setPrevPageSize] = useState(pagination.pageSize);
  const [gridKey, setGridKey] = useState(0);
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClosemask = () => {
    setOpenMask(false);
  };

  const handleOpenmask = () => {
    setOpenMask(true);
  };

  const handleOpenEmail = () => {
    setOpenEmailPopup(true);
  };

  const handleCloseEmail = () => {
    setOpenEmailPopup(false);
  };
  const handleCloseTask = () => {
    setOpenAssignTask(false);
  };

  const handleOpenTask = () => {
    setOpenAssignTask(true);
  };

  const handletoggleProspectiveLevels = () => {
    setViewProspectiveLevel(!viewprospectiveLevel);
  };

  // Upadate current page ids when pagination is chnages
  useEffect(() => {

    setPrevPageSize(pagination.pageSize);

    // new pagesize is less than prev page size then deselct all selected ids first
    if (pagination.pageSize !== prevPageSize) {
      setSelectedEnquiryIds([]);
      return;
    }

    // Update visible row IDs when pagination changes
    const currentPageRows = rows.slice(
      pagination.page * pagination.pageSize,
      (pagination.page + 1) * pagination.pageSize
    );
    const visibleIds = currentPageRows.map((row) => row.id);
    setVisibleRowIds(visibleIds);

    // If there are no visible rows, set SelectAllChecked to false
    if (visibleIds.length === 0) {
      setIsSelectAllChecked(false);
      setIsIndeterminate(false);
      return;
    }

    // Check if all visible rows are selected
    const allVisibleSelected = visibleIds.every((id) =>
      selectedEnquiryIds.includes(id)
    );
    const someVisibleSelected = visibleIds.some((id) =>
      selectedEnquiryIds.includes(id)
    );

    setIsSelectAllChecked(allVisibleSelected);
    setIsIndeterminate(someVisibleSelected && !allVisibleSelected);
  }, [pagination, rows, selectedEnquiryIds]);

  // HandleSelectAllCheckBox
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setIsSelectAllChecked(checked);

    if (checked) {
      // Select all visible row IDs
      setSelectedEnquiryIds((prevSelectedIds) => [
        ...new Set([...prevSelectedIds, ...visibleRowIds]),
      ]);
    } else {
      // Deselect all visible row IDs
      setSelectedEnquiryIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => !visibleRowIds.includes(id))
      );
    }
  };

  // Individual CheckBox
  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;

    setSelectedEnquiryIds((prevSelectedIds) => {
      if (checked) {
        const newSelected = [...prevSelectedIds, id];
        // Check if all visible rows are selected after this checkbox is checked
        const allVisibleSelected = visibleRowIds.every((rowId) =>
          newSelected.includes(rowId)
        );
        setIsSelectAllChecked(allVisibleSelected);
        setIsIndeterminate(!allVisibleSelected);

        return newSelected;
      } else {
        // Deselect the row ID
        const newSelected = prevSelectedIds.filter((rowId) => rowId !== id);
        setIsSelectAllChecked(false);
        setIsIndeterminate(
          visibleRowIds.some((rowId) => newSelected.includes(rowId))
        );

        return newSelected;
      }
    });
  };

  const handleChipClick = (status) => {
    if (status === "All") {
      setRows(originalRows);
      setSelectedChip("");
    } else {
      const filtered = originalRows.filter(
        (enquiry) => enquiry?.prospective_level_id?.prospective_level === status
      );
      setRows(filtered);
      setSelectedChip(status);
    }
  };


  /*const handleViewMore = (row) => {
    // Handle the "View More" action here
  

    navigate(`/enquirydetails/${row.id}`, {
      state: { enquiryDetails: row }
    });
  };*/

  const handleViewMore = (row) => {
    // Handle the "View More" action here
    
    const url = `/enquiry-summary/${row?.enq_personal_detail_id}`;
    const newState = { enquiryDetails: row };
  
    // Open link in new tab
    const newTab = window.open(url, "_blank");

    // If new tab opened successfully, navigate with state data
    if (newTab) {
      newTab.onload = () => {
        newTab.history.replaceState(newState, "", url);
      };
    } else {
      // If new tab was blocked by browser, navigate with state data in current tab
      navigate(url, { state: newState });
    }
  };

  const handleAddNewEnquiry = () => {
    navigate(`/addenquirynew`);
  };

  const handleAddBulkEnquiry = () => {
    navigate(`/addbulkenquiries`);
  };


  // const totalRows = rows.length; // Total number of rows in the table

  // Check if the select all checkbox should be indeterminate (some rows are selected but not all)

  const columns = [
    {
      field: "selection",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <Checkbox
          size="small"
          checked={isSelectAllChecked}
          indeterminate={isIndeterminate}
          onChange={handleSelectAllChange}
        />
      ),
      headerClassName: "header-cell",
      width: 70,
      renderCell: (params) => {
        const isChecked = selectedEnquiryIds.includes(params.row.id);
        return (
          <Checkbox
            size="small"
            checked={isChecked}
            onChange={(event) => handleCheckboxChange(event, params.row?.id)}
          />
        );
      },
    },
    {
      field: "name", // Logical field name for the column
      headerName: "Enquiry Name",
      width: 200,
      // sortable: true, // Enable sorting
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        `${params.row?.first_name || ""} ${params.row?.last_name || ""}`, // Use direct API fields
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{ fontSize: "0.65rem" }}
              onClick={() => handleViewMore(params.row)}
            >
              {params.row?.first_name} {params.row?.last_name}
            </Button>
          </div>
        );
      },
    },

    {
      field: "mobile",
      headerName: "Mobile",
      width: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "bold-header",
      valueGetter: (params) =>
        `${params.row?.mobile_country_code}${params.row?.mobile || ""}`,

      renderCell: (params) => (
        <div>
          {params.row?.mobile_country_code}

          {userRole === "advisor" || userRole === "trainer"
            ? params.row?.mobile.replace(/.(?=.{4,}$)/g, "*")
            : params.row?.mobile}
        </div>
      ),
    },

    {
      field: "email",
      headerName: "email",
      width: 250,
      headerAlign: "center",
      align: "center",
      headerClassName: "bold-header",
      valueGetter: (params) => `${params.row?.email || ""}`,
      renderCell: (params) => (
        <div>
          {userRole === "advisor" || userRole === "trainer"
            ? "**********"
            : params.row?.email}
        </div>
      ),
    },
    {
      field: "connected_call",
      headerName: "Cont.",
      width: 50,
      headerAlign: "center",
      align: "center",
      headerClassName: "bold-header",
      valueGetter: (params) => params.value,
    },
    {
      field: "not_connected_call",
      headerName: "N-Cont",
      width: 70,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value,
    },
    {
      field: "total_open_task",
      headerName: "O.T",
      headerAlign: "center",
      align: "center",
      width: 50,
      valueGetter: (params) => params.value,
    },
    {
      field: "enquiry_date",
      headerName: "Enq Date",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => `${params.row?.enquiry_date || ""}`,
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
    {
      field: "last_follow_up_date",
      headerName: "Last FollowUp ",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => `${params.row?.last_follow_up_date || ""}`,
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
    {
      field: "next_follow_up_date",
      headerName: "Next FollowUp ",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => `${params.row?.next_follow_up_date || ""}`,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "prospective_level_id",
      headerName: "Prospective Level",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value?.prospective_level || "",
    },
    {
      field: "recyclereson",
      headerName: "Recycle Reason",
      width: 200,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        `${params.row?.recycle_reason_id?.recycle_reason || ""}`,
    },
    {
      field: "nearest_branch_id",
      headerName: "Nearest Branch",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        `${params.row.nearest_branch_id?.branch_name || ""}`,
    },
    {
      field: "service_id",
      headerName: "Service",
      width: 150,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value?.service_name || "",
    },
    {
      field: "sub_service_id",
      headerName: "Sub Service",
      width: 150,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value?.name || "",
    },
    {
      field: "premium_admission_programs_id",
      headerName: "P.A Programs",
      width: 150,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value?.name || "",
    },
    {
      field: "campaign",
      headerName: "Campaign",
      width: 350,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params?.row?.campaign_id?.campaign_name || "",
    },

    {
      field: "assign_to",
      headerName: "P.Advisor",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params?.row?.assign_to?.first_name || "",
    },
    {
      field: "co_assign_to",
      headerName: "Co-Assignee",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params?.row?.co_assignee?.first_name || "",
      renderCell: (params) => (
        <div>
          {params.row.co_assignee?.first_name}{" "}
          {params.row.co_assignee?.last_name}
        </div>
      ),
    },
    {
      field: "lead_source_type_id",
      headerName: "LS",
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      valueFormatter: (params) => params.value?.name || "",
    },
    {
      field: "sub_lead_source_type_id",
      headerName: "SLS",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value?.source_type || "",
    },

    {
      field: "stage_id",
      headerName: "Stage",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value?.stage_name || "",
    },
    {
      field: "enrolled_date",
      headerName: "Enrolled date",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params.row?.enrolled_detail?.enrollment_date || "",
    },

    {
      field: "enrolled_by",
      headerName: "Enrolled By",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params.row?.enrolled_detail?.created_by?.first_name || "",
      renderCell: (params) => (
        <div>
          {params.row.enrolled_detail?.created_by?.first_name}{" "}
          {params.row.enrolled_detail?.created_by?.last_name}
        </div>
      ),
    },
  ];
  const boldHeaderCellStyle = {
    fontWeight: "bold", // Apply bold font weight to header cells
  };

  useEffect(() => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}admindashboard/allfreshenquiries/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.length === 0) {
            showSnackbar("No data Available", "error");
            setIsLoading(false);
          } else {
            const sortedData = data.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );

            setOriginalEnquirydata(sortedData);
            setOriginalRows(sortedData);
            setRows(sortedData);
            setIsLoading(false);
          }
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
      .then((response) => {
        if (response.ok) {
          setIsDownloadAccess(true);
        } else {
          setIsDownloadAccess(false);
        }
      })
      .catch((err) => {
        setIsDownloadAccess(false);
      });
  }, [userId]);

  useEffect(() => {
    const exportData = rows.map((row) => ({
      name: row.last_name
        ? `${row.first_name} ${row.last_name}`
        : row.first_name,
      mobile_code: row?.mobile_country_code,
      mobile: row.mobile,
      contactable: row.connected_call || "",
      non_contactable: row.not_connected_call || "",
      open_tasks: row.total_open_task || "",
      nearest_branch: row.nearest_branch_id?.branch_name || "",
      email: row.email,
      enquiry_date: fDate(row.enquiry_date),
      last_follow_up: row?.last_follow_up_date ? row.last_follow_up_date : "",
      next_follow_up: row?.next_follow_up_date ? row.next_follow_up_date : "",
      prospective_level: row.prospective_level_id?.prospective_level || "",
      recycle_reason: row.recycle_reason_id?.recycle_reason || "",
      service: row.service_id?.service_name || "",
      sub_service: row.sub_service_id?.name || "",
      premium_admission_programs: row.premium_admission_programs_id?.name || "",
      assign: row.assign_to?.first_name || "",
      co_assign: row.co_assignee?.first_name || "",
      campaign_id: row.campaign_id?.campaign_name || "",
      lead_source_type: row.lead_source_type_id?.name || "",
      sub_lead_source_type: row.sub_lead_source_type_id?.source_type || "",
      stage: row.stage_id?.stage_name || "",
      enrolled_date: row?.enrolled_detail?.enrollment_date
        ? row.enrolled_detail.enrollment_date
        : "",
      enrolled_by: row?.enrolled_detail?.created_by?.first_name || "",
    }));
    setExportData(exportData);
  }, [rows]);

  // Filter By Mobile , email,Name
  // const handlenamefilter = (e) => {
  //   const value = e.target.value;
  //   if (!isNaN(value) && /^\d+$/.test(value)) {
  //     let filteredData1 = originalRows.filter((data) =>
  //       `${data?.mobile_country_code}${data?.mobile}`.includes(value)
  //     );

  //     setRows(filteredData1);
  //   } else if (value.includes("@")) {
  //     // Check if the value looks like an email
  //     let filteredData1 = originalRows.filter((data) =>
  //       data?.email?.toLowerCase().includes(value)
  //     );
  //     setRows(filteredData1);
  //   } else {
  //     // Assume the value is a name
  //     let filteredData1 = originalRows.filter((data) =>
  //       `${data?.first_name} ${data?.last_name}`
  //         ?.toLowerCase()
  //         .replace(/\s+/g, "")
  //         .includes(value.toLowerCase().replace(/\s+/g, ""))
  //     );
  //     setRows(filteredData1);
  //   }
  // };

  // Search All column data
  const handlenamefilter = (e) => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, ""); // Normalize search value (lowercase and remove spaces)

    let filteredData1 = originalRows.filter((data) => {
      // Check each field for a match with the search value
      const fieldsToSearch = [
        `${data?.first_name || ""} ${data?.last_name || ""}`,

        `${data?.mobile_country_code || ""}${data?.mobile || ""}`,
        `${data?.email || ""}`,
        `${fDate(data?.enquiry_date || "")}`,
        `${data?.last_follow_up_date || ""}`,
        `${data?.next_follow_up_date || ""}`,
        `${data?.prospective_level_id?.prospective_level || ""}`,

        `${data?.recycle_reason_id?.recycle_reason || ""}`,
        `${data?.nearest_branch_id?.branch_name || ""}`,
        `${data?.service_id?.service_name || ""}`,
        `${data?.sub_service_id?.name || ""}`,
        `${data?.premium_admission_programs_id?.name || ""}`,
        `${data?.campaign_id?.campaign_name || ""}`,
        `${data?.assign_to?.first_name || ""} ${
          data?.assign_to?.last_name || ""
        }`,
        `${data?.co_assignee?.first_name || ""} ${
          data?.co_assignee?.last_name || ""
        }`,
        `${data?.lead_source_type_id?.name || ""}`,
        `${data?.sub_lead_source_type_id?.source_type || ""}`,
        `${data?.stage_id?.stage_name || ""}`,
        `${data?.enrolled_detail?.enrolled_date || ""}`,
        `${data?.enrolled_detail?.created_by?.first_name || ""} ${
          data?.enrolled_detail?.created_by?.last_name || ""
        }`,
      ];

      // Check if any of the fields contain the search value
      return fieldsToSearch.some((field) =>
        field.toLowerCase().replace(/\s+/g, "").includes(value)
      );
    });

    setRows(filteredData1); // Update the filtered data
  };

  // For mask task assign and enquiry transfer

  const handleFilterdata = () => {
    const data = rows.filter((item) => !selectedEnquiryIds.includes(item.id));
    setRows(data);
    setOriginalRows(data);

    setTimeout(() => {
      setSelectedEnquiryIds([]);
    }, 1500);
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fetching
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Grid container>
        {/* <Grid item xs={12} sm={4} md={3}>
          <Button
            variant="contained"
            size="medium"
            onClick={handleAddNewEnquiry}
            style={{ borderRadius: "10px" }}
          >
            Add New Enquiry
          </Button>
        </Grid> */}
        <Grid item xs={12} sm={4} md={2}>
          <HeadingCard>
            <Typography variant="h3" textAlign="center">
              {" "}
              View Enquiries
            </Typography>
          </HeadingCard>
        </Grid>
        <Grid item xs={12} sm={8} md={10}>
          <Grid container gap={1} justifyContent="end">
            {(userRole === "center_admin" || userRole === "data_entry") && (
              <MassEnquiryTransferButton onClick={() => handleOpenmask()}>
                Mass Enquiry Transfer
              </MassEnquiryTransferButton>
            )}
            {userRole === "data_entry" && (
              <MassTaskAssignButton onClick={() => handleOpenTask()}>
                Mass Task Assign
              </MassTaskAssignButton>
            )}
            <SendEmailButton onClick={handleOpenEmail} />
            <ViewTasks />

            <EnquaryFilter
              setOriginalRows={setOriginalRows}
              setRows={setRows}
              originalRows={originalRows}
              setIsLoading={setIsLoading}
              originaEnquiryData={originaEnquiryData}
              setGridKey={setGridKey}
            />

            {isDownloadAccess && (
              <CSVExport data={exportData} filename="enquiries.csv" />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Box m={2} />

      {/* Filter inputs */}
      <MainCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Total Enquiries :{" "}
              <span style={{ fontWeight: 400 }}>{originalRows.length}</span>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box
              display="fles"
              alignItems="center"
              cursor="pointer"
              style={{ cursor: "pointer" }}
              onClick={handletoggleProspectiveLevels}
            >
              <Typography variant="h4">Prospective Level</Typography>
              {!viewprospectiveLevel ? (
                <KeyboardArrowDownIcon fontSize="small" />
              ) : (
                <KeyboardArrowUpIcon fontSize="small" />
              )}
            </Box>
            {viewprospectiveLevel && (
              <Stack direction="row" gap={1} mt={1}>
                {/* <EnquiriesWidget enquiriesData={rows} /> */}

                <Chip
                  size="small"
                  label={`New Leads: ${
                    originalRows.filter(
                      (enquiry) =>
                        enquiry?.prospective_level_id?.prospective_level ===
                        "New lead"
                    ).length
                  }`}
                  variant={
                    selectedChip === "New lead" ? "contained" : "outlined"
                  }
                  style={{
                    background: selectedChip === "New lead" ? "#2196F3" : "",
                    color: selectedChip === "New lead" ? "white" : "",
                  }}
                  onClick={() => handleChipClick("New lead")}
                />
                <Chip
                  size="small"
                  label={`Super Prospective: ${
                    originalRows.filter(
                      (enquiry) =>
                        enquiry?.prospective_level_id?.prospective_level ===
                        "Super Prospective"
                    ).length
                  }`}
                  variant={
                    selectedChip === "Super Prospective"
                      ? "contained"
                      : "outlined"
                  }
                  style={{
                    background:
                      selectedChip === "Super Prospective" ? "#2196F3" : "",
                    color: selectedChip === "Super Prospective" ? "white" : "",
                  }}
                  onClick={() => handleChipClick("Super Prospective")}
                />
                <Chip
                  size="small"
                  label={`Hot: ${
                    originalRows.filter(
                      (enquiry) =>
                        enquiry?.prospective_level_id?.prospective_level ===
                        "Hot"
                    ).length
                  }`}
                  variant={selectedChip === "Hot" ? "contained" : "outlined"}
                  style={{
                    background: selectedChip === "Hot" ? "#2196F3" : "",
                    color: selectedChip === "Hot" ? "white" : "",
                  }}
                  onClick={() => handleChipClick("Hot")}
                />
                <Chip
                  size="small"
                  label={`Warm: ${
                    originalRows.filter(
                      (enquiry) =>
                        enquiry?.prospective_level_id?.prospective_level ===
                        "Warm"
                    ).length
                  }`}
                  variant={selectedChip === "Warm" ? "contained" : "outlined"}
                  style={{
                    background: selectedChip === "Warm" ? "#2196F3" : "",
                    color: selectedChip === "Warm" ? "white" : "",
                  }}
                  onClick={() => handleChipClick("Warm")}
                />
                <Chip
                  size="small"
                  label={`Contact In Future: ${
                    originalRows.filter(
                      (enquiry) =>
                        enquiry?.prospective_level_id?.prospective_level ===
                        "Contact In Future"
                    ).length
                  }`}
                  variant={
                    selectedChip === "Contact In Future"
                      ? "contained"
                      : "outlined"
                  }
                  style={{
                    background:
                      selectedChip === "Contact In Future" ? "#2196F3" : "",
                    color: selectedChip === "Contact In Future" ? "white" : "",
                  }}
                  onClick={() => handleChipClick("Contact In Future")}
                />

                <Chip
                  size="small"
                  label={`Recycled: ${
                    originalRows.filter(
                      (enquiry) =>
                        enquiry?.prospective_level_id?.prospective_level ===
                        "Recycled"
                    ).length
                  }`}
                  variant={
                    selectedChip === "Recycled" ? "contained" : "outlined"
                  }
                  style={{
                    background: selectedChip === "Recycled" ? "#2196F3" : "",
                    color: selectedChip === "Recycled" ? "white" : "",
                  }}
                  onClick={() => handleChipClick("Recycled")}
                />
              </Stack>
            )}
          </Grid>
          <Grid item xs={6}>
            <SelectedItemButton value={selectedEnquiryIds.length} />
          </Grid>

          <Grid
            item
            xs={6}
            container
            direction="row"
            alignItems="center"
            justifyContent="end"
          >
            <SearchTextFieldComp onChange={handlenamefilter} />
          </Grid>
        </Grid>
        <DataGrid
          rows={rows}
          key={gridKey}
          columns={columns}
          autoHeight
          disableColumnSorting={true}
          // disableColumnMenu={true} // Disables the column menu for all columns
          disableColumnFilter // Disables the filter functionality globally
          disableColumnSelector // Disables the column selector
          // pageSize={5}
          // defaultDensity="compact"
          // initialState={{
          //   pagination: {
          //     paginationModel: {
          //       pageSize: 100,
          //     },
          //   },
          // }}

          pageSize={pagination.pageSize}
          onPaginationModelChange={(model) => setPagination(model)}
          sx={{ marginTop: "20px" }}
          pageSizeOptions={[10, 25, 50, 100]}
          components={{
            NoRowsOverlay: () => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <p>No Enquiries available</p>
              </div>
            ),
          }}
        />

        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />

        {selectedEnquiryIds.length === 0 ? (
          <CustomPopup
            open={openMask}
            onClose={handleClosemask}
            component={<NoEnquiry onClose={handleClosemask} />}
            maxWidth="xs"
          />
        ) : (
          <CustomPopup
            open={openMask}
            onClose={handleClosemask}
            component={
              <MassEnquiryTransfer
                onClose={handleClosemask}
                selectedEnquiryIds={selectedEnquiryIds}
                handleFilterdata={handleFilterdata}
                setSelectedEnquiryIds={setSelectedEnquiryIds}
                setIsSelectAllChecked={setIsSelectAllChecked}
              />
            }
            title="Enquiry Transfer"
            maxWidth="sm"
            showDivider={true}
          />
        )}

        {selectedEnquiryIds.length === 0 ? (
          <CustomPopup
            open={openAssignTask}
            onClose={handleCloseTask}
            component={<NoenquiryForm onClose={handleCloseTask} />}
            maxWidth="xs"
          />
        ) : (
          <CustomPopup
            open={openAssignTask}
            onClose={handleCloseTask}
            component={
              <MassTaskAssign
                onClose={handleCloseTask}
                selectedEnquiryIds={selectedEnquiryIds}
                setSelectedEnquiryIds={setSelectedEnquiryIds}
                handleFilterdata={handleFilterdata}
              />
            }
            title="Task Assign"
            maxWidth="md"
            showDivider={true}
          />
        )}

        {selectedEnquiryIds.length === 0 ? (
          <CustomPopup
            open={OpenEmailPopup}
            onClose={handleCloseEmail}
            component={<NoeMailForm onClose={handleCloseEmail} />}
            maxWidth="xs"
          />
        ) : (
          <BulkEmailPopup
            open={OpenEmailPopup}
            handelclose={handleCloseEmail}
            component={
              <BulkMailform
                handelclose={handleCloseEmail}
                selectedId={selectedEnquiryIds}
              />
            }
            title="Send Bulk Emails"
            maxWidth="md"
            showDivider={true}
          />
        )}
      </MainCard>
    </div>
  );
};

export default ViewEnquiriesDataGrid;
