import { Button } from "@mui/material";
import React from "react";

export default function SecondaryButton({ onClick, label,disabled , size }) {
  return (
    <>
      <Button
        onClick={onClick}
        variant="contained"
        size={size?size:"medium"}
        style={{ borderRadius: "5px", background:disabled?"" :"#00b13f" }}
        disabled={disabled}
      >
        {label ? label : "Submit"}
      </Button>
    </>
  );
}
