// Author :Ayesha Shaikh
// Date : 6 Aug 2024

import {
  Box,
  Chip,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import StyledTableContainer from "../../newComponent/TableContainer";

export default function ApproveOnlinePaymentInstallation({ data }) {
  
  return (
    <Grid container gap={{xs:2, sm:3}}>
      <Typography variant="h5" fontWeight={400}>
        {" "}
        Name : <strong>{data?.enquiry_name?.first_name} {data?.enquiry_name?.last_name}</strong>
      </Typography>
      <Typography variant="h5"  fontWeight={400}>
        {" "}
        Package : <strong>{data?.package_name} </strong>
      </Typography>
      <Typography variant="h5"  fontWeight={400}>
        Amount Of Package : <strong>₹ {data?.amount_of_package} </strong>
      </Typography>
      <Typography variant="h5"  fontWeight={400}>
        Amount To Be Paid : <strong>₹ {data?.amount_to_paid}</strong>
      </Typography>
      <Typography variant="h5"  fontWeight={400}>
        Discount Amount : <strong>₹ {data?.discount_amount} </strong>
      </Typography>
      <StyledTableContainer
        component={Paper}
        style={{ maxWidth: "100%", overflow: "auto" , marginTop:5 }}
        borderLeft={true}
      >
        <Table aria-label="simple table" stickyHeader style={{ minWidth: 700 }}>
          <TableHead>
            <TableRow  >
            <TableCell style={{ minWidth: 80 }}>Sr. No</TableCell>
              <TableCell style={{ minWidth: 100 }}>Amount Collected</TableCell>
              <TableCell style={{ minWidth: 100 }}>Installment</TableCell>
              <TableCell style={{ minWidth: 100 }}>Payment Mode</TableCell>
              <TableCell style={{ minWidth: 130 }}>Status</TableCell>
              <TableCell style={{ minWidth: 100 }}>Payment Date</TableCell>
              <TableCell style={{ minWidth: 100 }}>Created By</TableCell>
              <TableCell style={{ minWidth: 100 }}>Approved By</TableCell>
              <TableCell style={{ minWidth: 100 }}>Approved Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.payment?.map((row,index) => (
              <TableRow key={row.id} style={{
                backgroundColor:
                  index % 2 === 0 ? "#f0f0f0" : "inherit",
                width: "6%",
              }}>
                 <TableCell >{row?.sl_no}</TableCell>
                <TableCell
                  
                  sx={{textAlign: 'center'}}
                >{row?.amount_collected} /-</TableCell>
                 
                <TableCell >{row?.installment}</TableCell>
                <TableCell >{row?.payment_mode?.payment_mode}</TableCell>
                <TableCell >
                  {row?.status === "pending" && (
                    <Chip variant="contained" size="small" label="Pending" sx={{background:'#FFAC1C'}}/>
                  )}
                  {row?.status === "confirmed" && (
                    <Chip variant="contained" size="small" label="Approved" color="secondary" />
                  )}
                   {row?.status === "failed" && (
                    <Chip variant="contained" size="small" label="Failed " color="error" />
                  )}
                    {row?.status === "cancelled" && (
                    <Chip variant="contained" size="small" label="Cancelled  " color="error" />
                  )}
                </TableCell>
                <TableCell >{row?.payment_date}</TableCell>
                <TableCell >{row?.collected_by?.first_name} {row?.collected_by?.last_name}</TableCell>
                <TableCell >{row?.approved_by?.first_name} {row?.approved_by?.last_name}</TableCell>
                <TableCell >{row?.approved_date} </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Grid>
  );
}
