import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { fDate } from "../../../utils/formatTime";

export default function AllLeadSourceData({
  onClose,
  data,
  chosenDate,
  sourcename,
  SubsourceLoading,
}) {
 
  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight: "400px", // Set a fixed height
    overflow: "auto", // Enable scrolling
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "1px",
      fontSize: "11px",
      color: "#000",
      textAlign: "center",
      fontFamily: "Roboto",
    },
    "& .MuiTableCell-body": {
      textAlign: "center",
      padding: "8px 16px",
      overflow: "hidden", // Hide overflowing content
      textOverflow: "ellipsis", // Show ellipsis when content overflows
      fontFamily: "Roboto",
      fontSize: "11px",
      color: "#666666",
      whiteSpace: "normal", // Allow text to wrap inside the cell
      wordWrap: "break-word",
    },
    "& .MuiTableCell-body:first-of-type": {
      borderLeft: "1px solid #ddd", // Add left border to the first column
    },
    "& .MuiTableCell-body:last-of-type": {
      borderRight: "1px solid #ddd", // Add right border to the last column
    },
  }));

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
    }).format(date);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} textAlign="center">
          <Typography
            style={{
              fontFamily: "Roboto",
              fontWeight: 500,
              fontSize: "17px",
              color: "#000000",
            }}
          >
            Sub Lead Source Breakdown For {sourcename} Source (
            {formatDate(chosenDate)})
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography> </Typography>
        </Grid>

        <Grid item xs={12}>
          {SubsourceLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box mt={2}>
              <StyledTableContainer component={Paper}>
                <Table
                  style={{ background: "#F9F9F9", borderRadius: "14px" }}
                  sx={{ tableLayout: "fixed" }}
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Sub Source Type</TableCell>
                      <TableCell>Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.length === 0 ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={2}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "11px",
                            color: "#000000",
                          }}
                        >
                          No data available
                        </Typography>
                      </Box>
                    ) : (
                      data?.map((row, index) => (
                        <TableRow
                          key={index}
                          style={{
                            width: "6%",
                          }}
                        >
                          <TableCell>
                            {row?.source_type}
                          </TableCell>
                          <TableCell>{row?.count}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}
