import {
  Chip,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { fDate } from "../../../utils/formatTime";
import StyledTableContainer from "../../../newTestPrep/newComponent/TableContainer";
import ca from "date-fns/esm/locale/ca/index.js";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AllEventcomp({ data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedEventType, setSelectedEventType] = useState(0);
  const [eventData, setEventData] = useState(data);
  const [campaignTypeList, setCampaignTypeList] = useState([]);

  const handleChangeEvent = (criteria) => {
    setSelectedEventType(criteria);
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = eventData.slice(startIndex, endIndex);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // campaign Type List
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}campaigntype`);
        if (response.ok) {
          const data = await response.json();
      
          setCampaignTypeList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allCampaignTypeList = [{ id: 0, name: "All" }, ...campaignTypeList];

  // Filter Event And Campaign
  useEffect(() => {
    if (selectedEventType !== 0) {
      const filteredevent = data.filter(
        (i) => i.campaign_type_id === selectedEventType
      );
      setEventData(filteredevent);
    } else {
      setEventData(data);
    }
  }, [selectedEventType]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} container gap={2}>
          {allCampaignTypeList.map((campaigntype) => (
            <Chip
              color={
                selectedEventType === campaigntype.id ? "secondary" : undefined
              }
              label={campaigntype?.name}
              variant={
                selectedEventType === campaigntype.id ? "contained" : "outlined"
              }
              size="small"
              selectedEventType={campaigntype.id}
              onClick={() => handleChangeEvent(campaigntype.id)}
            />
          ))}
        </Grid>

        <Grid item xs={12}>
          <StyledTableContainer component={Paper} background="#abf7b1">
            <Table
              stickyHeader
              // sx={{ minWidth: 650, tableLayout: "fixed" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Campaign Name</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                </TableRow>
              </TableHead>

              {eventData?.length === 0 ? (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">No data available</Typography>
                </Grid>
              ) : (
                paginatedData?.map((row, index) => (
                  <TableBody>
                    <TableRow
                      key={row.name}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#f0f0f0" : "inherit",
                      }}
                    >
                      <TableCell>{row?.campaign_name}</TableCell>
                      <TableCell> {fDate(row?.campaign_start_date)} </TableCell>
                      <TableCell> {fDate(row?.campaign_end_date)} </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              )}
            </Table>
            <TablePagination
              size="0rem"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={eventData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                ".MuiTablePagination-toolbar": { fontSize: "0.75rem" }, // Adjust toolbar text size
                ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                  {
                    fontSize: "0.75rem", // Adjust labels and displayed rows text size
                  },
                ".MuiTablePagination-select": {
                  fontSize: "0.75rem", // Adjust dropdown text size
                },
              }}
            />
          </StyledTableContainer>
        </Grid>
      </Grid>
    </>
  );
}
