import React, { useState } from 'react'
import MainCard from '../../../../components/ui-component/card/MainCard'; 
import { Box, Button, Chip, CircularProgress, Grid, Stack, StyledEngineProvider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { CSVExport } from '../../../../components/ui-component/table/CSVExport'; 
import CustomSnackbar from '../../../../components/ui-component/snackbar/CustomSnackbar'; 
import CreatePopup from '../../../../../components/dialog/CreatePopup'; 
import AddAdvisor from './AddAdvisor';
import StyledTableContainer from '../../../../newComponent/TableContainer';


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


function Row({ row, onEdit, index }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleViewSchedule = () => {
        setOpen(true);
      };

    return (
        <>
            <TableRow sx={{backgroundColor:index % 2 === 0 ? "#f0f0f0" : "inherit"}}>
              
                <TableCell >
                    {row.first_name} {row.last_name}
                </TableCell>
                <TableCell >{row.email}</TableCell>
                <TableCell >{row?.mobile}</TableCell>
                <TableCell >{row.gender}</TableCell>
                <TableCell >{row.is_active ? (  <Chip
                              variant="contained"
                              size="small"
                              color="secondary"
                              label="Active"
                            />
                          ) : (
                            <Chip
                              label="Inactive"
                              variant="contained"
                              size="small"
                              color="error"
                            />)}
                            </TableCell>
               {/*} <TableCell >
                <Button
                                    color="secondary"
                                    variant='contained'
                                    size='small'
                                    sx={{
                                        color: theme.palette.dark,
                                        borderColor: theme.palette.orange.main,
                                        '&:hover ': { background: theme.palette.orange.light }
                                    }}
                                    onClick={() => onEdit(row)} 
                                >  
                                    Edit
                                </Button>
                </TableCell>*/}
                
            </TableRow>
            
                               
                                   
                              
           
        </>
    );
}

function AdvisorTable({ advisors, branchId, branchName }) {
    const userId = localStorage.getItem('userId');
    const [rows, setRows] = useState(advisors)
    const [isLoading, setIsLoading] = useState(false)
    const [isAddRoomPopupOpen, setIsAddRoomPopupOpen] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
   
    const [editTrainerInfo, setEditTrainerInfo] = useState(null);
    

  const handleEditTrainer = (trainerInfo) => {
    setEditTrainerInfo(trainerInfo);
    handleAddRoomClick(); // Open the AddTrainer dialog
  };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };

    const handleAddRoomClick = () => {
        setIsAddRoomPopupOpen(true);
      };
    
      const handleAddRoomClose = () => {
        setIsAddRoomPopupOpen(false);
        setEditTrainerInfo(null);
      };

      const handleAddEmployee = (u) => {

        u.branch_id = branchId
        u.user_id = userId

        const editMode = !!editTrainerInfo;

        const advisorId = editTrainerInfo?.id

      /*  if (editMode) {
            const { email, ...updatedUser } = u;
            u = updatedUser;
        }*/

    
    
        const apiUrl = editMode
            ? `${API_ENDPOINT}advisor/${advisorId}`
            : `${API_ENDPOINT}advisor`;
    
        fetch(apiUrl, {
            method: editMode ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(u),
        })
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    showSnackbar(editMode ? 'Advisor updated successfully' : 'Advisor created successfully', 'success');
                    if (editMode) {
                        // If in edit mode, update the specific row
                        setRows((prevRows) =>
                          prevRows.map((row) =>
                            row.id === editTrainerInfo.id ? u : row
                          )
                        );
                      } else {
                        
                        setRows((prevRows) => [...prevRows, u]);
                      }
                     
                      setEditTrainerInfo(null);
                      window.location.reload()
                } else if (response.status === 409) {
                    showSnackbar('Advisor with Email Id Exists', 'error');
                   // setProfile({});
                } else if (response.status === 403) {
                  showSnackbar('Unauthorized to create advisor', 'error');
                 // setProfile({});
              }else if (editMode && response.status === 403) {
                showSnackbar('Unauthorized to update advisor', 'error');
               // setProfile({});
            }
                 else {
                
                    showSnackbar(`Error ${editMode ? 'updating' : 'creating'} advisor`, 'error');
                }
            })
            .catch((error) => {
                console.error(`Error ${editMode ? 'updating' : 'creating'} advisor`, error);
                showSnackbar(`Error ${editMode ? 'updating' : 'creating'} advisor`, 'error');
            });
        }

    
      const handleAddRoom = (newRoom) => {
        // Handle adding the new room to the state or perform API call
        // Assuming setRows is a function to update the rows state
        newRoom.branch_id = branchId
        newRoom.user_id = userId

   

        fetch(`${API_ENDPOINT}room`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newRoom),
          })
          .then((response) => {
            if(response.status === 201) {
               showSnackbar('Room created successfully', 'success');
              // window.location.reload()
              setRows((prevRows) => [...prevRows, newRoom]);
            } else if(response.status === 401) {
                showSnackbar('User unauthorized to create room', 'error');
                //window.location.reload()
             }
            else {
          
              showSnackbar('Error creating room', 'error');
            }
          })
            .catch((error) => {
              console.error('Error assigning admin:', error);
              showSnackbar('Error creating room', 'error');
            });

        
    
        // Close the Add Room popup
        handleAddRoomClose();
      };

  return (
    <>
    <MainCard
       
       
    >
        {/* table */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h3'>Advisors</Typography>
          </Grid>
     
     <Grid item xs={12}>
        <StyledTableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                  
                        <TableCell >Name</TableCell>
                        <TableCell >Email</TableCell>
                        <TableCell >Phone</TableCell>
                        <TableCell >Gender</TableCell>
                        <TableCell >Status</TableCell>
                       
                       
                    </TableRow>
                </TableHead>
                <TableBody>

                {rows.length === 0 ? (
                <TableRow>
                
                  <TableCell colSpan={5} align="center">
                    <Typography variant='h5'>No data available</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((room, index) => (
                  <Row key={room?.id} row={room} onEdit={handleEditTrainer} index={index}/>
                ))
              )}

                  </TableBody>
            </Table>
        </StyledTableContainer>
        </Grid> 
        </Grid>
    </MainCard> 

 {/*} <CreatePopup 
        open={isAddRoomPopupOpen} 
        onClose={handleAddRoomClose}
        component={<AddAdvisor onSubmit={handleAddEmployee} branchId={branchId} branchName={branchName} editMode={!!editTrainerInfo} trainerInfo={editTrainerInfo} />}
        >
      </CreatePopup> */}

      <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
    </>
  )
}

export default AdvisorTable