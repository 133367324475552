import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import CustomAutocompleteComp from '../../newComponent/AutocompleteComp'
import CustomTextFieldComp from '../../newComponent/customTextFieldComp'
import { useNavigate } from 'react-router-dom'
import { useGetTokenOrLogout } from '../../../utils/token'
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
function AddProductTarget({ onClose, advisorId, monthId, year}) {
    const navigate = useNavigate();
      const getTokenOrLogout = useGetTokenOrLogout();
      const userId = localStorage.getItem("userId");
      const [testTypeList, setTestTypeList] = useState([]);
      const [testType, setTestType] = useState([]);
      const [snackbarOpen, setSnackbarOpen] = React.useState(false);
        const [snackbarMessage, setSnackbarMessage] = React.useState("");
        const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
      const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };

      const {
          handleSubmit,
          control,
          setValue,
          formState: { errors },
          setError,
          reset,
        } = useForm();

      useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await fetch(`${API_ENDPOINT}product`);
              if (response.ok) {
                const data = await response.json();
      
                setTestTypeList(data);
              } else {
                throw new Error(`Request failed with status: ${response.status}`);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
          fetchData();
        }, []);

        const handleTestTypeChange = (event, value) => {
            setTestType(value);
            setValue(
              "product_ids",
              value.map((i) => i.id)
            );
          };
        
          const onSubmit = async (data) => {
            const token = getTokenOrLogout();
            if (!token) return;
            let headers = {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            };
            try {
              
              const productTargets = testType.map((product, index) => ({
                product_id: product.id, 
                rev_target: parseInt(data.rev_target?.[product.id] || 0, 10), // Get revenue target
                enroll_target: parseInt(data.enroll_target?.[product.id] || 0, 10), // Get enrollment target
              }));
          
              const requestData = {
                adv_user_id: advisorId?.id,
                month_id: monthId?.id,
                year: year,
                user_id: parseInt(userId, 10),
                product_targets: productTargets,
              };
        
              const response = await fetch(`${API_ENDPOINT}advisor-rev-target/addproducttarget`, {
                method: "POST",
                headers,
                body: JSON.stringify(requestData),
              });
              if (response.ok) {
                showSnackbar("Product Target Added Successfully", "success");
                onClose();
              } else if (response.status === 404) {
                navigate("/page404");
              } else if (response.status === 401) {
                navigate("/page401");
              } else if (response.status === 403) {
                navigate("/page403");
              } else if (response.status === 440) {
                navigate("/page440");
              } else {
                showSnackbar("Error in add employee target",'error');
              }
            } catch (error) {
              showSnackbar("Error in add employee target",'error');
            }
          };
        

  return (
    <>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography  variant="h6">
            {" "}
            Select Product{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="product_ids"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomAutocompleteComp
                {...field}
                size="small"
                multiple
                value={testType}
                onChange={handleTestTypeChange}
                options={testTypeList}
                getOptionLabel={(option) => option.name}
                error={errors.product_ids}
                helperText={errors.product_ids && "Product is required"}
                tooltipContent={(option) => option.name}
              />
            )}
          ></Controller>
        </Grid>
        
        <Box mt={5} />
        {testType.map((product, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={12} sm={4}>
          <Typography variant="h6">
            {" "}
            Product Name{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography> 
          <Controller
            name="product_id"
            control={control}
            //rules={{ required: true }}
            render={({ field }) => (
                  <CustomTextFieldComp
                    {...field}
                    value={product.name}
                    fullWidth
                    size="normal"
                    error={errors.product_id}
                    helperText={errors.product && "Product is required"}
                    InputProps={{
                      readOnly: true, 
                      style: { borderRadius: "14px", backgroundColor: "#f0f0f0" }, 
                    }}
                    
                  />
               
            )}
          ></Controller>
            
          </Grid>
          <Grid item xs={12} sm={4}>
          <Typography  variant="h6">
            Add Revenue Target{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name={`rev_target.${product.id}`} 
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextFieldComp
                size="normal"
                {...field}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                
                onChange={(e) =>
                  field.onChange(parseInt(e.target.value, 10) || 0)
                } 
                fullWidth
                error={!!errors.rev_target?.[product.id]}
                helperText={errors.rev_target?.[product.id] && "Revenue Target is required"}
              />
            )}
          />
          </Grid>
          <Grid item xs={12} sm={4}>
          <Typography  variant="h6">
            Add Enrolment Target{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name={`enroll_target.${product.id}`} 
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextFieldComp
                size="normal"
                {...field}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                onChange={(e) =>
                  field.onChange(parseInt(e.target.value, 10) || 0)
                } 
                fullWidth
                error={!!errors.enroll_target?.[product.id]}
                helperText={errors.enroll_target?.[product.id] && "Enrolment Target is required"}
              />
            )}
          />
          </Grid>
        </Grid>
      ))}

        <Grid item xs={12} textAlign="end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  )
}

export default AddProductTarget