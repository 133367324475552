import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ToggleButtontheme from "../../AllUserDashboards/Components/TogglebuttonTheme";
import ProductBaseDetails from "./ProductBaseDetails";
import PrductBaseRvenueStats from "./PrductBaseRvenueStats";
import ProductBaseEnrollStats from "./ProductBaseEnrollStats";
import FilterButtonComponent from "../../../newTestPrep/newComponent/NewButtonComp/FilterButtonComponent";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function ProductBaseEnrollRevenueStat({
  productWiseData,
  isproductloading,
  selectedFilter,
  fromDate,
  toDate,
  advisorId,
}) {
  const [statsFilter, setStatsFilter] = useState("details");
  const [productList, setProductList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterdData, setFilterdData] = useState([]);

  const handleApplyStatsFilter = (criteria) => {
    setStatsFilter(criteria);
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}testtype`)
      .then((response) => response.json())
      .then((data) => {
        setProductList(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  const handleFilterButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const allProductList = [
    {
      id: 0,
      name:
        selectedProducts?.length === productList?.length &&
        productList?.length > 0
          ? "Deselect All"
          : "Select All",
    },
    ...productList,
  ];

  const handleBranchSelection = (productId) => {
    if (productId === 0) {
      // "Select All" logic
      if (selectedProducts.length === productList.length) {
        // Deselect all if already all selected
        setSelectedProducts([]);
      } else {
        // Select all branches
        const productId = productList.map((branch) => branch.id);
        setSelectedProducts(productId);
      }
    } else {
      // Select/Deselect individual branch
      setSelectedProducts((prevSelectedBranches) => {
        if (prevSelectedBranches.includes(productId)) {
          return prevSelectedBranches.filter((id) => id !== productId);
        } else {
          return [...prevSelectedBranches, productId];
        }
      });
    }
  };

  useEffect(() => {
    const filterdData = productWiseData.filter((item) =>
      selectedProducts.includes(item.test_type_id?.id)
    );
 
    setFilterdData(filterdData);
  }, [selectedProducts, productWiseData]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Typography variant="subtitle1">
                Total No. of Products: {productWiseData?.length}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} textAlign="end" md={6} lg={8}>
              <Stack direction="row" justifyContent="flex-end" mt={1} gap={1}>
                <ThemeProvider theme={ToggleButtontheme}>
                  <ToggleButtonGroup
                    size="small"
                    exclusive
                    style={{ height: "20px" }}
                  >
                    <ToggleButton
                      onClick={() => handleApplyStatsFilter("revenuestat")}
                      style={{
                        background:
                          statsFilter === "revenuestat" ? "#00B13F" : "",
                        color:
                          statsFilter === "revenuestat" ? "#FFFFFF" : "#4b4b4b",
                      }}
                    >
                      Revenue Stats
                    </ToggleButton>
                    <ToggleButton
                      onClick={() => handleApplyStatsFilter("enrolstat")}
                      style={{
                        background:
                          statsFilter === "enrolstat" ? "#00B13F" : "",
                        color:
                          statsFilter === "enrolstat" ? "#FFFFFF" : "#4b4b4b",
                      }}
                    >
                      Enrolment Stats
                    </ToggleButton>
                    <ToggleButton
                      onClick={() => handleApplyStatsFilter("details")}
                      style={{
                        background: statsFilter === "details" ? "#00B13F" : "",
                        color:
                          statsFilter === "details" ? "#FFFFFF" : "#4b4b4b",
                      }}
                    >
                      Details
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        {isproductloading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
            width="100%"
            height="100%"
          >
            <CircularProgress size="2rem" />
          </Box>
        ) : (
          <Box
            sx={{
              background: "#F9F9F9",
              width: "100%",
              borderRadius: "14px",
              height: "100%",
            }}
            mt={1}
          >
            <Grid item xs={12} textAlign="end" mt={1} mb={1} mr={1}>
              <div style={{ position: "relative" }}>
                <FilterButtonComponent
                  onClick={handleFilterButtonClick}
                  text="Show All Products"
                />

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleFilterClose}
                  sx={{
                    "& .MuiPaper-root": {
                      maxHeight: "300px", // Maximum height for scrolling
                      overflowY: "auto", // Enable scrolling
                      minWidth: "200px", // Ensure menu width is reasonable
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom", // Align below the button
                    horizontal: "left", // Align left edge of the menu to the button
                  }}
                  transformOrigin={{
                    vertical: "top", // Start menu alignment from the top
                    horizontal: "left", // Align left edge of the menu
                  }}
                >
                  {allProductList.map((product) => (
                    <MenuItem
                      key={product.id}
                      onClick={() => handleBranchSelection(product.id)}
                      sx={{
                        fontSize: "10px",
                        height: "40px",
                      }}
                    >
                      <Checkbox
                        size="small"
                        checked={
                          product.id === 0
                            ? selectedProducts.length === productList.length &&
                              productList.length > 0
                            : selectedProducts.includes(product.id)
                        }
                        indeterminate={
                          product.id === 0 &&
                          selectedProducts.length > 0 &&
                          selectedProducts.length < productList.length
                        }
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent MenuItem click from firing
                          handleBranchSelection(product.id);
                        }}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: "16px" },
                        }}
                      />
                      <ListItemText
                        primary={product.name}
                        primaryTypographyProps={{ style: { fontSize: "10px" } }}
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </Grid>

            <Grid item xs={12} mt={2}>
              {statsFilter === "details" && (
                <ProductBaseDetails
                  selectedFilter={selectedFilter}
                  fromDate={fromDate}
                  toDate={toDate}
                  data={
                    selectedProducts.length === 0
                      ? productWiseData
                      : filterdData
                  }
                  advisorId={advisorId}
                />
              )}
              {statsFilter === "revenuestat" && (
                <PrductBaseRvenueStats
                  data={
                    selectedProducts.length === 0
                      ? productWiseData
                      : filterdData
                  }
                />
              )}
              {statsFilter === "enrolstat" && (
                <ProductBaseEnrollStats
                  data={
                    selectedProducts.length === 0
                      ? productWiseData
                      : filterdData
                  }
                />
              )}
            </Grid>
          </Box>
        )}
      </Grid>
    </>
  );
}
