import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";

import AllEventcomp from "./AllEventcomp";
import UpcommingEventBox from "./UpcommingEventBox";
import NewCardcomp from "../../../components/card/NewCardcomp";
import CustomPopup from "../../../components/CustomPopup";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function UpcommingEvents() {
  const [upcommigevent, setUpcommingEvent] = useState([]);
  const [upcommingLoading, setUpcommingLoding] = useState(false);
  const userId = localStorage.getItem("userId");
  const getTokenOrLogout = useGetTokenOrLogout();

  // Fetch Upcomming Event
  useEffect(() => {
    setUpcommingLoding(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/ongoing-upcomingevent/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          setUpcommingEvent(data);
          setUpcommingLoding(false);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
          setUpcommingLoding(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setUpcommingLoding(false);
      }
    };

    fetchData();
  }, []);
  const events = Array.isArray(upcommigevent) ? upcommigevent : []; // Ensure `data` is an array
  const totalEvents = events.length;

  const scrollContainerRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [openallEventPopup, setopenAllEventPopup] = useState(false);

  useEffect(() => {
    if (totalEvents > 2) {
      const container = scrollContainerRef.current;

      let scrollAmount = 0; // Tracks the current scroll position
      const eventHeight = 80; // Height of one event including margin
      const scrollSpeed = 15; // Speed of scrolling (lower value = faster)

      const scrollInterval = setInterval(() => {
        if (!isPaused) {
          scrollAmount += 1;
          container.scrollTop = scrollAmount;

          // Reset scroll position seamlessly when reaching the end
          if (scrollAmount >= container.scrollHeight / 2) {
            scrollAmount = 0; // Reset to start point
            container.scrollTop = 0;
          }
        }
      }, scrollSpeed);

      return () => clearInterval(scrollInterval); // Cleanup on unmount
    }
  }, [totalEvents, isPaused]);

  const handlePause = () => setIsPaused(true);
  const handleResume = () => setIsPaused(false);

  const handleOpenPopup = () => {
    setopenAllEventPopup(true);
  };

  const handleClosePopup = () => {
    setopenAllEventPopup(false);
  };

  return (
    <>
      <NewCardcomp>
        <Box display="flex" gap={1}>
          <Box>
            <img src="/dashboardimages/Group (9).png" alt="Upcoming Events" />
          </Box>
          <Box sx={{ cursor: "pointer" }}>
            <Tooltip title=" Running & Upcoming Events And Campaigns" arrow>
              <Typography
                variant="h5"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  color: "#4D4D4D",
                  textOverflow: "ellipsis",
                  maxWidth: {
                    lg: "200px",
                    md: "150px",
                  }, // You can set the width you want to limit the address
                }}
              >
                Running & Upcoming Events And Campaigns
              </Typography>
            </Tooltip>
          </Box>
        </Box>

        {upcommingLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
            width="100%"
          >
            <CircularProgress size="2rem" />
          </Box>
        ) : (
          <Box
            ref={scrollContainerRef}
            sx={{
              overflow: "hidden",
              height: "130px", // Adjust to fit two events
              position: "relative",
            }}
            onMouseEnter={handlePause}
            onMouseLeave={handleResume}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {[...events, ...events].map((event, index) => (
                <Box
                  key={index} // Use index for unique keys in duplicated list
                  sx={{
                    height: "65px", // Event height
                    marginBottom: "5px", // Space between events
                  }}
                >
                  <UpcommingEventBox
                    background={index % 2 === 0 ? "#FFEDFB" : "#E7F3FF"}
                    borderLeftcolor={index % 2 === 0 ? "#FD4CDA" : "#4057E1"}
                    eventData={event}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )}

        <Box mt={2} textAlign="center">
          <Button
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              fontFamily: "Roboto",
              color: "#00B13F",
              textAlign: "center",
            }}
            onClick={handleOpenPopup}
          >
            Show All
          </Button>
        </Box>
      </NewCardcomp>
      <CustomPopup
        open={openallEventPopup}
        onClose={handleClosePopup}
        title="Running & Upcoming Event And Campaign Details"
        component={
          <AllEventcomp onClose={handleClosePopup} data={upcommigevent} />
        }
        maxWidth="md"
        showDivider={true}
      />
    </>
  );
}
