import { useEffect, useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
  ButtonBase,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/features/auth/authSlice";
import { clearUserData } from "../../redux/features/user/userSlice";
import { useGetTokenOrLogout } from "../../utils/token";
//import { setStudentData } from '../../redux/features/student/studentSlice';
// ----------------------------------------------------------------------
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const MENU_OPTIONS = [
  /* {
    label: 'Home',
    icon: 'eva:home-fill',
  },*/
  {
    label: "My Dashboard",
    icon: "eva:person-fill",
  },
  /*{
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },*/
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const getTokenOrLogout = useGetTokenOrLogout();
  const [open, setOpen] = useState(null);
  const [userData, setUserData] = useState({});
  const userId = parseInt(localStorage.getItem("userId"));
  let userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { firstName, lastName, email, role } = useSelector(
    (state) => state.user
  );

 
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMenuClick = (label) => {
    handleClose();
    if (label === "Home") {
      navigate("/login");
    } else if (label === "My Dashboard") {
      navigate("/dashboard");
      // if (role === 'advisor') {
      //     navigate('/advisor/dashboard');
      //   } else if (role === 'admin') {
      //     navigate('/admin/dashboard');
      //   } else if (role === 'trainer') {
      //     navigate('/trainer/dashboard');
      //   }
    } else {
      handleClose();
    }
  };

  const handleLogout = () => {
    dispatch(logout());

    dispatch(clearUserData());

    //dispatch(setStudentData([]))

    navigate("/");
    // window.location.reload();
  };
  useEffect(() => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}employee/${userId}`, {
          headers,
        });
        if (response.ok) {
          const data = await response.json();
   

          setUserData(data);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        navigate("/page500");
      }
    };

    fetchData();
  }, []);

  const handleEdit = () => {
    const newState = { employeedetails: userData };
    localStorage.setItem("employeeState", JSON.stringify(newState));

    const url = `/view-profile/${userData.id}`;
    const newTab = window.open(url, "_blank");

    if (!newTab) {
      navigate(url, { state: newState });
    }
  };
  return (
    <>
      {/*<ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.dark,
              color: '#fff',
              //color: theme.palette.secondary.light,
              '&:hover': {
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark
              }
            }}
            onClick={handleOpen}
            color="inherit"
          >
            
          </Avatar>
        </ButtonBase>*/}

      <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
        <Avatar
          variant="rounded"
          sx={{
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={handleOpen}
          color="inherit"
        >
          <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
            <Avatar
              variant="rounded"
              sx={{
                color: "#787878",
                width: 40,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              onClick={handleOpen}
              color="inherit"
            ></Avatar>
          </ButtonBase>
        </Avatar>
        {/* <Avatar  onClick={handleOpen}
                alt="User Image"
                src={userData?.gender === "Female" ? "/logo/woman.png" : "/logo/profile.png"}
                sx={{ width: 40, height: 40 }}
              /> */}
      </ButtonBase>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userData?.first_name} {userData?.last_name}
          </Typography>
          <Typography variant="h6" sx={{ color: "text.secondary" }} noWrap>
            {userData?.email}
          </Typography>
          <Typography variant="h6" sx={{ color: "text.secondary" }} noWrap>
            {userRole && (
              <Typography variant="h6" color="textSecondary">
                {userRole === "data_entry"
                  ? "Data Entry"
                  : userRole === "advisor"
                  ? "Advisor"
                  : userRole === "trainer"
                  ? "Trainer"
                  : userRole === "admin"
                      ? "Admin"
                      : userRole === "center_admin"
                      ? "Center Admin"
                      : userRole === "accountant"
                      ? "Accountant"
                      : ""}
              </Typography>
            )}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {userData?.branch?.branch_name}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack >
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleMenuClick(option.label)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />
        <Stack >
        <MenuItem onClick={handleEdit}>
          Edit Profile
        </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />
        <Stack >
        <MenuItem  onClick={handleLogout}>
          Logout
        </MenuItem>
        </Stack>
      </Popover>
    </>
  );
}
