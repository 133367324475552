import { TableContainer } from '@mui/material';
import { styled } from '@mui/system';



const StyledTableContainer = styled(TableContainer)(({ theme , cellTextcolor , borderLeft }) => ({
  overflowX: "auto", // Enable horizontal scrolling when needed
  "& .MuiTableCell-head": {
    backgroundColor: "#abf7b1",
    fontWeight: 500,
    padding: "1px",
    fontSize: "11px",
    color: "#000",
   
 
    textAlign: "center",
    fontFamily: "Roboto",
    ...(borderLeft && {
      borderLeft: "0.1px solid #C3C3C3",
    }),
  },
  "& .MuiTableCell-body": {
    textAlign: "center",
    fontWeight: 500,
    padding: "4px 1px",
    overflow: "hidden", // Hide overflowing content
    textOverflow: "ellipsis", // Show ellipsis when content overflows
    fontFamily: "Roboto",
    fontSize: "11px",
    ...(borderLeft && {
      borderLeft: "0.1px solid #C3C3C3",
    }),
    color: cellTextcolor,
    whiteSpace: "normal", // Allow text to wrap inside the cell
    wordWrap: "break-word",
  },
  "& .MuiTableCell-body:first-of-type": {
    borderLeft: "1px solid #ddd", // Add left border to the first column
  },
  "& .MuiTableCell-body:last-of-type": {
    borderRight: "1px solid #ddd", // Add right border to the last column
  },
}));

export default StyledTableContainer;
