// Author: Ayesha Shaikh
// Date : 9 aug 2024

import {
  AppBar,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Html5QrcodeScanner } from "html5-qrcode";
import React, { useEffect, useRef, useState } from "react";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import "./Qrstyle.css";
import { Link, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import RegistrationForm from "./RegistrationForm";
import CustomPopup from "../../../components/CustomPopup";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function RegistrationDesk() {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(null);
  const [scancode, setScanCode] = useState(null);
  const [scanData, setScanData] = useState([]);
  const [showButton, setShowbutton] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOpen = () => {
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };
  console.log("Code matched =", scancode);

  console.log("userid", userId);

  // Qr Scan
  function onScanSuccess(decodedText, decodedResult) {
    // handle the scanned code as you like, for example:

    console.log(`Code matched = ${decodedText}`, decodedResult);
    setScanCode(decodedText);
    postData(userId, decodedText, null);
  }

  function onScanFailure(error) {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
    console.warn(`Code scan error = ${error}`);
  }

  useEffect(() => {
    const html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      { fps: 10, qrbox: { width: 250, height: 250 } },
      false
    );
    html5QrcodeScanner.render(onScanSuccess, onScanFailure);

    // Cleanup function to stop the scanner when the component unmounts
    return () => {
      html5QrcodeScanner.clear();
    };
  }, []);

  const handleMobileChange = (event) => {
    const value = event.target.value;
    setMobile(event.target.value);
    if (value.length === 10) {
      postData(userId, null, value);
    }
  };

  // Post Data
  const postData = async (userId, qrcode, mobile) => {
    const payload = {
      user_id: parseInt(userId),
      qrcode: qrcode || null,
      mobile: mobile || null,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}eventqr/searchqrcode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Response data:", data);
        setScanData(data);
        navigate("/scanqr-code", { state: { scanData: data } });
      } else if (response.status === 403) {
        showSnackbar("Unknown User", "error");
      } else if (response.status === 405) {
        showSnackbar("Enter valid QR code or mobile", "error");
      } else if (response.status === 404) {
        showSnackbar(
          "QR code or enquiry not found , Please Register First ",
          "error"
        );
        setShowbutton(true);
      } else {
        showSnackbar("Error Posting Data", "error");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      showSnackbar("Error Posting Data", "error");
    }
  };

  return (
    <>
      <AppBar
        sx={{
          bgcolor: "white",
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1000,
          boxShadow: " 0px 4px 5px 0px rgba(0, 0, 0, 0.3);",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {" "}
            <img src="/Siec Logo.png" width={150} />
          </Box>
          <Box>
            <Link to="/dashboard">
              <Button
                variant="contained"
                size="small"
                style={{
                  borderRadius: "25px",
                  color: "white",
                  background: "#21b13f",
                }}
                startIcon={<DashboardIcon />}
              >
                Dashboard
              </Button>
            </Link>
            <Link to="/">
              {" "}
              <Button
                size="small"
                variant="contained"
                style={{ borderRadius: "25px", background: "#ff4b4b" }}
                startIcon={<img src="/icons8-move-up-64.png" width="20px" />}
              >
                Logout
              </Button>
            </Link>
          </Box>
        </Box>
      </AppBar>
      <Box
        sx={{
          background: "#e7f6e7",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          minHeight: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%",
            xl: "100vh",
          },
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          zIndex: 1,
          position: "relative",
        }}
        mt={4}
      >
        <Grid
          container
          spacing={4}
          sx={{
            zIndex: 1,
          }}
        >
          {/* Registration Header */}

          <Grid item xs={12}>
            <Typography variant="h2">Registration Desk</Typography>
          </Grid>

          {/* TextField and Button */}
          {/* <Grid item xs={12} mt={2}>
          <Grid container justifyContent="center" alignItems="center" gap={2}>
            <CustomTextFieldComp
              size="Medium"
              placeholder="Search Phone Number"
              type="number"
              onChange={handleMobileChange}
              value={mobile}
              InputProps={{ style: { borderRadius: "10px", width: "300px" } }}
            />
            {showButton && (
              <Button
        style={{background:"#00b13f"}}
                variant="contained"
                onClick={handleOpen}
              >
                Register Now
              </Button>
            )}
          </Grid>
        </Grid> */}

          {/* Paper Section */}
          <Grid
            item
            xs={12}
            lg={12}
            sm={10}
            md={10}
            sx={{ mx: { xs: 3, sm: 5, md: 8, lg: 40 } }}
          >
            <Paper sx={{ padding: "20px", borderRadius: "15px" }} elevation={3}>
              <Box id="reader"></Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <CustomPopup
        open={openForm}
        onClose={handleClose}
        component={
          <RegistrationForm showSnackbar={showSnackbar} mobile={mobile} />
        }
        maxWidth="sm"
      />
    </>
  );
}
