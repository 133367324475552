// Author: Ayesha Shaikh
// Date : 13 aug 2024

import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EditExamDetetails({ data, counsellingId }) {
  const [examList, setExamlist] = useState([]);
  const [examName, setexamName] = useState(data?.exam_status_id?.id || null);
  const [examdate, setExamDate] = useState(data?.tentative_exam_date || null);
  const [score, setScore] = useState(data?.target_score || 0);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  console.log("score", score);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}examstatus`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setExamlist(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChangeStatus = (e) => {
    setexamName(e.target.value);
  };

  const handleDateChange = (e) => {
    setExamDate(e.target.value);
  };

  const handleScoreChange = (e) => {
    setScore(e.target.value);
  };

  const handleSave = async () => {
    try {
      const method = data?.id ? "PUT" : "POST";
      const endpoint = data?.id 
        ? `${API_ENDPOINT}eventcounsellingenquirydetails/${data.id}` 
        : `${API_ENDPOINT}eventcounsellingenquirydetails`;
  
      const response = await fetch(`${API_ENDPOINT}eventcounsellingenquirydetails/updatewithcounsellingid`, {
        method : "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          event_counselling_id: counsellingId,
          exam_status_id: examName,
          tentative_exam_date: examdate,
          target_score: score
        }),
      });
  
      if (response.ok) {
        showSnackbar("Data saved successfully");
        setTimeout(() => window.location.reload(), 1500);
      } else {
        const errorMessage = response.status === 404 ? "Not updated" : "Server error";
        showSnackbar(errorMessage, "error");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      showSnackbar("Server error", "error");
    }
  };

  return (
    <>
      <Grid container mt={2} spacing={2}>
        <Grid item xs={6} lg={4} sm={6} md={6}>
          <Typography> Exam Status</Typography>
          <Select
            fullWidth
            size="small"
            sx={{ borderRadius: "10px" }}
            value={examName}
            onChange={handleChangeStatus}
          >
            {examList.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.exam_status_name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6} lg={4} sm={6} md={6}>
          <Typography> Date Of Exam</Typography>
          <TextField
            fullWidth
            size="small"
            type="date"
            InputProps={{
              style: { borderRadius: "10px" },
            }}
            value={examdate}
            onChange={handleDateChange}
          />
        </Grid>

        <Grid item xs={6} lg={4} sm={6} md={6}>
          <Typography> Target Score</Typography>
          <TextField
            fullWidth
            type="number"
            size="small"
            InputProps={{
              style: { borderRadius: "10px" },
            }}
            value={score}
            onChange={handleScoreChange}
          />
        </Grid>

        <Grid item xs={12} lg={12} sm={12} md={12} textAlign="end">
          <Button style={{background:"#00b13f"}} variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
