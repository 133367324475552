import {
  Backdrop,
  Chip,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HeadingCard from "../../../newComponent/NewCardsComp/HeadingCard";
import MainCard from "../../../../components/Maincard";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../newComponent/TableCustomeComp/CustomNoRowsOverlay";
import FilterButtonComponent from "../../../newComponent/NewButtonComp/FilterButtonComponent";
import PendingPaymentFilter from "./PendingPaymentFilter";
import SearchTextFieldComp from "../../../newComponent/newtextfieldComp/SearchTextFieldComp";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../../../utils/token";
import CustomSnackbar from "../../../components/ui-component/snackbar/CustomSnackbar";
import { CSVExport } from "../../../components/ui-component/table/CSVExport";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function AllPendingPaymnts() {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const [pendingPaymentData, setPendingPaymentData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleToggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  // Fetch Pending Payment Api

  useEffect(() => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}pendingpayment/fetchpendingpayments/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();

          setPendingPaymentData(data);
          setSearchData(data);
          setOriginalData(data);
          setIsLoading(false);
        } else if (response.status === 404) {
          showSnackbar(
            "Couldn't find any due or overdue pending payments",
            "error"
          );
          setIsLoading(false);
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId]);


  useEffect(() => {
      const exportData = pendingPaymentData.map((row) => ({
        enquiry_name: `${row?.enquiry_id?.first_name} ${
          row?.enquiry_id?.last_name ? row?.enquiry_id?.last_name : ""
        }`.trim(),
        mobile: row.enquiry_id?.mobile || "",
        class: row?.class_id?.name || "",
        package: row?.package_id?.package_name || "",
        branch_enrolled: row?.branch_id?.branch_name || "",
        pending_amount: row?.pending_amount || 0,
        created_date: `${row?.created_date || ""}`.trim(),
        due_date: `${row?.due_date || ""}`.trim(),
        collected_by: `${row?.collected_by?.first_name} ${
          row?.collected_by?.last_name ? row?.collected_by?.last_name : ""
        }`.trim(),
        branch_collected_by: row?.branch_collected_by?.branch_name || "",
        payment_status: row?.payment_status || "",
        payment_date: row?.payment_date || "",
      }));
  
      setExportData(exportData);
    }, [pendingPaymentData]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.enquiry_id?.first_name || ""} ${
          params?.row?.enquiry_id?.last_name || ""
        }`,
        renderCell: (params) => {
          const isClickable = ["advisor", "trainer", "center-admin", "admin"].includes(userRole);
          
          return (
            <div
              style={{
                color: isClickable ? "#0096FF" : "#000",
                cursor: isClickable ? "pointer" : "default",
              }}
              onClick={
                isClickable
                  ? () => window.open(`/enquirydetails/${params?.row.enquiry_id?.id}`, "_blank")
                  : undefined
              }
            >
              {`${params?.row?.enquiry_id?.first_name || ""} ${
                params?.row?.enquiry_id?.last_name || ""
              }`}
            </div>
          );
        },
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 100,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row?.enquiry_id?.first_name || "",
      renderCell: (params) => <div>{params?.row.enquiry_id?.mobile}</div>,
    },
    {
      field: "class_id",
      headerName: "Class Name",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row?.class_id?.name || "",
      renderCell: (params) => <div>{params?.row?.class_id?.name}</div>,
    },
    {
      field: "package_id",
      headerName: "Package",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row?.package_id?.package_name || "",
      renderCell: (params) => (
        <div>{params?.row?.package_id?.package_name}</div>
      ),
    },
    {
      field: "class_branch_id",
      headerName: "Branch Enrolled",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row?.branch_id?.branch_name || "",
      renderCell: (params) => <div>{params?.row?.branch_id?.branch_name}</div>,
    },
    {
      field: "pending_amount",
      headerName: "Pending Amount",
      width: 100,
      headerClassName: "header-cell",
      // renderCell: (params) => <div></div>,
    },
    {
      field: "due_date",
      headerName: "Due Date",
      width: 100,
      headerClassName: "header-cell",
    },
    {
      field: "created_date",
      headerName: "Created Date",
      width: 100,
      headerClassName: "header-cell",
    },
    {
      field: "collected_by",
      headerName: "Collected By",
      width: 150,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.collected_by?.first_name || ""} ${
          params?.row?.collected_by?.last_name || ""
        }`,
      renderCell: (params) => (
        <div>
          {params?.row?.collected_by?.first_name}
          {params?.row?.collected_by?.last_name}
        </div>
      ),
    },
    {
      field: "branch_collected_by",
      headerName: "Branch Collected By",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.branch_collected_by?.branch_name || ""}`,
      renderCell: (params) => (
        <div>{params?.row?.branch_collected_by?.branch_name}</div>
      ),
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {" "}
          {params?.row?.payment_status === "pending" && (
            <Chip
              color="warning"
              label="Pending"
              size="small"
              variant="contained"
            />
          )}
          {params?.row?.payment_status === "paid" && (
            <Chip
              color="secondary"
              label="Paid"
              size="small"
              variant="contained"
            />
          )}
          {params?.row?.payment_status === "partially-paid" && (
            <Chip
              style={{ background: "orange" }}
              label="Partially-Paid"
              size="small"
              variant="contained"
            />
          )}
          {params?.row?.payment_status === "dropped" && (
            <Chip
              color="error"
              label="Dropped"
              size="small"
              variant="contained"
            />
          )}
        </div>
      ),
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      width: 100,
      headerClassName: "header-cell",
    },
    {
      field: "reason_for_drop",
      headerName: "Reason for drop",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row?.reason_for_drop?.reason_for_drop || "",
      renderCell: (params) => (
        <div>{params?.row?.reason_for_drop?.reason_for_drop}</div>
      ),
    },
    {
      field: "dropped_date",
      headerName: "Dropped Date",
      width: 100,
      headerClassName: "header-cell",
    },
  ];

  // Search All column data

  const handleSearchByName = (e) => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, ""); // Normalize search value (lowercase and remove spaces)

    let filteredData1 = searchData.filter((data) => {
      // Check each field for a match with the search value
      const fieldsToSearch = [
        `${data?.enquiry_id?.first_name || ""} ${
          data?.enquiry_id?.last_name || ""
        }`,

        `${data?.enquiry_id?.mobile || ""}`,
        `${data?.class_id?.name}`,
        `${data?.package_id?.package_name}`,
        `${data?.branch_id?.branch_name}`,
        `${data?.pending_amount}`,
        `${data?.due_date}`,
        `${data?.created_date}`,
        `${data?.collected_by?.first_name || ""} ${
          data?.collected_by?.last_name || ""
        }`,
        `${data?.branch_collected_by?.branch_name}`,
        `${data?.payment_status}`,
        `${data?.payment_date}`,
      ];

      // Check if any of the fields contain the search value
      return fieldsToSearch.some((field) =>
        field.toLowerCase().replace(/\s+/g, "").includes(value)
      );
    });

    setPendingPaymentData(filteredData1); // Update the filtered data
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} md={3} lg={2}>
          <HeadingCard label="Pending Payments" />
        </Grid>
        <Grid item xs={12} sm={7} md={9} lg={10} textAlign="end">
          <FilterButtonComponent
            showendIcon={true}
            open={openFilter}
            onClick={handleToggleFilter}
            sx={{ mr: 2 }}
          />
          {(userRole === "accountant" || userRole === "center-admin") && (
            <CSVExport data={exportData} filename="pending-payments.csv" />
          )}
        </Grid>
        

        <Grid item xs={12}>
          <PendingPaymentFilter
            open={openFilter}
            setSearchData={setSearchData}
            setPendingPaymentData={setPendingPaymentData}
            setOriginalData={setOriginalData}
            setIsLoading={setIsLoading}
            showSnackbar={showSnackbar}
            originalData={originalData}
          />
        </Grid>

        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="end">
                <SearchTextFieldComp onChange={handleSearchByName} />
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={pendingPaymentData}
                  columns={columns}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[25, 50, 100]}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
