import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'student_name', headerName: 'Student Name', width: 200 },
  { field: 'start_date', headerName: 'Start Date', width: 150 },
  { field: 'end_date', headerName: 'End Date', width: 150 },
  { field: 'duration', headerName: 'Duration (days)', width: 150 },
  { field: 'status_of_student', headerName: 'Status', width: 150 },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 300,
    renderCell: (params) => (
      <div>
       
        <button >View Assigned Tests</button>
        <button >Study Material</button>
      </div>
    ),
  }
];

const row = [
  { id: 1, name: 'John Doe', startDate: '2023-09-01', endDate: '2023-09-30', duration: 30, status: 'Active' },
  { id: 2, name: 'Jane Smith', startDate: '2023-09-05', endDate: '2023-09-25', duration: 20, status: 'Inactive' },
  // Add more rows as needed
];



const StudentDataGrid = () => {
    const { classId } = useParams();
    const [ rows, setRows] = useState([])
    const [ isLoading, setIsLoading] = useState(true)
  const [filterValues, setFilterValues] = useState({}); // Store filter values

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterValues({ ...filterValues, [name]: value });
  };

  const handleClearFilters = () => {
    setFilterValues({});
  };

  useEffect(() => {
    fetch(`${API_ENDPOINT}trainerdashboard/viewclass/${classId}`)
      .then((res) => res.json())
      .then((data) => {
      
        setRows(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching classes:', error);
        setIsLoading(false);
      });
  }, [classId]);

  const filteredRows = rows.filter((row) => {
    return Object.keys(filterValues).every((field) => {
      const value = filterValues[field];
      if (field === 'startDate' || field === 'endDate') {
        // Parse date values and filter based on date range
        const dateValue = new Date(row[field]);
        const filterDate = new Date(value);
        return !value || dateValue >= filterDate;
      }
      if (field === 'duration') {
        // Parse and filter based on numerical range for duration
        const durationValue = parseInt(value, 10);
        return isNaN(durationValue) || row[field] === durationValue;
      }
      if (field === 'status') {
        // Filter based on text value for status
        return !value || row[field].toLowerCase().includes(value.toLowerCase());
      }
      return !value || String(row[field]).toLowerCase().includes(value.toLowerCase());
    });
  });

  return (
    <div>
      {isLoading ? (
        // Display a loading indicator while fetching data
        <Box display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh">
        <CircularProgress />
        </Box>
      ) : (
        <>
      <div>
        {columns.map((column) => (
          <input
            key={column.field}
            type={
              column.field === 'startDate' || column.field === 'endDate' ? 'date' : 'text'
            }
            name={column.field}
            placeholder={`Filter ${column.headerName}`}
            value={filterValues[column.field] || ''}
            onChange={handleFilterChange}
          />
        ))}
        <button onClick={handleClearFilters}>Clear Filters</button>
      </div>

      
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </div>
      </>
      )}
    </div>
  );
};

export default StudentDataGrid;
