import { createTheme } from "@mui/material/styles";

const CustomAutocompleteTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            fontSize: "0.7rem", // Decrease font size of input text
            height: "27px",
            // overflow: "hidden",
            padding: "1px 2px",
          },

          "& .Mui-focused .MuiInputBase-root": {
            height: "auto", // Adjust height when focused
            // overflow: "visible", // Allow height to expand
          },
          "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            padding: "2.5px",
          },
        },

        listbox: {
          fontSize: "0.7rem", // Decrease font size of dropdown items
          padding: "4px", // Adjust padding between items
        },
        option: {
          fontSize: "0.7rem", // Font size for each option
          padding: "4px 8px", // Adjust padding for each option
        },
        noOptions: {
          fontSize: "0.7rem", // Smaller font size for no options text
          color: "#888", // Optional: customize color for better visibility
          padding: "8px", // Adjust padding around the noOptions text
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: "0.55rem", // Decrease font size of selected items (chips)
          padding: "1px 1px", // Adjust padding inside chips
          borderRadius: "10px", // Customize border radius for a smaller look
          height: "16px",
          width: "100px",
        },
        deleteIcon: {
          fontSize: "0.4rem", // Adjust the size of the delete icon
          margin: "0 4px", // Adjust margin to keep spacing consistent
          width: "13px", // Adjust width of the icon
          height: "12px", // Adjust height of the icon
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "7px",

          "&.MuiInputBase-sizeSmall": {
            height: "27px", // Default height for medium size
            "& .MuiInputBase-input": {
              fontSize: "0.7rem", // Decrease font size for small size input
            },
          },

          "&.MuiInputBase-sizeMedium": {
            height: "30px", // Default height for medium size
          },
          "&.MuiInputBase-sizeLarge": {
            height: "40px", // Default height for large size
          },
          "&.Mui-focused": {
            height: "auto", // Adjust height when focused
          },
        },
        input: {
          fontSize: "0.8rem", // Decrease font size of input text
        },
      },
    },
  },
});

export default CustomAutocompleteTheme;
