import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  InputLabel,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/Maincard";
import { DataGrid } from "@mui/x-data-grid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InfoCard from "../../newTestPrep/pages/Events/InfoCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../utils/token";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import CustomChip from "../../newTestPrep/newComponent/CustomChip/CustomSmallChip";
import CustomSmallChip from "../../newTestPrep/newComponent/CustomChip/CustomSmallChip";
import CustomAutocompleteComp from "../../newTestPrep/newComponent/AutocompleteComp";

import SearchButton from "../../newTestPrep/newComponent/NewButtonComp/SearchButton";
import ResetButtonComp from "../../newTestPrep/newComponent/NewButtonComp/ResetButtonComp";
import CustomTextFieldComp from "../../newTestPrep/newComponent/customTextFieldComp";

export default function CampaignReportFilter({
  open,
  setCampaignLoading,
  campaignLoading,
  setCampaignreportData,
  originalData,
  setcampaignRowData,
}) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();

  const [selectedButton, setSelectedButton] = useState(null);

  const [servicelist, setServiceList] = useState([]);
  const [service, setService] = useState([]);
  const [leadOwnerList, setLeadOwnerList] = useState([]);
  const [leadOwnerName, setLeadOwnerName] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [leadStageList, setLeadstageList] = useState([]);
  const [leadstage, setLeadstage] = useState([]);
  const [prospectiveLevelList, setProspectiveLevelList] = useState([]);
  const [prospectiveLevel, setProspectiveLevel] = useState([]);
  const [countrylist, setcountrylist] = useState([]);
  const [country, setCountry] = useState([]);
  const [leadSourceList, setLeadSourceList] = useState([]);
  const [leadSource, setLeadsource] = useState([]);
  const [statelist, setStatelist] = useState([]);
  const [state, setState] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedcountryId, setselectedCountryId] = useState([]);
  const [selectedStateids, setSelectedStateIds] = useState([]);
  const [nearestBranch, setNearestBranch] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignname, setCampaignName] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [eventFromDate, setEventFromDate] = useState("");
  const [eventToDate, setEventToDate] = useState("");
  const [loading, setloading] = useState(false);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setTodate] = useState("");
  const [createdbylist, setCreatedbylist] = useState([]);
  const [createdby, setCreatedby] = useState([]);
  const [subServiceName, setSubServiceName] = useState([]);
  const [subServiceList, setSubsubServiceList] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const noOptionsText = "Loading...";

  const years = Array.from(
    { length: 5 },
    (_, index) => new Date().getFullYear() - 3 + index
  );

  const [selectedYear, setSelectedYear] = useState(null);

  const handleselectedYear = async (value) => {
    setSelectedYear(value);

    getCampaignData(selectedButton, null, value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();

          setSubsubServiceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allSubservice = [{ name: "Select All", id: 0 }, ...subServiceList];

  // handle subservice
  const handleChangeSubservice = (id) => {
    setSelectedButton(null);
    setCampaignList([]);
    setCampaignName([]);
    if (id === 0) {
      // Handle "Select All"
      setSubServiceName(
        (prevSelected) =>
          prevSelected.length === subServiceList.length
            ? [] // Deselect all
            : subServiceList.map((subservice) => subservice.id) // Select all
      );
    } else {
      // Handle individual subservice selection
      setSubServiceName(
        (prevSelected) =>
          prevSelected.includes(id)
            ? prevSelected.filter((subserviceId) => subserviceId !== id) // Remove ID if already selected
            : [...prevSelected, id] // Add ID if not selected
      );
    }
  };

  const handleChipClick = async (criteria) => {
    setSelectedButton(criteria);
    setCampaignList([]);
    setSelectedYear("");

    setSelectedDuration("");
  };

  const handlDurationchange = async (criteria) => {
    setSelectedDuration(criteria);
    if (criteria === "customyear" || criteria === "customdate") {
      return;
    }
    setSelectedYear("");
    getCampaignData(selectedButton, criteria, null, null, null);
  };

  const handleChangeEventFromdate = (e) => {
    setEventFromDate(e.target.value);
  };

  const handleChangeEventTodate = (e) => {
    setEventToDate(e.target.value);
    if (eventFromDate !== "") {
      getCampaignData(
        selectedButton,
        selectedDuration,
        null,
        eventFromDate,
        e.target.value
      );
    }
  };

  const getCampaignData = async (
    selectedButton,
    duration,
    criteria,
    fromDate,
    toDate
  ) => {
    setloading(true);

    const requestData = {
      campaign_type: selectedButton,
      duration: duration,
      year: criteria,
      sub_service_id: subServiceName,
      from_date: fromDate,
      to_date: toDate,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/searchcampaign`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setloading(false);
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCampaignList(sortedData);
      } else if (response.status === 401) {
        // Handle errors, e.g., show an error message
      } else if (response.status === 404) {
        setloading(false);
        setCampaignList([]);
      } else {
        setloading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setloading(false);
    }
  };

  const allcampaignList = [
    ...(campaignList.length > 1 ? [{ campaign_name: "Select All" }] : []),
    ...campaignList,
  ];

  const handleCampaignChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.campaign_name === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (
        campaignname.some((option) => option.campaign_name === "Select All")
      ) {
        setCampaignName([]);
      } else {
        // Otherwise, select all options
        setCampaignName(campaignList);
      }
    } else {
      setCampaignName(value);
    }
  };

  const handleFromdateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleTodDateChange = (e) => {
    setTodate(e.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setServiceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleServiceChange = (value) => {
    setService(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setLeadOwnerList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allleadownerList = [{ first_name: "Select All" }, ...leadOwnerList];

  const handleOwnerNameChange = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setLeadOwnerName(leadOwnerList);
    } else {
      setLeadOwnerName(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setBranchlist(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allBranchlist = [{ branch_name: "Select All" }, ...branchlist];

  const handlebranchChange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}stage`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setLeadstageList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allLeadStagelist = [{ stage_name: "Select All" }, ...leadStageList];

  const handleLeadStagechange = (event, value) => {
    if (value.some((option) => option.stage_name === "Select All")) {
      setLeadstage(leadStageList);
    } else {
      setLeadstage(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}prospectivelevel`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setProspectiveLevelList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allProspectiveLevelList = [
    { prospective_level: "Select All" },
    ...prospectiveLevelList,
  ];
  const handleProspectivelevelChange = (event, value) => {
    if (value.some((option) => option.prospective_level === "Select All")) {
      setProspectiveLevel(prospectiveLevelList);
    } else {
      setProspectiveLevel(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}targetcountries`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setcountrylist(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const AllcountryList = [{ country_name: "Select All" }, ...countrylist];

  const handleCountryChange = async (event, value) => {
    if (value.some((option) => option.country_name === "Select All")) {
      setCountry(countrylist);
    } else {
      setCountry(value);
    }
    const countryIds = value.map((i) => i.id);
    setselectedCountryId(countryIds);
    const formdata = {
      country_id: countryIds,
    };
    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/statebycountry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      });

      if (response.ok) {
        const result = await response.json();
        setStatelist(result);
        console.log("statelist", result);
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleStateChange = async (value) => {
    setState(value);
    const stateIds = value.map((i) => i.id);
    setSelectedStateIds(stateIds);
    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/citybystate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ state_id: stateIds }),
      });

      if (response.ok) {
        const result = await response.json();
        setCityList(result);
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}leadsource`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setLeadSourceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allLeadSourceList = [{ name: "Select All" }, ...leadSourceList];

  const handleLeadsourcechange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      setLeadsource(leadSourceList);
    } else {
      setLeadsource(value);
    }
  };

  const handleCityChange = (value) => {
    setCity(value);
  };

  const handleNearestBranchChange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setNearestBranch(branchlist);
    } else {
      setNearestBranch(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setCreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const allcreatedByList = [{ first_name: "Select All" }, ...createdbylist];
  const handelcretedby = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setCreatedby(createdbylist);
    } else {
      setCreatedby(value);
    }
  };

  const handleSearch = async (event) => {
    if (campaignname.length === 0) {
      showSnackbar("Please select campaign name first", "error");
      return;
    }

    setCampaignLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      campaign_id: campaignname.map((i) => i.id),
      from_date: fromDate || null,
      to_date: toDate || null,
      // service_id:service.map((i)=>i.id),
      assign_to: leadOwnerName.map((i) => i.id),
      branch_id: branch.map((i) => i.id),
      // stage_id: leadstage.map((i) => i.id),
      // prospective_level_id: prospectiveLevel.map((i) => i.id),
      // //country_id: country.map((i) => i.id),
      // lead_source_id: leadSource.map((i) => i.id),
      // state_id: state.map((i) => i.id),
      // city_id: city.map((i) => i.id),

      // nearest_branch_id: nearestBranch.map((i) => i.id),
      created_by: createdby.map((i) => i.id),

      user_id: parseInt(userId),
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/filtercampaignreport`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCampaignreportData(sortedData);
        setcampaignRowData(sortedData);
        setCampaignLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      setCampaignLoading(false);
      navigate("/page500");
    }
  };

  // Add default 1 month start and end date
  const formatDate = (date) => date.toISOString().split("T")[0];
  useEffect(() => {
    const today = new Date();
    let oneMonthAgo = new Date(today);

    // Move back one month
    oneMonthAgo.setMonth(today.getMonth() - 1);

    // If the day doesn't exist in the previous month, set to the last valid day
    if (oneMonthAgo.getDate() !== today.getDate()) {
      oneMonthAgo.setDate(0); // Moves to the last day of the previous month
    }

    setTodate(formatDate(today));
    setFromDate(formatDate(oneMonthAgo));
  }, []);

  const resetFilter = () => {
    setCampaignName([]);
    // setFromDate("");
    // setTodate("");
    // setService([])
    setLeadOwnerName([]);
    setBranch([]);
    setLeadstage([]);
    setProspectiveLevel([]);
    setCountry([]);
    setLeadsource([]);
    setState([]);
    setCity([]);
    setNearestBranch([]);

    setCampaignreportData(originalData);
    setcampaignRowData(originalData);
    setSelectedButton("");
    setSelectedDuration("");
    setSelectedYear("");
    setCampaignList([]);
    setCreatedby([]);
    setSubServiceName([]);
    setEventFromDate("");
    setEventToDate("");
  };

  return (
    <>
      <Collapse in={open} fullWidth>
        <MainCard>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} lg={12} sm={12} md={12}>
              <Typography
                sx={{
                  color: "#2E4069",
                  fontSize: "19px",
                  fontWeight: 600,
                }}
              >
                Service
                <span
                  style={{
                    color: "red",
                    fontSize: "20px",
                    marginLeft: "3px",
                  }}
                >
                  *
                </span>
              </Typography>
              <Chip
                avatar={<Avatar alt="Natacha" src="/test (1).png" />}
                label="Test Preparation"
                variant="outlined"
                sx={{
                  marginTop: "10px",
                  fontSize: "14px",
                  fontWeight: 600,
                  border: "2px solid green",
                  color: "green",
                }}
              />
            </Grid> */}

            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  color: "#2E4069",

                  fontWeight: 600,
                  marginTop: "20px",
                }}
              >
                Sub Service
              </Typography>

              {allSubservice.map((subservice) => (
                <Chip
                  size="small"
                  key={subservice.id}
                  onClick={() => handleChangeSubservice(subservice.id)}
                  variant="outlined"
                  label={
                    subservice.id === 0
                      ? subServiceName.length === subServiceList.length
                        ? "Deselect All"
                        : "Select All"
                      : subservice.name
                  }
                  style={{
                    color: subServiceName.includes(subservice.id)
                      ? "green"
                      : "black",
                    fontSize: "0.7rem",
                    fontWeight: 500,
                    margin: "5px",
                    border: subServiceName.includes(subservice.id)
                      ? "2px solid green"
                      : "",
                  }}
                />
              ))}
            </Grid>

            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Typography
                variant="h4"
                sx={{ color: "#2E4069", fontWeight: 600 }}
              >
                Campaign Type
                <span
                  style={{
                    color: "red",
                    fontSize: "15px",
                    marginLeft: "3px",
                  }}
                >
                  *
                </span>
              </Typography>
              <Box mt={1}>
                <CustomSmallChip
                  label="All"
                  onClick={() => handleChipClick("all")}
                  isSelected={selectedButton === "all"}
                />

                <CustomSmallChip
                  label="Event"
                  onClick={() => handleChipClick("Event")}
                  isSelected={selectedButton === "Event"}
                />

                <CustomSmallChip
                  label="Generic"
                  onClick={() => handleChipClick("Generic")}
                  isSelected={selectedButton === "Generic"}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  color: "#2E4069",

                  fontWeight: 600,
                }}
              >
                Duration
                <span
                  style={{
                    color: "red",
                    fontSize: "15px",
                    marginLeft: "3px",
                  }}
                >
                  *
                </span>
              </Typography>
              <Grid container>
                <Box mt={1} sx={{ display: "flex", flexDirection: "row" }}>
                  <CustomSmallChip
                    label="All"
                    onClick={() => handlDurationchange("all")}
                    isSelected={selectedDuration === "all"}
                  />

                  <CustomSmallChip
                    label="Past"
                    onClick={() => handlDurationchange("past")}
                    isSelected={selectedDuration === "past"}
                  />

                  <CustomSmallChip
                    label="Last One Year"
                    onClick={() => handlDurationchange("lastoneyear")}
                    isSelected={selectedDuration === "lastoneyear"}
                  />
                  <CustomSmallChip
                    label="Recent & Upcoming"
                    onClick={() => handlDurationchange("recentupcoming")}
                    isSelected={selectedDuration === "recentupcoming"}
                  />
                  <CustomSmallChip
                    label="Custom Year"
                    onClick={() => handlDurationchange("customyear")}
                    isSelected={selectedDuration === "customyear"}
                  />
                  {selectedDuration === "customyear" && (
                    <CustomAutocompleteComp
                      value={selectedYear}
                      onChange={(event, value) => handleselectedYear(value)}
                      sx={{ width: "300px" }}
                      options={years}
                    />
                  )}
                  <CustomSmallChip
                    label="Custom Date"
                    onClick={() => handlDurationchange("customdate")}
                    isSelected={selectedDuration === "customdate"}
                  />

                  {selectedDuration === "customdate" && (
                    <>
                      <CustomTextFieldComp
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        size="small"
                        sx={{ margin: "5px" }}
                        type="date"
                        label="From Date"
                        value={eventFromDate}
                        InputProps={{ style: { borderRadius: "14px" } }}
                        onChange={handleChangeEventFromdate}
                      />

                      <CustomTextFieldComp
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        size="small"
                        sx={{ margin: "5px" }}
                        type="date"
                        label="From Date"
                        value={eventToDate}
                        onChange={handleChangeEventTodate}
                        InputProps={{ style: { borderRadius: "14px" } }}
                      />
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography variant="h6">Campaign</Typography>
              <CustomAutocompleteComp
                limitTags={1}
                noOptionsText={
                  loading && allcampaignList.length === 0
                    ? noOptionsText
                    : "Please Select Above Filter First"
                }
                multiple
                value={campaignname}
                onChange={handleCampaignChange}
                options={allcampaignList}
                getOptionLabel={(option) => option.campaign_name}
                tooltipContent={(option) => option.campaign_name}
                endAdornment={
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                  </>
                }
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <Typography variant="h6">Created From Date</Typography>

              <CustomTextFieldComp
                fullWidth
                size="normal"
                type="date"
                value={fromDate}
                onChange={handleFromdateChange}
                InputProps={{ style: { borderRadius: "10px" } }}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <Typography variant="h6">Created To Date</Typography>

              <CustomTextFieldComp
                fullWidth
                size="normal"
                type="date"
                value={toDate}
                onChange={handleTodDateChange}
                InputProps={{ style: { borderRadius: "10px" } }}
              />
            </Grid>
            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
                  <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                    Service
                  </Typography>
                  <Autocomplete
                    value={service}
                    multiple
                    onChange={  
                      handleServiceChange // Passing only the value
                    }
                    options={servicelist}
                    getOptionLabel={(option) => option.service_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid> */}

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography variant="h6">Lead Owner </Typography>
              <CustomAutocompleteComp
                value={leadOwnerName}
                multiple
                onChange={handleOwnerNameChange}
                options={allleadownerList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                tooltipContent={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
              />
            </Grid>
            {(userRole === "center_admin" ||
              userRole === "data_entry" ||
              userRole === "developer") && (
              <Grid item xs={12} lg={3} sm={6} md={6}>
                <Typography variant="h6">Branch </Typography>
                <CustomAutocompleteComp
                  size="small"
                  value={branch}
                  multiple
                  onChange={handlebranchChange}
                  options={allBranchlist}
                  getOptionLabel={(option) => option.branch_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" }, // Add borderRadius here
                      }}
                    />
                  )}
                  tooltipContent={(option) => option.branch_name}
                />
              </Grid>
            )}

            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Branch{" "}
              </Typography>
              <Autocomplete
                size="small"
                value={branch}
                multiple
                onChange={handlebranchChange}
                options={allBranchlist}
                getOptionLabel={(option) => option.branch_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Lead Stage{" "}
              </Typography>
              <Autocomplete
                size="small"
                value={leadstage}
                multiple
                onChange={handleLeadStagechange}
                options={allLeadStagelist}
                getOptionLabel={(option) => option.stage_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Prospective Level{" "}
              </Typography>
              <Autocomplete
                size="small"
                value={prospectiveLevel}
                multiple
                onChange={handleProspectivelevelChange}
                options={allProspectiveLevelList}
                getOptionLabel={(option) => option.prospective_level}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                First Country Of Interest{" "}
              </Typography>
              <Autocomplete
                size="small"
                multiple
                value={country}
                onChange={handleCountryChange}
                options={AllcountryList}
                getOptionLabel={(option) => option.country_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid> */}

            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
                  <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
             Campaign   Country {" "}
                  </Typography>
                  <Autocomplete
                   
                    multiple
                   
                    options=""
          
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid> */}

            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Lead Source{" "}
              </Typography>
              <Autocomplete
                size="small"
                value={leadSource}
                multiple
                onChange={handleLeadsourcechange}
                options={allLeadSourceList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid> */}

            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
                  <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                    States{" "}
                  </Typography>
                  <Autocomplete
                   size="small"
                    value={state}
                    multiple
                    onChange={(event, value) => {
                      handleStateChange(value); // Passing only the value
                    }}
                    options={statelist}
                    getOptionLabel={(option) => option.state_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid> */}

            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
                  <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                    Cities{" "}
                  </Typography>
                  <Autocomplete
                   size="small"
                    value={city}
                    multiple
                    onChange={(event, value) => {
                      handleCityChange(value); // Passing only the value
                    }}
                    options={cityList}
                    getOptionLabel={(option) => option.city_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid> */}

            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Nearest Branch{" "}
              </Typography>
              <Autocomplete
                size="small"
                value={nearestBranch}
                multiple
                onChange={handleNearestBranchChange}
                options={allBranchlist}
                getOptionLabel={(option) => option.branch_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography variant="h6">Created By </Typography>
              <CustomAutocompleteComp
                fullWidth
                value={createdby}
                multiple
                onChange={handelcretedby}
                options={allcreatedByList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                tooltipContent={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12} sm={12} textAlign="end">
              <Grid container gap={2} justifyContent="end">
                <SearchButton
                  label="Search"
                  onClick={handleSearch}
                  showIcon={true}
                />

                <ResetButtonComp label="Clear" onClick={resetFilter} />
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      </Collapse>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
