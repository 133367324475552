import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; 
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DesktopDatePicker } from '@mui/x-date-pickers'; 

const availableDates = [
  new Date('2023-11-23'),
  new Date('2023-11-25'),
  new Date('2023-11-28'),
  // Add more available dates as needed
];

const unavailableDates = [
  new Date('2023-11-24'),
  new Date('2023-11-26'),
  // Add more unavailable dates as needed
];



const DatePickerWithAvailability = () => {
    const availableDates = [
        new Date('2023-11-23'),
        new Date('2023-11-25'),
        new Date('2023-11-28'),
        // Add more available dates as needed
      ];
      
      const unavailableDates = [
        new Date('2023-11-24'),
        new Date('2023-11-26'),
        new Date('2023-11-28'),
        // Add more unavailable dates as needed
      ];

  const [selectedDate, setSelectedDate] = useState(null);



  const isDateAvailable = (date) => {
    // Check if the date is in the list of available dates
    return availableDates.some(
      (availableDate) => isWithinRange(availableDate, selectedDate, 10)
    );
  };

  const isDateUnavailable = (date) => {
    // Check if the date is in the list of unavailable dates
    return unavailableDates.some(
      (unavailableDate) => isWithinRange(unavailableDate, selectedDate, 10)
    );
  };

  const renderDay = (day, _dayPickerProps) => {
    const date = day.date;
    const isAvailable = availableDates.some(availDate => isSameDay(availDate, date));
    const isUnavailable = unavailableDates.some(unavailDate => isSameDay(unavailDate, date));
  
    const style = isAvailable
      ? { backgroundColor: 'green', color: 'white' }
      : isUnavailable
      ? { backgroundColor: 'red', color: 'white' }
      : null;
  
    return <div style={style}>{day.day}</div>;
  };

  const renderCustomDay = (day, _selectedDate, isSameMonth) => {
    const date = day.date;
    const isAvailable = availableDates.some(availDate => isSameDay(availDate, date));
    const isUnavailable = unavailableDates.some(unavailDate => isSameDay(unavailDate, date));

    return (
      <div
        style={{
          backgroundColor: isAvailable ? 'green' : isUnavailable ? 'red' : 'transparent',
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#2196f3',
          border: '1px solid',
          padding: 8,
          margin: 2,
          color: isSameMonth ? 'inherit' : '#aaa',
        }}
      >
        {day.day}
      </div>
    );
  };


  const isSameDay = (date1, date2) => {
    if (!date1 || !date2) {
        return false; // Return false if either date is undefined or null
      }
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const isWithinRange = (dateToCheck, startDate, range) => {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + range);
    return dateToCheck >= startDate && dateToCheck <= endDate;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        value={selectedDate}
        onChange={(date) => setSelectedDate(date)}
       slots={renderDay}
        disablePast
        openTo="day"
        views={['year', 'month', 'day']}
       minDate={new Date()}
        maxDate={new Date('2024-12-31')}
        renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                startAdornment: (
                  <React.Fragment>
                    {params.InputProps.startAdornment}
                    {params.InputProps.value && (
                      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 8 }}>
                        {params.days.map((day) => (
                          renderCustomDay(day, params.InputProps.value, params.month === day.month))
                        )}
                      </div>
                    )}
                  </React.Fragment>
                ),
              }}
            />
          )}
        shouldDisableDate={(day) => unavailableDates.some(date => isSameDay(date, day.date))}
        slotProps={{
            layout: {
              sx: {
                '.MuiPickersDay-root': {
                  color: '#bbdefb',
                  borderRadius: 2,
                  borderWidth: 1,
                  borderColor: '#2196f3',
                  border: '1px solid',
                  backgroundColor: 'green',
                }
              }
            }

          }} 
      />
    </LocalizationProvider>
  );
};

export default DatePickerWithAvailability;


/* Because of the structure of the DesktopDatePicker, the `sx` prop needs to be applied to
the `layout` slot */

/* Because of the structure of the DesktopDatePicker, the `sx` prop needs to be applied to
the `layout` slot */
