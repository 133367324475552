import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/ui-component/card/MainCard";
import { SettingsBackupRestore } from "@mui/icons-material";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useActionData } from "react-router-dom";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";
import CustomAutocompleteComp from "../../newComponent/AutocompleteComp";
import ApplyFilterButton from "../../newComponent/NewButtonComp/ApplyFilterButton";
import ResetFilterButton from "../../newComponent/NewButtonComp/ResetFilterButton";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const CollapsibleForm = ({
  onApplyFilter,
  onResetFilter,
  open,
  handleToggle,
}) => {
  const today = new Date().toISOString().split("T")[0];
  // const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [payment, setpayment] = useState([]);
  const [paymentname, setpaymentname] = useState([]);
  const [amountFrom, setAmmounfrom] = useState("");
  const [amountTo, setAmountTo] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(true);
  // const handleToggle = () => {
  //   setOpen(!open);
  // };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const newBranch = branch.map((i) => i.id);
  const newPaymentName = paymentname.map((i) => i.payment_mode);
  const handleApplyFilter = () => {
    onApplyFilter({
      name,
      mobile,
      newBranch,
      newPaymentName,
      amountFrom,
      amountTo,
      startDate,
      endDate,
    });
  };
  const resetfilter = () => {
    setName("");
    setMobile("");
    setBranch([]);
    setpaymentname([]);
    setStartDate("");
    setEndDate("");
    setAmountTo("");
    setAmmounfrom("");
    onResetFilter();
  };
  const handelnamechange = (e) => {
    setName(e.target.value);
  };
  const handelmobilechange = (e) => {
    setMobile(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);

          setLoading(false);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const allbranchList = [{ branch_name: "Select All" }, ...branchlist];

  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}paymentmode`);
        if (response.ok) {
          const data = await response.json();
          const activePayments = data.filter((item) => item.is_active === true);
          setpayment(activePayments);

          setLoading(false);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  console.log(payment);

  const handleChangepayment = (event, value) => {
    setpaymentname(value);
  };

  const ammountfrom = (e) => {
    setAmmounfrom(e.target.value);
  };

  const ammountto = (e) => {
    setAmountTo(e.target.value);
  };

  const handelstartdate = (e) => {
    setStartDate(e.target.value);
  };

  const handeltodate = (e) => {
    setEndDate(e.target.value);
  };

  return (
    <>
      {/* {open ? 'Hide filter' : 'Show Filter'} */}
      {/* <FilterAltIcon sx={{width:'100px'}} onClick={handleToggle}/>      */}

      <Collapse in={open} >
        <MainCard>
          <Grid container spacing={2}>
            {/* <Box sx={{ display: 'flex', flexDirection: 'row' }} > */}
            <Grid item xs={6} lg={3} md={6} sm={6}>
              <Typography variant="h6">Name</Typography>
              <CustomTextFieldComp
                size="normal"
                sx={{ mb: 1, width: "100%" }}
                InputProps={{
                  style: { borderRadius: "10px" }, // Add borderRadius here
                }}
                value={name}
                onChange={handelnamechange}
              />
            </Grid>
            <Grid item xs={6} lg={3} md={6} sm={6}>
              <Typography variant="h6">Mobile Number</Typography>
              <CustomTextFieldComp
                size="normal"
                sx={{ mb: 1, width: "100%" }}
                type="number"
                onChange={handelmobilechange}
                value={mobile}
                InputProps={{
                  style: { borderRadius: "10px" }, // Add borderRadius here
                }}
              />
            </Grid>
            <Grid item xs={6} lg={3} md={6} sm={6}>
              <Typography variant="h6">Branch</Typography>

              <CustomAutocompleteComp
                size="small"
                fullWidth
                value={branch}
                multiple
                onChange={handelbranchchange}
                options={allbranchList}
                getOptionLabel={(option) => option.branch_name}
                tooltipContent={(option) => option.branch_name}
              />
            </Grid>

            <Grid item xs={6} lg={3} md={6} sm={6}>
              <Typography variant="h6">Payment Mode</Typography>

              <CustomAutocompleteComp
                size="small"
                fullWidth
                value={paymentname}
                multiple
                onChange={handleChangepayment}
                options={payment}
                getOptionLabel={(option) => option.payment_mode}
                tooltipContent={(option) => option.payment_mode}
              />
            </Grid>
            {/* </Box> */}
            {/* <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}> */}
            <Grid item xs={6} lg={3} md={6} sm={6}>
              <Typography variant="h6">Payment Date From</Typography>
              <CustomTextFieldComp
                size="normal"
                sx={{ mb: 1, width: "100%" }}
                type="date"
                value={startDate}
                onChange={handelstartdate}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: { borderRadius: "10px" },
                  inputProps: { max: today },
                }}
              />
            </Grid>
            <Grid item xs={6} lg={3} md={6} sm={6}>
              <Typography variant="h6">Payment Date To</Typography>
              <CustomTextFieldComp
                size="normal"
                sx={{ mb: 1, width: "100%" }}
                type="date"
                value={endDate}
                onChange={handeltodate}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: { borderRadius: "10px" },
                  inputProps: { max: today },
                }}
              />
            </Grid>
            <Grid item xs={6} lg={3} md={6} sm={6}>
              <Typography variant="h6">Amount From</Typography>
              <CustomTextFieldComp
                size="normal"
                sx={{ mb: 1, width: "100%" }}
                InputProps={{
                  style: { borderRadius: "10px" }, // Add borderRadius here
                }}
                value={amountFrom}
                type="number"
                onChange={ammountfrom}
              />
            </Grid>
            <Grid item xs={6} lg={3} md={6} sm={6}>
              <Typography variant="h6">Amount To</Typography>
              <CustomTextFieldComp
                size="normal"
                sx={{ mb: 1, width: "100%" }}
                InputProps={{
                  style: { borderRadius: "10px" }, // Add borderRadius here
                }}
                type="number"
                value={amountTo}
                onChange={ammountto}
              />
            </Grid>
            {/* </Box> */}

            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
          container
          justifyContent='end'
          gap={1}
            >
              <ApplyFilterButton
                
                onClick={handleApplyFilter}
              />
             

              <ResetFilterButton
                
                onClick={resetfilter}
              />
              
            </Grid>
            {/* </Box> */}
          </Grid>
        </MainCard>
      </Collapse>
    </>
  );
};

export default CollapsibleForm;
