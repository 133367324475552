import React, { useState } from 'react'
import MainCard from '../../../components/ui-component/card/MainCard'
import { Box, Button, CircularProgress, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { CSVExport } from '../../../components/ui-component/table/CSVExport'
import RoomScheduleDialog from './RoomSchedule';
import CreatePopup from '../../../../components/dialog/CreatePopup';
import AddRoomForm from './rooms/AddRoom';
import CustomSnackbar from '../../../components/ui-component/snackbar/CustomSnackbar';
import StyledTableContainer from '../../../newComponent/TableContainer';
import VeiwIcon from '../../../newComponent/iconsComp/VeiwIcon';
import AddButton from '../../../newComponent/NewButtonComp/AddButton';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


function Row({ row, branchName, branchId, index }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleViewSchedule = () => {
        setOpen(true);
      };

      

    return (
        <>
            <TableRow  sx={{backgroundColor:index % 2 === 0 ? "#f0f0f0" : "inherit"}}>
            
                <TableCell >
                    {row.name}
                </TableCell>
                <TableCell >{row.capacity}</TableCell>
                <TableCell >{row?.teaching_mode}</TableCell>
                <TableCell >
                  <VeiwIcon tooltipTitle="View Schedule" onClick={handleViewSchedule}/>
              
                </TableCell>
                
            </TableRow>
            {open && (
                <RoomScheduleDialog open={open} onClose={() => setOpen(false)} roomId={row.id} branchName={branchName} branchId={branchId} />
            )}
                               
                                   
                              
           
        </>
    );
}

function RowTable({ rooms, branchId, branchName }) {
    const userId = localStorage.getItem('userId');
    const [rows, setRows] = useState(rooms)
    const [isLoading, setIsLoading] = useState(false)
    const [isAddRoomPopupOpen, setAddRoomPopupOpen] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
   

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };

    const handleAddRoomClick = () => {
        setAddRoomPopupOpen(true);
      };
    
      const handleAddRoomClose = () => {
        setAddRoomPopupOpen(false);
      };
    
      const handleAddRoom = (newRoom) => {
        // Handle adding the new room to the state or perform API call
        // Assuming setRows is a function to update the rows state
        newRoom.branch_id = branchId
        newRoom.user_id = userId

       

        fetch(`${API_ENDPOINT}room`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newRoom),
          })
          .then((response) => {
            if(response.status === 201) {
               showSnackbar('Room created successfully', 'success');
              
              setRows((prevRows) => [...prevRows, newRoom]);
              window.location.reload()
            } else if(response.status === 401) {
                showSnackbar('User unauthorized to create room', 'error');
                //window.location.reload()
             }
            else {
            
              showSnackbar('Error creating room', 'error');
            }
          })
            .catch((error) => {
              console.error('Error assigning admin:', error);
              showSnackbar('Error creating room', 'error');
            });

        
    
        // Close the Add Room popup
        handleAddRoomClose();
      };

  return (
    <>
   
    <MainCard
    
        // title="Rooms"
      
    >
       <Grid container spacing={2}>
        <Grid item xs={12} sm={6} >
          <Typography variant='h3'>Rooms</Typography>
        </Grid>
        <Grid item xs={12} sm={6} container justifyContent='end' gap={1}>
        <CSVExport data={rows} filename="rooms.csv"  />
        <AddButton label="Add Rooms" onClick={handleAddRoomClick}/> 
        </Grid>
        <Grid item xs={12} >
        {/* table */}
        
        <StyledTableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                      
                        <TableCell >Room Name</TableCell>
                        <TableCell >Capacity</TableCell>
                        <TableCell >Teaching Mode</TableCell>
                        <TableCell  >Action</TableCell>
                       
                    </TableRow>
                </TableHead>
                <TableBody>

                {rows.length === 0 ? (
                <TableRow>
                  <TableCell sx={{ pl: 3 }} />
                  <TableCell colSpan={4} align="center">
                    <Typography variant='h4'>No data available</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((room, index) => (
                  <Row key={room?.id} row={room} branchName={branchName} branchId={branchId}  index={index}/>
                ))
              )}

                  </TableBody>
            </Table>
        </StyledTableContainer>
        </Grid>
        </Grid>
    </MainCard> 

    <CreatePopup 
        open={isAddRoomPopupOpen} 
        onClose={handleAddRoomClose}
        component={<AddRoomForm onAddRoom={handleAddRoom} branchId={branchId} branchName={branchName} />}
        >
      </CreatePopup> 

      <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
    </>
  )
}

export default RowTable