// material-ui
import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

// project imports
import SubCard from "../../../components/ui-component/card/SubCard";
import Chip from "../../../components/ui-component/chip/Chip";
import { gridSpacing } from "../../../store/constant";

// assets
import CalendarTodayTwoToneIcon from "@mui/icons-material/CalendarTodayTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import PhoneAndroidTwoToneIcon from "@mui/icons-material/PhoneAndroidTwoTone";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { fDate } from "../../../../utils/formatTime";

import { useEffect, useState } from "react";
import {
  ExpandMore as ExpandMoreIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import MainCard from "../../../components/ui-component/card/MainCard";
import CustomSnackbar from "../../../components/ui-component/snackbar/CustomSnackbar";
import CustomTextFieldComp from "../../../newComponent/customTextFieldComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

/*const couponData = [
    { id: 1, name: 'COUPON1', value: 10 },
    { id: 2, name: 'COUPON2', value: 15 },
    { id: 3, name: 'COUPON3', value: 20 },
    { id: 4, name: 'COUPON4', value: 25 },
    { id: 5, name: 'COUPON5', value: 30 },
    { id: 6, name: 'COUPON6', value: 35 },
    { id: 7, name: 'COUPON7', value: 40 },
    { id: 8, name: 'COUPON8', value: 45 },
    { id: 9, name: 'COUPON9', value: 50 },
    { id: 10, name: 'COUPON10', value: 55 },
  ];*/

const sxDivider = {
  borderColor: "primary.light",
};

const detailsIconSX = {
  width: 15,
  height: 15,
  verticalAlign: "text-top",
  mr: 0.5,
  mt: 0.25,
};

const PaymentDetails = ({ confirmationDetails, studentEnrollmentDetails }) => {
  const [displayAll, setDisplayAll] = useState(false);
  const [couponData, setCouponData] = useState([]);
  const [loading, setLoading] = useState(true);
  //const [selectedcoupon,setSelectedCoupon] =useState([])

  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [selectedCouponData, setSelectedCouponData] = useState({});
  const displaycoupons = displayAll ? couponData : couponData.slice(0, 6);

  const handlecoupon = (coupon) => {
    setSelectedCoupon(coupon);
  };

  
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();

  const studentInfo = confirmationDetails || {};
  const theme = useTheme();

  const [paymentOption, setPaymentOption] = useState("fullPayment");
  const [isPartialPayment, setIsPartialPayment] = useState(false);
  const [offerDiscount, setOfferDiscount] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [paymentModes, setPaymentModes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [installmentDetails, setInstallmentDetails] = useState([
    { amount: 0, date: "" },
  ]);
  const [paymentMode, setPaymentMode] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const [amount, setAmount] = useState(studentInfo?.price || null);
  const [installmentAmount, setInstallmentAmount] = useState(null);
  const [pendingAmount, setPendingAmount] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showFailedPopup, setShowFailedPopup] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [packageDiscountAmount, setPackageDiscountAmount] = useState(null);
  const [giveSystemDiscountedPrice, setGiveSystemDiscountedPrice] =
    useState(false);
  const [giveManualDiscountedPrice, setGiveManualDiscountedPrice] =
    useState(false);

  const [originalData, setOriginalData] = useState([]);

  const [searchedCode, setSearchedCode] = useState("");
  const [discountDetails, setDiscountDetails] = useState(null);

  const [errormessage, setErrormessage] = useState(
    "Error sending payment details! Try Again"
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleManualDiscountedPriceChange = (event) => {
    setGiveManualDiscountedPrice(event.target.checked);
    setSelectedCoupon(null);
    setSelectedCouponData({});

    setDiscountAmount(0);
  };

  /* const handleSystemDiscountedPriceChange = (event) => {
        setGiveSystemDiscountedPrice(event.target.checked);

        if (event.target.checked) {
          let totalAmount = calculateTotalAmount();
          const systemDiscountedAmount = calculateDiscountAmountToBePaid();
          const difference = totalAmount - systemDiscountedAmount
          setAmount(systemDiscountedAmount)
          setDiscountAmount(difference);
          setPackageDiscountAmount(systemDiscountedAmount);
        } else {
          let totalAmount = calculateTotalAmount();
          setAmount(totalAmount)
          setDiscountAmount(0);
          setPackageDiscountAmount(0);
        }
    }*/

  const calculateDiscountedAmountToBePaid = () => {
    const baseAmount = studentInfo.price || 0; // Update with your base amount
    let discountedAmount = baseAmount;
    let newDiscountAmount = discountAmount;



    if (selectedCoupon) {
      if (selectedCouponData.discount_percentage === 0) {
        // Coupon provides a fixed amount discount
        newDiscountAmount = selectedCouponData.discount_amount;
        discountedAmount -= newDiscountAmount;
      } else {
        // Coupon provides a percentage discount
        newDiscountAmount =
          (baseAmount * selectedCouponData.discount_percentage) / 100;
        discountedAmount -= newDiscountAmount;
      }
    }

    if (giveManualDiscountedPrice) {
      discountedAmount -= newDiscountAmount;
    }

    if (paymentOption === "fullPayment") {
      // return offerDiscount ? confirmationDetails.discountedPrice : discountedAmount;
      //return discountedAmount
      return {
        discountedAmount: discountedAmount,
        discountAmount: newDiscountAmount,
      };
    } else {
      const installmentAmount = installmentDetails.reduce(
        (total, installment) => total + parseFloat(installment.amount),
        0
      );
      //return offerDiscount ? confirmationDetails.discountedPrice : discountedAmount - installmentAmount;
      // return discountedAmount - installmentAmount
      const discountedAmountWithInstallment =
        discountedAmount - installmentAmount;
      return {
        discountedAmount: discountedAmountWithInstallment,
        discountAmount: newDiscountAmount,
      };
    }
  };

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  const handleCloseFailedPopup = () => {
    setShowFailedPopup(false);
  };

  const handlePendingAmountChange = (event) => {
    setPendingAmount(event.target.value);
  };

  const handleDueDateChange = (event) => {
    setDueDate(event.target.value);
    validateForm();
  };

  const handleDiscountAmountChange = (event) => {
    const newDiscountedAmount = event.target.value;
    setDiscountAmount(newDiscountedAmount);

    let totalAmount = calculateTotalAmount();

    const newDiscountAmount = totalAmount - parseFloat(newDiscountedAmount);
    setPackageDiscountAmount(newDiscountAmount > 0 ? newDiscountAmount : 0);
    setAmount(newDiscountAmount > 0 ? newDiscountAmount : 0);
  };

  const handlePackageDiscountAmountChange = (event) => {
    setPackageDiscountAmount(event.target.value);
    setAmount(event.target.value);
  };

  const handlePaymentModeChange = (event) => {
    setPaymentMode(event.target.value);
  };

  const handlePaymentStatusChange = (event) => {
    setPaymentStatus(event.target.value);
  };

  const handlePaymentOptionChange = (event) => {
    setPaymentOption(event.target.value);
    setIsPartialPayment(!isPartialPayment);
    setInstallmentAmount(null);
    setPendingAmount(null);
    setDueDate(null);

    setIsFormValid(false);
  };

  useEffect(() => {
    if (paymentMode === "cash") {
      setPaymentStatus("paid");
    } else if (paymentMode === "online") {
      setPaymentStatus("pending");
    }
  }, [paymentMode]);

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  const handleOfferDiscountChange = (event) => {
    setOfferDiscount(event.target.checked);
    //setAmount(confirmationDetails.discountedPrice)
    setInstallmentAmount(null);
    setPendingAmount(null);
    setDueDate(null);

    setGiveSystemDiscountedPrice(false);

    setGiveManualDiscountedPrice(false);

    setDiscountAmount(null);
    setPackageDiscountAmount(null);

    setPaymentOption("fullPayment");

    //validateForm();

    setIsFormValid(false);
  };

  const handleDiscountChange = (event) => {
    setDiscount(parseFloat(event.target.value) || 0);
  };

  const handleDiscountCodeChange = (event) => {
    setDiscountCode(event.target.value);
  };

  const handleAddInstallment = () => {
    setInstallmentDetails([...installmentDetails, { amount: 0, date: "" }]);
  };

  const handleRemoveInstallment = (index) => {
    const updatedInstallments = [...installmentDetails];
    updatedInstallments.splice(index, 1);
    setInstallmentDetails(updatedInstallments);
  };

  const handleInstallmentChange = (index, field, value) => {
    const updatedInstallments = [...installmentDetails];
    updatedInstallments[index][field] = value;
    setInstallmentDetails(updatedInstallments);
  };

  const handleInstallmentAmountChange = (event) => {
    const newInstallmentAmount = event.target.value;
    setInstallmentAmount(newInstallmentAmount);

    validateForm();

    // Calculate pending amount based on the total amount and entered installment amount
    if (offerDiscount) {
      let totalAmount = packageDiscountAmount;

      const newPendingAmount = totalAmount - parseFloat(newInstallmentAmount);
      setPendingAmount(newPendingAmount > 0 ? newPendingAmount : 0);
    } else {
      let totalAmount = calculateTotalAmount();

      const newPendingAmount = totalAmount - parseFloat(newInstallmentAmount);
      setPendingAmount(newPendingAmount > 0 ? newPendingAmount : 0);
    }
  };

  // Calculate total amount based on payment option, discount, and installment details
  const calculateTotalAmount = () => {
    const baseAmount = studentInfo.price || 0; // Update with your base amount
    const discountAmountCalc = (baseAmount * discount) / 100;
    //const discountAmountCalc = discountAmount;
    const discountedAmount = baseAmount - discountAmountCalc;

    if (paymentOption === "fullPayment" || paymentOption === "installments") {
      return discountedAmount;
    } else {
      const installmentAmount = installmentDetails.reduce(
        (total, installment) => total + parseFloat(installment.amount),
        0
      );
      return installmentAmount;
    }
  };

  // Calculate amount to be paid after discount and installment
  const calculateDiscountAmountToBePaid = () => {
    const baseAmount = studentInfo.price || 0; // Update with your base amount
    const discountAmount = (baseAmount * discount) / 100;
    const discountedAmount = baseAmount - discountAmount;

    if (paymentOption === "fullPayment") {
      return offerDiscount
        ? confirmationDetails.discountedPrice
        : discountedAmount;
    } else {
      const installmentAmount = installmentDetails.reduce(
        (total, installment) => total + parseFloat(installment.amount),
        0
      );
      return offerDiscount
        ? confirmationDetails.discountedPrice
        : discountedAmount - installmentAmount;
    }
  };

  const calculateTotalAmountToBePaid = () => {
    const baseAmount = studentInfo.price || 0; // Update with your base amount
    const discountAmount = confirmationDetails.discountedPrice;
    const discountedAmount = packageDiscountAmount;

    if (paymentOption === "fullPayment") {
      return offerDiscount ? discountedAmount : baseAmount;
    } else if (paymentOption === "installments") {
      const paidAmount = parseFloat(installmentAmount) || 0;
      return offerDiscount ? paidAmount : paidAmount;
    } else {
      // Handle other payment options as needed
      return 0;
    }
  };

  const isInstallmentAvailable = () => {
    const installmentType = studentInfo.installments || 0;
    return installmentType > 1;
  };

  const validateForm = () => {
    const isInstallmentAmountValid = installmentAmount !== null;
    const isPendingAmountValid = pendingAmount !== null;
    const isDueDateValid = dueDate !== null;
    const isPaymentModeValid = paymentMode !== null;
    let isDiscountAmountValid;

    if (offerDiscount) {
      isDiscountAmountValid =
        discountAmount !== null && discountAmount !== undefined;
    } else {
      isDiscountAmountValid = true;
    }

   

    setIsFormValid(
      isInstallmentAmountValid &&
        isDueDateValid &&
        isPaymentModeValid &&
        isDiscountAmountValid
    );
  };


  useEffect(() => {
    validateForm();
  }, [dueDate, installmentAmount, paymentMode, discountAmount]);

  const handleProceedToPayment = () => {
    setIsPaymentLoading(true);
    const studentId = studentEnrollmentDetails.student_id;
    const updatedEnrollmentDetails = {
      ...studentEnrollmentDetails,
      payment_mode: paymentMode,
      discount: offerDiscount ? 1 : 0,
      package_id: studentEnrollmentDetails.package_id.id,
      payment_status: paymentStatus,
      price: confirmationDetails.price,
      user_id: userId,
    };

    const paymentDetails = {
      ...studentEnrollmentDetails,
      enquiry_id: studentEnrollmentDetails.enquiry_id,
      // amount: isPartialPayment ? installmentAmount : amount,
      payment_mode_id: parseInt(paymentMode),
      is_discount: offerDiscount,
      is_partial_payment: isPartialPayment,
      pending_amount: pendingAmount,
      next_payment_date: dueDate,
      package_id: studentEnrollmentDetails.package_id.id,
      payment_status: paymentStatus,
      user_id: userId,

      branch_enrolled_id: studentInfo.branch_id,

      package_amount: studentInfo.price,
      amount_to_be_paid: offerDiscount ? packageDiscountAmount : amount,
      amount_paid: isPartialPayment ? installmentAmount : amount,
      discount_amount: discountAmount,

      discount_id: selectedCoupon,
      is_manual_discount: giveManualDiscountedPrice,
    };

 

    // Make a POST request to enroll the student
    fetch(`${API_ENDPOINT}lmprovesionpayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paymentDetails),
    })
      .then((response) => {
  
        if (response.ok) {
          setIsPaymentLoading(false);
          // Handle success, e.g., show a success message
     
          // alert('Payment Details sent successfully')
          setShowSuccessPopup(true);
          setTimeout(() => {
            navigate("/enquiries");
          }, 2000);
        } else if (response.status === 403) {
          setIsPaymentLoading(false);
          setErrormessage(
            "Unauthorized to create enrollment. Please log in with your advisor account"
          );
          setShowFailedPopup(true);
          showSnackbar(
            "Error",
            "The student has the same existing package with same trainer"
          );
        } else if (response.status === 409) {
          setIsPaymentLoading(false);
          setErrormessage(
            "This enquiry has active sessions with this package selection"
          );
          setShowFailedPopup(true);
          showSnackbar(
            "Error",
            "The student has the same existing package with same trainer"
          );
        } else if (response.status === 410) {
          setIsPaymentLoading(false);
          setErrormessage(
            "This enquiry has an existing pending payment with the same package"
          );
          setShowFailedPopup(true);
          showSnackbar(
            "Error",
            "This enquiry has an existing pending payment with the same class and package"
          );
        } else {
          // Handle failure, e.g., show an error message
          setIsPaymentLoading(false);
          console.error("Failed to enroll student");
          setShowFailedPopup(true);
          //alert('Error sending payment details')
        }
      })
      .catch((error) => {
        setIsPaymentLoading(false);
        console.error("Error enrolling student:", error);
        // alert('Error sending payment details')
        setShowFailedPopup(true);
      });

    //setPaymentStatus('paid');
  };

  /*const fetchPaymentModes = async () => {
        try {
          const response = await fetch(`${API_ENDPOINT}paymentmode`);
          const data = await response.json();
          
          const activeModes = data.filter((mode) => mode.is_active === 1);

          return activeModes;
        } catch (error) {
          console.error('Error fetching payment modes:', error);
          return [];
        }
      };

      useEffect(() => {
        // Fetch payment modes when the component mounts
        const fetchPaymentModesData = async () => {
          const modes = await fetchPaymentModes();
          setPaymentModes(modes);
          if (modes?.length > 0) {
            
            setPaymentMode(modes[0].value);
          }
        
        };
        
        fetchPaymentModesData();
      }, []);*/

  useEffect(() => {
    setIsLoading(true);
    // Fetch packages data
    fetch(`${API_ENDPOINT}paymentmode`)
      .then((response) => response.json())
      .then((data) => {
        // Update the rows state with the fetched data
      
        const activeModes = data.filter((mode) => mode.is_active === true);
      
        setPaymentModes(activeModes);

        if (activeModes?.length > 0) {
          setPaymentMode(activeModes[0].id);
        }
        //setFilteredData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching categories data:", error);
        setIsLoading(false);
      });
  }, []);



  const fetchDiscountCodes = async () => {
    try {
      // Make a POST request to the API endpoint
      const response = await fetch(`${API_ENDPOINT}route2.0/getdiscount`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          branch_id: studentInfo.branch_id,
          test_type_id: studentInfo.test_type_id,
          package_id: studentEnrollmentDetails.package_id.id,
        }),
      });

      // Check if the response is successful (status code 200)
      if (response.ok) {
        const responseData = await response.json();
        setCouponData(responseData);
        setOriginalData(responseData);
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  // useEffect hook to fetch data when the component mounts (optional)
  useEffect(() => {
    fetchDiscountCodes();
  }, []);

  const handleSearchCode = () => {
    setLoading(true);
    // Simulating searching for the discount code in coupon data
    const foundCoupon = couponData.filter(
      (coupon) => coupon.discount_code === searchedCode
    );
  
    if (foundCoupon) {
      setCouponData(foundCoupon);
    } else {
      setCouponData([]);
    }
    setLoading(false);
  };

  const handleReset = () => {
    setCouponData(originalData);
    setSearchedCode("");
    setSelectedCoupon(null);
    setSelectedCouponData({});
  };

  const handleCouponSelect = (coupon, data) => {
    setSelectedCoupon(coupon);
    setSelectedCouponData(data);
  };

  useEffect(() => {
    const {
      discountedAmount: newDiscountedAmount,
      discountAmount: newDiscountAmount,
    } = calculateDiscountedAmountToBePaid();
    setDiscountAmount(newDiscountAmount);
    setPackageDiscountAmount(newDiscountedAmount);
    setAmount(newDiscountedAmount);
  }, [
    studentInfo,
    selectedCouponData,
    paymentOption,
    installmentDetails,
    offerDiscount,
    confirmationDetails,
  ]);

  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <SubCard
            title="Student Details"
            secondary={
              <Typography variant="subtitle1">{fDate(Date.now())}</Typography>
            }
          >
            <Box m={2}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Typography variant="body2">
                        <CalendarTodayTwoToneIcon sx={detailsIconSX} />{" "}
                        {studentInfo.studentName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        <PhoneAndroidTwoToneIcon sx={detailsIconSX} />{" "}
                        {studentInfo.studentMobile}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        <EmailTwoToneIcon sx={detailsIconSX} />{" "}
                        {studentInfo.studentEmail}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={sxDivider} />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Stack spacing={2}>
                        <Typography variant="h4">Class Details</Typography>
                        <Stack spacing={1}>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="subtitle1">
                              Test Type:
                            </Typography>
                            <Typography variant="body2">
                              {studentInfo.testType}
                            </Typography>
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="subtitle1">
                              Trainer Name :
                            </Typography>
                            <Typography variant="body2">
                              {studentInfo.trainerName}
                            </Typography>
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="subtitle1">
                              Class Slot :
                            </Typography>
                            <Typography variant="body2">
                              {studentInfo.slot}
                            </Typography>
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="subtitle1">
                              Branch Details :
                            </Typography>
                            <Typography variant="body2">
                              {studentInfo?.branch}
                            </Typography>
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="subtitle1">
                              Delivery Mode :
                            </Typography>
                            <Typography variant="body2">
                              {studentInfo.deliveryMode}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Stack spacing={2}>
                        <Typography variant="h4">Package Details</Typography>
                        <Stack spacing={1}>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="subtitle1">
                              Enroll Now :
                            </Typography>
                            <Typography variant="body2">
                              {studentInfo.is_tentative_enroll_date
                                ? "No"
                                : "Yes"}
                            </Typography>
                          </Stack>
                          {!studentInfo.is_tentative_enroll_date ? (
                            <Stack direction="row" spacing={1}>
                              <Typography variant="subtitle1">
                                Enrollment Date:
                              </Typography>
                              <Typography variant="body2">
                                {fDate(studentInfo?.enrollment_date)}
                              </Typography>
                            </Stack>
                          ) : (
                            <Stack direction="row" spacing={1}>
                              <Typography variant="subtitle1">
                                Tentative Date:
                              </Typography>
                              <Typography variant="body2">
                                {fDate(studentInfo?.tentative_enroll_date)}
                              </Typography>
                            </Stack>
                          )} 
                          <Stack direction="row" spacing={1}>
                            <Typography variant="subtitle1">
                              Package Name :
                            </Typography>
                            <Typography variant="body2">
                              {studentInfo.package_name}
                            </Typography>
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="subtitle1">
                              Package Sessions :
                            </Typography>
                            <Typography variant="body2">
                              {studentEnrollmentDetails.package_id?.duration} session(s)
                            </Typography>
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="subtitle1">
                              Payable installments :
                            </Typography>
                            <Typography variant="body2">
                              {studentInfo.installments}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={sxDivider} />
                </Grid>
              </Grid>
            </Box>
          </SubCard>
        </Grid>

        <Grid item xs={12}>
          <SubCard title="Payment" content={false}>
            <Box m={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Package Amount: Rs {calculateTotalAmount()}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h4">Discount</Typography>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography>Apply Discount</Typography>
                    <Checkbox
                      checked={offerDiscount}
                      onChange={handleOfferDiscountChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={12}>
                  {selectedCoupon && (
                    <Alert
                      severity="success"
                      color="secondary"
                      sx={{ mb: "8px" }}
                    >
                      {" "}
                      Your coupon was succefully applied.
                    </Alert>
                  )}

                  {offerDiscount && (
                    <>
                      {!giveManualDiscountedPrice && (
                        <>
                          <Grid
                            item
                            xs={12}
                            lg={12}
                            sx={{ marginBottom: "15px" }}
                          >
                            <Typography variant="h5">
                              Enter Discount Code
                            </Typography>
                            <TextField
                              size="small"
                              style={{ width: "300px" }}
                              InputProps={{ style: { borderRadius: "10px" } }}
                              value={searchedCode}
                              onChange={(e) =>
                                setSearchedCode(e.target.value.toUpperCase())
                              }
                            />
                            <Button
                              variant="contained"
                              style={{ marginLeft: "10px" }}
                              onClick={handleSearchCode}
                              disabled={searchedCode.trim() === ""}
                            >
                              Search Discount Code
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{ marginLeft: "10px" }}
                              onClick={handleReset}
                            >
                              Reset
                            </Button>
                          </Grid>

                          <MainCard sx={{ border: "2px solid #000" }}>
                            {loading ? (
                              <Box
                                style={{
                                  height: "100px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <CircularProgress color="primary" />
                              </Box>
                            ) : (
                              <>
                                {couponData.length === 0 ? (
                                  <Box
                                    style={{
                                      height: "100px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography textAlign="center" variant="h4">
                                      No Coupons Available
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box
                                    style={{
                                      height:
                                        couponData.length > 6
                                          ? "400px"
                                          : "auto",
                                      overflow: "auto",
                                    }}
                                  >
                                    {couponData.map((coupon, index) => (
                                      <Box
                                        key={coupon.id}
                                        sx={{
                                          padding: "12px",
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          backgroundColor:
                                            selectedCoupon === coupon.id
                                              ? "#f0fff4"
                                              : "transparent", // Light green color when selected
                                          cursor: "pointer", // Make the box clickable
                                        }}
                                        onClick={() =>
                                          handleCouponSelect(coupon.id, coupon)
                                        }
                                      >
                                        <Radio // Radio button for selection
                                          checked={selectedCoupon === coupon.id}
                                          onChange={() =>
                                            handleCouponSelect(
                                              coupon.id,
                                              coupon
                                            )
                                          }
                                          color="primary"
                                        />
                                        <Stack direction="column">
                                          <Typography
                                            sx={{
                                              fontSize: "15px",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {coupon.name}
                                          </Typography>
                                          {coupon.discount_percentage === 0 ? (
                                            <Typography>
                                              {coupon.discount_amount} Rs Off
                                            </Typography>
                                          ) : (
                                            <Typography>
                                              {coupon.discount_percentage}% Off
                                            </Typography>
                                          )}
                                        </Stack>
                                      </Box>
                                    ))}
                                  </Box>
                                )}
                              </>
                            )}
                          </MainCard>
                        </>
                      )}

                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={giveManualDiscountedPrice}
                              onChange={handleManualDiscountedPriceChange}
                            />
                          }
                          label="Give manual discounted price?"
                        />
                      </Grid>

                      {giveManualDiscountedPrice && (
                        <>
                          <Grid container spacing={2} mt={0.5}>
                            <Grid item xs={6}>
                              <TextField
                                size="small"
                                label="Discount Amount (INR)"
                                variant="outlined"
                                fullWidth
                                value={discountAmount}
                                onChange={handleDiscountAmountChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{ style: { borderRadius: "10px" } }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                size="small"
                                label="New Package Amount(INR)"
                                fullWidth
                                variant="outlined"
                                value={packageDiscountAmount}
                                // onChange={handlePackageDiscountAmountChange}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ style: { borderRadius: "10px" } }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} mt={2}>
                        <Typography variant="h4">
                          Discount Amount: Rs {discountAmount}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} mt={2}>
                        <Typography variant="h4">
                          New Package Amount: Rs {packageDiscountAmount}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h4">Payment Type</Typography>
                  <RadioGroup
                    row
                    aria-label="paymentOption"
                    name="paymentOption"
                    value={paymentOption}
                    onChange={handlePaymentOptionChange}
                  >
                    <FormControlLabel
                      value="fullPayment"
                      control={<Radio />}
                      label="Full Payment"
                    />
                    <FormControlLabel
                      value="installments"
                      control={<Radio />}
                      label="Installments"
                      // disabled={!isInstallmentAvailable()}
                    />
                  </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                  {paymentOption === "installments" && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Installment Amount (INR)
                          </Typography>
                          <CustomTextFieldComp
                            size="normal"
                            variant="outlined"
                            fullWidth
                            value={installmentAmount}
                            onChange={handleInstallmentAmountChange}
                            InputProps={{
                              style: { borderRadius: "10px" },
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6">Pending Amount</Typography>
                          <CustomTextFieldComp
                            size="normal"
                            fullWidth
                            variant="outlined"
                            value={pendingAmount}
                            onChange={handlePendingAmountChange}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              style: { borderRadius: "10px" },
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography style={{ visibility: "hidden" }}>
                            Due Date
                          </Typography>
                          <CustomTextFieldComp
                            size="normal"
                            label="Due Date"
                            variant="outlined"
                            fullWidth
                            type="date"
                            value={dueDate}
                            onChange={handleDueDateChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              style: { borderRadius: "10px" },
                              inputProps: {
                                min: new Date().toISOString().split("T")[0],
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {/*  <Grid item xs={12}>
              <Typography variant="h4">Discount</Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography>Apply Discount</Typography>
                <Checkbox
                  checked={offerDiscount}
                  onChange={handleOfferDiscountChange}
                />
              </Stack>
            </Grid>
            {offerDiscount && (
              <>
            <Grid item xs={12}>
              <Typography variant="h4">Discounted Package Price: Rs {calculateDiscountAmountToBePaid()}</Typography>
            </Grid>
            </>
            )}
            {offerDiscount && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    fullWidth
                    label="Select Discount Code"
                    variant="outlined"
                    value={discountCode}
                    onChange={handleDiscountCodeChange}
                  >
                    {discountOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
              </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Remarks"
                    variant="outlined"
                    value={remarks}
                    onChange={handleRemarksChange}
                  />
                </Grid>
              </>
                    )}*/}

                <Grid item xs={12}>
                  <Typography variant="h4">Payment Mode</Typography>
                  {paymentModes.length === 0 ? (
                    <Typography variant="h6" m={2}>
                      No Payment Modes Available
                    </Typography>
                  ) : (
                    <RadioGroup
                      row
                      aria-label="paymentMode"
                      name="paymentMode"
                      value={paymentMode}
                      onChange={handlePaymentModeChange}
                    >
                      {paymentModes.map((mode) => (
                        <FormControlLabel
                          key={mode.id}
                          value={mode.id}
                          control={<Radio />}
                          label={mode.payment_mode}
                        />
                      ))}
                    </RadioGroup>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h4">
                    Amount to be Paid: {calculateTotalAmountToBePaid()}{" "}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Typography variant="h6">Payment Status</Typography>
                    <Select
                      size="small"
                      labelId="payment-mode-label"
                      id="payment-mode-label"
                      value={paymentStatus}
                      onChange={handlePaymentStatusChange}
                      style={{ borderRadius: "10px" }}
                    >
                      <MenuItem value="pending">Pending</MenuItem>
                      {/*} <MenuItem value="paid">Paid</MenuItem>*/}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </SubCard>
        </Grid>

        <Grid container justifyContent="right" spacing={0} mt={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              onClick={handleProceedToPayment}
              disabled={paymentOption === "installments" && !isFormValid}
            >
              Submit Payment Details
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={showSuccessPopup}
        autoHideDuration={6000}
        onClose={handleCloseSuccessPopup}
        message="Payment request sent successfully! Email will be sent after confirmation of payment"
        action={
          <Button
            color="inherit"
            size="small"
            onClick={handleCloseSuccessPopup}
          >
            <CheckCircleOutlineIcon fontSize="small" />
          </Button>
        }
      />

      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={showFailedPopup}
        autoHideDuration={6000}
        onClose={handleCloseFailedPopup}
        message={errormessage}
        action={
          <Button color="inherit" size="small" onClick={handleCloseFailedPopup}>
            <CancelIcon fontSize="small" />
          </Button>
        }
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <Backdrop open={isPaymentLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Processing Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
};

export default PaymentDetails;
