import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/material";
export default function ClearButtonComp({ label , onClick, size}) {
  return (
    <>
      <Button
        size={ size ?size :"medium"}
        variant="contained"
        style={{ borderRadius: "8px", background:"#ff4b4b" }}
        startIcon={<ClearIcon style={{ fontSize: "17px" }} />}
        onClick={onClick}
      >
        {label ?label :"Clear"}
      </Button>
    </>
  );
}
