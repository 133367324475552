// Author: Ayesha Shaikh
// Date : 12 aug 2024

import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MainCard from "../../../components/Maincard";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function CounsellingDesk() {
  const [eventList, setEventList] = useState([]);
  const [deskData, setDeskData] = useState([]);
   const [deskId,setDeskid] =useState()
  const userId = localStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const [deskLoading, setDeskLoading] =useState(false)
  const [selectedeventChips, setSelectedeventChips] = useState(null);
  const [selectedDeskChips, setSelectedDeskChips] = useState(null);
const navigate=useNavigate()

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${API_ENDPOINT}eventattendance/fetchcounsellorevents/${userId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 403) {
          // Handle 403 error by showing Snackbar
          showSnackbar("No Ticket Found", "error");
          setLoading(false);
          return; // No need to continue further
        } else if (response.status === 401) {
          setLoading(false);
          return showSnackbar(
            "Unauthorised user or user id is undefined:",
            "error"
          );
       
        } else if (response.status === 404) {
          setLoading(false);
          setEventList([])
          showSnackbar("No events found for today", "error");
        } else if (response.status === 500) {
          setLoading(false);
          showSnackbar("Error Fetching Data", "error");
        }
      })
      .then((data) => {
        if (!data) return; // Stop further execution if data is not present

        setEventList([data]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        showSnackbar("Error fetching data:", "error");
        setLoading(false);
      });
  }, [userId]);

  const handleChipeventClick = (campaignName) => {

    // Toggle selection: if the chip is already selected, deselect it
 
    if (selectedeventChips === campaignName) {
      setSelectedeventChips(null);
    } else {
      setSelectedeventChips(campaignName);
      fetchDeskData(userId, campaignName);
    }
  };

  // Desk

  const handleChipDeskClick = (deskName) => {
    const selecteddeskid =deskData.find((desk)=>desk.id===deskName)
    if (selectedDeskChips === deskName) {
      setSelectedDeskChips(null);
      setDeskid(null)
    } else {
      setSelectedDeskChips(deskName);
      setDeskid(selecteddeskid?.id)
    }
  };

  const fetchDeskData = async (userId, campaignName) => {
    const payload = {
      user_id: parseInt(userId),
      event_id: campaignName,
    };
    setDeskLoading(true)
    try {
      const response = await fetch(
        `${API_ENDPOINT}eventattendance/fetcheventdesks`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Response data:", data);
        setDeskData(data);
        setDeskLoading(false)
        //     navigate('/scanqr-code', { state: { scanData: data } });
      } else if (response.status === 401) {
        setDeskLoading(false)
        showSnackbar("Unauthorised user or user id is undefined", "error");
        setDeskData([]);
        setSelectedDeskChips(null);
      } else if (response.status === 403) {
       
        showSnackbar("please select an event", "error");
        setDeskData([]);
        setSelectedDeskChips(null);
        setDeskLoading(false)
      } else if (response.status === 404) {
       
        showSnackbar("No desk registration found. Contact to Admin", "error");
        setDeskData([]);
        setSelectedDeskChips(null);
        setDeskLoading(false)
      } else {
        showSnackbar("Error Fetching Desk Data", "error");
        setDeskData([]);
        setSelectedDeskChips(null);
        setDeskLoading(false)
      }
    } catch (error) {
      console.error("Error posting data:", error);
      showSnackbar("Error Fetching Desk Data", "error");
      setDeskData([]);
      setSelectedDeskChips(null);
      setDeskLoading(false)
    }
  };

  const eventId=selectedeventChips
  const selectedData={eventId,deskId}

const handleNavigate=()=>{
    navigate('/scanqr-counsellingdesk', { state: { data: selectedData } });
}


  return (
    <>
      <AppBar
        sx={{
          bgcolor: "white",
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1000,
          boxShadow: " 0px 4px 5px 0px rgba(0, 0, 0, 0.3);",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {" "}
            <img src="/Siec Logo.png" width={150}/>
          </Box>
          <Box>
            <Link to="/dashboard">
              <Button
              size="small"
                variant="contained"
                style={{
                  borderRadius: "25px",
                  color: "white",
                  background: "#00b13f",
                }}
                startIcon={<DashboardIcon />}
              >
                Dashboard
              </Button>
            </Link>
            <Link to="/">
              {" "}
              <Button
              size="small"
                color="error"
                variant="contained"
                style={{ borderRadius: "25px" }}
                startIcon={<img src="/icons8-move-up-64.png" width="20px" />}
              >
                Logout
              </Button>
            </Link>
          </Box>
        </Box>
      </AppBar>

      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fething
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Box
        sx={{
          backgroundImage: "url(/istockphoto-1277862772-612x612.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          minHeight: {
            xs: "100%",
            sm: "100%",
            md: "100%", // Medium screens
            lg: "100%", // Large screens
            xl: "100%", // Extra large screens
          }, // Adjust height as needed
          zIndex: 1,
          position: "relative",
        }}
        mt={10}
      >
        <Grid
          container
          spacing={4}
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          {/* Select Event */}
          <Grid item xs={12} md={7} sm={10} lg={7} mt={2}>
            <MainCard>
              {eventList.length===0 ? (
                <Typography sx={{fontWeight:600, fontSize:"18px"}}>No event found for today</Typography>
              ) : ( 
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12} sm={12} md={12}>
                  <Typography
                    textAlign="left"
             variant="h4"
                  >
                    Select Event
                  </Typography>
                  <Box sx={{ textAlign: "left", padding: "5px" }}>
                    {eventList[0]?.events?.map((event) => (
                      <Chip
                 
                        key={event?.id}
                        label={event.campaign_name}
                        variant="outlined"
                        style={{
                          marginLeft: "10px",
                          marginTop: "10px",
                          backgroundColor:
                            selectedeventChips === event.id
                              ? "green"
                              : "transparent", // Set background to green if selected
                          color:
                            selectedeventChips === event.id ? "white" : "black",
                        }}
                        onClick={() => handleChipeventClick(event.id)}
                      />
                    ))}
                  </Box>
                </Grid>
                {/* Select desk */}

                <Grid item xs={12} lg={12} sm={12} md={12}>
                {deskLoading && ( 
                  <Typography > Loading desk Options....</Typography>
                )}
                </Grid>
                {deskData.length > 0 && (
                  <Grid item xs={12} lg={12} sm={12} md={12}>
                    <Typography
                      textAlign="left"
                      sx={{ fontSize: "20px", fontWeight: 600 }}
                    >
                      Select Desk
                    </Typography>
                    <Box sx={{ textAlign: "left", padding: "5px" }}>
                      {deskData.map((event) => (
                        <Chip
                          key={event?.id}
                          label={event.registration_desk}
                          variant="outlined"
                          sx={{
                            marginLeft: "10px",
                            marginTop: "10px",
                            backgroundColor:
                              selectedDeskChips === event.id
                                ? "green"
                                : "transparent", // Set background to green if selected
                            color:
                              selectedDeskChips === event.id
                                ? "white"
                                : "black",
                          }}
                          onClick={() => handleChipDeskClick(event.id)}
                        />
                      ))}
                    </Box>
                  </Grid>
                )}

                <Grid item xs={12} lg={12} sm={12} md={12} textAlign="end">
                  
                    <Button
                    variant="contained"
                    color="error"
                    endIcon={<ArrowForwardIcon />}
                    disabled={
                      selectedeventChips === null || selectedDeskChips === null
                    }
                    onClick={handleNavigate}
                  >
                    Next
                  </Button>
                 
                </Grid>
              </Grid>
              )}
            </MainCard>
          </Grid>
        </Grid>
      </Box>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
