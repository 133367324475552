import React, { useEffect, useState } from "react";

import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import CustomPopup from "../../components/CustomPopup";
import ProductWiseEnrollBreakDown from "./ProductWiseEnrollBreakDown";
import LoaderComp from "../../newTestPrep/newComponent/LoaderComp/Loader";
import { useGetTokenOrLogout } from "../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function TargetvsEnrolled({
  chosenDate,
  targetData,
  advisorId,
}) {
  const userId = localStorage.getItem("userId");
  const getTokenOrLogout = useGetTokenOrLogout();
  const [open, setOpen] = useState(false);
  const [detailsLoding, setDetailsLoading] = useState(false);
  const [detailsData, setDetailsData] = useState([]);

  const [state, setState] = React.useState({
    series: [0],
    options: {
      chart: {
        height: 100,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          gradientToColors: ["#f8af1d"],
          stops: [0, 50, 53, 91],
        },
      },
      colors: ["#f8af1d"],
      labels: ["Enrolled"],
    },
  });

  useEffect(() => {
    const percentage = Math.round(
      ((targetData?.achieved_enrollment || 0) /
        (targetData?.target_enrollment || 1)) *
        100
    );

    setState((prevState) => ({
      ...prevState,
      series: [percentage],
    }));
  }, [targetData]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getEnrolTargetData = async () => {
    setDetailsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),
      date: chosenDate,

      advisor_user_id: parseInt(advisorId),
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/advisor-enrollment-target-achievement-with-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setDetailsData(result);

        setDetailsLoading(false);
        handleOpen();
      } else {
        console.log("Error");
        setDetailsLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      setDetailsLoading(false);
    }
  };
  return (
    <>
      <LoaderComp isLoading={detailsLoding} />
      {targetData?.target_enrollment === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={170}
        >
          <Typography fontWeight={600}>
            No Enrolment Target Available
          </Typography>
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="radialBar"
              height="200px"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} container spacing={1} mt={1}>
            <Grid item xs={12} style={{ cursor: "pointer" }}>
              <Grid container justifyContent="space-between">
                <Typography variant="h6" sx={{ color: "#4B5563" }}>
                  Achieved Enrolment Target{" "}
                </Typography>
                <Typography variant="h6" sx={{ color: "#4B5563" }}>
                  {" "}
                  {targetData?.achieved_enrollment}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ cursor: "pointer" }}>
              <Grid container justifyContent="space-between">
                <Typography variant="h6" sx={{ color: "#4B5563" }}>
                  Total Enrolment Target{" "}
                </Typography>
                <Typography variant="h6" sx={{ color: "#4B5563" }}>
                  {" "}
                  {targetData?.target_enrollment}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} textAlign="center">
              <Button
                size="small"
                color="secondary"
                variant="text"
                onClick={getEnrolTargetData}
              >
                {" "}
                View Breakdown
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      <CustomPopup
        open={open}
        onClose={handleClose}
        component={
          <ProductWiseEnrollBreakDown
            chosenDate={chosenDate}
            data={detailsData}
          />
        }
        title="Product Wise Enrollment Target Breakdown"
        maxWidth="md"
        showDivider={true}
      />
    </>
  );
}
