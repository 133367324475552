import {
    Autocomplete,
    Button,
    Grid,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Controller, useForm } from "react-hook-form";
  import { useNavigate } from "react-router-dom";
  import { useGetTokenOrLogout } from "../../../utils/token";
  import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
  import CustomAutocompleteComp from "../../newComponent/AutocompleteComp";
  import CustomTextFieldComp from "../../newComponent/customTextFieldComp";
  
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  export default function EditProductTarget({ selectedData, onClose }) {
    const userId = localStorage.getItem("userId");
    const navigate = useNavigate();
    const getTokenOrLogout = useGetTokenOrLogout();
    const [changes, setChanges] = useState({});
    const [advisorList, setAdvisorList] = useState([]);
    const [monthList, setMonthList] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
    const showSnackbar = (message, severity) => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    };
  
    console.log("selectedData", selectedData);
    const {
      handleSubmit,
      control,
      setValue,
      formState: { errors },
      setError,
    } = useForm();
  
    // Edit all Fields
    const handleFieldChange = (fieldName, value) => {
      setChanges((prevChanges) => ({
        ...prevChanges,
        [fieldName]: value,
      }));
      setValue(fieldName, value);
    };
  
    const onSubmit = async (data) => {
      const token = getTokenOrLogout();
      if (!token) return;
      let headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      try {
        const requestData = {
          ...changes,
          user_id: parseInt(userId),
        };
        const response = await fetch(
          `${API_ENDPOINT}advisor-rev-target/${selectedData?.id}`,
          {
            method: "PUT",
            headers,
            body: JSON.stringify(requestData),
          }
        );
        if (response.ok) {
          showSnackbar("Product Target Updated Successfully", "success");
            onClose()
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          showSnackbar("Error in update employee target", "error");
        }
      } catch (error) {
        showSnackbar("Error in update employee target", "error");
      }
    };
  
    return (
      <>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
          <Typography variant="h6">
            {" "}
            Product Name{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography> 
          <Controller
            name="product_id"
            control={control}
            //rules={{ required: true }}
            render={({ field }) => (
                  <CustomTextFieldComp
                    {...field}
                    value={selectedData?.product_id?.name}
                    fullWidth
                    size="normal"
                    error={errors.product_id}
                    helperText={errors.product_id && "Product is required"}
                    InputProps={{
                      readOnly: true, 
                      style: { borderRadius: "14px", backgroundColor: "#f0f0f0" }, 
                    }}        
                  />          
            )}
          ></Controller>
            
          </Grid>
          <Grid item xs={12} sm={4}>
          <Typography  variant="h6">
            Add Revenue Target{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="rev_target" 
            control={control}
            rules={{ required: true }}
            defaultValue={selectedData?.rev_target}
            render={({ field }) => (
              <CustomTextFieldComp
                size="normal"
                {...field}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                onChange={(e) =>
                    handleFieldChange("rev_target", e.target.value)
                  } 
                fullWidth
                error={!!errors.rev_target}
                helperText={errors.rev_target && "Revenue Target is required"}
              />
            )}
          />
          </Grid>
          <Grid item xs={12} sm={4}>
          <Typography  variant="h6">
            Add Enrolment Target{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="enrolment_target"
            control={control}
            rules={{ required: true }}
            defaultValue={selectedData?.enrolment_target}
            render={({ field }) => (
              <CustomTextFieldComp
                size="normal"
                {...field}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                onChange={(e) =>
                    handleFieldChange("enrolment_target", e.target.value)
                  } 
                fullWidth
                error={!!errors.enrolment_target}
                helperText={errors.enrolment_target && "Enrolment Target is required"}
              />
            )}
          />
          </Grid>
        </Grid>

        <Grid item xs={12} textAlign="end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Grid>
  
        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </>
    );
  }