import { Box, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function PrductBaseRvenueStats({ data }) {
  const pageSize = 5; // Number of data points to show per page
  const [currentIndex, setCurrentIndex] = useState(0); // Tracks the current pagination index

  const [state, setState] = useState({
    series: [
      {
        name: "Collected Revenue",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "15px",
          borderRadius: 5,
          borderRadiusApplication: "end",
        },
      },
      colors: ["#00B13F"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [], // Initially empty categories
        labels: {
          style: {
            fontSize: "9px",
          },
        },
      },
      yaxis: {
        title: {
          text: "Revenue Collected (₹)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `₹ ${val}`;
          },
        },
      },
    },
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const sortedData = [...data].sort(
        (a, b) => b.collected_revenue - a.collected_revenue
      );
      if (currentIndex >= data.length) {
        setCurrentIndex(0);
      } else {
        const paginatedData = sortedData.slice(
          currentIndex,
          currentIndex + pageSize
        );
        const productName = paginatedData.map(
          (product) => product.test_type_id?.name
        );
        const collectedRevenue = paginatedData.map(
          (branch) => parseFloat(branch.collected_revenue) || 0
        );

        setState((prevState) => ({
          ...prevState,
          series: [
            {
              name: "Collected Revenue",
              data: collectedRevenue,
            },
          ],
          options: {
            ...prevState.options,
            xaxis: {
              categories: productName,
            },
          },
        }));
      }
    }
  }, [data, currentIndex, pageSize]); // Re-run effect when data, currentIndex, or pageSize changes

  const totalPages = Math.ceil((data?.length || 0) / pageSize);
  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={260}
      />

      <Box>
        <Pagination
          size="small"
          count={totalPages}
          page={Math.ceil(currentIndex / pageSize) + 1}
          onChange={(event, value) => {
            const newIndex = (value - 1) * pageSize;
            setCurrentIndex(newIndex);
          }}
          color="secondary"
          sx={{
            justifyContent: "end",
            display: "flex",
            "& .MuiPaginationItem-root": {
              fontSize: "0.6rem",
            },
          }}
        />
      </Box>
    </>
  );
}
