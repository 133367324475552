import { Card, Typography } from "@mui/material";
import React from "react";

export default function HeadingCard({
  children,
  height,
  width,
  label,
  imageSrc,
  imgewidth,
}) {
  return (
    <Card
      style={{
        borderRadius: "25px",
        padding: "7px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap:imageSrc ? 10 :'',
      }}
    >
      <img src={imageSrc} width={imgewidth} />
      <Typography variant="h3" textAlign="center">
        {label}
      </Typography>
      {children}
    </Card>
  );
}
