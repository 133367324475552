import { Backdrop, Typography } from '@mui/material'
import React from 'react'

export default function ClockLoader({isLoading}) {
  return (
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
           <Typography variant="h2" color="error" fontWeight={800} marginRight={2}>
             Fetching
           </Typography>
           <img
             src="/sand-clock.png"
             alt="Error"
             style={{ width: "200px", height: "200px" }}
           />
         </Backdrop>
  )
}
