import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NewCardcomp from "../../../components/card/NewCardcomp";
import ReactApexChart from "react-apexcharts";
import StyledTableContainer from "../../../newTestPrep/newComponent/TableContainer";
import CustomPopup from "../../../components/CustomPopup";
import EnrollDetails from "../../AllUserDashboards/CenterAdminDashboard/EnrollDetails";
import MoreCardDetails from "../../AllUserDashboards/CenterAdminDashboard/MoreCardDetails";
import LoaderComp from "../../../newTestPrep/newComponent/LoaderComp/Loader";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function ProductBaseDetails({
  data,
  selectedFilter,
  fromDate,
  toDate,
  advisorId,
}) {
  const userId = localStorage.getItem("userId");
  const getTokenOrLogout = useGetTokenOrLogout();

  const [opendetailPopup, setOpenDetailPopup] = useState(false);
  const [selectedData, setselectedData] = useState([]);
  const [selectedDataName, setSelectedDataname] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  const [productData, setProductData] = useState(data);

  // detailPopup
  const handleOpenDetailPopup = (Name, filterType) => {
    setOpenDetailPopup(true);
    setSelectedDataname(Name);
    setSelectedFilterType(filterType);
  };

  const handleCloseDetailPopup = () => {
    setOpenDetailPopup(false);
  };

  const getnumberInfo = async (cardName, testTypeId, filterType) => {
    setDetailsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      user_id: parseInt(userId),
      filter: selectedFilter,
      to_date: toDate,
      from_date: fromDate,
      advisor_user_id: parseInt(advisorId),
      filter_type: filterType,
      test_type_id: testTypeId,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/gettodayrevenue-by-test-type-with-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setselectedData(result);
        setDetailsLoading(false);
        handleOpenDetailPopup(cardName, filterType);
      } else {
        console.log("Error");
        // showSnackbar("Server Error", "error");
        setDetailsLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      // showSnackbar("Server Error", "error");
      setDetailsLoading(false);
    }
  };

  // Sort data and addted total row
  useEffect(() => {
    const sortedData = [...data].sort(
      (a, b) => b.total_revenue - a.total_revenue
    );
    const totalEnrol = sortedData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.total_enrollment;
    }, 0);

    const onlineEnrol = sortedData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.online_enrollment;
    }, 0);

    const offlineEnrol = sortedData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.offline_enrollment;
    }, 0);

    const totalRevenue = sortedData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.total_revenue;
    }, 0);

    const collectedRevenue = sortedData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.collected_revenue;
    }, 0);
    const pendingRevenue = sortedData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.pending_revenue;
    }, 0);
    const totalData = [
      ...sortedData,
      {
        test_type_id: { name: "Total" },
        total_enrollment: totalEnrol,
        online_enrollment: onlineEnrol,
        offline_enrollment: offlineEnrol,
        total_revenue: totalRevenue,
        collected_revenue: collectedRevenue,
        pending_revenue: pendingRevenue,
      },
    ];

    setProductData(totalData);
  }, [data]);

  return (
    <>
      <LoaderComp isLoading={detailsLoading} />
      <Grid spacing={2}>
        <Grid item xs={12}>
          <StyledTableContainer component={Paper}>
            <Table style={{ background: "#F9F9F9" }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Total Enrolments</TableCell>
                  <TableCell>Online Enrolments</TableCell>
                  <TableCell>Offline Enrolments</TableCell>
                  <TableCell>Total Revenue</TableCell>
                  <TableCell>Collected Revenue</TableCell>
                  <TableCell>Pending Revenue</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productData?.length === 0 ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "11px",
                        color: "#000000",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                ) : (
                  productData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          color:
                            index === productData.length - 1
                              ? "black"
                              : "inherit",
                          fontWeight:
                            index === productData.length - 1
                              ? "bold"
                              : "normal",
                        }}
                      >
                        {row?.test_type_id?.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          cursor: index !== productData.length - 1 ? "pointer" :"",
                          color:
                            index === productData.length - 1 ? "black" : "blue",
                          fontWeight:
                            index === productData.length - 1
                              ? "bold"
                              : "normal",
                        }}
                        onClick={() =>
                         index !== productData.length - 1 ? 
                          getnumberInfo(
                            "Total Enrollments",

                            row?.test_type_id?.id,
                            "total_enrollment"
                          ):undefined
                        }
                      >
                        {row?.total_enrollment}
                      </TableCell>
                      <TableCell
                        sx={{
                          cursor: index !== productData.length - 1 ? "pointer" :"",
                          color:
                            index === productData.length - 1 ? "black" : "blue",
                          fontWeight:
                            index === productData.length - 1
                              ? "bold"
                              : "normal",
                        }}
                        onClick={() =>
                          index !== productData.length - 1 ? 
                          getnumberInfo(
                            "Online Enrollments",

                            row?.test_type_id?.id,
                            "online_enrollment"
                          ):undefined
                        }
                      >
                        {" "}
                        {row?.online_enrollment}
                      </TableCell>
                      <TableCell
                        sx={{
                          cursor: index !== productData.length - 1 ? "pointer" :"",
                          color:
                            index === productData.length - 1 ? "black" : "blue",
                          fontWeight:
                            index === productData.length - 1
                              ? "bold"
                              : "normal",
                        }}
                        onClick={() =>
                          index !== productData.length - 1 ? 
                          getnumberInfo(
                            "Offline Enrollments",

                            row?.test_type_id?.id,
                            "offline_enrollment"
                          ):undefined
                        }
                      >
                        {row?.offline_enrollment}
                      </TableCell>
                      <TableCell
                        sx={{
                          cursor: index !== productData.length - 1 ? "pointer" :"",
                          color:
                            index === productData.length - 1 ? "black" : "blue",
                          fontWeight:
                            index === productData.length - 1
                              ? "bold"
                              : "normal",
                        }}
                        onClick={() =>
                          index !== productData.length - 1 ? 
                          getnumberInfo(
                            "Total Revenue",
                            row?.test_type_id?.id,
                            "total_revenue"
                          ):undefined
                        }
                      >
                        {row?.total_revenue}/-
                      </TableCell>
                      <TableCell
                        sx={{
                          cursor: index !== productData.length - 1 ? "pointer" :"",
                          color:
                            index === productData.length - 1 ? "black" : "blue",
                          fontWeight:
                            index === productData.length - 1
                              ? "bold"
                              : "normal",
                        }}
                        onClick={() =>
                          index !== productData.length - 1 ? 
                          getnumberInfo(
                            "Collected Revenue",
                            row?.test_type_id?.id,
                            "collected_revenue"
                          ):undefined
                        }
                      >
                        {row?.collected_revenue}/-
                      </TableCell>
                      <TableCell
                        sx={{
                          cursor: index !== productData.length - 1 ? "pointer" :"",
                          color:
                            index === productData.length - 1 ? "black" : "blue",
                          fontWeight:
                            index === productData.length - 1
                              ? "bold"
                              : "normal",
                        }}
                        onClick={() =>
                          index !== productData.length - 1 ? 
                          getnumberInfo(
                            "Pending Revenue",
                            row?.test_type_id?.id,
                            "pending_revenue"
                          ):undefined
                        }
                      >
                        {row?.pending_revenue}/-
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
      </Grid>

      <CustomPopup
        open={opendetailPopup}
        onClose={handleCloseDetailPopup}
        component={
          selectedFilterType === "online_enrollment" ||
          selectedFilterType === "offline_enrollment" ||
          selectedFilterType === "total_enrollment" ? (
            <EnrollDetails
              onClose={handleCloseDetailPopup}
              selectedFilter={selectedFilter}
              SelectedCardData={selectedData}
              fromDate={fromDate}
              toDate={toDate}
            />
          ) : (
            <MoreCardDetails
              onClose={handleCloseDetailPopup}
              selectedFilter={selectedFilter}
              SelectedCardData={selectedData}
              selectedCardName={selectedDataName}
              selectedCountFilter={selectedFilterType}
              fromDate={fromDate}
              toDate={toDate}
            />
          )
        }
        title={`${selectedDataName} Details`}
        maxWidth="lg"
        showDivider={true}
      />
    </>
  );
}
