import { Button, Grid, Typography } from '@mui/material'
import React from 'react'

export default function DeleteClass({onClose,handleDeleteslote}) {

   
    const handleCancel =()=>{
        onClose() 
    }
    const handleSubmit=()=>{
        handleDeleteslote()
    }
  return (
   <>
   <Grid container spacing={2}>
    <Grid item xs={12} textAlign='center'>
        <Typography variant='h4'>Are you sure to deactivate this class ?</Typography>
    </Grid>
    <Grid item xs={12} container gap={1} justifyContent='end'> 
    <Button variant="outlined" color="error" size="small" onClick={handleCancel}> Cancel</Button>
        <Button variant="contained" color="error" size="small" onClick={handleDeleteslote}> Deactivate</Button>

    </Grid>

   </Grid>
   </>
  )
}
