import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Alert, Grid, Link, Snackbar, Stack, Tooltip, Typography } from '@mui/material';

// assets
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { fDateTime } from '../../../utils/formatTime';
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar';
import { useEffect, useState } from 'react';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

// item drag wrapper
const getDragWrapper = ( theme, radius) => {
    const bgcolor = theme.palette.mode === 'dark' ? theme.palette.background.paper + 90 : theme.palette.grey[50];
    return {
        userSelect: 'none',
        margin: `0 0 ${8}px 0`,
        padding: 16,
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
        backgroundColor: bgcolor,
        borderRadius: 20,
       // ...draggableStyle
    };
};


const Items = ({ item }) => {
    const theme = useTheme();
    const userId = localStorage.getItem('userId');
    const [isDownloadAccess, setIsDownloadAccess] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

   const handlerDetails = (id) => {
       // dispatch(selectItem(id));
    };

    const copyNote = (noteContent) => {
        navigator.clipboard.writeText(noteContent).then(
          () => {
            setSnackbarMessage("Note copied to clipboard!");
            setSnackbarOpen(true);
          },
          (err) => {
            setSnackbarMessage("Error copying note!");
            setSnackbarOpen(true);
          }
        );
      };

      const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
      };

      useEffect(() => {
        fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
          .then((response) => {
            if (response.ok) {
              setIsDownloadAccess(true);
            } else {
              setIsDownloadAccess(false);
            }
          })
          .catch((err) => {
            setIsDownloadAccess(false);
          });
      }, [userId]);

    return (
            <>
            <div style={getDragWrapper(theme)}>
                <Grid item xs={12}>
                    <Stack direction="column" justifyContent="space-between" alignItems="center" sx={{ mb: item.advisor_id ? -0.75 : 0 }}>
                        <Typography
                            onClick={() => handlerDetails(item.id)}
                            variant="h6"
                            style={{ whiteSpace: "pre-wrap", wordWrap: "break-word",  wordBreak: "break-word" }}
                        >
                            {item.note}
                        </Typography>
                        
                        <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography 
                            onClick={() => handlerDetails(item.id)}
                            variant="h5"
                            color='error'
                            sx={{
                                display: 'inline-block',
                                verticalAlign: 'middle', 
                            }}
                        >
                            {item.note_by?.first_name} {item.note_by?.last_name}
                        </Typography>
                        {isDownloadAccess && (
                        <Tooltip title="Copy Note">
                            <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: '0.7rem', color:"#00b13f" }} onClick={() => copyNote(item?.note)}/>
                        </Tooltip>
                        )}
                        </Stack>

                 
                        <Stack direction="row" spacing={0.5} alignItems="center">
                        <Tooltip title="By">
                            <MenuBookTwoToneIcon color="secondary" sx={{ fontSize: '0.7rem', color:"#00b13f" }} />
                        </Tooltip>
                        <Tooltip title={item.note}>
                                    <Link
                                        variant="caption"
                                  
                                        underline="hover"
                                        sx={{ cursor: 'pointer', pt: 0.5 , color:"#00b13f" , fontSize:'0.6rem'}}
                                    >
                                        {fDateTime(item.createdAt)}
                                    </Link>
                                </Tooltip>
                            </Stack>
                    </Stack>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={3000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    >
                        <Alert 
                            onClose={handleCloseSnackbar} 
                            severity="success" 
                            sx={{ width: "100%" }}
                            elevation={6}
                            variant="filled"
                            >
                        {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Grid>
            </div>
            
            </>         
        );
};

Items.propTypes = {
    index: PropTypes.number,
    item: PropTypes.object
};

export default Items;