import React, { useContext, useEffect, useState } from 'react'
import './Navbar.css'
import { Badge, Button, IconButton, Menu, MenuItem, Tooltip, useMediaQuery } from '@mui/material';
import AccountPopover from '../../components/header/AccountPopover';
import { styled, alpha, createTheme, ThemeProvider } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import ChatIcon from '@mui/icons-material/Chat';
import ListAltIcon from '@mui/icons-material/ListAlt';
import NotificationsPopover from '../../components/header/NotificationPopover';
import CallIcon from '@mui/icons-material/Call';
import CachedIcon from '@mui/icons-material/Cached';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import BadgeIcon from '@mui/icons-material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';
import CreatePopup from '../dialog/CreatePopup';
import TaskEfficiencyPopup from '../../section/tasks/TaskEfficiencyPopup';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import TaskReportTabs from '../../section/tasks/TaskTableReport';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const theme = createTheme();

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));

function AdvisorNavbar() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate()
  const advisorId = localStorage.getItem('advisorId');

  const [isMenuOpen, setMenuOpen] = useState(null);
  const [ taskEfficiencyData, setTaskEfficiencyData] = useState([])
  const [isTaskReportOpen, setTaskReportOpen] = useState(false); 
  const [isTaskReportEfficiencyOpen, setTaskReportEfficiencyOpen] = useState(false); 

  const handleTaskReportOpen = () => {
    setTaskReportOpen(true);
  };

  const handleTaskReportClose = () => {
    setTaskReportOpen(false);
  };

  const handleTaskEfficiencyReportOpen = () => {
    setTaskReportEfficiencyOpen(true);
  };

  const handleTaskReportEfficiencyClose = () => {
    setTaskReportEfficiencyOpen(false);
  };


  const handleMenuOpen = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuOpen(null);
  }

  const handleMenuClose1 = () => {
    setMenuOpen(null);
    navigate('/advisor/addenquirynew')
  };

  const handleMenuClose2 = () => {
    setMenuOpen(null);
    navigate('/advisor/incoming-call')
  };

  const handleMenuClose3 = () => {
    setMenuOpen(null);
    navigate('/advisor/walk-in-form')
  };

  const navigateToZoomLink = () => {
    const zoomLink = 'https://zoom.us/j/97485138279?pwd=Y1cwcXRHUWZWOFN3aTZOdmhHZm4yQT09#success';

    //navigate(zoomLink);
    window.location.href = zoomLink;
  };

  const fetchAdvisorTaskEfficiency = async (advisorId) => {
    //setIsLoading(true);
    try {
      const response = await fetch(`${API_ENDPOINT}advisordashboard/taskefficiency/${advisorId}`);
      const data = await response.json();
      setTaskEfficiencyData(data)
     
       // setIsLoading(false);
    } catch (error) {
     
      //setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchAdvisorTaskEfficiency(advisorId)
  }, [])


  return (
    <nav className='navbar'>
        <div className='navbar__left'>
        <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          
        </div> 
        <div className='navbar__right'>
        {!isSmallScreen && (
          <>
            <Button variant='outlined' onClick={navigateToZoomLink}>Join SVO Link</Button>

            <Tooltip title='Today Task Efficiency'>
            <IconButton onClick={handleTaskEfficiencyReportOpen} >
            <QueryStatsIcon />
            </IconButton>
            </Tooltip>

            <Tooltip title='My Task Report'>
            <IconButton onClick={handleTaskReportOpen}>
            <ListAltIcon />
            </IconButton>
            </Tooltip>

            <Tooltip title='Add Enquiry'>
            <IconButton  onClick={handleMenuOpen}>
            <PersonAddIcon />
            </IconButton>
            </Tooltip>

            <Menu
              anchorEl={isMenuOpen}
              open={Boolean(isMenuOpen)}
              onClose={handleMenuClose}
              >
            <MenuItem onClick={handleMenuClose1}>Add Enquiry</MenuItem>
            <MenuItem onClick={handleMenuClose2}>Register Incoming Call</MenuItem>
            <MenuItem onClick={handleMenuClose3}>Register Walk In</MenuItem>
            </Menu>

            <NotificationsPopover />

            <Tooltip title='Reminders'>
            <IconButton >
            <Badge badgeContent={1} color="primary">
              <AccessAlarmIcon color="action" />
            </Badge>
            </IconButton>
            </Tooltip> 
          </>
        )}
        <AccountPopover />
            </div>
            <CreatePopup
                open={isTaskReportEfficiencyOpen}
                onClose={handleTaskReportEfficiencyClose}
                 title='Today Task Efficiency'
                  component={
              <TaskEfficiencyPopup data={taskEfficiencyData} /> 
        }
      />

            <CreatePopup
                open={isTaskReportOpen}
                onClose={handleTaskReportClose}
                 title='My Task Report'
                  component={
              <TaskReportTabs /> 
        }
      />
        </nav>
  )
}

export default AdvisorNavbar