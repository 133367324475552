import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import Walkins from "./Walkins";
import CustomSnackbar from "../../../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { fDate, fDateTime, fTimestamp } from "../../../../../utils/formatTime";
import EventEditForm from "./EventEditForm";
import CustomPopup from "../../../../../components/CustomPopup";
import CustomNoRowsOverlay from "../../../../../newTestPrep/newComponent/TableCustomeComp/CustomNoRowsOverlay";
import CustomAutocompleteComp from "../../../../../newTestPrep/newComponent/AutocompleteComp";

export default function EventRegistration({ enquiryData }) {
  console.log(enquiryData);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const enquiryId = enquiryData.id;
  const [eventlist, seteventlist] = useState([]);
  const [events, setEvents] = useState(null);
  const [registeredCountries, setRegisteredCountries] = useState([]);
  const [country, setcountry] = useState([]);
  const [status, setStatus] = useState("");

  const [eventId, setEventId] = useState(null);
  const [reasonForNoRegistration, setReasonForNoRegistration] = useState(null);
  const [eventData, setEventData] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [loading, setLoading] = useState(false);
  const [RegistrationList, setRegistrationList] = useState([]);
  const [eventLoading, setEventLoading] = useState(false);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [openPopup, setOpenPopup] = useState(false);
  const [selctedData, setSelectData] = useState(null);

  const handleOpen = (data) => {
    setOpenPopup(true);
    setSelectData(data);
  };
  const handleClose = () => {
    setOpenPopup(false);
  };
  console.log("data", country);
  const columns = [
    {
      field: "eventName",
      headerName: "Event Name",
      width: 350,

      renderCell: (params) => <div>{params.row?.event_id?.campaign_name}</div>,
    },
    {
      field: "registrationStatus",
      headerName: "Registration Status",
      width: 200,

      renderCell: (params) => <div>{params.row?.registration_status}</div>,
    },
    {
      field: "reason",
      headerName: "Reason for No Registration",
      width: 200,

      renderCell: (params) => (
        <div>{params.row?.reason_for_no_registration}</div>
      ),
    },
    {
      field: "visitatedStatus",
      headerName: "Visited Status",
      width: 150,

      renderCell: (params) => (
        <div>{params.row?.is_attended ? "Yes" : null}</div>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      width: 200,

      renderCell: (params) => <div>{params.row?.country_id.join(" , ")}</div>,
    },
    {
      field: "registeredBy",
      headerName: "Registerd By",
      width: 150,

      renderCell: (params) => (
        <div>
          {params.row?.registered_by?.first_name}{" "}
          {params.row?.registered_by?.last_name}
        </div>
      ),
    },
    {
      field: "registeredDate",
      headerName: "Registerd Date",
      width: 150,

      renderCell: (params) => <div>{fDate(params.row?.createdAt)}</div>,
    },
    {
      field: "registeredTime",
      headerName: "Registered Time",
      width: 150,

      renderCell: (params) => {
        const convertToAmPm = (time24) => {
          if (!time24) return "";
          const [hours, minutes] = time24.split(":").map(Number);
          const amPm = hours >= 12 ? "PM" : "AM";
          const hours12 = hours % 12 || 12;
          return `${hours12.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")} ${amPm}`;
        };

        const time12HourFormat = convertToAmPm(params.row?.registered_time);

        return <div>{time12HourFormat}</div>;
      },
    },

    {
      field: "modifiedBy",
      headerName: "Modified By",
      width: 150,

      renderCell: (params) => (
        <div>
          {params.row?.modified_by?.first_name}{" "}
          {params.row?.modified_by?.last_name}
        </div>
      ),
    },
    {
      field: "confirmbystudent",
      headerName: "Confirm By Student",
      width: 150,
    },
    {
      field: "confirmbyuser",
      headerName: "Confirm By User",
      width: 150,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,

      renderCell: (params) => (
        <div>
          <Button
            onClick={() => handleOpen(params.row)}
            variant="contained"
            color="secondary"
            size="small"
          >
            {" "}
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const rows = [];

  useEffect(() => {
    const fetchData = async () => {
      setEventLoading(true);
      try {
        const response = await fetch(`${API_ENDPOINT}campaign/event/withname`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          const filteredcampaign = data.filter(
            (campaign) => campaign.campaign_type_id?.id === 1
          );
          seteventlist(filteredcampaign);
          setEventLoading(false);

          console.log("data1", filteredcampaign);
        } else {
          setEventLoading(false);
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        setEventLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleEventChange = (event, value) => {
    const selectedEvent = eventlist.find((item) => item.id === value?.id);
    console.log("event is", selectedEvent);
    setEvents(selectedEvent);
    setRegisteredCountries(selectedEvent?.country_id || []);
    console.log(registeredCountries);
  };
  const handelcountry = (value) => {
    console.log("Registered Country", value);
    setcountry(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}eventregistrationstatus`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);

          setRegistrationList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handelstatus = (e) => {
    setStatus(e.target.value);
    console.log("status", e.target.value);
  };

  const handleReasonForNoRegistration = (e) => {
    setReasonForNoRegistration(e.target.value);
  };

  const handleSubmit = () => {
    if (events === null) {
      showSnackbar("Please Select Event", "error");
      return;
    }
    if (status === "") {
      showSnackbar("Please select registration status", "error");
      return;
    }

    const formdata = {
      enquiry_id: enquiryId,
      event_id: events.id,
      registration_status: status,
      country_id: country,
      reason_for_no_registration: reasonForNoRegistration,
      user_id: parseInt(userId),
    };

    fetch(`${API_ENDPOINT}eventregistration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formdata),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Event Registered Successfully.");
          // Handle success, e.g., show a success message to the user
          showSnackbar("Event Registered Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (response.status === 404) {
          console.error("Error: Not Found");
          showSnackbar("Error", "error");
          // Handle 404 error
        } else if (response.status === 409) {
          console.error("Enquiry already registered for this event");
          showSnackbar("Enquiry Already Registered For This Event.", "error");
        } else if (response.status === 500) {
          console.error("Error: Internal Server Error");
          showSnackbar("Internal Server Error", "error");
        } else {
          console.error("Error: Unknown Error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle network errors or other issues
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}route2.0/fetcheventregistration/${enquiryId}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setEventData(sortedData);
          setLoading(false);
        } else {
          setLoading(false);
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  console.log("data", eventData);
  const autoHeight = "200px";

  const noOptionsText = "Loading...";

  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6} md={6} lg={5}>
          <FormControl fullWidth>
            <Typography variant="h6">Event Name</Typography>

            {/* <Select
              value={events?.id}
              onChange={handleEventChange}
              style={{ borderRadius: "15px" }}
              MenuProps={MenuProps}
            
            >
              {eventlist.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.campaign_name}
                </MenuItem>
              ))}
            </Select> */}
            <CustomAutocompleteComp
              size="small"
              value={events}
              noOptionsText={
                eventLoading && eventlist.length === 0 ? noOptionsText : ""
              }
              onChange={handleEventChange}
              options={eventlist}
              getOptionLabel={(option) => option.campaign_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                    endAdornment: (
                      <>
                        {eventLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3}>
          <FormControl fullWidth>
            <Typography variant="h6">Registration Status</Typography>
            <Select
              size="normal"
              value={status}
              onChange={handelstatus}
              style={{ borderRadius: "10px" }}
            >
              {RegistrationList.map((item) => (
                <MenuItem key={item.id} value={item.registration_status}>
                  {item.registration_status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {status == "Not Registered" && (
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <FormControl fullWidth>
              <Typography variant="h6">Reason For No Registration</Typography>
              <Select
                size="normal"
                value={reasonForNoRegistration}
                onChange={handleReasonForNoRegistration}
                style={{ borderRadius: "10px" }}
              >
                <MenuItem value="Location Issues">Location Issues</MenuItem>
                <MenuItem value="Not Interested">Not Interested</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={6} lg={3}>
          {/* <FormControl fullWidth>
            <Typography variant="h6">
              Registered Country
            </Typography>
            <Select
            multiple
              value={country}
              onChange={handelcountry}
              style={{ borderRadius: "15px" }}
            >
              {registeredCountries.map((country, index) => (
                <MenuItem key={index} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <Typography variant="h6">Registered Country</Typography>
          <CustomAutocompleteComp
            size="small"
            disablePortal={false}
            fullWidth
            multiple
            onChange={(event, value) => {
              handelcountry(value); // Passing only the value
            }}
            options={registeredCountries}
            getOptionLabel={(option) => option}
          tooltipContent={(option) => option}
          />
        </Grid>
        <Grid item lg={1}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            style={{
              marginTop: "20px",
            }}
            onClick={handleSubmit}
            disabled={enquiryData.is_active === 0}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <div style={{ width: "100%", marginTop: "10px" }}>
        {loading ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={eventData}
            columns={columns}
            autoHeight={eventData.length === 0 && autoHeight}
            slots={{
              noRowsOverlay: () => (
                <CustomNoRowsOverlay message="No Data Available" />
              ),
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            sx={{
              marginTop: "20px",
              height: eventData.length > 6 ? "400px" : "auto",
            }}
            pageSizeOptions={[25, 50, 100]}
          />
        )}
      </div>

      <Typography marginTop={4} variant="h4" fontWeight={700}>
        Walk-ins
      </Typography>
      <Walkins enquiryData={enquiryData} />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <CustomPopup
        open={openPopup}
        onClose={handleClose}
        component={
          <EventEditForm
            selectedData={selctedData}
            onClose={handleClose}
            setEventData={setEventData}
            regstatus={status}
            reason={reasonForNoRegistration}
          />
        }
        title="Edit Registration "
        maxWidth="xs"
        showDivider={true}
      />
    </>
  );
}
