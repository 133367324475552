import { Button } from '@mui/material'
import React from 'react'
import EmailIcon from "@mui/icons-material/Email";
export default function SendEmailButton({onClick}) {
  return (
<>
<Button
startIcon={<EmailIcon/>}
        variant="contained"
        size="medium"
        style={{ background: "#00b13f" ,borderRadius:'8px'}}
        onClick={onClick}
      >
      Send Email
      </Button>
</>
  )
}
