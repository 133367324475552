import {
  Backdrop,
  Box,
  Button,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import StyledTableContainer from "../../../newTestPrep/newComponent/TableContainer";
import EnrollDetails from "./EnrollDetails";
import CustomPopup from "../../../components/CustomPopup";
import MoreCardDetails from "./MoreCardDetails";
import LoaderComp from "../../../newTestPrep/newComponent/LoaderComp/Loader";
import { useGetTokenOrLogout } from "../../../utils/token";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function DetailsStats({
  data,
  isPopup,
  selectedFilter,
  fromDate,
  toDate,
}) {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();

  const userId = localStorage.getItem("userId");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(!isPopup ? 9 : 14);
  const [opendetailPopup, setOpenDetailPopup] = useState(false);
  const [selectedData, setselectedData] = useState([]);
  const [selectedDataName, setSelectedDataname] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  const [productData, setProductData] = useState(data);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

 

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // detailPopup
  const handleOpenDetailPopup = (Name, filterType) => {
    setOpenDetailPopup(true);
    setSelectedDataname(Name);
    setSelectedFilterType(filterType);
  };

  const handleCloseDetailPopup = () => {
    setOpenDetailPopup(false);
  };

  const getCardMoreInfo = async (cardName, id, filterType) => {
    setDetailsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const requestData = {
      user_id: parseInt(userId),
      filter: selectedFilter,
      to_date: toDate,
      from_date: fromDate,
      branch_id: id,
      filter_type: filterType,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route/highestrevenuecenters-with-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setselectedData(result);
        setDetailsLoading(false);
        handleOpenDetailPopup(cardName, filterType);
      } else {
      
        // showSnackbar("Server Error", "error");
        setDetailsLoading(false);
      }
    } catch (error) {
  
      // showSnackbar("Server Error", "error");
      setDetailsLoading(false);
    }
  };

  // data which have to pass with state
  const stateData = {
    selectedFilter: selectedFilter,
    fromDate: fromDate,
    toDate: toDate,
    selctedBranch: data,
  };

  // redirect to centers report page
  const handleshowMore = (id, name) => {
    navigate(`/centerreport`, {
      state: { stateData },
    });
  };

  // const handleViewMore = (id, name) => {
  //   const state = { branchName: name, selectedFilter };

  //   // Store state in sessionStorage
  //   sessionStorage.setItem("branchState", JSON.stringify(state));

  //   // Open new tab
  //   window.open(`/centerdashboard/${id}`, "_blank");

  // };

  const passedStateData = {
    selectedFilter: selectedFilter,
    fromDate: fromDate,
    toDate: toDate,
  };
  const handleViewMore = (id, branchName) => {
    const passedStateData1 = { ...passedStateData, branchName }; // Add branchName to the object

    navigate(`/centerdashboard/${id}`, {
      state: { passedStateData: passedStateData1 }, // Ensure correct key
    });
  };

  useEffect(() => {
    const sortedData = [...data].sort(
      (a, b) => b.total_revenue - a.total_revenue
    );
    const totalEnrol = sortedData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.total_enrollment;
    }, 0);

    const onlineEnrol = sortedData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.online_enrollment;
    }, 0);

    const offlineEnrol = sortedData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.offline_enrollment;
    }, 0);

    const totalRevenue = sortedData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.total_revenue;
    }, 0);

    const collectedRevenue = sortedData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.collected_revenue;
    }, 0);
    const pendingRevenue = sortedData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.pending_revenue;
    }, 0);
    const totalData = [
      ...sortedData,
      {
        branch_name: "Total",
        total_enrollment: totalEnrol,
        online_enrollment: onlineEnrol,
        offline_enrollment: offlineEnrol,
        total_revenue: totalRevenue,
        collected_revenue: collectedRevenue,
        pending_revenue: pendingRevenue,
      },
    ];

    setProductData(totalData);
  }, [data]);

  return (
    <>
      <LoaderComp isLoading={detailsLoading} />
      <Box>
        <StyledTableContainer
          component={Paper}
          style={{ height: !isPopup ? "260px" : "auto", overflowY: "auto" }}
        >
          <Table style={{ background: "#F9F9F9" }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Center Name</TableCell>
                <TableCell>No. of Enrolment</TableCell>
                <TableCell>Online Enrolment</TableCell>
                <TableCell>Offline Enrolment</TableCell>
                <TableCell>Total Revenue</TableCell>
                <TableCell>Collected</TableCell>
                <TableCell>Pending</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productData?.length === 0 ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "11px",
                      color: "#000000",
                    }}
                  >
                    No data available
                  </Typography>
                </Box>
              ) : (
                productData.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      width: "6%",
                    }}
                  >
                    <TableCell
                      onClick={
                        !isPopup && index !== productData.length - 1
                          ? () => handleViewMore(row?.id, row?.branch_name)
                          : undefined
                      }
                      sx={{
                        cursor:
                          index !== productData.length - 1 && !isPopup
                            ? "pointer"
                            : "",
                        color:
                          index === productData.length - 1
                            ? "black"
                            : isPopup
                            ? "inherit"
                            : "blue",
                        fontWeight:
                          index !== productData.length - 1 ? "normal" : "bold",
                      }}
                    >
                      {row?.branch_name}
                    </TableCell>
                    <TableCell
                      onClick={
                        !isPopup && index !== productData.length - 1
                          ? () =>
                              getCardMoreInfo(
                                "Total Enrolments",
                                row?.id,
                                "total_enrollment"
                              )
                          : undefined
                      }
                      sx={{
                        cursor:
                          index !== productData.length - 1 && !isPopup
                            ? "pointer"
                            : "",
                        color:
                          index === productData.length - 1
                            ? "black"
                            : isPopup
                            ? "inherit"
                            : "blue",
                        fontWeight:
                          index !== productData.length - 1 ? "normal" : "bold",
                      }}
                    >
                      {row?.total_enrollment}
                    </TableCell>
                    <TableCell
                      sx={{
                        cursor:
                          index !== productData.length - 1 && !isPopup
                            ? "pointer"
                            : "",
                        color:
                          index === productData.length - 1
                            ? "black"
                            : isPopup
                            ? "inherit"
                            : "blue",
                        fontWeight:
                          index !== productData.length - 1 ? "normal" : "bold",
                      }}
                      onClick={
                        !isPopup && index !== productData.length - 1
                          ? () =>
                              getCardMoreInfo(
                                "Online Enrollments",
                                row?.id,
                                "online_enrollment"
                              )
                          : undefined
                      }
                    >
                      {row?.online_enrollment}
                    </TableCell>
                    <TableCell
                      sx={{
                        cursor:
                          index !== productData.length - 1 && !isPopup
                            ? "pointer"
                            : "",
                        color:
                          index === productData.length - 1
                            ? "black"
                            : isPopup
                            ? "inherit"
                            : "blue",
                        fontWeight:
                          index !== productData.length - 1 ? "normal" : "bold",
                      }}
                      onClick={
                        !isPopup && index !== productData.length - 1
                          ? () =>
                              getCardMoreInfo(
                                "Offline Enrollments",
                                row?.id,
                                "offline_enrollment"
                              )
                          : undefined
                      }
                    >
                      {row?.offline_enrollment}
                    </TableCell>
                    <TableCell
                      onClick={
                        !isPopup && index !== productData.length - 1
                          ? () =>
                              getCardMoreInfo(
                                "Total Revenue",
                                row?.id,
                                "total_revenue"
                              )
                          : undefined
                      }
                      sx={{
                        cursor:
                          index !== productData.length - 1 && !isPopup
                            ? "pointer"
                            : "",
                        color:
                          index === productData.length - 1
                            ? "black"
                            : isPopup
                            ? "inherit"
                            : "blue",
                        fontWeight:
                          index !== productData.length - 1 ? "normal" : "bold",
                      }}
                    >
                      {row?.total_revenue}/-
                    </TableCell>
                    <TableCell
                      onClick={
                        !isPopup && index !== productData.length - 1
                          ? () =>
                              getCardMoreInfo(
                                "Collected Revenue",
                                row?.id,
                                "collected_revenue"
                              )
                          : undefined
                      }
                      sx={{
                        cursor:
                          index !== productData.length - 1 && !isPopup
                            ? "pointer"
                            : "",
                        color:
                          index === productData.length - 1
                            ? "black"
                            : isPopup
                            ? "inherit"
                            : "blue",
                        fontWeight:
                          index !== productData.length - 1 ? "normal" : "bold",
                      }}
                    >
                      {row?.collected_revenue}/-
                    </TableCell>
                    <TableCell
                      onClick={
                        !isPopup && index !== productData.length - 1
                          ? () =>
                              getCardMoreInfo(
                                "Pending Revenue",
                                row?.id,
                                "pending_revenue"
                              )
                          : undefined
                      }
                      sx={{
                        cursor:
                          index !== productData.length - 1 && !isPopup
                            ? "pointer"
                            : "",
                        color:
                          index === productData.length - 1
                            ? "black"
                            : isPopup
                            ? "inherit"
                            : "blue",
                        fontWeight:
                          index !== productData.length - 1 ? "normal" : "bold",
                      }}
                    >
                      {row?.pending_revenue}/-
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>

        {data.length > 9 && !isPopup && (
          <Box sx={{ textAlign: "center" }}>
            <Button
              style={{
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "Roboto",
                color: "#00B13F",
                textAlign: "center",
              }}
              onClick={handleshowMore}
            >
              Show Centers
            </Button>
          </Box>
        )}
      </Box>

      <CustomPopup
        open={opendetailPopup}
        onClose={handleCloseDetailPopup}
        component={
          selectedFilterType === "online_enrollment" ||
          selectedFilterType === "offline_enrollment" ||
          selectedFilterType === "total_enrollment" ? (
            <EnrollDetails
              onClose={handleCloseDetailPopup}
              selectedFilter={selectedFilter}
              SelectedCardData={selectedData}
            />
          ) : (
            <MoreCardDetails
              onClose={handleCloseDetailPopup}
              selectedFilter={selectedFilter}
              SelectedCardData={selectedData}
              selectedCardName={selectedDataName}
              selectedCountFilter={selectedFilterType}
            />
          )
        }
        title={`${selectedDataName} Details`}
        maxWidth="lg"
        showDivider={true}
      />
    </>
  );
}
