import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Typography,
  Select,
  MenuItem,
  Box,
  FormHelperText,
  ListItemText,
  Checkbox,
  Grid,
} from "@mui/material";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { CheckBox } from "@mui/icons-material";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const PackageForm = ({ open, handleClose, handleSubmit, formData }) => {
  const userId = localStorage.getItem("userId");
  console.log(userId);
  console.log();
  
  const initialFormValues = {
    package_name: "",
    duration: "",
    test_type: "",
    original_price: "",
    discounted_price: "",
    payable_installment: "",
    status: "active",
    branch_id: [],
  };

  const [testTypes, setTestTypes] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [formValues, setFormValues] = useState(initialFormValues);

  const [errors, setErrors] = useState({
    packageName: "",
    duration: "",
    testType: "",
    originalPrice: "",
    discountedPrice: "",
    payableInstallments: "",
    branch: "", // Added branch field
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));

    // Clear the error message when the user starts typing again
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const resetForm = () => {
    // Reset formValues to the initial state
    setFormValues(initialFormValues);
    // Reset errors
    setErrors({
      packageName: "",
      duration: "",
      testType: "",
      originalPrice: "",
      discountedPrice: "",
      payableInstallments: "",
      branch: "", // Added branch field
    });
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}advisordashboard/gettesttype`)
      .then((response) => response.json())
      .then((data) => {
        setTestTypes(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}branch`)
      .then((response) => response.json())
      .then((data) => {
        setBranchlist(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
      });
  }, []);

  const validateForm = () => {
    const newErrors = {};

    // Check if packageName is empty
    if (!formValues.package_name.trim()) {
      newErrors.packageName = "Package Name is required";
    }

    // Check if duration is empty
    if (!formValues.duration) {
      newErrors.duration = "Duration is required";
    }

    // Check if testType is empty
    if (!formValues.test_type) {
      newErrors.testType = "Test Type is required";
    }

    // Check if originalPrice is empty or not a valid number
    if (
      !formValues.original_price ||
      isNaN(Number(formValues.original_price))
    ) {
      newErrors.originalPrice =
        "Original Price is required and must be a valid number";
    }

    // Check if discountedPrice is empty or not a valid number
    if (
      !formValues.discounted_price ||
      isNaN(Number(formValues.discounted_price))
    ) {
      newErrors.discountedPrice =
        "Discounted Price is required and must be a valid number";
    }

    if (!formValues.payable_installment) {
      newErrors.payableInstallments = "Payable Installments is required";
    }

    if (!formValues.branch_id || formValues.branch_id.length === 0) {
      newErrors.branch = "Branch is required";
    }
    // Set the new errors state
    setErrors(newErrors);

    // Return true if there are no errors, indicating the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = () => {
    // Validate the form
    if (validateForm()) {
      // If the form is valid, proceed with submitting the form
      if (formData) {
        // Edit mode: Use PUT request
        handleEditSubmit(formValues);
      } else {
        // Add mode: Use POST request
        handleAddSubmit(formValues);
      }

      // Reset the form after submission
      resetForm();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const handleAddSubmit = (formData) => {
    formData.user_id = parseInt(userId);

    fetch(`${API_ENDPOINT}package`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status === 201) {
          showSnackbar("Package created successfully", "success");
        } else {
          console.log("Error:", response);
          showSnackbar("Error creating package", "error");
        }
      })
      .catch((error) => {
        console.error("Error adding package:", error);
        showSnackbar("Error creating package", "error");
      });
  };

  const handleEditSubmit = (formData) => {
    formData.user_id = parseInt(userId);

    console.log(formData);

    fetch(`${API_ENDPOINT}package/${formData.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status === 200) {
          showSnackbar("Package updated successfully", "success");
        } else {
          console.log("Error:", response);
          showSnackbar("Error updating package", "error");
        }
      })
      .catch((error) => {
        console.error("Error updating package:", error);
        showSnackbar("Error updating package", "error");
      });
  };

  const handleCancel = () => {
    // Close the form and reset the values
    handleClose();
    // Reset the form when canceled
    resetForm();
  };

  console.log(formValues)

  useEffect(() => {
    if (formData) {
      // If formData is provided, set the formValues state with the formData
      setFormValues((prevValues) => ({
        ...prevValues,
        ...formData, // Copy other fields from formData

        // Check if test_type is an object, and if so, extract the id
        test_type:
          formData.test_type && typeof formData.test_type === "object"
            ? formData.test_type.id
            : formData.test_type,

            branch_id:
            formData.branch_id && Array.isArray(formData.branch_id)
              ? formData.branch_id
              : [],

             
      }));
    }

  }, [formData]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle variant="h4">
          {formData ? "Edit Package" : "Add Package"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
          <Typography variant="h6">Package Name</Typography>
            <CustomTextFieldComp
            size="normal"
            
              fullWidth
              value={formValues?.package_name}
              onChange={(e) => handleChange("package_name", e.target.value)}
              error={!!errors.packageName}
            />
            <FormHelperText error>{errors.packageName}</FormHelperText>
          </Grid>

             <Grid item xs={12} sm={6}>
            <Typography>Session (in session)</Typography>
               <CustomTextFieldComp
            size="normal"
              
              value={formValues?.duration}
              type="number"
              required
              onChange={(e) => handleChange("duration", e.target.value)}
              error={!!errors.duration}
              fullWidth
            ></CustomTextFieldComp>

            <FormHelperText error>{errors.duration}</FormHelperText>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <Typography>Test Type</Typography>
              <Select
               size="normal"
                value={formValues?.test_type}
                onChange={(e) => handleChange("test_type", e.target.value)}
                error={!!errors.testType}
                style={{borderRadius:"8px"}}
              >
                <MenuItem value="" disabled>
                  Select Test Type
                </MenuItem>
                {testTypes.map((testtype) => (
                  <MenuItem key={testtype.id} value={testtype.id}>
                    {testtype.name}{" "}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText error>{errors.testType}</FormHelperText>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <Typography>Branch</Typography>
              <Select
              size="normal"
                value={formValues?.branch_id}
                multiple
                onChange={(e) => handleChange("branch_id", e.target.value)}
                error={!!errors.branch}
                style={{borderRadius:"8px"}}
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const branches = branchlist.find(
                        (status) => status.branch_name === value
                      );
                      return branches ? branches.branch_name : "";
                    })
                    .join(", ")
                }
              >
                <MenuItem key="select-all" value="all">
                  <Checkbox
                    checked={formValues?.branch_id.length === branchlist.length}
                    onChange={(e) => {
                      const selectedIds = e.target.checked
                        ? branchlist.map((branch) => branch.branch_name)
                        : [];
                      handleChange("branch_id", selectedIds);
                    }}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {branchlist.map((name) => (
                  <MenuItem key={name.id} value={name.branch_name}>
                    <Checkbox
                      checked={formValues?.branch_id.includes(name.branch_name)}
                    />
                    <ListItemText primary={name.branch_name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText error>{errors.branch}</FormHelperText>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography>Original Price (₹)</Typography>
            <CustomTextFieldComp
            size="normal"
              fullWidth
              type="number"
              value={formValues?.original_price}
              onChange={(e) => handleChange("original_price", e.target.value)}
              required
              error={!!errors.originalPrice}
            />
            <FormHelperText error>{errors.originalPrice}</FormHelperText>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography>Discounted Price (₹)</Typography>
            <CustomTextFieldComp
            size="normal"
              fullWidth
              type="number"
              value={formValues?.discounted_price}
              onChange={(e) => handleChange("discounted_price", e.target.value)}
              required
              error={!!errors.discountedPrice}
            />
            <FormHelperText error>{errors.discountedPrice}</FormHelperText>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <Typography>Payable Installments</Typography>
              <Select
              size="normal"
                value={formValues?.payable_installment}
                onChange={(e) =>
                  handleChange("payable_installment", e.target.value)
                }
                style={{borderRadius:"8px"}}
                error={!!errors.payableInstallments}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            </FormControl>
            <FormHelperText error>{errors.payableInstallments}</FormHelperText>
          </Grid>

          {formData && (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <Typography>Status</Typography>
                <Select
                size="normal"
                  value={formValues?.status || "active"}
                  onChange={(e) => handleChange("status", e.target.value)}
                  error={!!errors.status}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inActive">Inactive</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText error>{errors.status}</FormHelperText>
            </Grid>
          )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button  color="error"  variant="outlined" onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={handleFormSubmit}
            color="secondary"
            variant="contained"
          >
            {formData ? "Save Changes" : "Add Package"}
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default PackageForm;
