import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../../utils/token";
import ApplyFilterButton from "../../newComponent/NewButtonComp/ApplyFilterButton";
import ResetFilterButton from "../../newComponent/NewButtonComp/ResetFilterButton";
import CustomAutocompleteComp from "../../newComponent/AutocompleteComp";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function FilterLeaveRequest({
  setLoading,
  setLeaveData,
  setOriginalLeaveData,
  originalLeaveData,
  setSearchData,
  setCriteria,
  setPage,
}) {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [userName, setUserName] = useState([]);
  const [userNameList, setuserNameList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leaveType, setLeaveType] = useState([]);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [leaveTypelist, setLeaveTypeList] = useState([
    { id: 1, name: "Full Day" },
    { id: 2, name: "First Half Day" },
    { id: 3, name: "Second Half Day" },
  ]);

  const [backupUser, setBackupUser] = useState([]);
  const [responseBy, setResponseBy] = useState([]);
  const [createdFromDate, setCreatedFromdate] = useState("");
  const [createdToDate, setCreatedToDate] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/getuserbyuser/${userId}`
        );
        if (response.ok) {
          const data = await response.json();

          setuserNameList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  const allUserList = [{ first_name: "Select All" }, ...userNameList];
  const handleUserChange = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setUserName(userNameList);
    } else {
      setUserName(value);
    }
  };

  const allLeaveTypeList = [{ name: "Select All" }, ...leaveTypelist];
  const handleLeaveTypeChange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      setLeaveType(leaveTypelist);
    } else {
      setLeaveType(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setAllEmployeeList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allemployeeuserList = [
    { first_name: "Select All" },
    ...allEmployeeList,
  ];

  const handleBackupUserChange = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setBackupUser(allEmployeeList);
    } else {
      setBackupUser(value);
    }
  };

  const handleResposeByChange = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setResponseBy(allEmployeeList);
    } else {
      setResponseBy(value);
    }
  };

  // set Creted Date By default

  const formatDate = (date) => date.toISOString().split("T")[0];

  useEffect(() => {
    const today = new Date();
    let oneMonthAgo = new Date();
    oneMonthAgo.setDate(today.getDate() - 30);

    setCreatedToDate(formatDate(today));
    setCreatedFromdate(formatDate(oneMonthAgo));
  }, []);

  // Apply Filter
  const handleApplyFilter = async () => {
    setLoading(true);
    setCriteria("all");
    setPage(0);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const requestData = {
      user_id: parseInt(userId),
      employee_name: userName.map((i) => i.id),
      backup_emp_name: backupUser.map((i) => i.id),
      leave_type_id: leaveType.map((i) => i.id),
      created_from_date: createdFromDate || null,
      created_to_date: createdToDate || null,
      created_by: responseBy.map((i) => i.id),
      start_date: startDate || null,
      end_date: endDate || null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/advisorleavefilter`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setLeaveData(result);
        setSearchData(result);
        setLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  const resetFilter = () => {
    setPage(0);
    setLeaveData(originalLeaveData);
    setSearchData(originalLeaveData);
    setUserName([]);
    setLeaveType([]);
    setBackupUser([]);
    setResponseBy([]);
  };
  return (
    <>
      <MainCard>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Select Employee Name</Typography>
            <CustomAutocompleteComp
              size="small"
              value={userName}
              multiple
              onChange={handleUserChange}
              options={allUserList}
              getOptionLabel={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
              tooltipContent={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Start Date</Typography>
            <CustomTextFieldComp
              type="date"
              size="normal"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
              InputProps={{
                style: { borderRadius: "10px" }, // Add borderRadius here
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="h6">End Date</Typography>
            <CustomTextFieldComp
              type="date"
              size="normal"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
              InputProps={{
                style: { borderRadius: "10px" }, // Add borderRadius here
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Select Leave Type</Typography>
            <CustomAutocompleteComp
              size="small"
              multiple
              value={leaveType}
              onChange={handleLeaveTypeChange}
              options={allLeaveTypeList}
              getOptionLabel={(option) => option.name}
              tooltipContent={(option) => option.name}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Select Backup Employee </Typography>
            <CustomAutocompleteComp
              size="small"
              multiple
              value={backupUser}
              onChange={handleBackupUserChange}
              options={allemployeeuserList}
              getOptionLabel={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
              tooltipContent={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Created From Date</Typography>
            <CustomTextFieldComp
              type="date"
              size="normal"
              fullWidth
              value={createdFromDate}
              onChange={(e) => setCreatedFromdate(e.target.value)}
              InputProps={{
                style: { borderRadius: "10px" }, // Add borderRadius here
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Created To Date</Typography>
            <CustomTextFieldComp
              type="date"
              size="normal"
              fullWidth
              value={createdToDate}
              onChange={(e) => setCreatedToDate(e.target.value)}
              InputProps={{
                style: { borderRadius: "10px" }, // Add borderRadius here
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Select Created By</Typography>
            <CustomAutocompleteComp
              size="small"
              multiple
              value={responseBy}
              onChange={handleResposeByChange}
              options={allemployeeuserList}
              getOptionLabel={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
              tooltipContent={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
            />
          </Grid>

          <Grid item xs={12} textAlign="end">
            <Grid container gap={1} justifyContent="end">
              <ApplyFilterButton onClick={handleApplyFilter} />

              <ResetFilterButton onClick={resetFilter} />
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}
