// Author : Ayesha Shaikh
//  Date : 13 sept 2024

import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function ViewEmployeeDetails({ selectedId }) {
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_ENDPOINT}employee/${selectedId}`);
        if (response.ok) {
          const data = await response.json();
          setEmployeeData(data);

          setLoading(false);
        } else if (response.status === 401) {
          setLoading(false);
        } else if (response.status === 404) {
          setLoading(false);
        } else if (response.status === 401) {
          setLoading(false);
        } else {
          setLoading(false);

          throw new Error("Error Fetching  Employees");
        }
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Typography>Loading....</Typography>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4} sm={6}>
            <Typography variant="h6">Employee Name</Typography>
            <CustomTextFieldComp
              value={`${
                employeeData.first_name ? employeeData?.first_name : " "
              } ${employeeData.last_name ? employeeData?.last_name : " "}`}
              fullWidth
              size="normal"
              InputProps={{ readOnly: true, style: { borderRadius: "10px" } }}
            />
          </Grid>

          <Grid item xs={12} lg={4} sm={6}>
            <Typography variant="h6">Email</Typography>
            <CustomTextFieldComp
              value={employeeData?.email}
              fullWidth
              size="normal"
              InputProps={{ readOnly: true, style: { borderRadius: "10px" } }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <Grid container>
              <Grid item xs={12} lg={4} sm={6}>
                <Typography variant="h6">ISD Code</Typography>
                <CustomTextFieldComp
                  value={employeeData?.mobile_country_code}
                  fullWidth
                  size="normal"
                  InputProps={{
                    readOnly: true,
                    style: { borderRadius: "10px" },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={8} sm={6}>
                <Typography variant="h6">Mobile No</Typography>
                <CustomTextFieldComp
                  value={employeeData?.mobile}
                  fullWidth
                  size="normal"
                  InputProps={{
                    readOnly: true,
                    style: { borderRadius: "10px" },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={4} sm={6}>
            <Typography variant="h6">Gender</Typography>
            <CustomTextFieldComp
              value={employeeData?.gender}
              fullWidth
              size="normal"
              InputProps={{ readOnly: true, style: { borderRadius: "10px" } }}
            />
          </Grid>

          <Grid item xs={12} lg={4} sm={6}>
            <Typography variant="h6">Date Of Birth</Typography>
            <CustomTextFieldComp
              value={employeeData?.dob}
              fullWidth
              size="normal"
              InputProps={{ readOnly: true, style: { borderRadius: "10px" } }}
            />
          </Grid>

          <Grid item xs={12} lg={4} sm={6}>
            <Typography variant="h6">Joining Date</Typography>
            <CustomTextFieldComp
              value={employeeData?.joining_date}
              fullWidth
              size="normal"
              InputProps={{ readOnly: true, style: { borderRadius: "10px" } }}
            />
          </Grid>

          <Grid item xs={12} lg={4} sm={6}>
            <Typography variant="h6">Designation</Typography>
            <CustomTextFieldComp
              value={employeeData?.designation}
              fullWidth
              size="normal"
              InputProps={{ readOnly: true, style: { borderRadius: "10px" } }}
            />
          </Grid>

          <Grid item xs={12} lg={4} sm={6}>
            <Typography variant="h6">Role</Typography>
            <CustomTextFieldComp
              value={employeeData?.role}
              fullWidth
              size="normal"
              InputProps={{ readOnly: true, style: { borderRadius: "10px" } }}
            />
          </Grid>

          <Grid item xs={12} lg={4} sm={6}>
            <Typography variant="h6">Branch</Typography>
            <CustomTextFieldComp
              value={employeeData?.branch?.branch_name}
              fullWidth
              size="normal"
              InputProps={{ readOnly: true, style: { borderRadius: "10px" } }}
            />
          </Grid>

          <Grid item xs={12} lg={4} sm={6}>
            <Typography variant="h6">Branch Manager</Typography>
            <CustomTextFieldComp
              value={employeeData?.branch_manager}
              fullWidth
              size="normal"
              InputProps={{ readOnly: true, style: { borderRadius: "10px" } }}
            />
          </Grid>

          <Grid item xs={12} lg={4} sm={6}>
            <Typography variant="h6">Reporting Manager</Typography>
            <CustomTextFieldComp
              value={employeeData?.reporting_manager}
              fullWidth
              size="normal"
              InputProps={{ readOnly: true, style: { borderRadius: "10px" } }}
            />
          </Grid>

          <Grid item xs={12} lg={4} sm={6}>
            <Typography variant="h6">Report CC </Typography>
            <CustomTextFieldComp
              value={employeeData?.add_report_cc_user}
              fullWidth
              size="normal"
              InputProps={{ readOnly: true, style: { borderRadius: "10px" } }}
            />
          </Grid>
          <Grid item xs={12} lg={4} sm={6}>
            <Typography variant="h6">Is Auto Assign Advisor </Typography>
            <CustomTextFieldComp
              value={employeeData?.is_auto_assigner ? "Yes" : "No"}
              fullWidth
              size="normal"
              InputProps={{ readOnly: true, style: { borderRadius: "10px" } }}
            />
          </Grid>
          {employeeData?.is_auto_assigner && (
            <>
              <Grid item xs={12} lg={4} sm={6}>
                <Typography variant="h6">Service </Typography>
                <CustomTextFieldComp
                  value={employeeData?.service_id}
                  fullWidth
                  size="normal"
                  InputProps={{
                    readOnly: true,
                    style: { borderRadius: "10px" },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4} sm={6}>
                <Typography variant="h6">Sub Service </Typography>
                <CustomTextFieldComp
                  value={employeeData?.sub_service_id}
                  fullWidth
                  size="normal"
                  InputProps={{
                    readOnly: true,
                    style: { borderRadius: "10px" },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4} sm={6}>
                <Typography variant="h6">Branch Handle </Typography>
                <CustomTextFieldComp
                  value={employeeData?.handling_branch_id}
                  fullWidth
                  size="normal"
                  InputProps={{
                    readOnly: true,
                    style: { borderRadius: "10px" },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4} sm={6}>
                <Typography variant="h6">Backup User </Typography>
                <CustomTextFieldComp
                  value={`${employeeData?.backup_user?.first_name} ${
                    employeeData.backup_user?.last_name || ""
                  }`}
                  fullWidth
                  size="normal"
                  InputProps={{
                    readOnly: true,
                    style: { borderRadius: "10px" },
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
}
