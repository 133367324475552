import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";

const API_GRADLYNK_ENDPOINT = process.env.REACT_APP_API_GRADLYNK;
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function GradlynkForm({ enquiryData }) {
  const userId = localStorage.getItem('userId');
  const ITEM_HEIGHT = 48;
  console.log("enquiryData", enquiryData.first_name);
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [serviceName, setServiceName] = useState(null);
  const [filteredSubServices, setFilteredSubServices] = useState([]);
  const [Subservice, setSubserVice] = useState(null);
  const [note, setNote] = useState(null);
  const [nearestBranch, setNearestBranch] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [gradlynkBranchList, setGradlynkBranchList] = useState([])
  const [country, setCountry] = useState(null);
  const [errors, setErrors] = useState({
    serviceName: false,
    Subservice: false,
    note: false,
    country: false,
    nearestBranch: false,
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const serviceNameList = [
    { id: 1, name: "Child Dependent Visa" },

    { id: 2, name: "Child Visa" },
    { id: 3, name: "Citizenship by Decent" },
    { id: 4, name: "Hotel Management" },
    { id: 5, name: "Internships" },
    { id: 6, name: "Migration" },
    { id: 7, name: "Parent Visa" },
    { id: 8, name: "Post Graduate Work Permit" },
    { id: 9, name: "Special Services" },
    { id: 10, name: "Spouse / Partner Visa" },
    { id: 11, name: "Spouse Work Visa" },
    { id: 12, name: "Student Dependent Visa" },
    { id: 13, name: "Student Visa" },
    { id: 14, name: "Temporary Activity Work Visa" },
    { id: 15, name: "Test Preparation" },
    { id: 16, name: "Visitor Visa" },
  ];

  const subServiceList = [
    { id: 1, service_id: "Special Services", name: "FOREX" },
    // { id: 2, service_id: "Special Services", name: "Education" },
    // { id: 3, service_id: "Special Services", name: "Insurance" },
    // { id: 4, service_id: "Special Services", name: "Accommodation" },
    // { id: 5, service_id: "Special Services", name: "Ticketing" },
    // { id: 6, service_id: "Visitor Visa", name: "Business Visa" },
    // { id: 7, service_id: "Visitor Visa", name: "Family Visa" },
    // { id: 8, service_id: "Visitor Visa", name: "Tourist Visa" },
    // {
    //   id: 9,
    //   service_id: "Spouse / Partner Visa",
    //   name: "Partner (Provisional/ Permanent) Visa (309/100)",
    // },
    // {
    //   id: 10,
    //   service_id: "Spouse / Partner Visa",
    //   name: "Partner (Provisional/ Permanent) Visa (820/801)",
    // },
    // { id: 11, service_id: "Parent Visa", name: "Parent Visa (subclass 103)" },
    // {
    //   id: 12,
    //   service_id: "Parent Visa",
    //   name: "Contributory Parent Visa (subclass 143)",
    // },
    // {
    //   id: 13,
    //   service_id: "Parent Visa",
    //   name: "Contributory Parent (Temporary) Visa (subclass 173)",
    // },
    // {
    //   id: 14,
    //   service_id: "Child Dependent Visa",
    //   name: "Child (Temporary) Visa (subclass 802)",
    // },

    // {
    //   id: 15,
    //   service_id: "Child Visa",
    //   name: "Child (Permanent) Visa (subclass 101) ",
    // },
    // {
    //   id: 16,
    //   service_id: "Migration",
    //   name: "Migration Service (sub service)",
    // },

    // {
    //   id: 17,
    //   service_id: "Student Dependent Visa",
    //   name: "Student Dependent visa (Sub Service)",
    // },
  ];

  const handleChange = (e) => {
    setServiceName(e.target.value);
    setErrors({ ...errors, serviceName: !e.target.value });
  };
  const handlesubServiceChange = (e) => {
    setSubserVice(e.target.value);
    if (serviceName === "Special Services") {
      setErrors({ ...errors, Subservice: !e.target.value });
    }
  };

  useEffect(() => {
    if (serviceName) {
      const filtered = subServiceList.filter(
        (subService) => subService.service_id === serviceName
      );
      setFilteredSubServices(filtered);
    } else {
      setFilteredSubServices([]);
    }
  }, [serviceName]);

  const handleNotechange = (e) => {
    setNote(e.target.value);
    setErrors({ ...errors, note: !e.target.value });
  };

  const handleNearestBranchChange = (e) => {
    setNearestBranch(e.target.value);
    setErrors({ ...errors, nearestBranch: !e.target.value });
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setErrors({ ...errors, country: !e.target.value });
    
  };

  const handleSubmit = () => {
    if (
      !serviceName ||
      (serviceName === "Special Services" && !Subservice) ||
      !country ||
      !note
    ) {
      setErrors({
        serviceName: !serviceName,
        Subservice: !Subservice && serviceName === "Special Services",
        country: !country,
        note: !note,
        nearestBranch: !nearestBranch
      });

      showSnackbar("Please fill out all fields", "error");
      return;
    }
    const formdata = {
      enquiry_name: enquiryData?.first_name,
      family_name: enquiryData?.last_name,
      mobile_code: enquiryData?.mobile_country_code,
      mobile: enquiryData?.mobile,
      email: enquiryData?.email,
      service_id: serviceName,
      sub_service_id: Subservice,
      nearest_branch: nearestBranch,
      first_country_of_interest: country,
      subject_id: "Fresh Call",
      //reason_followup: "Expert call follow up",
      notes: note,
      date_of_birth: enquiryData?.dob,
    };

    // Remove fields with null values
    const cleanedFormData = Object.fromEntries(
      Object.entries(formdata).filter(([_, value]) => value !== null)
    );

    fetch(`${API_GRADLYNK_ENDPOINT}save-testprep-enquiry`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cleanedFormData),
    })
    .then((response) => response.json().then((data) => ({ status: response.status, body: data })))
    .then(({ status, body }) => {
      if (status === 201) {
          //showSnackbar("Service Added Successfully");
  
          // Post data to another API
          fetch(`${API_ENDPOINT}addgradlynkservice`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({...cleanedFormData, enquiry_id: enquiryData.id, user_id: userId}),
          })
            .then((response) => {
              if (response.ok) {
                showSnackbar("Service Added Successfully");
              } else {
                console.error("Error: Unable to post to another API");
                showSnackbar("Error adding gradlynk service to DB", "error");
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              showSnackbar("Error: Server Error when adding service to db", "error");
            });
  
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else if (status === 500 && body.message === "Enquiry exists, new task added") {
          showSnackbar("Enquiry exists, new task added", "success");
        } else {
          console.error("Error: Unknown Error", body);
          showSnackbar(body.message || "Error adding gradlynk service", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showSnackbar("Error: Server Error", "error");
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_GRADLYNK_ENDPOINT}gradlynk-countries`);
        if (response.ok) {
          const data = await response.json();
          setCountryList(data?.data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchBranchData = async () => {
      try {
        const response = await fetch(`${API_GRADLYNK_ENDPOINT}gradlynk-branches`);
        if (response.ok) {
          const data = await response.json();
          setGradlynkBranchList(data.data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchBranchData();
  }, []);

  return (
    <>
      <Box width="100%">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <FormControl sx={{ width: "100%" }}>
              <Typography variant="h6">
                Select Service <span style={{ color: "red" }}>*</span>{" "}
              </Typography>
              <Select
              size="small"
                value={serviceName}
                onChange={handleChange}
                sx={{ borderRadius: "10px" }}
                MenuProps={MenuProps}
              >
                {serviceNameList.map((name) => (
                  <MenuItem key={name.id} value={name.name}>
                    <ListItemText primary={name.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.serviceName && (
              <Typography color="error" variant="caption">
                Service name is required
              </Typography>
            )}
          </Grid>

          {serviceName !== null && serviceName === "Special Services" && (
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <FormControl sx={{ width: "100%" }}>
                <Typography variant="h6">
                  Select Sub Service <span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                  size="small"
                  value={Subservice}
                  onChange={handlesubServiceChange}
                  sx={{ borderRadius: "10px" }}
                  MenuProps={MenuProps}
                >
                  {filteredSubServices.map((name) => (
                    <MenuItem key={name.id} value={name.name}>
                      <ListItemText primary={name.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.Subservice && (
                <Typography color="error" variant="caption">
                  Sub Service name is required
                </Typography>
              )}
            </Grid>
          )}

          
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <FormControl sx={{ width: "100%" }}>
                <Typography variant="h6">
                  Country of Interest <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
                <Select
                  size="small"
                  value={country}
                  onChange={handleCountryChange}
                  sx={{ borderRadius: "10px" }}
                  MenuProps={MenuProps}
                >
                  {countryList.map((name) => (
                    <MenuItem key={name.id} value={name.country_name}>
                      <ListItemText primary={name.country_name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.country && (
                <Typography color="error" variant="caption">
                  Country is required
                </Typography>
              )}
            </Grid>
          


            <Grid item xs={12} md={12} lg={12} sm={12}>
              <FormControl sx={{ width: "100%" }}>
                <Typography variant="h6">
                   Gradlynk Nearest Branch <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
                <Select
                  size="small"
                  value={nearestBranch}
                  onChange={handleNearestBranchChange}
                  sx={{ borderRadius: "10px" }}
                  MenuProps={MenuProps}
                >
                  {gradlynkBranchList.map((name) => (
                    <MenuItem key={name.id} value={name.branch_name}>
                      <ListItemText primary={name.branch_name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.nearestBranch && (
                <Typography color="error" variant="caption">
                  Nearest Branch is required
                </Typography>
              )}
            </Grid>
         

          <Grid item xs={12} md={12} lg={12} sm={12}>
            <Typography variant="h6">
              Note <span style={{ color: "red" }}>*</span>{" "}
            </Typography>
            <TextField
              size="small"
              multiline
              rows={4}
              value={note}
              onChange={handleNotechange}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              variant="outlined"
              fullWidth
            />
            {errors.serviceName && (
              <Typography color="error" variant="caption">
                Note is required
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} lg={12} md={12} sm={12} textAlign="end">
            <Button variant="contained" color="secondary" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
