import React, { useEffect, useState } from "react";
import MainCard from "../../newTestPrep/components/ui-component/card/MainCard";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import { useNavigate } from "react-router-dom";
import NewCardcomp from "../../components/card/NewCardcomp";

export default function ReportCards({
  data,
  selectedState,
  selectedcity,
  filteredData,
  passedStateData
}) {
  const navigate = useNavigate();
  // Filter data based on selected state
  //  const filteredData = data.filter((card) => {
  //   if (selectedState.length > 0 && !selectedState.includes(card.state_id)) {
  //     return false;
  //   }
  //   if (selectedcity.length > 0 && !selectedcity.includes(card.city_id)) {
  //     return false;
  //   }
  //   return true;
  // });

  console.log("filteredData", filteredData);
  const cardsPerPage = 9;
  const [currentpage, setcurrentpage] = useState(1);
  const indexOfLastCard = currentpage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = filteredData.slice(indexOfFirstCard, indexOfLastCard);
  const totalPages = Math.ceil(filteredData.length / cardsPerPage);
  const handleNextPage = () => {
    setcurrentpage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setcurrentpage((prevPage) => Math.max(prevPage - 1, 1));
  };

 // data which have to pass with state


 const handleViewMore = (id, branchName) => {
  const passedStateData1 = { ...passedStateData, branchName }; // Add branchName to the object

  navigate(`/centerdashboard/${id}`, {
    state: { passedStateData: passedStateData1 }, // Ensure correct key
  });
};

  return (
    <>
      <Grid container spacing={5}>
        {currentCards.map((data, index) => (
          <>
            <Grid item xs={12} lg={4} md={6} sm={6} key={index}>
              <NewCardcomp
                borderRadius="20px"
                padding="20px"
                onClick={() => handleViewMore(data.id, data.branch_name)}
               
                hoverBgColor={index % 2 === 0 ? '#D5F6E1' : '#FFEDFB'} // Alternating hover colors
                hoverEffect={true}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h3" textAlign="center">
                      {data.branch_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img src="/reporticon.png" />
                      <Typography
                        variant="h5"
                        sx={{
                          marginLeft: "10px",
                        }}
                      >
                        Total Revenue: ₹ {data.total_revenue}
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      container
                      gap={1.5}
                      justifyContent="center"
                      textAlign="center"
                    >
                      <Typography
                        variant="h7"
                        sx={{
                          background: "#F4F4F4",
                          color: "#425166",

                          padding: "5px",

                          borderRadius: "6px",
                        }}
                      >
                        Collected:{data.collected_revenue}
                      </Typography>

                      <Typography
                        variant="h7"
                        sx={{
                          background: "#F4F4F4",
                          color: "#425166",
                          padding: "5px",

                          borderRadius: "6px",
                        }}
                      >
                        Pending:{data.pending_revenue}
                      </Typography>

                      <Typography
                        variant="h7"
                        sx={{
                          background: "#F4F4F4",
                          color: "#425166",
                          padding: "5px",

                          borderRadius: "6px",
                        }}
                      >
                        New Enrollment: {data.total_enrollment}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={12} textAlign="center" mt={1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => handleViewMore(data.id, data.branch_name)}
                    >
                      View more details
                    </Button>
                  </Grid> */}
                </Grid>{" "}
              </NewCardcomp>
            </Grid>
          </>
        ))}
      </Grid>
      <Box sx={{ textAlign: "right", marginTop: "20px" }}>
        <Button
          size="small"
          disabled={currentpage === 1}
          onClick={handlePrevPage}
          style={{ marginRight: "10px" }}
        >
          Previous Page
        </Button>

        <Button
          variant="contained"
          size="small"
          color="secondary"
          style={{ width: "1px" }}
        >
          {currentpage}
        </Button>
        <Button
          size="small"
          disabled={currentpage === totalPages}
          onClick={handleNextPage}
        >
          Next Page
        </Button>
      </Box>
    </>
  );
}
