import { Button } from "@mui/material";
import React from "react";

export default function MassTaskAssignButton({ onClick }) {
  return (
    <>
      <Button
        variant="contained"
        size="medium"
        style={{ background: "#ed7211", borderRadius:'8px' }}
        onClick={onClick}
      >
        Mass Task Assign
      </Button>
    </>
  );
}
