import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import NewCardcomp from "../../../components/card/NewCardcomp";
import { useDateUtils } from "../../../utils/formatTime";
import { useNavigate } from "react-router-dom";

export default function AdvisorNewTarget({ loading, targetData }) {


  const navigate = useNavigate();
  const [state, setState] = React.useState({
    series: [0],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5,
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#444",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          gradientToColors: ["#01BE85"],
          stops: [0, 50, 53, 91],
        },
      },
      colors: ["#01BE85"],
      labels: ["Average Results"],
    },
  });

  useEffect(() => {
    const percentage = Math.round(
      ((targetData?.collectedRevenue || 0) / (targetData?.targetRevenue || 1)) *
        100
    );

    setState((prevState) => ({
      ...prevState,
      series: [percentage],
    }));
  }, [targetData]);

  const { currentMonthName, lastMonthName, weekRange } = useDateUtils();

  const handleView = () => {
    navigate("/advisor-target");
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <NewCardcomp>
          <Typography
            variant="subtitle2"
            sx={{ color: "#000000", textAlign: "center" }}
          >
            {currentMonthName} Month Target
          </Typography>
        </NewCardcomp>
      </Grid>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={2}
          width="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid item xs={12}>
          <NewCardcomp height="300px">
            {targetData?.targetRevenue === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={170}
              >
                <Typography className="picharttexttotal" fontWeight={600}>
                  No Target Available
                </Typography>
              </Box>
            ) : (
              <>
                <ReactApexChart
                  options={state.options}
                  series={state.series}
                  type="radialBar"
                  height={280}
                />

                <Grid container spacing={1} mt={2}>
                  <Grid item xs={6} display="flex" gap={1}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                        background: "#01BE85",
                      }}
                    ></Box>
                    <Typography variant="h7">Achieved Target</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h7">
                      {targetData?.collectedRevenue}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} display="flex" gap={1}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                        background: "#dcdcdc",
                      }}
                    ></Box>
                    <Typography variant="h7">Total Target</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h7">
                      {targetData?.targetRevenue}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid item xs={12} textAlign="center" mt={4}>
              <Button
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  fontFamily: "Roboto",
                  color: "#00B13F",
                  textAlign: "center",
                }}
                onClick={handleView}
              >
                View Your Target
              </Button>
            </Grid>
          </NewCardcomp>
        </Grid>
      )}
    </Grid>
  );
}
