import { Button } from '@mui/material'
import React from 'react'
import ControlPointIcon from '@mui/icons-material/ControlPoint';

export default function ApplyFilterButton({onClick,label, size, startIcon,addbutton, height}) {
  return (
<Button
      startIcon={addbutton ? <ControlPointIcon fontSize='small'/> :""}
        onClick={onClick}
        variant="contained"
        size={size?size:"small"}
        style={{borderRadius:'7px', background:"#46b1ff" , height:height}}
      >
        {label ? label : "Apply Filter"}
      </Button>
  )
}
