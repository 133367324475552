import { Button } from "@mui/material";
import React from "react";

export default function SelectedItemButton({ onClick, value }) {
  return (
    <>
      <Button
        variant="contained"
        size="small"
        style={{ background: "#00b13f", borderRadius: "6px", fontWeight: 400 }}
        onClick={onClick}
      >
        Selected Items : {value}
      </Button>
    </>
  );
}
