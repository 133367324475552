import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  MenuItem,
  Popper,
  TextField,

} from "@mui/material";
import { useForm, Controller, useWatch } from "react-hook-form";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import CloseIcon from "@mui/icons-material/Close";
import CustomAutocompleteComp from "../../../newTestPrep/newComponent/AutocompleteComp";
import CustomTextFieldComp from "../../../newTestPrep/newComponent/customTextFieldComp";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const CloseTaskDialog = ({ open, onClose, taskId, enquiryId }) => {
  const userId = localStorage.getItem("userId");
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 4;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [taskItems, setTaskItems] = useState([]);
  const [services, setServices] = useState([]);
  const [taskOwners, setTaskOwners] = useState([]);
  const [contactModes, setContactModes] = useState([]);
  const [notConnectedReasons, setNotConnectedReasons] = useState([]);
  const [followUpReasons, setFollowUpReasons] = useState([]);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [responseStatusOptions, setResponseStatusOptions] = useState([]);

  const responseStatus = watch("response_status");
  const contactMode = watch("contact_mode_id");

  const isFollowUpRequired = useWatch({
    control,
    name: "is_follow_up_required",
    defaultValue: true,
  });

  const isReminder = useWatch({
    control,
    name: "want_reminder_for_task",
    defaultValue: false,
  });

  const isUrgent = useWatch({
    control,
    name: "is_it_urgent",
    defaultValue: false,
  });

  const handleCheckboxChange = (event) => {
    // Handle checkbox changes
    setValue("is_follow_up_required", !isFollowUpRequired);
  };

  const handleCheckboxReminderChange = (event) => {
    // Handle checkbox changes
    setValue("want_reminder_for_task", !isReminder);
  };

  const handleCheckboxUrgentChange = (event) => {
    // Handle checkbox changes
    setValue("is_it_urgent", !isUrgent);
  };

  const handleClose = () => {
    // Add your logic to handle the close action, e.g., submit data or perform other actions
    onClose();
    reset(); // Reset form after closing
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route2.0/typeoftaskroles/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setTaskItems(data);
      })
      .catch((error) => {
        console.error("Error fetching task items:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}service`)
      .then((response) => response.json())
      .then((data) => {
        setServices(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}taskcontactmode`)
      .then((response) => response.json())
      .then((data) => {
        setContactModes(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching contact mode:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}tasknotconnectedreason`)
      .then((response) => response.json())
      .then((data) => {
        setNotConnectedReasons(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching not connected reasons:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}taskfollowupreason`)
      .then((response) => response.json())
      .then((data) => {
        setFollowUpReasons(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching follow up reasons:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalluser`)
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.sort((a, b) => {
          const nameA = (a.first_name + " " + a.last_name).toUpperCase();
          const nameB = (b.first_name + " " + b.last_name).toUpperCase();
          return nameA.localeCompare(nameB);
        });
        setTaskOwners(sortedData);
        console.log(data);
        const defaultOwner = sortedData.find(
          (owner) => owner.id === parseInt(userId)
        );
        if (defaultOwner) {
          setValue("task_owner", defaultOwner.id);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const onSubmit = async (data) => {
    data.status = false;
    data.closed_by = parseInt(userId);
    data.created_by = parseInt(userId);
    data.user_id = parseInt(userId);
    data.enquiry_id = enquiryId;
    console.log(data);
    try {
      const response = await fetch(
        `${API_ENDPOINT}task/allusers/closetask/${taskId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Include any other headers if needed
          },
          body: JSON.stringify(data), // Send form data as JSON
        }
      );

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        // Optionally, you can handle the success response
        console.log("Task closed successfully!");
        showSnackbar("Task closed successfully!", "success");

        setTimeout(() => {
          onClose();
        }, 2000);
        // Close the dialog

        // Reset the form after successful submission
        reset();
      } else {
        // Handle errors or display an error message
        console.error("Failed to close the task:", response.statusText);
        showSnackbar("Failed to close the task", "error");
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("An error occurred during form submission:", error);
      showSnackbar("An error occurred during form submission", "error");
    }
  };

  console.log(responseStatusOptions);

  // Watch the selected type of task
  const typeOfTask = useWatch({ control, name: "type_of_task" });

  // Find the selected task object based on the selected value
  const selectedTask = taskItems.find((item) => item.id === typeOfTask);
  const CustomPopper = (props) => {
    return <Popper {...props} placement="bottom" />
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle variant="h4">
          Close Task{" "}
          <IconButton
            edge="end"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 2 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Typography required variant="h6">
                  Contact mode <span
                        style={{
                          color: "red",
                        
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                </Typography>
                <Controller
                  name="contact_mode_id"
                  control={control}
                  rules={{ required: "Field is required" }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                    size="normal"
                      select
                      {...field}
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      fullWidth
                      error={Boolean(errors.contact_mode_id)}
                    >
                      {contactModes.map((mode) => (
                        <MenuItem key={mode.id} value={mode.id}>
                          {mode.contact_mode}
                        </MenuItem>
                      ))}
                    </CustomTextFieldComp>
                  )}
                />
                {errors.contact_mode_id && (
                  <span style={{ color: "red" }}>
                    {errors.contact_mode_id.message}
                  </span>
                )}
              </Grid>

              {contactMode == 1 && (
                <Grid item xs={6} md={6}>
                  <Typography required variant="h6">
                    Response Status <span
                        style={{
                          color: "red",
                        
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                  </Typography>
                  <Controller
                    name="response_status"
                    control={control}
                    rules={{ required: "Field is required" }}
                    render={({ field }) => (
                     <CustomTextFieldComp
                    size="normal"
                        select
                        {...field}
                        InputProps={{
                          style: { borderRadius: "10px" },
                        }}
                        fullWidth
                        error={Boolean(errors.response_status)}
                      >
                        <MenuItem value="connected">Connected</MenuItem>
                      </CustomTextFieldComp>
                    )}
                  />
                  {errors.response_status && (
                    <span style={{ color: "red" }}>
                      {errors.response_status.message}
                    </span>
                  )}
                </Grid>
              )}

              {contactMode == 2 && (
                <Grid item xs={6} md={6}>
                  <Typography required variant="h6">
                    Response Status <span
                        style={{
                          color: "red",
                        
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                  </Typography>
                  <Controller
                    name="response_status"
                    control={control}
                    rules={{ required: "Field is required" }}
                    render={({ field }) => (
                      <CustomTextFieldComp
                      size="normal"
                        select
                        {...field}
                        InputProps={{
                          style: { borderRadius: "10px" },
                        }}
                        fullWidth
                        error={Boolean(errors.response_status)}
                      >
                        <MenuItem value="connected">Connected</MenuItem>
                        <MenuItem value="not connected">Not Connected</MenuItem>
                      </CustomTextFieldComp>
                    )}
                  />
                  {errors.response_status && (
                    <span style={{ color: "red" }}>
                      {errors.response_status.message}
                    </span>
                  )}
                </Grid>
              )}

              {responseStatus == "not connected" && (
                <Grid item xs={6} md={6}>
                  <Typography required variant="h6">
                    Reason <span
                        style={{
                          color: "red",
                        
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                  </Typography>
                  <Controller
                    name="not_connected_reason_id"
                    control={control}
                    rules={{ required: "Field is required" }}
                    render={({ field }) => (
                      <TextField
                        select
                        {...field}
                        InputProps={{
                          style: { borderRadius: "10px" },
                        }}
                        fullWidth
                        error={Boolean(errors.not_connected_reason_id)}
                      >
                        {notConnectedReasons.map((reason) => (
                          <MenuItem key={reason.id} value={reason.id}>
                            {reason.reason}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  {errors.not_connected_reason_id && (
                    <span style={{ color: "red" }}>
                      {errors.not_connected_reason_id.message}
                    </span>
                  )}
                </Grid>
              )}

              {(contactMode == 3 || contactMode == 4 || contactMode == 5) && (
                <Grid item xs={6} md={6}>
                  <Typography required variant="h6">
                    Response Status <span
                        style={{
                          color: "red",
                        
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                  </Typography>
                  <Controller
                    name="response_status"
                    control={control}
                    rules={{ required: "Field is required" }}
                    render={({ field }) => (
                      <TextField
                        select
                        {...field}
                        InputProps={{
                          style: { borderRadius: "10px" },
                        }}
                        fullWidth
                        error={Boolean(errors.response_status)}
                      >
                        <MenuItem value="completed">Completed</MenuItem>
                      </TextField>
                    )}
                  />
                  {errors.response_status && (
                    <span style={{ color: "red" }}>
                      {errors.response_status.message}
                    </span>
                  )}
                </Grid>
              )}

              {(contactMode == 6 || contactMode == 7) && (
                <Grid item xs={6} md={6}>
                  <Typography required variant="h6">
                    Response Status <span
                        style={{
                          color: "red",
                        
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                  </Typography>
                  <Controller
                    name="response_status"
                    control={control}
                    rules={{ required: "Field is required" }}
                    render={({ field }) => (
                      <TextField
                        select
                        {...field}
                        InputProps={{
                          style: { borderRadius: "10px" },
                        }}
                        fullWidth
                        error={Boolean(errors.response_status)}
                      >
                        <MenuItem value="attended">Attended</MenuItem>
                        <MenuItem value="not attended">Not Attended</MenuItem>
                      </TextField>
                    )}
                  />
                  {errors.response_status && (
                    <span style={{ color: "red" }}>
                      {errors.response_status.message}
                    </span>
                  )}
                </Grid>
              )}

              {contactMode == 8 && (
                <Grid item xs={6} md={6}>
                  <Typography required variant="h6">
                    Response Status <span
                        style={{
                          color: "red",
                        
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                  </Typography>
                  <Controller
                    name="response_status"
                    control={control}
                    rules={{ required: "Field is required" }}
                    render={({ field }) => (
                      <TextField
                        select
                        {...field}
                        InputProps={{
                          style: { borderRadius: "10px" },
                        }}
                        fullWidth
                        error={Boolean(errors.response_status)}
                      >
                        <MenuItem value="completed">Completed</MenuItem>
                        <MenuItem value="incompleted">Incompleted</MenuItem>
                      </TextField>
                    )}
                  />
                  {errors.response_status && (
                    <span style={{ color: "red" }}>
                      {errors.response_status.message}
                    </span>
                  )}
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                <Typography variant="h6">Remarks <span
                        style={{
                          color: "red",
                        
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span></Typography>
                <Controller
                  name="remarks"
                  control={control}
                  rules={{ required: "Field is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      multiline
                      rows={3}
                      fullWidth
                      error={Boolean(errors.remarks)}
                    />
                  )}
                />
                {errors.remarks && (
                  <span style={{ color: "red" }}>{errors.remarks.message}</span>
                )}
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography variant="h6">
                  Is Follow up Not Required?
                </Typography>
                <Controller
                  name="is_follow_up_required"
                  control={control}
                  defaultValue={true}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      color="primary"
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={handleCheckboxChange}
                    />
                  )}
                />
              </Grid>

              {!isFollowUpRequired && (
                <Grid item xs={12} md={12}>
                  <Typography variant="h6">
                    Reason For No Follow Up
                  </Typography>
                  <Controller
                    name="no_follow_up_reason"
                    control={control}
                    rules={{ required: "Field is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        InputProps={{
                          style: { borderRadius: "10px" },
                        }}
                        multiline
                        rows={2}
                        fullWidth
                        error={Boolean(errors.no_follow_up_reason)}
                      />
                    )}
                  />
                  {errors.no_follow_up_reason && (
                    <span style={{ color: "red" }}>
                      {errors.no_follow_up_reason.message}
                    </span>
                  )}
                </Grid>
              )}
              {isFollowUpRequired && (
                <>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h5">
                      Schedule Next Follow Up Task
                    </Typography>
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <Typography
                      variant="h6"
                    >
                      Service Name
                      <span
                        style={{
                          color: "red",
                        
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                    </Typography>
                    <Controller
                      name="service_id"
                      control={control}
                      rules={{ required: "Service name is required" }}
                      defaultValue="Test Preparation"
                      render={({ field }) => (
                        <CustomTextFieldComp
                        size="normal"
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "10px" },
                          }}
                          fullWidth
                          error={Boolean(errors.service_id)}
                        >
                          {services.map((service) => (
                            <MenuItem
                              key={service.id}
                              value={service.service_name}
                            >
                              {service.service_name}
                            </MenuItem>
                          ))}
                        </CustomTextFieldComp>
                      )}
                    />
                    {errors.service_id && (
                      <span style={{ color: "red" }}>
                        {errors.service_id.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <Typography
                      variant="h6"
                    >
                      Type of Task
                      <span
                        style={{
                          color: "red",
                        
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                    </Typography>
                    <Controller
                      name="type_of_task"
                      control={control}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <CustomAutocompleteComp
                          {...field}
                          disablePortal
                          // PopperComponent={CustomPopper}
                          options={taskItems}
                          getOptionLabel={(option) => option.name_of_task_type}
                          onChange={(_, value) =>
                            field.onChange(value ? value.id : null)
                          }
                          error={Boolean(errors.type_of_task)}
                          helperText={errors.type_of_task?.message}
                         
                        />
                      )}
                    />
                  </Grid>
                  {selectedTask?.name_of_task_type === "Follow up call" && (
                    <Grid item xs={6} md={6} lg={3}>
                      <Typography required variant="h6">
                        Follow up reason
                      </Typography>
                      <Controller
                        name="follow_up_reason_id"
                        control={control}
                        rules={{ required: "Field is required" }}
                        render={({ field }) => (
                          <CustomTextFieldComp
                          size="normal"
                            select
                            {...field}
                            InputProps={{
                              style: { borderRadius: "10px" },
                            }}
                            fullWidth
                          >
                            {followUpReasons.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.follow_up_reason}
                              </MenuItem>
                            ))}
                          </CustomTextFieldComp>
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} md={6} lg={3}>
                    <Typography
                      variant="h6"
                    >
                      Due Date
                      <span
                        style={{
                          color: "red",
                        
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                    </Typography>
                    <Controller
                      name="due_date"
                      control={control}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <CustomTextFieldComp
                          size="normal"
                          type="date"
                          {...field}
                          InputProps={{
                            style: { borderRadius: "10px" },
                            inputProps: {
                              min: new Date().toISOString().split("T")[0],
                            },
                          }}
                          fullWidth
                        />
                      )}
                    />
                    {errors.due_date && (
                      <span style={{ color: "red" }}>
                        {errors.due_date.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <Typography
                      variant="h6"
                    >
                      Due Time
                      <span
                        style={{
                          color: "red",
                        
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                    </Typography>
                    <Controller
                      name="due_time"
                      control={control}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                         <CustomTextFieldComp
                          size="normal"
                          type="time"
                          {...field}
                          InputProps={{
                            style: { borderRadius: "10px" },
                          }}
                          fullWidth
                          error={Boolean(errors.task_due_time)}
                          //helperText={errors.task_due_time}
                        ></CustomTextFieldComp>
                      )}
                    />
                    {errors.due_time && (
                      <span style={{ color: "red" }}>
                        {errors.due_time.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <Typography required variant="h6">
                      Task Owner
                    </Typography>
                    <Controller
                      name="task_owner"
                      control={control}
                      defaultValue={parseInt(userId) || null}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <CustomAutocompleteComp
                          {...field}
                          disablePortal
                          options={taskOwners}
                          value={
                            taskOwners.find(
                              (owner) => owner.id === field.value
                            ) || null
                          }
                          getOptionLabel={(option) =>
                            `${option.first_name ? option.first_name : ""} ${
                              option.last_name ? option.last_name : ""
                            }`
                          }
                          onChange={(_, value) =>
                            field.onChange(value ? value.id : null)
                          }
                          error={Boolean(errors.task_owner)}
                              helperText={errors.task_owner?.message}
                          
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Typography variant="h6">
                      Want Reminder for Task?
                    </Typography>
                    <Controller
                      name="want_reminder_for_task"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          color="primary"
                          inputProps={{ "aria-label": "controlled" }}
                          onChange={handleCheckboxReminderChange}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Typography variant="h6">
                      Is Task Urgent?
                    </Typography>
                    <Controller
                      name="is_it_urgent"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          color="primary"
                          inputProps={{ "aria-label": "controlled" }}
                          onChange={handleCheckboxUrgentChange}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <DialogActions>
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" color="secondary" variant="contained" size="small">
                Close Task
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default CloseTaskDialog;
