// Author : Ayesha Shaikh
//  Date : 12 Nov 2024

import {
  Button,
  Chip,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { fDate } from "../../../utils/formatTime";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function ViewLeaveRequest({ selectedId }) {
  const [expandDetails, setExpandDetails] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleToggleExpand = () => {
    setExpandDetails(!expandDetails);
  };

  // Fetch All
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await fetch(
          `${API_ENDPOINT}advisor-leave-detail/${selectedId}`
        );
        if (response.ok) {
          const data = await response.json();

          setLeaveData(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Typography>Loading....</Typography>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography
            variant="h5"
            >
              {" "}
              Employee Name
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography >
              {" "}
              {leaveData?.employee_name?.first_name}{" "}
              {leaveData?.employee_name?.last_name}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
            variant="h5"
            >
              {" "}
              Leave Type
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography >
              {" "}
              {leaveData?.leave_type_id}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
             variant="h5"
            >
              {" "}
              Start Date
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography >
              {leaveData?.start_date}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              variant="h5"
            >
              {" "}
              End Date
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography >
              {" "}
              {leaveData?.end_date}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              variant="h5"
            >
              Created By
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography >
              {" "}
              {leaveData?.created_by?.first_name}{" "}
              {leaveData?.created_by?.last_name}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
               variant="h5"
            >
              {" "}
              Requested Date
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography >
              {fDate(leaveData?.createdAt)}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              variant="h5"
            >
              {" "}
              Backup Employee
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography >
              {leaveData?.backup_emp_name?.first_name}{" "}
              {leaveData?.backup_emp_name?.last_name}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            alignItems="center"
            mt={1}
            sx={{ cursor: "pointer" }}
            onClick={handleToggleExpand}
          >
            <Typography
             variant="h4"
            >
              {" "}
              More Information
            </Typography>
            {!expandDetails ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </Grid>

          {expandDetails && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography
                 variant="h5"
                >
                  {" "}
                  Number of days requested
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography >
                  {" "}
                  {leaveData?.no_of_days}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                   variant="h5"
                >
                  {" "}
                  Total hours requested
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography >
                  {" "}
                  {leaveData?.total_hours_req}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h5"
                >
                  {" "}
                  Status
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>
                  {" "}
                  <Chip
                    label={leaveData?.status}
                   style={{background:"#00b13f"}}
                    size="small"
                    variant="contained"
                  />
                </Typography>
              </Grid>

{leaveData.notes!==null && ( 
  <>
    <Grid item xs={6}>
                <Typography
                  variant="h5"
                >
                  {" "}
                  Additional Note (Optional)
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography sx={{ fontSize: "14px" }}>
                  {" "}
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={leaveData?.notes}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "#b2b2b4", // Keep the border color gray on mouseover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#b2b2b4", // Keep the border color gray even on focus
                        },
                      },
                    }}
                    InputProps={{
                      style: { borderRadius: "14px" }, // Add borderRadius here
                    }}
                  />
                </Typography>
              </Grid></>
) }
            
            </>
          )}
        </Grid>
      )}
    </>
  );
}
