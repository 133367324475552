import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";

import FilterListIcon from "@mui/icons-material/FilterList";

import MainCard from "../../newTestPrep/components/ui-component/card/MainCard";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { useGetTokenOrLogout } from "../../utils/token";
import { useNavigate } from "react-router-dom";
import CustomAutocompleteComp from "../../newTestPrep/newComponent/AutocompleteComp";
import CustomTextFieldComp from "../../newTestPrep/newComponent/customTextFieldComp";
import FilterComp from "../../newTestPrep/newComponent/iconsComp/FilterComp";
import ApplyFilterButton from "../../newTestPrep/newComponent/NewButtonComp/ApplyFilterButton";
import ResetFilterButton from "../../newTestPrep/newComponent/NewButtonComp/ResetFilterButton";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const TasksDataGridFilter = ({
  setTasksdata,
  originaltaskData,
  isLoading,
  setIsLoading,
  setSearchData,
  toggleFilterSection,
  isFilterOpen,
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [tasks, setTasks] = useState([]);

  const [dialogPayments, setDialogPayments] = useState([]);
  const [Taskowenerlist, settaskownerlist] = useState([]);
  const [leadNameFilter, setLeadNameFilter] = useState(null);
  const [ownerfilter, setownerfilter] = useState([]);
  const [typeoftasklist, settypeoftasklist] = useState([]);
  const [taskfilter, settaskfilter] = useState([]);
  const [statusfilter, setstatusfilter] = useState([{ name: "Open", id: 1 }]);
  const [statuslist, setStatuslist] = useState([
    { name: "Open", id: 1 },
    { name: "Closed", id: 0 },
  ]);
  const [stageslist, setstageslist] = useState([]);
  const [stagesfilter, setstagesfilter] = useState([]);
  const [examlist, setExamlist] = useState([]);
  const [examfilter, setexamfilter] = useState([]);
  const [createdbylist, setcreatedbylist] = useState([]);
  const [createdbyfilter, setcreatedbyfilter] = useState([]);
  const [duedatefrom, setDuedatefrom] = useState(null);
  const [duedateto, setDuedateto] = useState(null);
  const [createddatefrom, setCreateddatefrom] = useState(null);
  const [createddateto, setCreateddateto] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [followupReason, setFollowupReason] = useState([]);
  const [closeFromDate, setClosedFromDate] = useState("");
  const [closeToDate, setClosedToDate] = useState("");
  const [FollowUpReasonList, setFollowupReasonList] = useState([]);
  const [selectedFolloupId, setSelectedFollowupId] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignName, setCampaignName] = useState([]);
  const [subServiceList, setSubSurviceList] = useState([]);
  const [subserviceName, setSubserviceName] = useState([]);
  const [prospectiveLevelList, setProspectiveLevelList] = useState([]);
  const [prospectiveLevel, setProspectiveLevel] = useState([]);
  const [branchList, setBrachList] = useState([]);
  const [brannchName, setBranchName] = useState([]);
  const [nearesstBranch, setNearestBranch] = useState([]);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApplyFilter = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      // enquiry_name:leadNameFilter,
      due_from_date: duedatefrom || null,
      due_to_date: duedateto || null,
      created_from_date: createddatefrom || null,
      created_to_date: createddateto || null,
      created_by: createdbyfilter.map((i) => i.id),
      type_of_task: taskfilter.map((i) => i.id),
      task_owner: ownerfilter.map((i) => i.id),
      // stage:stagesfilter.map((i)=>i.id),
      // test_type:examfilter.map((i)=>i.id),
      status: statusfilter.map((i) => i.id),
      user_id: parseInt(userId),
      followup_reason: followupReason.map((i) => i.id),
      closed_from_date: closeFromDate || null,
      closed_to_date: closeToDate || null,
      stage_id: stagesfilter.map((i) => i.id),
      sub_service_id: subserviceName.map((i) => i.id),
      campaign_id: campaignName.map((i) => i.id),
      branch_id: brannchName.map((i) => i.id),
      nearest_branch_id: nearesstBranch.map((i) => i.id),
      prospective_level_id: prospectiveLevel.map((i) => i.id),
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/taskfilter`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        setIsLoading(false);
        setTasksdata(result);
        setSearchData(result);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  const resetfilter = () => {
    setTasksdata(originaltaskData);
    setSearchData(originaltaskData);
    // setLeadNameFilter("");
    settaskfilter([]);
    setexamfilter([]);
    setstagesfilter([]);
    setownerfilter([]);
    setcreatedbyfilter([]);
    setstatusfilter([]);
    setDuedatefrom("");
    setDuedateto("");
    setCreateddatefrom("");
    setCreateddateto("");
    setFollowupReason([]);
    setSelectedFollowupId([]);
    setClosedFromDate("");
    setClosedToDate("");
    setstagesfilter([]);
    setSubserviceName([]);
    setCampaignName([]);
    setProspectiveLevel([]);
    setBranchName([]);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/getuserbyuser/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
        
          settaskownerlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
        
          setcreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}typeoftask`);
        if (response.ok) {
          const data = await response.json();
       
          settypeoftasklist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}campaign/event/withname`);
        if (response.ok) {
          const data = await response.json();
        
          setCampaignList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allCampaignlist = [{ campaign_name: "Select All" }, ...campaignList];
  const handleCamapignChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.campaign_name === "Select All")) {
      setCampaignName(campaignList);
    } else {
      setCampaignName(value);
    }
  };

  const alltypeoftasklist = [
    { name_of_task_type: "Select All" },
    ...typeoftasklist,
  ];

  const handleTypeOfTaskChange = (event, value) => {
    // Check if "Select All" is selected
    const ids = value.map((i) => i.id);
    if (value.some((option) => option.name_of_task_type === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (
        stagesfilter.some((option) => option.name_of_task_type === "Select All")
      ) {
        settaskfilter([]);
      } else {
        // Otherwise, select all options
        settaskfilter(typeoftasklist);
        setSelectedFollowupId(ids);
      }
    } else {
      settaskfilter(value);
      setSelectedFollowupId(ids);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}taskfollowupreason`);
        if (response.ok) {
          const data = await response.json();
          setFollowupReasonList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const alltypeofReasonlist = [
    { follow_up_reason: "Select All" },
    ...FollowUpReasonList,
  ];
  const handleTypeOfReasonChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.follow_up_reason === "Select All")) {
      setFollowupReason(FollowUpReasonList);
    } else {
      setFollowupReason(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}stage`);
        if (response.ok) {
          const data = await response.json();
       
          setstageslist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
 

  const enhancedStagesList = [{ stage_name: "Select All" }, ...stageslist];

  const handleStageChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.stage_name === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (stagesfilter.some((option) => option.stage_name === "Select All")) {
        setstagesfilter([]);
      } else {
        // Otherwise, select all options
        setstagesfilter(stageslist);
      }
    } else {
      setstagesfilter(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
     
          setSubSurviceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allSubServicelist = [{ name: "Select All" }, ...subServiceList];
  const handlesubServiceChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.name === "Select All")) {
      setSubserviceName(subServiceList);
    } else {
      setSubserviceName(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}prospectivelevel`);
        if (response.ok) {
          const data = await response.json();
       
          setProspectiveLevelList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allprospectiveLevelList = [
    { prospective_level: "Select All" },
    ...prospectiveLevelList,
  ];
  const handleProspectiveLevelChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.prospective_level === "Select All")) {
      setProspectiveLevel(prospectiveLevelList);
    } else {
      setProspectiveLevel(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
        
          setBrachList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allBranchList = [{ branch_name: "Select All" }, ...branchList];
  const handleBranchChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranchName(branchList);
    } else {
      setBranchName(value);
    }
  };

  const handleNearestBranchChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.branch_name === "Select All")) {
      setNearestBranch(branchList);
    } else {
      setNearestBranch(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalltesttype`);
        if (response.ok) {
          const data = await response.json();
      
          setExamlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);




  const alltaskOwnerlist = [{ first_name: "Select All" }, ...Taskowenerlist];

  const handeltaskowner = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.first_name === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (ownerfilter.some((option) => option.first_name === "Select All")) {
        setownerfilter([]);
      } else {
        // Otherwise, select all options
        setownerfilter(Taskowenerlist);
      }
    } else {
      setownerfilter(value);
    }
  };

  const allcreatedBylist = [{ first_name: "Select All" }, ...createdbylist];

  const handelcreatedby = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.first_name === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (
        createdbyfilter.some((option) => option.first_name === "Select All")
      ) {
        setcreatedbyfilter([]);
      } else {
        // Otherwise, select all options
        setcreatedbyfilter(createdbylist);
      }
    } else {
      setcreatedbyfilter(value);
    }
  };

  const formatDate = (date) => date.toISOString().split("T")[0];

  useEffect(() => {
    const today = new Date();
    let oneMonthAgo = new Date();
    oneMonthAgo.setDate(today.getDate() - 30);

    setCreateddateto(formatDate(today));
    setCreateddatefrom(formatDate(oneMonthAgo));
  }, []);

  return (
    <>
      <Collapse in={isFilterOpen}>
        <MainCard>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>Enquiry Name</Typography>
                  <TextField
                    variant="outlined"
                    value={leadNameFilter}
                    onChange={(e) => setLeadNameFilter(e.target.value)}
                    fullWidth
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                  />
                </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Type of Task</Typography>

              <CustomAutocompleteComp
                size="small"
                fullWidth
                value={taskfilter}
                multiple
                onChange={handleTypeOfTaskChange}
                options={alltypeoftasklist}
                getOptionLabel={(option) => option.name_of_task_type}
                tooltipContent={(option) => option.name_of_task_type}
              />
            </Grid>

            {selectedFolloupId.includes(2) && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h6">Follow Up Reasons</Typography>

                <CustomAutocompleteComp
                  value={followupReason}
                  size="small"
                  multiple
                  onChange={handleTypeOfReasonChange}
                  options={alltypeofReasonlist}
                  getOptionLabel={(option) => option.follow_up_reason}
                  tooltipContent={(option) => option.follow_up_reason}
                />
              </Grid>
            )}

            {selectedFolloupId.includes(1) && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h6">Campaign Name</Typography>

                <CustomAutocompleteComp
                  value={campaignName}
                  size="small"
                  multiple
                  onChange={handleCamapignChange}
                  options={allCampaignlist}
                  getOptionLabel={(option) => option.campaign_name}
                  tooltipContent={(option) => option.campaign_name}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Due Date From</Typography>
              <CustomTextFieldComp
                size="normal"
                variant="outlined"
                value={duedatefrom}
                onChange={(e) => setDuedatefrom(e.target.value)}
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Due Date To</Typography>
              <CustomTextFieldComp
                size="small"
                variant="outlined"
                value={duedateto}
                onChange={(e) => setDuedateto(e.target.value)}
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Stages</Typography>

              <CustomAutocompleteComp
                fullWidth
                size="small"
                value={stagesfilter}
                multiple
                onChange={handleStageChange}
                options={enhancedStagesList}
                getOptionLabel={(option) => option.stage_name}
                tooltipContent={(option) => option.stage_name}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Sub Service</Typography>

              <CustomAutocompleteComp
                fullWidth
                size="small"
                value={subserviceName}
                multiple
                onChange={handlesubServiceChange}
                options={allSubServicelist}
                getOptionLabel={(option) => option.name}
                tooltipContent={(option) => option.name}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Prospective Level</Typography>

              <CustomAutocompleteComp
                fullWidth
                size="small"
                value={prospectiveLevel}
                multiple
                onChange={handleProspectiveLevelChange}
                options={allprospectiveLevelList}
                getOptionLabel={(option) => option.prospective_level}
                tooltipContent={(option) => option.prospective_level}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Branch</Typography>

              <CustomAutocompleteComp
                fullWidth
                size="small"
                value={brannchName}
                multiple
                onChange={handleBranchChange}
                options={allBranchList}
                getOptionLabel={(option) => option.branch_name}
                tooltipContent={(option) => option.branch_name}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Nearest Branch</Typography>

              <CustomAutocompleteComp
                fullWidth
                size="small"
                value={nearesstBranch}
                multiple
                onChange={handleNearestBranchChange}
                options={allBranchList}
                getOptionLabel={(option) => option.branch_name}
                tooltipContent={(option) => option.branch_name}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>Exam Type</Typography>

                  <Autocomplete
                    fullWidth
                    value={examfilter}
                    multiple
                    onChange={(event, value) => {
                      setexamfilter(value);
                    }}
                    options={examlist}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid> */}
            {(userRole === "center_admin" ||
              userRole === "admin" ||
              userRole === "data_entry") && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h6">Task Owner</Typography>

                <CustomAutocompleteComp
                  size="small"
                  fullWidth
                  value={ownerfilter}
                  multiple
                  onChange={handeltaskowner}
                  options={alltaskOwnerlist}
                  getOptionLabel={(option) =>
                    `${option.first_name ? option.first_name : ""}${
                      option.first_name && option.last_name ? " " : ""
                    }${option.last_name ? option.last_name : ""}`
                  }
                  tooltipContent={(option) =>
                    `${option.first_name ? option.first_name : ""}${
                      option.first_name && option.last_name ? " " : ""
                    }${option.last_name ? option.last_name : ""}`
                  }
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Created By </Typography>

              <CustomAutocompleteComp
                size="small"
                fullWidth
                value={createdbyfilter}
                multiple
                onChange={
                  handelcreatedby // Passing only the value
                }
                options={allcreatedBylist}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""}${
                    option.first_name && option.last_name ? " " : ""
                  }${option.last_name ? option.last_name : ""}`
                }
                tooltipContent={(option) =>
                  `${option.first_name ? option.first_name : ""}${
                    option.first_name && option.last_name ? " " : ""
                  }${option.last_name ? option.last_name : ""}`
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Date Created From</Typography>
              <CustomTextFieldComp
                size="normal"
                variant="outlined"
                value={createddatefrom}
                onChange={(e) => setCreateddatefrom(e.target.value)}
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Date Created To </Typography>
              <CustomTextFieldComp
                size="normal"
                variant="outlined"
                value={createddateto}
                onChange={(e) => setCreateddateto(e.target.value)}
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Closed From Date</Typography>
              <CustomTextFieldComp
                size="normal"
                variant="outlined"
                value={closeFromDate}
                onChange={(e) => setClosedFromDate(e.target.value)}
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Closed To Date </Typography>
              <CustomTextFieldComp
                size="small"
                variant="outlined"
                value={closeToDate}
                onChange={(e) => setClosedToDate(e.target.value)}
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Status </Typography>

              <CustomAutocompleteComp
                size="small"
                fullWidth
                value={statusfilter}
                multiple
                onChange={(event, value) => {
                  setstatusfilter(value);
                }}
                options={statuslist}
                getOptionLabel={(option) => option.name}
                tooltipContent={(option) => option.name}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              sx={{ textAlign: "end" }}
              container
              justifyContent="end"
              gap={1}
            >
              <ApplyFilterButton onClick={handleApplyFilter} />

              <ResetFilterButton onClick={resetfilter} />
            </Grid>
          </Grid>
        </MainCard>{" "}
      </Collapse>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default TasksDataGridFilter;
