import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { fDate } from "../../../../utils/formatTime";
import StyledTableContainer from "../../../../newTestPrep/newComponent/TableContainer";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function SubServiceDetails({ enquiryPersonalId }) {
  const [loading, setLoading] = useState(false);
  const [subserviceData, setSubserviceData] = useState([]);
 




  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}route/subservicebyenqperdetail/${enquiryPersonalId}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);

          setSubserviceData(data);
          setLoading(false);
        } else {
          setLoading(false);
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <Typography textAlign='left' variant="h4" fontWeight={700}>Sub Service History</Typography>
      {loading ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {subserviceData.length === 0 ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">
                No Sub Service history available
              </Typography>
            </Grid>
          ) : (
            <StyledTableContainer
              component={Paper}
              sx={{ borderRadius: "15px", padding: "10px" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Sub Service
                    </TableCell>
                    <TableCell
                      
                    >
                      Assign To
                    </TableCell>
                    <TableCell
                     
                    >
                      Co Assign
                    </TableCell>
                    <TableCell
                     
                    >
                      Enquiry Date
                    </TableCell>
                    <TableCell
                     
                    >
                      Last FollowUp
                    </TableCell>
                    <TableCell
                    
                    >
                      Next FollowUp
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subserviceData.map((row, index) => (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#f0f0f0" : "inherit",
                        width: "6%",
                      }}
                    >
                      <TableCell>{row?.sub_service_id?.name}</TableCell>
                      <TableCell
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {row?.assign_to?.first_name} {row?.assign_to?.last_name}
                      </TableCell>
                      <TableCell>
                        {row?.co_assignee?.first_name}
                        {row?.co_assignee?.last_name}
                      </TableCell>
                      <TableCell>{row?.enquiry_date}</TableCell>
                      <TableCell>{row?.last_follow_up_date}</TableCell>
                     
                      <TableCell> {row?.next_follow_up_date}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          )}
        </>
      )}
    </>
  );
}
