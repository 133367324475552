import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CustomPopup from "../../../../../components/CustomPopup";
import AllBranches from "../../../../../newTestPrep/pages/Campaign/AllBranches";
import CampaignRemainingData from "./CampaignRemainingData";

export default function CampaignDetail({ enquiryData }) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  console.log("enquirydata", enquiryData);
  const enquiryId = enquiryData.id;
  const [campaignData, setCampaignData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [branchPopup, setBranchpopup] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedTitile, setSelectedTitle] = useState("");
  const openpopup = (data, title) => {
    setBranchpopup(true);
    setSelectedData(data);
    setSelectedTitle(title);
  };

  const closepopup = () => {
    setBranchpopup(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}route2.0/campaigndetail/${enquiryId}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);

          setCampaignData(data);
          setLoading(false);
        } else {
          setLoading(false);
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const columns = [
    {
      field: "lead_Source_Type",
      headerName: "Lead Source Type",
      width: 150,

     
      renderCell: (params) => (
        <div>{params.row?.lead_source_type_id?.name}</div>
      ),
    },
    {
      field: "leadSubSource",
      headerName: "Lead Sub Source",
      width: 150,


      renderCell: (params) => (
        <div>{params.row?.sub_lead_source_type_id?.source_type}</div>
      ),
    },

    {
      field: "campaignName",
      headerName: "Campaign Name",
      width: 450,

   
      renderCell: (params) => (
        <div>{params.row?.campaign_id?.campaign_name}</div>
      ),
    },

    {
      field: "country",
      headerName: "Country",
      width: 350,


      renderCell: (params) => (
        <>
          {" "}
          <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {params.row.campaign_id?.country_id.length > 0
              ? params.row?.campaign_id?.country_id.slice(0, 5).join(" , ")
              : ""}
          </div>
          {params.row?.campaign_id?.country_id.length > 5 && (
            <Button
              size="small"
              variant="contained"
              onClick={() =>
                openpopup(params.row?.campaign_id?.country_id, "Countries")
              }
            >
              View{" "}
            </Button>
          )}
        </>
      ),
    },

    {
      field: "sub_service",
      headerName: "Sub Service",
      width: 250,

   
      renderCell: (params) => (
        <>
          {" "}
          <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {params.row.campaign_id?.sub_service_id.length > 0
              ? params.row?.campaign_id?.sub_service_id.slice(0, 5).join(" , ")
              : ""}
          </div>
          {params.row?.campaign_id?.sub_service_id.length > 5 && (
            <Button
              size="small"
              variant="contained"
              onClick={() =>
                openpopup(params.row?.campaign_id?.sub_service_id, "Services")
              }
            >
              View{" "}
            </Button>
          )}
        </>
      ),
    },

    {
      field: "branch",
      headerName: "Branch",
      width: 350,


      renderCell: (params) => (
        <>
          {" "}
          <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {params.row.campaign_id?.branch_id.length > 0
              ? params.row?.campaign_id?.branch_id.slice(0, 4).join(" , ")
              : ""}
          </div>
          {params.row?.campaign_id?.branch_id.length > 4 && (
            <Button
              size="small"
              variant="contained"
              onClick={() =>
                openpopup(params.row?.campaign_id?.branch_id, "Branches")
              }
            >
              View{" "}
            </Button>
          )}
        </>
      ),
    },
  ];

  console.log("campaignData", campaignData);
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Campaign Data Found</Box>
      </StyledGridOverlay>
    );
  }
  const autoHeight = "200px";
  return (
    <>
      <div style={{ width: "100%", marginTop: "10px" }}>
      
        {loading ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={campaignData}
            columns={columns}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            autoHeight={campaignData.length === 0 && autoHeight}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            sx={{ marginTop: "20px" }}
            pageSizeOptions={[25, 50, 100]}
          />
        )}
      </div>

      <CustomPopup
        open={branchPopup}
        onClose={closepopup}
        component={
          <CampaignRemainingData
            onClose={closepopup}
            selectedData={selectedData}
          />
        }
        title={selectedTitile}
        showDivider="true"
        maxWidth="xs"
      />
    </>
  );
}
