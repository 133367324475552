// Author : Ayesha Shaikh
//  Date : 17 sep 2024

import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  makeStyles,
  Paper,
  Slide,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CustomPopup from "../../../components/CustomPopup";
import AddEmployeeForm from "./AddEmployeeForm";
import { FlashOnRounded } from "@mui/icons-material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import ViewEmployeeDetails from "./ViewEmployeeDetails";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EmployeeFilter from "./EmployeeFilter";
import { fDate } from "../../../utils/formatTime";
import CloseIcon from "@mui/icons-material/Close";

import EmployeeEnquiryTaskData from "./EmployeeEnquiryTaskData";
import { useGetTokenOrLogout } from "../../../utils/token";
import { CSVExport } from "../../components/ui-component/table/CSVExport";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";
import FilterComp from "../../newComponent/iconsComp/FilterComp";
import FilterButtonComponent from "../../newComponent/NewButtonComp/FilterButtonComponent";
import ApplyFilterButton from "../../newComponent/NewButtonComp/ApplyFilterButton";
import SearchTextFieldComp from "../../newComponent/newtextfieldComp/SearchTextFieldComp";
import StyledTableContainer from "../../newComponent/TableContainer";
import CustomNoRowsOverlay from "../../newComponent/TableCustomeComp/CustomNoRowsOverlay";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EmployeeProfile() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();

  const userId = localStorage.getItem("userId");
  const [employeeData, setEmployeeData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [showSwitch, setShowSwitch] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [checkedState, setCheckedState] = useState({});
  const [viewPopup, setViewPopup] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [viewTaskData, setViewTaskData] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [exportData, setExportData] = useState([]);
  const [openRole, setOpenRole] = React.useState(false);
  const [remainingRoles, setRemainingRoles] = React.useState([]);
  const visibleRolesCount = 1; // Number of roles to display directly
  const handleOpenView = (row) => {
    console.log(row);
    setViewPopup(true);
    setSelectedId(row?.user_id);
  };

  const handleCloseView = () => {
    setViewPopup(false);
  };

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          {" "}
          <ActionButton onClick={() => handleOpenView(params?.row)}>
            <Tooltip title="View">
              <VisibilityOutlinedIcon
                fontSize="small"
                style={{
                  color: "#2196F3",
                  fontSize: "0.8rem",
                }}
              />
            </Tooltip>
          </ActionButton>
          {showSwitch && (
            <>
              <ActionButton
                onClick={() => handleEdit(params?.row)}
                style={{ marginLeft: "7px" }}
              >
                <Tooltip title="Edit">
                  <EditIcon fontSize="small" style={{ fontSize: "0.8rem" }} />
                </Tooltip>
              </ActionButton>

              <Tooltip
                title={
                  params?.row?.is_active === 0 ? "Make Active" : "Make Inactive"
                }
              >
                <Switch
                  checked={!!checkedState[params?.row.id]}
                  onChange={(event) =>
                    handleChange(event, params?.row?.id, params?.row)
                  }
                  size="small"
                  color="secondary"
                  // sx={{
                  //   "& .MuiSwitch-switchBase": {
                  //     padding: 1,
                  //     height: 1,
                  //   },
                  //   "& .MuiSwitch-thumb": {
                  //     width: 12, // Thumb size
                  //     height: 12,
                  //     background: "#00b13f", // Custom thumb color
                  //   },
                  // }}
                  sx={{
                    "& .MuiSwitch-switchBase": {
                      padding: 1,
                      height: 1,
                    },
                    "& .MuiSwitch-thumb": {
                      width: 12, // Thumb size
                      height: 12,
                      background: checkedState[params?.row.id]
                        ? "#00b13f"
                        : "#f44336", // Green when active, Red when inactive
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: checkedState[params?.row.id]
                        ? "#e0f7e0"
                        : "#ff9fad", // Light green for active, light red for inactive
                      opacity: 1, // Ensure visibility
                    },
                  }}
                />
              </Tooltip>

              <Chip
                label="Data Transfer"
                variant="outlined"
                size="small"
                style={{
                  background: "white",
                  marginLeft: "7px",
                }}
                onClick={() => handleViewTaskDataPopup(params?.row)}
              />
            </>
          )}
        </div>
      ),
    },

    {
      field: "emp_name",
      headerName: "Employee Name",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          {params?.row?.first_name} {params?.row?.last_name}
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <> {params?.row?.email} </>,
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <> {params?.row?.branch?.branch_name} </>,
    },
    {
      field: "branch_manager",
      headerName: "Branch Manager",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <> {params?.row?.branch_manager} </>,
    },
    {
      field: "reporting_manager",
      headerName: "Reporting Manager",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          {" "}
          {params?.row.reporting_manager
            ? params?.row.reporting_manager.join(", ")
            : ""}{" "}
        </>
      ),
    },

    {
      field: "role",
      headerName: "Role",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          {" "}
          {(() => {
            const roles = params?.row?.role || [];
            const visibleRoles = roles.slice(0, visibleRolesCount);
            const extraRoles = roles.slice(visibleRolesCount);

            return (
              <>
                {/* Display visible roles */}
                {visibleRoles.map((role, index) => (
                  <Chip
                    key={index}
                    size="small"
                    variant="contained"
                    label={getRoleLabel(role)}
                    style={{
                      marginRight: 8,
                      height: "15px",
                      background: getRoleColor(role),
                    }}
                  />
                ))}

                {/* Display a "+more" chip if there are extra roles */}
                {extraRoles.length > 0 && (
                  <Typography
                    variant="h6"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRolePopupOpen(extraRoles)}
                  >
                    {`+${extraRoles.length}`}{" "}
                  </Typography>
                )}
              </>
            );
          })()}{" "}
        </>
      ),
    },
    {
      field: "created_date",
      headerName: "Created Date",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => params?.row.created_date,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params?.row?.is_active === 1 ? (
          <Chip
            size="small"
            variant="contained"
            label="Active"
            style={{ background: "#00b13f" }}
          />
        ) : params?.row?.is_active === 0 ? (
          <Chip
            size="small"
            variant="contained"
            label="InActive"
            color="error"
          />
        ) : (
          ""
        ),
    },
  ];

  // Handle the switch change for a specific row
  const handleChange = async (event, id, row) => {
    const isChecked = event.target.checked;

    setCheckedState((prevState) => ({
      ...prevState,
      [id]: isChecked, // Update the state for the specific row ID
    }));

    try {
      const requestData = {
        is_active: isChecked,
        user_id: parseInt(userId),
      };
      const response = await fetch(
        `${API_ENDPOINT}employee/inactive/profile/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        showSnackbar(
          isChecked === true
            ? "User acount is activated successfully"
            : "User acount is deactivated successfully",
          "success"
        );

        const updatedData = employeeData.map((item) =>
          item?.id === row.id
            ? { ...item, is_active: isChecked === true ? 1 : 0 }
            : item
        );

        setEmployeeData(updatedData);
      } else if (response.status === 401) {
        showSnackbar("Unknown User", "error");
      } else if (response.status === 404) {
        showSnackbar("Error to Updating Status", "error");
        setCheckedState((prevState) => ({
          ...prevState,
          [id]: !isChecked, // Revert the state if the request fails
        }));
      } else if (response.status === 401) {
        showSnackbar("Unathorized User", "error");
      } else {
        showSnackbar("Error to Updating Status", "error");
        setCheckedState((prevState) => ({
          ...prevState,
          [id]: !isChecked, // Revert the state if the request fails
        }));
        throw new Error("Error to Updating Status");
      }
    } catch (error) {
      setCheckedState((prevState) => ({
        ...prevState,
        [id]: !isChecked, // Revert the state if the request fails
      }));
      showSnackbar("Error to Updating Status", "error");
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedatas = employeeData.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = getTokenOrLogout();
      if (!token) return;
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      try {
        const response = await fetch(`${API_ENDPOINT}employee`, { headers });
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setEmployeeData(sortedData);
          setSearchData(sortedData);
          setOriginalData(sortedData);
          const initialState = sortedData.reduce((acc, row) => {
            acc[row.id] = row.is_active === 1;
            return acc;
          }, {});
          setCheckedState(initialState);

          setLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        navigate("/page500");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
      .then((response) => {
        if (response.ok) {
          setShowSwitch(true);
        } else {
          setShowSwitch(false);
        }
      })
      .catch((err) => {
        setShowSwitch(false);
      });
  }, [userId]);

  const handleEdit = (row) => {
    const newState = { employeedetails: row };
    localStorage.setItem("employeeState", JSON.stringify(newState));

    const url = `/view-profile/${row?.id}`;
    const newTab = window.open(url, "_blank");

    if (!newTab) {
      navigate(url, { state: newState });
    }
  };

  const ActionButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: "#E5E4E2",
    width: "20px",
    height: "20px",
  }));

  const handleSearchByName = (e) => {
    const value = e.target.value;
    let filteredData1 = searchData.filter((data) =>
      `${data?.first_name} ${data?.last_name}`
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );
    setEmployeeData(filteredData1);
  };

  const handleFilterToggel = () => {
    setFilterOpen(!filterOpen);
  };

  const handleViewTaskDataPopup = (row) => {
    setViewTaskData(true);
    setSelectedId(row?.user_id);
    setSelectedRow(row);
  };
  const handlecloseTaskDataPopup = () => {
    setViewTaskData(false);
  };

  // Export Data
  useEffect(() => {
    const exportData = employeeData.map((row) => ({
      employee_name: `${row?.first_name} ${
        row?.last_name ? row.last_name : ""
      }`,
      email: row?.email || "",
      mobile_no: `${row.mobile_country_code} ${row.mobile ? row.mobile : ""}`,
      gender: row?.gender || "",
      date_of_birth: row.dob || "",
      joining_date: row.joining_date || "",
      designation: row.designation || "",
      role: row?.role,
      branch: row.branch?.branch_name || "",
      branch_manager: row?.branch_manager || "",
      reporting_manager: row?.reporting_manager || "",
      report_cc: row?.add_report_cc_user || "",
      status:
        row.is_active === 1 ? "Active" : row.is_active === 0 ? "Inactive" : "",
    }));
    setExportData(exportData);
  }, [employeeData]);

  const handleRolePopupOpen = (roles) => {
    setRemainingRoles(roles);
    setOpenRole(true);
  };

  const handleRolePopupClose = () => {
    setOpenRole(false);
  };
  // Helper functions to map role to label and color
  const getRoleLabel = (role) => {
    const roleLabels = {
      developer: "Developer",
      advisor: "Advisor",
      admin: "Admin",
      center_admin: "Center Admin",
      data_entry: "Data Entry",
      trainer: "Trainer",
      accountant: "Accountant",
      frontdesk_advisor: "FrontDesk Advisor",
      svo_coordinator: "SVO CoOrdinator",
    };
    return roleLabels[role] || role;
  };

  const getRoleColor = (role) => {
    const roleColors = {
      developer: "#46b1ff",
      advisor: "#46b1ff",
      admin: "#ff4b4b",
      center_admin: "#ff4b4b",
      data_entry: "#ffc107",
      trainer: "#651fff",
      accountant: "#00b13f",
      // frontdesk_advisor: "info",
      svo_coordinator: "#00b13f",
    };
    return roleColors[role] || "default";
  };

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fetching
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2} sm={3} md={2.5}>
          <HeadingCard label="Employees Profile" />
        </Grid>
        {/* <Grid item xs={4} lg={8}></Grid> */}

        <Grid
          item
          xs={12}
          lg={10}
          sm={9}
          md={9.5}
          container
          direction="row"
          justifyContent="end"
          alignItems="center"
          gap={1}
        >
          {showSwitch && (
            <ApplyFilterButton
              label="Add Employess"
              addbutton={true}
              onClick={handleOpen}
            />
          )}
          <FilterButtonComponent
            text="Filter"
            onClick={handleFilterToggel}
            open={filterOpen}
            showendIcon={true}
          />

          {showSwitch && (
            <CSVExport data={exportData} filename="employeeprofile.csv" />
          )}
        </Grid>

        {filterOpen && (
          <Grid item xs={12}>
            <EmployeeFilter
              loading={loading}
              setLoading={setLoading}
              originalData={originalData}
              showSnackbar={showSnackbar}
              setEmployeeData={setEmployeeData}
              setSearchData={setSearchData}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <MainCard>
            <Grid
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              textAlign="end"
              mt={2}
              mb={2}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <SearchTextFieldComp
                  // value={value}
                  onChange={handleSearchByName}
                />
              </Box>
            </Grid>

            <DataGrid
              rows={employeeData}
              columns={columns}
              slots={{
                noRowsOverlay: () => (
                  <CustomNoRowsOverlay message="No Data Available" />
                ),
              }}
              autoHeight
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              sx={{ marginTop: "20px" }}
              pageSizeOptions={[25, 50, 100]}
            />
          </MainCard>
        </Grid>
      </Grid>

      <CustomPopup
        open={open}
        onClose={handleClose}
        component={<AddEmployeeForm onClose={handleClose} />}
        title="Add Employee"
        maxWidth="md"
        showDivider={true}
      />

      <CustomPopup
        open={viewPopup}
        onClose={handleCloseView}
        component={
          <ViewEmployeeDetails
            onClose={handleCloseView}
            selectedId={selectedId}
          />
        }
        title="Employee Details"
        maxWidth="md"
        showDivider={true}
      />

      {/* <CustomPopup
        open={viewTaskData}
        onClose={handlecloseTaskDataPopup}
        component={
          <EmployeeTaskData
            onClose={handlecloseTaskDataPopup}
            selectedId={selectedId}
          />
        }
        title="Leads and Task Transfers"
        maxWidth="xl"
        showDivider={true}
      /> */}

      <Dialog
        fullScreen
        open={viewTaskData}
        onClose={handlecloseTaskDataPopup}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h3">Leads and Task Transfers</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handlecloseTaskDataPopup}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <EmployeeEnquiryTaskData
            onClose={handlecloseTaskDataPopup}
            selectedId={selectedId}
            setCheckedState={setCheckedState}
            employeeData={employeeData}
            setEmployeeData={setEmployeeData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
          />
        </DialogContent>
      </Dialog>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {/* Popup to display remaining roles */}
      <Dialog
        open={openRole}
        onClose={handleRolePopupClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle variant="h4">
          Remaining Roles{" "}
          <IconButton
            edge="end"
            onClick={handleRolePopupClose}
            aria-label="close"
            sx={{ position: "absolute", right: 15, top: 2 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List>
            {remainingRoles.map((role, index) => (
              // <ListItem key={index}>
              //   <ListItemText primary={getRoleLabel(role)} />
              // </ListItem>

              <Chip
                style={{ margin: "5px", background: getRoleColor(role) }}
                variant="contained"
                size="small"
                label={getRoleLabel(role)}
              ></Chip>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
