import React, { useEffect, useState } from "react";
import MainCard from "../../components/ui-component/card/MainCard";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ButtonComponent from "../../../components/button/ButtonComponent";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { gridSpacing } from "../../store/constant";
import FollowerCard from "../../components/ui-component/card/TestTypeCard";
import SubCard from "../../components/ui-component/card/SubCard";
import AddTestTypeDialog from "./AddTestType";
import ConfirmationDialog from "../../components/ui-component/card/ConfirmationDialog";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import TestTypeCard from "../../components/ui-component/card/TestTypeCard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddButton from "../../newComponent/NewButtonComp/AddButton";
import SearchTextFieldComp from "../../newComponent/newtextfieldComp/SearchTextFieldComp";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function TestType({ title }) {
  const theme = useTheme();
  //const testtypes = ['IELTS', 'PTE']
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");

  const [testTypes, setTestTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedTestType, setSelectedTestType] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  async function updateTestTypeStatus(testTypeId, newStatus) {
    console.log(newStatus);
    try {
      const response = await fetch(`${API_ENDPOINT}testtype/${testTypeId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          is_active: newStatus,
          user_id: parseInt(userId),
        }),
      });
      console.log(response);

      if (response.ok) {
        // Status update was successful
        //alert('Status Updated')
        console.log(response);
        showSnackbar("Test type status updated successfully", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        // Handle errors, e.g., show a notification or log the error
        console.error(
          "Failed to update test type status:",
          response.statusText
        );
        showSnackbar("Error updating status", "error");
      }
    } catch (error) {
      console.error("Error updating test type status:", error);
      showSnackbar("Error updating status", "error");
    }
  }

  const handleAddTestType = async (name) => {
    const newName = name.toUpperCase();
    if (name === "") {
      showSnackbar("Please add  Test Name", "error");
      return;
    }

    const isValid = /^[A-Za-z\s]+$/.test(name);
    if (!isValid) {
      showSnackbar(
        "Please add a valid name (only alphabetic characters allowed)",
        "error"
      );
      return;
    }

    if (name.startsWith(" ")) {
      showSnackbar(
        "Please add a valid name (only alphabetic characters allowed)",
        "error"
      );
      return;
    }
    const requestData = {
      name: newName,
      user_id: parseInt(userId),
    };

    try {
      const response = await fetch(`${API_ENDPOINT}testtype`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        showSnackbar("Test Type created successfully", "success");

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else if (response.status === 409) {
        // Handle errors, e.g., show an error message
        showSnackbar("Duplicate test name is found!!", "error");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleConfirmAction = () => {
    updateTestTypeStatus(
      selectedTestType.id,
      selectedTestType.is_active === 0 ? 1 : 0
    );
    setConfirmDialogOpen(false);
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    setIsLoading(true);
    fetch(`${API_ENDPOINT}route/gettesttypes`)
      .then((response) => response.json())
      .then((data) => {
        setTestTypes(data);
        setIsLoading(false);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
        setIsLoading(false);
      });
  }, []);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} sm={4} lg={2}>
       <HeadingCard label="Test Type" imageSrc="/test (2).png" imgewidth={20}/>
      </Grid>

      {userRole === "center_admin" && (
        <Grid item xs={12}  md={9} sm={8} lg={10} textAlign="end">
          <AddButton
            onClick={() => setAddDialogOpen(true)}
            label="Add Test Type"
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <MainCard>
          <Box textAlign="end">
            <SearchTextFieldComp
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Box>
          <Grid container spacing={2} mt={1}>
            {isLoading ? (
              <Grid
                item
                xs={12}
                style={{
                  minHeight: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="primary" />
              </Grid>
            ) : testTypes.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  minHeight: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">No test types available</Typography>
              </Grid>
            ) : (
              <>
                {testTypes
                  .filter((testtype) =>
                    testtype.name
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  )
                  .map((testtype,index) => (
                    <Grid item xs={6} md={4} lg={3} key={index}>
                      
                        <TestTypeCard
                       index ={index}
                          {...testtype}
                          onActivate={() => {
                            setSelectedTestType(testtype);
                            setConfirmDialogOpen(true);
                          }}
                          onDeactivate={() => {
                            setSelectedTestType(testtype);
                            setConfirmDialogOpen(true);
                          }}
                        />
                     
                    </Grid>
                  ))}
                <ConfirmationDialog
                  open={isConfirmDialogOpen}
                  onClose={() => setConfirmDialogOpen(false)}
                  onConfirm={handleConfirmAction}
                  message={`Are you sure you want to make ${
                    selectedTestType?.is_active === 0 ? "active" : "inactive"
                  }?`}
                />
              </>
            )}
          </Grid>

          <AddTestTypeDialog
            open={isAddDialogOpen}
            onClose={() => setAddDialogOpen(false)}
            onAdd={handleAddTestType}
          />

          <CustomSnackbar
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        </MainCard>
      </Grid>
    </Grid>
  );
}

export default TestType;
