// customTextFieldComp.js
import React from 'react';
import { TextField, ThemeProvider } from '@mui/material';
import CustomTextFieldtheme from '../themes/textFieldTheme'; // Adjust the path if necessary

const CustomTextFieldComp = ({  ...props }) => {
  return (
    <ThemeProvider theme={CustomTextFieldtheme}>
      <TextField
        
       
        {...props} // Spread other props like fullWidth, helperText, etc.
      />
    </ThemeProvider>
  );
};

export default CustomTextFieldComp;
