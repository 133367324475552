import { Avatar, ButtonBase, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MENU } from '../../../store/actions';

function ToggleSidebar() {
    const theme = useTheme();
      const leftDrawerOpened = useSelector((state) => state.customization.opened);
      const dispatch = useDispatch();
      const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
      };
  return (
    <div>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.dark,
              color: '#fff',
              //color: theme.palette.secondary.light,
              '&:hover': {
                background: 'transparent', 
                color: 'inherit'
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <MenuIcon stroke={1.5} size="1rem" />
          </Avatar>
        </ButtonBase>
    </div>
  )
}

export default ToggleSidebar