import React, { useEffect, useState } from 'react'
import StyledTableContainer from '../../newComponent/TableContainer';
import { Backdrop, Box, Button, CircularProgress, Grid, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar';
import CustomPopup from '../../../components/CustomPopup';
import AddProductTarget from './AddProductTarget';
import EditEmployeeTarget from './EditEmployeeTarget';
import EditProductTarget from './EditProductTarget';


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function ViewAdvisorProductTargets({ advisorId, monthId, year}) {
    const userId = localStorage.getItem("userId");
    const navigate = useNavigate();
    const [paginatedTargets, setPaginatedTargets] = useState([]);
    const [loading, setLoading] = useState(false)
    const [addPopup, setAddPopup] = useState(false);
      const [editPopup, setEditPopup] = useState(false);
      const [selectedData, setSelectedData] = useState(null)
     const [snackbarOpen, setSnackbarOpen] = React.useState(false);
      const [snackbarMessage, setSnackbarMessage] = React.useState("");
      const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
      const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };

      //Open Add Popup
  const handleOpenAddPopup = () => {
    setAddPopup(true);
  };

  //Close Add Popup
  const handleCloseAddPopup = () => {
    setAddPopup(false);
  };

  //Open Edit Popup
  const handleOpenEditPopup = (rowData) => {
    setEditPopup(true);
    setSelectedData(rowData);
  };

  //Close Edit Popup
  const handleCloseEditPopup = () => {
    setEditPopup(false);
  };

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
            if (!advisorId?.id || !monthId?.id || !year || !userId) {
              setLoading(false);
              return;
            }
      
            const requestData = {
              adv_user_id: advisorId.id,
              month_id: monthId.id,
              year: year,
              user_id: parseInt(userId),
            };
      
            const response = await fetch(`${API_ENDPOINT}advisor-rev-target/getadvisorproducttargets`, {
              method: "POST",
              headers: { "Content-Type": "application/json" }, // Add headers
              body: JSON.stringify(requestData),
            });
      
            if (!response.ok) {
              setLoading(false);
              if (response.status === 404) {
                console.warn("Data not found.");
              } else if (response.status === 401) {
                console.warn("Unauthorized access.");
                navigate("/page401")
              } else if (response.status === 403) {
                console.warn("Forbidden request.");
                navigate("/page403")
              } else if (response.status === 440) {
                console.warn("Session expired.");
                navigate("/page440")
              } else {
                console.error("Unexpected error:", response.status);
                showSnackbar("Error fetching employee target",'error');
              }
              return;
            }
      
            const data = await response.json();
            setPaginatedTargets(data);
          } catch (error) {
            console.error("Error fetching data:", error);
            showSnackbar("Error fetching employee target",'error');
          } finally {
            setLoading(false);
          }
        };
      
        fetchData();
      }, [advisorId, monthId, year, userId, addPopup, editPopup]); 
      

  return (
    <div>
        <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fetching
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>

      <Box sx={{ display: "flex", justifyContent: "space-evenly", marginBottom: 2 }}>
        <Typography variant="h5">
            Advisor: {advisorId?.id ? ` ${advisorId?.first_name}` : "N/A"}
        </Typography>
        <Typography variant="h5">
            Month: {monthId ? monthId.month_full_name : "N/A"}
        </Typography>
        <Typography variant="h5">
            Year: {year ? year : "N/A"}
        </Typography>
        <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => handleOpenAddPopup()}
            >
            Add Another Product Target
            </Button>
        </Box>
        <StyledTableContainer component={Paper} borderLeft={true}>
              <Table
                sx={{ minWidth: 650, tableLayout: "fixed" }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderLeft: "0.1px solid #C3C3C3" }}>
                      Product Name
                    </TableCell>
                    <TableCell sx={{}}>Revenue Target</TableCell>
                    <TableCell sx={{}}>Enrolment Target</TableCell>
                    <TableCell sx={{}}>Created By</TableCell>
                    <TableCell sx={{ width: "10%" }}>Action</TableCell>
                  </TableRow>
                </TableHead>

                {paginatedTargets?.targets?.length === 0 ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">No data available</Typography>
                  </Grid>
                ) : (
                  paginatedTargets?.targets?.map((row, index) => {
                    const currentDate = new Date();
                    const isPastDate =
                      parseInt(row?.year) < currentDate.getFullYear() ||
                      (parseInt(row?.year) === currentDate.getFullYear() &&
                        row?.month_id?.id < currentDate.getMonth() + 1);
                    return (
                      <TableBody>
                        <TableRow key={row.id}  style={{
                          backgroundColor:
                            index % 2 === 0 ? "#f0f0f0" : "inherit",
                        }}>
                          <TableCell
                       
                            sx={{ borderLeft: "0.1px solid #C3C3C3" }}
                          >
                            {row?.product_id?.name}
                          </TableCell>
                          <TableCell>{row?.rev_target} /-</TableCell>
                          <TableCell>{row?.enrolment_target}</TableCell>

                          <TableCell>{row?.created_by?.first_name}{" "}
                          {row?.created_by?.last_name}</TableCell>
                          <TableCell
                            sx={{
                              width: "10%",
                            }}
                          >
                            <Button
                              disabled={isPastDate}
                              size="small"
                              variant="contained"
                              color="secondary"
                              onClick={() => handleOpenEditPopup(row)}
                            >
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })
                )}
              </Table>
            </StyledTableContainer>
            <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
            <CustomPopup
                    open={addPopup}
                    onClose={handleCloseAddPopup}
                    component={
                        <AddProductTarget 
                        onClose={handleCloseAddPopup} 
                        advisorId={advisorId}
                        monthId={monthId}
                        year={year}
                         />}
                    title="Add Product Target"
                    maxWidth="sm"
                    showDivider={true}
                  />
                  <CustomPopup
                    open={editPopup}
                    onClose={handleCloseEditPopup}
                    component={
                      <EditProductTarget
                        onClose={handleCloseEditPopup}
                        selectedData={selectedData}
                      />
                    }
                    title="Edit Product Target"
                    maxWidth="sm"
                    showDivider={true}
                  />
    </div>
  )
}

export default ViewAdvisorProductTargets