// Author : Ayesha Shaikh
//  Date : 02 Dec 2024

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import CustomAutocompleteComp from "../../newComponent/AutocompleteComp";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AddEmployeeTarget() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const [advisorList, setAdvisorList] = useState([]);
  const [advisorName, setAdvisorName] = useState(null);
  const [testTypeList, setTestTypeList] = useState([]);
  const [testType, setTestType] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [monthname, setMonthName] = useState([]);
  const [productList, setProductList] = useState([]);
  const [product, setProduct] = useState([]);
  const [selectedyear, setSelectedYear] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}advisor`);
        if (response.ok) {
          const data = await response.json();

          setAdvisorList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}month`);
        if (response.ok) {
          const data = await response.json();

          setMonthList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalltesttype`);
        if (response.ok) {
          const data = await response.json();

          setTestTypeList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}product`);
        if (response.ok) {
          const data = await response.json();

          setProductList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleemployeeChange = (event, value) => {
    setAdvisorName(value);
    setValue("adv_user_id", value?.user_id);
  };

  const handleMonthChange = (event, value) => {
    setMonthName(value);
    setValue(
      "month_id",
      value.map((i) => i.id)
    );
  };

  const handleyearChange = (event, value) => {

    setSelectedYear(value);
    setValue("year", value?.year);
  };

  const handleTestTypeChange = (event, value) => {
    setTestType(value);
    setValue(
      "product_id",
      value.map((i) => i.id)
    );
  };

  const onSubmit = async (data) => {


    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const productTargets = testType.map((product, index) => ({
        product_id: product.id,
        rev_target: parseInt(data.rev_target?.[product.id] || 0, 10), // Get revenue target
        enroll_target: parseInt(data.enroll_target?.[product.id] || 0, 10), // Get enrollment target
      }));

      const requestData = {
        ...data,
        user_id: parseInt(userId, 10),
        product_targets: productTargets,
        year: selectedyear?.year,
      };

      const response = await fetch(`${API_ENDPOINT}advisor-rev-target`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });
      if (response.ok) {
        showSnackbar("Employee Target Added Successfully", "success");

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        showSnackbar("Error in add employee target", "error");
      }
    } catch (error) {
      showSnackbar("Error in add employee target", "error");
    }
  };

  const years = Array.from({ length: 3 }, (_, index) => ({
    year: new Date().getFullYear() - 1 + index,
  }));


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            {" "}
            Employee Name{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="adv_user_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomAutocompleteComp
                {...field}
                size="small"
                value={advisorName}
                onChange={handleemployeeChange}
                options={advisorList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : " "} ${
                    option.last_name ? option.last_name : " "
                  }`
                }
                error={errors.adv_user_id}
                helperText={errors.adv_user_id && "Employee name is required"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "14px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            )}
          ></Controller>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            {" "}
            Select Month{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="month_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomAutocompleteComp
                {...field}
                size="small"
                multiple
                value={monthname}
                onChange={handleMonthChange}
                options={monthList}
                getOptionLabel={(option) => option.month_full_name}
                error={errors.month_id}
                helperText={errors.month_id && "Month is required"}
                tooltipContent={(option) => option.month_full_name}
              />
            )}
          ></Controller>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            Year{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="year"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomAutocompleteComp
                {...field}
                size="small"
                value={selectedyear}
                onChange={handleyearChange}
                options={years}
                getOptionLabel={(option) => option.year}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            {" "}
            Select Product{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="product_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomAutocompleteComp
                {...field}
                size="small"
                multiple
                value={testType}
                onChange={handleTestTypeChange}
                options={productList}
                getOptionLabel={(option) => option.name}
                error={errors.test_type_id}
                helperText={errors.test_type_id && "Product is required"}
                tooltipContent={(option) => option.name}
              />
            )}
          ></Controller>
        </Grid>

        <Box mt={5} />
        {testType.map((product, index) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                {" "}
                Product Name{" "}
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1em",
                  }}
                >
                  *
                </span>
              </Typography>

              <Controller
                name="product_id"
                control={control}
                //rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    {...field}
                    value={product.name}
                    fullWidth
                    size="normal"
                    error={errors.product_id}
                    helperText={errors.product && "Product is required"}
                    InputProps={{
                      readOnly: true,
                      style: {
                        borderRadius: "10px",
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                Add Revenue Target{" "}
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1em",
                  }}
                >
                  *
                </span>
              </Typography>

              <Controller
                name={`rev_target.${product.id}`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    {...field}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    onChange={(e) =>
                      field.onChange(parseInt(e.target.value, 10) || 0)
                    }
                    fullWidth
                    error={!!errors.rev_target?.[product.id]}
                    helperText={
                      errors.rev_target?.[product.id] &&
                      "Revenue Target is required"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                Add Enrolment Target{" "}
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1em",
                  }}
                >
                  *
                </span>
              </Typography>

              <Controller
                name={`enroll_target.${product.id}`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    {...field}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    onChange={(e) =>
                      field.onChange(parseInt(e.target.value, 10) || 0)
                    }
                    fullWidth
                    error={!!errors.enroll_target?.[product.id]}
                    helperText={
                      errors.enroll_target?.[product.id] &&
                      "Enrolment Target is required"
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12} textAlign="end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
