// Author : Ayesha Shaikh
// created on 27-07-2024

import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EmployeeLeaveFilter({ onApplyFilter, onResetFilter }) {
  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const [open, setOpen] = useState(false);
  const [createdBylist, setCreatedBylist] = useState([]);
  const [createdby, setCreatedby] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [status, SetStaus] = useState([]);
  const [applicationType, setApplicationType] = useState([]);
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [leaveType, setLeaveType] = useState([]);
  const [requestedFrom, setRequestedFrom] = useState("");
  const [requestedTo, setRequestedTo] = useState("");
  const [leaveFrom, setLeaveFrom] = useState("");
  const [leaveTo, setLeaveTo] = useState("");
  const [leaveStartfrom, setLeaveStartFrom] = useState("");
  const [leaveStartTo, setLeaveStartTo] = useState("");
  const [leaveEndfrom, setLeaveEndFrom] = useState("");
  const [leaveEndTo, setLeaveEndTo] = useState("");
  const [showRequestedDateField, setShowRequestedDateField] = useState(false);
  const [showLeaveFromDate, setShowLeaveFromDate] = useState(false);
  const [showLeaveEndDate, setShoweLeaveEnddate] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalluser`)
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        const filterData= sortedData.filter((i)=>i.id===parseInt(userId))

if (userRole==="admin") { 
  setCreatedBylist(sortedData);
}
else{
  setCreatedBylist(filterData)
}
      
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const [statusList, setStatusList] = useState([
    { id: 1, name: "Pending", value: "pending" },
    { id: 2, name: "Accepted", value: "accepted" },
    { id: 3, name: "Denied", value: "denied" },
    { id: 4, name: "Cancelled", value: "cancelled" },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [applicationTypeList, setApplicationTypeList] = useState([
    { id: 1, name: "First Half Day", value: "first_half" },
    { id: 1, name: "Full Day", value: "full" },
    { id: 1, name: "Second Half Day", value: "second_half" },
    { id: 1, name: "Short Leave - Morning", value: "short_morning" },
    { id: 1, name: "Short Leave - Evening", value: "short_evening" },
  ]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}empleavetype`)
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setLeaveTypeList(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const allCreatedByList = [{ first_name: "Select All" }, ...createdBylist];
  const handelcretedby = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setCreatedby(createdBylist);
    } else {
      setCreatedby(value);
    }
  };

  const allStatusList = [{ name: "Select All" }, ...statusList];
  const handleStatusChange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      SetStaus(statusList);
    } else {
      SetStaus(value);
    }
  };

  const allbranchList = [{ branch_name: "Select All" }, ...branchlist];
  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  const allApplicationhList = [{ name: "Select All" }, ...applicationTypeList];
  const handelApplicationchange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      setApplicationType(applicationTypeList);
    } else {
      setApplicationType(value);
    }
  };

  const allLeavetype = [{ leave_type: "Select All" }, ...leaveTypeList];
  console.log(allLeavetype);
  const handleLeavetypeChange = (event, value) => {
    if (value.some((option) => option.leave_type === "Select All")) {
      setLeaveType(leaveTypeList);
    } else {
      setLeaveType(value);
    }
  };

  const handleRequestedFrom = (e) => {
    setRequestedFrom(e.target.value);
  };

  const handleRequestedTo = (e) => {
    setRequestedTo(e.target.value);
  };

  const handleLeaveFrom = (e) => {
    setLeaveFrom(e.target.value);
  };

  const handleLeaveTo = (e) => {
    setLeaveTo(e.target.value);
  };
  const newCreatedby = createdby.map((i) => i.id);
  const newStatus = status.map((i) => i.value);
  const newBranch = branch.map((i) => i.branch_name);
  const newApplicationType = applicationType.map((i) => i.value);
  const newLeaveType = leaveType.map((i) => i.id);

  const handleApplyFilter = () => {
    onApplyFilter({
      newCreatedby,
      newStatus,
      newBranch,
      newApplicationType,
      newLeaveType,
      requestedFrom,
      requestedTo,
      leaveFrom,
      leaveTo,
      leaveStartfrom,
      leaveStartTo,leaveEndfrom,
      leaveEndTo

    });

    handleToggle()
  };

  const handleResetFilter = () => {
    onResetFilter();
    setCreatedby([]);
    SetStaus([]);
    setBranch([]);
    setApplicationType([]);
    setLeaveType([]);
    setRequestedFrom("");
    setRequestedTo("");
    setLeaveFrom("");
    setLeaveTo("");
  };

  const handleCheckboxChange = (event) => {
    setShowRequestedDateField(event.target.checked);
  };

  const handleLeaveFromDate = (e) => {
    setShowLeaveFromDate(e.target.checked);
  };

  const handleLeaveToDate = (e) => {
    setShoweLeaveEnddate(e.target.checked);
  };

  const handleleaveStartfrom=(e)=>{
    setLeaveStartFrom(e.target.value)
  }

  const handleleaveStartTo=(e)=>{
    setLeaveStartTo(e.target.value)
  }

  const handleLeaveEndFrom =(e)=>{
    setLeaveEndFrom(e.target.value)
  }

  const handleLeaveEndTo =(e)=>{
    setLeaveEndTo(e.target.value)
  }
  
  
  

  return (
    <>
      <MainCard>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              marginLeft: "5px",
              fontSize: "24px",
              fontWeight: 700,
            }}
          >
            Trainer Leave Request
          </Typography>

  
          <Button
                  xs={12}
                  lg={12}
               variant="contained"
                  style={{   width: "150px",
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px", }}
                  onClick={handleToggle}
                >
                  <FilterAltOutlinedIcon
                   
                    fontSize="medium"
                  />
                  <Typography>Apply Filter</Typography>
                </Button>
        </Stack>

        <Drawer anchor="right" open={open} onClose={handleToggle}>
          <Grid
            container
            spacing={2}
            m={2}
            width={500}
           
          >
            <Grid item xs={12}>
              <Typography variant="h2" textAlign="center">
                Filter Options
              </Typography>
            </Grid>
       
              <Grid item xs={12} lg={6} sm={12} md={12}>
                <Typography sx={{ fontWeight: 600 }}>Employee Name </Typography>

                <Autocomplete
                  fullWidth
                  size="small"
                  value={createdby}
                  multiple
                  onChange={handelcretedby}
                  options={allCreatedByList}
                  getOptionLabel={(option) =>
                    `${option.first_name ? option.first_name : ""} ${
                      option.last_name ? option.last_name : ""
                    }`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" },
                      }}
                    />
                  )}
                />
              </Grid>
       

            <Grid item xs={12} lg={6} sm={12} md={12}>
              <Typography sx={{ fontWeight: 600 }}>Select Status </Typography>

              <Autocomplete
                fullWidth
                size="small"
                value={status}
                multiple
                onChange={handleStatusChange}
                options={allStatusList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} sm={12} md={12}>
              <Typography sx={{ fontWeight: 600 }}> Select Branch </Typography>
              <Autocomplete
                size="small"
                value={branch}
                multiple
                onChange={handelbranchchange}
                options={allbranchList}
                getOptionLabel={(option) => option.branch_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={6} sm={12} md={12}>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                Select Application Type{" "}
              </Typography>

              <Autocomplete
                size="small"
                value={applicationType}
                multiple
                onChange={handelApplicationchange}
                options={allApplicationhList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} sm={6} md={6}>
                  <Typography sx={{ fontWeight: 600 }}>
                    {" "}
                    Select Leave Date from{" "}
                  </Typography>
                  <TextField
                    value={leaveFrom}
                    onChange={handleLeaveFrom}
                    type="date"
                    size="small"
                    fullWidth
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={6} sm={6} md={6}>
                  <Typography sx={{ fontWeight: 600 }}>
                    {" "}
                    Select Leave Date To{" "}
                  </Typography>
                  <TextField
                    value={leaveTo}
                    onChange={handleLeaveTo}
                    type="date"
                    size="small"
                    fullWidth
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

           

            <Grid item xs={12} lg={6} sm={12} md={12}>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                Select Leave Type{" "}
              </Typography>

              <Autocomplete
                size="small"
                value={leaveType}
                multiple
                onChange={handleLeavetypeChange}
                options={allLeavetype}
                getOptionLabel={(option) => option.leave_type}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showRequestedDateField}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: 600 }}>
                      Filter With Requested Date Only
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>

            {showRequestedDateField && (
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12} sm={12} md={12}>
                    <Typography
                      textAlign="center"
                      sx={{ fontWeight: 600, fontSize: "16px" }}
                    >
                      Requested Date
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6} sm={6} md={6}>
                    <Typography sx={{ fontWeight: 600 }}>
                      {" "}
                      Date Start From{" "}
                    </Typography>
                    <TextField
                      value={requestedFrom}
                      onChange={handleRequestedFrom}
                      type="date"
                      size="small"
                      fullWidth
                      InputProps={{
                        style: { borderRadius: "15px" },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} sm={6} md={6}>
                    <Typography sx={{ fontWeight: 600 }}>
                      {" "}
                      Date Start To{" "}
                    </Typography>
                    <TextField
                      value={requestedTo}
                      onChange={handleRequestedTo}
                      type="date"
                      size="small"
                      fullWidth
                      InputProps={{
                        style: { borderRadius: "15px" },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} lg={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showLeaveFromDate}
                      onChange={handleLeaveFromDate}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: 600 }}>
                      Filter With Leave from Date Only
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>

            {showLeaveFromDate && (
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12} sm={12} md={12}>
                    <Typography
                      textAlign="center"
                      sx={{ fontWeight: 600, fontSize: "16px" }}
                    >
                      Leave From Date
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6} sm={6} md={6}>
                    <Typography sx={{ fontWeight: 600 }}>
                      {" "}
                      Date Start From{" "}
                    </Typography>
                    <TextField
                      value={leaveStartfrom}
                      onChange={handleleaveStartfrom}
                      type="date"
                      size="small"
                      fullWidth
                      InputProps={{
                        style: { borderRadius: "15px" },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} sm={6} md={6}>
                    <Typography sx={{ fontWeight: 600 }}>
                      {" "}
                      Date Start To{" "}
                    </Typography>
                    <TextField
                      value={leaveStartTo}
                      onChange={handleleaveStartTo}
                      type="date"
                      size="small"
                      fullWidth
                      InputProps={{
                        style: { borderRadius: "15px" },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}


            <Grid item xs={12} lg={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showLeaveEndDate}
                      onChange={handleLeaveToDate}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: 600 }}>
                      Filter With Leave To Date Only
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>

            {showLeaveEndDate && (
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12} sm={12} md={12}>
                    <Typography
                      textAlign="center"
                      sx={{ fontWeight: 600, fontSize: "16px" }}
                    >
                      Leave To Date
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6} sm={6} md={6}>
                    <Typography sx={{ fontWeight: 600 }}>
                      {" "}
                      Date Start From{" "}
                    </Typography>
                    <TextField
                      value={leaveEndfrom}
                      onChange={handleLeaveEndFrom}
                      type="date"
                      size="small"
                      fullWidth
                      InputProps={{
                        style: { borderRadius: "15px" },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} sm={6} md={6}>
                    <Typography sx={{ fontWeight: 600 }}>
                      {" "}
                      Date Start To{" "}
                    </Typography>
                    <TextField
                      value={leaveEndTo}
                      onChange={handleLeaveEndTo}
                      type="date"
                      size="small"
                      fullWidth
                      InputProps={{
                        style: { borderRadius: "15px" },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}


            <Grid item xs={12} lg={12} sm={12} md={12} textAlign="end">
              <Button
                variant="contained"
                style={{
                  borderRadius: "25px",
                  paddingLeft: "30px",
                  paddingRight: "30px",

                  fontSize: "14px",
                }}
                onClick={handleApplyFilter}
              >
                {" "}
                Search
              </Button>
              <Button
                color="error"
                variant="contained"
                style={{
                  borderRadius: "25px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  marginLeft: "10px",

                  fontSize: "14px",
                }}
                onClick={handleResetFilter}
              >
                {" "}
                Clear
              </Button>
            </Grid>
          </Grid>
        </Drawer>
      </MainCard>
    </>
  );
}
