import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import parse from "html-react-parser";
const PATH_URL = process.env.REACT_APP_API_UPLOADS;
export default function EmailData({data}) {
    console.log("datais", data)
  return (
    <>
    <Grid container spacing={2}>
        <Grid item xs={12}>
<Typography variant='h4'>{data?.template_title}</Typography>
        </Grid>

        <Grid item xs={12}>
<Typography variant='h4'>Subject : - </Typography>
<Typography >{data?.email_subject}</Typography>
        </Grid>

        <Grid item xs={12}>
<Typography variant='h4'>Body: -</Typography>
<Typography >{ parse(String(data?.description))}</Typography>
        </Grid>

        <Grid item xs={12}>
                                <Typography variant="h4">
                                  {data?.attachments?.length > 0 &&
                                    "Email Attachments"}{" "}
                                </Typography>

                                {data?.attachments?.map((image) => (
                                  <Box
                                    sx={{
                                      position: "relative", // Ensure positioning context
                                      height: "40px",
                                      margin: "10px",
                                      border: "1px solid #D5D5D5",
                                      display: "inline-block",
                                      padding: "5px",
                                      borderRadius: "10px",
                                      background: "#E1EBEE",
                                      cursor: "pointer",
                                    }}
                                    key={image.id}
                                  >
                                    <Typography
                                      mt={1}
                                      onClick={() =>
                                        window.open(
                                          `${PATH_URL}emailTemplateAttachments/${image.file_uploaded}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      {image.file_name}
                                    </Typography>
                                  </Box>
                                ))}
                              </Grid>
    </Grid>
    </>
  )
}
