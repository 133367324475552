// Author: Sharon Mwenda
// Date : 22 Jan 2025


import {
  Alert,
    Button,
    Paper,
    Snackbar,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import LaunchIcon from "@mui/icons-material/Launch";
  import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  
  export default function NewDeskRegistrations({ data, eventId, eventDeskId }) {
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');
     const [loading, setLoading] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
      const [snackbarMessage, setSnackbarMessage] = useState("");
      const [snackbarSeverity, setSnackbarSeverity] = useState("success");
      const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };
  
    const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
      marginTop: "20px",
      "& .MuiTableCell-head": {
        backgroundColor: "#abf7b1",
        fontWeight: 600,
        padding: "8px 16px",
  
        borderRight: "0.1px solid #C3C3C3",
        borderLeft: "0.1px solid #C3C3C3",
        color: "#000",
      },
      "& .MuiTableCell-body": {
        fontSize: "14px",
        fontWeight: 600,
        borderRight: "0.1px solid #C3C3C3",
        borderLeft: "0.1px solid #C3C3C3",
        align: "center",
        padding: "8px 16px",
        // "&:nth-of-type(n+2)": {
        //   color: "blue",
        // },
      },
    }));

    const fetchCounsellingData = async (mobileCountryCode, mobile, id ) => {
        const payload = {
            mobile_country_code: mobileCountryCode,
            mobile: mobile,
            user_id: parseInt(userId),
            event_id: eventId,
            event_desk_id: eventDeskId,
            registration_id: id
        };
    
        try {
          setLoading(true)
          const response = await fetch(
            `${API_ENDPOINT}eventattendance/startandfetchcounsellingdetails`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            }
          );
    
          if (response.ok) {
            const data = await response.json();
            console.log("Response data:", data);
            
            setLoading(false)
            handlenavigate1(data.data.enquiryDetails.id, eventId, eventDeskId )
          } else if (response.status === 400) {
            showSnackbar("Invalid mobile number", "error");
            setLoading(false)
          } 
          else if (response.status === 401) {
            showSnackbar("User not Authorized", "error");
            setLoading(false)
          } else if (response.status === 405) {
            showSnackbar(
              "Failed to fetch data from Gradlynk. Ask Student to register first",
              "error"
            );
            setLoading(false)
          } else if (response.status === 404) {
            showSnackbar("Error creating event counselling data", "error");
          } else if (response.status === 403) {
            showSnackbar("No enquiry data found with this mobile number in STM and Gradlynk", "error");
            setLoading(false)
          }
           else {
            showSnackbar("Error fetching enquiry data", "error");
            setLoading(false)
          }
        } catch (error) {
          console.error("Error posting data:", error);
          showSnackbar("Error fetching enquiry data", "error");
          setLoading(false)
        }
      };
  
    const handlenavigate1 = (enquiry_id, eventid, deskid) => {
      const selectedData = {
        enquiry_id,
        eventid,
        deskid,
        event_counselling_status: "not_started",
      };
      navigate(`/student-information/${enquiry_id}`, {
        state: { data: selectedData },
      });
    };
  
    return (
      <>
        <Typography sx={{ fontSize: "20px", fontWeight: 600, color: "blue" }}>
          New Desk Registrations
        </Typography>
  
        <StyledTableContainer component={Paper} style={{ maxHeight: "400px" }}>
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Registration Date</TableCell>
                <TableCell>Registration Time</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((student) => (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {student?.name}{" "}
                  </TableCell>
                  <TableCell component="th" scope="row">
                  {student?.mobile_country_code}-{student?.mobile}
                  </TableCell>
  
                  <TableCell component="th" scope="row">
                    {student?.counselling_date}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {student?.time}
                  </TableCell>
                  <TableCell component="th" scope="row">
                  <Button
                            color="error"
                            variant="contained"
                            onClick={() =>
                                fetchCounsellingData(
                                    student?.mobile_country_code,
                                    student?.mobile,
                                    student?.id
                                )
                              }
                          >
                            Start Counselling
                          </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }} 
          style={{ marginTop: "80px" }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }