import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Stack,
  TableCell,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { PersonPinCircleOutlined } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import AddBranch from "./AddBranch";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import AddBranchAdmin from "./AddBranchAdmin";
import Person3Icon from "@mui/icons-material/Person3";
import AddButton from "../../newComponent/NewButtonComp/AddButton";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";
import NewCardcomp from "../../../components/card/NewCardcomp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function BranchesPage() {
  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [addAdminDialogOpen, setAddAdminDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(9);
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const fetchBranchData = () => {
    setIsLoading(true);
    let headers = { Authorization: `Bearer ${token}` };

    fetch(`${API_ENDPOINT}route/getbranchdetail/${userId}`, { headers })
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching branch data:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchBranchData();
  }, []);

  const handleAssignAdminClick = (branchId, branchName) => {
    console.log("Assigning admin for branch:", branchId);
    setSelectedBranchId(branchId);
    setSelectedBranchName(branchName);
    setAddAdminDialogOpen(true);
  };

  const handleViewBranchData = (branchId, branchName, branchAdmin) => {
    console.log(branchId, branchName, branchAdmin);
    navigate(`/branch-data/${branchId}`, {
      state: {
        branchName,
        branchAdmin,
      },
    });
  };

  const handleAddBranch = (data) => {
    console.log(data);
    const branchData = {
      pincode_id: parseInt(data.pincode_id),
      country_id: data.country_id,
      state_id: data.state_id,
      city_id: data.city_id,
      branch_name: data.name,
      address: `${data.building} ${data.floorHouse}`,
      nearest_landmark: data?.landmark || null,
      location_link: data.location_link,
      user_id: parseInt(userId),
    };

    console.log(branchData);
    fetch(`${API_ENDPOINT}branch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(branchData),
    })
      .then((response) => {
        if (response.status === 201) {
          showSnackbar("Branch created successfully", "success");
          window.location.reload();
        } else {
          console.log("Error:", response);
          showSnackbar("Error creating Branch", "error");
        }
      })
      .catch((error) => {
        console.error("Error adding branch:", error);
        showSnackbar("Error creating Branch", "error");
      });
    setAddDialogOpen(false);
  };

  // pagination
  const totalPages = Math.ceil(branches.length / selectedRowsPerPage);

  const startIndex = (currentPage - 1) * selectedRowsPerPage;
  const endIndex = startIndex + selectedRowsPerPage;
  
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const dataForCurrentPage = branches.slice(startIndex, endIndex);
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="secondary" />
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3} sm={5}>
          <HeadingCard label={`User Role : ${userRole}`} />
          {/* <Box mb={2}>
            <AppBar position="static" color="secondary">
              <Toolbar>
                <Stack direction="row" spacing={2}>
                  <Typography variant="h4">User Role : {userRole}</Typography>
                  <Typography variant="h4"> </Typography>
                </Stack>
              </Toolbar>
            </AppBar>
          </Box> */}
        </Grid>
        {userRole === "center_admin" && (
          <Grid item xs={12} md={8} lg={9} sm={7} textAlign="end">
            <AddButton
              label="Add Branch"
              onClick={() => setAddDialogOpen(true)}
            />
          </Grid>
        )}

        {branches.length === 0 ? (
          <Grid item xs={12} sx={{ alignItems: "center" }}>
            <Typography variant="h4">No branches. Please add Branch</Typography>
          </Grid>
        ) : (
          dataForCurrentPage.map((slot,index) => (
            <>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <NewCardcomp padding="20px"     hoverBgColor={index % 2 === 0 ? "#D5F6E1" : "#FFEDFB"}
                  
                    hoverEffect={true} onClick={() =>
                      handleViewBranchData(
                        slot.id,
                        slot.branch_name,
                        slot.admin_id?.first_name
                      )
                    }>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h3" textAlign="center">
                        {slot.branch_name}{" "}
                      </Typography>

                      <Stack direction="column" spacing={2} mt={2}>
                        <Stack direction="row" spacing={2}>
                          <Box>
                          <img
                            src="/newTestPrep/Vector.png"
                            alt="img"
                            width={10}
                            height={10}
                          />{" "}
                          </Box>
                          <Box sx={{ cursor: "pointer" }}>
                            <Tooltip title={slot.address} arrow>
                              <Typography
                                variant="h6"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: {
                                    lg: "300px",
                                    md: "200px",
                                    sm: "150px",
                                    xs: "100px",
                                  }, // You can set the width you want to limit the address
                                }}
                              >
                                {slot.address}
                              </Typography>
                            </Tooltip>
                          </Box>
                        </Stack>

                        <Stack direction="row" spacing={2}>
                          <img
                            src="/icons8-person-64.png"
                            width={12}
                            height={16}
                          />
                          <Typography variant="h6">
                            {slot.admin_id?.first_name || "No admin assigned"}
                          </Typography>
                        </Stack>
{/* 
                        <Grid
                          item
                          xs={12}
                          container
                          gap={1}
                          justifyContent="center"
                        >
                          <Link to={`/branch-data/${slot.id}`}>
                            <Button
                              size="small"
                              variant="contained"
                              color="error"
                              
                            >
                              View Branch Data
                            </Button>
                          </Link>
                          {userRole === "center_admin" &&
                            slot.admin_id === null && (
                              <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                  handleAssignAdminClick(
                                    slot.id,
                                    slot.branch_name
                                  )
                                }
                              >
                                Assign Branch Admin
                              </Button>
                            )}
                        </Grid> */}
                      </Stack>
                    </Grid>
                  </Grid>
                </NewCardcomp>
              </Grid>
               
            </>
            
          ))
        )}
        <Grid item xs={12} container justifyContent="end">
                        <Pagination
                          count={totalPages}
                          color="secondary"
                          page={currentPage}
                          onChange={handlePageChange}
                          size="small"
                          sx={{
                            "& .MuiPaginationItem-root": {
                              "&.Mui-selected": {
                                backgroundColor: "#00b13f",
                                color: "white",
                                "&:hover": {
                                  backgroundColor: "#00b13f",
                                  color: "white",
                                },
                              },
                            },
                          }}
                        />
                      </Grid>
      </Grid>
      

      <AddBranch
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onAdd={handleAddBranch}
      />

      <AddBranchAdmin
        open={addAdminDialogOpen}
        onClose={() => setAddAdminDialogOpen(false)}
        branchId={selectedBranchId}
        branchName={selectedBranchName}
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default BranchesPage;
