import { CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { fDate } from '../../../../utils/formatTime'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function GradlynkServicesTable({ enquiryId}) {
    const [loading, setLoading] = useState(false)
    const [gradlynkService, setGradlynkService] = useState([])

    useEffect(() => {
        setLoading(true)
        fetch(`${API_ENDPOINT}route2.0/fetchgradlynkservice/${enquiryId}`)
        .then(response => response.json())
        .then(data => {
          data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setGradlynkService(data);
          setLoading(false)
        })
        .catch(error => {
          console.error('Error fetching payments items:', error);
          setLoading(false)
        });
    }, [enquiryId]);

  return (
    <>
    {loading ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
    <>
      {gradlynkService.length === 0 ? (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">No gradlynk service history available</Typography>
        </Grid>
      ) : (
       
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "15px", padding: "10px" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Service
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Sub Service
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Country Of Interest
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Gradlynk Branch
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Note
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Created By
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gradlynkService.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f0f0f0" : "inherit",
                      width: "6%",
                    }}
                  >
                    <TableCell>{row?.service_id}</TableCell>
                    <TableCell>
                    {row?.sub_service_id}
                    </TableCell>
                    <TableCell>{row?.first_country_of_interest}</TableCell>
                    <TableCell>
                      {row?.nearest_branch}
                    </TableCell>
                    <TableCell>
                      {row?.notes}
                    </TableCell>
                    <TableCell>{fDate(row?.createdAt)}</TableCell>
                    <TableCell>{row?.created_by?.first_name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
       
      )}
       </>
      )}
      </>
  )
}

export default GradlynkServicesTable