import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/Maincard";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomPopup from "../../components/CustomPopup";
import RepresentativeForm from "./RepresentativeForm";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid } from "@mui/x-data-grid";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../utils/token";
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";
import HeadingCard from "../../newTestPrep/newComponent/NewCardsComp/HeadingCard";
import CustomAutocompleteComp from "../../newTestPrep/newComponent/AutocompleteComp";
import SearchButton from "../../newTestPrep/newComponent/NewButtonComp/SearchButton";
import ResetButtonComp from "../../newTestPrep/newComponent/NewButtonComp/ResetButtonComp";
import CustomNoRowsOverlay from "../../newTestPrep/newComponent/TableCustomeComp/CustomNoRowsOverlay";
import ShowAllSevices from "./ShowAllSevices";

const production = process.env.REACT_APP_ENV === "production";
export default function EventRoadmap() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const noOptionsText = "Loading...";
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const [eventName, setEventName] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [eventLoading, setEventLoading] = useState(false);
  const [qrcode, setQrcode] = useState(null);
  const [openServicePopup, setOpenservicePopup] = useState(false);
  const [selectedSevices, setSelectedService] = useState([]);
  const [counsellerData, setCounsellorData] = useState({
    firstName: "",
    lastName: "",
    evetName: "",
  });
  const [counsellerLastName, setCounsellerLastName] = useState("");
  const [showQrCode, setShowQrCode] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      setEventLoading(true);
      try {
        const response = await fetch(`${API_ENDPOINT}campaign/event/withname`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          const filteredcampaign = data.filter(
            (campaign) => campaign.campaign_type_id?.id === 1
          );
          setEventList(filteredcampaign);
          setEventLoading(false);
          console.log("data1", filteredcampaign);
        } else {
          setEventLoading(false);
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        setEventLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleEventChange = (event, value) => {
    setEventName(value);
  };
  console.log("event", eventName);

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          {" "}
          <Button
            variant="contained"
            size="small"
            classes="secondary"
            onClick={() => handleGenerateQR(params?.row)}
          >
            Generate QR
          </Button>{" "}
        </div>
      ),
    },
    {
      field: "event",
      headerName: "Event",
      width: 450,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div> {params.row?.event_id?.campaign_name}</div>,
    },
    {
      field: "user",
      headerName: "User",
      width: 170,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          {params?.row?.counsellor_id?.first_name}{" "}
          {params?.row?.counsellor_id?.last_name}{" "}
        </div>
      ),
    },
    {
      field: "deskType",
      headerName: "Desk Type",
      width: 170,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div> {params.row?.registration_desk}</div>,
    },
    {
      field: "deskNumber",
      headerName: "Desk Number",
      width: 170,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div> {params.row?.desk_number}</div>,
    },
    {
      field: "service",
      headerName: "Service",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div> {params.row?.service_id?.service_name}</div>
      ),
    },
    {
      field: "subService",
      headerName: "Sub Service",
      width: 350,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <Box
            variant="body2"
            style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
          >
            {params.row.sub_service_id.length > 5
              ? params.row.sub_service_id
                  .slice(0, 5)
                  .map((item) => item.name)
                  .join(", ")
              : params.row.sub_service_id.map((item) => item.name).join(", ")}
          </Box>
          {params.row?.sub_service_id.length > 5 && (
            <Button
              style={{ marginLeft: "2px" }}
              size="small"
              variant="contained"
              onClick={() => handleopenServicePopup(params.row?.sub_service_id)}
            >
              View{" "}
            </Button>
          )}
        </>
      ),
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 130,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          {" "}
          {params?.row?.created_by?.first_name}{" "}
          {params?.row?.created_by?.last_name}
        </div>
      ),
    },
  ];

  const handleReset = () => {
    setEventName(null);
    setEventData([]);
    setQrcode(null);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${API_ENDPOINT}route2.0/fetcheventroadmap/${eventName.id}`
      );

      // Check if the response is successful (status code 200-299)
      if (response.ok) {
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setEventData(sortedData);
        setLoading(false);
      } else if (response.status === 403) {
        showSnackbar("No Found Any Data", "error");
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginateddata = eventData.slice(startIndex, endIndex);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleGenerateQR = (rowData) => {
    setQrcode(rowData?.qr_code);
    setCounsellorData((prevState) => ({
      ...prevState,
      firstName: rowData?.counsellor_id?.first_name,
      lastName: rowData?.counsellor_id?.last_name,
      evetName: rowData?.event_id?.campaign_name,
    }));
    showSnackbar("Please check the generated QR code below");
  };

  const downloadQrcode = () => {
    const qrCodeElement = document.getElementById("qrCodeContainer");
    toPng(qrCodeElement)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.download = `${qrcode}_qr_code.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error("Error generating QR code image: ", error);
      });
  };

  const handleopenServicePopup = (services) => {
    setOpenservicePopup(true);
    setSelectedService(services);
  };

  const closeServicepup = () => {
    setOpenservicePopup(false);
    setSelectedService([]);
    // setGridKey((prevKey) => prevKey + 1)
  };
  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} sm={4} lg={2}>
          {/* <Paper
            sx={{
              height: "43px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "24px",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box height="35px">
                <img src="/event roadmap.png" height="100%" />
              </Box>
              <Typography sx={{ fontSize: "20px", fontWeight: 600 }} ml={2}>
                Event Road Map
              </Typography>
            </Stack>
          </Paper> */}
          <HeadingCard label="Event Roadmap" />
        </Grid>
        <Grid item xs={12} md={9} sm={8} lg={10} textAlign="end">
          <Button
            variant="contained"
            size="medium"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => handleOpenPopup()}
            style={{ borderRadius: "10px", background: "rgb(70, 177, 255)" }}
          >
            Add New Representative
          </Button>
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} md={8} sm={8}>
                <FormControl sx={{ width: "100%" }}>
                  <Typography variant="h5">Event</Typography>

                  <CustomAutocompleteComp
                    size="small"
                    value={eventName}
                    noOptionsText={
                      eventLoading && eventList.length === 0
                        ? noOptionsText
                        : ""
                    }
                    onChange={handleEventChange}
                    options={eventList}
                    getOptionLabel={(option) => option.campaign_name}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={6} md={4} sm={4}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    visibility: "hidden",
                  }}
                >
                  Event
                </Typography>
                <Grid container gap={1}>
                  <SearchButton label="Search" onClick={fetchData} />

                  <ResetButtonComp label="Reset" onClick={handleReset} />
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <MainCard>
            <DataGrid
              disableColumnMenu
              rows={eventData}
              columns={columns}
              slots={{
                noRowsOverlay: () => (
                  <CustomNoRowsOverlay message="No Data Available" />
                ),
              }}
              autoHeight
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              sx={{ marginTop: "20px" }}
              pageSizeOptions={[25, 50, 100]}
            />
          </MainCard>
        </Grid>
      </Grid>

      <CustomPopup
        open={openPopup}
        onClose={handleClosePopup}
        title="Add New Representative"
        maxWidth="md"
        showDivider="true"
        component={<RepresentativeForm onClose={handleClosePopup} />}
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {/* Scanner */}
      {qrcode && (
        <Box mt={2}>
          <MainCard>
            <Typography variant="h3">Generated Qr Code</Typography>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={downloadQrcode}
            >
              Download Qr Code
            </Button>
            <Box
              id="qrCodeContainer"
              style={{
                display: "flex",
                padding: "20px",
                flexDirection: "column", // Arrange items in a column
                alignItems: "center", // Center horizontally on the page
                justifyContent: "center", // Center vertically on the page
                backgroundColor: "white",
                gap: "20px",
              }}
            >
              {/* Logo */}
              <Box
                style={{
                  width: "170px", // Ensure the logo spans the full container width
                }}
              >
                <img
                  src="/Siec Logo.png"
                  alt="Logo"
                  style={{
                    width: "100%", // Adjust size as needed
                  }}
                />
              </Box>

              <Typography variant="h3"> {counsellerData?.evetName}</Typography>

              {/* QR Code */}
              <Box className="scanner-border">
                <Box
                  margin={1}
                  style={{ backGround: "white", border: "10px solid white" }}
                >
                  <QRCode
                    size={300}
                    value={
                      production
                        ? `https://student.siectestmasters.in/event-registration?code=${qrcode}`
                        : `https://dev-instance.d1ufz94xk9odzx.amplifyapp.com/event-registration?code=${qrcode}`
                    }
                  />
                </Box>
              </Box>

              <Box>
                <Typography variant="h4">
                  Personalized Counseling Available
                </Typography>
                <Typography mt={1} variant="h4" textAlign="center">
                  Counselor:{" "}
                  <span style={{ fontSize: "16px", fontWeight: 500 }}>
                    {" "}
                    {counsellerData?.firstName} {counsellerData?.lastName}
                  </span>
                </Typography>
              </Box>
            </Box>
          </MainCard>
        </Box>
      )}
      <CustomPopup
        open={openServicePopup}
        onClose={closeServicepup}
        component={
          <ShowAllSevices
            onClose={closeServicepup}
            selectedSevices={selectedSevices}
          />
        }
        title="services"
        showDivider="true"
        maxWidth="xs"
      />
    </>
  );
}
