import { Grid, Typography } from "@mui/material";
import React from "react";
import { useDateUtils } from "../../../utils/formatTime";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../newTestPrep/newComponent/TableCustomeComp/CustomNoRowsOverlay";

export default function EnrollDetails({ selectedFilter, SelectedCardData, fromDate, toDate }) {
  const { currentMonthName, lastMonthName, weekRange ,currentYear} = useDateUtils();
  let currentDate = new Date().toISOString().split("T")[0];

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.enquiry_id?.first_name || ""} ${
          params?.row?.enquiry_id?.last_name || ""
        }`,
      renderCell: (params) => (
        <div
          style={{
            color: "#0096FF",

            cursor: "pointer",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          onClick={() =>
            window.open(
              `/enquirydetails/${params?.row.enquiry_id.id}`,
              "_blank"
            )
          }
        >
          {" "}
          {`${
            params?.row?.enquiry_id?.first_name
              ? params?.row?.enquiry_id?.first_name
              : ""
          } ${
            params?.row?.enquiry_id?.last_name
              ? params?.row?.enquiry_id?.last_name
              : ""
          }`}{" "}
        </div>
      ),
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row?.enquiry_id?.mobile || "",
      renderCell: (params) => <div>{params?.row.enquiry_id?.mobile}</div>,
    },
    {
      field: "branch_id",
      headerName: "Branch",
      flex: 1,
      headerClassName: "header-cell",

      renderCell: (params) => <div>{params?.row?.branch_id?.branch_name}</div>,
    },
    {
      field: "class_id",
      headerName: "Class",
      flex: 1,
      headerClassName: "header-cell",

      renderCell: (params) => <div>{params?.row?.class_id?.name}</div>,
    },
    {
      field: "package",
      headerName: "Package",
      flex: 1,
      headerClassName: "header-cell",

      renderCell: (params) => <div>{params?.row?.package_id?.package_name}</div>,
    },

    {
      field: "enrollment_date",
      headerName: "Enrolled Date",
      flex: 1,
      headerClassName: "header-cell",
    },
    {
      field: "created_by",
      headerName: "Enrolled By",
      flex: 1,
      headerClassName: "header-cell",

      renderCell: (params) => (
        <div>
          {params?.row?.created_by?.first_name}{" "}
          {params?.row?.created_by?.last_name}
        </div>
      ),
    },
  ];
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {selectedFilter === "today" && (
          <Typography variant="h4" textAlign="center">
            Today ({currentDate})
          </Typography>
        )}
        {selectedFilter === "currentweek" && (
          <Typography variant="h4" textAlign="center">
            This Week ({weekRange})
          </Typography>
        )}

        {selectedFilter === "currentmonth" && (
          <Typography variant="h4" textAlign="center">
            {currentMonthName} {currentYear}
          </Typography>
        )}
        
        {selectedFilter === "custom" && (
          <Typography variant="h4" textAlign="center">
           {fromDate} - {toDate}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <DataGrid
          rows={SelectedCardData}
          columns={columns}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
        />
      </Grid>
    </Grid>
  );
}
