import { Box, Button, Chip, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import SubCard from '../../../../components/ui-component/card/SubCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fDate } from '../../../../../utils/formatTime';
import EvaluationPDF from '../../../demos/EvaluationPDF';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function StudentClassDetails({studentDetail, studentId, enquiryId}) {
    const [classDetailsExpanded, setClassDetailsExpanded] = useState(true);
    const [classDetails, setClassDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [demoHistory, setDemoHistory] = useState([])
    const [openEvaluationPdfDialog, setOpenEvaluationPdfDialog] = useState(false);
    const [selectedDemo, setSelectedDemo] = useState(null);
    const [evaluationData, setEvaluationData] = useState([])

        const handleViewEvaluationClick = () => {
        setOpenEvaluationPdfDialog(true);
        };

    const handleClassDetailsToggle = () => {
        setClassDetailsExpanded(!classDetailsExpanded);
      };

      useEffect(() => {
        setClassDetails(studentDetail?.class_details_id);
      }, [studentDetail])

      const handleViewClick = (demo) => {
       
        setOpenEvaluationPdfDialog(true);
        setSelectedDemo(demo);
       // setDialogAction(action);

        if (demo.evaluation_id) {
          fetch(`${API_ENDPOINT}studentstatus/${demo.evaluation_id}`)
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                console.error('Failed to fetch evaluation data');
                return [];
              }
            })
            .then((evaluationData) => {
            setEvaluationData(evaluationData); 
          })
            .catch((error) => {
              console.error('Error fetching evaluation data:', error);
              setEvaluationData([]);
            });
        } else {
          // If evaluation_id is null, set an empty array
          setEvaluationData([]);
        }
      
    }

      useEffect(() => {
        setIsLoading(true);
        fetch(`${API_ENDPOINT}route/demohistory/${enquiryId}`)
        .then(response => response.json())
        .then(data => {
            setDemoHistory(data);
            setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching demo items:', error);
          setIsLoading(false);
        });
    }, [studentId]);


  return (
    <Grid container spacing={4}>
    <Grid item xs={12}>
    <SubCard sx={{ overflowX: 'auto' }} title="Demo History" content={false}>
    {isLoading ? (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <CircularProgress />
        </Box>
    ) : demoHistory.length === 0 ? (
        <Typography variant="body1" color="textSecondary">
            No demo history available.
        </Typography>
    ) : (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ pl: 3 }}>Trainer Name</TableCell>
                        <TableCell>Exam Type</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Slot</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell sx={{ pr: 3 }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {demoHistory.map((row, index) => (
                        <TableRow hover key={index}>
                            <TableCell sx={{ pl: 3 }}>{row.trainer_id?.first_name}</TableCell>
                            <TableCell sx={{ pl: 3 }}>{row.test_type_id?.name}</TableCell>
                            <TableCell>{fDate(row.available_demo_date)}</TableCell>
                            <TableCell>{row.slot}</TableCell>
                            <TableCell>
                                <Chip
                                    chipcolor={row.badgeType}
                                    label={row.status}
                                    size="small"
                                />
                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    // onClick={() => handleEnrollClick(row)}
                                    onClick={() => handleViewClick(row)}
                                    disabled={row.status !== 'completed'}
                                >
                                    View Evaluation
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )}
    </SubCard>
</Grid>

{openEvaluationPdfDialog && selectedDemo && (
    <Dialog
      fullWidth
      maxWidth='md'
      open={openEvaluationPdfDialog}
      onClose={() => setOpenEvaluationPdfDialog(false)}
    >
      <DialogTitle variant="h4">Evaluation PDF</DialogTitle>
      <DialogContent>
        {/* Render the EvaluationPDF component */}
        <EvaluationPDF 
            demoId={selectedDemo.id} 
            studentName={selectedDemo.student_name} 
            trainerName={selectedDemo?.trainer_id.first_name}
            evaluationData={evaluationData} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenEvaluationPdfDialog(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )}

<Grid item xs={12}>
    <SubCard title="Class and Package Details"
        secondary= {
            <IconButton onClick={handleClassDetailsToggle}>
                <ExpandMoreIcon />
            </IconButton>
        }>
             <Collapse in={classDetailsExpanded}>
             <Box sx={{ m: 1.5 }}> 
       
        <>
    {classDetails?.length === 0 ? (
             <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
             <Typography variant='h5'>No class history available</Typography>
             </Grid>
        ) : ( 
   <TableContainer component={Paper} sx={{borderRadius:'15px' ,padding:'10px' }}>
    <Table>
      <TableHead >
        <TableRow >
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Class Details</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 ,}}>Branch</TableCell>
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Package Details</TableCell>
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Enrollment Date</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600,}}>End Date</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Active Sessions</TableCell>
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Remaining Sessions</TableCell>
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Student Status</TableCell>
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody >
      <>
      {classDetails?.map((row, index) => (
          <TableRow key={index}  style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'inherit' ,width: '6%' }}>
            <TableCell >{row?.class_id?.name}</TableCell>
            <TableCell>{(row?.branch_id?.branch_name)}</TableCell>
            <TableCell >{row?.package_id?.package_name}<br />{row?.package_days} session(s)</TableCell>
            <TableCell>{fDate(row?.enrollment_date)}</TableCell>
            <TableCell >{fDate(row?.end_date)}</TableCell>
            <TableCell >{row?.active_total_sessions}</TableCell>
            <TableCell >{row?.remaining_sessions}</TableCell>
            <TableCell >
            <Chip
                label={row?.status_of_student}
                color={row?.status_of_student === 'active' ? 'secondary' : 'error'}
              />
            </TableCell>
            
            <TableCell>
                    
                   
                  </TableCell>
          </TableRow>
        ))}
        </>
      </TableBody>
    </Table>
  </TableContainer>
        )}
        </>
        
        </Box>
        </Collapse>
    </SubCard>
</Grid>
</Grid>



  )
}

export default StudentClassDetails