// Author: Ayesha Shaikh
// Date : 14 aug 2024

import { Grid, Typography } from '@mui/material'
import React, { useState } from 'react'

export default function ViewExamDetails({data}) {
   
  return (
<>
<Grid container mt={2}>
                      <Grid item xs={12} lg={6} md={6} sm={6}>
                      
                        <Typography mt={1}>Exam Status : <strong>{data?.exam_status_id?.exam_status_name}</strong></Typography>
                        <Typography mt={1}>Date Of Exam : <strong>{data?.tentative_exam_date}</strong></Typography>
                        <Typography mt={1}>Target Score :  <strong>{data?.target_score}</strong></Typography>
                      </Grid>
                    </Grid>
</>
  )
}
