import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Chip,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Email, Phone, WhatsApp } from "@mui/icons-material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const EnquiryDetailsCard = ({ enquiryData }) => {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const {
    id,
    first_name,
    last_name,
    status,
    email,
    mobile,
    mobile_country_code,
    whatsapp_country_code,
    whatsapp_number,
    test_type,
    course,
    advisor_name,
    advisor_id,
    assign_to,
    test_type_id,
    prospective_level_id,
    sub_service_id,
    sub_service,
  } = enquiryData;


  const [recycleRequest, setRecycleRequest] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClickToCall = () => {
    const data = {
      user_id: parseInt(userId),
      enquiry_id: id,
    };

    

    // Access the authorization token from environment variables
    const authToken = process.env.REACT_APP_CALL_TOKEN;

    // Send the POST request to the server
    fetch(`${API_ENDPOINT}clicktocall`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
         
          showSnackbar("Call Connected", "success");
          alert("Call Connected");
        } else {
         
          showSnackbar("Call Not Connected", "error");
          alert("Call Not Connected");
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        showSnackbar("Call Not Connected", "error");
        alert("Error Connecting Call");
      });
  };

  const handleCancelRecycleRequest = () => {
    const data = {
      user_id: parseInt(userId),
      //enquiry_id: id,
      status: "cancelled",
      is_active: false,
    };

  

    // Send the POST request to the server
    fetch(`${API_ENDPOINT}recyclerequest/updatebyEnquiryId/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
         
          showSnackbar("Recycle Request cancelled successfully", "success");
          alert("Recycle Request cancelled successfully");
          window.location.reload();
        } else {
         
          showSnackbar("Error cancelling recycle request", "error");
          alert("Error cancelling recycle request");
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        showSnackbar(
          "Network Error cancelling recycle request. Please try again",
          "error"
        );
        alert("Network Error cancelling recycle request. Please try again");
      });
  };

  const handleAcceptRecycleRequest = () => {
    const data = {
      user_id: parseInt(userId),
      //enquiry_id: id,
      status: "accepted",
      is_active: false,
    };

   

    // Send the POST request to the server
    fetch(`${API_ENDPOINT}recyclerequest/updatebyEnquiryId/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
     
          showSnackbar("Recycle Request accepted successfully", "success");
          alert("Recycle Request accepted successfully");
          window.location.reload();
        } else {
        
          showSnackbar("Error accepting recycle request", "error");
          alert("Error accepting recycle request");
          //window.location.reload()
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        showSnackbar(
          "Network Error accepting recycle request. Please try again",
          "error"
        );
        alert("Network Error accepting recycle request. Please try again");
        //window.location.reload()
      });
  };

  useEffect(() => {
    fetch(`${API_ENDPOINT}recyclerequest/fetchbyEnquiryId/${id}`)
      .then((response) => response.json())
      .then((data) => {
        // data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      
        setRecycleRequest(data);
      })
      .catch((error) => {
        console.error("Error fetching requests items:", error);
      });
  }, [id]);


  return (
    <>
      <Card style={{ padding: "10px" }}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h4">
              {first_name} {last_name}
            </Typography>

            {prospective_level_id && (
              <Chip
                variant="contained"
                size="small"
                color="secondary"
                label={prospective_level_id?.prospective_level}
              />
            )}
            {recycleRequest && (
              <>
                <Chip
                  variant="contained"
                  size="small"
                  color="error"
                  label="Recycle Request Pending"
                />
                {(userRole === "data_entry" || userRole === "center_admin") && (
                  <>
                    <Tooltip title="Cancel Recycle Request" arrow>
                      <IconButton
                        color="error"
                        onClick={handleCancelRecycleRequest}
                      >
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Accept Recycle Request" arrow>
                      <IconButton
                        color="secondary"
                        onClick={handleAcceptRecycleRequest}
                      >
                        <CheckIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </>
            )}
          </Box>

          <Box>
            <IconButton onClick={handleClickToCall} color="secondary">
              <CallIcon />
            </IconButton>
          </Box>
        </Box>
        <Box mt={2}>
          <Grid container spacing={2}>
            {email && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body1">
                  <strong>Email:</strong> {email}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">
                {/* <strong>Mobile:</strong> {mobile_country_code}{(userRole==="advisor" || userRole==="trainer") ?mobile?.replace(/.(?=.{4,}$)/g, '*') :  mobile} */}
                <strong>
                  Mobile:{mobile_country_code}
                  {mobile}
                </strong>
              </Typography>
            </Grid>
{enquiryData?.secondary_mobile && ( 
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">
                {/* <strong>Mobile:</strong> {mobile_country_code}{(userRole==="advisor" || userRole==="trainer") ?mobile?.replace(/.(?=.{4,}$)/g, '*') :  mobile} */}
                <strong>
                  Secondary Mobile:{enquiryData?.secondary_mobile_country_code}
                  {enquiryData?.secondary_mobile}
                </strong>
              </Typography>
            </Grid>
            )}

            {whatsapp_number && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body1">
                  {/* <strong>WhatsApp:</strong> {whatsapp_country_code}{(userRole==="advisor" || userRole==="trainer") ?whatsapp_number?.replace(/.(?=.{4,}$)/g, '*') :  whatsapp_number} */}
                  <strong>
                    Whatsapp :{mobile_country_code}
                    {whatsapp_number}
                  </strong>
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">
                <strong>Sub Service:</strong>{" "}
                {sub_service || sub_service_id?.name}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">
                <strong>Advisor:</strong> {assign_to?.first_name}
              </Typography>
            </Grid>
         
            {enquiryData?.enq_parent_id && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body1">
                  <strong>Class:</strong> {enquiryData?.enq_parent_id?.grade}
                </Typography>
              </Grid>
            )}

            {enquiryData?.enq_parent_id && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body1">
                  <strong>Parent Name:</strong>{" "}
                  {enquiryData?.enq_parent_id?.parent_name} 
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Card>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default EnquiryDetailsCard;
