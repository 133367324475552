import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import MainCard from "../../../components/Maincard";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import { useGetTokenOrLogout } from "../../../utils/token";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";
import CustomNoRowsOverlay from "../../newComponent/TableCustomeComp/CustomNoRowsOverlay";
import { DataGrid } from "@mui/x-data-grid";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function RequestCallBack() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [originalData, setOriginalData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    // Fetch expenses data
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    fetch(`${API_ENDPOINT}route2.0/callbackreq/${userId}`, { headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // Sort the filtered data by payment_date
        const sortedData = data.sort(
          (a, b) => new Date(b.request_date) - new Date(a.request_date)
        );
        setRequests(sortedData);
        console.log(sortedData);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message.includes("401")) {
          navigate("/page401");
        } else if (error.message.includes("403")) {
          navigate("/page403");
        } else if (error.message.includes("404")) {
          navigate("/page404");
        } else if (error.message.includes("440")) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
        setIsLoading(false);
      });
  }, [userId]);

  const columns = [
    {
      field: "enquiry_name",
      headerName: "Enquiry Name",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row.first_name} {params?.row.last_name}
        </div>
      ),
    },
    {
      field: "mobile",
      headerName: "mobile",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row.mobile_country_code} {params?.row.mobile}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.email} </div>,
    },
    {
      field: "nearest_branch",
      headerName: "Nearest Branch",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params?.row?.nearest_branch_id?.branch_name} </div>
      ),
    },
    {
      field: "request_date",
      headerName: "Request Date	",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params?.row?.request_date)}</div>,
    },
    {
      field: "assign_to",
      headerName: "Assign To",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row?.request_owner?.first_name}{" "}
          {params?.row?.request_owner?.last_name}
        </div>
      ),
    },
    {
      field: "note",
      headerName: "Note",
      width: 250,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.note} </div>,
    },
  ];
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} lg={4} sm={6} md={5}>
          <HeadingCard label="Request CallBacks From Student Panel" />
        </Grid>

        <Grid item xs={12}>
          <MainCard>
            <DataGrid
              rows={requests}
              columns={columns}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              autoHeight
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
            />
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default RequestCallBack;
