import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";

// project imports
import Avatar from "../../../components/ui-component/avatar/Avatar";
import { gridSpacing } from "../../../store/constant";

// assets

import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import { Controller, useForm } from "react-hook-form";
import { City, Country, State } from "country-state-city";
import { useEffect, useState } from "react";
import { Watch } from "@mui/icons-material";
import axios from "axios";
import CustomSnackbar from "../../../components/ui-component/snackbar/CustomSnackbar";
import CustomTextFieldComp from "../../../newComponent/customTextFieldComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const StudentInfo = ({ enquiryData, onSubmitSuccess, onNext }) => {
  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("userRole");
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    setError,
    handleSubmit,
  } = useForm();

  const [studentInfo, setStudentInfo] = useState(enquiryData || {});

  const [isWhatsappChecked, setIsWhatsappChecked] = useState(false);
  const [branches, setBranches] = useState([]);
  const [nearestBranch, setNearestBranch] = useState(
    studentInfo?.nearest_branch_id?.id || null
  );
  const [pincodeError, setPincodeError] = useState("");
  const [pinCodeData, setPinCodeData] = useState({});

  const [selectedCountry, setSelectedCountry] = useState(
    enquiryData?.country_id?.id || enquiryData?.country_id || 101
  );
  const [selectedState, setSelectedState] = useState(
    enquiryData?.state_id?.id || enquiryData?.state_id || null
  );
  const [selectedCity, setSelectedCity] = useState(
    enquiryData?.city_id?.id || enquiryData?.city_id || null
  );

  const [services, setServices] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [selectedService, setSelectedService] = useState(
    enquiryData?.service_id?.id || null
  );
  const [selectedServiceName, setSelectedServiceName] = useState("");
  const [users, setUsers] = useState([]);

  const [countries, setCountries] = useState([]);
  const [countriesList, setcountryList] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [leadSources, setLeadSources] = useState([]);
  const [subLeadSources, setSubLeadSources] = useState([]);
  const [selectedSourceType, setSelectedSourceType] = useState(
    enquiryData?.lead_source_type_id?.id || null
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //const selectedCountry = watch('country', 'IN')

  //const selectedState = watch('state')

  const States = State.getStatesOfCountry(selectedCountry);
  const Cities = City.getCitiesOfState(selectedCountry, selectedState);

  const handleWhatsappCheck = () => {
    setIsWhatsappChecked((prevIsChecked) => !prevIsChecked);
  };

  const handlePinCodeChange = (event) => {
    const newPinCode = event.target.value;
    if (newPinCode.length === 6) {
      setValue("pincode_id", newPinCode);
      fetchPinCodeData(newPinCode);
    } else {
      setPincodeError("Invalid pin code 3");
      //setNearestBranch(null)
      //setError('pincode', { type: 'manual', message: 'Invalid pin code 4' });
      /*reset({
                  state: '',
                  city: '',
                  nearest_branch: null
              })*/
      setSelectedState(null);
      setSelectedCity(null);
      setValue("pincode_id", null);
      setValue("state_id", "");
      setValue("city_id", "");
      // setValue('nearest_branch', null);
    }
  };

  const fetchPinCodeData = async (pincode) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}oldzipcode/pincode/${pincode}`
      );
      //setPinCodeData(response.data);
      if (response.status === 200) {
        const postOfficeData = response.data; // Get the first PostOffice object
        setPinCodeData(postOfficeData);
 
        if (postOfficeData) {
          // Update the State and City fields with the data from the API
          setPincodeError("");
          //setNearestBranch(postOfficeData.branch_detail[0].id)
          /*reset({
                  state: postOfficeData.state,
                  city: postOfficeData.city,
                  nearest_branch: postOfficeData.branch_detail[0].id
              })*/
          // setValue('pincode', pincode);
          setSelectedState(postOfficeData?.state_id);
          setSelectedCity(postOfficeData?.city_id);
          setValue("state_id", postOfficeData?.state_id);
          setValue("city_id", postOfficeData?.city_id);
          // setValue('nearest_branch', postOfficeData?.branch_detail[0]?.id);
        }
      } else {
      
        //alert('error')
        setPincodeError("Invalid pin code 1");
        //setNearestBranch(null)
        setError("pincode", { type: "manual", message: "Invalid pin code 2" });
        /* reset({
                      state: '',
                      city: '',
                      nearest_branch: null
                  })*/
        setValue("pincode_id", null);
        setValue("state_id", "");
        setValue("city_id", "");
        setSelectedState(null);
        setSelectedCity(null);
        //setValue('nearest_branch', null);
      }
    } catch (error) {
      console.error("Error fetching pin code data:", error);
      showSnackbar("Error fetching pin code data", "error");
      setPinCodeData(null);
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setValue("country_id", event.target.value);
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setValue("state_id", event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setValue("city_id", event.target.value);
  };

  const handleSourceTypeChange = (event) => {
    setSelectedSourceType(event.target.value);
    setValue("lead_source_type_id", event.target.value);
    setStudentInfo({ ...studentInfo, lead_source_type_id: event.target.value });
  };

  const handleServiceChange = (event) => {
    const filteredServiceName = services.find(
      (i) => i.id === event.target.value
    );
    setSelectedServiceName(filteredServiceName?.service_name);
    setSelectedService(event.target.value);
    setValue("service_id", event.target.value);
    setStudentInfo({ ...studentInfo, service_id: event.target.value });
  };

  useEffect(() => {
    fetch(`${API_ENDPOINT}leadsource`)
      .then((response) => response.json())
      .then((data) => {
        const filteredLeadSources = data.filter((sub) => sub.is_active === 1);
       

        setLeadSources(filteredLeadSources);
      })
      .catch((error) => {
        console.error("Error fetching lead sources:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedSourceType) {
      fetch(`${API_ENDPOINT}subleadsource`)
        .then((response) => response.json())
        .then((data) => {
          const filteredLeadSources = data.filter(
            (sub) => sub.lead_source_type_id === selectedSourceType
          );
          setSubLeadSources(filteredLeadSources);
        })
        .catch((error) => {
          console.error("Error fetching sub lead sources:", error);
        });
    }
  }, [selectedSourceType]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}service`)
      .then((response) => response.json())
      .then((data) => {
        setServices(data);
  
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_ENDPOINT}subservice`)
      .then((response) => response.json())
      .then((data) => {
        const subservicesData = data.filter(
          (i) => i.service_id === selectedService
        );
        if (enquiryData === null) {
          setSubServices(subservicesData);
        } else {
          const filteredSubServices = subservicesData.filter(
            (sub) => sub.id === studentInfo.sub_service_id?.id
          );

          setSubServices(filteredSubServices);
        }
      })
      .catch((error) => {
        console.error("Error fetching sub services:", error);
      });
  }, [selectedService]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalluser`)
      .then((response) => response.json())
      .then((data) => {
        let filteredData = data.filter(
          (user) =>
            user.user_role === "advisor" ||
            user.user_role === "admin" ||
            user.user_role === "trainer"
        );

        if (role === "advisor" || role === "trainer") {
          // If the role is advisor, filter the list to show only the logged-in advisor
          filteredData = data.filter((user) => user.id === parseInt(userId));
        }

        const sortedData = filteredData.sort((a, b) => {
          const nameA = (a.first_name + " " + a.last_name).toUpperCase();
          const nameB = (b.first_name + " " + b.last_name).toUpperCase();
          return nameA.localeCompare(nameB);
        });

        const sortedData2 = data.sort((a, b) => {
          const nameA = (a.first_name + " " + a.last_name).toUpperCase();
          const nameB = (b.first_name + " " + b.last_name).toUpperCase();
          return nameA.localeCompare(nameB);
        });
        setUsers(sortedData);
        //setTaskOwners(sortedData2);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const onSubmit = async (data) => {
    if (role !== "advisor") {
      showSnackbar(
        "Please login as an advisor role,to enroll this student",
        "error"
      );
      return;
    }
    if (selectedService !== 1) {
      showSnackbar(
        `Can't enroll the student with  ${selectedServiceName}  service`,
        "error"
      );
      return;
    }
    data.user_id = parseInt(userId);
    data.assign_to = parseInt(userId);

    try {
      // Handle the case when 'isWhatsappChecked' is true
      if (isWhatsappChecked) {
        data.isWhatsapp = true;
        data.whatsapp_number = null;
        data.whatsapp_country_code = null;
      } else {
        data.isWhatsapp = false;
      }

      const response = await fetch(
        `${API_ENDPOINT}enqpersonaldetail/addpersornaldetail/directenroll`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok || response.status === 409) {
        const responseData = await response.json();

        // Handle the response data, if needed


        const newId = responseData?.id || studentInfo?.id;
        const testTypeId = responseData?.test_type_id;
        const deliveryMode = responseData?.preferred_delivery_mode;
        const branchId = responseData?.nearest_branch_id;
        const subServiceId =
          responseData?.sub_service_id || studentInfo?.sub_service_id?.id;
        // Store the ID or pass it to the parent component
        if (onSubmitSuccess) {
          onSubmitSuccess(newId);
        }

        onNext(
          newId,
          testTypeId,
          deliveryMode,
          branchId,
          responseData,
          studentInfo,
          subServiceId
        );
      } else {

        showSnackbar("Error submitting data:", "error");
      }
    } catch (error) {
      // Handle errors, show a message, etc.
      console.error("Error submitting data:", error);
      showSnackbar("Error submitting data:", "error");
    }
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}branch`)
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
      
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}country`)
      .then((response) => response.json())
      .then((data) => {
        setcountryList(data);
        const sortedData = [...data].sort(
          (a, b) => a.phone_code - b.phone_code
        );
        const uniqueArray = sortedData.filter(
          (obj, index, self) =>
            index === self.findIndex((t) => t.phone_code === obj.phone_code)
        );
        setCountries(uniqueArray);

       
      })
      .catch((error) => {
        console.error("Error fetching country:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetch(`${API_ENDPOINT}oldstate`)
        .then((response) => response.json())
        .then((data) => {
          const filteredState = data.filter(
            (state) => state.country_id === selectedCountry
          );
          setStates(filteredState);
       
        })
        .catch((error) => {
          console.error("Error fetching state:", error);
        });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry && selectedState) {
      fetch(`${API_ENDPOINT}oldcity`)
        .then((response) => response.json())
        .then((data) => {
          const filteredCity = data.filter(
            (city) =>
              city.country === selectedCountry && city.state === selectedState
          );
          setCities(filteredCity);
       
        })
        .catch((error) => {
          console.error("Error fetching city:", error);
        });
    }
  }, [selectedCountry, selectedState]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            {/* <Avatar alt="User 1" sx={{ height: 80, width: 80 }} /> */}
          </Grid>
          <Grid item sm zeroMinWidth>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {/* <Stack direction="row" spacing={2} alignItems="center">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    multiple
                    type="file"
                  />
                </Stack> */}
              </Grid>
              {/* <Grid item xs={12}>
                <Typography variant="caption">
                  <ErrorTwoToneIcon
                    sx={{
                      height: 16,
                      width: 16,
                      mr: 1,
                      verticalAlign: "text-bottom",
                    }}
                  />
                  Image size Limit should be 125kb Max.
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/*<Grid item xs={6} md={4} lg={3}>
        <InputLabel required style={{ color: 'black' }}>First Name</InputLabel>
                <Controller
                  name='first_name'
                  control={control}
                  //rules={{ required: true }}
                 rules={{ required: 'First name is required' }} 
                  render={({ field }) => {
                    
                    return (
                      <CustomTextFieldComp
                        {...field}
                        fullWidth
                        value={studentInfo?.first_name || ''}
                        onChange={(e) => setStudentInfo({ ...studentInfo, first_name: e.target.value })}
                        InputProps={{
                          style: { borderRadius: '12px' },
                        }}
                        error={!!errors.first_name}
                      />
                    );
                  }}
                
                />
                {errors.first_name && (
                    <span style={{ color: 'red' }}>
                    {errors.first_name.message} 
                    </span>
                )}
                </Grid>*/}

      <Grid item xs={6} md={4} lg={3}>
        <Typography variant="h6">
          First Name
          <span
            style={{
              color: "red",
              position: "absolute",
              marginLeft: "4px",
              fontSize: "1em",
            }}
          >
            *
          </span>
        </Typography>
        <Controller
          name="first_name"
          control={control}
          defaultValue={studentInfo?.first_name || null}
          rules={{ required: true }}
          render={({ field }) => (
            <CustomTextFieldComp
              size="normal"
              {...field}
              fullWidth
              //value={studentInfo.first_name || ''}
              // onChange={(e) => setStudentInfo({ ...studentInfo, first_name: e.target.value })}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              error={Boolean(errors.first_name)}
              helperText={errors.first_name && "First name is required"}
            />
          )}
        />
      </Grid>

      <Grid item xs={6} md={4} lg={3}>
        <Typography variant="h6">Last Name</Typography>
        <Controller
          name="last_name"
          control={control}
          //rules={{ required: true }}
          defaultValue={studentInfo?.last_name || null}
          //rules={{ required: 'Last name is required' }}
          render={({ field }) => (
            <CustomTextFieldComp
              {...field}
              size="normal"
              fullWidth
              //value={studentInfo.last_name || ''}
              //onChange={(e) => setStudentInfo({ ...studentInfo, last_name: e.target.value })}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              error={!!errors.last_name}
              //helperText={errors.last_name && 'Last name is required'}
            />
          )}
        />
        {errors.last_name && (
          <span style={{ color: "red" }}>{errors.last_name.message}</span>
        )}
      </Grid>
      <Grid item xs={6} md={4} lg={3}>
        <Typography variant="h6">Gender</Typography>
        <Controller
          name="gender"
          control={control}
          defaultValue={studentInfo?.gender || null}
          render={({ field }) => (
            <CustomTextFieldComp
              size="normal"
              select
              {...field}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              fullWidth
              //value={studentInfo.gender || ''}
              // onChange={(e) => setStudentInfo({ ...studentInfo, gender: e.target.value })}
              error={!!errors.gender}
              //helperText={errors.gender && 'Gender is required'}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </CustomTextFieldComp>
          )}
        />
      </Grid>

      <Grid item xs={6} md={4} lg={3}>
        <Typography variant="h6">Date of Birth</Typography>
        <Controller
          name="dob"
          control={control}
          defaultValue={studentInfo?.dob || null}
          render={({ field }) => (
            <CustomTextFieldComp
              {...field}
              fullWidth
              size="normal"
              type="date"
              InputProps={{
                style: { borderRadius: "10px" },
                inputProps: { max: "2005-12-31" },
              }}
              //value={studentInfo.dob || ''}
              //onChange={(e) => setStudentInfo({ ...studentInfo, dob: e.target.value })}
              error={!!errors.dob}
              //helperText={errors.dob && 'Date of Birth is required'}
            />
          )}
        />
        {errors.dob && (
          <span
            style={{ color: "red", position: "absolute", top: 0, right: -8 }}
          >
            *
          </span>
        )}
      </Grid>

      <Grid item xs={6} md={4} lg={4} container>
        <Grid item xs={4} md={4} lg={3.5}>
          <Typography variant="h6">
            ISD code
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="mobile_country_code"
            control={control}
            //defaultValue={91}
            defaultValue={studentInfo?.mobile_country_code || 91}
            rules={{ required: "Country code is required" }}
            render={({ field }) => (
              <CustomTextFieldComp
                select
                size="normal"
                {...field}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                fullWidth
                defaultValue={91}
                error={Boolean(errors.mobile_country_code)}
                //helperText={errors.country && 'Country is required'}
              >
                {countries.map((country) => (
                  <MenuItem key={country.phone_code} value={country.phone_code}>
                    {country.phone_code}
                  </MenuItem>
                ))}
              </CustomTextFieldComp>
            )}
          />
          {errors.mobile_country_code && (
            <span style={{ color: "red" }}>
              {errors.mobile_country_code.message}
            </span>
          )}
        </Grid>

        <Grid item xs={8} md={8} lg={8.5}>
          <Typography variant="h6">
            Mobile Number
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="mobile"
            control={control}
            //defaultValue='+91'
            defaultValue={studentInfo?.mobile || null}
            rules={{
              required: "Contact number is required",
              /* pattern: {
                                    value: /^[1-9][0-9]{9}$/,
                                    message: "Invalid contact number format."
                                }*/
            }}
            render={({ field }) => (
              <CustomTextFieldComp
                size="normal"
                {...field}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                error={!!errors.mobile}
                //helperText={errors.mobile?.message}
                fullWidth
                //value={studentInfo.mobile || ''}
                //onChange={(e) => setStudentInfo({ ...studentInfo, mobile: e.target.value })}
              />
            )}
          ></Controller>
          {errors.mobile && (
            <span style={{ color: "red" }}>{errors.mobile.message}</span>
          )}
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={isWhatsappChecked}
              onChange={handleWhatsappCheck}
              name="isWhatsApp"
            />

            <Typography variant="h7">Contact same as whatsapp</Typography>
          </Box>
        </Grid>
      </Grid>

      {!isWhatsappChecked && (
        <Grid item xs={6} md={4} lg={4} container>
          <Grid item xs={4} md={4} lg={3.5}>
            <Typography variant="h6">
              ISD Code
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1em",
                }}
              >
                *
              </span>
            </Typography>
            <Controller
              name="whatsapp_country_code"
              control={control}
              defaultValue={studentInfo?.whatsapp_country_code || 91}
              rules={{ required: "Country code is required" }}
              render={({ field }) => (
                <CustomTextFieldComp
                  select
                  size="normal"
                  {...field}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                  fullWidth
                  defaultValue={91}
                  error={Boolean(errors.whatsapp_country_code)}
                >
                  {countries.map((country) => (
                    <MenuItem
                      key={country.phone_code}
                      value={country.phone_code}
                    >
                      {country.phone_code}
                    </MenuItem>
                  ))}
                </CustomTextFieldComp>
              )}
            />
            {errors.whatsapp_country_code && (
              <span style={{ color: "red" }}>
                {errors.whatsapp_country_code.message}
              </span>
            )}
          </Grid>
          <Grid item xs={8} md={8} lg={8.5}>
            <Typography variant="h6">
              Whatsapp Number
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1em",
                }}
              >
                *
              </span>
            </Typography>
            <Controller
              name="whatsapp_number"
              control={control}
              defaultValue={studentInfo?.whatsapp_number || null}
              rules={
                {
                  /*  pattern: {
                                        required: "Whatsapp number is required",
                                        value: /^[1-9][0-9]{9}$/,
                                        message: "Invalid whatsapp number."
                                    }*/
                }
              }
              render={({ field }) => (
                <CustomTextFieldComp
                  {...field}
                  size="normal"
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                  error={!!errors.whatsapp_number}
                  // helperText={errors.whatsapp_number?.message}
                  fullWidth
                />
              )}
            ></Controller>
            {errors.whatsapp_number && (
              <span style={{ color: "red" }}>
                {errors.whatsapp_number.message}
              </span>
            )}
          </Grid>
        </Grid>
      )}

      <Grid item xs={6} md={4} lg={4}>
        <Typography variant="h6">
          Email
          <span
            style={{
              color: "red",
              position: "absolute",
              marginLeft: "4px",
              fontSize: "1em",
            }}
          >
            *
          </span>
        </Typography>
        <Controller
          name="email"
          control={control}
          defaultValue={studentInfo.email || null}
          rules={{
            // required: "Email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address",
            },
          }}
          render={({ field }) => (
            <CustomTextFieldComp
              {...field}
              size="normal"
              // label="Email"
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              variant="outlined"
              error={!!errors.email}
              // helperText={errors.email}
              fullWidth
              //value={studentInfo.email || ''}
              // onChange={(e) => setStudentInfo({ ...studentInfo, email: e.target.value })}
            />
          )}
        ></Controller>
        {errors.email && (
          <span style={{ color: "red" }}>{errors.email.message}</span>
        )}
      </Grid>

      <Grid item xs={6} md={4} lg={3}>
        <Typography variant="h6">
          Country of Residence
          <span
            style={{
              color: "red",
              position: "absolute",
              marginLeft: "4px",
              fontSize: "1em",
            }}
          >
            *
          </span>
        </Typography>
        <Controller
          name="country_id"
          control={control}
          defaultValue={studentInfo?.country_id?.id || 101}
          rules={{ required: "Country is required" }}
          render={({ field }) => (
            <CustomTextFieldComp
              select
              size="normal"
              {...field}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              fullWidth
              value={selectedCountry}
              onChange={handleCountryChange}
              // defaultValue={101}
              error={Boolean(errors.country_id)}
              //helperText={errors.country && 'Country is required'}
            >
              {countriesList.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.country_name}
                </MenuItem>
              ))}
            </CustomTextFieldComp>
          )}
        />
        {errors.country_id && (
          <span style={{ color: "red" }}>{errors.country_id.message}</span>
        )}
      </Grid>

      <Grid item xs={6} md={4} lg={3}>
        <Typography variant="h6">Pincode</Typography>
        <Controller
          name="pincode_id"
          control={control}
          defaultValue={studentInfo.pincode_id || null}
          //rules={{ required: "Pin code is required", pattern: { value: /^\d{6}$/, message: "Invalid pin code" } }}
          render={({ field }) => (
            <CustomTextFieldComp
              {...field}
              size="normal"
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              fullWidth
              error={Boolean(errors.pincode_id)}
              onChange={handlePinCodeChange}
            />
          )}
        />
        {errors.pincode_id && (
          <span className="error-message" style={{ color: "red" }}>
            {errors.pincode_id.message}
          </span>
        )}
        {pincodeError && (
          <span className="error-message" style={{ color: "red" }}>
            {pincodeError}
          </span>
        )}
      </Grid>

      <Grid item xs={6} md={4} lg={3}>
        <Typography variant="h6">State</Typography>
        <Controller
          name="state_id"
          control={control}
          defaultValue={studentInfo?.state_id?.id || null}
          render={({ field }) => (
            <CustomTextFieldComp
              select
              size="normal"
              {...field}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              fullWidth
              value={selectedState}
              onChange={handleStateChange}
              // defaultValue={pinCodeData?.state}
              error={Boolean(errors.state_id)}
              //helperText={errors.state}
            >
              {states.map((state) => (
                <MenuItem key={state.id} value={state.id}>
                  {state.state_name}
                </MenuItem>
              ))}
            </CustomTextFieldComp>
          )}
        />
      </Grid>

      <Grid item xs={6} md={4} lg={3}>
        <Typography variant="h6">City</Typography>
        <Controller
          name="city_id"
          control={control}
          defaultValue={studentInfo?.city_id?.id || null}
          render={({ field }) => (
            <CustomTextFieldComp
              select
              size="normal"
              {...field}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              fullWidth
              value={selectedCity}
              onChange={handleCityChange}
              //defaultValue="IN"
              error={Boolean(errors.city_id)}
              // helperText={errors.city }
            >
              {cities.map((city) => (
                <MenuItem key={city.id} value={city.id}>
                  {city.city_name}
                </MenuItem>
              ))}
            </CustomTextFieldComp>
          )}
        />
      </Grid>

      <Grid item xs={6} md={4} lg={3}>
        <Typography variant="h6">
          Nearest Branch{" "}
          <span
            style={{
              color: "red",
              position: "absolute",
              marginLeft: "4px",
              fontSize: "1em",
            }}
          >
            *
          </span>
        </Typography>
        <Controller
          name="nearest_branch_id"
          control={control}
          rules={{ required: "Nearest Branch name is required" }}
          defaultValue={studentInfo?.nearest_branch_id?.id || null}
          render={({ field }) => (
            <CustomTextFieldComp
              select
              size="normal"
              {...field}
              SelectProps={{ MenuProps }}
              //value={nearest_branch}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              fullWidth
              error={Boolean(errors.nearest_branch_id)}
              // onChange={(event) => setNearestBranch(event.target.value)}
              // helperText={errors.nearest_branch && 'Branch Name is required'}
            >
              {branches.map((branch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.branch_name}
                </MenuItem>
              ))}
            </CustomTextFieldComp>
          )}
        />
        {errors.nearest_branch_id && (
          <span style={{ color: "red" }}>
            {errors.nearest_branch_id.message}
          </span>
        )}
      </Grid>

      <Grid item xs={6} md={4} lg={3}>
        <Typography variant="h6">
          Lead Source Type
          <span
            style={{
              color: "red",
              position: "absolute",
              marginLeft: "4px",
              fontSize: "1em",
            }}
          >
            *
          </span>
        </Typography>
        <Controller
          name="lead_source_type_id"
          control={control}
          rules={{ required: "Field is required" }}
          defaultValue={studentInfo?.lead_source_type_id?.id || null}
          render={({ field }) => (
            <CustomTextFieldComp
              select
              size="normal"
              {...field}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              fullWidth
              error={Boolean(errors.lead_source_type_id)}
              /*onChange={e => {
                                            field.onChange(e);
                                            handleLeadSourceTypeChange(e.target.value);
                                          }}*/
              value={selectedSourceType}
              onChange={handleSourceTypeChange}
              // helperText={errors.lead_source_type}
            >
              {leadSources.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </CustomTextFieldComp>
          )}
        />
        {errors.lead_source_type_id && (
          <span style={{ color: "red" }}>
            {errors.lead_source_type_id.message}
          </span>
        )}
      </Grid>
{selectedSourceType !==null && (
      <Grid item xs={6} md={4} lg={3}>
        <Typography variant="h6">
          Sub Lead Source Type
          <span
            style={{
              color: "red",
              position: "absolute",
              marginLeft: "4px",
              fontSize: "1em",
            }}
          >
            *
          </span>
        </Typography>
        <Controller
          name="sub_lead_source_type_id"
          control={control}
          rules={{ required: "Field is required" }}
          defaultValue={
            studentInfo.sub_lead_source_type_id?.id ||
            studentInfo.sub_lead_source_type_id ||
            null
          }
          render={({ field }) => (
            <CustomTextFieldComp
              select
              size="normal"
              {...field}
              SelectProps={{ MenuProps }}
              //  value={studentInfo.sub_lead_source_type_id || null}
              /*  onChange={(e) => {
                              setStudentInfo({ ...studentInfo, sub_lead_source_type_id: e.target.value })
                             
                            }}*/
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              fullWidth
              error={Boolean(errors.sub_lead_source_type_id)}
            >
              {subLeadSources.map((subSource) => (
                <MenuItem key={subSource.id} value={subSource.id}>
                  {subSource.source_type}
                </MenuItem>
              ))}
            </CustomTextFieldComp>
          )}
        />
        {errors.sub_lead_source_type_id && (
          <span style={{ color: "red" }}>
            {errors.sub_lead_source_type_id.message}
          </span>
        )}
      </Grid>
)}
      <Grid item xs={6} md={4} lg={3}>
        <Typography variant="h6">
          Service Name
          <span
            style={{
              color: "red",
              position: "absolute",
              marginLeft: "4px",
              fontSize: "1em",
            }}
          >
            *
          </span>
        </Typography>
        <Controller
          name="service_id"
          control={control}
          rules={{ required: "Field is required" }}
          defaultValue={
            studentInfo.service_id?.id || studentInfo.service_id || null
          }
          render={({ field }) => (
            <CustomTextFieldComp
              select
              size="normal"
              {...field}
              //  value={studentInfo.sub_lead_source_type_id || null}
              /*  onChange={(e) => {
                            setStudentInfo({ ...studentInfo, sub_lead_source_type_id: e.target.value })

                            }}*/
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              fullWidth
              error={Boolean(errors.service_id)}
              value={selectedService}
              onChange={handleServiceChange}
            >
              {services.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  {service.service_name}
                </MenuItem>
              ))}
            </CustomTextFieldComp>
          )}
        />
        {errors.service_id && (
          <span style={{ color: "red" }}>{errors.service_id.message}</span>
        )}
      </Grid>
      {selectedService !== null && (
        <Grid item xs={6} md={4} lg={3}>
          <Typography variant="h6">
            Sub Service
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="sub_service_id"
            control={control}
            rules={{ required: "Field is required" }}
            defaultValue={
              studentInfo.sub_service_id?.id ||
              studentInfo.sub_service_id ||
              null
            }
            render={({ field }) => (
              <CustomTextFieldComp
                select
                size="normal"
                {...field}
                //  value={studentInfo.sub_lead_source_type_id || null}
                /*  onChange={(e) => {
                            setStudentInfo({ ...studentInfo, sub_lead_source_type_id: e.target.value })

                            }}*/
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                fullWidth
                error={Boolean(errors.sub_service_id)}
              >
                {subServices.map((subService) => (
                  <MenuItem key={subService.id} value={subService.id}>
                    {subService.name}
                  </MenuItem>
                ))}
              </CustomTextFieldComp>
            )}
          />
          {errors.sub_service_id && (
            <span style={{ color: "red" }}>
              {errors.sub_service_id.message}
            </span>
          )}
        </Grid>
      )}

      <Grid item xs={6} md={4} lg={3}>
        <Typography variant="h6">
          Assign to
          <span
            style={{
              color: "red",
              position: "absolute",
              marginLeft: "4px",
              fontSize: "1em",
            }}
          >
            *
          </span>
        </Typography>
        <Controller
          name="assign_to"
          control={control}
          rules={{ required: "Field is required" }}
          defaultValue={
            studentInfo.assign_to?.id || studentInfo.assign_to || null
          }
          render={({ field }) => (
            <CustomTextFieldComp
              select
              size="normal"
              {...field}
              SelectProps={{ MenuProps }}
              //  value={studentInfo.sub_lead_source_type_id || null}
              /*  onChange={(e) => {
                            setStudentInfo({ ...studentInfo, sub_lead_source_type_id: e.target.value })

                            }}*/
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              fullWidth
              error={Boolean(errors.assign_to)}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.first_name}
                </MenuItem>
              ))}
            </CustomTextFieldComp>
          )}
        />
        {errors.assign_to && (
          <span style={{ color: "red" }}>{errors.assign_to.message}</span>
        )}
      </Grid>

      <Grid container justifyContent="right" spacing={0} mt={2}>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Continue
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Grid>
  );
};

export default StudentInfo;
