import { Button } from '@mui/material'
import React from 'react'
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function AddButton({onClick,label}) {
  return (
    <Button
    startIcon={ <AddCircleOutlineIcon fontSize='small'/> }
      onClick={onClick}
      variant="contained"
      color="primary"
      size="small"
      style={{borderRadius:'7px', }}
    >
      {label }
    </Button>
  )
}
