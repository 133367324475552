// Author : " Ayesha Shaikh"
// Date : 04 aug 2024

import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useGetTokenOrLogout } from "../../../utils/token";
import FilterComp from "../../newComponent/iconsComp/FilterComp";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";
import CustomAutocompleteComp from "../../newComponent/AutocompleteComp";
import ApplyFilterButton from "../../newComponent/NewButtonComp/ApplyFilterButton";
import ResetFilterButton from "../../newComponent/NewButtonComp/ResetFilterButton";
import NewCardcomp from "../../../components/card/NewCardcomp";
export default function CashCollectionFilter({
  setPayments,
  isLoading,
  showSnackbar,
  setIsLoading,
  originalData,
  open,
  setOpen,
  setSearchData
}) {
  const getTokenOrLogout = useGetTokenOrLogout();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const userRole=localStorage.getItem('userRole')
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  
  const [name, setName] = useState("");
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [fromdate, setfromdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [createdbylist, setCreatedbylist] = useState([]);
  const [createdby, setCreatedby] = useState([]);
  const [status, setStatus] = useState([]);
  const [amount, setAmount] = useState("");



  const handleNamechange = (e) => {
    setName(e.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allbranchList = [{ branch_name: "Select All" }, ...branchlist];

  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setCreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allcreatedByList = [{ first_name: "Select All" }, ...createdbylist];
  const handelcretedby = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setCreatedby(createdbylist);
    } else {
      setCreatedby(value);
    }
  };

  const statuslist = [
    { id: 1, name: "Received", value: "received" },
    {
      id: 2,
      name: "Deposited",
      value: "deposited",
    },
  ];

  const handlestatuschange = (event, value) => {
    setStatus(value);
  };

  const handlefromdatechange = (e) => {
    console.log(e.target.value);
    setfromdate(e.target.value);
  };

  const handletodatechange = (e) => {
    console.log(e.target.value);
    setenddate(e.target.value);
  };
  const newBranch = branch.map((i) => i.id);
  const newcreatedby = createdby.map((i) => i.id);
  const newStatus = status.map((i) => i.value);

  const handleApplyFilter = async (event) => {
    event.preventDefault();
    // Handle form submission logic here

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json" // Make sure to keep the content type
    };
    setIsLoading(true);
    const requestData = {
      payment_mode_id: [1],

      collected_by: newcreatedby,
      collected_status: newStatus,
      from_date: fromdate || null,
      to_date: enddate || null,
      user_id: parseInt(userId),

      is_partial_payment: [],
      payment_status: [],
      class_id: [],
      is_class_extension: [],
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/provisionalpaymentfilter`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        const sortedData = result.sort(
          (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
        );
        const filterdData = sortedData.filter(
          (item) =>
            item.collected_status === "deposited" ||
            item.collected_status === "received"
        );
        setPayments(filterdData);
        setSearchData(filterdData)
        setIsLoading(false);
      }else if(response.status===404) {
        showSnackbar("Could't find any data", 'error')
        setPayments([]);
        setSearchData([])
        setIsLoading(false);
      } else {
        setPayments([]);
        setSearchData([])
        setIsLoading(false);
        showSnackbar("Error Fetching Data", "error");
      }
    } catch (error) {
      setPayments([]);
      setSearchData([])
      setIsLoading(false);

      showSnackbar("Error Fetching Data", "error");
    }
  };

  const resetfilter = () => {
    setName("");
    setBranch([]);
 
    setCreatedby([]);
    setStatus([]);
    setAmount("");
    setPayments(originalData);
    setSearchData(originalData)
  };

  const handleamount = (e) => {
    setAmount(e.target.value);
  };

    // Add default 1 month start and end date
const formatDate = (date) => date.toISOString().split('T')[0];
useEffect(() => {
  let oneMonthAgo = new Date();
  oneMonthAgo.setDate(oneMonthAgo.getDate()-30);
  
  
  let today=new Date()
 
  setenddate(formatDate(today));
  setfromdate(formatDate(oneMonthAgo));
}, []);
  return (
    <>
     
      

        <Collapse in={open} sx={{ marginTop:open? "5px":0 }}>
        <MainCard >
          <Grid container spacing={2}>
            {/* <Grid item xs={3} lg={3} md={6} sm={6}>
              <Typography variant="h6">Enquiry Name </Typography>
              <TextField
                 size="normal"
              value={name}
              onChange={handleNamechange}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                fullWidth
              />
            </Grid> */}

            {/* <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography variant="h6">Branch</Typography>

              <Autocomplete
                size="normal"
                fullWidth
                value={branch}
                multiple
                onChange={handelbranchchange}
                options={allbranchList}
                getOptionLabel={(option) => option.branch_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "10px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography variant="h6"> Payment From  Date </Typography>
              <CustomTextFieldComp
                size="normal"
                value={fromdate}
                onChange={handlefromdatechange}
                type="date"
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography variant="h6"> Payment To Date </Typography>
              <CustomTextFieldComp
                size="normal"
                value={enddate}
                onChange={handletodatechange}
                type="date"
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                fullWidth
              />
            </Grid>
            {/* 
            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography variant="h6">
                {" "}
                Amount Collected{" "}
              </Typography>
              <TextField
                 size="normal"
                 value={amount}
                 onChange={handleamount}
                type="number"
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                fullWidth
              />
            </Grid> */}

{(userRole==="admin" || userRole==="center_admin") && ( 
            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography variant="h6">Collected By </Typography>
            

              <CustomAutocompleteComp
                size="normal"
                fullWidth
                value={createdby}
                multiple
                onChange={handelcretedby}
                options={allcreatedByList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
              tooltipContent={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
              />
            </Grid>
            )}
            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography variant="h6">Status</Typography>
              {/* <Select
                size="normal"
                  multiple
                  value={status}
                  onChange={handlestatuschange}
                  renderValue={(selected) =>
                    selected
                      .map((value) => {
                        const status = statuslist.find(
                          (status) => status.value === value
                        );
                        return status ? status.name : "";
                      })
                      .join(", ")
                  }
                  MenuProps={MenuProps}
                  style={{ borderRadius: "10px" }}
                >
                  {statuslist.map((name) => (
                    <MenuItem key={name.id} value={name.value}>
                      <Checkbox checked={status.indexOf(name.value) > -1} />
                      <ListItemText primary={name.name} />
                    </MenuItem>
                  ))}
                </Select> */}
              <CustomAutocompleteComp
                size="normal"
                fullWidth
                value={status}
                multiple
                onChange={handlestatuschange}
                options={statuslist}
                getOptionLabel={(option) => option.name}
              tooltipContent={(option) => option.name}
              />
            </Grid>
            <Grid item xs={12} lg={12} container justifyContent="end" gap={1}>
            <ApplyFilterButton
                
                onClick={handleApplyFilter}
              />
              <ResetFilterButton
                
                onClick={resetfilter}
              />
             

             
             
            </Grid>
          </Grid>
          </MainCard>
        </Collapse>
    
    </>
  );
}
