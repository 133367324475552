import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import { Provider } from 'react-redux';
import store from './redux/app/store';
import { EnquiryPopupProvider } from './context/EnquiryPopupContext';
import { ThemeProvider, createTheme } from '@mui/material';
import * as Sentry from "@sentry/react";
import "@fontsource/roboto/700.css"; // For 700 weight
import "@fontsource/roboto/400.css"; // For 400 weight
import "@fontsource/roboto/500.css"; // For 500 weight
import '@fontsource/roboto/300.css';

const theme = createTheme();

if (process.env.REACT_APP_ENV === 'production') {
Sentry.init({
  dsn: "https://bf332d1a19a00222d4fe9306ee0d07f3@o4507701561327616.ingest.de.sentry.io/4507701569585232",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, 
  tracePropagationTargets: [
    /^https:\/\/stmcrm-api\.siectestmasters\.in\/api\/crm/
  ],
  profilesSampleRate: 1.0, 
  // Session Replay
  replaysSessionSampleRate: 0.1, 
  replaysOnErrorSampleRate: 1.0,
})
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    
    <UserProvider>
   <BrowserRouter>
    <EnquiryPopupProvider>
    <ThemeProvider theme={theme}> 
    <App />
    </ThemeProvider>
    </EnquiryPopupProvider>
    </BrowserRouter>
    </UserProvider>
    
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
