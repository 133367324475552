import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Pagination,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import CreateLessonPopup from "./LessonPopup";
import LessonplanForm from "./LessonplanForm";
import LessonPlanFilter from "./LessonPlanFilter";
import { useGetTokenOrLogout } from "../../utils/token";
import NewCardcomp from "../../components/card/NewCardcomp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const CustomIcon = styled("img")({
  width: "15px",
  height: "15px",
  // position: 'fixed',
  // top: 0,
  // left: 0,
});
function LessonPlan() {
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");

  const [classes, setClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(9);
  console.log(selectedClass);
  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleViewLessonPlan = (classId, selectedClass) => {
    navigate(`/lessonplanlist/${classId}`, { state: { selectedClass } });
  };

  useEffect(() => {
    setIsLoading(true);

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route/fetchclass/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();

          let filteredData = [...data];

          if (filterCriteria) {
            filteredData = filteredData.filter((classItem) => {
              let passesFilter = true;

              if (filterCriteria.newClassName.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newClassName.includes(classItem?.id);
              }

              if (filterCriteria.newTrainerName.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newTrainerName.includes(
                    classItem.trainer_id?.id
                  );
              }

              if (filterCriteria.newBranch.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newBranch.includes(classItem.branch_id?.id);
              }

              if (filterCriteria.newModes.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newModes.includes(classItem?.mode_of_delivery);
              }

              if (filterCriteria.createfrom && filterCriteria.createdto) {
                const startDate = filterCriteria.createfrom;
                const endDate = filterCriteria.createdto;
                const createddate = new Date(classItem?.createdAt);
                const formattedDate = createddate.toISOString().slice(0, 10);
                console.log("formated date is", createddate);
                passesFilter =
                  passesFilter &&
                  formattedDate >= startDate &&
                  formattedDate <= endDate;
              }
              return passesFilter;
            });
          }
          setClasses(filteredData);
          //setFilteredData(data);
          setIsLoading(false);
          setOriginalData(data);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch {
        navigate("/page500");
      }
    };

    fetchData();
  }, [filterCriteria, userId]);

  const handleApplyFilter = (criteria) => {
    setFilterCriteria(criteria);
  };
  const handleResetFilter = () => {
    setFilterCriteria(null);
    setClasses(originalData);
  };

  // Pagination
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const totalPages = Math.ceil(classes.length / selectedRowsPerPage);

  const startIndex = (currentPage - 1) * selectedRowsPerPage;
  const endIndex = startIndex + selectedRowsPerPage;

  // Slice the array to get the students for the current page
  const dataForCurrentPage = classes.slice(startIndex, endIndex);

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LessonPlanFilter
            onApplyFilter={handleApplyFilter}
            onResetFilter={handleResetFilter}
          />
        </Grid>

        {classes.length === 0 && !isLoading ? (
          // Display a message when no classes are found
          <Grid item xs={12}>
            {" "}
            textAlign="center" width="100%" mt={10}
            <Typography variant="subtitle1">No Classes found.</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container spacing={5}>
              {dataForCurrentPage.map((classe, index) => (
                <Grid item xs={12} lg={4} md={6} sm={6}>
                  <NewCardcomp
                    borderRadius="20px"
                    padding="20px"
                    hoverBgColor={index % 2 === 0 ? "#D5F6E1" : "#FFEDFB"}
                    onClick={() => handleViewLessonPlan(classe.id, classe)}
                    hoverEffect={true}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h4" textAlign="center">
                          {classe.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h5" textAlign="center">
                          Trainer: {classe?.trainer_id?.first_name}{" "}
                          {classe?.trainer_id?.last_name}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} mt={1}>
                        <Grid container gap={2} justifyContent="center">
                          <Box
                            variant="h6"
                            sx={{
                              background: "#F4F4F4",

                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              gap: 1,
                              padding: "5px",

                              borderRadius: "6px",
                            }}
                          >
                            <img
                              src="/newTestPrep/Vector@2x.png"
                              alt="time"
                              width={13}
                            />
                            <Typography variant="h6" color="#425166">
                              {classe?.start_time} - {classe?.end_time}
                            </Typography>
                          </Box>
                          <Box
                            variant="h6"
                            sx={{
                              background: "#F4F4F4",

                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              gap: 1,
                              padding: "5px",

                              borderRadius: "6px",
                            }}
                          >
                            <img
                              src="/newTestPrep/icon _Laptop_.png"
                              alt="img"
                              width={13}
                            />{" "}
                            <Typography variant="h6" color="#425166">
                              {" "}
                              {classe?.mode_of_delivery}
                            </Typography>
                          </Box>
                          <Box
                            variant="h6"
                            sx={{
                              background: "#F4F4F4",

                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              gap: 1,
                              padding: "5px",

                              borderRadius: "6px",
                            }}
                          >
                            <img
                              src="/newTestPrep/Vector.png"
                              alt="img"
                              width={10}
                            />{" "}
                            <Typography variant="h6" color="#425166">
                              {" "}
                              {classe?.branch_id?.branch_name}
                            </Typography>{" "}
                          </Box>
                          <Typography
                            variant="h6"
                            sx={{
                              background: "#F4F4F4",
                              color: "#425166",

                              padding: "5px",

                              borderRadius: "6px",
                            }}
                          >
                            {" "}
                            {classe?.enrolled_student} Active Students
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={12} mt={2} textAlign="center">
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => handleViewLessonPlan(classe.id, classe)}
                      >
                        View Lesson Plan
                      </Button>
                    </Grid> */}
                      </Grid>
                    </Grid>
                  </NewCardcomp>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} container justifyContent="end">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            size="small"
            sx={{
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  backgroundColor: "#00b13f",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#00b13f",
                    color: "white",
                  },
                },
              },
            }}
          />
        </Grid>
      </Grid>
      <CreateLessonPopup
        onClose={handleClosePopup}
        open={openPopup}
        // title="Upload Lesson Plan"
        component={<LessonplanForm selectedClass={selectedClass} />}
      />
    </>
  );
}
export default LessonPlan;
