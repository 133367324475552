import { Avatar, Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../../../utils/token";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function UserProfile() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  let userRole = localStorage.getItem("userRole");
  const [userData, setUserData] = useState({});


  useEffect(() => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}employee/${userId}`, {
          headers,
        });
        if (response.ok) {
          const data = await response.json();
      

          setUserData(data);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        navigate("/page500");
      }
    };

    fetchData();
  }, []);


  const handleEdit = () => {
    const newState = { employeedetails: userData };
    localStorage.setItem("employeeState", JSON.stringify(newState));

    const url = `/view-profile/${userData.id}`;
    const newTab = window.open(url, "_blank");

    if (!newTab) {
      navigate(url, { state: newState });
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const { first_name, last_name, designation, gender, branch } = userData || {};

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Avatar
        alt="User Image"
        src={gender === "Female" ? "/logo/woman.png" : "/logo/profile.png"}
        sx={{ width: 56, height: 56 }}
      />
      <Box>
        <Typography variant="h4" component="div">
          {first_name ? capitalizeFirstLetter(first_name) : ""}{" "}
          {last_name ? capitalizeFirstLetter(last_name) : ""}{" "}
          {/* <IconButton
            size="small"
            aria-label="edit user"
            sx={{ marginLeft: 1, padding: 0 }}
            onClick={handleEdit}
          >
            <EditIcon sx={{ fontSize: 14 }} />
          </IconButton> */}
        </Typography>
        {userRole && (
          <Typography variant="body1" color="textSecondary">
            {userRole === "data_entry"
              ? "Data Entry"
              : userRole === "advisor"
              ? "Advisor"
              : userRole === "trainer"
              ? "Trainer"
              : userRole === "admin"
                  ? "Admin"
                  : userRole === "center_admin"
                  ? "Center Admin"
                  : userRole === "accountant"
                  ? "Accountant"
                  : ""}
          </Typography>
        )}
        {branch && (
          <Typography variant="body1" color="textSecondary">
            {branch?.branch_name}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default UserProfile;
