import React, {useEffect, useState} from 'react'
import { TextField, Button, Avatar } from '@mui/material';
//import Announcement from '../../components/announcement/Announcement';
import './mainClass.css'
import { useParams } from 'react-router-dom';
//import TrainerTab from '../../components/trainerTab/TrainerTab';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function MainClass() {
    const { classId } = useParams();
    const [ classData, setClassData] = useState({})
    const [ isLoading, setIsLoading] = useState(true)
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInput] = useState("");
  const [image, setImage] = useState(null);



  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = () => {

  }

  useEffect(() => {
    fetch(`${API_ENDPOINT}trainerdashboard/singleclassinfo/${classId}`)
      .then((res) => res.json())
      .then((data) => {
      
        setClassData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching classes:', error);
        setIsLoading(false);
      });
  }, [classId]);

  return (
    <>
    <div className="main">
      <div className="main__wrapper">
        <div className="main__content">
          <div className="main__wrapper1">
            <div className="main__bgImage">
              <div className="main__emptyStyles" />
            </div>
            <div className="main__text">
              <h1 className="main__heading main__overflow">
                { classData.name }
              </h1>
              <div className="main__section main__overflow">
                IELTS
              </div>
              <div className="main__wrapper2">
                <em className="main__code">Class Code : 356ZC</em>
                <div className="main__id">Instructors Name: Deepak</div>
                <div className="main__id">Mode of Delivery: Online</div>
              </div>
            </div>
          </div>
        </div>
        <div className="main__announce">
          <div className="main__status">
            <p>Upcoming</p>
            <p className="main__subText">No work due</p>
          </div>
          <div className="main__announcements">
            <div className="main__announcementsWrapper">
              <div className="main__ancContent">
                {showInput ? (
                  <div className="main__form">
                    <TextField
                      id="filled-multiline-flexible"
                      multiline
                      label="Announce Something to class"
                      variant="filled"
                      value={inputValue}
                      onChange={(e) => setInput(e.target.value)}
                    />
                    <div className="main__buttons">
                      <input
                        onChange={handleChange}
                        variant="outlined"
                        color="primary"
                        type="file"
                      />

                      <div>
                        <Button onClick={() => setShowInput(false)}>
                          Cancel
                        </Button>

                        <Button
                          onClick={handleUpload}
                          color="primary"
                          variant="contained"
                        >
                          Post
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="main__wrapper100"
                    onClick={() => setShowInput(true)}
                  >
                    <Avatar />
                    <div>Announce Something to class</div>
                  </div>
                )}
              </div>
            </div>
          {/*}  <Announcement  /> */}
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default MainClass