import { Tooltip } from '@mui/material'
import React from 'react'
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
export default function DenyIcon({onClick, tooltipTitle, fontSize}) {
  return (
   <>
      <Tooltip title={tooltipTitle}>
                  <CancelPresentationIcon
                    style={{ fontSize: fontSize || "1.2rem", cursor: "pointer" }}
                    variant="contained"
                    color="error"
                    onClick={onClick}
                  />
                </Tooltip>
   </>
  )
}
