import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Menu,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/Maincard";
import AdminRevenueCard from "../AdminRevenue/AdminRevenueCard";
import ReactApexChart from "react-apexcharts";
import LeadCalandar from "../LeadConversation/LeadCalandar";
import AdvisorRevenueTable from "./AdvisorRevenueTable";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { useNavigate, useParams } from "react-router-dom";
import { useGetTokenOrLogout } from "../../utils/token";
import { useDateUtils } from "../../utils/formatTime";
import FilterButtonComp from "../AllUserDashboards/Components/FilterButtonComp";
import RevenueCards from "../AllUserDashboards/CenterAdminDashboard/NewRevenueCards";
import NewCardcomp from "../../components/card/NewCardcomp";
import ProductBaseEnroll from "./ProductCard/ProductBaseDetails";
import AchivedAdvisorTarget from "./AchivedAdvisorTarget";
import TargetvsEnrolled from "./TargetvsEnrolled";
import ProductBaseEnrollRevenueStat from "./ProductCard/ProductBaseEnrollRevenueStat";
import LoaderComp from "../../newTestPrep/newComponent/LoaderComp/Loader";
import CustomPopup from "../../components/CustomPopup";
import EnrollDetails from "../AllUserDashboards/CenterAdminDashboard/EnrollDetails";
import MoreCardDetails from "../AllUserDashboards/CenterAdminDashboard/MoreCardDetails";
export default function AdvisorRevenue() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const { id } = useParams();

  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");

  console.log(id);

  const [userLoading, setUserloading] = useState(true);
  const [chosenDate, setChosenDate] = useState(new Date());
  const [chosenDate1, setChosenDate1] = useState(new Date());
  const [profileData, setProfileData] = useState({});
  const [iscardLoading, setCardLoading] = useState(false);
  const [cardData, setCardRevenueData] = useState([]);
  const [opendetailPopup, setOpenDetailPopup] = useState(false);
  const [selectedCardName, setSelectedCardName] = useState("");

  const [SelectedCardData, setSelectedCardData] = useState([]);
  const [selectedcardFilter, setSelectedcardfilter] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [productData, setProductData] = useState([]);

  const [revenueData, setRevenueData] = useState({});
  const [revenueLoading, setRevenueLoading] = useState(true);
  const [enroloading, setEnrolLoading] = useState(true);
  const [enrolTargetData, setEnroltargetData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const [selectedFilter, setSelectedFilter] = useState("today");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleClickFilter = (criteria, cardFilter) => {
    setSelectedFilter(criteria);

    if (criteria !== "custom") {
      handleApplyRevenuFilter(criteria, null, null);
      getProductData(criteria, null, null);
      setFromDate(null);
      setToDate(null);
    }
    if (criteria === "custom" && fromDate !== null && toDate !== null) {
      handleApplyRevenuFilter(criteria, fromDate, toDate);
      getProductData(criteria, fromDate, toDate);
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  console.log(chosenDate);
  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  useEffect(() => {
    setUserloading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/profile/${id}`, {
          headers,
        });
        if (response.ok) {
          const data = await response.json();
          setProfileData(data);
          setUserloading(false);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDateChange1 = (date) => {
    setChosenDate1(date);
  };

  const { first_name, last_name, role, branch_id } = profileData || {};

  // Fetch Card Data
  const handleApplyRevenuFilter = async (criteria, fromDate, toDate) => {
    setCardLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),
      advisor_user_id: id,
      filter: criteria,
      to_date: toDate || null,
      from_date: fromDate || null,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/gettodayrevenue`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();

        setCardRevenueData(result);

        setCardLoading(false);
      } else {
        console.log("Error");
        setCardLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      setCardLoading(false);
    }
  };

  useEffect(() => {
    if (selectedFilter !== "custom") {
      handleApplyRevenuFilter(selectedFilter);
      getProductData(selectedFilter);
    }
  }, []);

  // detailPopup
  const handleOpenDetailPopup = (cardName, cardFilter) => {
    setOpenDetailPopup(true);
    setSelectedCardName(cardName);
    setSelectedcardfilter(cardFilter);
  };

  const handleCloseDetailPopup = () => {
    setOpenDetailPopup(false);
  };

  const getCardMoreInfo = async (cardName, cardFilter) => {
    setDetailsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const requestData = {
      user_id: parseInt(userId),
      filter: selectedFilter,
      to_date: toDate,
      from_date: fromDate,
      filter_type: cardFilter,
      advisor_user_id: id,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/gettodayrevenue-with-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setSelectedCardData(result);
        setDetailsLoading(false);
        handleOpenDetailPopup(cardName, cardFilter);
      } else {
        console.log("Error");
        showSnackbar("Server Error", "error");
        setDetailsLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      showSnackbar("Server Error", "error");
      setDetailsLoading(false);
    }
  };

  // get product data api
  const getProductData = async (criteria, fromDate, toDate) => {
    setIsProductLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),
      filter: criteria,
      to_date: toDate,
      from_date: fromDate,

      advisor_user_id: id,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/gettodayrevenue-by-test-type`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setProductData(result);

        setIsProductLoading(false);
      } else {
        console.log("Error");
        setIsProductLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      setIsProductLoading(false);
    }
  };

  // Fetch  revenue target

  const handleDateChange = (date) => {
    setChosenDate(date);
  };

  const getRevenueTargetData = async (date) => {
    setRevenueLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),
      date: date,

      advisor_user_id: id,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/advisor-revenue-target-achievement`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setRevenueData(result);

        setRevenueLoading(false);
      } else {
        console.log("Error");
        setRevenueLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      setRevenueLoading(false);
    }
  };

  useEffect(() => {
    getRevenueTargetData(chosenDate);
  }, [chosenDate]);

  // get enroll target data
  const getEnrolTargetData = async (date) => {
    setEnrolLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),
      date: date,

      advisor_user_id: id,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/advisor-enrollment-target-achievement`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setEnroltargetData(result);

        setEnrolLoading(false);
      } else {
        console.log("Error");
        setEnrolLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      setEnrolLoading(false);
    }
  };

  useEffect(() => {
    getEnrolTargetData(chosenDate1);
  }, [chosenDate1]);

  return (
    <>
      <LoaderComp isLoading={userLoading || detailsLoading} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <Grid container spacing={2}>
            {/* Name and branch of advisor */}
            <Grid item xs={12}>
              <Typography variant="h4">
                {first_name ? capitalizeFirstLetter(first_name) : ""}{" "}
                {last_name ? capitalizeFirstLetter(last_name) : ""}{" "}
                <span style={{ color: "#F8AB1D" }}>
                  ({branch_id?.branch_name}){" "}
                </span>{" "}
              </Typography>
            </Grid>

            {/* Filter Button */}
            <Grid item xs={12} sm={12} mt={2}>
              <FilterButtonComp
                selectedFilter={selectedFilter}
                handleClickFilter={handleClickFilter}
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
              />
            </Grid>

            {/* Revenue cards */}
            <Grid item xs={12} container spacing={2} gap={1}>
              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#D5F6E1"
                  title="Total Revenue"
                  iconSrc="/Group 1000002878.png"
                  value={
                    iscardLoading ? (
                      <CircularProgress />
                    ) : (
                      cardData?.total_revenue
                    )
                  }
                  onClick={() =>
                    getCardMoreInfo("Total Revenue", "total_revenue")
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#E7F3FF"
                  title="Collected Revenue"
                  iconSrc="/Group 1000002854.png"
                  value={
                    iscardLoading ? (
                      <CircularProgress />
                    ) : (
                      cardData?.total_collected_revenue
                    )
                  }
                  onClick={() =>
                    getCardMoreInfo("Collected Revenue", "collected_revenue")
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#FFEDFB"
                  title="Pending Revenue"
                  iconSrc="/Group 1000002879.png"
                  value={
                    iscardLoading ? (
                      <CircularProgress />
                    ) : (
                      cardData?.total_pending_revenue
                    )
                  }
                  onClick={() =>
                    getCardMoreInfo("Pending Revenue", "pending_revenue")
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#FFF6E2"
                  title="New Enrolments"
                  iconSrc="/Group 1000002880.png"
                  value={
                    iscardLoading ? (
                      <CircularProgress />
                    ) : (
                      cardData?.new_enrollments || 0
                    )
                  }
                  onClick={() =>
                    getCardMoreInfo("New Enrollments", "new_enrollments")
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <NewCardcomp height="460px">
                <ProductBaseEnrollRevenueStat
                  productWiseData={productData}
                  isproductloading={isProductLoading}
                  selectedFilter={selectedFilter}
                  fromDate={fromDate}
                  toDate={toDate}
                  advisorId={id}
                />
              </NewCardcomp>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NewCardcomp height={275}>
                <LeadCalandar onDateChange={handleDateChange} />
                {revenueLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box mt={2}>
                    <AchivedAdvisorTarget
                      chosenDate={chosenDate}
                      targetData={revenueData}
                      advisorId={id}
                    />
                  </Box>
                )}
              </NewCardcomp>
            </Grid>

            <Grid item xs={12}>
              <NewCardcomp height={330}>
                <LeadCalandar onDateChange={handleDateChange1} />

                {enroloading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <TargetvsEnrolled
                    chosenDate={chosenDate1}
                    advisorId={id}
                    targetData={enrolTargetData}
                  />
                )}
              </NewCardcomp>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <CustomPopup
        open={opendetailPopup}
        onClose={handleCloseDetailPopup}
        component={
          selectedcardFilter === "new_enrollments" ? (
            <EnrollDetails
              onClose={handleCloseDetailPopup}
              selectedFilter={selectedFilter}
              SelectedCardData={SelectedCardData}
              fromDate={fromDate}
              toDate={toDate}
            />
          ) : (
            <MoreCardDetails
              onClose={handleCloseDetailPopup}
              selectedFilter={selectedFilter}
              SelectedCardData={SelectedCardData}
              selectedCardName={selectedCardName}
              selectedCountFilter={selectedcardFilter}
              fromDate={fromDate}
              toDate={toDate}
            />
          )
        }
        title={`${selectedCardName} Details`}
        maxWidth="lg"
        showDivider={true}
      />
    </>
  );
}
