import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import MainCard from "../../components/ui-component/card/MainCard";
import { toPng } from "html-to-image";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";
import ApplyFilterButton from "../../newComponent/NewButtonComp/ApplyFilterButton";
import SearchButton from "../../newComponent/NewButtonComp/SearchButton";
import SubmitButton from "../../newComponent/NewButtonComp/SubmitButton";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function WalkInQR() {
  const userId = localStorage.getItem("userId");
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(null);
  const [qrcode, setQrcode] = useState(null);

  const production = process.env.REACT_APP_ENV === "production";

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/fetchbranch/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  const handleBranchSelect = (event) => {
    setBranch(event.target.value);
    setQrcode(null);
  };

  // Generate QR code when button is clicked
  const handleGenerateQR = () => {
    setQrcode(branch);
  };

  // Download QR code as PNG
  const downloadQRCode = () => {
    const qrCodeElement = document.getElementById("qrCodeContainer");
    toPng(qrCodeElement)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.download = `branch_${branch}_qr_code.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error("Error generating QR code image: ", error);
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} lg={3}>
        <HeadingCard label="Generate Branch Offline WalkIn QR" />
      </Grid>
      <Grid item xs={12} lg={12}>
        <MainCard>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth>
                <Typography variant="h5">Select Branch</Typography>
                <Select
                  size="small"
                  value={branch}
                  onChange={handleBranchSelect}
                  style={{ borderRadius: "10px" }}
                >
                  {branches.map((b) => (
                    <MenuItem key={b.id} value={b.id}>
                      {b.branch_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="h5" visibility="hidden">
                Select Branch
              </Typography>
              <Grid container gap={1}>
                <SearchButton
                  label="Generate QR"
                  disabled={!branch}
                  onClick={handleGenerateQR}
                />

                <SubmitButton
                  label="Download QR"
                  disabled={!qrcode}
                  onClick={downloadQRCode}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box mt={2}>
            {qrcode && (
              <div id="qrCodeContainer"  style={{
                display: "flex",
                padding: "20px",
                flexDirection: "column", // Arrange items in a column
                alignItems: "center", // Center horizontally on the page
                justifyContent: "center", // Center vertically on the page
                backgroundColor: "white",
                gap: "20px",
              }}>
                 <Box
                                style={{
                                  width: "170px", // Ensure the logo spans the full container width
                                }}
                              >
                <img
                  src="/Siec Logo.png"
                  alt="Logo"
                  style={{
                    width: "100%", // Adjust size as needed
                  }}
                />
                </Box>
                {/* QR Code */}
                 <Box className="scanner-border">
                                <Box
                                  margin={1}
                                  style={{ backGround: "white", border: "10px solid white" }}
                                >
                <QRCode
                  size={300}
                  value={
                    production
                      ? `https://student.siectestmasters.in/basic-registration?branch=${qrcode}`
                      : `https://dev.d1ufz94xk9odzx.amplifyapp.com/basic-registration?branch=${qrcode}`
                  }
                  viewBox={`0 0 256 256`}
                />
                </Box>
                </Box>

                {/* Branch Name */}
                <Typography
                  variant="h4"
                  sx={{ marginTop: 1, fontWeight: "bold" }}
                >
                  {branches.find((b) => b.id === parseInt(branch))?.branch_name}
                </Typography>
              </div>
            )}
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
}

export default WalkInQR;
