// Author : Ayesha Shaikh
//  Date : 22 Mar 2025

import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HeadingCard from "../../../newComponent/NewCardsComp/HeadingCard";
import DownLoadButton from "../../../newComponent/NewButtonComp/DownLoadButton";
import MainCard from "../../../../components/Maincard";
import CustomAutocompleteComp from "../../../newComponent/AutocompleteComp";
import SearchButton from "../../../newComponent/NewButtonComp/SearchButton";
import StyledTableContainer from "../../../newComponent/TableContainer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDateUtils } from "../../../../utils/formatTime";
import NewCardcomp from "../../../../components/card/NewCardcomp";
import CustomPopup from "../../../../components/CustomPopup";
import ShowStages from "./ShowStages";
import SubserviceEnquiryDetails from "./SubserviceEnquiryDetails";
import CustomSnackbar from "../../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../../../utils/token";
import ClockLoader from "../../../newComponent/LoaderComp/ClockLoader";
import LoaderComp from "../../../newComponent/LoaderComp/Loader";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function TestTypeWiseEnquiryReport() {
  const getTokenOrLogout = useGetTokenOrLogout();
  const { currentMonthName } = useDateUtils();
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();
  const [branchList, setBranchList] = useState([]);
  const [branchName, setBranchName] = useState([]);
  const [columnName, setColumnName] = useState(null);

  const [advisorList, setAdvisorList] = useState([]);
  const [advisorName, setAdvisorName] = useState([
    { id: "all", first_name: "Select All" },
  ]);

  const [selectedYear, setSelectedYear] = useState({
    year: new Date().getFullYear(),
  });
  const [monthList, setMonthList] = useState([]);
  const [monthname, setMonthName] = useState(null);

  const [currentMonthIndex, setCurrentMonthIndex] = useState(0);
  const [rowData, setrowData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [enquiryData, setEnquiryData] = useState([]);
  const [open, setOpen] = useState(false);

  const [opendetails, setOpendetails] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsData, setDetailsdata] = useState([]);
  const [breakDownLoading, setbreakdownLoading] = useState(false);
  const [breakDownData, setBrealDownData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          setBranchList(data);
          setBranchName(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();

          setAdvisorList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const allBranchList = [{ branch_name: "Select All" }, ...branchList];

  const handleBranchChange = (event, value) => {
    if (value.some((item) => item.branch_name === "Select All")) {
      // If "Select All" is selected, set all branches
      setBranchName(branchList);
    } else {
      // If individual branches are selected, remove "Select All"
      setBranchName(value);
    }
  };

  const years = Array.from({ length: 5 }, (_, index) => ({
    year: new Date().getFullYear() - 3 + index,
  }));

  const handleselectedYear = async (value) => {
    setSelectedYear(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}month`);
        if (response.ok) {
          const data = await response.json();

          setMonthList(data);
          const currentIndex = data.findIndex(
            (month) => month.month_full_name === currentMonthName
          );

          setCurrentMonthIndex(currentIndex !== -1 ? currentIndex : 0);
          setMonthName(data[currentIndex].month_full_name);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handlePreviousMonth = () => {
    if (currentMonthIndex > 0) {
      const prevIndex = currentMonthIndex - 1;
      setCurrentMonthIndex(prevIndex);
      const monthName = monthList[prevIndex].month_full_name;
      setMonthName(monthName);
      handleSearchData(monthName);
    }
  };

  const handleNextMonth = () => {
    if (currentMonthIndex < monthList.length - 1) {
      const nextIndex = currentMonthIndex + 1;
      setCurrentMonthIndex(nextIndex);
      const monthName = monthList[nextIndex].month_full_name;
      setMonthName(monthName);
      handleSearchData(monthName);
    }
  };

  // Search Data

  const handleSearchData = async (month) => {
    if (selectedYear === null) {
      showSnackbar("Please Select Year", "error");
      return;
    }

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      user_id: parseInt(userId),
      year: selectedYear?.year,
      branch_id: branchName.map((i) => i.id),
      month: month,
    };

    setIsLoading(true);
    try {
      const response = await fetch(
        `${API_ENDPOINT}route/sub-service-wise-enq`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        // const reversedData = result.slice().reverse();
        const sortedData = result.sort(
          (a, b) => b.total_enquiry - a.total_enquiry
        );
        setEnquiryData(sortedData);
        setIsLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        showSnackbar("Please select user", "error");
        setEnquiryData([]);
        setIsLoading(false);
      } else if (response.status === 440) {
        navigate("/page440");
      } else if (response.status === 400) {
        showSnackbar("Some fields are missing", "error");
        setEnquiryData([]);
        setIsLoading(false);
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  useEffect(() => {
    handleSearchData(currentMonthName);
  }, []);

  const handleOpenStatusPopup = (rowData) => {
    setOpen(true);
    setrowData(rowData);
  };
  const handleCloseStatusPopup = (status, id) => {
    setOpen(false);
    setrowData(status);
  };

  const handleOpen = (rowData, columnName) => {
    setOpendetails(true);
    setrowData(rowData);
    setColumnName(columnName);
  };

  const handleclose = () => {
    setOpendetails(false);
  };

  const payLoadData = {
    enquiryData,
    branchName,
    selectedYear,
    monthname,
  };
  const handleView = () => {
    const url = `/view-sub-service-wise-report-pdf`;
    window.open(url, "_blank");

    localStorage.setItem("enquiryData", JSON.stringify(payLoadData));
  };

  // get Enquiry Details
  const handleGetEnquiryDetails = async (rowData, columnName, columnname1) => {
    if (selectedYear === null) {
      showSnackbar("Please Select Year", "error");
      return;
    }

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      user_id: parseInt(userId),
      year: selectedYear?.year,
      branch_id: branchName.map((i) => i.id),
      month: monthname,
      sub_service_id: rowData?.sub_service_id?.id,
      filter_type: columnname1,
    };

    setDetailsLoading(true);
    try {
      const response = await fetch(
        `${API_ENDPOINT}route/sub-service-wise-enq-with-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        // const reversedData = result.slice().reverse();
        setDetailsdata(result);
        setDetailsLoading(false);
        handleOpen(rowData, columnName);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        showSnackbar("Please select user", "error");
        setDetailsdata([]);
        setDetailsLoading(false);
      } else if (response.status === 440) {
        navigate("/page440");
      } else if (response.status === 400) {
        showSnackbar("Some fields are missing", "error");
        setDetailsdata([]);
        setDetailsLoading(false);
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  // Get breakDown Details

  const handleGetBreakDownDetails = async (rowData) => {
    if (selectedYear === null) {
      showSnackbar("Please Select Year", "error");
      return;
    }

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      user_id: parseInt(userId),
      year: selectedYear?.year,
      branch_id: branchName.map((i) => i.id),
      month: monthname,
      sub_service_id: rowData?.sub_service_id?.id,
    };

    setbreakdownLoading(true);
    try {
      const response = await fetch(
        `${API_ENDPOINT}route/sub-service-wise-enq-breakdown`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        // const reversedData = result.slice().reverse();
        const sortedData = result.sort(
          (a, b) => b.stage_count - a.stage_count 
        );
        setBrealDownData(sortedData);
        setbreakdownLoading(false);
        handleOpenStatusPopup(rowData);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        showSnackbar("Please select user", "error");
        setBrealDownData([]);
        setbreakdownLoading(false);
      } else if (response.status === 440) {
        navigate("/page440");
      } else if (response.status === 400) {
        showSnackbar("Some fields are missing", "error");
        setBrealDownData([]);
        setbreakdownLoading(false);
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  return (
    <>
      {/* Heading */}
      <LoaderComp isLoading={isLoading || detailsLoading || breakDownLoading} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <HeadingCard label="Sub Service Wise Enquiries" />
        </Grid>

        {/* DownLoad Report */}
        {userRole === "center_admin" && (
          <Grid item xs={12} sm={6} md={8} lg={9} textAlign="end">
            {" "}
            <DownLoadButton onClick={handleView} />{" "}
          </Grid>
        )}

        {/* Search Fields */}

        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              {userRole === "center_admin" && (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h6">Select Branch</Typography>
                    <CustomAutocompleteComp
                      size="small"
                      value={branchName}
                      multiple
                      onChange={handleBranchChange}
                      options={allBranchList}
                      getOptionLabel={(option) => option.branch_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "14px" }, // Add borderRadius here
                          }}
                          size="small"
                        />
                      )}
                      tooltipContent={(option) => option.branch_name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            backgroundColor: selected ? "#e0f7fa" : "inherit",
                          }}
                        >
                          {option.branch_name}
                        </li>
                      )}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={3}>
                    <Typography variant="h6">Select User</Typography>
                    <CustomAutocompleteComp
                      size="small"
                      value={advisorName}
                      multiple
                      onChange={handleUserChange}
                      options={allAdvisorList}
                      getOptionLabel={(option) =>
                        option.id === "all"
                          ? "All Selected"
                          : `${option.first_name ? option.first_name : ""} ${
                              option.last_name ? option.last_name : ""
                            }`
                      }
                      tooltipContent={(option) =>
                        option.id === "all"
                          ? "All Selected"
                          : `${option.first_name ? option.first_name : ""} ${
                              option.last_name ? option.last_name : ""
                            }`
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(props, option, { selected }) => {
                        console.log("selected", selected);
                        return (
                          <li
                            {...props}
                            style={{
                              backgroundColor: selected ? "#e0f7fa" : "inherit",
                            }}
                          >
                            {option.first_name} {option.last_name}
                          </li>
                        );
                      }}
                    />
                  </Grid> */}
                </>
              )}

              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Select Year</Typography>
                <CustomAutocompleteComp
                  value={selectedYear}
                  onChange={(event, value) => handleselectedYear(value)}
                  size="small"
                  options={years}
                  getOptionLabel={(option) => option.year}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography sx={{ visibility: "hidden" }}>
                  Spacing purpose
                </Typography>
                <SearchButton
                  size="small"
                  onClick={() => handleSearchData(monthname)}
                  showIcon={true}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>

        {/* Table */}

        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack
                  display="flex"
                  flexDirection="row"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    style={{
                      cursor: "pointer",
                      display: "inline-flex", // Align content inside
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px", // Set width and height for circle
                      height: "25px",
                      borderRadius: "50%", // Makes the element circular
                      backgroundColor: "#00C853", // Optional: add background color
                      border: "1px solid #ccc", // Optional: add border

                      opacity: currentMonthIndex === 0 ? 0.5 : 1,
                    }}
                    onClick={handlePreviousMonth}
                  >
                    <ArrowBackIcon
                      style={{ color: "white", fontSize: "1rem" }}
                    />
                  </Typography>

                  <Typography variant="h4">
                    {monthname} {selectedYear?.year}
                  </Typography>
                  <Typography
                    style={{
                      cursor: "pointer",
                      display: "inline-flex", // Align content inside
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px", // Set width and height for circle
                      height: "25px",
                      borderRadius: "50%", // Makes the element circular
                      backgroundColor: "#00C853", // Optional: add background color
                      border: "1px solid #ccc", // Optional: add border
                      opacity:
                        currentMonthIndex === monthList.length - 1 ? 0.5 : 1,
                    }}
                    onClick={handleNextMonth}
                  >
                    <ArrowForwardIcon
                      style={{ color: "white", fontSize: "1rem" }}
                    />
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <StyledTableContainer component={Paper} borderLeft={true}>
                  <Table
                    sx={{ minWidth: 650, tableLayout: "fixed" }}
                    aria-label="simple table"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Sub Service</TableCell>
                        <TableCell>Total Enquiries</TableCell>
                        <TableCell>Online Enquiries</TableCell>
                        <TableCell>Offline Enquiries</TableCell>
                        <TableCell>Status Breakdown</TableCell>
                      </TableRow>
                    </TableHead>

                    {enquiryData?.map((row, index) => (
                      <TableBody>
                        <TableRow
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "inherit",
                          }}
                        >
                          <TableCell>{row?.sub_service_id?.name}</TableCell>
                          <TableCell
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() =>
                              handleGetEnquiryDetails(
                                row,
                                "Total",
                                "total_enquiry"
                              )
                            }
                          >
                            {row?.total_enquiry}
                          </TableCell>
                          <TableCell
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() =>
                              handleGetEnquiryDetails(
                                row,
                                "Online",
                                "online_enquiry"
                              )
                            }
                          >
                            {row?.online_enquiry}
                          </TableCell>
                          <TableCell
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() =>
                              handleGetEnquiryDetails(
                                row,
                                "Offline",
                                "offline_enquiry"
                              )
                            }
                          >
                            {row?.offline_enquiry}
                          </TableCell>
                          <TableCell>
                            <Button
                              color="secondary"
                              size="small"
                              onClick={() =>
                                handleGetBreakDownDetails(row, row?.id)
                              }
                              variant="text"
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                </StyledTableContainer>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      <CustomPopup
        open={open}
        onClose={handleCloseStatusPopup}
        component={
          <ShowStages
            onClose={handleCloseStatusPopup}
            data={breakDownData}
            payLoadData={payLoadData}
            sub_service_id={rowData?.sub_service_id}

            showSnackbar={showSnackbar}
          />
        }
        title={`Stages & Prospective Level Details Of ${rowData?.sub_service_id?.name}`}
        maxWidth="lg"
        showDivider={true}
      />

      <CustomPopup
        open={opendetails}
        onClose={handleclose}
        component={
          <SubserviceEnquiryDetails
            onClose={handleclose}
            data={detailsData}
            payLoadData={payLoadData}
            stagePopup={false}
          />
        }
        title={`${columnName} Enquiry Details of ${rowData?.sub_service_id?.name}`}
        maxWidth="lg"
        showDivider={true}
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
