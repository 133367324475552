import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

function AddTestTypeDialog({ open, onClose, onAdd }) {
  const [name, setName] = useState("");
  const [error, setError] = useState(false);

  const handleNameChange = (e) => {
    const value = e.target.value;

    // const isValid = /^[A-Za-z\s]+$/.test(value) || value === "";
    setName(value);
    // setError(!isValid);
  };

  const handleAdd = () => {
    onAdd(name);
  
    
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Test Type</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          value={name}
          onChange={handleNameChange}
          fullWidth
          variant="outlined"
          size="small"
          sx={{ marginTop: "7px" }}
          // error={error}
          // helperText={error ? "Only alphabetic characters are allowed" : ""}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="error"
          variant="contained"
          size="small"
        >
          Cancel
        </Button>
        <Button
          onClick={handleAdd}
          color="primary"
          variant="contained"
          size="small"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddTestTypeDialog;
