import React, { useEffect, useState } from "react";
import MainClass from "../../views/trainer/classes/mainClass/MainClass";
import MainCard from "../../newTestPrep/components/ui-component/card/MainCard";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
// import TicketPopup from "./TicketPopup";
// import TicketForm from "./TicketForm";
import { fDate } from "../../utils/formatTime";
import { useNavigate } from "react-router-dom";
import TicketDrawer from "./TicketDrawer";
import { Edit } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import EditticketDrawer from "./EditticketDrawer";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { useGetTokenOrLogout } from "../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function Raiceticket() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const [openTicketForm, setOpenTicketForm] = useState(false);
  const [ticketdata, setticketdata] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state

  const [selectedTicket, setSelectedTicket] = useState(null);
  const [open, setopen] = useState(false);
  const [openpopup, setOpenpopup] = React.useState(false);
  const [ticketToDelete, setTicketToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsPerPage] = useState(4);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    console.log(message);
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOpen = (ticket) => {
    setSelectedTicket(ticket);
    setOpenpopup(true);
  };

  const handleClose = () => {
    setOpenpopup(false);
  };

  const handleDeleteTicket = async () => {
    try {
      // Make a PUT request to update is_active to 0 for the selected ticket
      const response = await fetch(
        `${API_ENDPOINT}ticket/${selectedTicket.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ is_active: 0, user_id: userId }),
        }
      );

      if (response.ok) {
        // If the request is successful, update the ticket data by removing the deleted ticket
        setticketdata((prevTickets) =>
          prevTickets.filter((ticket) => ticket.id !== selectedTicket.id)
        );
        handleClose(); // Close the dialog after deletion
      } else {
        throw new Error(
          `Failed to delete ticket with status: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Error deleting ticket:", error);
    }
  };

  const handleCloseTicketForm = () => {
    setOpenTicketForm(false);
  };

  const handleOpenTicketForm = () => {
    setOpenTicketForm(true);
  };

  const handelopendrawer = () => {
    setopen(true);
  };

  const handelclosedrawer = () => {
    setopen(false);
  };

  console.log(userId);

  useEffect(() => {
    setLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route/fetchtickets/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();

          const activeTickets = data.filter((ticket) => ticket.is_active === 1);
          const sortedData = activeTickets.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setticketdata(sortedData);
          setLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch {
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId]);

  console.log(ticketdata);

  const date = ticketdata.map((item) => {
    return new Date(item.createdAt).toISOString().slice(0, 10);
  });

  console.log(date);

  const handleEditTicket = (ticket) => {
    setSelectedTicket(ticket); // Set the selected ticket

    setopen(true); // Open the drawer
  };

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;

  const waitingTickets = ticketdata
    .filter((ticket) => ticket.status === "created")
    .slice(indexOfFirstTicket, indexOfLastTicket);

  const resolvingTickets = ticketdata
    .filter((ticket) => ticket.status === "resolving")
    .slice(indexOfFirstTicket, indexOfLastTicket);

  const resolvedTickets = ticketdata
    .filter((ticket) => ticket.status === "completed")
    .slice(indexOfFirstTicket, indexOfLastTicket);

  console.log(waitingTickets);
  return (
    <>
      <MainCard>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={10} sm={9} md={9}>
            <Typography variant="h3">Track Your Tickets</Typography>
          </Grid>
          <Grid item xs={6} lg={2} sm={3} md={3}>
            <Button
              variant="contained"
              style={{ background: "#00b13f" }}
              onClick={handleOpenTicketForm}
            >
              Raise Ticket
            </Button>
          </Grid>

          <Grid item xs={12} lg={4} sm={6} md={6}>
            <Paper
              elevation={2}
              // sx={{  display: "flex", alignItems: "center" }}
            >
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                }}
              >
                Waiting
              </Typography>
              {loading ? ( // Conditionally render loader if loading is true
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: "15px",
                  }}
                >
                  {waitingTickets.map((item) => (
                    <Paper
                      elevation={2}
                      sx={{
                        marginBottom: "15px",
                        paddingLeft: "10px",
                        borderRadius: "16px",
                        position: "relative",
                        backgroundColor:
                          item.type_of_ticket === "issue"
                            ? "#ffebee"
                            : item.type_of_ticket === "feedback"
                            ? "#e8f5e9"
                            : "inherit",
                      }}
                      key={item.id}
                    >
                      <Box sx={{ position: "absolute", top: 0, right: 0 }}>
                        <Tooltip title="Edit Ticket" arrow>
                          <IconButton onClick={() => handleEditTicket(item)}>
                            <Edit fontSize="small" /> {/* Edit icon */}
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete Ticket" arrow>
                          <IconButton onClick={() => handleOpen(item)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Stack
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            marginLeft: "7px",
                            marginTop: "30px",
                            wordWrap: "break-word",
                            maxWidth: "calc(100% - 50px)",
                          }}
                        >
                          {item.issue_description} {item.feedback_description}
                        </Typography>
                      </Stack>

                      <Stack
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppin",
                            fontSize: "0.7rem",
                            fontWeight: 500,
                            marginLeft: "7px",
                          }}
                        >
                          {item.ticket_priority ? (
                            <span style={{ color: "gold" }}>★★★★★</span>
                          ) : (
                            <span style={{ color: "gold" }}>★★★</span>
                          )}
                        </Typography>
                      </Stack>

                      <Chip
                        size="small"
                        variant="contained"
                        label={`${item.type_of_ticket}`}
                        sx={{
                          textAlign: "center",
                          marginTop: "5px",
                          background :    item.type_of_ticket === "issue" ? "#ff4b4b" :"#00b13f"
                        }}
                        
                      />

                      <Typography
                        variant="h5"
                        sx={{
                          color: "#B2BEB5",

                          marginTop: "5px",
                        }}
                      >
                        {fDate(item.createdAt)}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} lg={4} sm={6} md={6}>
            <Paper
              elevation={2}
              // sx={{  display: "flex", alignItems: "center" }}
            >
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                }}
              >
                Resolving
              </Typography>
              {loading ? ( // Conditionally render loader if loading is true
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: "15px",
                  }}
                >
                  {resolvingTickets.map((item) => (
                    <Paper
                      elevation={2}
                      sx={{
                        marginBottom: "15px",
                        paddingLeft: "10px",
                        borderRadius: "16px",
                        position: "relative",
                        backgroundColor:
                          item.type_of_ticket === "issue"
                            ? "#ffebee"
                            : item.type_of_ticket === "feedback"
                            ? "#e8f5e9"
                            : "inherit",
                      }}
                      key={item.id}
                    >
                      {/* <IconButton
                        sx={{ position: "absolute", top: 0, right: 0,  }} // Positioning the IconButton
                        onClick={() => handleEditTicket(item)}
                      >
                        <Edit  /> 
                      </IconButton> */}

                      <Stack
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            marginLeft: "7px",
                            marginTop: "20px",
                            wordWrap: "break-word", // Allows breaking long words
                            maxWidth: "calc(100% - 50px)",
                          }}
                        >
                          {item.issue_description} {item.feedback_description}
                        </Typography>
                      </Stack>

                      <Stack
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "Poppin",

                            fontWeight: 500,
                            marginLeft: "7px",
                          }}
                        >
                          {item.ticket_priority ? (
                            <span style={{ color: "gold" }}>★★★★★</span>
                          ) : (
                            <span style={{ color: "gold" }}>★★★</span>
                          )}
                        </Typography>
                      </Stack>

                      <Chip
                        size="small"
                        variant="contained"
                        label={`${item.type_of_ticket}`}
                        sx={{
                          textAlign: "center",
                          marginTop: "5px",
                            background :    item.type_of_ticket === "issue" ? "#ff4b4b" :"#00b13f"
                        }}
                        
                      />

                      <Typography
                        variant="h5"
                        sx={{
                          color: "#B2BEB5",

                          marginTop: "5px",
                        }}
                      >
                        {fDate(item.createdAt)}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} lg={4} sm={6} md={6}>
            <Paper
              elevation={2}
              // sx={{  display: "flex", alignItems: "center" }}
            >
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                }}
              >
                Resolved
              </Typography>
              {loading ? ( // Conditionally render loader if loading is true
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: "15px",
                  }}
                >
                  {resolvedTickets.map((item) => (
                    <Paper
                      elevation={2}
                      sx={{
                        marginBottom: "15px",
                        paddingLeft: "10px",
                        borderRadius: "16px",
                        position: "relative",
                        backgroundColor:
                          item.type_of_ticket === "issue"
                            ? "#ffebee"
                            : item.type_of_ticket === "feedback"
                            ? "#e8f5e9"
                            : "inherit",
                      }}
                      key={item.id}
                    >
                      <Stack
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            marginLeft: "7px",
                            marginTop: "20px",
                            wordWrap: "break-word", // Allows breaking long words
                            maxWidth: "calc(100% - 50px)",
                          }}
                        >
                          {item.issue_description} {item.feedback_description}
                        </Typography>
                      </Stack>

                      <Stack
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "Poppin",

                            fontWeight: 500,
                            marginLeft: "7px",
                          }}
                        >
                          {item.ticket_priority ? (
                            <span style={{ color: "gold" }}>★★★★★</span>
                          ) : (
                            <span style={{ color: "gold" }}>★★★</span>
                          )}
                        </Typography>
                      </Stack>

                      <Chip
                        size="small"
                        variant="contained"
                        label={`${item.type_of_ticket}`}
                        sx={{
                          textAlign: "center",
                          marginTop: "5px",
                            background :    item.type_of_ticket === "issue" ? "#ff4b4b" :"#00b13f"
                        }}
                        
                      />

                      <Typography
                        variant="h5"
                        sx={{
                          color: "#B2BEB5",

                          marginTop: "5px",
                        }}
                      >
                        {fDate(item.createdAt)}
                      </Typography>

                      <Stack
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            marginLeft: "7px",
                            marginTop: "20px",
                          }}
                        >
                          Resolved Notes
                        </Typography>
                      </Stack>

                      <Stack
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            marginLeft: "7px",
                          }}
                        >
                          {item.notes}
                        </Typography>
                      </Stack>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#B2BEB5",

                          marginTop: "5px",
                        }}
                      >
                        Date: {fDate(item.updatedAt)}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <Pagination
          size="small"
            count={Math.ceil(ticketdata.length / ticketsPerPage)}
            color="secondary"
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      </MainCard>
      <TicketDrawer open={openTicketForm} onClose={handleCloseTicketForm} />

      <EditticketDrawer
        open={open}
        onClose={handelclosedrawer}
        ticket={selectedTicket}
        setticketdata={setticketdata}
      />

      <Dialog
        open={openpopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle variant="h4">
          {"Are you sure you want to delete this ticket?"}
        </DialogTitle>

        <DialogActions>
          <Button
            size="small"Ticket
            onClick={handleClose}
            variant="contained"
            style={{ background: "red" }}
          >
            No
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={handleDeleteTicket}
            color="primary"
            style={{ background: "green" }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
