import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Backdrop, Box, CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material';

// project imports

import MainCard from '../../../../components/ui-component/card/MainCard'; 
import { gridSpacing } from '../../../../store/constant'; 

// assets
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import StudentProfile from './StudentProfile';
import StudentPayments from './StudentPayments';
import StudentAttendance from './StudentAttendance';
import StudentTestDetails from './StudentTestDetails';
import StudentClassDetails from './StudentClassDetails';
import StudentPayments2 from './studentPayments2';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

// tabs panel
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// tabs option
const tabsOption = [
   
    {
        label: 'Student Details',
        icon: <DescriptionTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Class & Demo Details',
        icon: <AccountCircleTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Payment Details',
        icon: <LibraryBooksTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Attendance Details',
        icon: <MailTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    }
];

// ==============================|| PROFILE 1 ||============================== //

const StudentDetails = () => {
    const theme = useTheme();
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    //const { studentDetail } = location.state || {};

    const [studentsData, setStudentsData] = useState({})
    const [studentId, setStudentId] = useState(null)
    const [enquiryId, setEnquiryId] = useState(null)
    const [isLoading, setIsLoading] = useState([])


   
    

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (location.state) {
            setIsLoading(true)
          const { studentDetail } = location.state;
          setStudentsData(studentDetail)
          setStudentId(studentDetail.id)
          setEnquiryId(studentDetail.enquiry_id?.id)
          setIsLoading(false)
        }
      }, [location]);

      const fetchEnquiryData = async (studentId) => {
        setIsLoading(true)
        const apiUrl = `${API_ENDPOINT}route/getstudent/${studentId}`; // Adjust the API endpoint as needed
      
        try {
          const response = await fetch(apiUrl);
      
          if (!response.ok) {
            // Handle non-successful responses (e.g., 404 Not Found, 500 Internal Server Error)
            navigate('/page404')
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
          }
      
          const data = await response.json();
          setIsLoading(false)
       
          return data;
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false)
          navigate('/page404')
          throw new Error('Failed to fetch data');
        }
      };
  
      useEffect(() => {
      
        
        const fetchEnquiryInfo = async () => {
          try {
            // Replace 'fetchEnquiryData' with your actual API function
            const data = await fetchEnquiryData(id); // Assuming fetchEnquiryData takes a student ID as an argument
            setStudentsData(data);
            setStudentId(data.id)
           
            setIsLoading(false)
          } catch (error) {
            console.error('Error fetching enquiry data:', error);
            setIsLoading(false)
            // Handle error as needed
          }
        };
    
        if (id && (Object.keys(studentsData).length === 0 || studentsData.id !== id)) {
            fetchEnquiryInfo();
          }
      }, [id]);


    return (
        <>
        <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h2" color="error" fontWeight={800} marginRight={2}>Fetching</Typography>
        <CircularProgress color="primary" />
      </Backdrop>
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        sx={{
                            mb: 3,
                            '& a': {
                                minHeight: 'auto',
                                minWidth: 10,
                                py: 1.5,
                                px: 1,
                                mr: 2.25,
                                color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            },
                            '& a.Mui-selected': {
                                color: theme.palette.primary.main
                            },
                            '& .MuiTabs-indicator': {
                                bottom: 2
                            },
                            '& a > svg': {
                                marginBottom: '0px !important',
                                mr: 1.25
                            }
                        }}
                    >
                        {tabsOption.map((tab, index) => (
                            <Tab key={index} component={Link} to="#" icon={tab.icon} label={tab.label} {...a11yProps(index)} />
                        ))}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <StudentProfile studentDetail={studentsData} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <StudentClassDetails studentDetail={studentsData} studentId={studentId} enquiryId={enquiryId}/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <StudentPayments2 studentId={studentId}/>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <StudentAttendance studentId={studentId}/>
                    </TabPanel>
                  {/*}  <TabPanel value={value} index={3}>
                        <StudentTestDetails />
                        </TabPanel> */}
                    
                   
                </Grid>
            </Grid>
        </MainCard>
        </>
    );
};

export default StudentDetails;