import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import React, { useEffect, useState } from "react";
import ExpenseTable from "./ExpenseTable";
import ExpenseTab from "./ExpenseTab";
import ExpensePopup from "./ExpensePopup";
import ExpenseForm from "./ExpenseForm";
import { useGetTokenOrLogout } from "../../utils/token";
import { useNavigate } from "react-router-dom";
import HeadingCard from "../../newTestPrep/newComponent/NewCardsComp/HeadingCard";
import AddButton from "../../newTestPrep/newComponent/NewButtonComp/AddButton";
import CustomPopup from "../../components/CustomPopup";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function Expense() {
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [isLoading, setIsLoading] = useState(false);
  const [expenses, setExpenses] = useState([]);

  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleConfirmAction = () => {
    // Handle the action when the user clicks the confirm button
    console.log("Confirmed!");
    handleClosePopup();
  };

  useEffect(() => {
    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route/fetchexpense/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();

          const sortedData = data.sort(
            (a, b) => new Date(b.start_date) - new Date(a.start_date)
          );

          console.log(sortedData);
          setExpenses(sortedData);
          //setFilteredData(data);
          setIsLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch {
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId]);

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Box
        className="box"
        sx={{
          height: "1086px",
          width: "100%",
          position: "relative",
          color: "",
        }}
      >
        {/* Gradient Background */}
        <Box
          className="rectangle"
          sx={{
            background:
              "linear-gradient(248deg, #FFF6F0 -41.57%, rgba(255, 246, 240, 0.00) 97.88%)",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: -1,
          }}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} lg={2} md={3} sm={4}>
            <HeadingCard label="Expenses Track" />
          </Grid>
          <Grid item xs={12} lg={10} md={9} sm={8} textAlign="end">
            <AddButton label="Add Expense" onClick={handleOpenPopup} />
          </Grid>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <ExpenseTable data={expenses} />
          </Grid>

          <CustomPopup
            open={isPopupOpen}
            onClose={handleClosePopup}
            component={<ExpenseForm onClose={handleClosePopup} />}
            title="Add Expense"
            maxWidth="sm"
            showDivider={true}
          />
        </Grid>
      </Box>
    </>
  );
}
