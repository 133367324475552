import React, { useEffect, useState } from "react";
import MainCard from "../../components/ui-component/card/MainCard";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { fDate } from "../../../utils/formatTime";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterListTwoTone";
import PrintIcon from "@mui/icons-material/PrintTwoTone";
import FileCopyIcon from "@mui/icons-material/FileCopyTwoTone";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/AddTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PackageForm from "./PackageEditForm";
import PackagesFilter from "./PackagesFilter";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../../utils/token";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";
import AddButton from "../../newComponent/NewButtonComp/AddButton";
import FilterButtonComponent from "../../newComponent/NewButtonComp/FilterButtonComponent";
import SearchTextFieldComp from "../../newComponent/newtextfieldComp/SearchTextFieldComp";
import StyledTableContainer from "../../newComponent/TableContainer";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../newComponent/TableCustomeComp/CustomNoRowsOverlay";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function PackagesList() {
  const getTokenOrLogout = useGetTokenOrLogout();
  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const theme = useTheme();
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleApplyFilter = (criteria) => {
    setFilterCriteria(criteria);
  };
  const handleResetFilter = () => {
    setFilterCriteria(null);
    setPackages(originalData);
    setSearchData(originalData);
  };

  const [isFormOpen, setFormOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = getTokenOrLogout();
      if (!token) return;

      let headers = { Authorization: `Bearer ${token}` };
      try {
        setIsLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}route2.0/getpackages/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );

          let filteredData = [...sortedData];
          console.log(filteredData);
          if (filterCriteria) {
            filteredData = filteredData.filter((packageData) => {
              let passesFilter = true;

              if (filterCriteria && filterCriteria.packageName) {
                const Name = packageData?.package_name
                  ? packageData.package_name.toLowerCase()
                  : ""; // Check if name is null or undefined

                const filterString = filterCriteria.packageName.toLowerCase();

                passesFilter = passesFilter && Name.includes(filterString);
              }

              if (filterCriteria && filterCriteria.duration) {
                const filterString =
                  parseInt(filterCriteria.duration) === packageData?.duration;
                console.log(filterString);
                passesFilter = passesFilter && filterString;
              }

              if (filterCriteria.newTestType.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newTestType.includes(
                    packageData.test_type?.id
                  );
              }

              if (filterCriteria.newBranch.length > 0) {
                console.log("newBranch", packageData?.branch_id);
                passesFilter =
                  passesFilter &&
                  packageData?.branch_id?.some((branchId) =>
                    filterCriteria.newBranch.includes(branchId)
                  );
              }

              if (filterCriteria.newStatus.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newStatus.includes(packageData?.status);
              }

              return passesFilter;
            });
          }
          console.log(data);
          setPackages(filteredData);
          setOriginalData(data);
          setSearchData(filteredData);
          setIsLoading(false);
          setPage(0);
        } else if (response.status === 401) {
          setIsLoading(false);
          navigate("/page401");
        } else if (response.status === 403) {
          setIsLoading(false);
          navigate("/page403");
        } else if (response.status === 440) {
          setIsLoading(false);
          navigate("/page440");
        } else {
          setIsLoading(false);
          navigate("/page500");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId, filterCriteria]);

  const handleOpenForm = (packageData) => {
    setSelectedPackage(packageData);
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setSelectedPackage(null);
    setFormOpen(false);
  };

  const handleFormSubmit = () => {
    // Implement logic to handle form submission
    // You may want to update the data or make an API call
    handleCloseForm();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  console.log(filterCriteria);

  // Search by name
  // const handleNameChange = (e) => {
  //   const value = e.target.value;
  //   let filteredData1 = searchData.filter((data) =>
  //     data?.package_name
  //       ?.toLowerCase()
  //       .replace(/\s+/g, "")
  //       .includes(value.toLowerCase().replace(/\s+/g, ""))
  //   );
  //   setPackages(filteredData1);
  //   setPage(0);
  // };

  const columns = [
    {
      field: "Action",
      headerName: "action",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          <IconButton
            style={{ color: "#00b13f" }}
            size="small"
            aria-label="edit"
            onClick={() => handleOpenForm(params?.row)}
          >
            <Tooltip title="Edit">
              <EditIcon fontSize="small" style={{ fontSize: "0.8rem" }} />
            </Tooltip>
          </IconButton>
        </div>
      ),
    },
    {
      field: "package_name",
      headerName: "Package Name",
      width: 250,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row.package_name}</div>,
    },

    {
      field: "duration",
      headerName: "Duration(in session)",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row.duration}</div>,
    },
    {
      field: "test_type",
      headerName: "Test Type",
      width: 120,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row.test_type?.name || "",
      renderCell: (params) => <div>{params?.row.test_type?.name}</div>,
    },

    {
      field: "sub_test_type",
      headerName: "Sub Test Type",
      width: 120,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row.sub_test_type_id?.name || "",
      renderCell: (params) => <div>{params?.row.sub_test_type_id?.name}</div>,
    },

    {
      field: "branch",
      headerName: "Branch",

      headerClassName: "header-cell",
      valueGetter: (params) => params?.row.branch_id.join(", ") || "",
      renderCell: (params) => <div>{params?.row.branch_id.join(", ")}</div>,
    },
    {
      field: "original_price",
      headerName: "Original Price",

      headerClassName: "header-cell",

      renderCell: (params) => <div>{params?.row.original_price}</div>,
    },
    {
      field: "discounted_price",
      headerName: "Discounted Price",

      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row.discounted_price}</div>,
    },

    {
      field: "payable_installment",
      headerName: "Payable Installments",

      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row.payable_installment}</div>,
    },
    {
      field: "max_allow_day",
      headerName: "Max Allowable Days",

      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row.max_allow_day}</div>,
    },
    {
      field: "mock_tests",
      headerName: "Mock Tests",
      width: 70,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row.mock_tests}</div>,
    },

    {
      field: "module_wise_questions",
      headerName: "Module-Wise Tests",
      width: 70,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row.module_wise_questions}</div>,
    },

    {
      field: "question_wise_questions",
      headerName: "Question-Wise Tests",
      width: 70,
      headerClassName: "header-cell",
      renderCell: (params) => <div> {params?.row.question_wise_questions}</div>,
    },

    {
      field: "status",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row.status === "active" && (
            <Chip
              variant="contained"
              label="Active"
              size="small"
              color="secondary"
            />
          )}
          {params?.row.status === "inActive" && (
            <Chip
              variant="contained"
              label="Inactive"
              size="small"
              color="error"
            />
          )}
        </div>
      ),
    },
  ];

  // search all column in datagrid
  const handleNameChange = (e) => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, ""); // Normalize search value (lowercase and remove spaces)

    let filteredData1 = searchData.filter((data) => {
      // Check each field for a match with the search value
      const fieldsToSearch = [
        `${data.package_name}`,
        `${data.duration}`,
        `${data.test_type?.name}`,
        `${data.sub_test_type_id?.name}`,
        `${data?.branch_id}`,
        `${data?.original_price}`,
        `${data?.discounted_price}`,
        `${data?.payable_installment}`,
        `${data?.max_allow_day}`,
        `${data?.mock_tests}`,

        `${data?.module_wise_questions}`,
        `${data?.question_wise_questions}`,
      ];

      // Check if any of the fields contain the search value
      return fieldsToSearch.some((field) =>
        field.toLowerCase().replace(/\s+/g, "").includes(value)
      );
    });

    setPackages(filteredData1); // Update the filtered data
  };
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2.5} lg={2}>
          <HeadingCard label="Packages List" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          md={9.5}
          lg={10}
          sx={{ textAlign: "right" }}
          container
          gap={1}
          justifyContent="end"
        >
          {/* product add & dialog */}
          {(userRole === "center_admin" || userRole === "developer") && (
            <AddButton
              onClick={() => handleOpenForm(null)}
              label="Add Package"
            />
          )}

          <FilterButtonComponent
            onClick={handleToggle}
            showendIcon={true}
            open={open}
            text="Filter"
          >
            Filter
          </FilterButtonComponent>
        </Grid>
        <Grid item xs={12}>
          <PackagesFilter
            open={open}
            onApplyFilter={handleApplyFilter}
            onResetFilter={handleResetFilter}
          />
        </Grid>

        <PackageForm
          open={isFormOpen}
          handleClose={handleCloseForm}
          handleSubmit={handleFormSubmit}
          formData={selectedPackage}
        />
        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <SearchTextFieldComp
                    size="small"
                    onChange={handleNameChange}
                    InputProps={{
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                {/* table */}

                <DataGrid
                  rows={packages}
                  columns={columns}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[25, 50, 100]}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
}

export default PackagesList;
