import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Card, Grid, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';

// assets
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import PersonAddTwoToneIcon from '@mui/icons-material/PersonAddTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';

import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import ButtonComponent from '../../../../components/button/ButtonComponent';

import CancelIcon from '@mui/icons-material/Cancel';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import NewCardcomp from '../../../../components/card/NewCardcomp';



// ==============================|| SOCIAL PROFILE - FOLLOWER CARD ||============================== //

const TestTypeCard = ({ avatar, follow, location, name, is_active, onActivate, onDeactivate ,index}) => {
    const theme = useTheme();
    const userRole=localStorage.getItem("userRole")

    console.log("nameis" , index)
    const handleActivate = () => {
        if (onActivate) {
          onActivate();
        }
      };
    
      const handleDeactivate = () => {
        if (onDeactivate) {
          onDeactivate();
        }
      };
    
    return (
        
        <NewCardcomp
         hoverBgColor={index % 2 === 0 ? '#D5F6E1' : '#FFEDFB'} // Alternating hover colors
        hoverEffect={true}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        
                        <Grid item xs zeroMinWidth container gap={1} alignItems='center' justifyContent='center'>
                            {/* <img src='/test (2).png' width={20}/> */}
                            <Typography
                                variant="h3"
                               textAlign='center'
                               
                            >
                                {name}
                            </Typography>
                            
                        </Grid>
                       
                    </Grid>
                </Grid>
                <Grid item xs={12} textAlign='center'>
                    {is_active === 0 ? (
                        <Button  variant="contained" size='small' color='secondary' style={{height:'20px'}}  startIcon={<DoneOutlineIcon style={{fontSize:"0.8rem"}}/>} onClick={handleActivate} >
                            Make Active
                        </Button>
                       
                    ) : (
                        userRole==="center_admin" && (
                        <Button variant="contained" size='small' color='error' style={{height:'20px'}}  startIcon={<CancelIcon style={{fontSize:"0.8rem"}}/>} onClick={handleDeactivate}>
                            Make InActive
                        </Button>
                        )
                    )}
                </Grid>
            </Grid>
        </NewCardcomp>
    );
};



export default TestTypeCard;