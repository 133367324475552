import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, CircularProgress, Grid } from "@mui/material";
import RoomScheduleTable from "./RoomScheduleTable";
import CustomSnackbar from "../../../../components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function ClassTabs({ branchRooms, branchId }) {
  const [value, setValue] = useState(0);
  const [selectedRoomData, setSelectedRoomData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const branch_Id = branchId;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      if (branchRooms.length > 0) {
        const firstRoomId = branchRooms[0].id;
        const firstRoomData = await fetchDataForRoom(firstRoomId, branch_Id);
        setSelectedRoomData(firstRoomData);
      }
    };

    fetchInitialData();
  }, [branchRooms]);

  /*const handleChange = async (event, newValue) => {
    setValue(newValue);
    setIsLoading(true);
    setSelectedRoomData([]); 

    const selectedRoom = branchRooms[newValue];
    const roomId = selectedRoom.id; 
    const branch_Id = branchId;

    try {
      // Make a POST request with the roomId
      const response = await fetch(`${API_ENDPOINT}route/slotbyroombranch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ room_id: roomId, branch_id: branch_Id}),
      });

      if (response.status === 403) {
        //throw new Error('Network response was not ok');
       // setSelectedRoomData([])
       showSnackbar('No Classes found in this room', 'error');

      }

      const data = await response.json();
      // Store the fetched data in state
      setSelectedRoomData(data);
    } catch (error) {
        if (error.message.includes('401')) {
            showSnackbar('Unauthorized to fetch classes', 'error');
        } else if (error.message.includes('403')) {
          showSnackbar('No Classes found in this room', 'error');
        }  else {
          console.error('Error fetching student data:', error);
          //showSnackbar('Error fetching classes data:', 'error');
          setIsLoading(false);
        }
    } finally {
      setIsLoading(false);
    }
  };*/

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    setIsLoading(true);
    setSelectedRoomData([]);

    const selectedRoom = branchRooms[newValue];
    const roomId = selectedRoom.id;
    const branch_Id = branchId;

    try {
      const data = await fetchDataForRoom(roomId, branch_Id);
      setSelectedRoomData(data);
    } catch (error) {
      handleFetchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataForRoom = async (roomId, branchId) => {
    try {
      const response = await fetch(`${API_ENDPOINT}route/slotbyroombranch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ room_id: roomId, branch_id: branchId }),
      });

      if (response.status === 403) {
        showSnackbar("No Classes found in this room", "error");
        return [];
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

  const handleFetchError = (error) => {
    if (error.message.includes("401")) {
      showSnackbar("Unauthorized to fetch classes", "error");
    } else if (error.message.includes("403")) {
      showSnackbar("No Classes found in this room", "error");
    } else {
      console.error("Error fetching student data:", error);
    }
  };

  return (
    <>

<Grid container>
  <Grid item xs={12} container justifyContent='center'>
      <Tabs
        value={value}
        onChange={handleChange}
        // variant='contained'
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          minHeight: "3px", // You can adjust the height of the tabs here
        }}
      >
        {branchRooms.map((room, index) => (
          <Tab
            key={index}
            label={`View ${room.name} slots ${room.teaching_mode}`}
            sx={{
              fontSize: "0.7rem",
              padding: "4px 12px", // Adjust padding to make the tabs smaller
              "&.MuiTab-root": {
                minHeight: "20px", // Set the height of the tab root element directly
              },
              "&.Mui-selected": {
                backgroundColor: "green",
                color: "white",
              },
              "&:not(.Mui-selected)": {
                backgroundColor: "red",
                color: "white",
              },
              margin: "0px 8px",
            }}
          />
        ))}
      </Tabs>
      </Grid>

      {/* Display circular progress while loading */}
      {isLoading && <CircularProgress color="primary" />}

<Grid item xs={12}>
      {/* Pass the selected room data to RoomScheduleTable */}
      {!isLoading && selectedRoomData && (
        <RoomScheduleTable
          key={value}
          roomName={branchRooms[value]?.name}
          roomTeachingMode={branchRooms[value]?.teaching_mode}
          data={selectedRoomData}
          roomId={branchRooms[value]?.id}
          branchId={branch_Id}
        />
      )}
      </Grid>
</Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
