import React, { useState } from "react";
import StyledTableContainer from "../../newComponent/TableContainer";
import {
  Backdrop,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AprroveEnquiryData({ selectedEnquiryData }) {
  const userId = localStorage.getItem("userId");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleViewdetails = async (enquiry_id, class_id, package_id) => {
    console.log(
      "enquiry_id=",
      enquiry_id,
      "package_id=",
      package_id,
      "class_id=",
      class_id
    );

    setDetailsLoading(true);

    const requestData = {
      class_id: class_id,
      package_id: package_id,
      enquiry_id: enquiry_id,
      user_id: parseInt(userId),
    };
    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/showenqforaccount`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        const result = await response.json();
        setDetailsData(result);
        setDetailsLoading(false);
      } else if (response.status === 401) {
        // Handle errors, e.g., show an error message
        showSnackbar("Please Verify that You are a User!!", "error");
        setDetailsLoading(false);
      } else if (response.status === 403) {
        showSnackbar("Unathorized User", "error");
        setDetailsLoading(false);
      } else {
        showSnackbar("Internal  Server Error", "error");
        setDetailsLoading(false);
      }
    } catch (error) {
      showSnackbar("Internal  Server Error", "error");
      setDetailsLoading(false);
    }
  };

  return (
    <>
      <Backdrop open={detailsLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledTableContainer
            component={Paper}
            sx={{ borderRadius: "15px", padding: "10px" }}
          >
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Enquiry Name</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Collected By</TableCell>
                  {/* <TableCell
           
          >
            Branch
          </TableCell> */}
                  <TableCell>Branch</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedEnquiryData.map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#f0f0f0" : "inherit",
                        width: "6%",
                      }}
                    >
                      <TableCell
                        sx={{
                          color: "#46b1ff",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleViewdetails(
                            row?.enquiry_id?.id,
                            row?.class_id,
                            row?.package_id
                          )
                        }
                      >
                        {row?.enquiry_id?.first_name}
                      </TableCell>

                      <TableCell> {row?.enquiry_id?.mobile}</TableCell>

                      <TableCell> {row?.amount_paid}</TableCell>
                      <TableCell>
                        {" "}
                        {row?.collected_by?.first_name}{" "}
                        {row?.collected_by?.last_name}
                      </TableCell>

                      <TableCell>
                        {row?.enquiry_id?.branch_id?.branch_name}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
        <Grid item xs={12}>
          {detailsData !== null && (
            <>
              <Typography variant="h4" mb={2}>
                Installments Details
              </Typography>
              <Grid container gap={{xs:2, sm:3}}>
              <Typography variant="h5">
                {" "}
                Name :{" "}
                <strong>
                  {detailsData?.enquiry_name?.first_name}{" "}
                  {detailsData?.enquiry_name?.last_name}
                </strong>
              </Typography>
              <Typography variant="h5" >
                {" "}
                Package : <strong>{detailsData?.package_name} </strong>
              </Typography>
              <Typography variant="h5" >
                Amount Of Package :{" "}
                <strong>₹ {detailsData?.amount_of_package} </strong>
              </Typography>
              <Typography variant="h5">
                Amount To Be Paid :{" "}
                <strong>₹ {detailsData?.amount_to_paid}</strong>
              </Typography>
              <Typography variant="h5" >
                Discount Amount :{" "}
                <strong>₹ {detailsData?.discount_amount} </strong>
              </Typography>
              </Grid>
              <StyledTableContainer
                component={Paper}
                style={{
                  maxWidth: "100%",
                  overflow: "auto",
                  marginTop: "20px",
                }}
              >
                <Table
                  aria-label="simple table"
                  stickyHeader
                  style={{ minWidth: 700 }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ minWidth: 80 }}>Sr. No</TableCell>
                      <TableCell>Amount Collected</TableCell>
                      <TableCell>Installment</TableCell>
                      <TableCell>Payment Mode</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Payment Date</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Approved By</TableCell>
                      <TableCell>Approved Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detailsData?.payment?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row?.sl_no}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {row?.amount_collected} /-
                        </TableCell>

                        <TableCell>{row?.installment}</TableCell>
                        <TableCell>{row?.payment_mode?.payment_mode}</TableCell>
                        <TableCell>
                          {row?.status === "pending" && (
                            <Chip
                              size="small"
                              variant="contained"
                              label="Pending"
                              sx={{ background: "#FFAC1C" }}
                            />
                          )}
                          {row?.status === "confirmed" && (
                            <Chip
                              size="small"
                              variant="contained"
                              label="Approved"
                              color="secondary"
                            />
                          )}
                          {row?.status === "failed" && (
                            <Chip
                              size="small"
                              variant="contained"
                              label="Failed "
                              color="error"
                            />
                          )}
                          {row?.status === "cancelled" && (
                            <Chip
                              size="small"
                              variant="contained"
                              label="Cancelled  "
                              color="error"
                            />
                          )}
                        </TableCell>
                        <TableCell>{row?.payment_date}</TableCell>
                        <TableCell>
                          {row?.collected_by?.first_name}{" "}
                          {row?.collected_by?.last_name}
                        </TableCell>
                        <TableCell>
                          {row?.approved_by?.first_name}{" "}
                          {row?.approved_by?.last_name}
                        </TableCell>
                        <TableCell>{row?.approved_date} </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </>
          )}
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
