// Author : Ayesha Shaikh
//  Date : 18 sep 2024
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Switch,
} from "@mui/material";
import React, { useState } from "react";
import MainCard from "../../../components/Maincard";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useGetTokenOrLogout } from "../../../utils/token";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";
import CustomAutocompleteComp from "../../newComponent/AutocompleteComp";
import CloseIcon from "@mui/icons-material/Close";
import SubmitButton from "../../newComponent/NewButtonComp/SubmitButton";
import SecondaryButton from "../../newComponent/NewButtonComp/SubmitButton";
import is from "date-fns/esm/locale/is/index.js";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function AddEmployeeForm() {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const today = new Date().toISOString().split("T")[0];
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const [codeList, setCodeList] = useState([]);
  const [BranchList, setBranchList] = useState([]);
  const [branchName, setBranchName] = useState(null);

  const [roleList, setRoleList] = useState([]);
  const [role, setrole] = useState([]);
  const [selectedRoleName, setSelectedRoleName] = useState([]);

  const [testTypeList, setTestTypeList] = useState([]);
  const [selectedTestType, setselectTestType] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [service, setService] = useState([]);
  const [subServiceList, setSubserviceList] = useState([]);
  const [subService, setSubService] = useState([]);
  const [workBranch, setWorkBranch] = useState([]);
  const [userList, setUserList] = useState([]);

  const [branchManager, setBranchManager] = useState([]);
  const [reportingManagerList, setReportingManagerList] = useState([]);
  const [reportingManager, setReportingManager] = useState([]);
  const [reportUser, setReportUser] = useState([]);
  const [reportUser1, setReportUser1] = useState([]);
  const [reportUsers, setReportUsers] = useState([{ id: 1, value: "" }]);
  const [branchHandled, setBranchhandled] = useState([]);
  const [backupUser, setBackupUser] = useState(null);
  const [isAutoAssign, setIsautoassign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAutoSendReport, setIsAutoSendReport] = useState(false);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const ccField = reportUser.map((i) => i.email);
  const ccField1 = reportUser1.map((i) => i.email);

  const onSubmit = async (data) => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const requestData = {
        ...data,
        role: role.map((i) => i.id),
        branch: branchName?.id,
        branch_manager: branchManager?.first_name,
        reporting_manager: reportingManager.map((i) => i.id),
        add_report_cc_user: [...ccField, ...ccField1],
        user_id: parseInt(userId),
      };
      const response = await fetch(`${API_ENDPOINT}employee`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });
      if (response.ok) {
        showSnackbar("Employee Added Successfully", "success");

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else if (response.status === 409) {
        showSnackbar("Email already exists", "error");
      } else if (response.status === 404) {
        showSnackbar("Server Error", "error");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}country`);
        if (response.ok) {
          const data = await response.json();
          setCountryList(data);
          const sortedData = [...data].sort(
            (a, b) => a.phone_code - b.phone_code
          );
          const uniqueArray = sortedData.filter(
            (obj, index, self) =>
              index === self.findIndex((t) => t.phone_code === obj.phone_code)
          );

          setCodeList(uniqueArray);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          setBranchList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}role`);
        if (response.ok) {
          const data = await response.json();
          const filterdData = data.filter(
            (i) => i.role_name !== "center_admin"
          );
          setRoleList(filterdData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          setServiceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          const serviceIds = service.map((i) => i.id);
          const filterdSubservice = data.filter((i) =>
            serviceIds.includes(i.service_id)
          );
          setSubserviceList(filterdSubservice);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [service]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setUserList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}reportingmanager`);
        if (response.ok) {
          const data = await response.json();
          setReportingManagerList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}testtype`);
        if (response.ok) {
          const data = await response.json();
          setTestTypeList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleBranchChange = (event, value) => {
    setBranchName(value);
    setValue("branch", value);
  };

  useEffect(() => {
    const value = branchName?.admin_id?.user_id;
    const defaultBranchManager = userList.find((i) => i.id === value);
    setBranchManager(defaultBranchManager);
  }, [branchName]);

  const handleRoleChange = (event, value) => {
    setrole(value);
    const selectedRoleName = value.map((i) => i.role_name);
    setSelectedRoleName(selectedRoleName);
    setValue("role", value);
  };

  useEffect(() => {
    if (!selectedRoleName.includes("advisor")) {
        setValue("is_send_auto_report", false);
    }
}, [selectedRoleName, setValue]);

  const handleExpertiseChange = (event, value) => {
    setselectTestType(value);
    const selectedTestTypeIds = value.map((i) => i.id);
    setValue("trainer_expertise", selectedTestTypeIds);
  };
  const handleCountryChange = (event, value) => {
    setCountry(value);
  };

  const handleServiceChange = (event, value) => {
    setService(value);
    const selectedids = value.map((i) => i.id);
    setValue("service_id", selectedids);
  };

  const handleSubServiceChange = (event, value) => {
    setSubService(value);
    const selectedids = value.map((i) => i.id);
    setValue("sub_service_id", selectedids);
  };

  const handleBranchManagerChange = (event, value) => {
    setBranchManager(value);
  };

  const handleBackupUserchange = (event, value) => {
    setBackupUser(value);

    setValue("backup_user", value?.id);
  };

  const handleWorkbranch = (event, value) => {
    setBranchhandled(value);
    const selectedeId = value.map((i) => i.id);
    setValue("handling_branch_id", selectedeId);
  };
  const handlereportManagerChange = (event, value) => {
    setReportingManager(value);
    setValue("reporting_manager", value);
  };

  const alluserList = [{ first_name: "Other", email: null }, ...userList];
  const handlereportCCChange = (event, value) => {
    setReportUser(value);
  };

  const today1 = new Date();
  const birthDate = new Date("02-06-2002");
  const age = today1.getFullYear() - birthDate.getFullYear();

  const selectedccUser = reportUser.map((i) => i.first_name);
  const handlereportCCChange1 = (index, event) => {
    const newReportUsers = [...reportUsers];
    newReportUsers[index].value = event.target.value;
    setReportUsers(newReportUsers);
  };

  //  Add More cc Field
  const handleAddMore = () => {
    setReportUsers([...reportUsers, { id: reportUsers.length + 1, value: "" }]);
  };

  // Removed TextField  cc
  const handleRemoveField = (index) => {
    const newReportUsers = reportUsers.filter((_, i) => i !== index);
    // Ensure there's always at least one field
    if (newReportUsers.length === 0) {
      setReportUsers([{ id: 1, value: "" }]);
    } else {
      setReportUsers(newReportUsers);
    }
  };

  const handleChangestatus = (event) => {
    setIsautoassign(event.target.checked);
    setValue("is_auto_assigner", event.target.checked);
  };

  const handleChangeAutoSendstatus = (event) => {
    setIsAutoSendReport(event.target.checked);
    setValue("is_send_auto_report", event.target.checked);
  };

  return (
    <>
      <Grid container spacing={2}>
        {/* Employee Name  */}
        <Grid item xs={12} lg={4} md={6} sm={6}>
          <Typography variant="h6">
            First Name{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "0.7em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="first_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextFieldComp
                size="normal"
                {...field}
                InputProps={{
                  style: { borderRadius: "10px", height: "37px" },
                }}
                fullWidth
                error={errors.first_name}
                helperText={errors.first_name && "First name is required"}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={4} md={6} sm={6}>
          <Typography variant="h6">Last Name </Typography>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <CustomTextFieldComp
                size="normal"
                {...field}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                fullWidth
              />
            )}
          />
        </Grid>

        {/* Email */}
        <Grid item xs={12} lg={4} md={6} sm={6}>
          <Typography variant="h6">
            Email{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "0.7em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Please enter a valid email address",
              },
            }}
            render={({ field }) => (
              <CustomTextFieldComp
                size="normal"
                {...field}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                fullWidth
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
              />
            )}
          />
        </Grid>

        {/* Mobile */}
        <Grid item xs={12} lg={4} md={6} sm={6}>
          <Grid container>
            <Grid item xs={12} lg={4} md={4} sm={6}>
              <Typography variant="h6">ISD Code </Typography>
              <Controller
                name="mobile_country_code"
                control={control}
                defaultValue={91}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    {...field}
                    size="normal"
                    select
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        disableScrollLock: true,
                        sx: { maxHeight: "50%" },
                      },
                    }}
                  >
                    {codeList.map((code) => (
                      <MenuItem key={code?.phone_code} value={code?.phone_code}>
                        {code?.phone_code}
                      </MenuItem>
                    ))}
                  </CustomTextFieldComp>
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} lg={8} md={8} sm={6}>
              <Typography variant="h6">Mobile No </Typography>
              <Controller
                name="mobile"
                control={control}
                rules={{
                  pattern: {
                    value: /^[0-9]{10}$/, // Example regex for 10-digit mobile numbers
                    message: "Please enter a valid 10-digit mobile number",
                  },
                }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    {...field}
                    fullWidth
                    size="normal"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={!!errors.mobile}
                    helperText={errors.mobile && errors.mobile.message}
                  />
                )}
              ></Controller>
            </Grid>
          </Grid>
        </Grid>

        {/* Gender */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <Typography variant="h6">Gender </Typography>
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <CustomTextFieldComp
                {...field}
                fullWidth
                size="normal"
                select
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </CustomTextFieldComp>
            )}
          ></Controller>
        </Grid>

        {/*Date of Birth */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <Typography variant="h6">Date of Birth </Typography>
          <Controller
            name="dob"
            control={control}
            rules={{
              validate: (value) => {
                const today = new Date();
                const birthDate = new Date(value);
                const age = today.getFullYear() - birthDate.getFullYear();
                const monthDiff = today.getMonth() - birthDate.getMonth();
                const dayDiff = today.getDate() - birthDate.getDate();

                // Check if user is less than 18 years old
                if (
                  age < 18 ||
                  (age === 18 &&
                    (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)))
                ) {
                  return "You must be at least 18 years old";
                }
                return true;
              },
            }}
            render={({ field }) => (
              <CustomTextFieldComp
                {...field}
                fullWidth
                size="normal"
                type="date"
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                error={!!errors.dob}
                helperText={errors.dob ? errors.dob.message : ""}
              />
            )}
          >
            {" "}
          </Controller>
        </Grid>

        <Grid item xs={12} lg={4} md={3} sm={4}>
          <Typography variant="h6">Date of Joining </Typography>
          <Controller
            name="joining_date"
            control={control}
            defaultValue={today}
            render={({ field }) => (
              <CustomTextFieldComp
                {...field}
                fullWidth
                size="normal"
                type="date"
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            )}
          ></Controller>
        </Grid>

        {/* Designation */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <Typography variant="h6">
            Designation{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "0.7em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="designation"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextFieldComp
                {...field}
                fullWidth
                size="normal"
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                error={errors.designation}
                helperText={errors.designation && "Designation is required"}
              />
            )}
          ></Controller>
        </Grid>

        {/* Select Role */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <FormControl fullWidth error={errors.role}>
            <Typography variant="h6">
              Select Role{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "0.7em",
                }}
              >
                *
              </span>
            </Typography>
            <Controller
              name="role"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomAutocompleteComp
                  {...field}
                  size="small"
                  multiple
                  disablePortal
                  value={role}
                  onChange={handleRoleChange}
                  options={roleList}
                  getOptionLabel={(option) => option.role_name}
                  tooltipContent={(option) => option.role_name}
                />
              )}
            ></Controller>
            {errors.role && <FormHelperText>Role is required</FormHelperText>}
          </FormControl>
        </Grid>

        {/* Trainer Expertises*/}
        {selectedRoleName.includes("trainer") && (
          <Grid item xs={12} lg={4} md={3} sm={4}>
            <FormControl fullWidth error={errors.trainer_expertise}>
              <Typography variant="h6">
                Select Trainer Expertices{" "}
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "0.7em",
                  }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="trainer_expertise"
                control={control}
                rules={{
                  required: selectedRoleName.includes("trainer") ? true : false,
                }}
                render={({ field }) => (
                  <CustomAutocompleteComp
                    {...field}
                    size="small"
                    multiple
                    disablePortal
                    value={selectedTestType}
                    onChange={handleExpertiseChange}
                    options={testTypeList}
                    getOptionLabel={(option) => option.name}
                    tooltipContent={(option) => option.name}
                  />
                )}
              ></Controller>
              {errors.trainer_expertise && (
                <FormHelperText>Trainer Expertise is required</FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
  {selectedRoleName.includes("advisor") && (
      <Grid item xs={12} lg={4} md={3} sm={4} container gap={1} alignItems="center">
          <Typography variant="h5">Include in Email Report to Seniors</Typography>
          <Controller
            name="is_send_auto_report"
            control={control}
            // rules={{ required: true }}
            render={({ field }) => (
              <Switch
                {...field}
                checked={isAutoSendReport}
                onChange={handleChangeAutoSendstatus}
                size="small"
                color="secondary"
                sx={{
                  "& .MuiSwitch-switchBase": {
                    padding: 1,
                    height: 1,
                  },
                  "& .MuiSwitch-thumb": {
                    width: 12, // Thumb size
                    height: 12,
                    background: isAutoSendReport ? "#00b13f" : "#f44336", // Green when active, Red when inactive
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: isAutoSendReport ? "#e0f7e0" : "#ff9fad", // Light green for active, light red for inactive
                    opacity: 1, // Ensure visibility
                  },
                }}
              />
            )}
          ></Controller>
        </Grid>
      )}

        {/* Branch  */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <FormControl fullWidth error={errors.branch}>
            <Typography variant="h6">
              Branch{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "0.7em",
                }}
              >
                *
              </span>
            </Typography>

            <Controller
              name="branch"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomAutocompleteComp
                  {...field}
                  size="small"
                  disablePortal
                  value={branchName}
                  onChange={handleBranchChange}
                  options={BranchList}
                  getOptionLabel={(option) => option.branch_name}
                />
              )}
            ></Controller>
            {errors.branch && (
              <FormHelperText>Branch is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Branch  Manager   */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <FormControl fullWidth>
            <Typography variant="h6">Branch Manager </Typography>
            <Controller
              name="branch_manager"
              control={control}
              render={({ field }) => (
                <CustomAutocompleteComp
                  {...field}
                  size="small"
                  disablePortal
                  value={branchManager}
                  onChange={handleBranchManagerChange}
                  options={userList}
                  getOptionLabel={(option) =>
                    `${option.first_name ? option.first_name : " "} ${
                      option.last_name ? option.last_name : " "
                    }`
                  }
                />
              )}
            ></Controller>{" "}
          </FormControl>
        </Grid>

        {/* Reporting  Manager  */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <Typography variant="h6">
            Reporting Manager{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "0.7em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="reporting_manager"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomAutocompleteComp
                {...field}
                size="small"
                multiple
                disablePortal
                value={reportingManager}
                onChange={handlereportManagerChange}
                options={reportingManagerList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : " "} ${
                    option.last_name ? option.last_name : " "
                  }`
                }
                error={errors.reporting_manager}
                helperText={
                  errors.reporting_manager && "Reporting manager is required"
                }
                tooltipContent={(option) =>
                  `${option.first_name ? option.first_name : " "} ${
                    option.last_name ? option.last_name : " "
                  }`
                }
              />
            )}
          ></Controller>
        </Grid>

        {/* Work On Country */}
        {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Work On Country{" "}
                </Typography>

                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  value={country}
                  onChange={handleCountryChange}
                  options={countryList}
                  getOptionLabel={(option) => option.country_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

        {/* Work On Service */}
        {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Work On Service{" "}
                </Typography>
               
                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  value={service}
                  onChange={handleServiceChange}
                  options={serviceList}
                  getOptionLabel={(option) => option.service_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {  borderRadius: "10px",  }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

        {/* Work On SubService */}
        {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Work On SubService{" "}
                </Typography>
                
                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  value={subService}
                  onChange={handleSubServiceChange}
                  options={subServiceList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {  borderRadius: "10px",  }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

        {/*  Branch Type */}
        {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Branch Type{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  InputProps={{
                    style: {
                       borderRadius: "10px",
                    },
                  }}
                />
              </Grid> */}

        {/*Work On Branch */}
        {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Work On Branch{" "}
                </Typography>
                
                  
                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  value={workBranch}
                  onChange={handleWorkBranchChange}
                  options={BranchList}
                  getOptionLabel={(option) => option.branch_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {  borderRadius: "10px",  }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

        {/*Report CC User */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <Typography variant="h6">Select Report CC User </Typography>
          <Controller
            name="add_report_cc_user"
            control={control}
            render={({ field }) => (
              <CustomAutocompleteComp
                {...field}
                size="small"
                disablePortal
                multiple
                value={reportUser}
                onChange={handlereportCCChange}
                options={alluserList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : " "} ${
                    option.last_name ? option.last_name : " "
                  } ${option.email ? `:- ${option.email}` : " "}`
                }
                tooltipContent={(option) =>
                  `${option.first_name ? option.first_name : " "} ${
                    option.last_name ? option.last_name : " "
                  } ${option.email ? `:- ${option.email}` : " "}`
                }
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      {`${option.first_name ? option.first_name : " "} ${
                        option.last_name ? option.last_name : " "
                      } ${option.email ? `:- ${option.email}` : " "}`}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "14px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            )}
          ></Controller>
        </Grid>
        {selectedccUser.includes("Other") &&
          reportUsers.map((reportUser, index) => (
            <React.Fragment key={reportUser.id}>
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">Add Report CC User</Typography>
                <Controller
                  name={`add_report_cc_user_${reportUser.id}`}
                  control={control}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      {...field}
                      fullWidth
                      size="normal"
                      value={reportUser.value}
                      onChange={(e) => handlereportCCChange1(index, e)}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                        endAdornment: (
                          <InputAdornment position="end" fontSize="small">
                            <IconButton
                              fontSize="small"
                              style={{ fontSize: "0.7rem" }}
                              edge="end"
                              onClick={() => handleRemoveField(index)}
                            >
                              <CloseIcon style={{ fontSize: "1rem" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              {index === reportUsers.length - 1 && (
                <Grid item>
                  <Typography sx={{ visibility: "hidden" }}>
                    Spacing Purpose
                  </Typography>

                  <SecondaryButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    label="Add More"
                    onClick={handleAddMore}
                  >
                    Add More
                  </SecondaryButton>
                </Grid>
              )}
            </React.Fragment>
          ))}

        {/* Auto assign  */}
        <Grid item xs={12} container gap={1} alignItems="center">
          <Typography variant="h5">Auto Assign Lead </Typography>
          <Controller
            name="is_auto_assigner"
            control={control}
            // rules={{ required: true }}
            render={({ field }) => (
              <Switch
                {...field}
                checked={isAutoAssign}
                onChange={handleChangestatus}
                size="small"
                color="secondary"
                sx={{
                  "& .MuiSwitch-switchBase": {
                    padding: 1,
                    height: 1,
                  },
                  "& .MuiSwitch-thumb": {
                    width: 12, // Thumb size
                    height: 12,
                    background: isAutoAssign ? "#00b13f" : "#f44336", // Green when active, Red when inactive
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: isAutoAssign ? "#e0f7e0" : "#ff9fad", // Light green for active, light red for inactive
                    opacity: 1, // Ensure visibility
                  },
                }}
              />
            )}
          ></Controller>
        </Grid>

        {isAutoAssign && (
          <>
            {/* Service Field  */}

            <Grid item xs={12} lg={4} md={3} sm={4}>
              <Typography variant="h6">
                Service{" "}
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "0.7em",
                  }}
                >
                  *
                </span>
              </Typography>

              <Controller
                name="service_id"
                control={control}
                rules={{ required: isAutoAssign ? true : false }}
                render={({ field }) => (
                  <CustomAutocompleteComp
                    {...field}
                    size="small"
                    multiple
                    disablePortal
                    value={service}
                    onChange={handleServiceChange}
                    options={serviceList}
                    getOptionLabel={(option) => option.service_name}
                    error={errors.service_id}
                    helperText={errors.service_id && "Service is required"}
                    tooltipContent={(option) => option.service_name}
                  />
                )}
              ></Controller>
            </Grid>

            {/* Subservice  */}
            {service.length > 0 && (
              <Grid item xs={12} lg={4} md={3} sm={4}>
                <Typography variant="h6">
                  Sub Service{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "0.7em",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Controller
                  name="sub_service_id"
                  control={control}
                  rules={{ required: isAutoAssign ? true : false }}
                  render={({ field }) => (
                    <CustomAutocompleteComp
                      {...field}
                      size="small"
                      multiple
                      disablePortal
                      value={subService}
                      onChange={handleSubServiceChange}
                      options={subServiceList}
                      getOptionLabel={(option) => option.name}
                      error={errors.sub_service_id}
                      helperText={
                        errors.sub_service_id && "Sub Service is required"
                      }
                      tooltipContent={(option) => option.name}
                    />
                  )}
                ></Controller>
              </Grid>
            )}

            {/* Branch Handled */}
            <Grid item xs={12} lg={4} md={3} sm={4}>
              <Typography variant="h6">
                Branch Handled{" "}
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "0.7em",
                  }}
                >
                  *
                </span>
              </Typography>

              <Controller
                name="handling_branch_id"
                control={control}
                rules={{ required: isAutoAssign ? true : false }}
                render={({ field }) => (
                  <CustomAutocompleteComp
                    {...field}
                    size="small"
                    multiple
                    disablePortal
                    value={branchHandled}
                    onChange={handleWorkbranch}
                    options={BranchList}
                    getOptionLabel={(option) => option.branch_name}
                    error={errors.handling_branch_id}
                    helperText={
                      errors.handling_branch_id && "Branch is required"
                    }
                    tooltipContent={(option) => option.branch_name}
                  />
                )}
              ></Controller>
            </Grid>

            {/* Backup Field */}
            <Grid item xs={12} lg={4} md={3} sm={4}>
              <Typography variant="h6">
                Backup User{" "}
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "0.7em",
                  }}
                >
                  *
                </span>
              </Typography>

              <Controller
                name="backup_user"
                control={control}
                rules={{ required: isAutoAssign ? true : false }}
                render={({ field }) => (
                  <CustomAutocompleteComp
                    {...field}
                    size="small"
                    disablePortal
                    value={backupUser}
                    onChange={handleBackupUserchange}
                    options={userList}
                    getOptionLabel={(option) =>
                      `${option.first_name ? option.first_name : " "} ${
                        option.last_name ? option.last_name : " "
                      }`
                    }
                    error={errors.backup_user}
                    helperText={errors.backup_user && "Backup User is required"}
                    tooltipContent={(option) =>
                      `${option.first_name ? option.first_name : " "} ${
                        option.last_name ? option.last_name : " "
                      }`
                    }
                  />
                )}
              ></Controller>
            </Grid>
          </>
        )}

        <Grid item xs={12} textAlign="end">
          <SecondaryButton
            variant="contained"
            color="secondary"
            label="Submit"
            onClick={handleSubmit(onSubmit)}
          />
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
