import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MainCard from "../../components/ui-component/card/MainCard";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TableContainer,
  TextField,
  Typography,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { fDate } from "../../../utils/formatTime";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import FilterComp from "../../newComponent/iconsComp/FilterComp";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";
import CustomAutocompleteComp from "../../newComponent/AutocompleteComp";
import ApplyFilterButton from "../../newComponent/NewButtonComp/ApplyFilterButton";
import ResetFilterButton from "../../newComponent/NewButtonComp/ResetFilterButton";
import StyledTableContainer from "../../newComponent/TableContainer";
import CustomNoRowsOverlay from "../../newComponent/TableCustomeComp/CustomNoRowsOverlay";
import VeiwIcon from "../../newComponent/iconsComp/VeiwIcon";
import EditIconcomp from "../../newComponent/iconsComp/EditIconcomp";
import SearchTextFieldComp from "../../newComponent/newtextfieldComp/SearchTextFieldComp";
//import Chip from "../../components/ui-component/chip/Chip";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function ViewAttendance() {
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDialogOpen2, setDialogOpen2] = useState(false);

  const [studentData, setStudentData] = useState([]);
  const [selectedStudentAttendance, setSelectedStudentAttendance] = useState(
    []
  );
  const [branchlist, setbranchlist] = useState([]);
  const [trainerlist, settrainerlist] = useState([]);
  const [selectedBranchIds, setSelectedBranchIds] = useState([]);
  const [trainerid, setselectedtrainerid] = useState([]);
  const [classlist, setclasslist] = useState([]);
  const [statusOptions, setStatusOptions] = useState([
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ]);

  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [enrollmentDateFrom, setEnrollmentDateFrom] = useState("");
  const [enrollmentDateTo, setEnrollmentDateTo] = useState("");
  const [branch, setBranch] = useState([]);
  const [trainerName, setTrainerName] = useState([]);
  const [className, setClassName] = useState([]);
  const [status, setStus] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [studentRowId, setStudentRowId] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [originalData, setOriginalData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const toggleFilterSection = () => {
    setFilterOpen(!isFilterOpen);
  };

  const allBranchList = [{ branch_name: "Select All" }, ...branchlist];
  const handleBranchChange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  const allTrainerList = [{ first_name: "Select All" }, ...trainerlist];

  const handleChangeTrainer = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setTrainerName(trainerlist);
    } else {
      setTrainerName(value);
    }
  };

  const allClassListList = [{ name: "Select All" }, ...classlist];

  const handleChangeClass = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      setClassName(classlist);
    } else {
      setClassName(value);
    }
  };

  const handleApplyFilter = async (event) => {
    setIsLoading(true);

    setPage(0);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      branch_id: branch.map((i) => i.id),
      trainer_id: trainerName.map((i) => i.id),
      class_id: className.map((i) => i.id),
      status: status.map((i) => i.value),
      user_id: parseInt(userId),
      from_date: enrollmentDateFrom || null,
      to_date: enrollmentDateTo || null,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/attendancefilter`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.length === 0) {
          showSnackbar("No data Available", "error");
          setIsLoading(false);
        }

        setStudentData(result);
        setSearchData(result);

        setIsLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/page500");
    }
  };

  const resetFilters = () => {
    setBranch([]);
    setClassName([]);
    setTrainerName([]);
    setStus([]);
    setEnrollmentDateFrom("");
    setEnrollmentDateTo("");
    setStudentData(originalData);
    setSearchData(originalData);
  };

  function handleEditAttendance(date, status) {
    try {
      setSelectedDate(date);
      setSelectedStatus(status);
      setDialogOpen2(true);
    } catch (error) {
      console.error("Error editing attendance:", error);
      showSnackbar("Error editing attendance:", "error");
    }
  }

  const handleViewAttendance = async (studentId, classId, rowId) => {
   
    setStudentRowId(rowId);
    try {
      const response = await fetch(`${API_ENDPOINT}route/viewattendance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          student_id: studentId,
          class_id: classId,
          student_class_details_id: rowId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch attendance data");
      }

      const attendanceData = await response.json();

      // Assuming you want to set the selected student data, if needed
      const student = studentData.find(
        (student) => student.student_id?.id === studentId
      );
      setSelectedStudent(student);

      setSelectedStudentAttendance(attendanceData);
      setDialogOpen(true);

      setSelectedStudentId(studentId);
      setSelectedClassId(classId);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseDialog2 = () => {
    setDialogOpen2(false);
  };

  useEffect(() => {
    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchDemoData = async () => {
      try {
        // Fetch demo data based on the user ID
        const response = await fetch(
          `${API_ENDPOINT}route/studentattlist/${userId}`,
          { headers }
        );

        if (response.ok) {
          const data = await response.json();
         

          setStudentData(data);

          setOriginalData(data);
          setSearchData(data);

          setIsLoading(false);
        }
        // Handle non-successful responses
        else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch {
        navigate("/page500");
      }
    };

    // Only fetch data if userId is available
    if (userId) {
      fetchDemoData();
    }
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route/fetchbranch/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
         
          setbranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/gettrainers/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          
          settrainerlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleFetchClass = async (event) => {
    const requestData = {
      user_id: parseInt(userId),
      branch_id: branch.map((i) => i.id),
      trainer_id: trainerName.map((i) => i.id),
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/classbybranchandtrainer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setclasslist(result);
      } else {
        console.error("Error fetching data:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchClass();
  }, [branch, trainerName]);

  const attendanceColumns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      headerClassName: "header-cell",
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        let chipColor = "";
        let chipLabel = "";

        switch (params.value) {
          case "1":
            chipColor = "secondary";
            chipLabel = "Present";
            break;
          case "0":
            chipColor = "error";
            chipLabel = "Absent";
            break;
          case "-1":
            chipColor = "primary";
            chipLabel = "Absent with permission";
            break;
          default:
            chipColor = "orange";
            chipLabel = "Unknown";
        }

        return (
          <Chip
            size="normal"
            variant="contained"
            label={chipLabel}
            color={chipColor === "default-color" ? "default" : chipColor}
          />
        );
      },
    },
    {
      headerClassName: "header-cell",
      field: "action",
      headerName: "Action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <EditIconcomp
            onClick={() =>
              handleEditAttendance(params.row.date, params.row.status)
            }
          />
        </>
      ),
    },
  ];

  function handleEditAttendanceConfirmed(status) {
    try {
      const data = {
        date: selectedDate,
        class_id: selectedClassId,
        attendance_val: status,
        student_class_details_id: studentRowId,
      };

      if (selectedStudentId) {
        fetch(
          `${API_ENDPOINT}attendance/updateattendanceby/${selectedStudentId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        )
          .then((response) => {
            if (response.ok) {
              showSnackbar("Attendance edited successfully", "success");
              handleCloseDialog2();
              handleCloseDialog();
            } else {
              showSnackbar("Failed to edit attendance", "error");
            }
          })
          .catch((error) => {
            console.error("Error editing attendance:", error);
            showSnackbar("Error editing attendance:", "error");
          });
      }
    } catch (error) {
      console.error("Error editing attendance:", error);
      showSnackbar("Error editing attendance:", "error");
    }
  }

  function CustomExportButton({ onClick }) {
    return (
      <Button onClick={onClick} variant="contained" color="secondary">
        Export CSV
      </Button>
    );
  }


  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = studentData.slice(startIndex, endIndex);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Student Attendance Column
  const column = [
    {
      field: "action", // Logical field name for the column
      headerName: "Action",
      width: 70,
      sortable: false, // Enable sorting
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return (
          <VeiwIcon
            tooltipTitle="View Attendance"
            fontSize="0.8rem"
            onClick={() =>
              handleViewAttendance(
                params?.row?.student_id?.id,
                params?.row?.class_id?.id,
                params?.row?.id
              )
            }
          />
        );
      },
    },

    {
      field: "enroll_id", // Logical field name for the column
      headerName: "Enroll Id",
      width: 100,
      // sortable: true, // Enable sorting
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params?.row?.student_id?.lead_no || "",
      renderCell: (params) => {
        return <>{params?.row?.student_id?.lead_no}</>;
      },
    },
    {
      field: "student_name", // Logical field name for the column
      headerName: "Student Name",
      width: 150,
      // sortable: true, // Enable sorting
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        `${params?.row?.enquiry_id?.first_name || ""} ${
          params?.row?.enquiry_id?.last_name || ""
        }`,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.enquiry_id?.first_name}{" "}
            {params?.row?.enquiry_id?.last_name}
          </>
        );
      },
    },

    {
      field: "enrollment_date", // Logical field name for the column
      headerName: "Enrollment Date",
      width: 100,
      // sortable: true, // Enable sorting
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <>{params?.row?.enrollment_date}</>;
      },
    },
    {
      field: "branch", // Logical field name for the column
      headerName: "Branch",
      width: 200,
      // sortable: true, // Enable sorting
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params?.row?.branch_id?.branch_name || "",
      renderCell: (params) => {
        return <>{params?.row?.branch_id?.branch_name}</>;
      },
    },

    {
      field: "trainer", // Logical field name for the column
      headerName: "Trainer",
      width: 150,
      // sortable: true, // Enable sorting
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        `${params?.row?.class_id?.trainer_id?.first_name || ""} ${
          params?.row?.class_id?.trainer_id?.last_name || ""
        }`,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.class_id?.trainer_id?.first_name}{" "}
            {params?.row?.class_id?.trainer_id?.last_name}
          </>
        );
      },
    },
    {
      field: "class", // Logical field name for the column
      headerName: "Class",
      width: 200,
      // sortable: true, // Enable sorting
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params?.row?.class_id?.name || "",
      renderCell: (params) => {
        return <>{params?.row?.class_id?.name}</>;
      },
    },
    {
      field: "status_of_student", // Logical field name for the column
      headerName: "Status",
      width: 100,
      // sortable: true, // Enable sorting
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <>{params?.row?.status_of_student}</>;
      },
    },
    {
      field: "present_days", // Logical field name for the column
      headerName: "Present Days",
      width: 100,
      // sortable: true, // Enable sorting
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <>{params?.row?.present_days}</>;
      },
    },
    {
      field: "absent_days", // Logical field name for the column
      headerName: "Absent Days",
      width: 100,
      // sortable: true, // Enable sorting
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <>{params?.row?.absent_days}</>;
      },
    },
    {
      field: "skipped_days", // Logical field name for the column
      headerName: "Days Skipped",
      width: 100,
      // sortable: true, // Enable sorting
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <>{params?.row?.skipped_days}</>;
      },
    },
  ];

  // Search All column data
  const handleSearchByName = (e) => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, ""); // Normalize search value (lowercase and remove spaces)

    let filteredData1 = searchData.filter((data) => {
      // Check each field for a match with the search value
      const fieldsToSearch = [
        `${data?.student_id?.lead_no || ""}`,
        `${data?.enquiry_id?.first_name || ""} ${
          data?.enquiry_id?.last_name || ""
        }`,

        `${data?.enrollment_date || ""}`,
        `${data?.branch_id?.branch_name || ""}`,
        `${data?.enquiry_id?.total_open_task || ""}`,

        `${data?.class_id?.trainer_id?.first_name || ""} ${
          data?.class_id?.trainer_id?.last_name || ""
        }`,

        `${data?.class_id?.name || ""}`,
        `${data?.status_of_student || ""}`,
        `${data?.present_days || ""}`,
        `${data?.absent_days || ""}`,
        `${data?.skipped_days || ""}`,
      ];

      // Check if any of the fields contain the search value
      return fieldsToSearch.some((field) =>
        field.toLowerCase().replace(/\s+/g, "").includes(value)
      );
    });

    setStudentData(filteredData1); // Update the filtered data
  };
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Stack direction="column" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fetching
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MainCard>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="h3">Student Attendance</Typography>
              </Box>
              <Box>
                <FilterComp onClick={toggleFilterSection} />
              </Box>
            </Box>
            <Collapse in={isFilterOpen}>
              <Grid container spacing={2} mt={isFilterOpen ? 1 : ""}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="h6">Enrollment Date From</Typography>
                  <CustomTextFieldComp
                    InputProps={{
                      style: { borderRadius: "10px" }, // Add borderRadius here
                    }}
                    size="normal"
                    onChange={(e) => setEnrollmentDateFrom(e.target.value)}
                    value={enrollmentDateFrom}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="h6">Enrollment Date To</Typography>
                  <CustomTextFieldComp
                    InputProps={{
                      style: { borderRadius: "10px" }, // Add borderRadius here
                    }}
                    size="normal"
                    onChange={(e) => setEnrollmentDateTo(e.target.value)}
                    value={enrollmentDateTo}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="h6">Branch</Typography>
                  <CustomAutocompleteComp
                    size="normal"
                    value={branch}
                    multiple
                    limitTags={2}
                    onChange={handleBranchChange}
                    options={allBranchList}
                    getOptionLabel={(option) => option.branch_name}
                    tooltipContent={(option) => option.branch_name}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="h6">Select Trainer </Typography>
                  <CustomAutocompleteComp
                    size="normal"
                    value={trainerName}
                    multiple
                    limitTags={2}
                    onChange={handleChangeTrainer}
                    options={allTrainerList}
                    getOptionLabel={(option) =>
                      `${option.first_name ? option.first_name : ""} ${
                        option.last_name ? option.last_name : ""
                      }`
                    }
                    tooltipContent={(option) =>
                      `${option.first_name ? option.first_name : ""} ${
                        option.last_name ? option.last_name : ""
                      }`
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="h6">Select Class </Typography>
                  <CustomAutocompleteComp
                    size="normal"
                    value={className}
                    multiple
                    limitTags={2}
                    onChange={handleChangeClass}
                    options={allClassListList}
                    getOptionLabel={(option) => option.name}
                    tooltipContent={(option) => option.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="h6">Select Status </Typography>
                  <CustomAutocompleteComp
                    size="normal"
                    value={status}
                    multiple
                    onChange={(e, value) => setStus(value)}
                    options={statusOptions}
                    getOptionLabel={(option) => option.label}
                    tooltipContent={(option) => option.label}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  md={12}
                  container
                  gap={1}
                  justifyContent="end"
                >
                  <ApplyFilterButton onClick={handleApplyFilter} />

                  <ResetFilterButton onClick={resetFilters} />
                </Grid>
              </Grid>
            </Collapse>
          </MainCard>
        </Grid>

        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="end">
                <SearchTextFieldComp onChange={handleSearchByName} />
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  disableColumnSorting
                  rows={studentData}
                  columns={column}
                  autoHeight
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[25, 50, 100]}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        scroll="body"
      >
        <IconButton
          edge="end"
          onClick={handleCloseDialog}
          aria-label="close"
          sx={{ position: "absolute", right: 15, top: 2 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {selectedStudent && (
            <>
              <Typography variant="h4" gutterBottom>
                {`${selectedStudent?.enquiry_id?.first_name}'s Attendance`}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Enrollment No: {selectedStudent?.lead_no}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Class: {selectedStudent?.class_id?.name}
              </Typography>

              <DataGrid
                getRowId={() => Math.floor(Math.random() * 100000000)}
                rows={selectedStudentAttendance}
                columns={attendanceColumns}
                autoHeight
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
                sx={{ marginTop: "20px" }}
                pageSizeOptions={[25, 50, 100]}
              />
            </>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDialogOpen2}
        onClose={handleCloseDialog2}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle variant="h4">
          Select Status
          <IconButton
            edge="end"
            onClick={handleCloseDialog2}
            aria-label="close"
            sx={{ position: "absolute", right: 15, top: 2 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Select
              size="small"
              style={{ borderRadius: "10px" }}
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              <MenuItem value={"1"}>Present</MenuItem>
              <MenuItem value={"0"}>Absent</MenuItem>
              <MenuItem value={"-1"}>Absent with permission</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog2} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => handleEditAttendanceConfirmed(selectedStatus)}
            color="secondary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default ViewAttendance;
