import {
  Autocomplete,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { all } from "axios";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
export default function EditMissedLeadsForm({ onClose, selectedRowData }) {
  console.log("selectedRowData", selectedRowData);

  const userId = localStorage.getItem("userId");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [firstName, setFirstName] = useState(
    selectedRowData?.first_name || null
  );
  const [lastName, setLastName] = useState(selectedRowData?.last_name || null);
  const [email, setEmail] = useState(selectedRowData?.email || null);
  const [isdCode, setIsdCode] = useState(
    selectedRowData?.mobile_country_code || 91
  );
  const [mobile, setMobile] = useState(selectedRowData?.mobile || null);
  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(
    selectedRowData?.nearest_branch_id || null
  );
  const [serviceList, setServiceList] = useState([]);
  const [selectedService, setSelectedService] = useState(
    selectedRowData?.service_id || null
  );
  const [subserviceList, setSubserviceList] = useState([]);
  const [selectedsubService, setSelectedSubService] = useState(
    selectedRowData?.sub_service_id || null
  );
  const [CountryList, setCountryList] = useState([]);

  const phoneRegex = /^[1-9][0-9]{9}$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const [leadsourceTypelist, setLeadSourceTypelist] = useState([]);
  const [leadsourceType, setLeadSourceType] = useState(
    selectedRowData?.lead_source_type_id || null
  );
  const [subleadSourceTypeList, setSubleadSourceTypeList] = useState([]);
  const [subleadSourceType, setSubleadSourceType] = useState(
    selectedRowData?.sub_lead_source_type_id || null
  );
  const [errors, setErrors] = useState({
    firstName: false,
    email: false,
    isdCode: false,
    mobile: false,
    selectedCampaign: false,
  });

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}country`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = [...data].sort(
            (a, b) => a.phone_code - b.phone_code
          );
          const uniqueArray = sortedData.filter(
            (obj, index, self) =>
              index === self.findIndex((t) => t.phone_code === obj.phone_code)
          );

          setCountryList(uniqueArray);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setServiceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          const subserviceData = sortedData.filter(
            (i) => i.service_id === selectedService?.id
          );
          setSubserviceList(subserviceData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedService]);

  console.log("selectedService", selectedService);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setBranchList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}leadsource`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          const filterdata = sortedData.filter((item) => item.is_active === 1);
          setLeadSourceTypelist(filterdata);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subleadsource`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setSubleadSourceTypeList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handlefirstNameChange = (e) => {
    setFirstName(e.target.value);
    setErrors({ ...errors, firstName: !e.target.value });
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setErrors({
      ...errors,
      email: !emailValue || !emailRegex.test(emailValue),
    });
  };

  const handleChangeIsdCode = (e) => {
    setIsdCode(e.target.value);
    setErrors({ ...errors, isdCode: !e.target.value });
  };

  const handlemobileChange = (e) => {
    const mobileValue = e.target.value;
    setMobile(mobileValue);
    setErrors({
      ...errors,
      mobile: !mobileValue || !phoneRegex.test(mobileValue),
    });
  };

  const handleServiceChange = (value) => {
    setSelectedSubService(null);
    setSelectedService(value);
    setErrors({ ...errors, selectedService: !value });
  };

  const handleSubServiceChange = (event, value) => {
    setSelectedSubService(value);
    setErrors({ ...errors, selectedsubService: !value });
  };

  const handlebranchChange = (value) => {
    setSelectedBranch(value);
    setErrors({ ...errors, selectedBranch: !value });
  };

  const handleLeadsourceChange = (event, value) => {
    setLeadSourceType(value);
    setErrors({ ...errors, leadsourceType: !value });
  };

  const handleSubLeadsourceChange = (event, value) => {
    setSubleadSourceType(value);
    setErrors({ ...errors, subleadSourceType: !value });
  };

  const handleSubmit = () => {
    if (
      !firstName ||
      !isdCode ||
      !mobile ||
      !selectedBranch ||
      !selectedService ||
      !selectedsubService ||
      !leadsourceType ||
      !subleadSourceType
    ) {
      setErrors({
        firstName: !firstName,
        isdCode: !isdCode,
        mobile: !mobile || !phoneRegex.test(mobile),
        selectedBranch: !selectedBranch,
        selectedService: !selectedService,
        selectedsubService: !selectedsubService,
        leadsourceType: !leadsourceType,
        subleadSourceType: !subleadSourceType,
      });

      return;
    }
    const formdata = {
      first_name: firstName || null,
      last_name: lastName || null,
      email: email || null,
      mobile_country_code: isdCode,
      mobile: mobile || null,
      nearest_branch_id: selectedBranch?.id || null,
      service_id: selectedService?.id || null,
      sub_service_id: selectedsubService?.id || null,
      lead_source_type_id: leadsourceType?.id || null,
      sub_lead_source_type_id: subleadSourceType?.id || null,
      user_id: parseInt(userId),
    };

    fetch(`${API_ENDPOINT}missedenquiry/${selectedRowData?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formdata),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Enquiry updated Successfully.");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (response.status === 400) {
          showSnackbar("Error", "error");
        } else {
          console.error("Error: Unknown Error");
          showSnackbar("Error: Unknown Error", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showSnackbar("Error: Unknown Error", "error");
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6} sm={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            First Name
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>
          <TextField
            size="small"
            value={firstName}
            onChange={handlefirstNameChange}
            InputProps={{
              style: { borderRadius: "12px" },
            }}
            fullWidth
          />
          {errors.firstName && (
            <Typography color="error" variant="caption">
              First name is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={6} sm={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Last Name
          </InputLabel>
          <TextField
            size="small"
            value={lastName}
            onChange={handleLastNameChange}
            InputProps={{
              style: { borderRadius: "12px" },
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6} sm={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Email{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>
          <TextField
            size="small"
            value={email}
            onChange={handleEmailChange}
            InputProps={{
              style: { borderRadius: "12px" },
            }}
            fullWidth
            // error={errors.email}
            //helperText={errors.email && "Valid Email is required"}
          />
          {/* {errors.email && (
              <Typography color="error" variant="caption">
                Email is required
              </Typography>
            )} */}
        </Grid>

        <Grid item xs={12} md={6} lg={6} sm={6} container>
          <Grid item xs={4} md={4} lg={3}>
            <InputLabel style={{ color: "black", position: "relative" }}>
              ISD Code
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1.5em",
                }}
              >
                *
              </span>
            </InputLabel>

            <TextField
              size="small"
              select
              value={isdCode}
              onChange={handleChangeIsdCode}
              InputProps={{
                style: { borderRadius: "12px" },
              }}
              fullWidth
              defaultValue={91}
              SelectProps={{
                MenuProps: {
                  sx: {
                    maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
              }}
              error={errors.isdCode}
            >
              {CountryList.map((country) => (
                <MenuItem
                  men
                  key={country.phone_code}
                  value={country.phone_code}
                >
                  {country.phone_code}
                </MenuItem>
              ))}

              {errors.isdCode && (
                <Typography color="error" variant="caption">
                  ISD Code is required
                </Typography>
              )}
            </TextField>
          </Grid>
          <Grid item xs={8} md={8} lg={9}>
            <InputLabel style={{ color: "black", position: "relative" }}>
              Mobile
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1.5em",
                }}
              >
                *
              </span>
            </InputLabel>

            <TextField
              size="small"
              type="number"
              InputProps={{
                style: { borderRadius: "12px" },
              }}
              value={mobile}
              onChange={handlemobileChange}
              fullWidth
              error={errors.mobile}
              helperText={errors.mobile && "Valid Mobile is required"}
            />

            {/* {errors.mobile && (
                <Typography color="error" variant="caption">
                  Mobile number is required
                </Typography>
              )} */}
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Nearest Branch
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>

          <Autocomplete
            disablePortal
            size="small"
            value={selectedBranch}
            options={branchList}
            onChange={(event, value) => {
              console.log(value);

              handlebranchChange(value); // Passing only the value
            }}
            getOptionLabel={(option) => option.branch_name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: "12px" },
                }}
              />
            )}
            sx={{
              "& .MuiAutocomplete-listbox": {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
              },
            }}
          />

          {errors.selectedBranch && (
            <Typography color="error" variant="caption">
              Nearest Branch is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Service
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>

          <Autocomplete
            disablePortal
            size="small"
            value={selectedService}
            onChange={(event, value) => {
              handleServiceChange(value); // Passing only the value
            }}
            options={serviceList}
            getOptionLabel={(option) => option.service_name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: "12px" },
                }}
              />
            )}
            sx={{
              "& .MuiAutocomplete-listbox": {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
              },
            }}
          />

          {errors.selectedService && (
            <Typography color="error" variant="caption">
              Service is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Sub Service
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>

          <Autocomplete
            disablePortal
            size="small"
            value={selectedsubService}
            onChange={
              handleSubServiceChange // Passing only the value
            }
            options={subserviceList}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: "12px" },
                }}
              />
            )}
          />
          {errors.selectedsubService && (
            <Typography color="error" variant="caption">
              Sub Service is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Lead Source Type
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>

          <Autocomplete
            disablePortal
            size="small"
            value={leadsourceType}
            onChange={handleLeadsourceChange}
            options={leadsourceTypelist}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: "12px" },
                }}
              />
            )}
            sx={{
              "& .MuiAutocomplete-listbox": {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
              },
            }}
          />
          {errors.leadsourceType && (
            <Typography color="error" variant="caption">
              Lead source type is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Sub Lead Source Type
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>

          <Autocomplete
            disablePortal
            size="small"
            value={subleadSourceType}
            onChange={handleSubLeadsourceChange}
            options={subleadSourceTypeList}
            getOptionLabel={(option) => option.source_type}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: "12px" },
                }}
              />
            )}
            sx={{
              "& .MuiAutocomplete-listbox": {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
              },
            }}
          />
          {errors.subleadSourceType && (
            <Typography color="error" variant="caption">
              Sub lead source type is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12} textAlign="end">
          <Button
            color="secondary"
            variant="contained"
            style={{ borderRadius: "25px" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
