import React, { useEffect, useState } from "react";
import ClockLoader from "../../../newComponent/LoaderComp/ClockLoader";
import {
  Document,
  Font,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

export default function SubServiceDetailsPdf() {
  const [loading, setLoading] = useState(false);
  const [enquiryData, setEnquiryData] = useState([]);

  const styles = StyleSheet.create({
    document: { padding: 10 },
    page: {
      fontFamily: "Open Sans",

      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    heading: {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "16px",
      marginBottom: "10px",
      textDecoration: "underline",
    },

    heading2: {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "14px",
      marginBottom: "10px",
    },

    table: {
      marginTop: 10,
      width: "100%",
      marginBottom: 10,
      borderCollapse: "collapse",
    },
    tableRow: {
      flexDirection: "row",
    },
    tableCell: {
      padding: 5,
      fontSize: 9,
      textAlign: "center",
      flex: 1,
      borderBottom: "1px solid #808080", // Bottom border for rows
      borderRight: "1px solid #808080", // Right border for columns
    },
    tableHeaderCell: {
      fontFamily: "Open Sans",
      padding: 5,
      fontSize: 9,
      textAlign: "center",
      fontWeight: "bold",
      flex: 1,
      borderTop: "1px solid #808080",
      borderBottom: "1px solid #808080", // Bottom border for header
      borderRight: "1px solid #808080", // Right border for columns
      backgroundColor: "#7ff2ba",
      color: "#002e17",
    },
    lastColumnCell: {
      borderRight: "1px solid #808080", // No right border for the last column
    },

    firstColumnCell: {
      flex: 2,
      borderLeft: "1px solid #808080",
    },
    title: {
      marginTop: "20px",
      fontSize: "12px",
    },
    subtitle: {
      fontWeight: 600,
      fontSize: "14px",
      fontFamily: "Open Sans",
    },
    chipContainer: {
      marginTop: "6px",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 3, // Add spacing between chips
    },
    chip: {
      backgroundColor: "#7ff2ba",
      borderRadius: 16,
      paddingHorizontal: 6,
      paddingVertical: 2,
      marginRight: 1,
      marginBottom: 8,
    },
    chipText: {
      fontFamily: "Open Sans",
      fontSize: 7,
    },
  });

  useEffect(() => {
    // Retrieve the data from localStorage
    setLoading(true);
    const storedData = localStorage.getItem("enquiryData");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        console.log("parsedData", parsedData);

        // Destructure the parsed object to extract individual values
        const { revenueData, advisorName, selectedYear, monthname } =
          parsedData;

        // Update the state or perform actions with the retrieved data
        setEnquiryData(parsedData);
        setLoading(false);
      } catch (error) {
        console.error("Error parsing stored data:", error);
        setLoading(false);
      }
    }
  }, []);
  return (
    <>
      <ClockLoader isLoading={loading} />
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document style={styles.document}>
          <Page size="A4" style={styles.page}>
            <View>
              <Text style={styles.heading}>
                Sub Service Wise Enquiry Report
              </Text>
            </View>
            <View style={styles.heading2}>
              <Text>
                {" "}
                {enquiryData.monthname} {enquiryData.selectedYear?.year}{" "}
              </Text>
            </View>

            {enquiryData?.branchName?.some(
              (name) => name.branch_name === "Select All"
            ) ? (
              <Text style={styles.title}>
                Please refer the Enquiry report for{" "}
                <Text style={styles.subtitle}>All</Text> Branches
              </Text>
            ) : (
              <>
                <Text style={styles.title}>
                  Please refer the enquiry report for below branches
                </Text>

                <View style={styles.chipContainer}>
                  {enquiryData?.branchName?.map((name, index) => (
                    <View key={index} style={styles.chip}>
                      <Text style={styles.chipText}>{name.branch_name}</Text>
                    </View>
                  ))}
                </View>
              </>
            )}

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={[styles.tableHeaderCell, styles.firstColumnCell]}>
                  Sub Service
                </Text>
                <Text style={styles.tableHeaderCell}>Total Enquiries</Text>
                <Text style={styles.tableHeaderCell}>Online Enquiries</Text>
                <Text style={styles.tableHeaderCell}>Offline Enquiries</Text>
              </View>
              {enquiryData?.enquiryData?.length === 0 ? (
                <Text style={{ fontSize: "10px" }}>No Data Available</Text>
              ) : (
                enquiryData?.enquiryData?.map((row, index) => (
                  <View style={styles.tableRow} key={index}>
                    <Text style={[styles.tableCell, styles.firstColumnCell]}>
                      {row?.sub_service_id?.name}
                    </Text>
                    <Text style={styles.tableCell}>{row?.total_enquiry}</Text>
                    <Text style={styles.tableCell}>{row?.online_enquiry}</Text>
                    <Text style={styles.tableCell}>{row?.offline_enquiry}</Text>
                  </View>
                ))
              )}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
}
