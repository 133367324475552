import { Box, Grid, styled, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import CustomNoRowsOverlay from "../../newComponent/TableCustomeComp/CustomNoRowsOverlay";

export default function PackageData({
  selectedDateData,
  moment,
  selectedDate,
  data,
}) {
  console.log("selectedDateData", data);

  let currDate = new Date(selectedDate);
  currDate = currDate.toISOString().split("T")[0];

  const demodata = data
    .filter((date) => date.date === currDate)
    .map((i) => i.demos)
    .flat();

  console.log("demodata", demodata);

  const date = moment(selectedDate);
  const formattedDate = date.format("DD MMMM, YYYY");

  const columns = [
    {
      field: "student_name",
      headerName: "Student name",
      flex: 0.7,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row?.enquiry_id?.first_name}{" "}
          {params?.row?.enquiry_id?.last_name}
        </div>
      ),
    },
    {
      field: "package",
      headerName: "Package Details",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row?.package_id?.package_name} {"  "}
          {"  "}{" "}
          {params?.row?.class_extended !== 0
            ? `(class extension  for ${params.row?.class_extended} days)`
            : ""}{" "}
        </div>
      ),
    },
  ];

  return (
    <Grid container spacing={2}>
      {demodata.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h4">Demo Details for {formattedDate}</Typography>
        </Grid>
      )}
      {demodata.map((enrollment, index) => (
        <Grid item xs={12} key={index}>
          <Box
            sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
          >
            <Typography fontSize="18px">
              Demo For <strong>{enrollment?.enquiry_name}</strong> ( Advisor{" "}
              <strong>: {enrollment?.enquiry_owner}</strong>)
            </Typography>
          </Box>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Box
          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
        >
          <Typography variant="h5">Enrolled Students</Typography>
          <Typography variant="h5"> : {selectedDateData?.length}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <DataGrid
            rows={selectedDateData}
            columns={columns}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            autoHeight={selectedDateData.length < 6}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            getRowId={() => Math.floor(Math.random() * 100000000)}
            sx={{
              marginTop: "20px",
              height: selectedDateData.length > 6 ? "450px" : "300px",
            }}
            pageSizeOptions={[25, 50, 100]}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
