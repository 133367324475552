import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import InfoCard from "./InfoCard";
import EventFilter from "./EventFilter";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {
  format,
  subMonths,
  addMonths,
  isSameMonth,
  getMonth,
  getYear,
} from "date-fns";
import { useGetTokenOrLogout } from "../../../utils/token";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";
import CustomAutocompleteComp from "../../newComponent/AutocompleteComp";
import SearchButton from "../../newComponent/NewButtonComp/SearchButton";
import ResetButtonComp from "../../newComponent/NewButtonComp/ResetButtonComp";
import SearchTextFieldComp from "../../newComponent/newtextfieldComp/SearchTextFieldComp";

export default function EventWiseReport({ open }) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [eventData, setEventData] = useState([]);
  const [eventOriginalData, setEventOriginalData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [eventList, setEventList] = useState([]);
  const [eventname, setEventName] = useState([]);
  const [eventloading, setEventLoading] = useState(false);
  const [subServiceName, setSubServiceName] = useState([]);
  const [subServiceList, setSubsubServiceList] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [eventType, setEventType] = useState(null);
  const [eventFromDate, setEventFromDate] = useState("");
  const [eventToDate, setEventToDate] = useState("");

  const currentDate = new Date();
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);

  console.log(selectedMonthYear);
  const dates = [
    subMonths(currentDate, 2),
    subMonths(currentDate, 1),
    currentDate,
    addMonths(currentDate, 1),
    addMonths(currentDate, 2),
  ];

  const noOptionsText = "Loading...";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();

          setSubsubServiceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const allSubservice = [{ name: "Select All", id: 0 }, ...subServiceList];

  // handle subservice
  const handleChangeSubservice = (id) => {
    setEventName([]);
    setEventList([]);
    setEventFromDate("");
    setEventToDate("");
    setEventType(null);
    setSelectedMonthYear(null);
    if (id === 0) {
      // Handle "Select All"
      setSubServiceName(
        (prevSelected) =>
          prevSelected.length === subServiceList.length
            ? [] // Deselect all
            : subServiceList.map((subservice) => subservice.id) // Select all
      );
    } else {
      // Handle individual subservice selection
      setSubServiceName(
        (prevSelected) =>
          prevSelected.includes(id)
            ? prevSelected.filter((subserviceId) => subserviceId !== id) // Remove ID if already selected
            : [...prevSelected, id] // Add ID if not selected
      );
    }
  };

  const handleEventData = async ({ date = null, eventType = null }) => {
    // Reset dependent states
    setEventName([]);
    setEventList([]);
    setEventFromDate("");
    setEventToDate("");
    setSelectedMonthYear(date ? format(date, "MMMM,yyyy") : null);
    setEventType(eventType);

    // Determine which filter to use for fetching data
    const filter = date ? format(date, "MMMM,yyyy") : eventType;

    // Fetch event data
    if (eventType !== "custom") {
      getEventData(date, filter, null, null);
    }
  };

  // Fetch Custom Event Data
  const handleChangeEventFromdate = (e) => {
    setEventFromDate(e.target.value);
  };

  const handleChangeEventTodate = (e) => {
    setEventToDate(e.target.value);
    if (eventFromDate !== "") {
      getEventData(null, eventType, eventFromDate, e.target.value);
    }
  };

  const getEventData = async (date, eventcriteria, fromdate, todate) => {
    const requestData = {
      filter: date === null ? eventcriteria : format(date, "MMMM, yyyy"),
      sub_service_id: subServiceName,
      from_date: fromdate || null,
      to_date: todate || null,
    };
    setEventLoading(true);
    try {
      const response = await fetch(`${API_ENDPOINT}campaign/eventbymonth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setEventList(sortedData);
        setEventLoading(false);
      } else if (response.status === 404) {
        setEventList([]);
        setEventLoading(false);
      } else {
        setEventLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setEventLoading(false);
    }
  };

  const allcampaignList = [
    ...(eventList.length > 1 ? [{ campaign_name: "Select All" }] : []),
    ...eventList,
  ];

  const handelEventChange = (event, value) => {
    if (value.some((option) => option.campaign_name === "Select All")) {
      setEventName(eventList);
    } else {
      setEventName(value);
    }
  };
  const columns = [
    {
      field: "name",
      headerName: "Event Name",
      width: 450,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => `${params.row?.event_id?.campaign_name || ""}`,
      renderCell: (params) => <div>{params.row?.event_id?.campaign_name} </div>,
    },

    {
      field: "registered_and_confirmed",
      headerName: "Registered And Confirmed",
      width: 200,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value,
      renderCell: (params) => (
        <div>{params.row?.registered_and_confirmed} </div>
      ),
    },
    {
      field: "registered_and_not_confirmed",
      headerName: "Registered And Not Confirmed",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value,
      renderCell: (params) => (
        <div>{params.row?.registered_and_not_confirmed} </div>
      ),
    },
    {
      field: "not_registered",
      headerName: "Not Registered",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value,
      renderCell: (params) => <div> {params.row?.not_registered}</div>,
    },
    {
      field: "total_registration",
      headerName: "Total Registration",
      width: 200,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value,
      renderCell: (params) => <div>{params.row?.total_registration} </div>,
    },
    {
      field: "attended_registered",
      headerName: "Total Attended (Registered Student)",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value,
      renderCell: (params) => <div>{params.row?.attended_registered} </div>,
    },
    {
      field: "attended_not_registered",
      headerName: "Attended (Not Registered Student)",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value,
      renderCell: (params) => <div>{params.row?.attended_not_registered} </div>,
    },
    {
      field: "total_attended",
      headerName: "Total Attended",
      width: 200,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value,
      renderCell: (params) => <div>{params.row?.total_attended}</div>,
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  const handleSubmit = async (event) => {
    if (eventname.length <= 0) {
      showSnackbar("Please choose an event", "error");
      return;
    }

    setLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      event: eventname.map((i) => i.id),
      user_id: parseInt(userId),
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/eventwisereport`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        setEventData(result);
        setEventOriginalData(result);
        setLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  const handleReset = () => {
    setEventName([]);
    setEventData([]);
    setEventList([]);
    setEventOriginalData([]);
    setEventFromDate("");
    setEventToDate("");
    setEventType(null);
    setSelectedMonthYear(null);
    setSubServiceName([]);
  };

  // Search
  const handleSearchByName = (e) => {
    const value = e.target.value;
    let filteredData1 = eventOriginalData.filter((data) =>
      `${data?.event_id?.campaign_name}`
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );
    setEventData(filteredData1);
  };
  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid item lg={12} sm={12} md={12} xs={12}>
        <Collapse in={open} fullWidth>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#2E4069",

                    fontWeight: 600,
                    marginTop: "20px",
                  }}
                >
                  Sub Service
                </Typography>

                {allSubservice.map((subservice) => (
                  <Chip
                    size="small"
                    key={subservice.id}
                    onClick={() => handleChangeSubservice(subservice.id)}
                    variant="outlined"
                    label={
                      subservice.id === 0
                        ? subServiceName.length === subServiceList.length
                          ? "Deselect All"
                          : "Select All"
                        : subservice.name
                    }
                    style={{
                      color: subServiceName.includes(subservice.id)
                        ? "green"
                        : "black",
                      fontSize: "0.7rem",
                      fontWeight: 500,
                      margin: "5px",
                      border: subServiceName.includes(subservice.id)
                        ? "2px solid green"
                        : "",
                    }}
                  />
                ))}
              </Grid>

              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Typography
                  variant="h4"
                  sx={{ color: "#2E4069", fontWeight: 600 }}
                >
                  Duration
                  <span
                    style={{
                      color: "red",
                      fontSize: "15px",
                      marginLeft: "3px",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Chip
                  size="small"
                  variant="outlined"
                  onClick={() => handleEventData({ eventType: "all" })}
                  label="All Event"
                  style={{
                    borderRadius: "25px",
                    fontSize: "0.7rem",
                    margin: "5px",
                    border: eventType === "all" ? "2px solid green" : "",
                    fontWeight: 500,
                    color: eventType === "all" ? "green" : "black",
                  }}
                />

                <Chip
                  size="small"
                  variant="outlined"
                  onClick={() => handleEventData({ eventType: "past" })}
                  label="Past Event"
                  style={{
                    borderRadius: "25px",
                    fontSize: "0.7rem",
                    margin: "5px",
                    border: eventType === "past" ? "2px solid green" : "",
                    fontWeight: 500,
                    color: eventType === "past" ? "green" : "black",
                  }}
                />

                {dates.map((date, index) => {
                  const formattedDate = format(date, "MMMM,yyyy");
                  const isSelected = formattedDate === selectedMonthYear;
                  console.log("selected", isSelected);
                  return (
                    <Chip
                      size="small"
                      key={index}
                      variant="outlined"
                      onClick={() => handleEventData({ date: date })}
                      label={format(date, "MMMM yyyy")}
                      style={{
                        margin: "5px",
                        borderRadius: "25px",
                        fontSize: "0.7rem",
                        border: isSelected ? "2px solid green" : "",
                        color: isSelected ? "green" : "black",
                        fontWeight: 500,
                      }}
                    />
                  );
                })}

                <Chip
                  size="small"
                  variant="outlined"
                  onClick={() => handleEventData({ eventType: "custom" })}
                  label="Custom Event"
                  style={{
                    borderRadius: "25px",
                    fontSize: "0.7rem",
                    margin: "5px",
                    border: eventType === "custom" ? "2px solid green" : "",
                    fontWeight: 500,
                    color: eventType === "custom" ? "green" : "black",
                  }}
                />

                {eventType === "custom" && (
                  <>
                    <CustomTextFieldComp
                      size="small"
                      InputProps={{ style: { borderRadius: "14px" } }}
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      label="From Date"
                      variant="outlined"
                      sx={{ margin: "5px" }}
                      value={eventFromDate}
                      onChange={handleChangeEventFromdate}
                    />

                    <CustomTextFieldComp
                      size="small"
                      InputProps={{ style: { borderRadius: "14px" } }}
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      label="To Date"
                      variant="outlined"
                      sx={{ margin: "5px" }}
                      value={eventToDate}
                      onChange={handleChangeEventTodate}
                    />
                  </>
                )}
              </Grid>

              <Grid item xs={12} lg={6} md={6} sm={6}>
                <Typography variant="h6">Event</Typography>

                <CustomAutocompleteComp
                  limitTags={3}
                  size="small"
                  noOptionsText={
                    eventloading && allcampaignList.length === 0
                      ? noOptionsText
                      : "Please Select Above Filter First"
                  }
                  multiple
                  value={eventname}
                  onChange={handelEventChange}
                  options={allcampaignList}
                  getOptionLabel={(option) => option.campaign_name}
                  tooltipContent={(option) => option.campaign_name}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12} sm={12} textAlign="end">
                <Grid container gap={2} justifyContent="end">
                  <SearchButton
                    showIcon={true}
                    onClick={handleSubmit}
                    label="Get Event Wise Report"
                  />

                  <ResetButtonComp onClick={handleReset} label="Clear Report" />
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
        </Collapse>
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <MainCard>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4" sx={{ fontWeight: 600 }}>
                Event Wise Registration Report
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} textAlign="end">
              <SearchTextFieldComp onChange={handleSearchByName} />
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                rows={eventData}
                columns={columns}
                autoHeight
                getRowId={() => Math.floor(Math.random() * 100000000)}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
                sx={{ marginTop: "20px" }}
                pageSizeOptions={[25, 50, 100]}
              />
            </Grid>
          </Grid>
        </MainCard>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
