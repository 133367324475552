// Author : Ayesha Shaikh
//  Date : 22 Mar 2025

import {
  Grid,
  Typography,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import StyledTableContainer from "../../../newComponent/TableContainer";
import CustomPopup from "../../../../components/CustomPopup";
import SubserviceEnquiryDetails from "./SubserviceEnquiryDetails";
import { CSVExport } from "../../../components/ui-component/table/CSVExport";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../../../utils/token";
import LoaderComp from "../../../newComponent/LoaderComp/Loader";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function ShowStages({ data, payLoadData, sub_service_id ,showSnackbar}) {
  const navigate=useNavigate()
   const getTokenOrLogout = useGetTokenOrLogout();
   const userId=localStorage.getItem('userId')
  const [opendetails, setOpendetails] = useState(false);
  const [detailsloading, setDetailsLoading] =useState(false)
  const [detailsData, setDetailsdata] = useState([]);
  const [showBranch, setShowBranch] = useState(false);
  const [stageName, setStageName] =useState(null)
  const [prospectiveLeval, setProspectiveLevl] =useState(null)

  const handleOpen = (stageName, prospectiveLeval) => {
    setOpendetails(true);
    setStageName(stageName)
    setProspectiveLevl(prospectiveLeval)
  };

  const handleclose = () => {
    setOpendetails(false);
  };

  const handleToggleShowBranch = () => {
    setShowBranch(!showBranch);
  };

  const handleGetBreakDownDetails = async (rowData,filterType, prospectiveLeval) => {
    if (payLoadData?.selectedYear?.year === null) {
      showSnackbar("Please Select Year", "error");
      return;
    }

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      user_id: parseInt(userId),
      year: payLoadData?.selectedYear?.year,
      branch_id: payLoadData?.branchName.map((i) => i.id),
      month: payLoadData?.monthname,
      sub_service_id: sub_service_id?.id,
      filter_type:filterType,
      stage_id :rowData?.stage_id?.id
    };

    setDetailsLoading(true);
    try {
      const response = await fetch(
        `${API_ENDPOINT}route/sub-service-wise-enq-breakdown-with-detial`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        // const reversedData = result.slice().reverse();
        setDetailsdata(result);
        setDetailsLoading(false);
        handleOpen(rowData,prospectiveLeval);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        showSnackbar("Please select user", "error");
        setDetailsdata([]);
        setDetailsLoading(false);
      } else if (response.status === 440) {
        navigate("/page440");
      } else if (response.status === 400) {
        showSnackbar("Some fields are missing", "error");
        setDetailsdata([]);
        setDetailsLoading(false);
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };


  return (
    <>
    <LoaderComp isLoading={detailsloading}/>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" textAlign="center">
            {" "}
            {payLoadData?.monthname} {payLoadData?.selectedYear?.year}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            onClick={handleToggleShowBranch}
            sx={{ cursor: "pointer" }}
          >
            <Typography variant="h5">Selected Branches</Typography>
            {showBranch ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Box>
        </Grid>
        {showBranch && (
          <Grid item xs={12}>
            <Box
              sx={{
                border: "1px solid #888888",
                padding: "10px",
                borderRadius: "10px",
                display: "flex",
                gap: 1,
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              {payLoadData?.branchName.map((branch, index) => (
                <>
                  <Typography variant="h6">{branch?.branch_name}</Typography>
                  {index !== payLoadData.branchName.length - 1 && (
                    <Box
                      sx={{
                        borderLeft: "1px solid #888888",
                        height: "20px",
                        marginLeft: 1,
                      }}
                    />
                  )}
                </>
              ))}
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <StyledTableContainer component={Paper} borderLeft={true}>
            <Table
              sx={{ tableLayout: "fixed" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell>Stage Name</TableCell>
                  <TableCell>Stage Count</TableCell>
                  <TableCell>Super Prospective</TableCell>
                  <TableCell>Hot</TableCell>
                  <TableCell>Warm</TableCell>
                  <TableCell>Contact In Future</TableCell>
                  <TableCell>Recycled</TableCell>
                  <TableCell>New lead</TableCell>
                </TableRow>
              </TableHead>

              {data.map((row, index) => (
                <TableBody>
                  <TableRow
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f0f0f0" : "inherit",
                    }}
                  >
                    <TableCell>{row?.stage_id?.stage_name}</TableCell>
                    <TableCell
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={()=>handleGetBreakDownDetails(row,'stage_count', null)}
                    >
                      {row?.stage_count}
                    </TableCell>

                    <TableCell
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={()=>handleGetBreakDownDetails(row,'super_prospective', "Super Prospective")}
                    >
                      {row?.super_prospective}
                    </TableCell>

                    <TableCell
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={()=>handleGetBreakDownDetails(row,'hot',"Hot")}
                    >
                      {row?.hot}
                    </TableCell>

                    <TableCell
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={()=>handleGetBreakDownDetails(row,'warm',"Warm")}
                    >
                      {row?.warm}
                    </TableCell>

                    <TableCell
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={()=>handleGetBreakDownDetails(row,'contact_in_future', "Contact In Future")}
                    >
                      {row?.contact_in_future}
                    </TableCell>

                    <TableCell
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={()=>handleGetBreakDownDetails(row,'recycled', "Recycled")}
                    >
                      {row?.recycled}
                    </TableCell>

                    <TableCell
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={()=>handleGetBreakDownDetails(row,'new_lead', "New Lead")}
                    >
                      {row?.new_lead}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          </StyledTableContainer>
        </Grid>
      </Grid>

      <CustomPopup
        open={opendetails}
        onClose={handleclose}
        component={
          <SubserviceEnquiryDetails
            onClose={handleclose}
            data={detailsData}
            payLoadData={payLoadData}
            stageName={stageName}
            prospectiveLeval={prospectiveLeval}
            subService={sub_service_id}
            stagePopup={true}
          />
        }
        title= {`Enquiry Details`}
        maxWidth="lg"
        showDivider={true}
      />
    </>
  );
}
