import React from "react";
import { Box, Typography } from "@mui/material";
export default function PackagesAll({ selectedpackages }) {
  return (
    <>
    <Box sx={{height:'300px'}}>
      <Typography variant="h5">
        <ul>
          {selectedpackages.map((pkg, index) => (
            <li key={index} style={{padding:'5px'}}>{pkg}</li>
          ))}
        </ul>
      </Typography>
      </Box>
    </>
  );
}
