import React, { useEffect, useState } from "react";
import MainCard from "../../../../../components/Maincard";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { fDate, fDateTime } from "../../../../../utils/formatTime";
import parse from "html-react-parser";
export default function EmailDetails({ selectedid }) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const PATH_URL = process.env.REACT_APP_API_UPLOADS;
  const [emaildetails, setEmaildetails] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(emaildetails);
  const dateString = emaildetails.createdAt;
  const date = new Date(dateString);
  const dayName = date.toLocaleDateString("en-US", { weekday: "long" });

  const body =
    typeof emaildetails?.email_body === "string"
      ? parse(emaildetails?.email_body)
      : "";
  useEffect(() => {
    setLoading(true);
    fetch(`${API_ENDPOINT}sentemail/${selectedid}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          console.log("Not Found any data");
          setLoading(false);
          return; // No need to continue further
        } else if (response.status === 500) {
          setLoading(false);
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .then((data) => {
        if (!data) return; // Stop further execution if data is not present

        setEmaildetails(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [selectedid]);
  console.log("details", emaildetails);
  return (
    <MainCard>
      {loading ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : !loading && emaildetails.length === 0 ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <Typography variant="h6">No Email Data </Typography>
        </Box>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Email Sent on (Date and Time){" "}
              </Typography>
              <TextField
              size="small"
                value={`${dayName} , ${fDateTime(emaildetails.createdAt)}`}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Email Sent to </Typography>
              <TextField
              size="small"
                value={`${emaildetails.sent_to_enq_id?.first_name} ${emaildetails.sent_to_enq_id?.last_name}  < ${emaildetails.sent_to_enq_id.email} > `}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Email Sent by </Typography>
              <TextField
              size="small"
                value={`${emaildetails.sent_by_id?.first_name} ${emaildetails.sent_by_id?.last_name}  < ${emaildetails?.sender_address} > `}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">CC </Typography>
              <TextField
               size="small"
                value={emaildetails?.email_cc ? emaildetails?.email_cc : "____"}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">BCC </Typography>
              <TextField
              size="small"
                value={
                  emaildetails?.email_bcc ? emaildetails?.email_bcc : "____"
                }
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Subject </Typography>
              <TextField
              size="small"
                value={
                  emaildetails?.email_subject === null ||
                  emaildetails?.email_subject === "null"
                    ? "____"
                    : emaildetails?.email_subject
                }
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            {emaildetails?.email_body && (
              <Grid item xs={12}>
                <Typography
                  style={{
                    border: "1px solid #D5D5D5",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  {parse(emaildetails?.email_body)}
                </Typography>
              </Grid>
            )}
            {emaildetails.attachement.length > 0 && (
              <Grid xs={12} mt={2}>
                <Divider fullWidth />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h6">
                {emaildetails.attachement.length > 0 && "Email Attachments"}{" "}
              </Typography>

              {emaildetails.attachement.map((image) => (
                <Box
                  sx={{
                    position: "relative", // Ensure positioning context
                    height: "40px",
                    margin: "10px",
                    border: "1px solid #D5D5D5",
                    display: "inline-block",
                    padding: "5px",
                    borderRadius: "10px",
                    background: "#E1EBEE",
                    cursor: "pointer",
                  }}
                  key={image.id}
                >
                  <Typography
                    mt={1}
                    onClick={() =>
                      window.open(
                        `${PATH_URL}emailattachment/${image.file_path}`, // This is the correct way to include the file path
                        "_blank"
                      )
                    }
                  >
                    {image.file_name}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </MainCard>
  );
}
