import { useContext, useEffect, useState } from 'react';
import { Routes, Route, Router, Navigate } from 'react-router-dom';
import './App.css';

import { UserContext } from './context/UserContext';
import AdvisorDashboard from './pages/advisordashboard/AdvisorDashboard';
import Login2 from './pages/Login2';
import LeadsDataGrids from './pages/advisorPages/LeadsDataGrids';
import EnquiryDetails from './views/advisor/enquirydetails/EnquiryDetails';
import AddEnquiryNew from './pages/advisorPages/addEnquiry/AddEnquiryNew';
import AddIncomingCall from './pages/advisorPages/addEnquiry/AddIncomingCall';
import AddWalkIn from './pages/advisorPages/addEnquiry/AddWalkIn';
import AdvisorTasks from './pages/advisorPages/tasks/AdvisorTasks';

import TaskReport from './pages/reports/TaskReport';
import ViewAdvisorNotifications from './pages/advisorPages/notifications/ViewAdvisorNotifications';
import TaskReportGrid from './pages/advisorPages/tasks/TaskReportGrid';
import AdminDashboard2 from './pages/adminPages/AdminDashboard2';
import TrainerDashboard from './pages/trainerPages/TrainerDashboard';
import TrainerClasses from './pages/trainerPages/TrainerClasses';
import ClassInfo from './pages/trainerPages/ClassInfo';
import TrainerDemosPage from './pages/trainerPages/TrainerDemosPage';
import StudyMaterialPage from './views/trainer/studymaterial/ViewStudyMaterial';
import { useSelector } from 'react-redux';
import AdminClasses from './pages/adminPages/classes/AdminClasses';
import AdminDemos from './views/admin/demos/AdminDemos';
import ViewEnquiriesDataGrid from './views/admin/enquiries/ViewEnquiriesDataGrid';
import TrainerEnquiries from './views/trainer/enquiries/TrainerEnquiries';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import themes from './newTestPrep/themes';
import NavigationScroll from './newTestPrep/layout/NavigationScroll';
import Login5 from './newTestPrep/pages/authentication/authentication/Login5';
import MainLayout from './newTestPrep/layout/MainLayout'

import TestType from './newTestPrep/pages/testtypes/TestType';

import EmployeeCardPage from './newTestPrep/pages/employee/viewEmployee/Employees';
import BranchesTable from './newTestPrep/pages/branches/BranchesTable';
import MainTrainerPage from './newTestPrep/pages/classes/MainTrainerPage';
import DemosTable from './newTestPrep/pages/demos/DemosTable';
import PackagesList from './newTestPrep/pages/packages/PackagesList';
import EnrollStudent from './newTestPrep/pages/students/enrollStudents/EnrollStudentPage';
import ViewAttendance from './newTestPrep/pages/attendance/ViewAttendance';
import TakeAttendance from './newTestPrep/pages/attendance/TakeAttendance';
import PaymentsDataGrid from './newTestPrep/pages/payments/PaymentDataGrid';
import StudentsListPage from './newTestPrep/pages/students/viewStudents/StudentsList';
import StudentDetails from './newTestPrep/pages/students/viewStudents/studentDetails/StudentDetails';
import AddBulkEnquiryForm from './newTestPrep/pages/enquiry/AddBulkEnquiries';
import AddPackage from './newTestPrep/pages/students/viewStudents/studentDetails/AddPackage';
import Dashboard from './newTestPrep/pages/dashboard/Dashboard';
import TaskDataGrid from './section/tasks/AdvisorTaskDataGrid';
import TasksDataGrid from './newTestPrep/pages/tasks/TasksDataGrid';
import LandingPage from './newTestPrep/pages/landing/LandingPage';
import Register from './newTestPrep/pages/authentication/authentication/Register';
import RegistrationPage from './newTestPrep/pages/register/RegistrationPage';
import CenterAdmin from './newTestPrep/pages/dashboard/CenterAdmin';
import BranchesPage from './newTestPrep/pages/branches/BranchesPage';
import BranchDashboard from './newTestPrep/pages/branches/BranchDashboard';
import Page404 from './pages/Page404';
import Page500Server from './pages/Page500.jsx';

import RolePermission from './newTestPrep/pages/authentication/RolePermission.jsx';
import RolePage from './newTestPrep/pages/authentication/authentication/RolePage.jsx';

import DemoDetails from './newTestPrep/pages/demos/DemoDetails.jsx';

import LeadConversation from './pages/LeadConversation/LeadConversation.jsx';
import MainPage from './pages/LeadConversation/MainPage.jsx';
import AdminMainPage from './pages/AdminDashboard.jsx/AdminMainPage.jsx';
import AdminDasboardOverview from './pages/AdminDashboardOverview/AdminDasboardOverview.jsx';
import AdminDashboardStudent from './pages/AdminDasboardStudent/AdminDashboardStudent.jsx';
import AdminDashboardRevenue from './pages/AdminDashboardRevenue/AdminDashboardRevenue.jsx';
import MainTabs from './pages/AdminDashboardReport/MainTabs.jsx';

import Expense from './pages/Expense/Expense.jsx';
import EnquaryFilter from './newTestPrep/components/Filter/EnquaryFilter.jsx';
import ViewPaymentsAccounts from './newTestPrep/pages/accounts/ViewPaymentsAccounts.jsx';
import RefundRequests from './newTestPrep/pages/accounts/RefundRequest.jsx';

import InvoiceForm from './newTestPrep/components/Invoice/InvoiceForm.jsx';

import ViewPaidPaymentsAccounts from './newTestPrep/pages/accounts/ViewPaidPayments.jsx';
import AddBulkTaskForm from './newTestPrep/pages/import/TaskImport.jsx';
import Report from './pages/CenterReport/Report.jsx';

import NoteImport from './newTestPrep/pages/import/NoteImport.jsx';
import DataEntryDashboard from './newTestPrep/pages/dashboard/dataentry/DataEntryDashboard.jsx';
import AccountantDashboard from './newTestPrep/pages/dashboard/accountant/AccountantDashboard.jsx';


import Raiceticket from './pages/RaiseTicket/Raiseticket.jsx';
import Allticket from './pages/RaiseTicket/Allticket.jsx';
import TicketInfo from './pages/RaiseTicket/TicketInfo.jsx';
import DeveloperDashboard from './newTestPrep/pages/dashboard/developer/DeveloperDashboard.jsx';
import TaskPage from './newTestPrep/pages/enquiry/tasks/TaskPage.jsx';

import LessonPlanTable from './pages/LessonPlan/ViewLessonPlan.jsx';
import AdminRevenue from './pages/AdminRevenue/AdminRevenue.jsx';
import AdminNewDashboard from './newTestPrep/pages/dashboard/admin/AdminNewDashboard.jsx';
import ClassList from './newTestPrep/pages/activestudents/ClassList.jsx';
import LessonPlan from './pages/LessonPlan/LessonPlan.jsx';
import LessonPlanList from './pages/LessonPlan/LessonPlanList.jsx';
import ActiveStudentTable from './newTestPrep/pages/activestudents/ViewActiveStudents.jsx';
import RegisterSvo from './newTestPrep/pages/RegisterSvo/RegisterSvo.jsx';
import Campaign from './newTestPrep/pages/Campaign/Campaign.jsx';
import Discount from './pages/Discount/Discount.jsx';

import DiscountDetails from './pages/Discount/DiscountDetails.jsx';
import PersistentNotification from './components/notification/PersistentNotification.jsx';
import CashCollections from './newTestPrep/pages/payments/CashCollections.jsx';
import ApproveCashDeposits from './newTestPrep/pages/payments/ApproveCashDeposits.jsx';
import AdvisorReport from './pages/AdvisorReport/AdvisorReport.jsx';
import AdvisorRevenue from './pages/advisorRevenue/AdvisorRevenue.jsx';
import OnlinePayments from './newTestPrep/pages/payments/OnlinePayments.jsx';
import ApprovedPayments from './newTestPrep/pages/payments/ApprovedPayments.jsx';
import ApproveOnlinePayments from './newTestPrep/pages/payments/ApproveOnlinePayments.jsx';
import FutureEnrollmentPanel from './newTestPrep/pages/futureEnrollment/FutureEnrollmentPanel.jsx';
import RequestCallBack from './newTestPrep/pages/requestCallBack/RequestCallBack.jsx';
import StudentLeaveRequests from './newTestPrep/pages/leaverequests/StudentLeaveRequests.jsx';
import RegistrationStatus from './newTestPrep/pages/Events/RegistrationStatus.jsx';
import AdvisorTaskDataGrid from './section/tasks/AdvisorTaskDataGrid';

import ViewTodaysEnquiry from './views/admin/enquiries/ViewTodaysEnquiry.jsx';

import EventRoadmap from './pages/EventRoadmap/EventRoadmap.jsx';
import EnquirySummary from './views/admin/enquiries/EnquirySummary.jsx';
import StudentInformation from './newTestPrep/pages/EventQr/StudentInformation.jsx';
import ScanQr from './newTestPrep/pages/EventQr/ScanQr.jsx';
import RecycleRequest from './newTestPrep/pages/RecycleRequest/RecycleRequest.jsx';
import Tamplate from './newTestPrep/pages/Tamplate/Tamplate.jsx';
import CampaignReport from './pages/CampaignReport/CampaignReport.jsx';
import SvoReport from './newTestPrep/pages/SVOReport/SvoReport.jsx';
import Advisorclass from './newTestPrep/pages/AdvisorClasses/Advisorclass.jsx';
import TaskReports from './newTestPrep/pages/TaskReport/TaskReport.jsx';
import EventAttendeesImport from './newTestPrep/pages/import/EventAttendeesImport.jsx';
import EmployeeMeeting from './newTestPrep/pages/employeeMeeting/EmployeeMeeting.jsx';
import EmployeeLeaveRequests from './newTestPrep/pages/leave/EmployeeLeaveRequests.jsx';
import WorkReport from './newTestPrep/pages/workReport/WorkReport.jsx';
import WalkInReport from './newTestPrep/pages/WalkInReport/WalkInReport.jsx';
import DemoCalendar from './newTestPrep/pages/AdvisorClasses/DemoCalendar.jsx';

import AdvisorReportPdf from './newTestPrep/pages/workReport/AdvisorReportPdf.jsx';
import TrainerReportPdf from './newTestPrep/pages/workReport/TrainerReportPdf.jsx';
import CenterReportPdf from './pages/CenterReport/CenterReportPdf.jsx';
import EnglishTestMastDesk from './newTestPrep/pages/EventQr/EnglishTestMastDesk.jsx';
import RegistrationDesk from './newTestPrep/pages/EventQr/RegistrationDesk.jsx';
import CounsellingDesk from './newTestPrep/pages/EventQr/CounsellingDesk.jsx';
import EnglishTestMasterDesk from './newTestPrep/pages/EventQr/EnglishTestMastDesk.jsx';
import DeclinedPayment from './newTestPrep/pages/payments/DeclinedPayment.jsx';
import EmployeeProfile from './newTestPrep/pages/employeeProfile/AllEmployeeProfile.jsx';
import EditEmployeeProfile from './newTestPrep/pages/employeeProfile/EditEmployeeProfile.jsx';
import WalkInQR from './newTestPrep/pages/walkinQR/WalkInQR.jsx';
import WalkInLeads from './newTestPrep/pages/walkInLeads/WalkInLeads.jsx';
import Page401 from './pages/Page401.jsx';
import Page403 from './pages/Page403.jsx';
import Page440 from './pages/Page440.jsx';
import AllEmployeeStatus from './newTestPrep/pages/EmployeeStatus/AllEmployeeStatus.jsx';
import AllholydayRequest from './newTestPrep/pages/holidayRequest/AllholydayRequest.jsx';
import AllEmployeeTraget from './newTestPrep/pages/employeeTargets/AllEmployeeTraget.jsx';
import AdvisorTargetRevenue from './pages/advisordashboard/AdvisorTargetRevenue.jsx';
import TestTypeEnrollmentReport from './pages/TestTypeEnrollmentsRevenue/TestTypeEnrollmentReport.jsx';
import AdvisorTargetReportPdf from './pages/advisordashboard/AdvisorTargetReportPdf.jsx';
import DownloadTestTypeReport from './pages/TestTypeEnrollmentsRevenue/DownloadTestTypeReport.jsx';
import AdvisorTaskWiseReport from './newTestPrep/pages/AdvisorTaskWiseReport/AdvisorTaskWiseReport';
import DownloadTaskWiseReport from './newTestPrep/pages/AdvisorTaskWiseReport/DownloadTaskWiseReport.jsx';
import CenterAdminDashboard from './pages/AllUserDashboards/CenterAdminDashboard/CenterAdminDashboard.jsx';
import AdvisorNewDashBoard from './pages/AllUserDashboards/AdvisorNewDashBoard/AdvisorNewDashBoard.jsx';
import TrainerNewDashBoard from './pages/AllUserDashboards/TrainerNewDashBoard/TrainerNewDashBoard.jsx';
import NewLayout from './newTestPrep/layout/newLayout/index.js';
import AdminNewDashBoardUi from './pages/AllUserDashboards/AdminNewDashBoardUi/AdminNewDashBoardUi.jsx';
import AccountantdashBoardUi from './pages/AllUserDashboards/AccountantNewDashBoard/AccountantdashBoardUi.jsx';
import DataEntryNewDashBoard from './pages/AllUserDashboards/DataEntryNewDashboard/DataEntryNewDashBoard.jsx';
import GradlynkStudentPanelLeads from './newTestPrep/pages/GradlynkStudentPanelLeads/GradlynkStudentPanelTable.jsx';
import MissedAutoAssignLeads from './newTestPrep/pages/missedAutoAssignLeads/MissedAutoAssignLeads.jsx';
import AllPendingPaymnts from './newTestPrep/pages/payments/pendingPayments/AllPendingPaymnts.jsx';
import TestTypeWiseEnquiryReport from './newTestPrep/pages/centerAdminReports/testTypeWiseEnquiry/TestTypeWiseEnquiryReport.jsx';
import AdminBranchDashBoard from './pages/CenterReport/branchDashboard/AdminBranchDashBoard.jsx';
import SubServiceDetailsPdf from './newTestPrep/pages/centerAdminReports/testTypeWiseEnquiry/SubServiceDetailsPdf.jsx';







const API_ENDPOINT = process.env.REACT_APP_API_SOCKET;


function App() {
  const { user } = useContext(UserContext);
 //const [userRole2, setUserRole2] = useState(localStorage.getItem('userRole'));
 const userRole = useSelector((state) => state.user.role);
 const userId = localStorage.getItem('userId');
 const userRole2 = localStorage.getItem('userRole');
 const [socket, setSocket] = useState(null)
 const [showPersistentNotification, setShowPersistentNotification] = useState(false);
 const [message, setMessage] = useState('New Notification')
 
  // Use useEffect to update userRole whenever user changes

 /* useEffect(() => {
    setSocket(io(`${API_ENDPOINT}`))

    return () => {
      socket?.disconnect();
    }
  }, [])

  useEffect(() => {
    //socket?.emit('newUser', userId)

    socket?.on("connect", () => {
      console.log("Connected to Socket.io server");
      socket.emit("newUser", userId); // Assuming userId is available in your context
    });

    socket?.on("demoBooked", (data) => {
      console.log("Demo booked:", data);
      // Handle notification logic for the trainer here
      // Show toast notification when "demoBooked" event is received
      //toast.success(data.message);
      setMessage(data?.message)
      setShowPersistentNotification(true);
      playNotificationSound();

    });

    socket?.on("alertNotification", (message) => {
      
      // Show toast notification
      toast.success(message);

      //setShowPersistentNotification(true
      // Play notification sound when toast notification is displayed
      playNotificationSound();
    });

    const playNotificationSound = () => {
      const sound = new Howl({
        src: ["/notifications-sound-127856.mp3"],
        
        //autoplay: true,
        loop: true,
      });
      sound.play();

      // Set a timeout to stop the sound after 5 seconds
  setTimeout(() => {
    sound.stop();
  }, 5000);
    };
    
    return () => {
      socket?.off("connect");
      socket?.off("demoBooked");
      socket?.off("alertNotification")
    };

  }, [socket, userId])


  console.log(userRole)*/

  const handleMarkRead = () => {
    // Logic to mark notification as read
    setShowPersistentNotification(false); // Hide the persistent notification
  };

  /*useEffect(() => {
    function setZoom() {
      if (window.matchMedia('(min-width: 780px) and (max-width: 1280px)').matches) {
        document.body.style.zoom = '80%';
      } else {
        document.body.style.zoom = '100%';
      }
    }

    // Call the function to set the zoom on page load
    setZoom();

    // Handle the window resize event
    window.addEventListener('resize', setZoom);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', setZoom);
  }, []);*/
  
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes}>
          <CssBaseline />
          <NavigationScroll>
         
      <Routes>
        <Route path="/login" element={<Login5 />} />
       
        <Route path='/' element={<LandingPage />} />
        <Route path='/register' element={<Register />} />
        <Route path='/center-registration' element={<RegistrationPage />} />
        <Route path='/role' element={<RolePage />} />
           <Route path='/page404' element={<Page404/>} />
           <Route path='/page500' element={<Page500Server/>} />
           <Route path='/page401' element={<Page401/>} />
           <Route path='/page403' element={<Page403/>} />
           <Route path='/page440' element={<Page440/>} />
       {/*} <Route path="/" element={<SignUp />} />*/}

        <Route path='/receipt/:receiptId' element={<InvoiceForm/>} />
        <Route path='/scanqr-code' element={<ScanQr />} />
        <Route path='/student-information/:id' element={<StudentInformation />} />
        <Route path='/counselling-desk' element={<CounsellingDesk />} />
        <Route path='/scanqr-counsellingdesk' element={<EnglishTestMasterDesk />} />
        <Route path='/registration-desk' element={<RegistrationDesk />} />
        <Route path="/work-report-details" element={< AdvisorReportPdf/>} />
        <Route path="/work-report-generate" element={< TrainerReportPdf/>} />
        <Route path="/view-center-report" element={< CenterReportPdf/>} />
        <Route path="/view-test-type-wise-report" element={< DownloadTestTypeReport/>} />
        <Route path ="advisor-target-report-pdf" element={<AdvisorTargetReportPdf/>}  />
        <Route path ="/advisor-target-report-pdf" element={<AdvisorTargetReportPdf/>}  />
        <Route path ="/view-task-wise-report-pdf" element={<DownloadTaskWiseReport/>}  />
        <Route path ="/view-sub-service-wise-report-pdf" element={<SubServiceDetailsPdf/>}  />
        <Route
          path="/*"
          element={
            userRole !== null || userRole2 !== null ? (
              <NewLayout socket={socket}>
                <NavigationScroll>
                <PersistentNotification
                  message={message}
                  open={showPersistentNotification}
                  handleClose={() => setShowPersistentNotification(false)}
                  handleMarkRead={handleMarkRead}
                />
                <Routes>
                <Route
                    path="/dashboard"
                    element={
                      (userRole2 === 'admin' || userRole === 'admin') ? (
                        // <AdminNewDashboard />
                        <AdminNewDashBoardUi/>
                      ) : (userRole2 === 'trainer' || userRole === 'trainer') ? (
                        // <TrainerDashboard />
                     <TrainerNewDashBoard/>
                      ) : (userRole2 === 'advisor' || userRole === 'advisor') ? (
                        // <AdvisorDashboard />
                        <AdvisorNewDashBoard/>
                      ) : (userRole2 === 'center_admin' || userRole === 'center_admin') ? (
                        // <AdminRevenue />

                        <CenterAdminDashboard/>
                      ) : (userRole2 === 'data_entry' || userRole === 'data_entry') ? (
                        // <DataEntryDashboard />
                        <DataEntryNewDashBoard/>
                      ) : (userRole2 === 'accountant' || userRole === 'accountant') ? (
                        // <AccountantDashboard />
                        <AccountantdashBoardUi/>
                      ) : (userRole2 === 'developer' || userRole === 'developer') ? (
                        <DeveloperDashboard />
                      ) :
                       (
                        // Handle the case where none of the conditions match
                        <Navigate to="/page404" />
                      )
                    }
                  />
                
               {/*} <Route path="/dashboard" element={<Dashboard />} />*/}
                <Route path="/test-types" element={<TestType />} />
                <Route path="/employees" element={<EmployeeCardPage />} />
                <Route path="/branches" element={<BranchesTable />} />
                <Route path='/branch' element={<BranchesPage />} />
                <Route path='/branch-data/:id' element={<BranchDashboard />} />
                <Route path="/add-classes" element= {<MainTrainerPage />} />
                <Route path="/packages" element= {<PackagesList />} />
                <Route path="/all-demos" element= {<DemosTable />} />
                <Route path="/enroll-student" element={<EnrollStudent />} />
                <Route path='/view-attendance' element={<ViewAttendance />} />
                <Route path='/take-attendance' element={<TakeAttendance />} />
                <Route path='/all-payments' element={<PaymentsDataGrid />} />
                <Route path='/view-students' element={<StudentsListPage />} />
                <Route path='/view-student/:id' element={<StudentDetails />} />
                <Route path='/addbulkenquiries' element={<AddBulkEnquiryForm />} />
                <Route path='/add-package' element={<AddPackage />} />
                <Route path='/all-tasks' element={<AdvisorTaskDataGrid />} />
                <Route path="/classes" element={<AdminClasses />} />
                <Route path="/class/:classId" element={<ClassInfo />} />
                <Route path='/demos' element={<AdminDemos />} />
                <Route path='/demodetails/:demoId' element={<DemoDetails />} />
                <Route path='/addenquirynew' element={<AddEnquiryNew />} />
                <Route path='/incoming-call' element={<AddIncomingCall />} />
                 <Route path='/walk-in-form' element={<AddWalkIn />} />
                 <Route path='/enquiries' element={<ViewEnquiriesDataGrid />} />
                <Route path='/enquirydetails/:leadNo' element={<EnquiryDetails />} />
                <Route path='/notifications' element={<ViewAdvisorNotifications />} />
                <Route path='/viewstudymaterial' element={<StudyMaterialPage />} />
                <Route path='/leadconversionreport' element={<LeadConversation/>} />
                <Route path='/leadconversation' element={<MainPage/>} />
                <Route path='/admindashboard' element={<AdminMainPage/>} />
                <Route path='/admindashboardoverview' element={<AdminDasboardOverview/>} />
                <Route path='admindashboardstudent' element={<AdminDashboardStudent/>} />
                <Route path='/admindashboardrevenue' element={<AdminDashboardRevenue/>} />
                <Route path='/admindashboardreport' element={<MainTabs/>} />

                <Route path='/expense' element={<Expense/>} />

                <Route path='/filter' element={<EnquaryFilter/>} />

                <Route path='/pendingaccounts' element={<ViewPaymentsAccounts />} />
                <Route path='/paidaccounts' element={<ViewPaidPaymentsAccounts />} />
                <Route path='/refundrequests' element={<RefundRequests />} />

                <Route path='/addbulktasks' element={<AddBulkTaskForm />} />
                <Route path='/centerreport' element={<Report/>}/>

                <Route path='/addbulknotes' element={<NoteImport />} />
                <Route path='/lessonplan' element={<LessonPlan/>}/>
                <Route path='raiseticket' element={<Raiceticket/>}/>
                <Route path='/allticket' element={<Allticket/>} />
                <Route path='/allticket/:ticketId' element={<TicketInfo/>} />
                <Route path='/enquiry/task' element={<TaskPage/>} />

                <Route path='/classlist' element={<ClassList />} />
                <Route path='/viewactivestudents' element={<ActiveStudentTable />} />
                <Route path='/viewlessonplan' element={<LessonPlanTable />} />
                <Route path='/adminrevenue' element={<AdminRevenue/>} />
                <Route path='/lessonplanlist/:classId' element={<LessonPlanList/>} />

                {/* <Route path='/centerdashboard/:id' element={<AdminNewDashboard />} /> */}
                <Route path='/centerdashboard/:id' element={<AdminBranchDashBoard />} />
                <Route path='/register-svo' element={<RegisterSvo/>} />
                <Route path='/campaign' element={<Campaign />} />
                <Route path ='/discount' element={<Discount/>} />
                <Route path='/advisorrevenue/:id' element={<AdvisorRevenue/>} />
                <Route path="/discountdetails/:id" element={<DiscountDetails />} />

                <Route path='/advisorreport' element={<AdvisorReport/>} />
                <Route path='/cash-collections' element={<CashCollections />} />
                <Route path='/onlinepayments' element={<OnlinePayments />} />
                <Route path='/approvedpayments' element={<ApprovedPayments/>} />

                <Route path='/approvedepositslip' element={<ApproveCashDeposits/>} />
                <Route path='/approveonlinepayments' element={<ApproveOnlinePayments />} />
                <Route path='/declinepayment' element={<DeclinedPayment />} />
                <Route path='/pendingpayments' element={<AllPendingPaymnts />} />
                <Route path='/futureenrolments' element={<FutureEnrollmentPanel />} />
                <Route path='/requestcallbacks' element={<RequestCallBack />} />
                <Route path='/studentleaverequests' element={<StudentLeaveRequests />} />
                <Route path='/event-report' element={<RegistrationStatus />} />
                <Route path='/todaysenquiry' element={<ViewTodaysEnquiry />} />

                <Route path='/event-roadmap' element={<EventRoadmap />} />

                <Route path='/student-qr' element={<StudentInformation />} />
                
                <Route path='/enquiry-summary/:personalid' element={<EnquirySummary />} />
               
                <Route path='/recyle-request' element={<RecycleRequest />} />
                <Route path='/add-tamplate' element={<Tamplate />} />
                <Route path='/campaign-report' element={<CampaignReport />} />
                <Route path='/svo-report' element={<SvoReport />} />
                <Route path='/classeslist' element={<Advisorclass />} />
                <Route path='/task-report' element={<TaskReports />} />

                <Route path='/addbulkeventattendees' element={<EventAttendeesImport />} />
                <Route path='/employee-meeting' element={<EmployeeMeeting />} />
                <Route path='/leaverequests' element={<EmployeeLeaveRequests />} />
                <Route path='/work-report' element={<WorkReport />} />
                <Route path='/walkins' element={<WalkInReport />} />
                <Route path="/slot-details/:id" element={<DemoCalendar />} />
                <Route path="/employees-profile" element={<EmployeeProfile />} />
                <Route path="/view-profile/:id" element={<EditEmployeeProfile />} />
                <Route path="/generarebranchqr" element={<WalkInQR />} />
                <Route path='/walkinleads' element={<WalkInLeads />} />
                <Route path='/employee-status' element={<AllEmployeeStatus />} />
                <Route path='/holiday-request' element={<AllholydayRequest />} />
                <Route path='/employee-target' element={<AllEmployeeTraget />} />
                <Route path='/advisor-target' element={<AdvisorTargetRevenue />} />
                <Route path='/test-type-wise-revenue' element={<TestTypeEnrollmentReport />} />
                <Route path ="advisor-task-wise-revenue" element={<AdvisorTaskWiseReport/>}  />
                <Route path ="/test-Type-wise-enquiries" element={<TestTypeWiseEnquiryReport/>}  />
                <Route path='/new-page' element={<NewLayout />} />
                <Route path="/gradlynk-studentpanel-leads" element={<GradlynkStudentPanelLeads />} />
                <Route path="/missed-autoassign-leads" element={<MissedAutoAssignLeads />} />
                </Routes>
                </NavigationScroll>
              </NewLayout>
            ) : (
              <Navigate to="/page404" />
            )
          }
        />

      {/*}  <Route
          path="/advisor/*"
          element={
            userRole === 'advisor' || userRole2 === 'advisor' ? (
              <AdvisorLayout>
                <Routes>
                <Route path="/dashboard" element={<AdvisorDashboard />} />
                <Route path='/addenquirynew' element={<AddEnquiryNew />} />
                <Route path='/incoming-call' element={<AddIncomingCall />} />
                 <Route path='/walk-in-form' element={<AddWalkIn />} />
                 <Route path='/enquiries' element={<LeadsDataGrids />} />
                <Route path='/enquirydetails/:leadNo' element={<EnquiryDetails />} />
                <Route path='/advisornotifications' element={<ViewAdvisorNotifications />} />
                <Route path='/messenger' element={<ChatApp />} />
                <Route path='/advisorCalendar' element={<AdvisorCalendar />} />
                <Route path='/myprofile' element={<AdvisorProfile />} />
                </Routes>
              </AdvisorLayout>
            ) : (
              <Navigate to="/" />
            )
          }
        />

      <Route
          path="/trainer/*"
          element={
            userRole === 'trainer' || userRole2 === 'trainer' ? (
              <TrainerLayout>
                <Routes>
                <Route path="/dashboard" element={<TrainerDashboard />} />
                <Route path="/classes" element={<TrainerClasses />} />
                <Route path="/class/:classId" element={<ClassInfo />} />
                <Route path='/demos' element={<TrainerDemosPage />} />
                <Route path='/addenquirynew' element={<AddEnquiryNew />} />
                <Route path='/incoming-call' element={<AddIncomingCall />} />
                 <Route path='/walk-in-form' element={<AddWalkIn />} />
                 <Route path='/enquiries' element={<TrainerEnquiries />} />
                <Route path='/enquirydetails/:leadNo' element={<EnquiryDetails />} />
                <Route path='/notifications' element={<ViewAdvisorNotifications />} />
                <Route path='/messenger' element={<ChatApp />} />
                <Route path='/calendar' element={<AdvisorCalendar />} />
                <Route path='/viewstudymaterial' element={<StudyMaterialPage />} />
                <Route path='/myprofile' element={<TrainerProfile />} />
                </Routes>
              </TrainerLayout>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        
        {/* Add other routes as needed */}
        
      </Routes>
      </NavigationScroll>
      </ThemeProvider>
      </StyledEngineProvider>
      </>

   /* <LeadProvider>
    <Routes>
      <Route path="/" element={<SignUp/>} />
      <Route path="/leadinfo" element={<Personalinfo />} />
      <Route path="/addlead" element={<AddLead />} />
      <Route path="/qualification" element={<Qualification />} />
      <Route path="/testType" element={<Testinfo />} />
      <Route path="/leads" element={<Leads />} />
      <Route path="/lead/:id" element={<Lead />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/verify" element={<VerifyEmail />}/>
      <Route path='/login' element={<Login2 />} />
      <Route path='/leadDashboard' element={<LeadDashboard />} />
      <Route path='/leadcalendar' element={<LeadCalendar2 />}  />
      <Route path='/leadCreatePopUp' element={<LeadCreatePop />} />
      <Route path='/advisor' element={<AdminDashboard />} />
      <Route path='/leaddetails' element={<LeadDetails />} />
      <Route path='/advisorCalendar' element={<AdvisorCalendar />} />
      <Route path='/studentstatus' element={<StudentStatusForm />} />
      <Route path='/student' element={<StudentProfile />} />
      <Route path='/preview' element={<StudentPreview />} />
      <Route path='/studentslist' element={<StudentsList />} />
      <Route path='/employees' element={<EmployeesTable />} />
      <Route path='/products' element={<ProductData />} />
      <Route path='/courses' element={<CoursesData />} />
      <Route path='/batches' element={<BatchData />} />
      <Route path='/leadprofile' element={<LeadProfilePage />} />
      <Route path='/addleadinfo' element={<AddLeadInformation />} />
      <Route path='/test' element={<TestTab />} />
      <Route path='evaluationlist' element={<PTEEvaluation />} />
      <Route path='/createlead' element={<CreateLead />} />
      <Route path='/advisordashboard' element={<AdvisorDashboard />} />
      <Route path='/enquiries' element={<LeadsDataGrids />} />
      <Route path='/enquirydetails/:leadNo' element={<EnquiryDetails />} />
      <Route path='/addenquirynew' element={<AddEnquiryNew />} />
      <Route path='/incoming-call' element={<AddIncomingCall />} />
      <Route path='/walk-in-form' element={<AddWalkIn />} />
      <Route path='/tasks' element={<AdvisorTasks />} />
      <Route path='/messenger' element={<ChatApp />} />
      <Route path='/taskreport' element={<TaskReport />}/>
      <Route path='/advisornotifications' element={<ViewAdvisorNotifications />} />
      <Route path='/taskreportgrid' element={<TaskReportGrid />} />
      <Route path='/trainerdashboard' element={<TrainerDashboard/>} />
      <Route path='/admindashboard' element={<AdminDashboard2 />} />
    </Routes> 
  </LeadProvider>*/



  )
}

export default App;


  