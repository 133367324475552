import { createTheme } from "@mui/material/styles";

const CustomTextFieldtheme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "12px",
        },
        outlined: {
          transform: "translate(14px, 14px) scale(1)",
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "7px",
          "&.MuiInputBase-sizeSmall": {
            fontSize: "0.6rem",
            height: "23px",
            padding: "4px 8px",
          },
          "&.MuiInputBase-sizeNormal": {
            fontSize: "0.7rem !important",
            height: "27px !important",
          
          },
          "&.MuiInputBase-sizeMedium": {
            fontSize: "0.8rem !important",
            height: "33px !important",
            padding: "6px 12px !important",
          },
          "&.MuiInputBase-sizeLarge": {
            fontSize: "1rem",
            height: "48px",
            padding: "8px 16px",
          },
        },
        input: {
          fontSize: "inherit",
          "&.MuiInputBase-inputSizeSmall": {
            padding: "4px",
          },
          "&.MuiInputBase-inputSizeMedium": {
            padding: "6px",
          },
          "&.MuiInputBase-inputSizeLarge": {
            padding: "8px",
          },
        },
        notchedOutline: {
          // borderRadius: "7px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // Default font size, will be overridden dynamically
          fontSize: "0.75rem", // Default size for normal input
        },
      },
    },
  },
  overrides: {
    MuiMenuItem: {
      root: {
        "&.MuiInputBase-sizeSmall": {
          fontSize: "0.6rem", // Font size for small input
        },
        "&.MuiInputBase-sizeNormal": {
          fontSize: "0.7rem", // Font size for normal input
        },
        "&.MuiInputBase-sizeMedium": {
          fontSize: "0.8rem", // Font size for medium input
        },
        "&.MuiInputBase-sizeLarge": {
          fontSize: "1rem", // Font size for large input
        },
      },
    },
  },
});

export default CustomTextFieldtheme;
