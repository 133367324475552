import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CheckIcon from "@mui/icons-material/Check";
import MainCard from "../../../components/Maincard";
import CustomPopup from "../../../components/CustomPopup";
import AddTemplateForm from "./AddTemplateForm";
import EditIcon from "@mui/icons-material/Edit";
import EditTemplateForm from "./EditTemplateForm";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";
import ApplyFilterButton from "../../newComponent/NewButtonComp/ApplyFilterButton";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";
import SearchButton from "../../newComponent/NewButtonComp/SearchButton";
import ResetFilterButton from "../../newComponent/NewButtonComp/ResetFilterButton";
import ResetButtonComp from "../../newComponent/NewButtonComp/ResetButtonComp";
import ClearButtonComp from "../../newComponent/NewButtonComp/ResetButtonComp";
import EmailData from "./EmailData";
const PATH_URL = process.env.REACT_APP_API_UPLOADS;
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function Tamplate() {
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const [templatelist, setTemplatelist] = useState([]);
  const [templateTitle, setTemplateTitle] = useState("");
  const [loader, setLoader] = useState(false);
  const [openRow, setOpenRow] = useState(null);
  const [openViewTemplate, setOpenViewTemplate] =useState(false)
  const [addTemplatePopup, setAddtemplatePopup] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [originalData, setOriginaldata] = useState([]);
  const [editopenpopup, setEditOpenpopup] = useState(false);
  const [selecteddata, setSelecteddata] = useState(null);
  const handleOpenPopup = () => {
    setAddtemplatePopup(true);
  };

  const handleclosePopup = () => {
    setAddtemplatePopup(false);
  };

  const handleOpenEditPopup = (data) => {
    setEditOpenpopup(true);
    setSelecteddata(data);
  };

  const handlecloseEditPopup = () => {
    setEditOpenpopup(false);
  };

  useEffect(() => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        setLoader(true);
        const response = await fetch(`${API_ENDPOINT}route/fetchtemplate`, {
          headers,
        });
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setTemplatelist(sortedData);
          console.log(sortedData);
          setOriginaldata(sortedData);
          setLoader(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch {
        navigate("/page500");
      }
    };

    fetchData();
  }, []);

  const handleSearch = () => {
    const filtered = originalData.filter((template) =>
      template.template_title
        .toLowerCase()
        .includes(templateTitle.toLowerCase())
    );
    setTemplatelist(filtered);
  };

  const handlereset = () => {
    setTemplateTitle("");
    setTemplatelist(originalData);
  };

  // const toggleRow = (index) => {
  //   setOpenRow((prevOpenRow) => (prevOpenRow === index ? null : index));
  // };
  const openEmailPopup=(data)=>{
    setOpenViewTemplate(true)
    setSelecteddata(data)

  }
   const CloseViewEmailPopup=()=>{
    setOpenViewTemplate(false)

   }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, templatelist.length - page * rowsPerPage);

  return (
    <>
      <Backdrop open={loader} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={12} lg={2} sm={3} md={2.5}>
          <HeadingCard label="Email Template" />
        </Grid>
        <Grid item xs={12} sm={9} md={9.5} lg={10} textAlign="end">
          <ApplyFilterButton
            addbutton={true}
            onClick={handleOpenPopup}
            label="Add Template"
          />
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} sm={6} md={6}>
                <Typography variant="h5">Search By Template Title</Typography>
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                  value={templateTitle}
                  onChange={(e) => setTemplateTitle(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6} sm={6} lg={6} container>
                <Typography
                  variant="h5"
                  sx={{
                    visibility: "hidden",
                  }}
                >
                  Search By Template Title
                </Typography>
                <Grid container gap={1}>
                  <SearchButton onClick={handleSearch} />

                  <ClearButtonComp onClick={handlereset} />
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={12} md={12} sm={12}>
        <MainCard>
          <TableContainer component={Paper}>
            <Table
              aria-label="simple table"
              sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
            >
              <TableHead>
                <TableRow sx={{ height: "10px" }}>
                  <TableCell
                    sx={{
                      background: "#E1EBEE",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      padding: "10px",
                      lineHeight: "1",
                      verticalAlign: "top",
                      fontWeight: 600,
                      fontSize: "0.7rem",
                    }}
                  >
                    Template Name
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "#E1EBEE",
                      height: "10px",
                      padding: "10px",
                      lineHeight: "1",
                      verticalAlign: "top",
                      textAlign: "center",
                      fontWeight: 600,
                      fontSize: "0.7rem",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loader && templatelist.length === 0 ? (
                  <Typography>No data</Typography>
                ) : (
                  (rowsPerPage > 0
                    ? templatelist.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : templatelist
                  ).map((item, index) => (
                    <React.Fragment key={item.id}>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            fontSize: "0.7rem",
                            padding: "10px",
                            lineHeight: "1",
                          }}
                        >
                          {item.template_title}

                          {/* {openRow === index && (
                            <>
                              {item.email_subject && (
                                <Typography sx={{ marginTop: "10px" }}>
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      marginTop: "10px",
                                    }}
                                  >
                                    Subject :-
                                  </Typography>
                                  {item.email_subject}
                                </Typography>
                              )}
                              <Typography sx={{ marginTop: "10px" }}>
                                <Typography
                                  variant="h4"
                                  sx={{
                                    marginTop: "10px",
                                  }}
                                >
                                  Body :-
                                </Typography>
                                {parse(String(item.description))}
                              </Typography>

                              <Grid item xs={12}>
                                <Typography variant="h4">
                                  {item?.attachments?.length > 0 &&
                                    "Email Attachments"}{" "}
                                </Typography>

                                {item?.attachments?.map((image) => (
                                  <Box
                                    sx={{
                                      position: "relative", // Ensure positioning context
                                      height: "40px",
                                      margin: "10px",
                                      border: "1px solid #D5D5D5",
                                      display: "inline-block",
                                      padding: "5px",
                                      borderRadius: "10px",
                                      background: "#E1EBEE",
                                      cursor: "pointer",
                                    }}
                                    key={image.id}
                                  >
                                    <Typography
                                      mt={1}
                                      onClick={() =>
                                        window.open(
                                          `${PATH_URL}emailTemplateAttachments/${image.file_uploaded}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      {image.file_name}
                                    </Typography>
                                  </Box>
                                ))}
                              </Grid>
                            </>
                          )} */}
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center", // Center horizontally
                            alignItems: "center",
                            padding: "10px",
                            lineHeight: "1",
                          }}
                        >
                          <Box
                            onClick={() => openEmailPopup(item)}
                            sx={{
                              height: "15px",
                              width: "18px",
                              borderRadius: "40%",
                              background: "blue",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            <RemoveRedEyeOutlinedIcon
                              fontSize="small"
                              style={{ fontSize: "0.8rem" }}
                            />
                          </Box>
                          <Box
                            onClick={() => handleOpenEditPopup(item)}
                            sx={{
                              height: "15px",
                              width: "18px",
                              borderRadius: "40%",
                              background: "green",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginLeft: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <EditIcon
                              fontSize="small"
                              style={{ fontSize: "0.8rem" }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={templatelist.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </MainCard>
      </Grid>

      <CustomPopup
        open={addTemplatePopup}
        onClose={handleclosePopup}
        component={<AddTemplateForm onClose={handleclosePopup} />}
        title="Add Template"
        maxWidth="sm"
        showDivider={true}
      />

      <CustomPopup
        open={editopenpopup}
        onClose={handlecloseEditPopup}
        component={
          <EditTemplateForm
            onClose={handlecloseEditPopup}
            selecteddata={selecteddata}
            setTemplatelist={setTemplatelist}
          />
        }
        title="Edit Template"
        maxWidth="sm"
        showDivider={true}
      />
       <CustomPopup
              open={openViewTemplate}
              onClose={CloseViewEmailPopup}
              component={
                <EmailData
                  onClose={CloseViewEmailPopup}
                  data={selecteddata}
                />
              }
              title="Template Details"
              showDivider="true"
              maxWidth="sm"
            />
    </>
  );
}
