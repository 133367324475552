import { Box, Typography } from '@mui/material'
import React from 'react'
import { fDate } from '../../../utils/formatTime'

export default function UpcommingEventBox({background, color, borderLeftcolor,eventData}) {

  return (
  <>
    <Box
        sx={{
          borderRadius: "0px 15px 15px 0px ",
          background: background,
          padding: "5px",
          borderLeft:`3px solid ${borderLeftcolor}`,
        }}
      >
        <Typography
   variant='h7'
          sx={{
          
            fontWeight: 600,
            color:color,
          }}
        >
        {eventData?.campaign_name}
        </Typography>
        <Typography
 variant='h7'
          sx={{
            
            color: "#4D4D4D",
          }}
        >
        {fDate (eventData?.campaign_start_date)} - {fDate (eventData?.campaign_end_date)}
        </Typography>
      </Box>
  </>
  )
}
