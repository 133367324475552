import { InputAdornment, TextField, ThemeProvider } from "@mui/material";
import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import CustomTextFieldtheme from "../../themes/textFieldTheme";

export default function SearchTextFieldComp({ width, onChange }) {
  return (
    <>
      <ThemeProvider theme={CustomTextFieldtheme}>
        <TextField
          onChange={onChange}
          placeholder="Search"
          style={{ width: width }}
          size="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </ThemeProvider>
    </>
  );
}
