import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CustomSnackbar from '../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar';
import CloseIcon from "@mui/icons-material/Close";
import CustomAutocompleteComp from '../../../newTestPrep/newComponent/AutocompleteComp';
import CustomTextFieldComp from '../../../newTestPrep/newComponent/customTextFieldComp';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const AddTaskFormPopup = ({ open, onClose, onSubmit, studentId }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const userId = localStorage.getItem('userId');
  const [taskItems, setTaskItems] = useState([]);
  const [taskType, setTaskType] = useState(null);
  const [taskOwner, setTaskOwner] = useState(null);
  const [dueDate, setDueDate] = useState('');
  const [dueTime, setDueTime] = useState('');
  const [wantReminder, setWantReminder] = useState(false);
  const [isUrgent, setIsUrgent] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [followUpReasons, setFollowUpReasons] = useState([]);
  const [followupReasonname, setFollowupReasonName] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleFormSubmit = (data) => {
    // Validate and submit the task data
    const taskData = {
      enquiry_id: studentId,
      type_of_task: taskType?.id,
      task_owner: taskOwner?.id,
      due_date: dueDate,
      due_time: dueTime,
      want_reminder_for_task: wantReminder,
      is_it_urgent: isUrgent,
      user_id:parseInt(userId),
      follow_up_reason_id: followupReasonname?.id,
    };

    fetch(`${API_ENDPOINT}task`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(taskData),
    })
      .then((response) => {
        if (response.status === 200) {
          setTaskType(null);
          setDueDate('');
          setDueTime('');
          setIsUrgent(false);
          setWantReminder(false);

          showSnackbar('Task added successfully', 'success');

          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          showSnackbar('Error adding task. Please try again.', 'error');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        showSnackbar('Error adding task. Please try again.', 'error');
      });

    onSubmit(taskData);
  };

  useEffect(() => {
    fetch(`${API_ENDPOINT}route2.0/typeoftaskroles/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setTaskItems(data);
      })
      .catch((error) => {
        console.error('Error fetching task items:', error);
      });
  }, [userId]);

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/getalluser`)
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.sort((a, b) => {
          const nameA = (a.first_name + ' ' + a.last_name).toUpperCase();
          const nameB = (b.first_name + ' ' + b.last_name).toUpperCase();
          return nameA.localeCompare(nameB);
        });
        setUsers(sortedData);
        const defaultOwner = sortedData.find(
          (owner) => owner.id === parseInt(userId, 10)
        );
        if (defaultOwner) {
          setTaskOwner(defaultOwner);
        }
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, [userId]);

  useEffect(() => {
    fetch(`${API_ENDPOINT}taskfollowupreason`)
      .then((response) => response.json())
      .then((data) => {
        setFollowUpReasons(data);
      })
      .catch((error) => {
        console.error('Error fetching follow up reasons:', error);
      });
  }, []);

  const handleTaskTypeChange = (event, value) => {
    setTaskType(value);
    setSelectedTask(value?.id);
    setValue('type_of_task', value);
  };

  const handleFollowupReasonChange = (event, value) => {
    setFollowupReasonName(value);
    setValue('follow_up_reason_id',value)
  };

  const handleDueDateChange = (e) => {
    setDueDate(e.target.value);
    setValue('task_due_date', e.target.value);
  };

  const handleDueTimeChange = (e) => {
    setDueTime(e.target.value);
    setValue('task_due_time', e.target.value);
  };

  
 const handletaskownerChange =(event,value)=>{
  setTaskOwner(value)
  setValue('task_owner', value)
 }
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle variant="h4">Add Task

        <IconButton
            edge="end"
            onClick={onClose}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 2 }}
          >
            <CloseIcon fontSize='small'/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography style={{ color: 'black' }}>
                Type of Task
                <span
                  style={{
                    color: 'red',
                    position: 'absolute',
                    marginLeft: '4px',
                    fontSize: '1em',
                  }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="type_of_task"
                control={control}
                rules={{ required: 'Field is required' }}
                render={({ field }) => (
                  <CustomAutocompleteComp
                    {...field}
                    disablePortal
                    options={taskItems}
                    getOptionLabel={(option) => option.name_of_task_type}
                    value={taskType}
                    onChange={handleTaskTypeChange}
                    error={Boolean(errors.type_of_task)}
                        helperText={errors.type_of_task?.message || ''}
                   
                     
                  />
                )}
              />
            </Grid>
            {selectedTask === 2 && (
              <Grid item xs={6}>
                <Typography style={{ color: 'black' }}>
                  Follow up reason
                  <span
                    style={{
                      color: 'red',
                      position: 'absolute',
                      marginLeft: '2px',
                      fontSize: '1em',
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="follow_up_reason_id"
                  control={control}
                  rules={{ required: 'Field is required' }}
                  render={({ field }) => (
                    <CustomAutocompleteComp
                      {...field}
                      disablePortal
                      options={followUpReasons}
                      getOptionLabel={(option) => option.follow_up_reason}
                      value={followupReasonname}
                      onChange={handleFollowupReasonChange}
                      error={Boolean(errors.follow_up_reason_id)}
                      helperText={errors.follow_up_reason_id?.message || ''}
                     
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography style={{ color: 'black' }}>
                Due Date
                <span
                  style={{
                    color: 'red',
                    position: 'absolute',
                    marginLeft: '2px',
                    fontSize: '1em',
                  }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="task_due_date"
                control={control}
                rules={{ required: 'Field is required' }}
                render={({ field }) => (
                  <CustomTextFieldComp
                  size='normal'
                    type="date"
                    {...field}
                    InputProps={{
                      style: { borderRadius: '10px' },
                      inputProps: { min: new Date().toISOString().split('T')[0] },
                    }}
                    fullWidth
                    value={dueDate}
                    onChange={handleDueDateChange}
                    error={Boolean(errors.task_due_date)}
                    helperText={errors.task_due_date?.message || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ color: 'black' }}>
                Due Time
                <span
                  style={{
                    color: 'red',
                    position: 'absolute',
                    marginLeft: '2px',
                    fontSize: '1em',
                  }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="task_due_time"
                control={control}
                rules={{ required: 'Field is required' }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size='normal'
                    type="time"
                    {...field}
                    InputProps={{
                      style: { borderRadius: '10px' },
                    }}
                    fullWidth
                    value={dueTime}
                    onChange={handleDueTimeChange}
                    error={Boolean(errors.task_due_time)}
                    helperText={errors.task_due_time?.message || ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} md={6}>
                    <Typography  style={{ color: "black" }}>
                      Task Owner
                      <span
                  style={{
                    color: 'red',
                    position: 'absolute',
                    marginLeft: '2px',
                    fontSize: '1em',
                  }}
                >
                  *
                </span>
                    </Typography>
                    <Controller
                      name="task_owner"
                      control={control}
                      defaultValue={parseInt(userId) || null}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <CustomAutocompleteComp
                          {...field}
                          disablePortal
                          options={users}
                          value={taskOwner}
                          getOptionLabel={(option) =>
                            `${option.first_name ? option.first_name : ""} ${
                              option.last_name ? option.last_name : ""
                            }`
                          }
                          onChange={handletaskownerChange}
                          error={Boolean(errors.task_owner)}
                              helperText={errors.task_owner?.message}
                        
                        />
                      )}
                    />
                  </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={wantReminder}
                    onChange={(e) => setWantReminder(e.target.checked)}
                  />
                }
                label="Want Reminder"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isUrgent}
                    onChange={(e) => setIsUrgent(e.target.checked)}
                  />
                }
                label="Urgent"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color='error' >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(handleFormSubmit)}
            variant="contained"
            color="secondary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default AddTaskFormPopup;
