import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import OnlinePaymentFilter from "./OnlinePaymentFilter";
import { useGetTokenOrLogout } from "../../../utils/token";
import StyledTableContainer from "../../newComponent/TableContainer";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../newComponent/TableCustomeComp/CustomNoRowsOverlay";
import UploadIconcomp from "../../newComponent/iconsComp/UoloadIconcomp";
import SearchTextFieldComp from "../../newComponent/newtextfieldComp/SearchTextFieldComp";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PATH_URL = process.env.REACT_APP_API_UPLOADS;

function OnlinePayments() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [originalData, setOriginalData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const updateSelectedRowStatus = (newStatus) => {
    const updatedPayments = payments.map((payment) => {
      if (payment.id === selectedRow) {
        return { ...payment, collected_status: newStatus };
      }
      return payment;
    });
    setPayments(updatedPayments);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleAddUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("user_id", userId);

      if (selectedRow) {
        formData.append("provesional_payment_id", selectedRow);
      } else {
        // Handle case where selectedRows is empty (optional)

        showSnackbar("No row selected for upload", "error");
        return;
      }

      try {
        const response = await fetch(`${API_ENDPOINT}uploadpaymentscreenshot`, {
          method: "POST",
          body: formData,
        });

        const result = await response.json();

        if (response.status === 201) {
          showSnackbar("Upload successful.", "success");

          setOpenUploadDialog(false);

          updateSelectedRowStatus("deposited");
        } else {
          showSnackbar(
            `Upload stopped. Server returned:,${response.statusText}`,
            "error"
          );
        }
      } catch (error) {
        showSnackbar("Error uploading file", "error");
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    // Fetch expenses data
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    const fetchData = async () => {
      try {
        let url = "";
        // if (!filterCriteria) {
        //   url = `${API_ENDPOINT}route2.0/onlinepayments/${userId}`;
        // } else {
        //   url = `${API_ENDPOINT}route2.0/fetchonlinepayment/${userId}`;
        // }

        url = `${API_ENDPOINT}route2.0/onlinepayments/${userId}`;
        const response = await fetch(url, { headers });
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }

        const data = await response.json();

        const sortedData = data.sort(
          (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
        );

        setPayments(sortedData);
        setSearchData(sortedData);
        setIsLoading(false);
        setOriginalData(sortedData);
      } catch (error) {
        if (error.message.includes("401")) {
          navigate("/page401");
        } else if (error.message.includes("403")) {
          navigate("/page403");
        } else if (error.message.includes("404")) {
          navigate("/page404");
        } else if (error.message.includes("440")) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
        setIsLoading(false);
      }
    };
    fetchData();
  }, [userId]);

  // View Payment ScreenShot
  const handleViewDepositSlip = (filePath) => {
    window.open(`${PATH_URL}paymentScreenshots/${filePath}`, "_blank");
  };
  const ActionButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: "#E5E4E2",
    width: "20px",
    height: "20px",
  }));

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.enquiry_id?.first_name || ""} ${
          params?.row?.enquiry_id?.last_name || ""
        }`,
      renderCell: (params) => (
        <div
          style={{
            color: "#0096FF",

            cursor: "pointer",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          onClick={() =>
            window.open(
              `/enquirydetails/${params?.row.enquiry_id.id}`,
              "_blank"
            )
          }
        >
          {" "}
          {`${
            params?.row?.enquiry_id?.first_name
              ? params?.row?.enquiry_id?.first_name
              : ""
          } ${
            params?.row?.enquiry_id?.last_name
              ? params?.row?.enquiry_id?.last_name
              : ""
          }`}{" "}
        </div>
      ),
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row?.enquiry_id?.mobile || "",
      renderCell: (params) => <div>{params?.row.enquiry_id?.mobile}</div>,
    },
    {
      field: "branch",
      headerName: "Branch",
      flex: 1.2,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        params?.row?.enquiry_id?.branch_id?.branch_name || "",
      renderCell: (params) => (
        <div>{params?.row?.enquiry_id?.branch_id?.branch_name}</div>
      ),
    },
    {
      field: "payment_date",
      headerName: "Date",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params?.row?.payment_date)}</div>,
    },
    {
      field: "razorpay_link_id",
      headerName: "Razorpay Link ID",
      flex: 1.3,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.razorpay_link_id}</div>,
    },
    {
      field: "amount_paid",
      headerName: "Amount Collected",
      flex: 0.7,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.amount_paid}</div>,
    },
    {
      field: "initiated_by",
      headerName: "Initiated By",
      flex: 1,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.created_by?.first_name || ""} ${
          params?.row?.created_by?.last_name || ""
        }`,
      renderCell: (params) => (
        <div>
          {params?.row?.created_by?.first_name}{" "}
          {params?.row?.created_by?.last_name}
        </div>
      ),
    },

    {
      field: "collected_status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row?.collected_status === "received" && (
            <Chip
              size="normal"
              variant="contained"
              label="Generated"
              color="error"
            />
          )}
          {params?.row?.collected_status === "deposited" && (
            <Chip
              size="normal"
              variant="contained"
              label="Deposited"
              style={{ background: "orange" }}
            />
          )}
          {params?.row?.collected_status === "confirmed" && (
            <Chip
              size="normal"
              variant="contained"
              label="Confirmed"
              color="primary"
            />
          )}
        </div>
      ),
    },

    {
      field: "action",
      headerName: "Action",
      flex: 0.5,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row?.collected_status === "received" && (
            <Tooltip title="Upload Payment Screenshot">
              <span>
                {" "}
                <UploadIconcomp
                  onClick={() => {
                    setOpenUploadDialog(true);
                    setSelectedRow(params?.row.id);
                  }}
                />
              </span>
            </Tooltip>
          )}

          {params?.row?.collected_status === "deposited" && (
            <ActionButton
              onClick={() =>
                handleViewDepositSlip(
                  params?.row?.payment_screenshot_id?.file_uploaded
                )
              }
            >
              <Tooltip title="View Payment Screenshot">
                <VisibilityOutlinedIcon
                  fontSize="small"
                  style={{
                    color: "#00b13f",
                    fontSize: "0.8rem",
                  }}
                />
              </Tooltip>
            </ActionButton>
          )}
        </div>
      ),
    },
  ];

  // Search All column data
  const handleSearchByName = (e) => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, ""); // Normalize search value (lowercase and remove spaces)

    let filteredData1 = searchData.filter((data) => {
      // Check each field for a match with the search value
      const fieldsToSearch = [
        `${data?.enquiry_id?.first_name || ""} ${
          data?.enquiry_id?.last_name || ""
        }`,

        `${data?.enquiry_id?.mobile || ""}`,
        `${data?.enquiry_id?.branch_id?.branch_name || ""}`,
        `${data?.razorpay_link_id || ""}`,

        `${fDate(data?.payment_date || "")}`,
        `${data?.amount_paid || ""}`,
        `${data?.created_by?.first_name || ""} ${
          data?.created_by?.last_name || ""
        }`,

        `${
          data?.collected_status === "received"
            ? "generated"
            : data?.collected_status || ""
        }`,
      ];

      // Check if any of the fields contain the search value
      return fieldsToSearch.some((field) =>
        field.toLowerCase().replace(/\s+/g, "").includes(value)
      );
    });

    setPayments(filteredData1); // Update the filtered data
  };
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <OnlinePaymentFilter
            setPayments={setPayments}
            originalData={originalData}
            isLoading={isLoading}
            showSnackbar={showSnackbar}
            setIsLoading={setIsLoading}
            setSearchData={setSearchData}
          />
        </Grid>

        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="end">
                <SearchTextFieldComp onChange={handleSearchByName} />
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={payments}
                  columns={columns}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[25, 50, 100]}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <Dialog
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
      >
        <DialogTitle variant="h4">Upload Payment Screenshot</DialogTitle>
        <DialogContent>
          <TextField type="file" onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUploadDialog(false)}>Cancel</Button>
          <Button onClick={handleAddUpload} variant="contained">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OnlinePayments;
