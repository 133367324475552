import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";
import CustomAutocompleteComp from "../../newComponent/AutocompleteComp";
import CustomAutocompleteTheme from "../../themes/AutocompleteTheme";

export default function CampaignForm({ onClose }) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const userId = localStorage.getItem("userId");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const [countrylist, setcountrylist] = useState([]);
  const [country, setCountry] = useState([]);
  const [statelist, setStatelist] = useState([]);
  const [state, setstate] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [campaigntypelist, setCampaigntypelist] = useState([]);
  const [campaigntype, setCampaigntype] = useState(null);
  const [servicelist, setServiceList] = useState([]);
  const [Service, setService] = useState(null);
  const [paid, setPaid] = useState("");
  const [showAssociatedCampaign, setShowAssociatedCampaign] = useState(false);
  const [associatedlist, setAssociatedlist] = useState([]);
  const [associated, setAssociated] = useState(null);
  const [testTypeList, setTestTypeList] = useState([]);
  const [testTypeName, setTestTypename] = useState([]);
  const [selectedSubserviceIds, setSelectedSubserviceIds] = useState([]);
  const [subServicePrograms, setSubServicePrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}targetcountries`);
        if (response.ok) {
          const data = await response.json();

          setcountrylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allCountry = [{ country_name: "Select All" }, ...countrylist];
  const handelcountrychange = (event, value) => {
    if (value.some((option) => option.country_name === "Select All")) {
      setCountry(countrylist);
      const ids = countrylist.map((i) => i.country_id);
      setSelectedCountryId(ids);
      setValue("country_id", value);
    } else {
      const ids = value.map((i) => i.country_id);
      setCountry(value);

      setSelectedCountryId(ids);
      setValue("country_id", value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}oldstate`);
        if (response.ok) {
          const data = await response.json();

          const filteredStates = data.filter((state) =>
            selectedCountryId.includes(state.country_id)
          );

          setStatelist(filteredStates);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [country]);

  const handelstatechange = (event, value) => {
    setstate(value);
    setValue("state_id", value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allbranchList = [{ branch_name: "Select All" }, ...branchlist];

  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
      setValue("branch_id", value);
    } else {
      setBranch(value);
      setValue("branch_id", value);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}campaigntype`);
        if (response.ok) {
          const data = await response.json();
          setCampaigntypelist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handelcampaignType = (e) => {
    const selectedCampaignType = e.target.value;

    setCampaigntype(selectedCampaignType);

    setValue("campaign_type_id", selectedCampaignType);

    if (selectedCampaignType === 1) {
      setShowAssociatedCampaign(true);
    } else {
      setShowAssociatedCampaign(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (campaigntype) {
          const response = await fetch(
            `${API_ENDPOINT}route/getcampaign/${userId}`
          );
          if (response.ok) {
            const data = await response.json();

            const filteredcampaign = data.filter(
              (campaign) => campaign.campaign_type_id?.id === 2
            );
            setAssociatedlist(filteredcampaign);
          } else {
            throw new Error(`Request failed with status: ${response.status}`);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [campaigntype]);

  const associatechange = (event, value) => {
    setAssociated(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          setServiceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handelservice = (e) => {
    setTestTypename([]);
    setSelectedProgram([])
    setSelectedSubserviceIds([])
    setService(e.target.value);

    setValue("service_id", e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          const SubserviceData = data.filter((i) => i.service_id === Service);
          setTestTypeList(SubserviceData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [Service]);

  const allTestTypeList = [{ name: "Select All" }, ...testTypeList];
  const handleTestTypeChange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      const selectedIds = testTypeList.map((i) => i.id);
      setTestTypename(testTypeList);
      setSelectedSubserviceIds(selectedIds);
      setValue("sub_service_id", value);
    } else {
      const selectedIds = value.map((i) => i.id);
      setTestTypename(value);
      setSelectedSubserviceIds(selectedIds);
      setValue("sub_service_id", value);
    }
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}subservice-program`)
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.filter((i) =>
          selectedSubserviceIds.includes(i?.sub_service_id)
        );
        setSubServicePrograms(filteredData);
      })
      .catch((error) => {
        console.error("Error fetching sub service programs:", error);
      });
  }, [selectedSubserviceIds]);

  const allProgramList = [{ name: "Select All" }, ...subServicePrograms];
  const handleChangePrimiumProgram = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      const selectedIds = subServicePrograms.map((i) => i.id);
      setSelectedProgram(subServicePrograms);
      setValue("premium_admission_programs_id", selectedIds);
    } else {
      const selectedIds = value.map((i) => i.id);
      setValue("premium_admission_programs_id", selectedIds);
      setSelectedProgram(value);
    }
  };

  const onSubmit = async (data) => {
    try {
      const requestData = {
        ...data,
        associated_campaign_id: associated?.id || null,
        branch_id: branch.map((i) => i.id),
        country_id: selectedCountryId,

        user_id: parseInt(userId),
        service_id: Service,
        sub_service_id: testTypeName.map((i) => i.id),
        state_id: state.map((i) => i.id),
      };
      const response = await fetch(`${API_ENDPOINT}campaign/onecampaign`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      if (response.ok) {
        showSnackbar("Campaign Created Successfully", "success");

        reset();
        setCountry([]); // reset country dropdown
        setstate([]); // reset state dropdown
        setBranch([]); // reset branch dropdown
        setCampaigntype(""); // reset campaign type dropdown
        setService(""); // reset service dropdown
        setPaid("");
        setAssociated("");

        setTimeout(() => {
      window.location.reload();
        }, 1500);
      } else if (response.status === 409) {
        showSnackbar(
          "Campaign with same campaign name exists.Should be unique",
          "error"
        );
      } else {
        showSnackbar("Failed to Create Campaign", "error");
        throw new Error("Failed to update payment status");
      }
    } catch (error) {
      console.error("Error submitting campaign data:", error);
    }
  };

  const paidstatus = [
    { id: 1, value: "Paid" },
    { id: 2, value: "Unpaid" },
  ];

  const handelpaidstatus = (e) => {
    setPaid(e.target.value);

    setValue("paid_status", e.target.value);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={6} md={6} sm={6}>
          <FormControl fullWidth error={Boolean(errors.campaign_type_id)}>
            <Typography variant="h6">
              Campaign Type{" "}
              <span style={{ color: "red", fontSize: "0.7rem" }}>*</span>
            </Typography>
            <Controller
              name="campaign_type_id"
              control={control}
              rules={{ required: true }} // Add your validation rules here
              render={({ field }) => (
                <Select
                  size="normal"
                  {...field}
                  sx={{ borderRadius: "10px" }}
                  value={campaigntype}
                  onChange={handelcampaignType}
                >
                  {campaigntypelist.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.campaign_type_id && (
              <FormHelperText>Campaign Type is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Typography variant="h6">
            Campaign Name
            <span style={{ color: "red", fontSize: "0.7rem" }}>*</span>
          </Typography>
          <Controller
            name="campaign_name"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextFieldComp
                size="normal"
                {...field}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                fullWidth
                error={errors.campaign_name}
                helperText={errors.campaign_name && "Campaign Name is required"}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Typography variant="h6">External Campaign Name</Typography>
          <Controller
            name="external_campaign_name"
            control={control}
            defaultValue=""
            // rules={{ required: true }}
            render={({ field }) => (
              <CustomTextFieldComp
                size="normal"
                {...field}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                fullWidth
                error={errors.external_campaign_name}
                helperText={
                  errors.external_campaign_name &&
                  "External Campaign Name is required"
                }
              />
            )}
          />
        </Grid>

        {showAssociatedCampaign && (
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <FormControl fullWidth>
              <Typography variant="h6">Associated Campaign </Typography>
              <Controller
                name="associated_campaign_id"
                control={control}
                // rules={{ required: true }} // Add your validation rules here
                render={({ field }) => (
                  // <Select
                  //   {...field}
                  //   sx={{ borderRadius: "14px" }}
                  //   value={associated}
                  //   onChange={associatechange}
                  //   MenuProps={MenuProps}
                  // >
                  //   {associatedlist.map((item) => (
                  //     <MenuItem key={item.id} value={item.id}>
                  //       {item.campaign_name}
                  //     </MenuItem>
                  //   ))}
                  // </Select>

                  <CustomAutocompleteComp
                    size="small"
                    disablePortal
                    {...field}
                    value={associated}
                    options={associatedlist}
                    getOptionLabel={(option) => option.campaign_name}
                    onChange={associatechange}
                  />
                )}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} lg={6}>
          <Typography variant="h6">
            Campaign Start Date
            <span style={{ color: "red", fontSize: "0.7rem" }}>*</span>
          </Typography>
          <Controller
            name="campaign_start_date"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextFieldComp
                size="normal"
                {...field}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                type="date"
                fullWidth
                error={errors.campaign_start_date}
                helperText={
                  errors.campaign_start_date &&
                  " Campaign Start Date is required"
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Typography variant="h6">
            Campaign End Date
            <span style={{ color: "red", fontSize: "0.7rem" }}>*</span>
          </Typography>
          <Controller
            name="campaign_end_date"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextFieldComp
                size="normal"
                {...field}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                type="date"
                fullWidth
                error={errors.campaign_end_date}
                helperText={
                  errors.campaign_end_date && " Campaign End Date is required"
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={6} lg={6} md={6} sm={6}>
          <FormControl fullWidth error={errors.paid_status}>
            <Typography variant="h6">
              Paid Status
              <span style={{ color: "red", fontSize: "0.7rem" }}>*</span>
            </Typography>
            <Controller
              name="paid_status"
              control={control}
              rules={{ required: true }} // Add your validation rules here
              render={({ field }) => (
                <Select
                  size="normal"
                  {...field}
                  sx={{ borderRadius: "10px" }}
                  value={paid}
                  onChange={handelpaidstatus}
                >
                  {paidstatus.map((item) => (
                    <MenuItem key={item.id} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.paid_status && (
              <FormHelperText>Paid Status is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl fullWidth error={errors.branch_id}>
            <Typography variant="h6">
              Branch <span style={{ color: "red", fontSize: "0.7rem" }}>*</span>
            </Typography>

            <Controller
              name="branch_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomAutocompleteComp
                  disablePortal
                  size="small"
                  {...field}
                  multiple
                  value={branch}
                  options={allbranchList}
                  getOptionLabel={(option) => option.branch_name}
                  onChange={handelbranchchange}
                  tooltipContent={(option) => option.branch_name}
                />
              )}
            />
            {errors.branch_id && (
              <FormHelperText>Branch is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={6} lg={6} md={6} sm={6}>
          <FormControl fullWidth error={Boolean(errors.service_id)}>
            <Typography variant="h6">
              Service{" "}
              <span style={{ color: "red", fontSize: "0.7rem" }}>*</span>
            </Typography>
            <Controller
              name="service_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  size="normal"
                  {...field}
                  sx={{ borderRadius: "10px" }}
                  value={Service}
                  onChange={handelservice}
                >
                  {servicelist.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.service_name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.service_id && (
              <FormHelperText color="red">Service is required</FormHelperText>
            )}
          </FormControl>
        </Grid>
        {Service !== null && (
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth error={errors.sub_service_id}>
              <Typography variant="h6">
                Sub Service{" "}
                <span style={{ color: "red", fontSize: "0.7rem" }}>*</span>
              </Typography>

              <Controller
                name="sub_service_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomAutocompleteComp
                    disablePortal
                    {...field}
                    noOptionsText={
                      Service === null ? "Please Select Service First" : "ss"
                    }
                    multiple
                    value={testTypeName}
                    options={allTestTypeList}
                    getOptionLabel={(option) => option.name}
                    onChange={handleTestTypeChange}
                    tooltipContent={(option) => option.name}
                  />
                )}
              />
              {errors.sub_service_id && (
                <FormHelperText color="red">
                  Sub Service is required
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {(selectedSubserviceIds.includes(13) ||
          selectedSubserviceIds.includes(14)) && (
          <Grid item xs={12} lg={6} md={6} sm={6}>
            <Typography variant="h6">
              Premium Admission Program
              <Controller
                name="premium_admission_programs_id"
                control={control}
                // rules={{ required: true }}
                render={({ field }) => (
                  <CustomAutocompleteComp
                    disablePortal
                    {...field}
                    multiple
                    value={selectedProgram}
                    options={allProgramList}
                    getOptionLabel={(option) => option.name}
                    onChange={handleChangePrimiumProgram}
                    tooltipContent={(option) => option.name}
                  />
                )}
              />
            </Typography>
          </Grid>
        )}

        <Grid item xs={6} lg={6} md={6} sm={6}>
          <FormControl fullWidth error={errors.country_id}>
            <Typography variant="h6">
              Country{" "}
              <span style={{ color: "red", fontSize: "0.7rem" }}>*</span>
            </Typography>

            <Controller
              name="country_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomAutocompleteComp
                  disablePortal
                  {...field}
                  size="small"
                  multiple
                  value={country}
                  options={allCountry}
                  getOptionLabel={(option) => option.country_name}
                  onChange={handelcountrychange}
                  tooltipContent={(option) => option.country_name}
                />
              )}
            />
            {errors.country_id && (
              <FormHelperText>Country is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={6} lg={6} md={6} sm={6}>
          <FormControl fullWidth>
            <Typography variant="h6">State</Typography>
            <Controller
              name="state_id"
              control={control}
              render={({ field }) => (
                <CustomAutocompleteComp
                  {...field}
                  disablePortal
                  value={state}
                  size="small"
                  multiple
                  options={statelist}
                  getOptionLabel={(option) => option.state_name}
                  onChange={handelstatechange}
                  tooltipContent={(option) => option.state_name}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={12} sx={{ textAlign: "end" }}>
          <Grid container gap={1} justifyContent="end">
            <Button
              variant="contained"
              size="small"
              color="error"
              style={{
                background: "#ff4b4b",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
