import { Collapse, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../../components/Maincard";
import CustomTextFieldComp from "../../../newComponent/customTextFieldComp";
import CustomAutocompleteComp from "../../../newComponent/AutocompleteComp";
import ApplyFilterButton from "../../../newComponent/NewButtonComp/ApplyFilterButton";
import ResetFilterButton from "../../../newComponent/NewButtonComp/ResetFilterButton";
import { useGetTokenOrLogout } from "../../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function PendingPaymentFilter({
  open,
  setSearchData,
  setPendingPaymentData,
  setOriginalData,
  showSnackbar,
  originalData,
  setIsLoading,
}) {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const getTokenOrLogout = useGetTokenOrLogout();
  const [dueFromDate, setDuefromDate] = useState("");
  const [dueToDate, setDueToDate] = useState("");
  const [createdFromDate, setCreatedfromDate] = useState("");
  const [createdToDate, setCreatedToDate] = useState("");
  const [collectedByList, setCollectedByList] = useState([]);
  const [colelctedBy, setCollectedBy] = useState([]);

  const [BranchList, setbranchList] = useState([]);
  const [collectedBranchName, setCollectedBranchName] = useState([]);
  const [classList, setClassList] = useState([]);
  const [className, setClassName] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [packageName, setPackageName] = useState([]);
  const [enrollBranchList, setEnrollBranchList] = useState([]);
  const [enrollBranch, setEnrollBranch] = useState([]);
  const [payementFromDate, setPatymentfromDate] = useState("");
  const [paymentToDate, setPaymentToDate] = useState("");
  const [paymetStatusList, setPaymentStatusList] = useState([
    { id: 1, name: "pending", status: "Pending" },
    { id: 2, name: "partially-paid", status: "Partially-Paid" },
    { id: 3, name: "paid", status: "Paid" },
    { id: 4, name: "dropped", status: "Dropped" },
  ]);
  const [status, setStatus] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/getadvisors/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          setCollectedByList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allCollectedByList = [{ first_name: "Select All" }, ...collectedByList];

  const handelCollectedBychange = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setCollectedBy(collectedByList);
    } else {
      setCollectedBy(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route/fetchbranch/${userId}`
        );
        if (response.ok) {
          const data = await response.json();

          setbranchList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allBranchList = [{ branch_name: "Select All" }, ...BranchList];
  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setCollectedBranchName(BranchList);
    } else {
      setCollectedBranchName(value);
    }
  };

  // Fetch Class
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}class`);
        if (response.ok) {
          const data = await response.json();
          setClassList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allClassList = [{ name: "Select All" }, ...classList];
  const handleChangeClass = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      setClassName(classList);
    } else {
      setClassName(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}package`);
        if (response.ok) {
          const data = await response.json();
          setPackageList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allPackageList = [{ package_name: "Select All" }, ...packageList];
  const handleChangePackage = (event, value) => {
    if (value.some((option) => option.package_name === "Select All")) {
      setPackageName(packageList);
    } else {
      setPackageName(value);
    }
  };

  //  Branch
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setEnrollBranchList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allEnrollBranchList = [
    { branch_name: "Select All" },
    ...enrollBranchList,
  ];
  const handleChangeEnrollBranch = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setEnrollBranch(enrollBranchList);
    } else {
      setEnrollBranch(value);
    }
  };

  const handleChangeStatus = (event, value) => {
    setStatus(value);
  };
  // Apply Filter Api
  const handleApplyFilter = async (event) => {
    event.preventDefault();
    // Handle form submission logic here

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    setIsLoading(true);
    const requestData = {
      user_id: parseInt(userId),
      package_id: packageName.map((i) => i.id),
      class_id: className.map((i) => i.id),
      collected_by: colelctedBy.map((i) => i.user_id),
      payment_from_date: payementFromDate || null,
      payment_to_date: paymentToDate || null,
      created_from_date: createdFromDate || null,
      created_to_date: createdToDate || null,
      due_from_date: dueFromDate || null,
      due_to_date: dueToDate || null,
      payment_status: status?.map((i) => i.name),
      branch_id: enrollBranch.map((i) => i.id),
      branch_collected_by: collectedBranchName.map((i) => i.id),
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}pendingpayment/fetchpendingpaymentsfilter`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        const sortedData = result.sort(
          (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
        );

        setPendingPaymentData(sortedData);
        setSearchData(sortedData);
        setIsLoading(false);
      } else if (response.status === 404) {
        showSnackbar("Could't find any data", "error");
        setPendingPaymentData([]);
        setSearchData([]);
        setIsLoading(false);
      } else if (response.status === 500) {
        showSnackbar("Server Error", "error");
        setPendingPaymentData([]);
        setSearchData([]);
        setIsLoading(false);
      }
      else {
        setPendingPaymentData([]);
        setSearchData([]);
        setIsLoading(false);
        showSnackbar("Error Fetching Data", "error");
      }
    } catch (error) {
      setPendingPaymentData([]);
      setSearchData([]);
      setIsLoading(false);
      showSnackbar("Error Fetching Data", "error");
    }
  };

  // Reset filter
  const handleResetFilter = () => {
    setDuefromDate("");
    setDueToDate("");
    setCreatedfromDate("");
    setCreatedToDate("");
    setCollectedBy([]);
    setCollectedBranchName([]);
    setPatymentfromDate("");
    setPaymentToDate("");
    setEnrollBranch([]);
    setPendingPaymentData(originalData);
    setStatus([]);
    setSearchData(originalData);
  };
  return (
    <>
      <Collapse in={open}>
        <MainCard>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Class</Typography>
              <CustomAutocompleteComp
                size="normal"
                fullWidth
                value={className}
                multiple
                onChange={handleChangeClass}
                options={allClassList}
                getOptionLabel={(option) => option.name}
                tooltipContent={(option) => option.name}
              />
            </Grid> */}

            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Package</Typography>
              <CustomAutocompleteComp
                size="normal"
                fullWidth
                value={packageName}
                multiple
                onChange={handleChangePackage}
                options={allPackageList}
                getOptionLabel={(option) => option.package_name}
                tooltipContent={(option) => option.package_name}
              />
            </Grid> */}

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6"> Branch Enrolled</Typography>
              <CustomAutocompleteComp
                size="normal"
                fullWidth
                value={enrollBranch}
                multiple
                onChange={handleChangeEnrollBranch}
                options={allEnrollBranchList}
                getOptionLabel={(option) => option.branch_name}
                tooltipContent={(option) => option.branch_name}
              />
            </Grid>
            {userRole!=="trainer" && ( 
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Collected By</Typography>
              <CustomAutocompleteComp
                size="normal"
                fullWidth
                value={colelctedBy}
                multiple
                onChange={handelCollectedBychange}
                options={allCollectedByList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                tooltipContent={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
              />
            </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Due From Date</Typography>
              <CustomTextFieldComp
                fullWidth
                size="normal"
                type="date"
                value={dueFromDate}
                onChange={(e) => setDuefromDate(e.target.value)}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Due To Date</Typography>
              <CustomTextFieldComp
                fullWidth
                size="normal"
                type="date"
                value={dueToDate}
                onChange={(e) => setDueToDate(e.target.value)}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Created From Date</Typography>
              <CustomTextFieldComp
                fullWidth
                size="normal"
                type="date"
                value={createdFromDate}
                onChange={(e) => setCreatedfromDate(e.target.value)}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Created To Date</Typography>
              <CustomTextFieldComp
                fullWidth
                size="normal"
                type="date"
                value={createdToDate}
                onChange={(e) => setCreatedToDate(e.target.value)}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Payment From Date</Typography>
              <CustomTextFieldComp
                fullWidth
                size="normal"
                type="date"
                value={payementFromDate}
                onChange={(e) => setPatymentfromDate(e.target.value)}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Payment To Date</Typography>
              <CustomTextFieldComp
                fullWidth
                size="normal"
                type="date"
                value={paymentToDate}
                onChange={(e) => setPaymentToDate(e.target.value)}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Branch Collected By</Typography>
              <CustomAutocompleteComp
                fullWidth
                value={collectedBranchName}
                multiple
                onChange={handelbranchchange}
                options={allBranchList}
                getOptionLabel={(option) => option?.branch_name}
                tooltipContent={(option) => option?.branch_name}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Payment Status</Typography>
              <CustomAutocompleteComp
                fullWidth
                value={status}
                multiple
                onChange={handleChangeStatus}
                options={paymetStatusList}
                getOptionLabel={(option) => option?.status}
                tooltipContent={(option) => option?.status}
              />
            </Grid>

            {/* 
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">Payment Status</Typography>
              <CustomAutocompleteComp />
            </Grid> */}
            <Grid item xs={12} container gap={1} justifyContent="end">
              <ApplyFilterButton onClick={handleApplyFilter} />
              <ResetFilterButton onClick={handleResetFilter} />
            </Grid>
          </Grid>
        </MainCard>
      </Collapse>
    </>
  );
}
