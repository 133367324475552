import { Button } from '@mui/material'
import React from 'react'

export default function MassTaskTransferButton({onClick, size }) {
  return (
  <>
   <Button
        variant="contained"
        size= { size ? size :"medium"}
        style={{ background: "#46b1ff", borderRadius:'8px' }}
        onClick={onClick}
      >
        Mass Task Transfer
      </Button>
  </>
  )
}
