import {
  Button,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function TicketFilter({
  open,
  handleToggle,
  onApplyFilter,
  onResetFilter,
}) {
  const today = new Date().toISOString().split("T")[0];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [tickettype, setTicketType] = useState("");
  const [createdBy, setCreatedBy] = useState([]);
  const [createdbylist, setCreatedbylist] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [urgency, setUrgency] = useState([]);
  const [status, setStatus] = useState([]);
  const handleApplyFilter = () => {
    onApplyFilter({ tickettype, dateFrom, dateTo, urgency, status, createdBy });
  };

  const resetfilter = () => {
    setTicketType("");
    setCreatedBy([]);
    setDateFrom("");
    setDateTo("");
    setUrgency([]);
    setStatus([]);
    onResetFilter();
  };

  const handelnamechange = (e) => {
    setTicketType(e.target.value);
  
  };

  const handelstartdate = (e) => {
    setDateFrom(e.target.value);
   
  };

  const handeltodate = (e) => {
    setDateTo(e.target.value);
   
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();

          setCreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handelcreatedby = (e) => {
    setCreatedBy(e.target.value);
  };

  const urgencylist = [
    { id: 0, name: "Yes", value: 1 },
    {
      id: 1,
      name: "No",
      value: 0,
    },
  ];

  const handelurgencychange = (e) => {
    setUrgency(e.target.value);
  };

  const statuslist = [
    { id: 0, name: "Created", value: "created" },
    {
      id: 1,
      name: "Resolving",
      value: "resolving",
    },
    { id: 2, name: "Completed", value: "completed" },
  ];

  const handelstatuschange = (e) => {
    setStatus(e.target.value);
  };
  return (
    <Collapse in={open} sx={{ marginTop: "5px" }}>
      {/* <MainCard  > */}
      {/* <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 2, pt: 1, mt: 1 }}> */}
      <Grid container spacing={2}>
        {/* <Box sx={{ display: 'flex', flexDirection: 'row' }} > */}
        <Grid item xs={6} lg={3} md={6} sm={6}>
          <TextField
            sx={{ mb: 1, width: "100%" }}
            label="Type Of Ticket"
            value={tickettype}
            onChange={handelnamechange}
            InputProps={{
              style: { borderRadius: "15px" },
            }}
          />
        </Grid>

        <Grid item xs={6} lg={3} md={6} sm={6}>
          <FormControl sx={{ mb: 1, width: "100%" }}>
            <InputLabel htmlFor="outlined-select-branch">Created By</InputLabel>

            <Select
              multiple
              input={<OutlinedInput label="Created By" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              value={createdBy}
              onChange={handelcreatedby}
              label="Branch"
              style={{ borderRadius: "15px" }}
            >
              {createdbylist.map((item) => (
                <MenuItem key={item.id} value={item.first_name}>
                  <Checkbox checked={createdBy.indexOf(item.first_name) > -1} />
                  <ListItemText primary={item.first_name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* </Box> */}
        {/* <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}> */}
        <Grid item xs={6} lg={3} md={6} sm={6}>
          <TextField
            sx={{ mb: 1, width: "100%" }}
            label="Created Date From"
            type="date"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: { borderRadius: "15px" },
              inputProps: { max: today },
            }}
            value={dateFrom}
            onChange={handelstartdate}
          />
        </Grid>
        <Grid item xs={6} lg={3} md={6} sm={6}>
          <TextField
            sx={{ mb: 1, width: "100%" }}
            label="Created Date To"
            type="date"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: { borderRadius: "15px" },
              inputProps: { max: today },
            }}
            value={dateTo}
            onChange={handeltodate}
          />
        </Grid>

        <Grid item xs={6} lg={3} md={6} sm={6}>
          <FormControl sx={{ mb: 1, width: "100%" }}>
            <InputLabel htmlFor="outlined-select-branch">Urgency</InputLabel>

            <Select
              multiple
              input={<OutlinedInput label="Urgency" />}
              renderValue={(selected) =>
                selected
                  .map((value) => {
                    const urgencyValue = urgencylist.find(
                      (status) => status.value === value
                    );
                    return urgencyValue ? urgencyValue.name : "";
                  })
                  .join(", ")
              }
              MenuProps={MenuProps}
              style={{ borderRadius: "15px" }}
              value={urgency}
              onChange={handelurgencychange}
            >
              {urgencylist.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  <Checkbox checked={urgency.indexOf(item.value) > -1} />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} lg={3} md={6} sm={6}>
          <FormControl sx={{ mb: 1, width: "100%" }}>
            <InputLabel htmlFor="outlined-select-branch">Status</InputLabel>

            <Select
              multiple
              input={<OutlinedInput label="Status" />}
              renderValue={(selected) =>
                selected
                  .map((value) => {
                    const statusValue = statuslist.find(
                      (status) => status.value === value
                    );
                    return statusValue ? statusValue.name : "";
                  })
                  .join(", ")
              }
              MenuProps={MenuProps}
              value={status}
              onChange={handelstatuschange}
              style={{ borderRadius: "15px" }}
            >
              {statuslist.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  <Checkbox checked={status.indexOf(item.value) > -1} />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* </Box> */}

        <Grid item xs={12} sm={12} lg={12} md={12} sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            style={{
              background: "#4242f5",
              width: "150px",
              marginRight: "7px",
            }}
            onClick={handleApplyFilter}
          >
            {" "}
            Apply Filter
          </Button>
          <Button
            variant="contained"
            style={{
              background: "#f7690a",
              width: "150px",
              marginRight: "7px",
            }}
            onClick={handleToggle}
          >
            {" "}
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ width: "150px" }}
            onClick={resetfilter}
          >
            {" "}
            Reset Filter
          </Button>
        </Grid>
        {/* </Box> */}
      </Grid>
      {/* </MainCard> */}
    </Collapse>
  );
}
