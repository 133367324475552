import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  MenuItem,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function AddBranch({ onAdd, onClose, open }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    setError,
  } = useForm();

  const [pincodeError, setPincodeError] = useState("");
  const [pinCodeData, setPinCodeData] = useState({});

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(101);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setValue("country_id", event.target.value);
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setValue("state_id", event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setValue("city_id", event.target.value);
  };

  const handlePinCodeChange2 = (event) => {
    const newPinCode = event.target.value;
    if (newPinCode.length === 6) {
      setValue("pincode_id", newPinCode);
      fetchPinCodeData2(newPinCode);
    } else {
      setPincodeError("Invalid pin code 3");
      setSelectedState(null);
      setSelectedCity(null);
      setValue("pincode_id", null);
      setValue("state_id", null);
      setValue("city_id", null);
      // setValue('nearest_branch', null);
    }
  };

  const fetchPinCodeData2 = async (pincode) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}oldzipcode/pincode/${pincode}`
      );
      //setPinCodeData(response.data);
      if (response.status === 200) {
        const postOfficeData = response.data; // Get the first PostOffice object
        setPinCodeData(postOfficeData);
    

        if (postOfficeData) {
          // Update the State
          setPincodeError("");
          setSelectedState(parseInt(postOfficeData.state_id));
          setValue("state_id", parseInt(postOfficeData.state_id));

          setSelectedCity(parseInt(postOfficeData.city_id));
          setValue("city_id", parseInt(postOfficeData.city_id));

          // Fetch City data based on the selected State
          // fetchCityData(parseInt(postOfficeData.state_id), parseInt(postOfficeData.city_id));
        }
      } else {
     
        //alert('error')
        setPincodeError("Invalid pin code 1");
        //setNearestBranch(null)
        setError("pincode", { type: "manual", message: "Invalid pin code 2" });
        setValue("pincode_id", null);
        setValue("state_id", null);
        setValue("city_id", null);
        setSelectedState(null);
        setSelectedCity(null);
        //setValue('nearest_branch', null);
      }
    } catch (error) {
      console.error("Error fetching pin code data:", error);
      showSnackbar("Error fetching pin code data", "error");
      setPinCodeData(null);
    }
  };

  const handleAdd = (data) => {
    onAdd(data);
  
    // onClose();
    reset({
      state_id: null,
      city_id: null,
      building: "",
      floorHouse: "",
      pincode_id: null,
      location_link: "",
    });
    setSelectedState(null);
    setSelectedCity(null);
  };

  const handleCancel = () => {
    // Reset the form and clear errors
    reset({
      state_id: null,
      city_id: null,
      building: "",
      floorHouse: "",
      pincode_id: null,
      location_link: "",
    });
    setSelectedState(null);
    setSelectedCity(null);
    // Clear any form-level errors
    setPincodeError("");
    onClose();
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}country`)
      .then((response) => response.json())
      .then((data) => {
        setCountries(data);
      
      })
      .catch((error) => {
        console.error("Error fetching country:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetch(`${API_ENDPOINT}oldstate`)
        .then((response) => response.json())
        .then((data) => {
          const filteredState = data.filter(
            (state) => state.country_id === selectedCountry
          );
          setStates(filteredState);
         
        })
        .catch((error) => {
          console.error("Error fetching state:", error);
        });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry && selectedState) {
      fetch(`${API_ENDPOINT}oldcity`)
        .then((response) => response.json())
        .then((data) => {
          const filteredCity = data.filter(
            (city) =>
              city.country === selectedCountry && city.state === selectedState
          );
          setCities(filteredCity);
        
        })
        .catch((error) => {
          console.error("Error fetching city:", error);
        });
    }
  }, [selectedCountry, selectedState]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle variant="title">Add Centre / Branch</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Centre / Branch Name
                <span
                  style={{ color: "red", marginLeft: "4px", fontSize: "1em" }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="name"
                control={control}
                rules={{ required: "Branch name is required" }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    {...field}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    fullWidth
                  />
                )}
              />
              {errors.name && (
                <span style={{ color: "red" }}>{errors.name.message}</span>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">
                Building/ Tower/ Floor
                <span
                  style={{ color: "red", marginLeft: "4px", fontSize: "1em" }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="building"
                control={control}
                rules={{ required: "Building name is required" }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    {...field}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    fullWidth
                  />
                )}
              />
              {errors.building && (
                <span style={{ color: "red" }}>{errors.building.message}</span>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">
                Area / Sector / Locality
                <span
                  style={{ color: "red", marginLeft: "4px", fontSize: "1em" }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="floorHouse"
                control={control}
                rules={{ required: "Area is required" }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    {...field}
                    placeholder="ie Sector 47"
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    fullWidth
                  />
                )}
              />
              {errors.floorHouse && (
                <span style={{ color: "red" }}>
                  {errors.floorHouse.message}
                </span>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">
                Nearest Landmark/Famous Spot(Optional)
              </Typography>
              <Controller
                name="landmark"
                control={control}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    {...field}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    fullWidth
                  />
                )}
              />
              {errors.landmark && (
                <span style={{ color: "red" }}>{errors.landmark.message}</span>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">
                Location Link
                <span
                  style={{ color: "red", marginLeft: "4px", fontSize: "1em" }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="location_link"
                control={control}
                rules={{ required: "Location Link is required" }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    {...field}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    fullWidth
                  />
                )}
              />
              {errors.location_link && (
                <span style={{ color: "red" }}>
                  {errors.location_link.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">
                Country
                <span
                  style={{ color: "red", marginLeft: "4px", fontSize: "1em" }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="country_id"
                control={control}
                defaultValue={101}
                rules={{ required: "Country is required" }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    select
                    {...field}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    fullWidth
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    defaultValue={101}
                    error={Boolean(errors.country_id)}
                    //helperText={errors.country && 'Country is required'}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.country_name}
                      </MenuItem>
                    ))}
                  </CustomTextFieldComp>
                )}
              />
              {errors.country_id && (
                <span style={{ color: "red" }}>
                  {errors.country_id.message}
                </span>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h6">
                Pincode
                <span
                  style={{ color: "red", marginLeft: "4px", fontSize: "1em" }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="pincode_id"
                control={control}
                //rules={{ required: "Pin code is required", pattern: { value: /^\d{6}$/, message: "Invalid pin code" } }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    {...field}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    fullWidth
                    error={Boolean(errors.pincode_id)}
                    onChange={handlePinCodeChange2}
                  />
                )}
              />
              {errors.pincode_id && (
                <span className="error-message" style={{ color: "red" }}>
                  {errors.pincode_id.message}
                </span>
              )}
              {pincodeError && (
                <span className="error-message" style={{ color: "red" }}>
                  {pincodeError}
                </span>
              )}
            </Grid>

            <Grid item xs={12} lg={6} md={6} sm={6}>
              <Typography variant="h6">
                State
                <span
                  style={{ color: "red", marginLeft: "4px", fontSize: "1em" }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="state_id"
                control={control}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    select
                    {...field}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    fullWidth
                    value={selectedState}
                    onChange={handleStateChange}
                    // defaultValue={pinCodeData?.state}
                    error={Boolean(errors.state_id)}
                    //helperText={errors.state}
                  >
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.state_name}
                      </MenuItem>
                    ))}
                  </CustomTextFieldComp>
                )}
              />
            </Grid>

            {selectedState !== null && (
              <Grid item xs={12} lg={6} sm={6} md={6}>
                <Typography variant="h6">
                  City
                  <span
                    style={{ color: "red", marginLeft: "4px", fontSize: "1em" }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="city_id"
                  control={control}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      size="normal"
                      select
                      {...field}
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      fullWidth
                      value={selectedCity}
                      onChange={handleCityChange}
                      //defaultValue="IN"
                      error={Boolean(errors.city_id)}
                      // helperText={errors.city }
                    >
                      {cities.map((city) => (
                        <MenuItem key={city.id} value={city.id}>
                          {city.city_name}
                        </MenuItem>
                      ))}
                    </CustomTextFieldComp>
                  )}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            color="error"
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(handleAdd)}
            color="secondary"
            variant="contained"
            size="small"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default AddBranch;
