import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ApplyFilterButton from "../../newComponent/NewButtonComp/ApplyFilterButton";
import CustomAutocompleteComp from "../../newComponent/AutocompleteComp";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EditLeaveRequest({
  onClose,
  selectedRowData,
  showSnackbar,
  setSelectedRowData,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
  } = useForm();

  const today = new Date().toISOString().split("T")[0];
  const userId = localStorage.getItem("userId");
  const [userName, setUserName] = useState(null);
  const [userNameList, setuserNameList] = useState([]);
  const [BackupUser, setBackupUser] = useState(null);

  const [leaveTypelist, setLeaveTypeList] = useState([
    { id: 1, name: "Full Day" },
    { id: 2, name: "First Half Day" },
    { id: 3, name: "Second Half Day" },
  ]);

  console.log("selectedRowData", selectedRowData);
  const [changes, setChanges] = useState({});

  //   Handle change field
  const handleFieldChange = (fieldName, value) => {
    console.log("fieldName", fieldName, selectedRowData?.start_date);

    setChanges((prevChanges) => ({
      ...prevChanges,
      [fieldName]: value,
    }));

    setSelectedRowData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    setValue(fieldName, value); // Update form value

    // Reset start and end dates when leave type changes
    if (fieldName === "leave_type_id") {
      setValue("start_date", "");
      setValue("end_date", "");
      setSelectedRowData((prevData) => ({
        ...prevData,
        start_date: "",
        end_date: "",
      }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setuserNameList(data);
          const defaultValue = data.find(
            (i) => i.id === selectedRowData?.employee_name?.id
          );
          console.log("defaultValue", defaultValue);
          setValue("employee_name", defaultValue?.id || null);

          const defaultbackupuserValue = data.find(
            (i) => i.id === selectedRowData?.backup_emp_name?.id
          );
          console.log("defaultValue", defaultbackupuserValue);
          setValue("backup_emp_name", defaultbackupuserValue?.id || null);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //   set default value for leave type
  useEffect(() => {
    const defaulttestTypeValue = leaveTypelist.find(
      (levetype) => levetype?.name === selectedRowData?.leave_type_id
    );
    setValue("leave_type_id", defaulttestTypeValue?.id);
  }, [selectedRowData]);

  //   handle submit function
  const onSubmit = async (data) => {
    try {
      const requestData = {
        ...changes,
        user_id: parseInt(userId),
      };
      const response = await fetch(
        `${API_ENDPOINT}advisor-leave-detail/${selectedRowData?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        showSnackbar("Employee leave request updated successfully", "success");

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else if (response.status === 401) {
        showSnackbar("Unknown User", "error");
      } else if (response.status === 403) {
        showSnackbar("Unathorized User", "error");
      } else {
        showSnackbar("Error to update Employee Leave Request", "error");
        throw new Error("Error to update Employee Leave Request");
      }
    } catch (error) {
      showSnackbar("Error to update Employee Leave Request", "error");
      console.error("Error to update Employee Leave Request", error);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            Select Employee{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="employee_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomAutocompleteComp
                {...field}
                size="small"
                readOnly
                value={
                  userNameList.find((user) => user.id === field.value) || null
                }
                onChange={(event, value) =>
                  handleFieldChange("employee_name", value?.id)
                }
                options={userNameList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "14px" }, // Add borderRadius here
                    }}
                    error={errors.employee_name}
                    helperText={
                      errors.employee_name && "Employee name is required"
                    }
                  />
                )}
              />
            )}
          ></Controller>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            Select Backup Employee{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="backup_emp_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomAutocompleteComp
                {...field}
                size="small"
                value={
                  userNameList.find((user) => user.id === field.value) || null
                }
                onChange={(event, value) =>
                  handleFieldChange("backup_emp_name", value?.id)
                }
                options={userNameList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                error={errors.backup_emp_name}
                    helperText={
                      errors.backup_emp_name &&
                      "Backup employee name is required"
                    }
              />
            )}
          ></Controller>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            Select Leave Type{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="leave_type_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomAutocompleteComp
                {...field}
                size="small"
                value={
                  leaveTypelist.find((user) => user.id === field.value) || null
                }
                onChange={(event, value) =>
                  handleFieldChange("leave_type_id", value?.name)
                }
                options={leaveTypelist}
                getOptionLabel={(option) => option.name}
                error={errors.leave_type_id}
                helperText={
                  errors.leave_type_id && "Leave type is required"
                }
              />
            )}
          ></Controller>
        </Grid>

        {selectedRowData?.leave_type_id === "Full Day" && (
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  Start Date{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Controller
                  name="start_date"
                  control={control}
                  defaultValue={selectedRowData?.start_date}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      {...field}
                      fullWidth
                      size="normal"
                      type="date"
                      onChange={(event, value) =>
                        handleFieldChange("start_date", event.target.value)
                      }
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        
                        },
                        inputProps: { min: today },
                      }}
                      error={errors.start_date}
                      helperText={errors.start_date && "Start Date is required"}
                    />
                  )}
                ></Controller>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  End Date{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Controller
                  name="end_date"
                  control={control}
                  defaultValue={selectedRowData?.end_date}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      {...field}
                      fullWidth
                      size="normal"
                      type="date"
                      onChange={(event, value) =>
                        handleFieldChange("end_date", event.target.value)
                      }
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          height: "37px",
                        },
                        inputProps: { min: today },
                      }}
                      error={errors.end_date}
                      helperText={errors.end_date && "End Date is required"}
                    />
                  )}
                ></Controller>
              </Grid>
            </Grid>
          </Grid>
        )}

        {(selectedRowData?.leave_type_id === "First Half Day" ||
          selectedRowData?.leave_type_id === "Second Half Day") && (
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Start Date{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1em",
                }}
              >
                *
              </span>
            </Typography>

            <Controller
              name="start_date"
              control={control}
              defaultValue={selectedRowData.start_date || ""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextFieldComp
                  {...field}
                  fullWidth
                  size="normal"
                  type="date"
                  onChange={(event, value) =>
                    handleFieldChange("start_date", event.target.value)
                  }
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                   
                    },
                    inputProps: { min: today },
                  }}
                  error={errors.start_date}
                  helperText={errors.start_date && "Start Date is required"}
                />
              )}
            ></Controller>
          </Grid>
        )}

        <Grid item xs={12} sm={12}>
          <Typography variant="h6">Additional Note (Optional)</Typography>

          <Controller
            name="notes"
            control={control}
            defaultValue={selectedRowData?.notes}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                onChange={(event, value) =>
                  handleFieldChange("notes", event.target.value)
                }
                size="small"
                multiline
                rows={4}
                InputProps={{
                  style: {
                    borderRadius: "14px",
                  },
                }}
              />
            )}
          ></Controller>
        </Grid>

        <Grid item xs={12} textAlign="end">
          <ApplyFilterButton
            size="medium"
            label="Submit"
            onClick={handleSubmit(onSubmit)}
          />
        </Grid>
      </Grid>
    </>
  );
}
