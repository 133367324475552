import {
  Backdrop,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import ApprovePaymentFilter from "./ApprovePaymentFilter";
import { useGetTokenOrLogout } from "../../../utils/token";
import CustomNoRowsOverlay from "../../newComponent/TableCustomeComp/CustomNoRowsOverlay";
import { DataGrid } from "@mui/x-data-grid";
import SearchTextFieldComp from "../../newComponent/newtextfieldComp/SearchTextFieldComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function ApprovedPayments() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [originalData, setOriginalData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        let url = "";
        // if (!filterCriteria) {
        //   url = `${API_ENDPOINT}route2.0/confirmedpayments/${userId}`;
        // } else {
        //   url = `${API_ENDPOINT}route2.0/fetchconfirmedpayment/${userId} `;
        // }

        const token = getTokenOrLogout();
        if (!token) return;
        let headers = {
          Authorization: `Bearer ${token}`,
        };
        url = `${API_ENDPOINT}route2.0/confirmedpayments/${userId}`;
        const response = await fetch(url, { headers });
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }

        const data = await response.json();

        const sortedData = data.sort(
          (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
        );

        setPayments(sortedData);
        setSearchData(sortedData);
        setIsLoading(false);
        setOriginalData(sortedData);
      } catch (error) {
        if (error.message.includes("401")) {
          navigate("/page401");
        } else if (error.message.includes("403")) {
          navigate("/page403");
        } else if (error.message.includes("404")) {
          navigate("/page404");
        } else if (error.message.includes("440")) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
        setIsLoading(false);
      }
    };
    fetchData();
  }, [userId]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.enquiry_id?.first_name || ""}${
          params?.row?.enquiry_id?.last_name || ""
        }`,
      renderCell: (params) => (
        <div
          style={{
            color: "#0096FF",

            cursor: "pointer",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          onClick={() =>
            window.open(
              `/enquirydetails/${params?.row.enquiry_id.id}`,
              "_blank"
            )
          }
        >
          {" "}
          {`${
            params?.row?.enquiry_id?.first_name
              ? params?.row?.enquiry_id?.first_name
              : ""
          } ${
            params?.row?.enquiry_id?.last_name
              ? params?.row?.enquiry_id?.last_name
              : ""
          }`}{" "}
        </div>
      ),
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 0.8,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row?.enquiry_id?.mobile || "",
      renderCell: (params) => <div>{params?.row.enquiry_id?.mobile}</div>,
    },
    {
      field: "branch",
      headerName: "Branch",
      flex: 1.2,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        params?.row?.enquiry_id?.branch_id?.branch_name || "",
      renderCell: (params) => (
        <div>{params?.row?.enquiry_id?.branch_id?.branch_name}</div>
      ),
    },
    {
      field: "payment_date",
      headerName: "Date",
      flex: 0.8,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params?.row?.payment_date)}</div>,
    },
    {
      field: "payment_mode",
      headerName: "Payment Mode",
      flex: 1,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row?.payment_mode_id?.payment_mode || "",
      renderCell: (params) => (
        <div>{params?.row?.payment_mode_id?.payment_mode}</div>
      ),
    },
    {
      field: "amount_paid",
      headerName: "Amount Collected",
      flex: 0.7,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.amount_paid}</div>,
    },
    {
      field: "collected_by",
      headerName: "Initiated By",
      flex: 1,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.collected_by?.first_name || ""}${
          params?.row?.collected_by?.last_name || ""
        }`,
      renderCell: (params) => (
        <div>
          {params?.row?.collected_by?.first_name}{" "}
          {params?.row?.collected_by?.last_name}
        </div>
      ),
    },
    {
      field: "approved_by",
      headerName: "Approved By",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row?.approved_by?.first_name}{" "}
          {params?.row?.approved_by?.last_name}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.7,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row?.collected_status === "received" && (
            <Chip
              size="normal"
              variant="contained"
              label="Received"
              color="error"
            />
          )}
          {params?.row?.collected_status === "deposited" && (
            <Chip
              label="normal"
              size="small"
              variant="contained"
              color="warning"
            />
          )}
          {params?.row?.collected_status === "confirmed" && (
            <Chip
              label="Confirmed"
              size="normal"
              variant="contained"
              color="primary"
            />
          )}
        </div>
      ),
    },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   flex: 1,
    //   headerClassName: "header-cell",
    //   renderCell: (params) => (
    //     <div>
    //       {params?.row?.approved_by?.first_name}{" "}
    //       {params?.row?.approved_by?.last_name}
    //     </div>
    //   ),
    // },
  ];

  // Search All column data
  const handleSearchByName = (e) => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, ""); // Normalize search value (lowercase and remove spaces)

    let filteredData1 = searchData.filter((data) => {
      // Check each field for a match with the search value
      const fieldsToSearch = [
        `${data?.enquiry_id?.first_name || ""} ${
          data?.enquiry_id?.last_name || ""
        }`,

        `${data?.enquiry_id?.mobile || ""}`,
        `${data?.enquiry_id?.branch_id?.branch_name || ""}`,
        `${data?.payment_mode_id?.payment_mode || ""}`,

        `${fDate(data?.payment_date || "")}`,
        `${data?.amount_paid || ""}`,
        `${data?.collected_by?.first_name || ""} ${
          data?.collected_by?.last_name || ""
        }`,
        `${data?.approved_by?.first_name || ""} ${
          data?.approved_by?.last_name || ""
        }`,

        `${
          data?.collected_status === "received"
            ? "generated"
            : data?.collected_status || ""
        }`,
      ];

      // Check if any of the fields contain the search value
      return fieldsToSearch.some((field) =>
        field.toLowerCase().replace(/\s+/g, "").includes(value)
      );
    });

    setPayments(filteredData1); // Update the filtered data
  };
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <ApprovePaymentFilter
            setPayments={setPayments}
            originalData={originalData}
            isLoading={isLoading}
            showSnackbar={showSnackbar}
            setIsLoading={setIsLoading}
            setSearchData={setSearchData}
          />
        </Grid>

        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="end">
                <SearchTextFieldComp onChange={handleSearchByName} />
              </Grid>

              <Grid item xs={12}>
                <DataGrid
                  rows={payments}
                  columns={columns}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[25, 50, 100]}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default ApprovedPayments;
