// assets
import SchoolIcon from "@mui/icons-material/School";
import SettingsIcon from "@mui/icons-material/Settings";
import PeopleIcon from "@mui/icons-material/People";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Groups3Icon from "@mui/icons-material/Groups3";
import AppRegistrationTwoToneIcon from "@mui/icons-material/AppRegistrationTwoTone";
import PaymentsTwoToneIcon from "@mui/icons-material/PaymentsTwoTone";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import CampaignIcon from "@mui/icons-material/Campaign";
import DiscountIcon from "@mui/icons-material/Discount";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import EventIcon from "@mui/icons-material/Event";
import RecyclingIcon from "@mui/icons-material/Recycling";
import ArticleIcon from "@mui/icons-material/Article";
import SummarizeIcon from "@mui/icons-material/Summarize";
import PaidIcon from "@mui/icons-material/Paid";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
const icons = {
  DashboardIcon,
  SettingsIcon,
  PeopleIcon,
  CalendarMonthIcon,
  SchoolIcon,
  Groups3Icon,
  AppRegistrationTwoToneIcon,
  PaymentsTwoToneIcon,
  AssignmentIcon,
  SettingsIcon,
  AssessmentIcon,
  PublishOutlinedIcon,
  PaidOutlinedIcon,
  ConfirmationNumberIcon,
  SchoolIcon,
  CampaignIcon,
  DiscountIcon,
  PhoneCallbackIcon,
  ExitToAppIcon,
  EventIcon,
  RecyclingIcon,
  ArticleIcon,
  CalendarMonthIcon,
  SummarizeIcon,
  PaidIcon,
  QrCodeScannerIcon,
  CrisisAlertIcon,
};

const utilities = {
  id: "utilities",
  //title: 'Utilities',
  type: "group",
  children: [ 
    {
        id: 'default',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        icon: icons.DashboardIcon,
        breadcrumbs: false,
        
      },
      {
           id: 'revenuereports',
           title: 'Center Admin Reports',
           type: 'collapse',
          //url: '/enquiries',
           icon: icons.AssessmentIcon,
           breadcrumbs: false,
          children: [
               {
                 id: 'center-reports',
                 title: 'Center Reports',
                 type: 'item',
                 url:'/centerreport',
                 breadcrumbs: false
               },
               {
                id: 'advisor-reports',
                title: 'Advisor Reports',
                type: 'item',
                url:'/advisorreport',
                breadcrumbs: false,
                
              },
              {
                id: 'advisor-target-report',
                title: 'Advisor Target Report',
                type: 'item',
                url:'/advisor-target',
                breadcrumbs: false,
                
              },
              {
                id: "test-type-advisor-revenue",
                title: "Test Type Revenue",
                type: "item",
                url: "/test-type-wise-revenue",
                breadcrumbs: false,
                
              },
              {
                id: 'advisor-task-revenue-report',
                title: 'Task Wise Report',
                type: 'item',
                url:'/advisor-task-wise-revenue',
                breadcrumbs: false,
              },
            

              {
                id: 'test-Type-wise-enquiries',
                title: 'Sub Service Wise Enquiries',
                type: 'item',
                url:'/test-Type-wise-enquiries',
                breadcrumbs: false,
              },
             
             ]
         },
    
    {
      id: "reports",
      title: "Reports",
      type: "collapse",
      //url: '/enquiries',
      icon: icons.AssessmentIcon,
      breadcrumbs: false,
      children: [
        {
          id: "event-reports",
          title: "Event Reports",
          type: "item",
          url: "/event-report",
          breadcrumbs: false,
        },
        {
          id: "campaign-reports",
          title: "Campaign Reports",
          type: "item",
          url: "/campaign-report",
          breadcrumbs: false,
        },
        {
          id: "svo-reports",
          title: "SVO Reports",
          type: "item",
          url: "/svo-report",
          breadcrumbs: false,
        },
        {
          id: "task-reports",
          title: "Task Report",
          type: "item",
          url: "/task-report",
          breadcrumbs: false,
        },
        {
          id: "walkins",
          title: "Walkin Report",
          type: "item",
          url: "/walkins",
          breadcrumbs: false,
        },
      ],
    },

    {
      id: "work-report",
      title: "Work Report",
      type: "item",
      url: "/work-report",
      icon: icons.SummarizeIcon,
      breadcrumbs: false,
    },

    {
      id: "employeetarget",
      title: "Employee Target",
      type: "item",
      url: "/employee-target",
      icon: icons.CrisisAlertIcon,
      breadcrumbs: false,
    },
    {
      id: "admin-area",
      title: "Branch Admin Area",
      type: "item",
      url: "/branch",
      icon: icons.SettingsIcon,
      breadcrumbs: false,
    },
    {
      id: "test-types",
      title: "Exam Types",
      type: "item",
      url: "/test-types",
      icon: icons.SettingsIcon,
      breadcrumbs: false,
    },
    {
      id: "packages",
      title: "Packages",
      type: "item",
      url: "/packages",
      icon: icons.SettingsIcon,
      breadcrumbs: false,
    },

    /*  {
        id: 'setup',
        title: 'Set up',
        type: 'collapse',
        icon: icons.SettingsIcon,
        children: [
         /* {
            id: 'employees',
            title: 'Employees',
            type: 'item',
            url: '/employees',
            breadcrumbs: false
          },
          {
            id: 'test-types',
            title: 'Test Types',
            type: 'item',
            url: '/test-types',
            breadcrumbs: false
          },
         /* {
            id: 'branches',
            title: 'Branches',
            type: 'item',
            url: '/branches',
            breadcrumbs: false
          },
          {
            id: 'add-classes',
            title: 'Classes',
            type: 'item',
            url: '/add-classes',
            breadcrumbs: false
          },
          {
            id: 'packages',
            title: 'Packages',
            type: 'item',
            url: '/packages',
            breadcrumbs: false
          },
        ]
      },*/

    {
      id: "accounts",
      title: "Accounts",
      type: "collapse",
      //url: '/accounts',
      icon: icons.PaymentsTwoToneIcon,
      breadcrumbs: false,
      children: [
        /*   {
            id: 'pending-accounts',
            title: 'View Pending Payments',
            type: 'item',
           url:'/pendingaccounts',
            breadcrumbs: false
          },*/

        {
          id: "refund-requests",
          title: "View Refund Requests",
          type: "item",
          url: "/refundrequests",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "paid-accounts",
      title: "View Paid Payments",
      type: "item",
      url: "/paidaccounts",
      icon: icons.PaidIcon,
      breadcrumbs: false,
    },
    {
      id: "demos",
      title: "Demos",
      type: "item",
      url: "/all-demos",
      icon: icons.SchoolIcon,
      breadcrumbs: false,
    },
    {
      id: "enquiries",
      title: "Enquiries",
      type: "collapse",
      //url: '/enquiries',
      icon: icons.PeopleIcon,
      breadcrumbs: false,
      children: [
        {
          id: "enquiries",
          title: "View Enquiries",
          type: "item",
          url: "/enquiries",
          breadcrumbs: false,
          isNew: true,
        },
        {
          id: "add-enquiries",
          title: "Add Enquiries",
          type: "item",
          url: "/addenquirynew",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "students",
      title: "Students",
      type: "collapse",
      icon: icons.Groups3Icon,
      breadcrumbs: false,
      children: [
        {
          id: "students",
          title: "View Students",
          type: "item",
          url: "/view-students",
          breadcrumbs: false,
        },
        {
          id: "enroll-students",
          title: "Enroll Students",
          type: "item",
          url: "/enroll-student",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "classes",
      title: "Classes",
      type: "item",
      url: "/classlist",
      icon: icons.SchoolIcon,
      breadcrumbs: false,
    },
    {
      id: "attendance",
      title: "Attendance",
      type: "collapse",
      //url: '/enquiries',
      icon: icons.AppRegistrationTwoToneIcon,
      breadcrumbs: false,
      children: [
        {
          id: "view-attendance",
          title: "View Attendance",
          type: "item",
          url: "/view-attendance",
          breadcrumbs: false,
        },
        {
          id: "take-attendance",
          title: "Take Attendance",
          type: "item",
          url: "/take-attendance",
          breadcrumbs: false,
        },
      ],
    },

      {
        id: 'meeting',
        title: 'Meetings',
        type: 'item',
        url: '/employee-meeting',
          icon: icons.CalendarMonthIcon,
        breadcrumbs: false,
      
      },
      {
        id: 'lesson-plan',
        title: 'Lesson Plan',
        type: 'item',
        url: '/lessonplan',
          icon: icons.AssignmentIcon,
        breadcrumbs: false
      },
      {
        id: 'payments',
        title: 'Payments',
        type: 'collapse',
        //url: '/all-payments',
          icon: icons.PaymentsTwoToneIcon,
        breadcrumbs: false,
        isNew: true,
        children: [
          {
            id: 'cash-collections',
            title: 'Cash Collections',
            type: 'item',
            url:'/cash-collections',
            breadcrumbs: false,
            isNew: true
          },
          {
            id: 'onlinepayments',
            title: 'Online Payment',
            type: 'item',
            url:'/onlinepayments',
            breadcrumbs: false,
            isNew: true
          },
          {
            id: 'approvedpayments',
            title: 'Approved Payments',
            type: 'item',
            url:'/approvedpayments',
            breadcrumbs: false,
            isNew: true
          },
          {
            id: 'declinedpayments',
            title: 'Declined Payments',
            type: 'item',
            url:'/declinepayment',
            breadcrumbs: false,
            isNew: true,
          
          },
          {
            id: 'pendingpayments',
            title: 'Pending Payments',
            type: 'item',
            url:'/pendingpayments',
            breadcrumbs: false,
            isNew: true,
          
          }
        ]
      },
      {
        id: 'approvecashdeposits',
        title: 'Approve Cash Deposits',
        type: 'item',
        url: '/approvedepositslip',
          icon: icons.PaymentsTwoToneIcon,
        breadcrumbs: false,
       
      },
      {
        id: 'approveonlinepayments',
        title: 'Approve Online Payments',
        type: 'item',
        url: '/approveonlinepayments',
          icon: icons.PaymentsTwoToneIcon,
        breadcrumbs: false,
     
      },
      {
        id: 'pendingpayments',
        title: 'Pending Payments',
        type: 'item',
        url:'/pendingpayments',
        icon: icons.PaymentsTwoToneIcon,
        breadcrumbs: false,
        isNew: true,
      
      },
      {
        id: 'futureenrolments',
        title: 'Update Future Enrolments',
        type: 'item',
        url: '/futureenrolments',
      icon: icons.ConfirmationNumberIcon,
        breadcrumbs: false,
       
      },
      {
        id: 'tasks',
        title: 'Tasks',
        type: 'item',
        url: '/all-tasks',
          icon: icons.AssignmentIcon,
        breadcrumbs: false
      },
      {
        id: 'trainer-leave-request',
        title: 'Trainer Leave Requests',
        type: 'item',
        url: '/leaverequests',
        icon: icons.ExitToAppIcon,
        breadcrumbs: false,
      
      },
      {
        id: "advisor-leave-request",
        title: "Advisor Leave Request",
        type: "item",
        url: "/holiday-request",
        icon: icons.ExitToAppIcon,
        breadcrumbs: false,
        isNew: true,
      },
      {
        id: 'studentleaverequests',
        title: 'Student Leave Requests',
        type: 'item',
        url: '/studentleaverequests',
        icon: icons.ExitToAppIcon,
        breadcrumbs: false,
     
      },
      {
        id: 'requestcallbacks',
        title: 'Request Callbacks',
        type: 'item',
        url: '/requestcallbacks',
        icon: icons.PhoneCallbackIcon,
        breadcrumbs: false,
      
      },
      {
        id: 'expense',
        title: 'Track Expenses',
        type: 'item',
        url: '/expense',
          icon: icons.PaidOutlinedIcon,
        breadcrumbs: false
      },
      {
        id: 'campaign',
        title: 'Campaigns',
        type: 'item',
        url: '/campaign',
        icon: icons.CampaignIcon,
        breadcrumbs: false,
        
      },
      {
        id: 'eventzone',
        title: 'Event Section',
        type: 'collapse',
        //url: '/enquiries',
        icon: icons.EventIcon,
        breadcrumbs: false,
        isNew: true,
        children: [
            {
              id: 'event-roadmap',
              title: 'Event Roadmap',
              type: 'item',
              url:'/event-roadmap',
              breadcrumbs: false, 
          
            },
         
            {
              id: 'registration-desk',
              title: 'Registration Desk',
              type: 'item',
              url:'/registration-desk',
              breadcrumbs: false, 
           
            },
           
            
            {
              id: 'counsling-desk',
              title: 'Counselling Desk',
              type: 'item',
              url:'/counselling-desk',
              breadcrumbs: false, 
             
            }
          ]
      },
      {
        id: 'import',
        title: 'Import',
        type: 'collapse',
        //url: '/enquiries',
        icon: icons.PublishOutlinedIcon, 
        breadcrumbs: false,
        children: [
            {
              id: 'import-leads',
              title: 'Import Leads',
              type: 'item',
              url:'/addbulkenquiries',
              breadcrumbs: false
            },
            {
              id: 'import-tasks',
              title: 'Import Tasks',
              type: 'item',
              url:'/addbulktasks',
              breadcrumbs: false
            },
            {
              id: 'import-notes',
              title: 'Import Notes',
              type: 'item',
              url:'/addbulknotes',
              breadcrumbs: false
            },
            {
              id: 'import-event-attendees',
              title: 'Import Event Attendees',
              type: 'item',
              url:'/addbulkeventattendees',
              breadcrumbs: false
            }
          ]
      },

      
      // {
      //   id: 'reports',
      //   title: 'Reports',
      //   type: 'collapse',
      //   //url: '/enquiries',
      //   icon: icons.AssessmentIcon,
      //   breadcrumbs: false,
      //   children: [
      //       {
      //         id: 'leads-reports',
      //         title: 'Leads Report',
      //         type: 'item',
      //        // url:'/lead-report',
      //         breadcrumbs: false
      //       }
      //     ]
      // },
      {
        id: 'discount',
        title: 'Discount Requests',
        type: 'item',
        url: '/discount',
      icon: icons.DiscountIcon,
        breadcrumbs: false,
   
      },
   

    // {
    //   id: 'reports',
    //   title: 'Reports',
    //   type: 'collapse',
    //   //url: '/enquiries',
    //   icon: icons.AssessmentIcon,
    //   breadcrumbs: false,
    //   children: [
    //       {
    //         id: 'leads-reports',
    //         title: 'Leads Report',
    //         type: 'item',
    //        // url:'/lead-report',
    //         breadcrumbs: false
    //       }
    //     ]
    // },
    {
      id: "importedleads",
      title: "Imported Leads",
      type: "item",
      url: "/todaysenquiry",
      icon: icons.PeopleIcon,
      breadcrumbs: false,
    },
    {
      id: "missedleads",
      title: "Missed Auto Assign Leads",
      type: "item",
      url: "/missed-autoassign-leads",
      icon: icons.PeopleIcon,
      breadcrumbs: false,
      isNew: true
    },
    {
      id: "raiseticket",
      title: "Raise Ticket",
      type: "item",
      url: "/raiseticket",
      icon: icons.ConfirmationNumberIcon,
      breadcrumbs: false,
    },
    {
      id: "viewticket",
      title: "View Tickets",
      type: "item",
      url: "/allticket",
      icon: icons.ConfirmationNumberIcon,
      breadcrumbs: false,
    },
    {
      id: "recyclerequets",
      title: "Recycle Request",
      type: "item",
      url: "/recyle-request",
      icon: icons.RecyclingIcon,
      breadcrumbs: false,
    },
    {
      id: "addtemplate",
      title: "View Email Template",
      type: "item",
      url: "/add-tamplate",
      icon: icons.ArticleIcon,
      breadcrumbs: false,
    },
    {
      id: "classsearch",
      title: "Classes Search",
      type: "item",
      url: "/classeslist",
      icon: icons.SchoolIcon,
      breadcrumbs: false,
    
    },

    {
      id: "walkin-qr",
      title: "Offline WalkIns QR",
      type: "item",
      url: "/generarebranchqr",
      icon: icons.QrCodeScannerIcon,
      breadcrumbs: false,
      isNew: true,
    },
    {
      id: "employees-profile",
      title: "Employees Profile",
      type: "item",
      url: "/employees-profile",
      icon: icons.PeopleIcon,
      breadcrumbs: false,
      isNew: true,
    },

    {
      id: "employee-status",
      title: "Auto-Assign Advisor",
      type: "item",
      url: "/employee-status",
      icon: icons.PeopleIcon,
      breadcrumbs: false,
      isNew: true,
    },

    
  ],
};

export default utilities;
