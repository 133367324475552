import React from "react";
import {
  Autocomplete,
  Chip,
  TextField,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import CustomAutocompleteTheme from "../themes/AutocompleteTheme";

const CustomAutocompleteComp = ({
  label,
  value,
  onChange,
  options,
  getOptionLabel,
  tooltipContent,
  multiple = false,
  limitTags = 1,
  noOptionsText,
  size = "small",
  endAdornment,
  placeholder,
  error,
  helperText,

  ...rest
}) => {
  return (
    <div>
      <ThemeProvider theme={CustomAutocompleteTheme}>
        <Autocomplete
          multiple={multiple}
          value={value}
          onChange={onChange}
          noOptionsText={noOptionsText}
          options={options}
          getOptionLabel={getOptionLabel}
          limitTags={limitTags}
          {...rest}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={value?.length===0 ? placeholder : ""}
              
              
              size={size}
              error={error} // Attach error state
              helperText={helperText} // Attach helperText
              InputProps={{
                ...params.InputProps,
                style: { borderRadius: "10px" },
                endAdornment: (
                  <>
                    {endAdornment
                      ? endAdornment // Custom endAdornment
                      : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Tooltip
                key={index}
                title={tooltipContent(option)}
                slotProps={{ tooltip: { sx: { fontSize: "0.7rem" } } }}
              >
                <span>
                  {" "}
                  {/* Wrapping Chip with span for hover */}
                  <Chip
                    sx={{ cursor: "pointer" }}
                    label={getOptionLabel(option)}
                    {...getTagProps({ index })}
                  />
                </span>
              </Tooltip>
            ))
          }
        />
      </ThemeProvider>
    </div>
  );
};

export default CustomAutocompleteComp;
