import {
    Backdrop,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Stack,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  export default function AssignAdvisorToMissedLeads({ selectedid, onClose }) {
    const userId = localStorage.getItem("userId");
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    const [advisorList, setAdvisorList] = useState([]);
    const [advisor, setAdvisor] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [isLoading, setIsLoading] = useState(false);
  
    const showSnackbar = (message, severity) => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    };
    const handleadvisor = (e) => {
      setAdvisor(e.target.value);
    };
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_ENDPOINT}route/getalluser`);
          if (response.ok) {
            const data = await response.json();
  
            setAdvisorList(data);
          } else {
            throw new Error(`Request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, []);
  
    const handleSave = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${API_ENDPOINT}upload/assign-manually`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: parseInt(userId),
            assign_to: advisor,
            missed_enquiry_id: selectedid,
          }),
        });
        if (response.ok) {
          // Handle success
          setIsLoading(false);
          showSnackbar("Enquiry assigned successfully");
          console.log("Enquiry created successfully.");
  
          setTimeout(() => {
            onClose();
            window.location.reload(); 
          }, 1500);
        } else if (response.status === 409) {
          // Handle conflict (status code 409)
          setIsLoading(false);
          showSnackbar("Duplicate mobile number", "error");
          console.error("Duplicate mobile number. Please use a different one.");
          setTimeout(() => {
            onClose(); // Assuming handleClose is a function you've defined elsewhere
          }, 1500);
        } else if (response.status === 400) {
            
            setIsLoading(false);
            showSnackbar("Please select advisor", "error");
            console.error("Advisor cannot be null.");
        } else if (response.status === 405) {
            
          setIsLoading(false);
          showSnackbar("First Name, Mobile, Nearest Branch, Service, Sub-Service, Lead Source, Sub Lead Source can't be null", "error");
          console.error("These field are required.");
          setTimeout(() => {
            onClose();
            window.location.reload();
          }, 3000);
      }
        else {
          setIsLoading(false);
          showSnackbar("Error assigning advisor", "error");
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error creating enquiry:", error);
      }
    };

    return (
      <>
        <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
          <Stack direction="row" spacing={1}>
            <Typography
              variant="h4"
              color="secondary"
              fontWeight={800}
              marginRight={2}
            >
              Assigning Enquiries....
            </Typography>
            <CircularProgress color="primary" />
          </Stack>
        </Backdrop>
        <Grid container>
          <Grid item xs={6} lg={12} md={6} sm={6}>
            <FormControl fullWidth>
              <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                Name Of The Advisor
              </Typography>
              <Select
                style={{ borderRadius: "15px" }}
                MenuProps={MenuProps}
                value={advisor}
                onChange={handleadvisor}
              >
                {advisorList.map((item) => (
                  <MenuItem value={item.id}>
                    {item.first_name} {item.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
  
          <Grid
            item
            xs={12}
            letterSpacing={12}
            md={12}
            sm={12}
            textAlign="end"
            marginTop="10px"
          >
            <Button
              variant="contained"
              color="secondary"
              style={{ fontSize: "16px", borderRadius: "24px" }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </>
    );
  }