import PropTypes from 'prop-types';

// material-ui
import { Box, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';

// project imports
import MainCard from '../MainCard';
import NewCardcomp from '../../../../../components/card/NewCardcomp';

// ==============================|| REPORT CARD ||============================== //

const BranchCard = ({ primary, secondary, iconPrimary, color, onClick, backGround,isLoading   }) => {
    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary ? <IconPrimary fontSize="large" /> : null;

    return (
        <NewCardcomp
        hoverEffect={true}
        hoverBgColor={backGround}
        onClick={onClick}
        >
        
        <Grid container flexDirection="row"  >
            <Box sx={{position:'absolute'}}>
                <Typography variant="h2" style={{ color }}>
                    {primaryIcon}
                </Typography>
            </Box>
    
            <Box display="flex" flexDirection="column" ml={2} justifyContent="center" alignItems="center" width='100%'>
                <Typography variant="h4" style={{ color: "rgb(132, 128, 128)" }}>
                    {secondary}
                </Typography>

                <Typography variant="h3">{isLoading? <CircularProgress/> : primary}</Typography>
            </Box>
        </Grid>
        
    </NewCardcomp>
    
    );
};

BranchCard.propTypes = {
    primary: PropTypes.string,
    secondary: PropTypes.string,
    iconPrimary: PropTypes.object,
    color: PropTypes.string
};

export default BranchCard;