import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";

// material-ui
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Stack,
  TextareaAutosize,
  InputAdornment,
} from "@mui/material";

// project imports
import SubCard from "../../components/ui-component/card/SubCard";
import CloseIcon from "@mui/icons-material/Close";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import CustomPopup from "../../../components/CustomPopup";
import SpeechText from "./SpeechText";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// ==============================|| KANBAN BOARD - ADD ITEM ||============================== //

const AddItem = ({ studentId }) => {
  const userId = localStorage.getItem("userId");

  const [addTaskBox, setAddTaskBox] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isRecording, setIsRecording] = useState(false); // Tracks recording state
  const recognitionRef = useRef(null); // Stores speech recognition instance

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleAddTaskChange = () => {
    setAddTaskBox((prev) => !prev);
  };

  const [title, setTitle] = useState(""); // This is for manual input

  // const addTask = () => {
  //   if (title.length > 0) {
  //     const requestData2 = {
  //       note: title,
  //       enquiry_id: studentId,
  //       user_id: parseInt(userId),
  //     };

  //     fetch(`${API_ENDPOINT}note`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestData2),
  //     })
  //       .then((response) => {
  //         if (response.status === 201) {
  //           showSnackbar("Note added successfully", "success");
  //           setTitle(""); // Reset title after successful add
  //         setTimeout(() => {
  //           window.location.reload()
  //         }, 1500);
            
  //         } else {
  //           showSnackbar("Error adding note. Please try again.", "error");
  //         }
  //       })
  //       .catch((error) => {
  //         showSnackbar(
  //           "A network error occurred. Please try again later.",
  //           "error"
  //         );
  //       });

  //     handleAddTaskChange();
  //   } else {
  //   }
  // };

  const addTask = async () => {
    
    if (title.length > 0) {

    const requestData2 = {
      note: title,
      enquiry_id: studentId,
      user_id: parseInt(userId),
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}note`,
        {
          method: "POST",
           headers :{
       
            "Content-Type": "application/json", // Make sure to keep the content type
          },
          body: JSON.stringify(requestData2),
        }
      );

      if (response.ok) {


        showSnackbar("Note added successfully", "success");
            setTitle(""); // Reset title after successful add
            handleAddTaskChange();
          setTimeout(() => {
            window.location.reload()
          }, 1000);
      } else {
        showSnackbar("Error adding note. Please try again.", "error");
      }
    } catch (error) {
      showSnackbar("Error adding note. Please try again.", "error");
    }
  }
  };

  // Initialize Speech Recognition API
  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      alert("Web Speech API is not supported in this browser.");
      return;
    }
    recognitionRef.current = new SpeechRecognition(); // Initialize recognition
    recognitionRef.current.continuous = true; // Keep listening
    recognitionRef.current.interimResults = false; // Get real-time results
    recognitionRef.current.lang = "en-US"; // Set language

    recognitionRef.current.onresult = (event) => {
      const lastIndex = event.results.length - 1;
      const newSpeech = event.results[lastIndex][0].transcript.trim();

      // Always append spoken text (even if it's the same)
      setTitle((prevText) =>
        prevText ? prevText + " " + newSpeech : newSpeech
      );
    };

    recognitionRef.current.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
    };
  }, []);

  // Start Speech Recognition
  const startRecording = () => {
    if (recognitionRef.current) {
      recognitionRef.current.start();
      setIsRecording(true);
    }
  };

  // Stop Speech Recognition
  const stopRecording = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleClearNote = () => {
    setTitle("");
    stopRecording();
    setAddTaskBox(false);
  };
  return (
    <>
      <Grid container alignItems="center" spacing={1} sx={{ marginTop: 1 }}>
        {addTaskBox && (
          <Grid item xs={12}>
            <SubCard
              contentSX={{
                p: 2,
                transition: "background-color 0.25s ease-out",
              }}
            >
              <Grid container alignItems="center" spacing={0.5}>
                <Grid item xs={12}>
                  {/* <TextareaAutosize
                    fullWidth
                    placeholder="Type or Speak..."
                    value={title} // Display the combined text (speech + typed)
                    onChange={handleTaskTitle} // Handle manual typing
                    onKeyUp={(event) => {
                      handleAddTask(event);
                    }}
                    multiline
                   
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                      border: "none",
                      outline: "none",
                      resize: "none",
                     
                    }}
                    helperText={isTitle ? "Task title is required." : ""}
                    error={isTitle}
                  /> */}
                  <TextField
                    fullWidth
                    placeholder="Type or Speak..."
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    variant="outlined"
                    multiline
                    sx={{
                      "& input": {
                        bgcolor: "transparent",
                        p: 0,
                        borderRadius: "0px",
                      },
                      "& fieldset": { display: "none" },
                      "& .MuiFormHelperText-root": {
                        ml: 0,
                      },
                      "& .MuiOutlinedInput-root": {
                        bgcolor: "transparent",
                      },
                    }}
                    InputProps={{
                      inputComponent: TextareaAutosize,
                      inputProps: {
                        minRows: 1, // Start with one line
                        maxRows: 5, // Expand up to five lines
                      },
                      endAdornment: (
                        <InputAdornment position="start">
                          <CloseIcon
                            fontSize="small"
                            color="error"
                            style={{ cursor: "pointer" }}
                            onClick={handleClearNote}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs zeroMinWidth />
                <Grid item>
                  {/* <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={isRecording ? stopRecording : startRecording}
                  >
                    {isRecording ? "Stop Recording" : "Start Recording"}
                  </Button> */}
                  <Stack direction="row" alignItems="center">
                    {isRecording ? (
                      <img
                        src="/podcast.gif"
                        onClick={stopRecording}
                        width={30}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <>
                        <img
                          src="/mute.png"
                          onClick={startRecording}
                          width={20}
                          style={{ cursor: "pointer" }}
                        />
                      </>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addTask}
                      size="small"
                    >
                      Add
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
        )}
        {!addTaskBox && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "100%" }}
              onClick={handleAddTaskChange}
            >
              Add Note
            </Button>
          </Grid>
        )}

        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </Grid>
    </>
  );
};

AddItem.propTypes = {
  studentId: PropTypes.string.isRequired,
};

export default AddItem;
