// Author : Ayesha Shaikh
//  Date : 22 Mar 2025

import React, { useEffect, useState } from "react";
import CustomNoRowsOverlay from "../../../newComponent/TableCustomeComp/CustomNoRowsOverlay";
import { DataGrid } from "@mui/x-data-grid";
import { fDate, fDateTime } from "../../../../utils/formatTime";
import { Box, Button, Grid, Typography } from "@mui/material";
import { CSVExport } from "../../../components/ui-component/table/CSVExport";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function SubserviceEnquiryDetails({
  data,
  payLoadData,
  stageName,
  prospectiveLeval,
  subService,
  stagePopup
}) {
  const [exportData, setExportData] = useState([]);
  const [showBranch, setShowBranch] = useState(false);

  const columns = [
    {
      field: "name", // Logical field name for the column
      headerName: "Enquiry Name",
      width: 200,
      // sortable: true, // Enable sorting
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        `${params.row?.first_name || ""} ${params.row?.last_name || ""}`, // Use direct API fields
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{ fontSize: "0.65rem" }}
              onClick={() =>
                window.open(`/enquirydetails/${params?.row?.id}`, "_blank")
              }
            >
              {params.row?.first_name} {params.row?.last_name}
            </Button>
          </div>
        );
      },
    },

    {
      field: "mobile",
      headerName: "Mobile",
      width: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "bold-header",
      valueGetter: (params) =>
        `${params.row?.mobile_country_code}${params.row?.mobile || ""}`,

      renderCell: (params) => (
        <div>
          {params.row?.mobile_country_code}
          {params.row?.mobile}
        </div>
      ),
    },
    {
      field: "enquiry_date",
      headerName: "Enquiry Date",
      width: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "bold-header",
    },
    {
      field: "prospective_level_id",
      headerName: "Prospective Level",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value?.prospective_level || "",
    },

    {
      field: "nearest_branch_id",
      headerName: "Nearest Branch",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        `${params.row.nearest_branch_id?.branch_name || ""}`,
    },
    {
      field: "branch_id",
      headerName: "Branch",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => `${params.row?.branch_id?.branch_name || ""}`,
    },
    {
      field: "service_id",
      headerName: "Service",
      width: 150,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value?.service_name || "",
    },
    {
      field: "sub_service_id",
      headerName: "Sub Service",
      width: 150,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value?.name || "",
    },

    {
      field: "campaign",
      headerName: "Campaign",
      width: 350,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params?.row?.campaign_id?.campaign_name || "",
    },

    {
      field: "assign_to",
      headerName: "P.Advisor",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params?.row?.assign_to?.first_name || "",
    },

    {
      field: "lead_source_type_id",
      headerName: "LS",
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      valueFormatter: (params) => params.value?.name || "",
    },
    {
      field: "sub_lead_source_type_id",
      headerName: "SLS",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value?.source_type || "",
    },

    {
      field: "stage_id",
      headerName: "Stage",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.value?.stage_name || "",
    },
  ];

  // Export Data

  useEffect(() => {
    const exportData = data?.map((row) => ({
      name: row?.last_name
        ? `${row?.first_name} ${row?.last_name}`
        : row.first_name,
      mobile: row.mobile,
      prospective_level: row?.prospective_level_id?.prospective_level || "",

      nearest_branch: row?.nearest_branch_id?.branch_name || " ",
      branch: row?.branch_id?.branch_name || "",
      service: row?.service_id?.service_name || "",
      sub_service: row?.sub_service_id?.name || "",
      campaign_name: row.campaign_id?.campaign_name,
      lead_source_type: row.lead_source_type_id?.name || "",
      sub_lead_source_type: row.sub_lead_source_type_id?.source_type || "",
      stage: row?.stage_id?.stage_name || "",
      assign_to: row?.assign_to?.first_name || "",
    }));
    setExportData(exportData);
  }, [data]);

  const handleToggleShowBranch = () => {
    setShowBranch(!showBranch);
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={6} md={6} textAlign="end">
          <Typography variant="h4">
            {payLoadData?.monthname} {payLoadData?.selectedYear?.year}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} textAlign="end">
          <CSVExport data={exportData} filename="EnquiryDetails.csv" />
        </Grid>
        {stagePopup && (
        <Grid item xs={12} container gap={3}>
          <Box display="flex" flexDirection="row">
            <Typography variant="h5">Stage : </Typography>{" "}
            <Typography ml={0.5}>{stageName?.stage_id?.stage_name}</Typography>{" "}
          </Box>
          {prospectiveLeval !== null && (
            <Box display="flex" flexDirection="row">
              <Typography variant="h5">Prospective Level: </Typography>{" "}
              <Typography ml={0.5}>{prospectiveLeval}</Typography>{" "}
            </Box>
          )}
          <Box display="flex" flexDirection="row">
            <Typography variant="h5">Sub Service : </Typography>{" "}
            <Typography ml={0.5}>{subService?.name}</Typography>{" "}
          </Box>
        </Grid>
         )}
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            onClick={handleToggleShowBranch}
            sx={{ cursor: "pointer" }}
          >
            <Typography variant="h5">Selected Branches</Typography>
            {showBranch ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Box>
        </Grid>
        {showBranch && (
          <Grid item xs={12}>
            <Box
              sx={{
                border: "1px solid #888888",
                padding: "10px",
                borderRadius: "10px",
                display: "flex",
                gap: 1,
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              {payLoadData?.branchName.map((branch, index) => (
                <>
                  <Typography variant="h6">{branch?.branch_name}</Typography>
                  {index !== payLoadData.branchName.length - 1 && (
                    <Box
                      sx={{
                        borderLeft: "1px solid #888888",
                        height: "20px",
                        marginLeft: 1,
                      }}
                    />
                  )}
                </>
              ))}
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataGrid
            rows={data}
            columns={columns}
            autoHeight
            disableColumnSorting={true}
            // disableColumnMenu={true} // Disables the column menu for all columns
            disableColumnFilter // Disables the filter functionality globally
            disableColumnSelector // Disables the column selector
            // pageSize={5}
            // defaultDensity="compact"
            // initialState={{
            //   pagination: {
            //     paginationModel: {
            //       pageSize: 100,
            //     },
            //   },
            // }}

            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
