import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import ApproveOnlinePaymentFilter from "./ApproveOnlinePaymentFilter";
import ApproveOnlinePaymentInstallation from "./PaymentInstallation";
import CustomPopup from "../../../components/CustomPopup";
import { useGetTokenOrLogout } from "../../../utils/token";
import { DataGrid } from "@mui/x-data-grid";
import UploadIconcomp from "../../newComponent/iconsComp/UoloadIconcomp";
import CustomNoRowsOverlay from "../../newComponent/TableCustomeComp/CustomNoRowsOverlay";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SearchTextFieldComp from "../../newComponent/newtextfieldComp/SearchTextFieldComp";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PATH_URL = process.env.REACT_APP_API_UPLOADS;

function ApproveOnlinePayments() {
  const navigate = useNavigate();

  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [originalData, setOriginalData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [openPopup, setOpenPopUp] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [opendeclined, setOpenDeclined] = useState(false);
  const [errors, setErrors] = useState(false);
  const [remark, setRemark] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const handleOpenDeclinedPopup = (id) => {
    setOpenDeclined(true);
    setSelectedId(id);
  };

  const handleCloseDeclinedPopup = () => {
    setOpenDeclined(false);
  };

  const handleOpenPopup = () => {
    setOpenPopUp(true);
  };

  const handleClosePopup = () => {
    setOpenPopUp(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleUpdateStatus = (paymentId, newStatus) => {
    // Update the status locally in the state
    const updatedPayments = payments.map((payment) =>
      payment.paymentId === paymentId
        ? { ...payment, payment_status: newStatus }
        : payment
    );
    setPayments(updatedPayments);

    // Send a request to the server to update the status
    fetch(`${API_ENDPOINT}route/editprovesional/${paymentId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payment_status: newStatus,
        user_id: parseInt(userId),
      }),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Payment status updated successfully", "success");
          // Remove the row from state if the status is updated successfully
          const updatedPayments = payments.filter(
            (payment) => payment.id !== paymentId
          );
          setPayments(updatedPayments);
        } else {
          showSnackbar("Failed to update payment status", "error");
          throw new Error("Failed to update payment status");
        }
        // Handle successful response if needed
      })
      .catch((error) => {
        console.error("Error updating payment status:", error);
        showSnackbar("Error updating payment status", "error");
        // Revert the status locally if the server update fails
        setPayments(payments);
      });
  };

  //http://localhost:3001/api/leadmanagement/route2.0/provesionalonlinepayment/1
  useEffect(() => {
    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    // Fetch expenses data
    fetch(`${API_ENDPOINT}route2.0/provesionalonlinepayment/${userId}`, {
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        const activePayments = data.filter(
          (item) => item?.payment_mode_id?.payment_mode === "Online payment"
        );
        console.log(activePayments);
        // Sort the filtered data by payment_date
        const sortedData = activePayments.sort(
          (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
        );

        setPayments(sortedData);

        setSearchData(sortedData);
        setIsLoading(false);
        setOriginalData(sortedData);
      })
      .catch((error) => {
        if (error.message.includes("401")) {
          navigate("/page401");
        } else if (error.message.includes("403")) {
          navigate("/page403");
        } else if (error.message.includes("404")) {
          navigate("/page404");
        } else if (error.message.includes("440")) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
        setIsLoading(false);
      });
  }, [userId]);

  const handleViewDepositSlip = (filePath) => {
    window.open(`${PATH_URL}paymentScreenshots/${filePath}`, "_blank");
  };

  const handleViewdetails = async (enquiry_id, class_id, package_id, data) => {
    console.log(
      "enquiry_id=",
      enquiry_id,
      "package_id=",
      package_id,
      "class_id=",
      class_id
    );
    console.log(data);
    setDetailsLoading(true);

    const requestData = {
      class_id: class_id,
      package_id: package_id,
      enquiry_id: enquiry_id,
      user_id: parseInt(userId),
    };
    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/showenqforaccount`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        const result = await response.json();
        setDetailsData(result);
        setDetailsLoading(false);
        handleOpenPopup();
      } else if (response.status === 401) {
        // Handle errors, e.g., show an error message
        showSnackbar("Please Verify that You are a User!!", "error");
        setDetailsLoading(false);
      } else if (response.status === 403) {
        showSnackbar("Unathorized User", "error");
        setDetailsLoading(false);
      } else {
        showSnackbar("Internal  Server Error", "error");
        setDetailsLoading(false);
      }
    } catch (error) {
      showSnackbar("Internal  Server Error", "error");
      setDetailsLoading(false);
    }
  };

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
    setErrors({ ...errors, remark: !e.target.value });
  };

  const handleDeclinedPayment = async () => {
    if (!remark) {
      setErrors({ remark: !remark });
      return;
    }

    const formdata = {
      id: selectedId,
      remarks: remark,
      user_id: parseInt(userId),
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route/declineonlinepayments`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      );

      if (response.ok) {
        showSnackbar("Payment Declined Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else if (response.status === 400) {
        showSnackbar("Id not found", "error");
      } else if (response.status === 401) {
        showSnackbar("Unathorized User", "error");
      } else {
        showSnackbar("Error Posting Data", "error");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      showSnackbar("Error Posting Data", "error");
    }
  };

  const ActionButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: "#E5E4E2",
    width: "20px",
    height: "20px",
  }));

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-cell",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ display: "flex", gap: 3 }}>
          {params?.row?.payment_screenshot_id && (
            <Tooltip title="View Payment Screenshot">
              <VisibilityOutlinedIcon
                fontSize="small"
                style={{
                  color: "#00b13f",
                  fontSize: "1rem",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleViewDepositSlip(
                    params?.row?.payment_screenshot_id?.file_uploaded
                  )
                }
              />
            </Tooltip>
          )}
          {userRole === "accountant" && (
            <>
              {params?.row?.collected_status === "deposited" && (
                <Tooltip title="change status to confirmed">
                  <CheckCircleIcon
                    style={{
                      color: "#00b13f",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                    variant="contained"
                    size="small"
                    onClick={() => handleUpdateStatus(params?.row.id, "paid")}
                  />
                </Tooltip>
              )}
              {params?.row?.collected_status === "deposited" && (
                <Tooltip title="Decline">
                  <CancelPresentationIcon
                    style={{ fontSize: "1rem", cursor: "pointer" }}
                    variant="contained"
                    color="error"
                    onClick={() => handleOpenDeclinedPopup(params?.row.id)}
                  />
                </Tooltip>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.enquiry_id?.first_name || ""} ${
          params?.row?.enquiry_id?.last_name || ""
        }`,
      renderCell: (params) => (
        <div
          style={{
            color: "#0096FF",

            cursor: "pointer",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          onClick={() =>
            handleViewdetails(
              params?.row?.enquiry_id?.id,
              params?.row?.class_id,
              params?.row?.package_id,
              params?.row
            )
          }
        >
          {" "}
          {`${
            params?.row?.enquiry_id?.first_name
              ? params?.row?.enquiry_id?.first_name
              : ""
          } ${
            params?.row?.enquiry_id?.last_name
              ? params?.row?.enquiry_id?.last_name
              : ""
          }`}{" "}
        </div>
      ),
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 100,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row?.enquiry_id?.mobile || "",
      renderCell: (params) => <div>{params?.row.enquiry_id?.mobile}</div>,
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 150,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        params?.row?.enquiry_id?.branch_id?.branch_name || "",
      renderCell: (params) => (
        <div>{params?.row?.enquiry_id?.branch_id?.branch_name}</div>
      ),
    },
    {
      field: "payment_date",
      headerName: "Date",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params?.row?.payment_date)}</div>,
    },
    {
      field: "R_L_Id",
      headerName: "Razorpay Link ID / Payment ID",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${
          params?.row?.payment_initiated_from === "student panel"
            ? params?.row?.razorpay_payment_id
            : params?.row?.razorpay_link_id
        }`,
      renderCell: (params) => (
        <div>
          {params?.row?.payment_initiated_from === "student panel"
            ? params?.row?.razorpay_payment_id
            : params?.row?.razorpay_link_id}
        </div>
      ),
    },
    {
      field: "amount_paid",
      headerName: "Amount Collected",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.amount_paid}</div>,
    },
    {
      field: "payment_initiated_from",
      headerName: "Payment Initiated From",

      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.payment_initiated_from}</div>,
    },
    {
      field: "initiated_by",
      headerName: "Initiated By",
      width: 150,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.created_by?.first_name || ""} ${
          params?.row?.created_by?.last_name || ""
        }`,
      renderCell: (params) => (
        <div>
          {params?.row?.created_by?.first_name}{" "}
          {params?.row?.created_by?.last_name}
        </div>
      ),
    },

    {
      field: "collected_status",
      headerName: "Status",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row?.collected_status === "received" && (
            <Chip
              variant="contained"
              size="normal"
              label="Generated"
              color="error"
            />
          )}
          {params?.row?.collected_status === "deposited" && (
            <Chip
              variant="contained"
              size="normal"
              label="Deposited"
              color="warning"
            />
          )}
          {params?.row?.collected_status === "confirmed" && (
            <Chip
              variant="contained"
              size="normal"
              label="Confirmed"
              color="primary"
            />
          )}
          {params?.row?.collected_status === "declined" && (
            <Chip
              variant="contained"
              size="normal"
              label="Declined"
              color="error"
            />
          )}
        </div>
      ),
    },
  ];

  // Search All column data
  const handleSearchByName = (e) => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, ""); // Normalize search value (lowercase and remove spaces)

    let filteredData1 = searchData.filter((data) => {
      // Check each field for a match with the search value
      const fieldsToSearch = [
        `${data?.enquiry_id?.first_name || ""} ${
          data?.enquiry_id?.last_name || ""
        }`,

        `${data?.enquiry_id?.mobile || ""}`,
        `${data?.enquiry_id?.branch_id?.branch_name || ""}`,
        `${data?.razorpay_link_id || ""}`,

        `${fDate(data?.payment_date || "")}`,
        `${data?.amount_paid || ""}`,
        `${data?.payment_initiated_from || ""}`,
        `${data?.created_by?.first_name || ""} ${
          data?.created_by?.last_name || ""
        }`,

        `${
          data?.collected_status === "received"
            ? "generated"
            : data?.collected_status || ""
        }`,
      ];

      // Check if any of the fields contain the search value
      return fieldsToSearch.some((field) =>
        field.toLowerCase().replace(/\s+/g, "").includes(value)
      );
    });

    setPayments(filteredData1); // Update the filtered data
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Backdrop open={detailsLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching Installment Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <ApproveOnlinePaymentFilter
            setPayments={setPayments}
            originalData={originalData}
            isLoading={isLoading}
            showSnackbar={showSnackbar}
            setIsLoading={setIsLoading}
            setSearchData={setSearchData}
          />
        </Grid>

        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="end">
                {" "}
                <SearchTextFieldComp onChange={handleSearchByName} />
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={payments}
                  columns={columns}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[25, 50, 100]}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <CustomPopup
        open={openPopup}
        onClose={handleClosePopup}
        component={
          <ApproveOnlinePaymentInstallation
            onClose={handleClosePopup}
            data={detailsData}
          />
        }
        title="Installment Details"
        showDivider="true"
        maxWidth="lg"
      />

      {/* Declined Dialog */}
      <Dialog
        open={opendeclined}
        onClose={handleCloseDeclinedPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography sx={{ fontWeight: 600 }}>
              Remark{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1em",
                }}
              >
                *
              </span>
            </Typography>
            <TextField
              placeholder="Type anything…"
              value={remark}
              fullWidth
              multiline
              rows={4}
              onChange={handleRemarkChange}
              InputProps={{ style: { borderRadius: "15px" } }}
              error={errors.remark}
              helperText={errors.remark && "Remark is required"}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeclinedPopup} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleDeclinedPayment}
            autoFocus
            variant="contained"
            color="error"
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ApproveOnlinePayments;
