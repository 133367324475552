import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FilterButtonComp from "../../AllUserDashboards/Components/FilterButtonComp";
import RevenueCards from "../../AllUserDashboards/CenterAdminDashboard/NewRevenueCards";
import NewCardcomp from "../../../components/card/NewCardcomp";
import BranchRevenueAndEnrollData from "./BranchRevenueAndEnrollData";
import LeadCalandar from "../../LeadConversation/LeadCalandar";
import { useGetTokenOrLogout } from "../../../utils/token";
import CustomPopup from "../../../components/CustomPopup";
import EnrollDetails from "../../AllUserDashboards/CenterAdminDashboard/EnrollDetails";
import MoreCardDetails from "../../AllUserDashboards/CenterAdminDashboard/MoreCardDetails";
import LoaderComp from "../../../newTestPrep/newComponent/LoaderComp/Loader";

import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import BranchEnrolledTarget from "./BranchEnrolledTarget";

import BranchRevenueTarget from "./BranchRevenueTarget";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AdminBranchDashBoard() {
  const { id } = useParams();

  const location = useLocation();
  const stateData = location?.state?.passedStateData;

  const userId = localStorage.getItem("userId");
  const [branchId, setBranchId] = useState(id || null);
  const getTokenOrLogout = useGetTokenOrLogout();
  const [selectedFilter, setSelectedFilter] = useState(
    stateData?.selectedFilter
  );
  const [fromDate, setFromDate] = useState(stateData?.fromDate || null);
  const [toDate, setToDate] = useState(stateData?.toDate || null);
  const [revenueLoading, setRevenueLoading] = useState(false);
  const [cardRevenueData, setCardRevenueData] = useState([]);

  const [chosenDate, setChosenDate] = useState(new Date());
  const [chosenDate1, setChosenDate1] = useState(new Date());

  const [opendetailPopup, setOpenDetailPopup] = useState(false);

  const [selectedCardName, setSelectedCardName] = useState("");

  const [SelectedCardData, setSelectedCardData] = useState([]);
  const [selectedcardFilter, setSelectedcardfilter] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [productWiseData, setProductWiseData] = useState([]);
  const [isproductloading, setIsProductLoading] = useState(false);
  const [revenueTargetLoading, setRevenueTargetLoading] = useState(true);
  const [revenueData, setRevenueData] = useState({});
  const [enrolledLoading, setEnrolledLoading] = useState(true);
  const [enrolledTargetData, setEnrolledTargetData] = useState({});

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClickFilter = (criteria, cardFilter) => {
    setSelectedFilter(criteria);

    if (criteria !== "custom") {
      handleApplyRevenuFilter(criteria, null, null);
      getBranchData(criteria, null, null);
      setFromDate(null);
      setToDate(null);
    }
    if (criteria === "custom" && fromDate !== null && toDate !== null) {
      handleApplyRevenuFilter(criteria, fromDate, toDate);
      getBranchData(criteria, fromDate, toDate);
    }
  };
  // Fetch Card Data
  const handleApplyRevenuFilter = async (criteria, fromDate, toDate) => {
    setRevenueLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),

      branch_id: parseInt(branchId),
      filter: criteria,
      to_date: toDate,
      from_date: fromDate,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route/gettodayrevenue`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();

        setCardRevenueData(result);

        setRevenueLoading(false);
      } else {
        console.log("Error");
        setRevenueLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      setRevenueLoading(false);
    }
  };

  const getBranchData = async (criteria, fromDate, toDate) => {
    setIsProductLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),

      branch_id: parseInt(branchId),
      filter: criteria,
      to_date: toDate,
      from_date: fromDate,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route/branchrevenueandenrollmentbyproduct`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setProductWiseData(result);

        setIsProductLoading(false);
      } else {
        console.log("Error");
        setIsProductLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      setIsProductLoading(false);
    }
  };

  // Fetch 1st render
  useEffect(() => {
    handleApplyRevenuFilter(selectedFilter, fromDate, toDate);
    getBranchData(selectedFilter, fromDate, toDate);
  }, []);

  const handleDateChange = (date) => {
    setChosenDate(date);
  };

  const handleDateChange1 = (date) => {
    setChosenDate1(date);
  };

  const handleOpenDetailPopup = (cardName, cardFilter) => {
    setOpenDetailPopup(true);
    setSelectedCardName(cardName);
    setSelectedcardfilter(cardFilter);
  };

  const handleCloseDetailPopup = () => {
    setOpenDetailPopup(false);
  };

  const getCardMoreInfo = async (cardName, cardFilter) => {
    setDetailsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),
      filter: selectedFilter,
      to_date: toDate,
      from_date: fromDate,
      filter_type: cardFilter,
      branch_id: parseInt(branchId),
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route/gettodayrevenue-with-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setSelectedCardData(result);
        setDetailsLoading(false);
        handleOpenDetailPopup(cardName, cardFilter);
      } else {
        console.log("Error");
        showSnackbar("Server Error", "error");
        setDetailsLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      showSnackbar("Server Error", "error");
      setDetailsLoading(false);
    }
  };

  // Branch Revenue Target

  const getBranchRevenueTarget = async () => {
    setRevenueTargetLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      user_id: parseInt(userId),

      branch_id: parseInt(branchId),
      date: chosenDate,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route/branch-revenue-target-achievement`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setRevenueData(result);
        setRevenueTargetLoading(false);
      } else {
        console.log("Error");
        showSnackbar("Server Error", "error");
        setRevenueTargetLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      showSnackbar("Server Error", "error");
      setRevenueTargetLoading(false);
    }
  };

  useEffect(() => {
    getBranchRevenueTarget(chosenDate);
  }, [chosenDate]);

  // get Enrolled Target

  const getBranchEnrolledTarget = async () => {
    setEnrolledLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      user_id: parseInt(userId),

      branch_id: parseInt(branchId),
      date: chosenDate1,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route/branch-enrollment-target-achievement`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setEnrolledTargetData(result);
        setEnrolledLoading(false);
      } else {
        console.log("Error");
        showSnackbar("Server Error", "error");
        setEnrolledLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      showSnackbar("Server Error", "error");
      setEnrolledLoading(false);
    }
  };

  useEffect(() => {
    getBranchEnrolledTarget(chosenDate1);
  }, [chosenDate1]);

  return (
    <>
      <LoaderComp isLoading={detailsLoading} />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={9} sm={12} md={9}>
          <Grid container spacing={2}>
            {/* Branch Name */}
            <Grid item xs={12}>
              <Typography variant="h3">
                Branch{" "}
                <span style={{ color: "#F8AB1D" }}>
                  ({stateData?.branchName})
                </span>
              </Typography>
            </Grid>

            {/* Filter Button */}
            <Grid item xs={12} sm={12} mt={2}>
              <FilterButtonComp
                selectedFilter={selectedFilter}
                handleClickFilter={handleClickFilter}
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
              />
            </Grid>

            {/* Revenue Cards */}
            <Grid item xs={12} container spacing={2} gap={1}>
              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#D5F6E1"
                  title="Total Revenue"
                  iconSrc="/Group 1000002878.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                      cardRevenueData?.total_revenue
                    )
                  }
                  onClick={() =>
                    getCardMoreInfo("Total Revenue", "total_revenue")
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#E7F3FF"
                  title="Collected Revenue"
                  iconSrc="/Group 1000002854.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                      cardRevenueData?.total_collected_revenue
                    )
                  }
                  onClick={() =>
                    getCardMoreInfo("Collected Revenue", "collected_revenue")
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#FFEDFB"
                  title="Pending Revenue"
                  iconSrc="/Group 1000002879.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                      cardRevenueData?.total_pending_revenue
                    )
                  }
                  onClick={() =>
                    getCardMoreInfo("Pending Revenue", "pending_revenue")
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#FFF6E2"
                  title="New Enrolments"
                  iconSrc="/Group 1000002880.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                      cardRevenueData?.total_new_enrollment
                    )
                  }
                  onClick={() =>
                    getCardMoreInfo("New Enrolments", "new_enrollments")
                  }
                />
              </Grid>
            </Grid>

            {/* Month Wise Data */}
            <Grid item xs={12}>
              <NewCardcomp height="460px">
                <BranchRevenueAndEnrollData
                  isproductloading={isproductloading}
                  productWiseData={productWiseData}
                  selectedFilter={selectedFilter}
                  fromDate={fromDate}
                  toDate={toDate}
                  branchId={branchId}
                />
              </NewCardcomp>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={3} sm={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NewCardcomp height={275}>
                <LeadCalandar onDateChange={handleDateChange} />
                {revenueTargetLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box mt={1} height="100%">
                    <BranchRevenueTarget
                      targetData={revenueData}
                      chosenDate={chosenDate}
                      branchId={branchId}
                    />
                  </Box>
                )}
              </NewCardcomp>
            </Grid>

            <Grid item xs={12}>
              <NewCardcomp height={330}>
                <LeadCalandar onDateChange={handleDateChange1} />
                {enrolledLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <BranchEnrolledTarget
                    targetData={enrolledTargetData}
                    loading={enrolledLoading}
                    chosenDate={chosenDate1}
                    branchId={branchId}
                  />
                )}
              </NewCardcomp>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <CustomPopup
        open={opendetailPopup}
        onClose={handleCloseDetailPopup}
        component={
          selectedcardFilter === "new_enrollments" ? (
            <EnrollDetails
              onClose={handleCloseDetailPopup}
              selectedFilter={selectedFilter}
              SelectedCardData={SelectedCardData}
              fromDate={fromDate}
              toDate={toDate}
            />
          ) : (
            <MoreCardDetails
              onClose={handleCloseDetailPopup}
              selectedFilter={selectedFilter}
              SelectedCardData={SelectedCardData}
              selectedCardName={selectedCardName}
              selectedCountFilter={selectedcardFilter}
              fromDate={fromDate}
              toDate={toDate}
            />
          )
        }
        title={`${selectedCardName} Details`}
        maxWidth="lg"
        showDivider={true}
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
