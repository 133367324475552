import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import {
  Autocomplete,
  Button,
  ButtonBase,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FilterComp from "../../newComponent/iconsComp/FilterComp";
import CustomAutocompleteComp from "../../newComponent/AutocompleteComp";
import ApplyFilterButton from "../../newComponent/NewButtonComp/ApplyFilterButton";
import ResetFilterButton from "../../newComponent/NewButtonComp/ResetFilterButton";
export default function ClassesFilter({ onApplyFilter, onResetFilter }) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const [classList, setClassList] = useState([]);
  const [Classname, setClassname] = useState([]);
  const [TrainerList, setTrainerList] = useState([]);
  const [trainerName, setTrainerName] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [modes, setModes] = useState([]);
  const [createfrom, setCreatedfrom] = useState("");
  const [createdto, setCreatedto] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}class`);
        if (response.ok) {
          const data = await response.json();
          setClassList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allclassList=[{name:"Select All"},...classList]
  const handelClasschange = (event,value) => {
 
    if (value.some((option) => option.name === "Select All")) {
      setClassname(classList);
    } else {
      setClassname(value);
    }

 
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}trainer/`);
        if (response.ok) {
          const data = await response.json();
          setTrainerList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allTrainerList=[{first_name:"Select All"},...TrainerList]
  const handelTrainerName = (event,value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setTrainerName(TrainerList);
    } else {
      setTrainerName(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allBranchList=[{branch_name:"Select All"},...branchlist]
  const handelbranchchange = (event,value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  const ModesList = [
    { id: 0, name: "Online", value: "online" },
    { id: 1, name: "Offline", value: "offline" },
  ];
  const handleModeChange = (event,value) => {
    setModes(value);
  };

  const handlecratedfrom = (e) => {
    setCreatedfrom(e.target.value);
  };

  const handlecreatedto = (e) => {
    setCreatedto(e.target.value);
  };

  const newClassName=Classname.map((i)=>i.id)
  const newTrainerName =trainerName.map((i)=>i.id)
  const newBranch=branch.map((i)=>i.id)
  const newModes =modes.map((i)=>i.value)
  const handleApplyFilter = () => {
    onApplyFilter({
      newClassName,
      newTrainerName,
      newBranch,
      createfrom,
      createdto,
      newModes,
    });
  };

  const resetfilter = () => {
    onResetFilter();

    setClassname([]);
    setTrainerName([]);
    setBranch([]);
    setCreatedfrom("");
    setCreatedto("");
    setModes([]);
  };
  return (
    <div>
      <MainCard>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
           variant="h3"
          >
            My Classes
          </Typography>
        
          <FilterComp
            onClick={handleToggle}
            fontSize="medium"
          />
  
       
        </Stack>

        <Collapse in={open} sx={{ marginTop: open? "10px":0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3} sm={6} md={6}>
              <FormControl sx={{ mb: 1, width: "100%" }}>
                <Typography variant="h6">Class Details</Typography>
              

<CustomAutocompleteComp
              
                value={Classname}
                multiple
                onChange={handelClasschange}
                options={allclassList}
                getOptionLabel={(option) =>
                 option.name
                }
                tooltipContent={(option) =>
                  option.name
                 }
              />
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={3} sm={6} md={6}>
         
                <Typography variant="h6">
                  Trainer Details
                </Typography>
             
        <CustomAutocompleteComp
            
                value={trainerName}
                multiple
                onChange={handelTrainerName}
                options={allTrainerList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
              tooltipContent={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
            
                <Typography variant="h6">Branch</Typography>

              
             
             <CustomAutocompleteComp
                size="small"
                value={branch}
                multiple
                onChange={handelbranchchange}
                options={allBranchList}
                getOptionLabel={(option) =>
                  option.branch_name
                }
              tooltipContent={(option) =>
                option.branch_name
              }
              />
            </Grid>

            <Grid item xs={6} lg={3} md={6} sm={6}>
         
                <Typography variant="h6">
                  Mode Of Delivery
                </Typography>
               
<CustomAutocompleteComp
                size="small"
                value={modes}
                multiple
                onChange={handleModeChange}
                options={ModesList}
                getOptionLabel={(option) =>
                  option.name
                }
                tooltipContent={(option) =>
                  option.name
                }
              />
             </Grid>

            {/* <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography variant="h6">
                {" "}
                Created From Date{" "}
              </Typography>
              <TextField
                value={createfrom}
                onChange={handlecratedfrom}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
                type="date"
              />
            </Grid>

            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography variant="h6">Created To Date </Typography>
              <TextField
                value={createdto}
                onChange={handlecreatedto}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
                type="date"
              />
            </Grid> */}

            <Grid item xs={12} lg={12} sm={12} md={12} container justifyContent="end"  gap={1}>

            <ApplyFilterButton
               
               onClick={handleApplyFilter}
             />
              <ResetFilterButton
              
                onClick={resetfilter}
              />
            

             
              
            </Grid>
          </Grid>
        </Collapse>
      </MainCard>
    </div>
  );
}
