import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
export default function SearchButton({ onClick, label,disabled , showIcon , size }) {
  return (
    <>
      <Button
         startIcon={showIcon ? <SearchIcon style={{ fontSize: "17px" }} /> : null}
        onClick={onClick}
        variant="contained"
        size={size?size:"medium"}
        style={{borderRadius:'8px' , background:disabled ? "" :"#46b1ff"}}
        disabled={disabled}
      >
        {label ? label :"Search"}
      </Button>
    </>
  );
}
