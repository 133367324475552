import React, { useState } from "react";
import ViewTasks from "./ViewTasks";
import ViewTaskFilter from "./ViewTaskFilter";
import { useNavigate } from "react-router-dom";
import { Divider, Grid, Typography, useTheme } from "@mui/material";
import ViewTasksDetails from "./ViewTasksDetails";
import MainCard from "../../../components/ui-component/card/MainCard";

export default function TaskPage() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");

  const [open, setOpen] = React.useState(false);
  const [filterCriteria, setFilterCriteria] = useState(null);

  const [task, setTask] = useState([]);
  const [createdby, setCreatedby] = useState([]);
  const [stage, setStage] = useState([]);
  const [taskOwner, setTaskowner] = useState([]);
  const [status, setStatus] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [name, setName] = useState("");
  const [createdDatefrom, setCreateddatefrom] = useState(null);
  const [createdDateTo, setCreateddateTo] = useState(null);
  const [duedatefrom, setDuedatefrom] = useState(null);
  const [duedateTo, setDuedateTo] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [selectedChip, setSelectedChip] = useState("notStarted");
  const [isLoading, setIsLoading] = useState(false);
  const [closedby, setClosedby] = useState([]);
  const [closedDatefrom, setCloseddatefrom] = useState(null);
  const [closedDateTo, setCloseddateTo] = useState(null);
  const [originaltaskData, setOriginalTaskData] = useState([]);
  const [followupReason,setFollowupReason] =useState([])
  const [selectedFolloupId,setSelectedFollowupId] =useState([])
  const [prospectiveLevel,setProspectiveLevel] =useState([])
const [RecycleReason, setRecycleReason]=useState([])
const [campaign, setCampaign] =useState([])
const [subservice, setSubservice]=useState([])
const [prospectiveLevelId, setProspectiveLevelId] =useState([])
  return (
    <>
<MainCard>
      <Grid container spacing={1}>
      
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <Typography variant="h3" fontWeight={800}>
            View My Tasks
          </Typography>
          <Divider sx={{ marginTop: "10px" }} />
        </Grid>
        <Grid item xs={12} lg={8.5} sm={8.5} md={8.5}>
        <ViewTasksDetails
                filterCriteria={filterCriteria}
                setFilterCriteria={setFilterCriteria}
                tasks={tasks}
                setTasks={setTasks}
                selectedChip={selectedChip}
                setSelectedChip={setSelectedChip}
                filteredTasks={filteredTasks}
                setFilteredTasks={setFilteredTasks}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setOriginalTaskData={setOriginalTaskData}
                setTask={setTask}
                setCreatedby={setCreatedby}
                setTaskowner={setTaskowner}
                setStatus={setStatus}
                setCreateddatefrom={setCreateddatefrom}
                setCreateddateTo={setCreateddateTo}
                setDuedatefrom={setDuedatefrom}
                setDuedateTo={setDuedateTo}
                setClosedby={setClosedby}
                setCloseddatefrom={setCloseddatefrom}
                setCloseddateTo={setCloseddateTo}
                followupReason={followupReason}
                setFollowupReason={setFollowupReason}
                setSelectedFollowupId={setSelectedFollowupId}
                selectedFolloupId={selectedFolloupId}
                prospectiveLevel={prospectiveLevel}
                setProspectiveLevel={setProspectiveLevel}
                prospectiveLevelId={prospectiveLevelId}
                setProspectiveLevelId={setProspectiveLevelId}
                RecycleReason={RecycleReason}
                setRecycleReason={setRecycleReason}
                campaign={campaign}
                setCampaign={setCampaign}
                subservice={subservice}
                setSubservice={setSubservice}
                stage={stage}
                setStage={setStage}
              />
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginLeft: "15px", color: "black" }}
        />

        <Grid item xs={12} lg={3.1} sm={3.1} md={3.1} sx={{ml:'2px'}}>
        <ViewTaskFilter
                task={task}
                setTask={setTask}
                createdby={createdby}
                setCreatedby={setCreatedby}
                stage={stage}
                setStage={setStage}
                taskOwner={taskOwner}
                setTaskowner={setTaskowner}
                status={status}
                setStatus={setStatus}
                state={state}
                setState={setState}
                city={city}
                setCity={setCity}
                name={name}
                setName={setName}
                createdDatefrom={createdDatefrom}
                setCreateddatefrom={setCreateddatefrom}
                createdDateTo={createdDateTo}
                setCreateddateTo={setCreateddateTo}
                duedatefrom={duedatefrom}
                setDuedatefrom={setDuedatefrom}
                duedateTo={duedateTo}
                setDuedateTo={setDuedateTo}
                closedby={closedby}
                setClosedby={setClosedby}
                closedDatefrom={closedDatefrom}
                setCloseddatefrom={setCloseddatefrom}
                closedDateTo={closedDateTo}
                setCloseddateTo={setCloseddateTo}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                tasks={tasks}
                setTasks={setTasks}
                selectedChip={selectedChip}
                setSelectedChip={setSelectedChip}
                originaltaskData={originaltaskData}
                filteredTasks={filteredTasks}
                setFilteredTasks={setFilteredTasks}
                followupReason={followupReason}
                setFollowupReason={setFollowupReason}
                setSelectedFollowupId={setSelectedFollowupId}
                selectedFolloupId={selectedFolloupId}
                prospectiveLevel={prospectiveLevel}
                setProspectiveLevel={setProspectiveLevel}
                prospectiveLevelId={prospectiveLevelId}
                setProspectiveLevelId={setProspectiveLevelId}
                RecycleReason={RecycleReason}
                setRecycleReason={setRecycleReason}
                campaign={campaign}
                setCampaign={setCampaign}
                subservice={subservice}
                setSubservice={setSubservice}
              />
        </Grid>
      </Grid>
      </MainCard>
    </>
  );
}
