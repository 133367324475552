import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { DataGrid } from "@mui/x-data-grid";
import MainCard from "../../components/Maincard";
import CustomPopup from "../../components/CustomPopup";

import Discountfilter from "./Discountfilter";
import DiscountForm from "./DiscountForm";
import { useNavigate } from "react-router-dom";

import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import RestartDiscount from "./RestartDiscount";
import Branches from "./Branches";
import TestTypeAll from "./TestTypeAll";
import PackagesAll from "./PackagesAll";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useGetTokenOrLogout } from "../../utils/token";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HeadingCard from "../../newTestPrep/newComponent/NewCardsComp/HeadingCard";
import AddButton from "../../newTestPrep/newComponent/NewButtonComp/AddButton";
import FilterButtonComponent from "../../newTestPrep/newComponent/NewButtonComp/FilterButtonComponent";
import CustomNoRowsOverlay from "../../newTestPrep/newComponent/TableCustomeComp/CustomNoRowsOverlay";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
export default function Discount() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  console.log(userId);
  console.log(userId);
  const userRole = localStorage.getItem("userRole");
  console.log("userrole", userRole);
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();

  const [open, setOpen] = useState(false);
  const [discountData, setDiscountdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [openrestart, setopenrestart] = useState(false);
  const [selectedid, setselectedid] = useState(null);
  const [branchPopup, setBranchpopup] = useState(false);
  const [selectedbranhes, setseletedbranches] = useState([]);
  const [testTypepopup, setTesttypepopup] = useState(false);
  const [seletedtesttyps, setSelectedtTesttypes] = useState([]);
  const [packagespopup, setPackagespopup] = useState(false);
  const [selectedpackages, setSelectedpackages] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const [gridKey, setGridKey] = useState(0);

  const visibleCount = 1; // Number of roles to display directly
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const openpackagepopup = (packages) => {
    setPackagespopup(true);
    setSelectedpackages(packages);
  };

  const closepackagepopup = () => {
    setPackagespopup(false);
  };
  const opentesttypepopup = (testtypes) => {
    setTesttypepopup(true);
    setSelectedtTesttypes(testtypes);
  };

  const closetesttypepopup = () => {
    setTesttypepopup(false);
  };
  const openbranchpopup = (branches) => {
    setBranchpopup(true);
    setseletedbranches(branches);
  };

  const closeBranchpopup = () => {
    setBranchpopup(false);
  };
  const handleopenrestart = (id) => {
    setopenrestart(true);
    setselectedid(id);
  };
  console.log("selected id", selectedid);

  const handlecloserestart = () => {
    setopenrestart(false);
  };

  const handleApplyFilter = (criteria) => {
    setFilterCriteria(criteria);
  };
  const handleResetFilter = () => {
    setFilterCriteria(null);
    setDiscountdata(originalData);
  };
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handelopen = () => {
    setOpen(true);
  };

  const handeelclose = () => {
    setOpen(false);
  };

  const handleViewClick = (rowData) => {
    window.open(`/discountdetails/${rowData.id}`, "_blank");
  };

  useEffect(() => {
    setLoading(true);

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    fetch(`${API_ENDPOINT}route2.0/getdiscountbyuser/${userId}`, { headers })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      })
      .then((data) => {
        if (!data) return; // Stop further execution if data is not present

        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        console.log(sortedData);
        let filteredData = [...sortedData];
        console.log(filteredData);
        if (filterCriteria) {
          filteredData = filteredData.filter((discount) => {
            let passesFilter = true;

            if (filterCriteria && filterCriteria.name) {
              const Name = discount?.name ? discount.name.toLowerCase() : ""; // Check if name is null or undefined

              const filterString = filterCriteria.name.toLowerCase();

              passesFilter = passesFilter && Name.includes(filterString);
            }

            if (filterCriteria.newReason.length > 0) {
              passesFilter =
                passesFilter &&
                filterCriteria.newReason.includes(
                  discount?.reason_for_creating_discount
                );
            }

            if (filterCriteria.newBranch.length > 0) {
              passesFilter =
                passesFilter &&
                discount?.branch.some((branchId) =>
                  filterCriteria.newBranch.includes(branchId)
                );
            }

            if (filterCriteria.newTestType.length > 0) {
              passesFilter =
                passesFilter &&
                discount?.test_type.some((testtype) =>
                  filterCriteria.newTestType.includes(testtype)
                );
            }

            if (filterCriteria.newPackage.length > 0) {
              passesFilter =
                passesFilter &&
                discount?.package.some((packages) =>
                  filterCriteria.newPackage.includes(packages)
                );
            }

            if (
              filterCriteria.discountstartdate &&
              filterCriteria.discountenddate
            ) {
              const startDate = filterCriteria.discountstartdate;
              const endDate = filterCriteria.discountenddate;
              const discountstartdate = discount?.start_date;
              const discountenddate = discount?.end_date;
              passesFilter =
                passesFilter &&
                discountstartdate >= startDate &&
                discountstartdate <= startDate &&
                discountenddate >= endDate &&
                discountenddate <= endDate;
            }

            if (filterCriteria.startfromdate && filterCriteria.startenddate) {
              const startDate = filterCriteria.startfromdate;
              const endDate = filterCriteria.startenddate;
              const createddate = discount?.start_date;

              console.log("formated date is", createddate);
              passesFilter =
                passesFilter &&
                createddate >= startDate &&
                createddate <= endDate;
            }

            if (filterCriteria.endfromdate && filterCriteria.endtodate) {
              const startDate = filterCriteria.endfromdate;
              const endDate = filterCriteria.endtodate;
              const createddate = discount?.end_date;

              console.log("formated date is", createddate);
              passesFilter =
                passesFilter &&
                createddate >= startDate &&
                createddate <= endDate;
            }

            if (filterCriteria.newStatus.length > 0) {
              passesFilter =
                passesFilter &&
                filterCriteria.newStatus.includes(discount?.status);
            }

            if (filterCriteria.createfrom && filterCriteria.createdto) {
              const startDate = filterCriteria.createfrom;
              const endDate = filterCriteria.createdto;
              const createddate = new Date(discount?.createdAt);
              const formattedDate = createddate.toISOString().slice(0, 10);
              console.log("formated date is", createddate);
              passesFilter =
                passesFilter &&
                formattedDate >= startDate &&
                formattedDate <= endDate;
            }

            if (filterCriteria.newCreatedby.length > 0) {
              passesFilter =
                passesFilter &&
                filterCriteria.newCreatedby.includes(discount?.created_by?.id);
            }
            return passesFilter;
          });
        }
        setDiscountdata(filteredData);
        setOriginalData(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/page500");
      });
  }, [userId, filterCriteria]);

  console.log("filtercriteria", filterCriteria);
  const handleCancel = (discountId) => {
    // Assuming discountId is passed as an argument to the function

    fetch(`${API_ENDPOINT}discoutform/${discountId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status: "cancelled", user_id: parseInt(userId) }),
    })
      .then((response) => {
        if (response.ok) {
          // Handle success
          showSnackbar("Discount cancelled successfully", "success");
          // Here you might want to update the UI accordingly, for example, removing the cancelled discount from the state
          const updatedDiscountData = discountData.map((discount) => {
            if (discount.id === discountId) {
              return { ...discount, status: "cancelled" };
            }
            return discount;
          });
          setDiscountdata(updatedDiscountData);
        } else {
          // Handle error responses
          showSnackbar("Failed to cancel discount", "error");
        }
      })
      .catch((error) => {
        console.error("Error cancelling discount:", error);
        showSnackbar("Failed to cancel discount", "error");
      });
  };

  const handleaccepted = (discountId) => {
    // Assuming discountId is passed as an argument to the function

    fetch(`${API_ENDPOINT}discoutform/${discountId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status: "accepted", user_id: parseInt(userId) }),
    })
      .then((response) => {
        if (response.ok) {
          // Handle success
          showSnackbar("Discount accepted successfully", "success");
          const updatedDiscountData = discountData.map((discount) => {
            if (discount.id === discountId) {
              return { ...discount, status: "accepted" };
            }
            return discount;
          });
          setDiscountdata(updatedDiscountData);
          // Here you might want to update the UI accordingly, for example, removing the cancelled discount from the state
        } else {
          // Handle error responses
          showSnackbar("Failed to accept discount", "error");
        }
      })
      .catch((error) => {
        console.error("Error accepting   discount:", error);
        showSnackbar("Failed to accept discount", "error");
      });
  };

  const handlDeny = (discountId) => {
    // Assuming discountId is passed as an argument to the function

    fetch(`${API_ENDPOINT}discoutform/${discountId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status: "denied", user_id: parseInt(userId) }),
    })
      .then((response) => {
        if (response.ok) {
          // Handle success
          showSnackbar("Discount Denied successfully", "success");
          const updatedDiscountData = discountData.map((discount) => {
            if (discount.id === discountId) {
              return { ...discount, status: "denied" };
            }
            return discount;
          });
          setDiscountdata(updatedDiscountData);
          // Here you might want to update the UI accordingly, for example, removing the cancelled discount from the state
        } else {
          // Handle error responses
          showSnackbar("Unathorised User", "error");
        }
      })
      .catch((error) => {
        console.error("Error denied  discount:", error);
        showSnackbar("Failed to denied discount", "error");
      });
  };

  const handlDeactive = (discountId) => {
    // Assuming discountId is passed as an argument to the function

    fetch(`${API_ENDPOINT}discoutform/${discountId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status: "cancelled", user_id: parseInt(userId) }),
    })
      .then((response) => {
        if (response.ok) {
          // Handle success
          showSnackbar("Discount deactive successfully", "success");
          const updatedDiscountData = discountData.map((discount) => {
            if (discount.id === discountId) {
              return { ...discount, status: "cancelled" };
            }
            return discount;
          });
          setDiscountdata(updatedDiscountData);
          // Here you might want to update the UI accordingly, for example, removing the cancelled discount from the state
        } else {
          // Handle error responses
          showSnackbar("Failed to deactive discount", "error");
        }
      })
      .catch((error) => {
        console.error("Error deactive  discount:", error);
        showSnackbar("Failed to deactive discount", "error");
      });
  };
  const columns = [
    {
      headerName: "Action",
      width: 300,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <>
          <ButtonBase
            style={{ marginLeft: "7px" }}
            onClick={() => handleViewClick(params.row)}
          >
            <Tooltip title="View">
              <VisibilityOutlinedIcon
                fontSize="small"
                style={{ color: "#2196F3", fontSize: "0.9rem" }}
              />
            </Tooltip>
          </ButtonBase>

          {(userRole === "admin" || userRole === "center_admin") && (
            <Tooltip title="Deactivate Discount">
              <Switch
                checked={params.row?.status !== "cancelled"}
                onChange={(event) => handlDeactive(params.row.id)}
                disabled={params.row?.status === "cancelled"}
                size="small"
                color="secondary"
                sx={{
                  "& .MuiSwitch-switchBase": {
                    padding: 1,
                    height: 1,
                  },
                  "& .MuiSwitch-thumb": {
                    width: 12, // Thumb size
                    height: 12,
                    background:
                      params.row?.status !== "cancelled" ? "#00b13f" : "", // Custom thumb color
                  },
                }}
              />
            </Tooltip>
          )}
          {(userRole === "admin" || userRole === "center_admin") &&
            params.row?.status === "pending" && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "7px" }}
                  onClick={() => handleaccepted(params.row.id)}
                >
                  Approve Request{" "}
                </Button>

                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  style={{ marginLeft: "7px" }}
                  onClick={() => handlDeny(params.row.id)}
                >
                  Deny Request
                </Button>
              </>
            )}

          {(userRole === "trainer" || userRole === "advisor") &&
            userId === params.row.created_by?.id &&
            params.row?.status === "pending" && (
              <Button
                variant="contained"
                size="small"
                color="error"
                style={{ marginLeft: "7px" }}
                onClick={() => handleCancel(params.row.id)}
              >
                Cancel Request{" "}
              </Button>
            )}
          {(userRole === "admin" || userRole === "center_admin") &&
            params.row?.status === "completed" && (
              <Button
                variant="contained"
                color="error"
                size="small"
                style={{ marginLeft: "7px" }}
                onClick={() => handleopenrestart(params.row.id)}
              >
                Restart Discount{" "}
              </Button>
            )}
        </>
      ),
    },
    {
      field: "name",
      headerName: "Name of Discount",
      width: 250,
      headerAlign: "center",

      align: "center",
      renderCell: (params) => <>{params.row?.name}</>,
    },

    {
      field: "code",
      headerName: " Discount Code",
      width: 100,
      headerAlign: "center",

      align: "center",
      renderCell: (params) => <>{params.row?.discount_code}</>,
    },

    {
      field: "reason",
      headerName: "Reason for Discount",
      width: 200,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => <>{params.row?.reason_for_creating_discount}</>,
    },
    {
      field: "branches",
      headerName: "Branches",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const branches = params?.row?.branch || [];
        const visibleCount = 1; // Set the number of visible branches
        const visibleBranches = branches.slice(0, visibleCount);
        const extraBranches = branches.slice(visibleCount);

        return (
          <>
            {visibleBranches.map((branch, index) => (
              <div key={index}>{branch}</div>
            ))}
            {extraBranches.length > 0 && (
              <Typography
                variant="h6"
                style={{ cursor: "pointer", marginLeft: "5px" ,color:"#46b1ff" }}
                onClick={() => openbranchpopup(extraBranches)}
              >
                {`+${extraBranches.length}`}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "testType",
      headerName: "Test Type",
      width: 150,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        // <>
        //   <Box style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
        //     {/* {params.row?.test_type.slice(0,5).join(" , ")} */}
        //     {params.row.test_type.length > 0
        //       ? params.row?.test_type.slice(0, 6).join(" , ")
        //       : ""}
        //   </Box>
        //   {params.row?.test_type.length > 5 && (
        //     <Button
        //       variant="contained"
        //       size="small"
        //       onClick={() => opentesttypepopup(params.row?.test_type)}
        //       style={{ marginLeft: "12px" }}
        //     >
        //       View
        //     </Button>
        //   )}
        // </>

        const testTypes = params?.row?.test_type || [];
        const visibleCount = 1; // Set the number of visible branches
        const visibletestType = testTypes.slice(0, visibleCount);
        const extraTestType = testTypes.slice(visibleCount);

        return (
          <>
            {visibletestType.map((testType, index) => (
              <div key={index}>{testType}</div>
            ))}
            {extraTestType.length  >0 && (
              <Typography
                variant="h6"
                style={{ cursor: "pointer", marginLeft: "5px" ,color:"#46b1ff"}}
                onClick={() => opentesttypepopup(extraTestType)}
              >
                {`+${extraTestType.length}`}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "packages",
      headerName: "Packages",
      width: 150,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
       
        const packeges = params?.row?.package || [];
        const visibleCount = 1; // Set the number of visible branches
        const visiblePackage = packeges.slice(0, visibleCount);
        const extraPackages = packeges.slice(visibleCount);

        return (
          <>
            {visiblePackage.map((packages, index) => (
              <div key={index}>{packages}</div>
            ))}
            {extraPackages.length  >0 && (
              <Typography
                variant="h6"
                style={{ cursor: "pointer", marginLeft: "5px",color:"#46b1ff" }}
                onClick={() => openpackagepopup(extraPackages)}
              >
                {`+${extraPackages.length}`}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 120,
      headerAlign: "center",

      align: "center",
      renderCell: (params) => <>{params.row?.start_date}</>,
    },

    {
      field: "endDate",
      headerName: "End Date",
      width: 120,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => <>{params.row?.end_date}</>,
    },

    {
      field: "value",
      headerName: "Value",
      width: 100,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        if (params.row?.type_of_discount === "cash") {
          return <>{params.row?.discount_amount} Rs</>;
        } else if (params.row?.type_of_discount === "percentage") {
          return <>{params.row?.discount_percentage}%</>;
        }
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 120,

      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          {params.row?.status.charAt(0).toUpperCase() +
            params.row?.status.slice(1)}
        </>
      ),
    },
  ];

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <HeadingCard label="Discounts" />
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={9}
          lg={10}
          container
          justifyContent="end"
          gap={1}
        >
          <AddButton onClick={handelopen} label="Create Discount" />
          <FilterButtonComponent onClick={handleDrawerOpen} />
        </Grid>

        <Grid item xs={12} lg={12}>
          <Discountfilter
            onApplyFilter={handleApplyFilter}
            onResetFilter={handleResetFilter}
            setGridKey={setGridKey}
            handleDrawerClose={handleDrawerClose}
            isDrawerOpen={isDrawerOpen}
          />
        </Grid>
      </Grid>

      <MainCard>
        <Box>
          <DataGrid
            key={gridKey}
            rows={discountData}
            columns={columns}
            autoHeight
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            sx={{ marginTop: "20px" }}
            pageSizeOptions={[25, 50, 100]}
          />
        </Box>
      </MainCard>

      <CustomPopup
        open={open}
        onClose={handeelclose}
        component={<DiscountForm onClose={handeelclose} />}
        title="Create A Discount"
        showDivider="true"
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <CustomPopup
        open={openrestart}
        onClose={handlecloserestart}
        component={
          <RestartDiscount
            onClose={handlecloserestart}
            selectedid={selectedid}
            discountData={discountData}
            setDiscountdata={setDiscountdata}
          />
        }
        title="Restart Discount"
        showDivider="true"
      />

      <CustomPopup
        open={branchPopup}
        onClose={closeBranchpopup}
        component={
          <Branches
            onClose={closeBranchpopup}
            selectedbranhes={selectedbranhes}
          />
        }
        title="Branches"
        showDivider="true"
        maxWidth="xs"
      />
      <CustomPopup
        open={testTypepopup}
        onClose={closetesttypepopup}
        component={
          <TestTypeAll
            onClose={closetesttypepopup}
            seletedtesttyps={seletedtesttyps}
          />
        }
        title="Test Type"
        showDivider="true"
        maxWidth="xs"
      />

      <CustomPopup
        open={packagespopup}
        onClose={closepackagepopup}
        component={
          <PackagesAll
            onClose={closepackagepopup}
            selectedpackages={selectedpackages}
          />
        }
        title="Packages"
        showDivider="true"
        maxWidth="xs"
      />
    </>
  );
}
