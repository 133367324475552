import React, { useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { fDate } from "../../utils/formatTime";
import MainCard from "../../components/Maincard";
import CustomNoRowsOverlay from "../../newTestPrep/newComponent/TableCustomeComp/CustomNoRowsOverlay";
import { DataGrid } from "@mui/x-data-grid";

export default function ExpenseTable({ data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name Of Expense",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.name}</div>,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.category_id?.name}</div>,
    },
    {
      field: "branch",
      headerName: "Branch Name",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.branch_id?.branch_name}</div>,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params?.row?.start_date)}</div>,
    },
    {
      field: "Amount",
      headerName: "amount",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.amount}</div>,
    },
  ];

  return (
    <>
      <MainCard>
        <DataGrid
          rows={data}
          columns={columns}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
        />
      </MainCard>
    </>
  );
}
