import React from "react";
import MainCard from "../../../../components/ui-component/card/MainCard";
import { Box, Button, CircularProgress, Stack, Typography, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Person as PersonIcon } from '@mui/icons-material';
export default function SearchData({ searchResults,onClose,loading }) {

  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");

  const navigate = useNavigate()

  const handleViewMore = (row) => {
   
    window.open(`/enquiry-summary/${row.enq_personal_detail_id}`, '_blank');

   /* navigate(`/enquirydetails/${row.id}`, {
      state: { enquiryDetails: row }
    });
    onClose()*/
  };

  const columns = [
    {
      field: "firstName",
      headerName: "First name",
      width: 200,
      renderCell: (params) => {
        // console.log('Cell Renderer - params.row:', params.row);
        return (
          <div>
       {(params.row.is_view===true) ? (
                      <Button size='small' onClick={() => handleViewMore(params.row)}>
                        <PersonIcon /> {params.row.first_name} {params.row.last_name}
                      </Button>
):(
                      <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <PersonIcon />
                        <Typography>
                          {params.row.first_name} {params.row.last_name}
                        </Typography>
                      </Stack>
)}
          </div>
        );
      },
      //editable: true,
    },
    {
      field: "services",
      headerName: "Services",
      width: 200,
      renderCell: (params) => (
        <Typography>{params.row.service_id?.service_name}</Typography>
      ),
    },

    {
      field: "subServices",
      headerName: "Sub Services",
      width: 200,
      renderCell: (params) => (
        <Typography>{params.row.sub_service_id?.name}</Typography>
      ),

      //editable: true,
    },
    {
      field: "branch",
      headerName: "Branch",
      renderCell: (params) => (
        <Typography>{params.row.branch_id?.branch_name}</Typography>
      ),

      //editable: true,
    },
    {
      field: "stage",
      headerName: "Stage",

      renderCell: (params) => (
        <Typography>{params.row.stage_id?.stage_name}</Typography>
      ),

      //editable: true,
    },
    {
      field: "prospectiveLevel",
      headerName: "Prospective Level",
      width: 200,
      renderCell: (params) => (
        <Typography>
          {params.row.prospective_level_id?.prospective_level}
        </Typography>
      ),

      //editable: true,
    },
    {
      field: "assign",
      headerName: "Assigned To",
      renderCell: (params) => (
        <Typography>{params.row.assign_to?.first_name}</Typography>
      ),
    },
      {
        field: "co-assign",
        headerName: "Co-Assignee",
        width:200,
        renderCell: (params) => (
          <Typography>{params.row.co_assignee?.first_name} {params.row.co_assignee?.last_name}</Typography>
        ),
      


    },
    {
      field: "assignRequest",
      headerName: "Assign Request",
width:150,
      editable: true,
    },
    {
      field: "remark",
      headerName: "Remarks",

     // editable: true,
    },
    {
      field: "recentotp",
      headerName: "Recent Otp",

     // editable: true,
    },
  ];
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Enquiries Found by the provided Information</Box>
      </StyledGridOverlay>
    );
  }

  return (
    <>
  {
  (loading && searchResults.length === 0) ? (
    <Box sx={{ width: '100%', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </Box>
  ) : ( (!loading && searchResults.length === 0) ? (
    <Box sx={{ width: '100%', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
      <Typography sx={{fontWeight:600, fontSize:'18px'}}>No Enquiries Found by the provided Information.</Typography>
    </Box>
  ) : (
    <MainCard>
  <Box sx={{ width: "100%" }}>
          <DataGrid
            rows={searchResults}
            columns={columns}
            autoHeight
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            disableRowSelectionOnClick
          />
        </Box>
    </MainCard>
  ))
}

    </>
  );
}
