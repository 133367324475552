import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
// import { EditorState, convertToRaw } from 'draft-js'; // Import EditorState
// import { Editor } from "react-draft-wysiwyg";
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import draftToHtml from "draftjs-to-html";
import JoditEditor from "jodit-react";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
export default function LessonplanForm({
  selectedClass,
  selectedButton,
  onClose,
}) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");

  console.log("selctedclass is", selectedClass);
  const id = selectedClass.id;
  console.log("id is", id);
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDateError, setFromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");

  const handelfromdatechange = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setFromDate(formattedDate); // Assuming setFromDate is a function to update fromDate state
    console.log(formattedDate);
    setFromDateError("");
  };

  const handeltodatechange = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setToDate(formattedDate);
    setToDateError("");
  };
  const [editorStates, setEditorStates] = useState(daysOfWeek.map(() => ""));
  const editor = useRef(null);

  const parser = new DOMParser();
  const htmlString = "<strong>Beware of the leopard</strong>";
  const doc3 = parser.parseFromString(htmlString, "text/html");
  const defaultEmail = doc3.body.firstChild.textContent;

  const config = {
    readonly: false,
    toolbarAdaptive: false,
    buttons: [
      "undo",
      "redo",
      "font",
      "fontsize",
      "bold",
      "italic",
      "underline",
      "eraser",
      "brush",
      "link",
      "ul",
      "ol",
      "indent",
      "outdent",
      "left",
      "fullsize",
      "source",
    ],
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };
  const handleEditorChange = (index, newContent) => {
    setEditorStates((prevStates) => {
      const newEditorStates = [...prevStates];
      newEditorStates[index] = newContent;
      return newEditorStates;
    });
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const editorComponents = useMemo(
    () =>
      daysOfWeek.map((day, index) => (
        <Box>
          <Typography variant="h5">{day}</Typography>
          <JoditEditor
            key={index}
            ref={editor}
            value={editorStates[index]}
            config={config}
            tabIndex={index + 2}
            onBlur={(newContent) => handleEditorChange(index, newContent)}
          />
        </Box>
      )),
    [editorStates]
  );

  const isFromdate = (date) => {
    const day = date.day();

    return (
      day === 0 || day === 2 || day === 3 || day === 4 || day === 5 || day === 6
    );
  };

  const isTodate = (date) => {
    const day = date.day();

    return (
      day === 0 || day === 2 || day === 3 || day === 4 || day === 5 || day === 1
    );
  };

  const handleSubmit = () => {
    // Prepare data for submission

    const data = {
      week_type: selectedButton,
      class_id: id,
      user_id: userId,
    };

    daysOfWeek.forEach((day, index) => {
      data[`day_${index + 1}`] = editorStates[index];
    });

    // Make POST request to the API
    fetch(`${API_ENDPOINT}route/createlessonPlan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Lesson Plan Uploaded Successfully", "success");
        } else {
          showSnackbar("Failed To Upload Lesson Plan", "error");
          throw new Error("Failed To Upload Lesson Plan");
        }
        setFromDate("");
        setToDate("");
        setEditorStates(daysOfWeek.map(() => ""));
        setTimeout(() => {
          onClose();
        }, 1500);
        // Handle successful response if needed
      })
      // Handle successful response if needed

      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
        showSnackbar("Error in uploading lesson plan", "error");
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",

          minHeight: "100vh", // Ensure sufficient height
          width: "100%",
          margin: "auto",
          left: "50%",
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <Typography variant="h3" textAlign="center">
              Upload Lesson Plan
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker", "DateTimePicker", "DateRangePicker"]}
              >
                <DemoItem label="From date">
                  <DatePicker
                    // defaultValue={nextSunday}
                    shouldDisableDate={isFromdate}
                    views={["year", "month", "day"]}
                    value={fromDate}
                    onChange={handelfromdatechange}
                  />
                  {fromDateError && (
                    <Typography color="error" variant="caption">
                      {fromDateError}
                    </Typography>
                  )}
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker", "DateTimePicker", "DateRangePicker"]}
              >
                <DemoItem label="To Date">
                  <DatePicker
                    // defaultValue={nextSunday}
                    shouldDisableDate={isTodate}
                    views={["year", "month", "day"]}
                    value={toDate}
                    onChange={handeltodatechange}
                  />
                  {toDateError && (
                    <Typography color="error" variant="caption">
                      {toDateError}
                    </Typography>
                  )}
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Grid> */}
          {daysOfWeek.map((day, index) => (
            <Grid key={index} item xs={12} lg={12} sm={12} md={12}>
              {/* <Typography>{day}</Typography> */}

              {editorComponents[index]}
            </Grid>
          ))}
          <Grid item xs={6} lg={12} md={3} sm={3} textAlign="end">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
