import React from "react";
import { Typography } from "@mui/material";
export default function TestTypeAll({ seletedtesttyps }) {
  return (
    <Typography variant="h5">
      <ul>
        {seletedtesttyps.map((pkg, index) => (
          <li key={index} style={{ padding: "5px" }}>
            {pkg}
          </li>
        ))}
      </ul>
    </Typography>
  );
}
