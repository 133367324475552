import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import InfoCard from "./InfoCard";
import EventFilter from "./EventFilter";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import DetailedReport from "./DetailedReport";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EventIcon from "@mui/icons-material/Event";
import UserWiseReport from "./UserWiseReport";
import PeopleIcon from "@mui/icons-material/People";
import SummarizeIcon from "@mui/icons-material/Summarize";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EventWiseReport from "./EventWiseReport";
import NewCardcomp from "../../../components/card/NewCardcomp";
import FilterIcon from "../../../components/IconComp/FilterIcon";
import FilterButtonComponent from "../../newComponent/NewButtonComp/FilterButtonComponent";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";
import Eventattendees from "./Eventattendees";

export default function RegistrationStatus() {
  const [selectedChip, setSelectedChip] = useState("detailedreport");

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      selectedChip === "userwisereport" ||
      selectedChip === "eventwisereport" ||
      selectedChip === "eventattendees"
    ) {
      setOpen(true);
    }
  }, [selectedChip]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleChipClick = (criteria) => {
    setSelectedChip(criteria);
    setOpen(false);
  };

  return (
    <>
      <Grid Grid container spacing={2}>
        <Grid item xs={12} lg={2} sm={4} md={3}>
                  <HeadingCard>
                    <Typography variant="h3" textAlign="center">
                      {" "}
                    Event Report
                    </Typography>
                  </HeadingCard></Grid>
        {/* <Grid item lg={2.5}>
          {" "}
          <Paper
            sx={{
              height: "40px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "24px",
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
              Event Report
            </Typography>
          </Paper>
        </Grid> */}
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <Grid container>
            <Grid item xs={12} md={8} sm={12} lg={8}>
              <Grid container gap={2}>
                <Button
                  size="small"
                  style={{
                    fontWeight: selectedChip === "detailedreport" ? 600 : 500,
                    background:
                      selectedChip === "detailedreport" ? "#07b344" : "",
                    borderRadius: "10px",
                    color:
                      selectedChip !== "detailedreport" ? "#364152" : "white",
                    border:
                      selectedChip !== "detailedreport"
                        ? "0.5px solid #b2b2b4"
                        : "",
                  }}
                  onClick={() => handleChipClick("detailedreport")}
                >
                  Detailed Report
                </Button>

                <Button
                  size="small"
                  style={{
                    fontWeight: selectedChip === "userwisereport" ? 600 : 500,
                    background:
                      selectedChip === "userwisereport" ? "#07b344" : "",
                    borderRadius: "10px",
                    color:
                      selectedChip !== "userwisereport" ? "#364152" : "white",
                    border:
                      selectedChip !== "userwisereport"
                        ? "0.5px solid #b2b2b4"
                        : "",
                  }}
                  onClick={() => handleChipClick("userwisereport")}
                >
                  User Wise Specific Report
                </Button>

                <Button
                  size="small"
                  style={{
                    fontWeight: selectedChip === "eventwisereport" ? 600 : 500,
                    background:
                      selectedChip === "eventwisereport" ? "#07b344" : "",
                    borderRadius: "10px",
                    color:
                      selectedChip !== "eventwisereport" ? "#364152" : "white",
                    border:
                      selectedChip !== "eventwisereport"
                        ? "0.5px solid #b2b2b4"
                        : "",
                  }}
                  onClick={() => handleChipClick("eventwisereport")}
                >
                  Event Wise Specific Report
                </Button>

                <Button
                  size="small"
                  style={{
                    fontWeight: selectedChip === "eventattendees" ? 600 : 500,
                    background:
                      selectedChip === "eventattendees" ? "#07b344" : "",
                    borderRadius: "10px",
                    color:
                      selectedChip !== "eventattendees" ? "#364152" : "white",
                    border:
                      selectedChip !== "eventattendees"
                        ? "0.5px solid #b2b2b4"
                        : "",
                  }}
                  onClick={() => handleChipClick("eventattendees")}
                >
                  Event Attendees Report
                </Button>

              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              sm={12}
              lg={4}
              display="flex"
              justifyContent="flex-end"
            >
              <FilterButtonComponent
                onClick={handleToggle}
                text="Filter"
                open={open}
                showendIcon={true}
              />
            </Grid>
          </Grid>
        </Grid>

        {selectedChip === "detailedreport" && <DetailedReport open={open} />}
        {selectedChip === "userwisereport" && <UserWiseReport open={open} />}
        {selectedChip === "eventwisereport" && <EventWiseReport open={open} />}
        {selectedChip === "eventattendees" && <Eventattendees open={open} />}
      </Grid>
    </>
  );
}
