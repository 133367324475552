// Author : Ayesha Shaikh
// Date : 17 Aug 2024

import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import { DataGrid } from "@mui/x-data-grid";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { fDate } from "../../../utils/formatTime";
import HeadingCard from "../../../newTestPrep/newComponent/NewCardsComp/HeadingCard";
import StyledTableContainer from "../../../newTestPrep/newComponent/TableContainer";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EnquirySummary() {
  const navigate = useNavigate();
  const location = useLocation();
  const { personalid } = useParams();
  const userId = localStorage.getItem("userId");

  const [summaryData, setSummaryData] = useState([]);

  const [notesList, setNotesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [criteria, selectedCriteria] = useState("all_note");

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Fetch Summary Data
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const requestData = {
      user_id: parseInt(userId),
      enq_personal_detail_id: parseInt(personalid),
    };
    try {
      setLoading(true);
      const response = await fetch(
        `${API_ENDPOINT}route2.0/getenqbypersonaldetail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        const noteslist = result?.note_list;
        setNotesList(noteslist);
        setSummaryData(result);
        setLoading(false);
      } else if (response.status === 401) {
        showSnackbar("Unknown User", "error");
        setLoading(false);
      } else if (response.status === 400) {
        showSnackbar("Personal Id Is Missing", "error");
        setLoading(false);
      } else {
        setLoading(false);
        showSnackbar("Error fetching data:", "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      showSnackbar("Error fetching data:", "error");
      setLoading(false);
    }
  };

  const rowsWithSerialNumbers = notesList.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));


  // View Enquiry Details page
  const handleViewMore = (row) => {
    // Handle the "View More" action here
  
    const url = `/enquirydetails/${row?.id}`;
    const newState = { enquirydetails: row };
    // Open link in new tab
    const newTab = window.open(url, "_blank");

    // If new tab opened successfully, navigate with state data
    if (newTab) {
      newTab.onload = () => {
        newTab.history.replaceState(newState, "", url);
      };
    } else {
      // If new tab was blocked by browser, navigate with state data in current tab
      navigate(url, { state: newState });
    }
  };

  // Filter note according to selectedcriteria
  const handleSelectedCriteria = (criteria) => {
    selectedCriteria(criteria);
    const noteList = summaryData?.note_list;
    if (criteria === "all_note") {
      setNotesList(noteList);
    } else {
      const noteList = summaryData.note_list?.filter(
        (note) => parseInt(criteria) === note?.enquiry_id?.sub_service_id?.id
      );
      setNotesList(noteList);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2} sm={4} md={4}>
          <HeadingCard label="Enquiry Summary"></HeadingCard>
        </Grid>

        <Backdrop open={loading} style={{ zIndex: 9999 }}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fetching Summary Details
          </Typography>
          <CircularProgress color="primary" />
        </Backdrop>
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <Stack
            sx={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}
          >
            <Box
              style={{
                background: "#F3DEFA",
                padding: "5px",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <PersonOutlineOutlinedIcon
                fontSize="small"
                sx={{ marginRight: "5px", color: "#A03BBF" }}
              />
              <Typography variant="h5" color="#364152">
                {summaryData?.enq_personal_detail_id?.first_name}{" "}
                {summaryData?.enq_personal_detail_id?.last_name}
              </Typography>
            </Box>

            <Box
              style={{
                background: "#EdEBFB",
                padding: "5px",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginLeft: "5px",
              }}
            >
              <EmailOutlinedIcon
                fontSize="small"
                sx={{ marginRight: "5px", color: "#635ba3" }}
              />
              <Typography variant="h5">
                {summaryData?.enq_personal_detail_id?.email}
              </Typography>
            </Box>

            <Box
              style={{
                background: "#F9EBD8",
                padding: "5px",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginLeft: "5px",
              }}
            >
              <CallOutlinedIcon
                sx={{ marginRight: "5px", color: "#F7B88F" }}
                fontSize="small"
              />
              <Typography variant="h5">
                {summaryData?.enq_personal_detail_id?.mobile}
              </Typography>
            </Box>
          </Stack>
          <MainCard>
            <Grid container spacing={2}>
              {summaryData?.enquiry_list?.map((enqiry) => (
                <Grid item xs={12} md={6} sm={6} lg={3}>
                  <Paper
                    sx={{
                      backgroundColor: "#ffedfb",
                      padding: "10px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleViewMore(enqiry)}
                  >
                    <Typography variant="h4" sx={{ color: "#DA4C4E" }}>
                      {enqiry?.sub_service_id?.name}
                    </Typography>
                    <Stack spacing={0.5} mt={1}>
                    <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography variant="h6" fontWeight={600}>Service :</Typography>
                        <Typography variant="h6" fontWeight={600} ml={1}>
                          {enqiry?.service_id?.service_name}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography variant="h6">Stage :</Typography>
                        <Typography variant="h6" fontWeight={400} ml={1}>
                          {enqiry?.stage_id?.stage_name}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography variant="h6">
                          Prospective Level :
                        </Typography>
                        <Typography variant="h6" fontWeight={400} ml={1}>
                          {enqiry?.prospective_level_id?.prospective_level}{" "}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography variant="h6">Assign To :</Typography>
                        <Typography variant="h6" fontWeight={400} ml={1}>
                          {enqiry?.assign_to?.first_name}{" "}
                          {enqiry?.assign_to?.last_name}{" "}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography variant="h6">CoAssign :</Typography>
                        <Typography variant="h6" fontWeight={400} ml={1}>
                          {enqiry?.co_assignee?.first_name}{" "}
                          {enqiry?.co_assignee?.last_name}{" "}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography variant="h6">Enquiried Date :</Typography>
                        <Typography variant="h6" fontWeight={400} ml={1}>
                          {enqiry?.enquiry_date}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography variant="h6">Last Follow Up :</Typography>
                        <Typography variant="h6" fontWeight={400} ml={1}>
                          {enqiry?.last_follow_up_date}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography variant="h6">Next Due Date :</Typography>
                        <Typography variant="h6" fontWeight={400} ml={1}>
                          {enqiry?.next_follow_up_date}
                        </Typography>
                      </Stack>{" "}
                    </Stack>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </MainCard>
        </Grid>

        <Grid item xs={12} md={12} lg={12} sm={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">Notes</Typography>
              </Grid>

              <Grid item xs={12}>
                <Chip
                  size="small"
                  label="All Notes"
                  onClick={() => handleSelectedCriteria("all_note")}
                  variant={criteria === "all_note" ? "contained" : "outlined"}
                  style={{
                    background: criteria === "all_note" ? "#00b13f" : "",
                  }}
                  sx={{ margin: "3px" }}
                />

                {summaryData?.enquiry_list?.map((subservice) => (
                  <Chip
                    size="small"
                    sx={{ margin: "3px" }}
                    value={subservice?.sub_service_id?.id}
                    key={subservice?.sub_service_id?.name}
                    label={subservice?.sub_service_id?.name}
                    onClick={() =>
                      handleSelectedCriteria(subservice?.sub_service_id?.id)
                    }
                    style={{
                      background:
                        criteria === subservice?.sub_service_id?.id
                          ? "#00b13f"
                          : "",
                    }}
                    variant={
                      criteria === subservice?.sub_service_id?.id
                        ? "contained"
                        : "outlined"
                    }
                  />
                ))}
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <StyledTableContainer component={Paper} borderLeft={true}>
                    <Table
                      sx={{ minWidth: 650, tableLayout: "fixed" }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: "10%" }}>S.No</TableCell>
                          <TableCell sx={{ width: "20%" }}>
                            Sub Service
                          </TableCell>
                          <TableCell sx={{ width: "30%" }}>Notes</TableCell>
                          <TableCell sx={{ width: "20%" }}>
                            Created By
                          </TableCell>
                          <TableCell sx={{ width: "20%" }}>
                            Created Date
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowsWithSerialNumbers.length === 0 ? (
                          <Box mt={1}>
                            <Typography variant="h6">
                              No Data Available
                            </Typography>
                          </Box>
                        ) : (
                          rowsWithSerialNumbers.map((row) => (
                            <TableRow key={row.name}>
                              <TableCell>{row.serialNumber}</TableCell>
                              <TableCell>
                                {row?.enquiry_id?.sub_service_id?.name}
                              </TableCell>
                              <TableCell>{row.note}</TableCell>
                              <TableCell>
                                {" "}
                                {row?.note_by?.first_name}{" "}
                                {row?.note_by?.last_name}
                              </TableCell>
                              <TableCell>{fDate(row.createdAt)}</TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                </Box>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
