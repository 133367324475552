import { Button } from '@mui/material'
import React from 'react'

export default function ResetFilterButton({onClick,label}) {
  return (
    <Button
    size="small"
    variant="contained"
    style={{ borderRadius: "8px", background:"#ff4b4b" }}
 
    onClick={onClick}
  >
   {label?label :"Reset Filter"}
  </Button>
  )
}
