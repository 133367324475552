import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { fDate } from "../../../../utils/formatTime";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Person as PersonIcon } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import CustomSnackbar from "../../../components/ui-component/snackbar/CustomSnackbar";
import CloseTaskDialog from "../../../../pages/advisorPages/tasks/CloseTaskPopup";
import NoTaskPopup from "./NoTaskPopup";

import CustomPopup from "../../../../components/CustomPopup";
import { CSVExport } from "../../../components/ui-component/table/CSVExport";
import MassTaskTransfer from "../../../components/DataTransferComp.jsx/MassTaskTransfer";
import MassTaskTransferButton from "../../../newComponent/NewButtonComp/MassTaskTransferButton";
import SelectedItemButton from "../../../newComponent/NewButtonComp/SelectedItemButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function ViewTasksDetails({
  tasks,
  setTasks,
  selectedChip,
  setSelectedChip,
  filteredTasks,
  setFilteredTasks,
  isLoading,
  setIsLoading,
  setOriginalTaskData,
  setStatus,
  setTask,
  setCreatedby,

  setStage,
  setState,
  setCity,
  setTaskowner,
  setCreateddatefrom,
  setCreateddateTo,
  setDuedatefrom,
  setDuedateTo,
  setFollowupReason,
  setCloseddatefrom,
  setCloseddateTo,
  setClosedby,
  setSelectedFollowupId,
  prospectiveLevel,
  setProspectiveLevel,
  prospectiveLevelId,
  setProspectiveLevelId,
  RecycleReason,
  setRecycleReason,
  campaign,
  setCampaign,
  subservice,
  setSubservice,
}) {
  const userId = localStorage.getItem("userId");
  const location = useLocation();
  const user_role = localStorage.getItem("userRole");

  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [selectedEnquiryId, setSelectedEnquiryId] = useState(null);
  const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false);

  const [exportData, setExportData] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const isEnquiryTaskRoute = location.pathname === "/enquiry/task";

  const [isDownloadAccess, setIsDownloadAccess] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 100,
  });

  const [visibleRowIds, setVisibleRowIds] = useState([]); // Store current page's row IDs
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [prevPageSize, setPrevPageSize] = useState(pagination.pageSize);
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [openMask, setOpenMask] = useState(false);
  const [openCalls, setOpenCalls] = useState(false);

  // const handleSelectAllChange = (event) => {
  //   const checked = event.target.checked;
  //   setIsSelectAllChecked(checked);

  //   if (checked) {
  //     const allRowIds = filteredTasks.map((row) => row.id);
  //     setSelectedIds(allRowIds);
  //   } else {
  //     setSelectedIds([]);
  //   }
  // };

  // const handleCheckboxChange = (event, id) => {
  //   console.log("id", id);
  //   const checked = event.target.checked;
  //   if (checked) {
  //     setSelectedIds((prev) => [...prev, id]);
  //   } else {
  //     setSelectedIds((prev) => prev.filter((rowId) => rowId !== id));
  //   }
  // };
  const handleOpenCallsToggle = () => {
    setOpenCalls(!openCalls);
  };

  useEffect(() => {
    // Update visible row IDs when pagination changes
    console.log("pagination", pagination, selectedIds);
    setPrevPageSize(pagination.pageSize);
    if (pagination.pageSize !== prevPageSize) {
      setSelectedIds([]);
      return;
    }
    const currentPageRows = filteredTasks.slice(
      pagination.page * pagination.pageSize,
      (pagination.page + 1) * pagination.pageSize
    );
    const visibleIds = currentPageRows.map((row) => row.id);
    setVisibleRowIds(visibleIds);

    // If there are no visible rows, set SelectAllChecked to false
    if (visibleIds.length === 0) {
      setIsSelectAllChecked(false);
      setIsIndeterminate(false);
      return;
    }

    // Check if all visible rows are selected
    const allVisibleSelected = visibleIds.every((id) =>
      selectedIds.includes(id)
    );
    const someVisibleSelected = visibleIds.some((id) =>
      selectedIds.includes(id)
    );

    setIsSelectAllChecked(allVisibleSelected);
    setIsIndeterminate(someVisibleSelected && !allVisibleSelected);
  }, [pagination, filteredTasks, selectedIds]);

  // HandleSelectAllCheckBox
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setIsSelectAllChecked(checked);

    if (checked) {
      // Select all visible row IDs
      setSelectedIds((prevSelectedIds) => [
        ...new Set([...prevSelectedIds, ...visibleRowIds]),
      ]);
    } else {
      // Deselect all visible row IDs
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => !visibleRowIds.includes(id))
      );
    }
  };

  // Individual CheckBox
  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;

    setSelectedIds((prevSelectedIds) => {
      if (checked) {
        const newSelected = [...prevSelectedIds, id];
        // Check if all visible rows are selected after this checkbox is checked
        const allVisibleSelected = visibleRowIds.every((rowId) =>
          newSelected.includes(rowId)
        );
        setIsSelectAllChecked(allVisibleSelected);
        setIsIndeterminate(!allVisibleSelected);

        return newSelected;
      } else {
        // Deselect the row ID
        const newSelected = prevSelectedIds.filter((rowId) => rowId !== id);
        setIsSelectAllChecked(false);
        setIsIndeterminate(
          visibleRowIds.some((rowId) => newSelected.includes(rowId))
        );

        return newSelected;
      }
    });
  };

  const handleClosemask = () => {
    setOpenMask(false);
  };

  const handleOpenmask = () => {
    setOpenMask(true);
  };

  console.log(selectedIds);

  const handleViewMore = (row) => {
    console.log("View More:", row.enquiry_id?.id);
    console.log("View More:", row.demo_id);

    if (user_role === "admin" || user_role === "trainer") {
      console.log("Task Type:", row.type_of_task.name_of_task_type);

      if (
        ["Demo request", "Test prep demo", "Evaluation demo"].includes(
          row.type_of_task.name_of_task_type
        )
      ) {
        console.log("Navigating to Demo Details");
        if (row.demo_id) {
          window.open(`/demodetails/${row.demo_id}`, "_blank");
        } else {
          window.open("/page404", "_blank");
        }
      } else {
        console.log("Navigating to Enquiry Details");
        window.open(`/enquirydetails/${row.enquiry_id?.id}`, "_blank");
      }
    } else {
      console.log("Navigating to Enquiry Details");
      window.open(`/enquirydetails/${row.enquiry_id?.id}`, "_blank");
    }
  };

  const handleTaskClose = (taskId, enquiryId) => {
    // Set the selected task ID and open the CloseDialogForm
    setSelectedTaskId(taskId);
    setSelectedEnquiryId(enquiryId);
    setIsCloseDialogOpen(true);
  };

  const handleClickToCall = (id) => {
    const data = {
      user_id: parseInt(userId),
      enquiry_id: id,
    };

    console.log(data);

    // Access the authorization token from environment variables
    const authToken = process.env.REACT_APP_CALL_TOKEN;

    // Send the POST request to the server
    fetch(`${API_ENDPOINT}clicktocall`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Success");
          showSnackbar("Call Connected", "success");
        } else {
          console.log("Error:", response);
          showSnackbar("Call Not Connected", "error");
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        showSnackbar("Call Not Connected", "error");
      });
  };

  useEffect(() => {
    setIsLoading(true);

    let fetchUrl = "";
    if (selectedChip === "notStarted") {
      fetchUrl = `${API_ENDPOINT}route/getnotstartedtask/${userId}`;
    } else if (selectedChip === "completed") {
      fetchUrl = `${API_ENDPOINT}route/getcompletedtask/${userId}`;
    } else if (selectedChip === "overdue") {
      fetchUrl = `${API_ENDPOINT}route/getoverduetask/${userId}`;
    }

    fetch(fetchUrl)
      .then((res) => res.json())
      .then((data) => {
        const sortedTasks = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        console.log(sortedTasks);

        setTasks(sortedTasks);

        setFilteredTasks(sortedTasks);
        setOriginalTaskData(sortedTasks);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching tasks:", error);
        setIsLoading(false);
        setTasks([]);
        setOriginalTaskData([]);
        setFilteredTasks([]);
      });
  }, [userId, selectedChip]);

  console.log(filteredTasks);
  // Helper functions to check if a date is today or after today

  const handleChipClick = (criteria) => {
    setStatus([]);
    setTask([]);
    setCreatedby([]);
    setFollowupReason([]);
    setTaskowner([]);
    setCreateddatefrom("");
    setCreateddateTo("");
    setDuedatefrom("");
    setDuedateTo("");
    setSelectedFollowupId([]);
    setCloseddatefrom("");
    setCloseddateTo("");
    setClosedby([]);

    setSelectedChip(criteria);
    setProspectiveLevel([]);
    setRecycleReason([]);
    setStage([]);
    setCampaign([]);
    setSubservice([]);
    setProspectiveLevelId([]);
    setSelectedIds([]);
  };
  console.log(filteredTasks);

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
      .then((response) => {
        if (response.ok) {
          setIsDownloadAccess(true);
        } else {
          setIsDownloadAccess(false);
        }
      })
      .catch((err) => {
        setIsDownloadAccess(false);
      });
  }, [userId]);

  useEffect(() => {
    const exportData = filteredTasks.map((row) => ({
      type_of_task: row.type_of_task?.name_of_task_type,
      enquiry_name: `${row.enquiry_id?.first_name} ${row.enquiry_id?.last_name}`,
      status: row?.status === 0 ? "Closed" : "Open",
      task_owner: row.task_owner?.first_name || "",
      follow_up_reason: row?.follow_up_reason_id?.follow_up_reason || "",
      due_date: `${row?.due_date} ${row?.due_time}`,
      stage: row.enquiry_id?.stage_id?.stage_name || "",
      prospective_leval:row.enquiry_id?.prospective_level_id?.prospective_level,
        
      campaign: row.campaign_id?.campaign_name,
      sub_service: row.enquiry_id?.sub_service_id?.name,

      created_by: row.created_by?.first_name || "",
      created_date: fDate(row?.createdAt),

      closed_by: row.closed_by?.first_name || "",
      closed_at: `${fDate(row?.closed_date)} ${row?.task_close_time}`,
      closed_date: `${row?.closed_date} ${row?.task_close_time}`,
      contact_mode: row?.contact_mode_id?.contact_mode || "",
      follow_up_task: row?.is_follow_up_required === 1 ? "Yes" : "No",
      no_follow_up_reason : row?.no_follow_up_reason,
      response_status: row?.response_status || "",
      not_connected_reason:row?.not_connected_reason_id?.reason || "",
      remarks: row?.remarks || "",
      state: row.enquiry_id?.state_id?.state_name || " ",
      city: row.enquiry_id?.city_id?.city_name || "",
    }));
    setExportData(exportData);
  }, [filteredTasks]);

  const columns = [
    {
      field: "selection",
      sortable: false,
      renderHeader: (params) => (
        <Checkbox
          size="small"
          checked={isSelectAllChecked}
          indeterminate={isIndeterminate}
          onChange={handleSelectAllChange}
        />
      ),
      headerClassName: "header-cell",
      width: 70,
      renderCell: (params) => {
        const isChecked = selectedIds.includes(params.row.id);
        return (
          <Checkbox
            size="small"
            checked={isChecked}
            onChange={(event) => handleCheckboxChange(event, params.row?.id)}
          />
        );
      },
    },
    {
      field: "taskType",
      headerName: "Type of Task",

      headerAlign: "center",
      align: "center",
      width: 200,
      filterable: true,
      renderCell: (params) => (
        <div>{params.row.type_of_task?.name_of_task_type}</div>
      ),
    },
    {
      field: "name",
      headerName: "Enquiry Name",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              variant="text"
              onClick={() => handleViewMore(params.row)}
            >
              <PersonIcon fontSize="small" style={{ fontSize: "1rem" }} />{" "}
              {params.row.enquiry_id?.first_name}{" "}
              {params.row.enquiry_id?.last_name}
            </Button>
          </div>
        );
      },
    },

    {
      field: "mobile",
      headerName: "Call",
      width: 50,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => (
        <IconButton
          color="secondary"
          onClick={() => handleClickToCall(params.row.enquiry_id?.id)}
        >
          <CallIcon />
        </IconButton>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let chipColor;
        let statusLabel;
        let closeButton;

        switch (params.value) {
          case 0:
            chipColor = "secondary";
            statusLabel = "Closed";
            closeButton = null;
            break;
          case 1:
            chipColor = "error";
            statusLabel = "Open";
            closeButton = (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() =>
                  handleTaskClose(params.row.id, params.row.enquiry_id?.id)
                }
              >
                Close Task
              </Button>
            );
            break;
          default:
            chipColor = "orange";
            statusLabel = "Unknown";
            closeButton = null;
        }

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Chip
              size="small"
              label={statusLabel}
              color={chipColor}
              variant="contained"
              style={{ fontSize: "10px", marginRight: "8px" }}
            />
            {closeButton}
          </div>
        );
      },
    },
    {
      field: "taskOwner",
      headerName: "Task Owner",
      width: 100,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => <div>{params.row.task_owner?.first_name}</div>,
    },
    {
      field: "follow_up_reason_id",
      headerName: "Follow Up Reason",
      width: 300,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row?.follow_up_reason_id?.follow_up_reason}</div>
      ),
    },
    {
      field: "due_date",
      headerName: "Due Date",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          {params.value} {params.row?.due_time}{" "}
        </div>
      ),
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 100,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row.enquiry_id?.stage_id?.stage_name}</div>
      ),
    },
    {
      field: "prospectiveLevel",
      headerName: "Prospective Level",
      width: 150,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => (
        <div>
          {params.row.enquiry_id?.prospective_level_id?.prospective_level}
        </div>
      ),
    },
    {
      field: "campaign",
      headerName: "Campaign",
      width: 350,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row.campaign_id?.campaign_name}</div>
      ),
    },
    {
      field: "subService",
      headerName: "Sub Service",
      width: 150,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row.enquiry_id?.sub_service_id?.name}</div>
      ),
    },

    {
      field: "createdBy",
      headerName: "Created By",
      width: 100,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => <div>{params.row.created_by?.first_name}</div>,
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div>{fDate(params?.value)}</div>,
    },

    {
      field: "closedBy",
      headerName: "Closed By",
      width: 100,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => <div>{params.row.closed_by?.first_name}</div>,
    },
    {
      field: "closed_date",
      headerName: "Closed At",
      width: 180,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => (
        <div>
          {fDate(params.row?.closed_date)} {params.row?.task_close_time}
        </div>
      ),
    },
    {
      field: "contact_mode_id",
      headerName: "Contact Mode",
      width: 150,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row?.contact_mode_id?.contact_mode}</div>
      ),
    },
    {
      field: "response_status",
      headerName: "Response Status",
      width: 130,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => <div>{params.row?.response_status}</div>,
    },
    {
      field: "not_connected_reason_id",
      headerName: "Not Connected Reason",
      width: 180,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row?.not_connected_reason_id?.reason}</div>
      ),
    },
    {
      field: "is_follow_up_required",
      headerName: "Follow up task",
      width: 100,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row?.is_follow_up_required === 1 ? "Yes" : "No"}</div>
      ),
    },
    {
      field: "no_follow_up_reason",
      headerName: "No Follow Up Reason",
      width: 150,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => <div>{params.row?.no_follow_up_reason}</div>,
    },

    {
      field: "remarks",
      headerName: "Remarks",
      width: 200,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "state",
      headerName: "State",
      width: 100,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row.enquiry_id?.state_id?.state_name}</div>
      ),
    },
    {
      field: "city",
      headerName: "City",
      width: 100,
      headerAlign: "center",
      align: "center",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row.enquiry_id?.city_id?.city_name}</div>
      ),
    },
  ];

  const handleFilterdata1 = () => {
    const filteredData = filteredTasks.filter(
      (i) => !selectedIds.includes(i.id)
    );
    setFilteredTasks(filteredData);
    setTimeout(() => {
      setSelectedIds([]);
    }, 1500);
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fetching
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Grid container spacing={2}>
        {(user_role === "center_admin" || user_role === "data_entry") && (
          <Grid item xs={12}>
            <MassTaskTransferButton onClick={() => handleOpenmask()} />
          </Grid>
        )}

        <Grid item xs={12} md={9}>
          <Grid container gap={2}>
            <Chip
              size="medium"
              label="Not Started Tasks"
              onClick={() => handleChipClick("notStarted")}
              variant={selectedChip === "notStarted" ? "contained" : "outlined"} // Change variant based on selection
              // color={selectedChip === "notStarted" ? "error" : "default"} // Change color based on selection
              style={{
                background: selectedChip === "notStarted" ? "#00b13f" : "",
              }}
            />
            <Chip
              size="medium"
              label="Completed Tasks"
              onClick={() => handleChipClick("completed")}
              variant={selectedChip === "completed" ? "contained" : "outlined"}
              // color={selectedChip === "completed" ? "secondary" : "default"}
              //
              style={{
                background: selectedChip === "completed" ? "#00b13f" : "",
              }}
            />
            <Chip
              size="medium"
              label="Overdue Tasks"
              onClick={() => handleChipClick("overdue")}
              variant={selectedChip === "overdue" ? "contained" : "outlined"}
              // color={selectedChip === "overdue" ? "primary" : "default"}
              style={{
                background: selectedChip === "overdue" ? "#00b13f" : "",
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container gap={1} justifyContent="flex-end">
            {!isEnquiryTaskRoute && (
              <Link to="/enquiry/task" target="_blank">
                <Button size="medium" variant="contained">
                  Open New Tab
                </Button>
              </Link>
            )}

            {isDownloadAccess && (
              <CSVExport data={exportData} filename="tasks.csv" />
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {selectedChip === "notStarted" && (
          <>
            <Grid item xs={12} md={10}>
              <Typography variant="h4" align="left" fontWeight={800}>
                Total Not Started Tasks: {filteredTasks.length}
              </Typography>
            </Grid>

            <Grid item xs={12} md={2} textAlign="end">
              {" "}
              <SelectedItemButton value={selectedIds.length} />
            </Grid>
          </>
        )}

        {selectedChip === "completed" && (
          <Grid item xs={12} md={12}>
            {/* <Grid item xs={12}  >
            {" "}
            <SelectedItemButton value={selectedIds.length} />
      </Grid> */}
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ cursor: "pointer" }}
              onClick={handleOpenCallsToggle}
            >
              <Typography variant="h4">Calls Status </Typography>
              {!openCalls ? (
                <KeyboardArrowDownIcon fontSize="small" />
              ) : (
                <KeyboardArrowUpIcon fontSize="small" />
              )}
            </Box>{" "}
            {openCalls && (
              <Grid item xs={12}>
                <Box
                  mt={1}
                  sx={{
                    border: "1px solid #888888",
                    padding: "10px",
                    borderRadius: "10px",
                    display: "flex",
                    gap: 1,
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography variant="h6">
                    Outbound calls:{" "}
                    {
                      filteredTasks.filter(
                        (task) => task?.contact_mode_id?.id === 2
                      ).length
                    }
                  </Typography>
                  <Box
                    sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                  />

                  <Typography variant="h6">
                    Connected calls:{" "}
                    {
                      filteredTasks.filter(
                        (task) =>
                          task?.contact_mode_id?.id === 2 &&
                          task?.response_status === "connected"
                      ).length
                    }
                  </Typography>

                  <Typography variant="h6">
                    Not connected calls:{" "}
                    {
                      filteredTasks.filter(
                        (task) =>
                          task?.contact_mode_id?.id === 2 &&
                          task?.response_status === "not connected"
                      ).length
                    }
                  </Typography>
                  <Box
                    sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                  />

                  <Typography variant="h6">
                    Inbound calls:{" "}
                    {
                      filteredTasks.filter(
                        (task) => task?.contact_mode_id?.id === 1
                      ).length
                    }
                  </Typography>
                  <Box
                    sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                  />

                  <Typography variant="h6">
                    SMS/WhatsApp/Email:{" "}
                    {
                      filteredTasks.filter(
                        (task) =>
                          task?.contact_mode_id?.id === 3 ||
                          task?.contact_mode_id?.id === 4 ||
                          task?.contact_mode_id?.id === 5
                      ).length
                    }
                  </Typography>
                  <Box
                    sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                  />

                  <Typography variant="h6">
                    Online App:{" "}
                    {
                      filteredTasks.filter(
                        (task) => task?.contact_mode_id?.id === 6
                      ).length
                    }
                  </Typography>
                  <Box
                    sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                  />

                  <Typography variant="h6">
                    Offline App:{" "}
                    {
                      filteredTasks.filter(
                        (task) => task?.contact_mode_id?.id === 7
                      ).length
                    }
                  </Typography>
                  <Box
                    sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                  />

                  <Typography variant="h6">
                    Attended appointments:{" "}
                    {
                      filteredTasks.filter(
                        (task) =>
                          (task?.contact_mode_id?.id === 6 ||
                            task?.contact_mode_id?.id === 7) &&
                          task.response_status === "attended"
                      ).length
                    }
                  </Typography>
                  <Box
                    sx={{ borderLeft: "1px solid #888888", height: "20px" }}
                  />

                  <Typography variant="h6">
                    Not attended appointments:{" "}
                    {
                      filteredTasks.filter(
                        (task) =>
                          (task?.contact_mode_id?.id === 6 ||
                            task?.contact_mode_id?.id === 7) &&
                          task.response_status === "not attended"
                      ).length
                    }
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        )}

        {selectedChip === "overdue" && (
          <>
            <Grid item xs={12} md={10}>
              <Typography variant="h4" align="left" fontWeight={800}>
                Total Overdue Tasks: {filteredTasks.length}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} textAlign="end">
              {" "}
              <SelectedItemButton value={selectedIds.length} />
            </Grid>
          </>
        )}

        <Grid item xs={12} style={{ width: "100%" }}>
          {!isLoading && filteredTasks.length === 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/task.png"
                alt="Task Icon"
                style={{ width: "100px", height: "100px" }}
              />
              <Typography variant="h3" align="center" gutterBottom>
                No Tasks
              </Typography>
            </div>
          )}
          {!isLoading && filteredTasks.length > 0 && (
            <Box sx={{ height: "600px", overflow: "auto" }}>
              <DataGrid
                columns={columns}
                rows={filteredTasks}
                autoHeight
                pageSize={pagination.pageSize}
                onPaginationModelChange={(model) => setPagination(model)}
                pageSizeOptions={[10, 25, 50, 100]}
                slots={{
                  NoRowsOverlay: () => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        //height: '100%',
                      }}
                    >
                      <p>No Tasks available</p>
                    </div>
                  ),
                }}
                slotProps={{
                  toolbar: {
                    exportButton: {
                      csvOptions: {
                        fileName: "task_report",
                      },
                    },
                  },
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {isCloseDialogOpen && (
        <CloseTaskDialog
          open={isCloseDialogOpen}
          onClose={() => setIsCloseDialogOpen(false)}
          taskId={selectedTaskId}
          enquiryId={selectedEnquiryId}
        />
      )}

      {selectedIds.length === 0 ? (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={<NoTaskPopup onClose={handleClosemask} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={
            <MassTaskTransfer
              onClose={handleClosemask}
              handleFilterdata={handleFilterdata1}
              selectedId={selectedIds}
            />
          }
          title="Task Transfer"
          maxWidth="sm"
          showDivider={true}
        />
      )}
    </>
  );
}

export default ViewTasksDetails;
