import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

export default function Content() {
  const firstName =localStorage.getItem("firstName")


  const currentHour = new Date().getHours();
 

  // Determine the greeting
  const getGreeting = () => {
    if (currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour < 16) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };
  return (
    <>
     <Typography
     variant='h3'
                // sx={{
                //   fontFamily: "Roboto",
                //   color: "#000000",
                //   fontWeight: 700,
                //   fontSize: "1.2rem",
                // }}
              >
           Hey {firstName} !   <br />{" "}
                <span style={{ color: "#F8AB1D" }}>Welcome </span>  to{" "}
                <span style={{ color: "#F8AB1D" }}>SIEC Test Masters</span>
              </Typography>
              {/* <Typography
                sx={{
                  fontFamily: "Roboto",
                  color: "#848080",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginTop: "10px",
                }}
              >
                The only way to do great work is to love what you do
              </Typography> */}
    </>
  )
}
