import React, { useEffect, useState } from "react";
import AdvisorNavbar from "../../../components/navbar/AdvisorNavbar";
import Header from "../../../components/header/Header";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { Country, State, City } from "country-state-city";
//import { countryCodes } from 'country-codes-list'
import { Link as ScrollLink } from "react-scroll";
import { Link, useNavigate } from "react-router-dom";

import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import HeadingCard from "../../../newTestPrep/newComponent/NewCardsComp/HeadingCard";
import NewCardcomp from "../../../components/card/NewCardcomp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  border: `2px solid #2196F3`,
  borderRadius: "12px",
}));

const BodyContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  overflowY: "auto", // Enable vertical scrolling for the content
  height: "calc(100vh - 50px)", // Subtract header height
}));

function AddEnquiryNew() {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const advisorId = localStorage.getItem("advisorId");
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    setError,
  } = useForm();

  const navigate = useNavigate();

  //  const [firstname, setFirstname] = useState('');
  // const [lastname, setLastname] = useState('');
  // const [email, setEmail] = useState('');
  // const [mobile, setMobile] = useState('');
  const [advisors, setAdvisors] = useState([]);
  const [role, setRole] = useState(userRole);

  const [searching, setSearching] = useState(false);
  const [enquiryFound, setEnquiryFound] = useState(true);
  const [showSearchForm, setShowSearchForm] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showNoEnquiryPopup, setShowNoEnquiryPopup] = useState(false);
  const [pinCodeData, setPinCodeData] = useState({});
  const [pincodeError, setPincodeError] = useState("");
  const [leadSources, setLeadSources] = useState([]);
  const [subLeadSources, setSubLeadSources] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [services, setServices] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [sourceTypes, setSourceTypes] = useState([]);
  const [subSourceTypes, setSubSourceTypes] = useState([]);
  const [purposeOfTest, setPurposeOfTest] = useState([]);
  const [leadTestExperience, setLeadTestExperience] = useState([]);
  const [examStatuses, setExamStatus] = useState([]);
  const [proficiencyLevel, setProficiencyLevel] = useState([]);
  const [prospectiveLevel, setProspectiveLevel] = useState([]);
  const [levelOfInterest, setLevelOfInterest] = useState([]);
  const [targetCountries, setTargetCountries] = useState([]);
  const [subTestType, setSubtestType] = useState([]);
  const [subTestTypeFormat, setSubtestTypeFormat] = useState([]);
  const [subServicePrograms, setSubServicePrograms] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [isSavingLoading, setIsSavingLoading] = useState(false);

  const [isWhatsappChecked, setIsWhatsappChecked] = useState(false);
  const [taskItems, setTaskItems] = useState([]);
  const [branches, setBranches] = useState([]);
  const [testtypes, setTestTypes] = useState([]);
  const [selectedTestTypeId, setSelectedTestTypeId] = useState(null);
  const [selectedTestTypeName, setSelectedTestTypeName] = useState(null);
  const [users, setUsers] = useState([]);
  const [taskOwners, setTaskOwners] = useState([]);
  const [nearest_branch, setNearestBranch] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(101);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);

  const [selectedSourceType, setSelectedSourceType] = useState(null);
  const [state, setState] = useState(null);
  const [selectedPremiumSubService, setSelectedPremiumSubService] =
    useState(null);

  const States = State.getStatesOfCountry(selectedCountry);
  const Cities = City.getCitiesOfState(selectedCountry, selectedState);

  const examStatus = watch("exam_status_id");
  const testBooking = watch("test_taken");
  const deliveryMode = watch("preferred_delivery_mode");

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setValue("country_id", event.target.value);
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setValue("state_id", event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setValue("city_id", event.target.value);
  };

  const handleServiceChange = (_, values) => {
    setSelectedServices(values);
    const selectedServiceIds = values.map((service) => service.id);
    setValue("service_id", selectedServiceIds);

    if (values.some((service) => service.id === 2)) {
      setValue("assign_to", null);
      setValue("task_owner", null);
    }

    // Reset sub-service fields if their main service is not selected
    if (!selectedServiceIds.includes(1)) {
      setValue("test_preparation_sub_service_id", null);
    }
    if (!selectedServiceIds.includes(2)) {
      setValue("premium_admission_sub_service_id", null);
      setValue("premium_admission_programs_id", null);
    }
  };

  const handlePremiumSubServiceChange = (e) => {
    setSelectedPremiumSubService(e.target.value);
    setValue("premium_admission_sub_service_id", e.target.value);
  };

  const handleSourceTypeChange = (event) => {
    setSelectedSourceType(event.target.value);
    setValue("lead_source_type_id", event.target.value);
  };

  const handleExamStatusChange = (value) => {
    setValue("exam_status_id", value);
  };

  const handleTestBookingStatusChange = (value) => {
    setValue("test_taken", value);
  };

  const handleDeliveryModeStatusChange = (value) => {
    setValue("delivery_mode", value);
  };

  const handleWhatsappCheck = () => {
    setIsWhatsappChecked((prevIsChecked) => !prevIsChecked);
  };

  const handlePinCodeChange = (event) => {
    const newPinCode = event.target.value;
    if (newPinCode.length === 6) {
      setValue("pincode_id", newPinCode);
      fetchPinCodeData(newPinCode);
    } else {
      setPincodeError("Invalid pin code 3");
      //setNearestBranch(null)
      //setError('pincode', { type: 'manual', message: 'Invalid pin code 4' });
      /*reset({
                    state: '',
                    city: '',
                    nearest_branch: null
                })*/
      setSelectedState(null);
      setSelectedCity(null);
      setValue("pincode_id", null);
      setValue("state_id", "");
      setValue("city_id", "");
      // setValue('nearest_branch', null);
    }
  };

  const fetchPinCodeData = async (pincode) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}oldzipcode/pincode/${pincode}`
      );
      //setPinCodeData(response.data);
      if (response.status === 200) {
        const postOfficeData = response.data; // Get the first PostOffice object
        setPinCodeData(postOfficeData);
        console.log(postOfficeData);
        if (postOfficeData) {
          // Update the State and City fields with the data from the API
          setPincodeError("");
          setSelectedState(parseInt(postOfficeData.state_id));
          setValue("state_id", parseInt(postOfficeData.state_id));

          setSelectedCity(parseInt(postOfficeData.city_id));
          setValue("city_id", parseInt(postOfficeData.city_id));
          // setValue('nearest_branch', postOfficeData?.branch_detail[0]?.id);
        }
      } else {
        console.log("Invalid pincode");
        console.log("API error");
        //alert('error')
        setPincodeError("Invalid pin code 1");
        //setNearestBranch(null)
        setError("pincode", { type: "manual", message: "Invalid pin code 2" });
        /* reset({
                        state: '',
                        city: '',
                        nearest_branch: null
                    })*/
        setValue("pincode_id", null);
        setValue("state_id", "");
        setValue("city_id", "");
        setSelectedState(null);
        setSelectedCity(null);
        //setValue('nearest_branch', null);
      }
    } catch (error) {
      console.error("Error fetching pin code data:", error);
      showSnackbar("Error fetching pin code data", "error");
      setPinCodeData(null);
    }
  };

  /* const onSubmit = (data) => {
            if (isWhatsappChecked) {
            data.isWhatsapp = true;
            data.whatsapp_number = null;
            } else {
                data.isWhatsapp = false;
            }
            console.log(data); // You can replace this with your submission logic
        }; */

  const handleSearch = async () => {
    //e.preventDefault()

    setSearching(true);

    // Simulate a loading delay
    //await new Promise(resolve => setTimeout(resolve, 1000));

    /*const userData = {
                first_name: firstname,
                last_name: lastname,
                email: email,
                mobile: mobile
            }*/

    try {
      // Make a POST request to your API endpoint
      const response = await fetch(
        `${API_ENDPOINT}advisordashboard/addpersonaldetail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(),
        }
      );
      console.log(response);

      if (response.status === 201) {
        const responseData = await response.json();

        console.log("API Response:", responseData);

        // setShowForm(true);
        setShowNoEnquiryPopup(true);
      } else if (response.status === 200) {
        setShowNoEnquiryPopup(true);
      } else {
        console.error(
          "API Request Failed:",
          response.status,
          response.statusText
        );
        setShowNoEnquiryPopup(true);
      }
    } catch (error) {
      console.error("An error occurred during the API request:", error);
      // Handle the error, show an error message, or take other actions as needed
    }

    setSearching(false);
  };

  const onSubmit = async (data) => {
    // handleSearch()

    if (isWhatsappChecked) {
      data.isWhatsapp = true;
      data.whatsapp_number = null;
      data.whatsapp_country_code = null;
    } else {
      data.isWhatsapp = false;
    }

    setIsSavingLoading(true);

    data.user_id = parseInt(userId, 10);

    console.log(data);

    try {
      //await handleSearch();

      const response = await fetch(
        `${API_ENDPOINT}advisordashboard/addenquiry`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Replace with the appropriate content type
          },
          body: JSON.stringify(data),
        }
      );
      // .then((response) => {
      if (response.status === 201) {
        // API call succeeded, show success message
        // alert('Enquiry sent successfully');
        setIsSavingLoading(false);
        showSnackbar("Enquiry sent successfully", "success");

        /*setTimeout(() => {
          window.location.reload();
        }, 3000);*/
        showSnackbar("Enquiry sent successfully", "success");
        console.log("Enquiry sent successfully", JSON.stringify(response));

        console.log("Form reset successfully");
      } else {
        // API call failed, show error message
        // alert('Error submitting enquiry. Please try again.');
        setIsSavingLoading(false);
        showSnackbar("Error submitting enquiry. Please try again.", "error");
      }
    } catch (error) {
      // Handle network errors or other issues
      console.error("Error:", error);
      // alert('An error occurred. Please try again later.');
      setIsSavingLoading(false);
      showSnackbar("An error occurred. Please try again later.", "error");
    }
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // Fetching countries and set initial value
    const countries = Country.getAllCountries();
    setValue("country", "IN");

    //Fetching states and set initial value
    const states = State.getStatesOfCountry(selectedCountry);
    //setValue('state', states[0]?.isoCode || '');

    //Fetching cities and set the initial value
    const cities = City.getCitiesOfState(selectedCountry, selectedState);
    //setValue('city', cities[0]?.name || '');
  }, [setValue, selectedCountry, selectedState]);

  const handleLeadSourceTypeChange = (selectedType) => {
    // Filter sub lead sources based on the selected lead source type category
    const filteredSubLeadSources = leadSources.filter(
      (source) => source.category === selectedType
    );
    setSubLeadSources(filteredSubLeadSources);
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}maritalstatuses`)
      .then((response) => response.json())
      .then((data) => {
        setMaritalStatus(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching maritalstatus:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}country`)
      .then((response) => response.json())
      .then((data) => {
        const sortedData = [...data].sort(
          (a, b) => a.phone_code - b.phone_code
        );
        const uniqueArray = sortedData.filter(
          (obj, index, self) =>
            index === self.findIndex((t) => t.phone_code === obj.phone_code)
        );
        setCountriesList(data);
        setCountries(uniqueArray);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching country:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetch(`${API_ENDPOINT}oldstate`)
        .then((response) => response.json())
        .then((data) => {
          const filteredState = data.filter(
            (state) => state.country_id === selectedCountry
          );
          setStates(filteredState);
          console.log(filteredState);
        })
        .catch((error) => {
          console.error("Error fetching state:", error);
        });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry && selectedState) {
      fetch(`${API_ENDPOINT}oldcity`)
        .then((response) => response.json())
        .then((data) => {
          const filteredCity = data.filter(
            (city) =>
              city.country === selectedCountry && city.state === selectedState
          );
          setCities(filteredCity);
          console.log(filteredCity);
        })
        .catch((error) => {
          console.error("Error fetching city:", error);
        });
    }
  }, [selectedCountry, selectedState]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}targetcountries`)
      .then((response) => response.json())
      .then((data) => {
        setTargetCountries(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching target countries:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}service`)
      .then((response) => response.json())
      .then((data) => {
        setServices(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedServices.length > 0) {
      fetch(`${API_ENDPOINT}subservice`)
        .then((response) => response.json())
        .then((data) => {
          const filteredSubServices = data.filter((sub) =>
            selectedServices.includes(sub.service_id)
          );
          setSubServices(data);
        })
        .catch((error) => {
          console.error("Error fetching sub-services:", error);
        });
    } else {
      setSubServices([]);
    }
  }, [selectedServices]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}subservice-program`)
      .then((response) => response.json())
      .then((data) => {
        setSubServicePrograms(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching sub service programs:", error);
      });
  }, [selectedPremiumSubService]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}purposeoftest`)
      .then((response) => response.json())
      .then((data) => {
        setPurposeOfTest(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching purpose of test:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}leadtestexperience`)
      .then((response) => response.json())
      .then((data) => {
        setLeadTestExperience(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching test experience:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}examstatus`)
      .then((response) => response.json())
      .then((data) => {
        setExamStatus(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching exam status:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}levelofinterest`)
      .then((response) => response.json())
      .then((data) => {
        setLevelOfInterest(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching level of interest:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}proficiencylevel`)
      .then((response) => response.json())
      .then((data) => {
        setProficiencyLevel(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching proficiency level:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}prospectivelevel`)
      .then((response) => response.json())
      .then((data) => {
        setProspectiveLevel(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching prospective level:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_ENDPOINT}leadsource`)
      .then((response) => response.json())
      .then((data) => {
        const filteredLeadSources = data.filter((sub) => sub.is_active === 1);
        //setSubSourceTypes(filteredLeadSources);
        setLeadSources(filteredLeadSources);
      })
      .catch((error) => {
        console.error("Error fetching lead sources:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedSourceType) {
      fetch(`${API_ENDPOINT}subleadsource`)
        .then((response) => response.json())
        .then((data) => {
          const filteredLeadSources = data.filter(
            (sub) => sub.lead_source_type_id === selectedSourceType
          );
          setSubLeadSources(filteredLeadSources);
        })
        .catch((error) => {
          console.error("Error fetching sub lead sources:", error);
        });
    }
  }, [selectedSourceType]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route2.0/typeoftaskroles/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setTaskItems(data);
      })
      .catch((error) => {
        console.error("Error fetching task items:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_ENDPOINT}advisor`)
      .then((response) => response.json())
      .then((data) => {
        let filteredData = data;

        if (role === "advisor") {
          // If the role is advisor, filter the list to show only the logged-in advisor
          filteredData = data.filter((user) => user.id === parseInt(userId));
        }

        const sortedData = filteredData.sort((a, b) => {
          const nameA = (a.first_name + " " + a.last_name).toUpperCase();
          const nameB = (b.first_name + " " + b.last_name).toUpperCase();
          return nameA.localeCompare(nameB);
        });
        setAdvisors(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching advisor items:", error);
      });
  }, []);
  //console.log(taskItems)

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}branch`)
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalltesttype`)
      .then((response) => response.json())
      .then((data) => {
        setTestTypes(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
      });
  }, []);

  useEffect(() => {
    const testType = testtypes.find((i) => i.id === selectedTestTypeName);
    setSelectedTestTypeId(testType);
  });

  console.log("selectedTestTypeId", selectedTestTypeId);
  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route2.0/getsubtesttype/${selectedTestTypeName}`)
      .then((response) => response.json())
      .then((data) => {
        setSubtestType(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
        setSubtestType([]);
      });
  }, [selectedTestTypeName]);

  console.log("subtesttype", subTestType);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}testdeliveryformat`)
      .then((response) => response.json())
      .then((data) => {
        setSubtestTypeFormat(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalluser`)
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.filter(
          (user) =>
            user.user_role === "advisor" ||
            user.user_role === "admin" ||
            user.user_role === "trainer"
        );

        console.log(filteredData);

        let sortedData = filteredData.sort((a, b) => {
          const nameA = (a.first_name + " " + a.last_name).toUpperCase();
          const nameB = (b.first_name + " " + b.last_name).toUpperCase();
          return nameA.localeCompare(nameB);
        });

        if (userRole === "advisor" || userRole === "trainer") {
          // If the role is advisor, filter the list to show only the logged-in advisor
          sortedData = filteredData.filter(
            (user) => user.id === parseInt(userId)
          );
        }

        const sortedData2 = data.sort((a, b) => {
          const nameA = (a.first_name + " " + a.last_name).toUpperCase();
          const nameB = (b.first_name + " " + b.last_name).toUpperCase();
          return nameA.localeCompare(nameB);
        });
        setUsers(sortedData);
        setTaskOwners(sortedData2);
        console.log(sortedData2);
        console.log(sortedData);
        const defaultOwner = data.find(
          (owner) => owner.id === parseInt(userId)
        );
        if (defaultOwner) {
          setValue("task_owner", defaultOwner.id);
          setValue("assign_to", defaultOwner.id);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const handleTestTypeChange = (e) => {
    setSelectedTestTypeName(e.target.value);
    setValue("test_type_id", e.target.value);
  };

  console.log("selectedTestTypeId", selectedTestTypeName);
  return (
    <div className="content">
      {/* Header */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ position: "sticky", top: 40, zIndex: 10 }}
          >
            <NewCardcomp borderRadius="none">
              <Grid container>
                <Grid item xs={12} sm={4} md={2}>
                  <Typography variant="h3" ml={2}>
                    {" "}
                    Add Enquiry
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={10}>
                  <Grid container gap={1} justifyContent="end">
                    <Button
                      variant="contained"
                      style={{ borderRadius: "8px", background: "#00b13f" }}
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Save
                    </Button>

                    <Button
                      variant="contained"
                      style={{ borderRadius: "8px", background: "#ff4b4b" }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </NewCardcomp>
          </Grid>

          {/* Personal Info*/}

          {/* {showForm && (  */}
          <Grid item xs={12} style={{ position: "relative" }}>
            <Grid container spacing="4">
              <Grid item xs={12} md={12} lg={12}>
                <Box container spacing="2">
                  <Stack direction="column" spacing={2}>
                    <Item>
                      <Typography
                        id="personal-info"
                        variant="h4"
                        fontWeight={700}
                      >
                        Personal Information
                      </Typography>
                      <Grid container spacing={3} marginTop="1px">
                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            First Name
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="first_name"
                            control={control}
                            //rules={{ required: true }}
                            rules={{ required: "First name is required" }}
                            render={({ field }) => {
                              console.log(field.value); // Log the value
                              return (
                                <TextField
                                  size="small"
                                  {...field}
                                  fullWidth
                                  InputProps={{
                                    style: { borderRadius: "12px" },
                                  }}
                                  error={!!errors.first_name}
                                />
                              );
                            }}
                          />
                          {errors.first_name && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.first_name.message}
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">Last Name</Typography>
                          <Controller
                            name="last_name"
                            control={control}
                            //rules={{ required: true }}
                            //rules={{ required: 'Last name is required' }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                {...field}
                                fullWidth
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                error={!!errors.last_name}
                                //helperText={errors.last_name && 'Last name is required'}
                              />
                            )}
                          />
                          {errors.last_name && (
                            <span style={{ color: "red" }}>
                              {errors.last_name.message}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">Gender</Typography>
                          <Controller
                            name="gender"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={!!errors.gender}
                                //helperText={errors.gender && 'Gender is required'}
                              >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                              </TextField>
                            )}
                          />
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">Date of Birth</Typography>
                          <Controller
                            name="dob"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                {...field}
                                fullWidth
                                type="date"
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                  inputProps: { max: "2005-12-31" },
                                }}
                                error={!!errors.dob}
                                //helperText={errors.dob && 'Date of Birth is required'}
                              />
                            )}
                          />
                          {errors.dob && (
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                top: 0,
                                right: -8,
                              }}
                            >
                              *
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">Marital Status</Typography>
                          <Controller
                            name="marital_status"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={!!errors.marital_status}
                                //helperText={errors.gender && 'Gender is required'}
                              >
                                {maritalStatus.map((maritalstatus) => (
                                  <MenuItem
                                    key={maritalstatus.id}
                                    value={maritalstatus.id}
                                  >
                                    {maritalstatus.maritial_type}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Item>

                    {/* Contact Information */}
                    <Item>
                      <Typography
                        id="contact-info"
                        variant="h4"
                        fontWeight={700}
                      >
                        Contact Information
                      </Typography>
                      <Grid container spacing={3} marginTop="1px">
                        <Grid item xs={6} md={4} lg={3} container>
                          <Grid item xs={4} md={4} lg={4}>
                            <Typography variant="h6">
                              ISD Code
                              <span
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  marginLeft: "4px",
                                  fontSize: "1em",
                                }}
                              >
                                *
                              </span>
                            </Typography>
                            <Controller
                              name="mobile_country_code"
                              control={control}
                              defaultValue={91}
                              rules={{ required: "Country code is required" }}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  select
                                  {...field}
                                  InputProps={{
                                    style: { borderRadius: "12px" },
                                  }}
                                  fullWidth
                                  defaultValue={91}
                                  error={Boolean(errors.mobile_country_code)}
                                  //helperText={errors.country && 'Country is required'}
                                >
                                  {countries.map((country) => (
                                    <MenuItem
                                      key={country.phone_code}
                                      value={country.phone_code}
                                    >
                                      {country.phone_code}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            />
                            {errors.mobile_country_code && (
                              <span style={{ color: "red" }}>
                                {errors.mobile_country_code.message}
                              </span>
                            )}
                          </Grid>

                          <Grid item xs={8} md={8} lg={8}>
                            <Typography variant="h6">
                              Mobile
                              <span
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  marginLeft: "4px",
                                  fontSize: "1em",
                                }}
                              >
                                *
                              </span>
                            </Typography>
                            <Controller
                              name="mobile"
                              control={control}
                              //defaultValue='+91'
                              rules={{
                                required: "Contact number is required",
                                /* pattern: {
                                value: /^[1-9][0-9]{9}$/,
                                message: "Invalid mobile number.",
                              },*/
                              }}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  {...field}
                                  InputProps={{
                                    style: { borderRadius: "12px" },
                                  }}
                                  error={!!errors.mobile}
                                  //helperText={errors.mobile?.message}
                                  fullWidth
                                />
                              )}
                            ></Controller>
                            {errors.mobile && (
                              <span
                                style={{ color: "red", fontSize: "0.7rem" }}
                              >
                                {errors.mobile.message}
                              </span>
                            )}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isWhatsappChecked}
                                  onChange={handleWhatsappCheck}
                                  name="isWhatsApp"
                                />
                              }
                              label={
                                <Typography variant="h6" color="#364152">
                                  Contact same as WhatsApp
                                </Typography>
                              }
                            />
                          </Grid>
                        </Grid>

                        {!isWhatsappChecked && (
                          <Grid item xs={6} md={4} lg={3} container>
                            <Grid item xs={4} md={4} lg={4}>
                              <Typography variant="h6">
                                ISD Code
                                <span
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    marginLeft: "4px",
                                    fontSize: "1em",
                                  }}
                                >
                                  *
                                </span>
                              </Typography>
                              <Controller
                                name="whatsapp_country_code"
                                control={control}
                                defaultValue={91}
                                rules={{ required: "Country code is required" }}
                                render={({ field }) => (
                                  <TextField
                                    size="small"
                                    select
                                    {...field}
                                    InputProps={{
                                      style: { borderRadius: "12px" },
                                    }}
                                    fullWidth
                                    defaultValue={91}
                                    error={Boolean(
                                      errors.whatsapp_country_code
                                    )}
                                  >
                                    {countries.map((country) => (
                                      <MenuItem
                                        key={country.phone_code}
                                        value={country.phone_code}
                                      >
                                        {country.phone_code}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                              {errors.whatsapp_country_code && (
                                <span style={{ color: "red" }}>
                                  {errors.whatsapp_country_code.message}
                                </span>
                              )}
                            </Grid>
                            <Grid item xs={8} md={8} lg={8}>
                              <Typography variant="h6">
                                Whatsapp Number
                                <span
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    marginLeft: "4px",
                                    fontSize: "1em",
                                  }}
                                >
                                  *
                                </span>
                              </Typography>
                              <Controller
                                name="whatsapp_number"
                                control={control}
                                rules={
                                  {
                                    /* pattern: {
                                  required: "Whatsapp number is required",
                                  value: /^[1-9][0-9]{9}$/,
                                  message: "Invalid whatsapp number.",
                                },*/
                                  }
                                }
                                render={({ field }) => (
                                  <TextField
                                    size="small"
                                    {...field}
                                    InputProps={{
                                      style: { borderRadius: "12px" },
                                    }}
                                    error={!!errors.whatsapp_number}
                                    // helperText={errors.whatsapp_number?.message}
                                    fullWidth
                                  />
                                )}
                              ></Controller>
                              {errors.whatsapp_number && (
                                <span style={{ color: "red" }}>
                                  {errors.whatsapp_number.message}
                                </span>
                              )}
                            </Grid>
                          </Grid>
                        )}

                        <Grid item xs={6} md={4} lg={3} container>
                          <Grid item xs={4} md={4} lg={4}>
                            <Typography variant="h6">ISD Code</Typography>
                            <Controller
                              name="secondary_mobile_country_code"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  select
                                  {...field}
                                  InputProps={{
                                    style: { borderRadius: "12px" },
                                  }}
                                  fullWidth
                                  error={Boolean(
                                    errors.secondary_mobile_country_code
                                  )}
                                >
                                  {countries.map((country) => (
                                    <MenuItem
                                      key={country.phone_code}
                                      value={country.phone_code}
                                    >
                                      {country.phone_code}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            />
                            {errors.secondary_mobile_country_code && (
                              <span style={{ color: "red" }}>
                                {errors.secondary_mobile_country_code.message}
                              </span>
                            )}
                          </Grid>

                          <Grid item xs={8} md={8} lg={8}>
                            <Typography variant="h6">
                              Secondary Mobile
                            </Typography>
                            <Controller
                              name="secondary_mobile"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  {...field}
                                  InputProps={{
                                    style: { borderRadius: "12px" },
                                  }}
                                  error={!!errors.secondary_mobile}
                                  fullWidth
                                />
                              )}
                            ></Controller>
                            {errors.secondary_mobile && (
                              <span style={{ color: "red" }}>
                                {errors.secondary_mobile.message}
                              </span>
                            )}
                          </Grid>
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">Email</Typography>
                          <Controller
                            name="email"
                            control={control}
                            rules={{
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          ></Controller>
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">Secondary Email</Typography>
                          <Controller
                            name="secondary_email"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                variant="outlined"
                                error={!!errors.secondary_email}
                                fullWidth
                              />
                            )}
                          ></Controller>
                          {errors.secondary_email && (
                            <span style={{ color: "red" }}>
                              {errors.secondary_email.message}
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Country of Residence
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="country_id"
                            control={control}
                            defaultValue={101}
                            rules={{ required: "Country is required" }}
                            render={({ field }) => (
                              <TextField
                                select
                                size="small"
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                value={selectedCountry}
                                onChange={handleCountryChange}
                                defaultValue={101}
                                error={Boolean(errors.country_id)}
                                //helperText={errors.country && 'Country is required'}
                              >
                                {countriesList.map((country) => (
                                  <MenuItem key={country.id} value={country.id}>
                                    {country.country_name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                          {errors.country_id && (
                            <span style={{ color: "red" }}>
                              {errors.country_id.message}
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">Pincode</Typography>
                          <Controller
                            name="pincode_id"
                            control={control}
                            //rules={{ required: "Pin code is required", pattern: { value: /^\d{6}$/, message: "Invalid pin code" } }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.pincode_id)}
                                onChange={handlePinCodeChange}
                              />
                            )}
                          />
                          {errors.pincode_id && (
                            <span
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {errors.pincode_id.message}
                            </span>
                          )}
                          {pincodeError && (
                            <span
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {pincodeError}
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">State</Typography>
                          <Controller
                            name="state_id"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                value={selectedState}
                                onChange={handleStateChange}
                                // defaultValue={pinCodeData?.state}
                                error={Boolean(errors.state_id)}
                                //helperText={errors.state}
                              >
                                {states.map((state) => (
                                  <MenuItem key={state.id} value={state.id}>
                                    {state.state_name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">City</Typography>
                          <Controller
                            name="city_id"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                value={selectedCity}
                                onChange={handleCityChange}
                                //defaultValue="IN"
                                error={Boolean(errors.city_id)}
                                // helperText={errors.city }
                              >
                                {cities.map((city) => (
                                  <MenuItem key={city.id} value={city.id}>
                                    {city.city_name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Nearest Branch{" "}
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="nearest_branch_id"
                            control={control}
                            rules={{ required: "Branch name is required" }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                //value={nearest_branch}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.nearest_branch_id)}
                                // onChange={(event) => setNearestBranch(event.target.value)}
                                // helperText={errors.nearest_branch && 'Branch Name is required'}
                              >
                                {branches.map((branch) => (
                                  <MenuItem key={branch.id} value={branch.id}>
                                    {branch.branch_name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                          {errors.nearest_branch_id && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.nearest_branch_id.message}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </Item>

                    {/* Mandatory Information */}

                    <Item>
                      <Typography
                        id="mandatory_info"
                        variant="h4"
                        fontWeight={700}
                      >
                        Mandatory Information
                      </Typography>
                      <Grid container spacing={3} marginTop="1px">
                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Service Name
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="service_id"
                            control={control}
                            rules={{ required: "Service name is required" }}
                            render={({ field }) => (
                              <Autocomplete
                                multiple
                                options={services}
                                getOptionLabel={(option) => option.service_name}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox checked={selected} />
                                    {option.service_name}
                                  </li>
                                )}
                                onChange={handleServiceChange}
                                value={selectedServices}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    fullWidth
                                    sx={{
                                      borderRadius: "12px",
                                      fontSize: "6px",
                                      "& .MuiOutlinedInput-root": {
                                        borderRadius: "12px",
                                      },
                                    }}
                                    error={Boolean(errors.service_id)}
                                  />
                                )}
                              />
                            )}
                          />
                          {errors.service_id && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.service_id.message}
                            </span>
                          )}
                        </Grid>

                        {selectedServices.some((s) => s.id === 1) && (
                          <Grid item xs={6} md={4} lg={3}>
                            <Typography variant="h6">
                              Test Preparation Sub Service
                              <span
                                style={{
                                  color: "red",
                                  marginLeft: "4px",
                                  fontSize: "1em",
                                }}
                              >
                                *
                              </span>
                            </Typography>
                            <Controller
                              name="test_preparation_sub_service_id"
                              control={control}
                              rules={{
                                required:
                                  "Test Preparation Sub Service is required",
                              }}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  select
                                  {...field}
                                  fullWidth
                                  sx={{
                                    borderRadius: "12px",
                                    "& .MuiOutlinedInput-root": {
                                      borderRadius: "12px",
                                    },
                                  }}
                                  error={Boolean(
                                    errors.test_preparation_sub_service_id
                                  )}
                                >
                                  {subServices
                                    .filter((s) => s.service_id === 1)
                                    .map((service) => (
                                      <MenuItem
                                        key={service.id}
                                        value={service.id}
                                      >
                                        {service.name}
                                      </MenuItem>
                                    ))}
                                </TextField>
                              )}
                            />
                            {errors.test_preparation_sub_service_id && (
                              <span
                                style={{ color: "red", fontSize: "0.7rem" }}
                              >
                                {errors.test_preparation_sub_service_id.message}
                              </span>
                            )}
                          </Grid>
                        )}

                        {selectedServices.some((s) => s.id === 2) && (
                          <>
                            <Grid item xs={6} md={4} lg={3}>
                              <Typography variant="h6">
                                Premium Admission Sub Service
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: "4px",
                                    fontSize: "1em",
                                  }}
                                >
                                  *
                                </span>
                              </Typography>
                              <Controller
                                name="premium_admission_sub_service_id"
                                control={control}
                                rules={{
                                  required:
                                    "Premium Admission Sub Service is required",
                                }}
                                render={({ field }) => (
                                  <TextField
                                    size="small"
                                    select
                                    {...field}
                                    fullWidth
                                    value={selectedPremiumSubService}
                                    sx={{
                                      borderRadius: "12px",
                                      "& .MuiOutlinedInput-root": {
                                        borderRadius: "12px",
                                      },
                                    }}
                                    error={Boolean(
                                      errors.premium_admission_sub_service_id
                                    )}
                                    // onChange={(e) => {
                                    //   field.onChange(e);
                                    //   setSelectedPremiumSubService(e.target.value);
                                    // }}
                                    onChange={handlePremiumSubServiceChange}
                                  >
                                    {subServices
                                      .filter((s) => s.service_id === 2)
                                      .map((service) => (
                                        <MenuItem
                                          key={service.id}
                                          value={service.id}
                                        >
                                          {service.name}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                )}
                              />
                              {errors.premium_admission_sub_service_id && (
                                <span
                                  style={{ color: "red", fontSize: "0.7rem" }}
                                >
                                  {
                                    errors.premium_admission_sub_service_id
                                      .message
                                  }
                                </span>
                              )}
                            </Grid>

                            {selectedPremiumSubService !== null && (
                              <Grid item xs={6} md={4} lg={3}>
                                <Typography variant="h6">
                                  {selectedPremiumSubService === 13
                                    ? "Undergraduate Admission Program"
                                    : "Postgraduate Admission Program"}
                                </Typography>
                                <Controller
                                  name="premium_admission_programs_id"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      size="small"
                                      select
                                      {...field}
                                      fullWidth
                                      sx={{
                                        borderRadius: "12px",
                                        "& .MuiOutlinedInput-root": {
                                          borderRadius: "12px",
                                        },
                                      }}
                                    >
                                      {subServicePrograms
                                        .filter(
                                          (s) =>
                                            s.sub_service_id ===
                                            selectedPremiumSubService
                                        )
                                        .map((program) => (
                                          <MenuItem
                                            key={program.id}
                                            value={program.id}
                                          >
                                            {program.name}
                                          </MenuItem>
                                        ))}
                                    </TextField>
                                  )}
                                />
                              </Grid>
                            )}
                          </>
                        )}
                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Purpose Of Test
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="purpose_of_test_id"
                            control={control}
                            rules={{ required: "Purpose of Test is required" }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                // defaultValue='test prep'
                                error={Boolean(errors.purpose_of_test_id)}
                                //helperText={errors.service_name}
                              >
                                {purposeOfTest.map((purpose) => (
                                  <MenuItem key={purpose.id} value={purpose.id}>
                                    {purpose.purpose_name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                          {errors.purpose_of_test_id && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.purpose_of_test_id.message}
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Lead Class Experience
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="lead_test_experience_id"
                            control={control}
                            rules={{ required: "Field is required" }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.lead_test_experience_id)}
                                //helperText={errors.exam_status}
                                // onChange={(e) => handleExamStatusChange(e.target.value)}
                              >
                                {leadTestExperience.map((experience) => (
                                  <MenuItem
                                    key={experience.id}
                                    value={experience.id}
                                  >
                                    {experience.test_experience}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                          {errors.lead_test_experience_id && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.lead_test_experience_id.message}
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Exam Type Class
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="test_type_id"
                            control={control}
                            rules={{ required: "Exam Type is required" }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                value={selectedTestTypeName}
                                onChange={handleTestTypeChange}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.test_type_id)}
                                //helperText={errors.test_type}
                              >
                                {testtypes.map((testtype) => (
                                  <MenuItem
                                    key={testtype.id}
                                    value={testtype.id}
                                  >
                                    {testtype.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                          {errors.test_type_id && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.test_type_id.message}
                            </span>
                          )}
                        </Grid>

                        {/* Ielts Specification */}
                        {selectedTestTypeId?.name === "IELTS" && (
                          <>
                            <Grid item xs={6} md={4} lg={3}>
                              <Typography variant="h6">
                                Sub Test Type
                                <span
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    marginLeft: "4px",
                                    fontSize: "1em",
                                  }}
                                >
                                  *
                                </span>
                              </Typography>
                              <Controller
                                name="sub_test_type_id"
                                control={control}
                                rules={{
                                  required: "Sub Exam Type is required",
                                }}
                                render={({ field }) => (
                                  <TextField
                                    size="small"
                                    select
                                    {...field}
                                    InputProps={{
                                      style: { borderRadius: "12px" },
                                    }}
                                    fullWidth
                                    error={Boolean(errors.sub_test_type_id)}
                                    //helperText={errors.test_type}
                                  >
                                    {subTestType.map((subtesttype) => (
                                      <MenuItem
                                        key={subtesttype.id}
                                        value={subtesttype.id}
                                      >
                                        {subtesttype.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                              {errors.sub_test_type_id && (
                                <span
                                  style={{ color: "red", fontSize: "0.7rem" }}
                                >
                                  {errors.sub_test_type_id.message}
                                </span>
                              )}
                            </Grid>

                            <Grid item xs={6} md={4} lg={3}>
                              <Typography variant="h6">
                                Test Delivery Format
                                <span
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    marginLeft: "4px",
                                    fontSize: "1em",
                                  }}
                                >
                                  *
                                </span>
                              </Typography>
                              <Controller
                                name="test_delivery_format_id"
                                control={control}
                                rules={{
                                  required: "Test Delivery Format is required",
                                }}
                                render={({ field }) => (
                                  <TextField
                                    select
                                    {...field}
                                    InputProps={{
                                      style: { borderRadius: "12px" },
                                    }}
                                    fullWidth
                                    error={Boolean(
                                      errors.test_delivery_format_id
                                    )}
                                    //helperText={errors.test_type}
                                  >
                                    {subTestTypeFormat.map((subtesttype) => (
                                      <MenuItem
                                        key={subtesttype.id}
                                        value={subtesttype.id}
                                      >
                                        {subtesttype.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                              {errors.test_delivery_format_id && (
                                <span
                                  style={{ color: "red", fontSize: "0.7rem" }}
                                >
                                  {errors.test_delivery_format_id.message}
                                </span>
                              )}
                            </Grid>
                          </>
                        )}

                        {/* PTE Specification */}

                        {selectedTestTypeId?.name === "PTE" && (
                          <Grid item xs={6} md={4} lg={3}>
                            <Typography variant="h6">
                              Sub Test Type
                              <span
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  marginLeft: "4px",
                                  fontSize: "1em",
                                }}
                              >
                                *
                              </span>
                            </Typography>
                            <Controller
                              name="sub_test_type_id"
                              control={control}
                              rules={{ required: "Sub Exam Type is required" }}
                              render={({ field }) => (
                                <TextField
                                  select
                                  {...field}
                                  InputProps={{
                                    style: { borderRadius: "12px" },
                                  }}
                                  fullWidth
                                  error={Boolean(errors.sub_test_type_id)}
                                  //helperText={errors.test_type}
                                >
                                  {subTestType.map((subtesttype) => (
                                    <MenuItem
                                      key={subtesttype.id}
                                      value={subtesttype.id}
                                    >
                                      {subtesttype.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            />
                            {errors.sub_test_type_id && (
                              <span
                                style={{ color: "red", fontSize: "0.7rem" }}
                              >
                                {errors.sub_test_type_id.message}
                              </span>
                            )}
                          </Grid>
                        )}

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Preferred Delivery Mode
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="preferred_delivery_mode"
                            control={control}
                            rules={{ required: "Field is required" }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.preferred_delivery_mode)}
                                //helperText={errors.exam_status}
                                // onChange={(e) => handleExamStatusChange(e.target.value)}
                              >
                                <MenuItem value="online">Online</MenuItem>
                                <MenuItem value="offline">Offline</MenuItem>
                              </TextField>
                            )}
                          />
                          {errors.preferred_delivery_mode && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.preferred_delivery_mode.message}
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            First Country of Interest
                          </Typography>
                          <Controller
                            name="target_country_id"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.target_country)}
                                //helperText={errors.target_country}
                              >
                                {targetCountries.map((country) => (
                                  <MenuItem key={country.id} value={country.id}>
                                    {country.country_name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Lead Source Type
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="lead_source_type_id"
                            control={control}
                            rules={{ required: "Lead Source is required" }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.lead_source_type_id)}
                                /*onChange={e => {
                                            field.onChange(e);
                                            handleLeadSourceTypeChange(e.target.value);
                                          }}*/
                                value={selectedSourceType}
                                onChange={handleSourceTypeChange}
                                // helperText={errors.lead_source_type}
                              >
                                {leadSources.map((type) => (
                                  <MenuItem key={type.id} value={type.id}>
                                    {type.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                          {errors.lead_source_type_id && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.lead_source_type_id.message}
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Sub Lead Source Type
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="sub_lead_source_type_id"
                            control={control}
                            rules={{ required: "Sub Lead Source is required" }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.sub_lead_source_type_id)}
                              >
                                {subLeadSources.map((subSource) => (
                                  <MenuItem
                                    key={subSource.id}
                                    value={subSource.id}
                                  >
                                    {subSource.source_type}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                          {errors.sub_lead_source_type_id && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.sub_lead_source_type_id.message}
                            </span>
                          )}
                        </Grid>
                        {!selectedServices.some((s) => s.id === 2) && (
                          <Grid item xs={6} md={4} lg={3}>
                            <Typography variant="h6">
                              Assign To
                              <span
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  marginLeft: "4px",
                                  fontSize: "1em",
                                }}
                              >
                                *
                              </span>
                            </Typography>
                            <Controller
                              name="assign_to"
                              control={control}
                              rules={{ required: "Field is required" }}
                              render={({ field }) => (
                                <Autocomplete
                                  size="small"
                                  disablePortal
                                  disableClearable
                                  {...field}
                                  value={
                                    taskOwners.find(
                                      (owner) => owner.id === field.value
                                    ) || null
                                  }
                                  options={
                                    userRole === "advisor" ||
                                    userRole === "trainer"
                                      ? users
                                      : taskOwners
                                  }
                                  getOptionLabel={(option) =>
                                    `${
                                      option.first_name ? option.first_name : ""
                                    } ${
                                      option.last_name ? option.last_name : ""
                                    }`
                                  }
                                  onChange={(_, value) =>
                                    field.onChange(value ? value.id : null)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        style: { borderRadius: "12px" },
                                      }}
                                      fullWidth
                                      error={Boolean(errors.assign_to)}
                                      helperText={errors.assign_to?.message}
                                    />
                                  )}
                                />
                              )}
                            />
                            {errors.assign_to && (
                              <span
                                style={{ color: "red", fontSize: "0.7rem" }}
                              >
                                {errors.assign_to.message}
                              </span>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Item>

                    {/* Test Information */}
                    <Item>
                      <Typography id="test-info" variant="h4" fontWeight={700}>
                        Test Details Information
                      </Typography>
                      <Grid container spacing={3} marginTop="0.5px">
                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Exam Status
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="exam_status_id"
                            control={control}
                            rules={{ required: "Field is required" }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.exam_status_id)}
                                //helperText={errors.exam_status}
                                onChange={(e) =>
                                  handleExamStatusChange(e.target.value)
                                }
                              >
                                {examStatuses.map((status) => (
                                  <MenuItem key={status.id} value={status.id}>
                                    {status.exam_status_name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                          {errors.exam_status_id && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.exam_status_id.message}
                            </span>
                          )}
                        </Grid>

                        {examStatus === 1 && (
                          <>
                            {/*} <Grid item xs={6} md={4} lg={3}>
                                <InputLabel required style={{ color: 'black' }} >Exam Name</InputLabel>
                                    <Controller
                                    name='english_exam_name'
                                    control={control}
                                    
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                        error={Boolean(errors.exam_name)}
                                        //helperText={errors.exam_name}
                                        >
                                        <MenuItem value='Academic IELTS'>Academic IELTS</MenuItem>
                                        <MenuItem value='General IELTS'>General IELTS</MenuItem>
                                        <MenuItem value='GRE'>GRE</MenuItem>
                                        <MenuItem value='GMAT'>GMAT</MenuItem>
                                        <MenuItem value='PTE'>PTE</MenuItem>
                                        <MenuItem value='TOEFL'>TOEFL</MenuItem>
                                        </TextField>
                                    )}
                                    />
                                    </Grid>*/}

                            <Grid item xs={6} md={4} lg={3}>
                              <Typography variant="h6">Date of Exam</Typography>
                              <Controller
                                name="tentative_test_date"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size="small"
                                    {...field}
                                    fullWidth
                                    type="date"
                                    InputProps={{
                                      style: { borderRadius: "12px" },
                                      inputProps: {
                                        min: new Date()
                                          .toISOString()
                                          .split("T")[0],
                                      },
                                    }}
                                    error={Boolean(errors.date_of_exam)}
                                    //helperText={errors.date_of_exam && 'Date of Exam is required'}
                                  />
                                )}
                              />
                              {errors.date_of_exam && (
                                <span
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    top: 0,
                                    right: -8,
                                  }}
                                >
                                  *
                                </span>
                              )}
                            </Grid>

                            <Grid item xs={6} md={4} lg={3}>
                              <Typography variant="h6">
                                Assumptive Proficiency Level
                              </Typography>
                              <Controller
                                name="assumptive_proficiency_level_id"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size="small"
                                    select
                                    {...field}
                                    InputProps={{
                                      style: { borderRadius: "12px" },
                                    }}
                                    fullWidth
                                    error={Boolean(errors.proficiency_level)}
                                    //helperText={errors.proficiency_level}
                                  >
                                    {proficiencyLevel.map((status) => (
                                      <MenuItem
                                        key={status.id}
                                        value={status.id}
                                      >
                                        {status.english_proficiency}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                            </Grid>

                            <Grid item xs={6} md={4} lg={3}>
                              <Typography variant="h6">
                                Target Test Score
                              </Typography>
                              <Controller
                                name="target_score"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size="small"
                                    {...field}
                                    fullWidth
                                    type="number"
                                    InputProps={{
                                      style: { borderRadius: "12px" },
                                      inputProps: {
                                        min: 1,
                                        max: 9,
                                      },
                                    }}
                                    error={Boolean(errors.target_score)}
                                    //helperText={errors.target_score }
                                  />
                                )}
                              />
                              {errors.target_score && (
                                <span
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    top: 0,
                                    right: -8,
                                  }}
                                >
                                  *
                                </span>
                              )}
                            </Grid>
                          </>
                        )}

                        {examStatus === 2 && (
                          <>
                            <Grid item xs={6} md={4} lg={3}>
                              <Typography variant="h6">
                                Do you want to book the test?
                              </Typography>
                              <Controller
                                name="test_taken"
                                control={control}
                                rules={{ required: "Field is required" }}
                                render={({ field }) => (
                                  <TextField
                                    size="small"
                                    select
                                    {...field}
                                    InputProps={{
                                      style: { borderRadius: "12px" },
                                    }}
                                    fullWidth
                                    error={Boolean(errors.test_taken)}
                                    //helperText={errors.test_booking}
                                    onChange={(e) =>
                                      handleTestBookingStatusChange(
                                        e.target.value
                                      )
                                    }
                                  >
                                    <MenuItem value="yes">Yes</MenuItem>
                                    <MenuItem value="no">No</MenuItem>
                                  </TextField>
                                )}
                              />
                            </Grid>

                            {testBooking === "yes" && (
                              <>
                                {/*}  <Grid item xs={6} md={4} lg={3}>
                                <InputLabel required style={{ color: 'black' }} >Specify Exam Name</InputLabel>
                                    <Controller
                                    name='english_exam_name'
                                    control={control}
                                    
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                        error={Boolean(errors.exam_name)}
                                        //helperText={errors.exam_name}
                                        >
                                        <MenuItem value='Academic IELTS'>Academic IELTS</MenuItem>
                                        <MenuItem value='General IELTS'>General IELTS</MenuItem>
                                        <MenuItem value='GRE'>GRE</MenuItem>
                                        <MenuItem value='GMAT'>GMAT</MenuItem>
                                        <MenuItem value='PTE'>PTE</MenuItem>
                                        <MenuItem value='TOEFL'>TOEFL</MenuItem>
                                        </TextField>
                                    )}
                                    />
                                    </Grid>*/}

                                <Grid item xs={6} md={4} lg={3}>
                                  <Typography variant="h6">
                                    Tentative Date of Exam
                                  </Typography>
                                  <Controller
                                    name="tentative_test_date"
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        size="small"
                                        {...field}
                                        fullWidth
                                        type="date"
                                        InputProps={{
                                          style: { borderRadius: "12px" },
                                          inputProps: {
                                            min: new Date()
                                              .toISOString()
                                              .split("T")[0],
                                          },
                                        }}
                                        error={Boolean(errors.test_date)}
                                        //helperText={errors.date_of_exam && 'Date of Exam is required'}
                                      />
                                    )}
                                  />
                                  {errors.date_of_exam && (
                                    <span
                                      style={{
                                        color: "red",
                                        position: "absolute",
                                        top: 0,
                                        right: -8,
                                      }}
                                    >
                                      *
                                    </span>
                                  )}
                                </Grid>

                                <Grid item xs={6} md={4} lg={3}>
                                  <Typography variant="h6">
                                    Assumptive Proficiency Level
                                  </Typography>
                                  <Controller
                                    name="assumptive_proficiency_level_id"
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        size="small"
                                        select
                                        {...field}
                                        InputProps={{
                                          style: { borderRadius: "12px" },
                                        }}
                                        fullWidth
                                        error={Boolean(
                                          errors.english_proficiency_level
                                        )}
                                        // helperText={errors.proficiency_level}
                                      >
                                        {proficiencyLevel.map((status) => (
                                          <MenuItem
                                            key={status.id}
                                            value={status.id}
                                          >
                                            {status.english_proficiency}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={6} md={4} lg={3}>
                                  <Typography variant="h6">
                                    Target Test Score
                                  </Typography>
                                  <Controller
                                    name="target_score"
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        type="number"
                                        InputProps={{
                                          style: { borderRadius: "12px" },
                                        }}
                                        error={Boolean(errors.target_score)}
                                        //helperText={errors.target_score }
                                      />
                                    )}
                                  />
                                  {errors.target_score && (
                                    <span
                                      style={{
                                        color: "red",
                                        position: "absolute",
                                        top: 0,
                                        right: -8,
                                      }}
                                    >
                                      *
                                    </span>
                                  )}
                                </Grid>
                              </>
                            )}

                            {testBooking === "no" && (
                              <Grid item xs={6} md={4} lg={3}>
                                <Typography variant="h6">
                                  Assumptive Proficiency Level
                                </Typography>
                                <Controller
                                  name="assumptive_proficiency_level_id"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      size="small"
                                      select
                                      {...field}
                                      InputProps={{
                                        style: { borderRadius: "12px" },
                                      }}
                                      fullWidth
                                      error={Boolean(
                                        errors.english_proficiency_level
                                      )}
                                      // helperText={errors.proficiency_level}
                                    >
                                      {proficiencyLevel.map((status) => (
                                        <MenuItem
                                          key={status.id}
                                          value={status.id}
                                        >
                                          {status.english_proficiency}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  )}
                                />
                              </Grid>
                            )}
                          </>
                        )}

                        {examStatus === 3 && (
                          <>
                            {/*} <Grid item xs={6} md={4} lg={3}>
                                         <InputLabel required style={{ color: 'black' }} >Specify Exam Name</InputLabel>
                                             <Controller
                                             name='english_exam_name'
                                             control={control}
                                             
                                             render={({ field }) => (
                                                 <TextField
                                                 select
                                                 {...field}
                                                 InputProps={{
                                                     style: { borderRadius: '12px' },
                                                 }}
                                                 fullWidth
                                                 error={Boolean(errors.exam_name)}
                                                 //helperText={errors.exam_name}
                                                 >
                                                 <MenuItem value='Academic IELTS'>Academic IELTS</MenuItem>
                                                 <MenuItem value='General IELTS'>General IELTS</MenuItem>
                                                 <MenuItem value='GRE'>GRE</MenuItem>
                                                 <MenuItem value='GMAT'>GMAT</MenuItem>
                                                 <MenuItem value='PTE'>PTE</MenuItem>
                                                 <MenuItem value='TOEFL'>TOEFL</MenuItem>
                                                 </TextField>
                                             )}
                                             />
                                                </Grid>*/}

                            <Grid item xs={6} md={4} lg={3}>
                              <Typography variant="h6">Date of Exam</Typography>
                              <Controller
                                name="tentative_test_date"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    type="date"
                                    InputProps={{
                                      style: { borderRadius: "12px" },
                                      inputProps: {
                                        min: new Date()
                                          .toISOString()
                                          .split("T")[0],
                                      },
                                    }}
                                    error={Boolean(errors.test_date)}
                                    // helperText={errors.date_of_exam && 'Date of Exam is required'}
                                  />
                                )}
                              />
                              {errors.date_of_exam && (
                                <span
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    top: 0,
                                    right: -8,
                                  }}
                                >
                                  *
                                </span>
                              )}
                            </Grid>

                            <Grid item xs={6} md={4} lg={3}>
                              <Typography variant="h6">
                                Assumptive Proficiency Level
                              </Typography>
                              <Controller
                                name="assumptive_proficiency_level_id"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size="small"
                                    select
                                    {...field}
                                    InputProps={{
                                      style: { borderRadius: "12px" },
                                    }}
                                    fullWidth
                                    error={Boolean(errors.proficiency_level)}
                                    // helperText={errors.proficiency_level}
                                  >
                                    {proficiencyLevel.map((status) => (
                                      <MenuItem
                                        key={status.id}
                                        value={status.id}
                                      >
                                        {status.english_proficiency}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                            </Grid>

                            <Grid item xs={6} md={4} lg={3}>
                              <Typography variant="h6">Test Score</Typography>
                              <Controller
                                name="target_score"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size="small"
                                    {...field}
                                    fullWidth
                                    type="number"
                                    InputProps={{
                                      style: { borderRadius: "12px" },
                                    }}
                                    error={Boolean(errors.target_score)}
                                    //  helperText={errors.test_score }
                                  />
                                )}
                              />
                              {errors.test_score && (
                                <span
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    top: 0,
                                    right: -8,
                                  }}
                                >
                                  *
                                </span>
                              )}
                            </Grid>
                          </>
                        )}

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">Ready to Enroll?</Typography>
                          <Controller
                            name="ready_to_enroll"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.ready_to_enroll)}
                                // helperText={errors.ready_to_enroll}
                              >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                              </TextField>
                            )}
                          />
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Level of Interest
                          </Typography>
                          <Controller
                            name="level_of_interest_id"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.level_of_interest_id)}
                                // helperText={errors.proficiency_level_of_interest}
                              >
                                {levelOfInterest.map((status) => (
                                  <MenuItem key={status.id} value={status.id}>
                                    {status.level_of_interest}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Prospective Level
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="prospective_level_id"
                            control={control}
                            rules={{ required: "Field is required" }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.prospective_level_id)}
                                // helperText={errors.prospective_level}
                              >
                                {prospectiveLevel.map((status) => (
                                  <MenuItem key={status.id} value={status.id}>
                                    {status.prospective_level}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                          {errors.prospective_level_id && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.prospective_level_id.message}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </Item>

                    {/* Qualifiers */}

                    {/* Add Notes*/}
                    <Item>
                      <Typography id="add-notes" variant="h4" fontWeight={700}>
                        Add Notes
                      </Typography>
                      <Grid item xs={12} md={12} lg={12} marginTop="1px">
                        <Typography variant="h6">Add a Note</Typography>
                        <Controller
                          name="notes"
                          control={control}
                          rules={{ required: "Note is required" }}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              {...field}
                              multiline
                              rows={5}
                              fullWidth
                              InputProps={{
                                style: { borderRadius: "12px" },
                              }}
                              error={Boolean(errors.notes)}
                              // helperText={errors.notes && 'Notes is required'}
                            />
                          )}
                        />
                        {errors.notes && (
                          <span style={{ color: "red", fontSize: "0.7rem" }}>
                            {errors.notes.message}
                          </span>
                        )}
                      </Grid>
                    </Item>

                    {/* Add Task */}
                    <Item>
                      <Typography id="add-task" variant="h4" fontWeight={700}>
                        Add Task
                      </Typography>
                      <Grid container spacing={3} marginTop="0.5px">
                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Type of Task
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="type_of_task"
                            control={control}
                            rules={{ required: "Field is required" }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                select
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.type_of_task)}
                                //helperText={errors.type_of_task}
                              >
                                {taskItems.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name_of_task_type}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                          {errors.type_of_task && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.type_of_task.message}
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Due Date
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="task_due_date"
                            control={control}
                            rules={{ required: "Field is required" }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="date"
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                  inputProps: {
                                    min: new Date().toISOString().split("T")[0],
                                  },
                                }}
                                fullWidth
                                error={Boolean(errors.task_due_date)}
                                // helperText={errors.task_due_date}
                              ></TextField>
                            )}
                          />
                          {errors.task_due_date && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.task_due_date.message}
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={6} md={4} lg={3}>
                          <Typography variant="h6">
                            Due Time
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                                marginLeft: "4px",
                                fontSize: "1em",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Controller
                            name="task_due_time"
                            control={control}
                            rules={{ required: "Field is required" }}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="time"
                                {...field}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                }}
                                fullWidth
                                error={Boolean(errors.task_due_time)}
                                //helperText={errors.task_due_time}
                              ></TextField>
                            )}
                          />
                          {errors.task_due_time && (
                            <span style={{ color: "red", fontSize: "0.7rem" }}>
                              {errors.task_due_time.message}
                            </span>
                          )}
                        </Grid>

                        {!selectedServices.some((s) => s.id === 2) && (
                          <Grid item xs={6} md={6} lg={3}>
                            <Typography variant="h6">Task Owner</Typography>
                            <Controller
                              name="task_owner"
                              control={control}
                              // defaultValue={parseInt(userId) || null}
                              rules={{ required: "Field is required" }}
                              render={({ field }) => (
                                <Autocomplete
                                  size="small"
                                  {...field}
                                  disablePortal
                                  disableClearable
                                  value={
                                    taskOwners.find(
                                      (owner) => owner.id === field.value
                                    ) || null
                                  }
                                  options={taskOwners}
                                  getOptionLabel={(option) =>
                                    `${
                                      option.first_name ? option.first_name : ""
                                    } ${
                                      option.last_name ? option.last_name : ""
                                    }`
                                  }
                                  onChange={(_, value) =>
                                    field.onChange(value ? value.id : null)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        style: { borderRadius: "12px" },
                                      }}
                                      fullWidth
                                      error={Boolean(errors.task_owner)}
                                      helperText={errors.task_owner?.message}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Item>
                  </Stack>
                </Box>
              </Grid>

              <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
              />

              {/*  <Grid item xs={12} md={12} lg={3}>
            <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={12} lg={12}>
            <ScrollLink to='personal-info'>
          <Chip
            label="Personal Information"
            
            sx={{ width: '100%', fontSize: '1.2rem', marginBottom: '10px' }}
          />
          </ScrollLink>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
        <ScrollLink to='contact-info'>
          <Chip
            label="Contact Information"
            
            sx={{ width: '100%', fontSize: '1.2rem', marginBottom: '10px' }}
          />
          </ScrollLink>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
        <ScrollLink to="mandatory-info" >
          <Chip
            label="Mandatory Information"
            
            sx={{ width: '100%', fontSize: '1.2rem', marginBottom: '10px' }}
          />
          </ScrollLink>
        </Grid>

       
        <Grid item xs={12} md={12} lg={12}>
        <ScrollLink to='test-info'>
          <Chip
            label="Test Details"
            
            sx={{ width: '100%', fontSize: '1.2rem', marginBottom: '10px' }}
          />
          </ScrollLink>
        </Grid>
        

        
        <Grid item xs={12} md={12} lg={12}>
        <ScrollLink to='qualifiers'>
          <Chip
            id='qualifiers'
            label="Qualifiers"
            
            sx={{ width: '100%', fontSize: '1.2rem', marginBottom: '10px' }}
          />
          </ScrollLink>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
        <ScrollLink to='course-info' smooth={true} duration={500}>
          <Chip
            label="Course Information"
           
            sx={{ width: '100%', fontSize: '1.2rem', marginBottom: '10px' }}
          />
          </ScrollLink>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
        <ScrollLink to='add-notes' smooth={true} duration={500}>
          <Chip
            label="Add Notes"
           
            sx={{ width: '100%', fontSize: '1.2rem', marginBottom: '10px' }}
          />
          </ScrollLink>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
        <ScrollLink to='add-task' smooth={true} duration={500}>
          <Chip
            label="Add Task"
           
            sx={{ width: '100%', fontSize: '1.2rem', marginBottom: '10px' }}
          />
          </ScrollLink>
        </Grid>
      </Grid>
      </Grid>
    </Grid>
    </Box>

                                            )} */}
            </Grid>
          </Grid>
        </Grid>
        <Dialog open={showNoEnquiryPopup}>
          <DialogTitle>No Enquiry Found</DialogTitle>
          <DialogContent>
            No enquiry exists with the provided details.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowNoEnquiryPopup(false)}>OK</Button>
          </DialogActions>
        </Dialog>
        <Backdrop open={searching} style={{ zIndex: 999 }}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Backdrop open={isSavingLoading} style={{ zIndex: 9999 }}>
          <Typography
            variant="h4"
            color="error"
            fontWeight={800}
            marginRight={2}
          >
            Saving Details
          </Typography>
          <CircularProgress color="primary" />
        </Backdrop>
      </form>
    </div>
  );
}

export default AddEnquiryNew;

/* 
    <Item>
                                    <div id='qualifiers'>
                                <Typography id='qualifiers' variant='h6' fontWeight={700}>Qualifiers</Typography>
                                        <Grid container spacing={4}>
                                        <Grid item xs={6} md={4} lg={3}>
                                            <InputLabel style={{ color: 'black' }}>
                                            Target Intake
                                            </InputLabel>
                                            <Controller
                                                name='target_intake'
                                                control={control}
                                                render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='date'
                                                    InputProps={{
                                                    style: { borderRadius: '12px' },
                                                    }}
                                                    error={Boolean(errors.target_intake)}
                                                    helperText={errors.target_intake && 'Date of Exam is required'}
                                                />
                                                )}
                                            />
                                            {errors.target_intake && (
                                                <span style={{ color: 'red', position: 'absolute', top: 0, right: -8 }}>
                                                *
                                                </span>
                                            )}
                                            </Grid>

                                            <Grid item xs={6} md={4} lg={3}>
                                         <InputLabel style={{ color: 'black' }}>
                                         Budget(INR)
                                         </InputLabel>
                                         <Controller
                                             name='budget'
                                             control={control}
                                             render={({ field }) => (
                                             <TextField
                                                 {...field}
                                                 fullWidth
                                                 type='number'
                                                 InputProps={{
                                                 style: { borderRadius: '12px' },
                                                 }}
                                                 error={Boolean(errors.budget)}
                                                 helperText={errors.budget }
                                             />
                                             )}
                                         />
                                         {errors.budget && (
                                             <span style={{ color: 'red', position: 'absolute', top: 0, right: -8 }}>
                                             *
                                             </span>
                                         )}
                                         </Grid>

                                            <Grid item xs={6} md={4} lg={3}>
                                            <InputLabel style={{ color: 'black' }} >Ready to Enroll?</InputLabel>
                                            <Controller
                                            name='ready_to_enroll'
                                            control={control}
                                            
                                            render={({ field }) => (
                                                <TextField
                                                select
                                                {...field}
                                                InputProps={{
                                                    style: { borderRadius: '12px' },
                                                }}
                                                fullWidth
                                                error={Boolean(errors.ready_to_enroll)}
                                                helperText={errors.ready_to_enroll}
                                                >
                                                <MenuItem value="yes">Yes</MenuItem>
                                                <MenuItem value="no">No</MenuItem>
                                                </TextField>
                                            )}
                                            />
                                            </Grid>

                                            <Grid item xs={6} md={4} lg={3}>
                                <InputLabel style={{ color: 'black' }} >English Proficiency Level</InputLabel>
                                    <Controller
                                    name='english_proficiency'
                                    control={control}
                                    
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                        error={Boolean(errors.proficiency_level)}
                                        helperText={errors.proficiency_level}
                                        >
                                        <MenuItem value='expert'>Expert</MenuItem>
                                        <MenuItem value='very good'>Very Good</MenuItem>
                                        <MenuItem value='ggod'>Good</MenuItem>
                                        <MenuItem value='competent'>Competent</MenuItem>
                                        <MenuItem value='modest'>Modest</MenuItem>
                                        <MenuItem value='limited'>Limited</MenuItem>
                                        <MenuItem value='extremely limited'>Extremely Limited</MenuItem>
                                        </TextField>
                                    )}
                                    />
                            </Grid>

                            <Grid item xs={6} md={4} lg={3}>
                                <InputLabel style={{ color: 'black' }} >Level of Interest</InputLabel>
                                    <Controller
                                    name='level_of_interest'
                                    control={control}
                                    
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                        error={Boolean(errors.level_of_interest)}
                                        helperText={errors.proficiency_level_of_interest}
                                        >
                                        <MenuItem value='high'>High</MenuItem>
                                        <MenuItem value='medium'>Medium</MenuItem>
                                        <MenuItem value='low'>Low</MenuItem>
                                        <MenuItem value='not interested'>Not Interested</MenuItem>
                                        </TextField>
                                    )}
                                    />
                            </Grid>

                            <Grid item xs={6} md={4} lg={3}>
                                <InputLabel required style={{ color: 'black' }}>Prospective Level</InputLabel>
                                    <Controller
                                    name='prospective_level'
                                    control={control}
                                    rules={{ required: "Field is required" }}
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                        error={Boolean(errors.prospective_level)}
                                        helperText={errors.prospective_level}
                                        >
                                        <MenuItem value='super prospective'>Super Prospective</MenuItem>
                                        <MenuItem value='hot'>Hot</MenuItem>
                                        <MenuItem value='contact in future'>Contact in Future</MenuItem>
                                        <MenuItem value='competent'>Competent</MenuItem>
                                        <MenuItem value='warm'>Warm</MenuItem>
                                        <MenuItem value='recycle'>Recycle</MenuItem>
                                        <MenuItem value='new lead'>New Lead</MenuItem>
                                        </TextField>
                                    )}
                                    />
                            </Grid>
                                        </Grid>
                                        </div>
                                      </Item>

                                      {/*  Class Choice
                                      <Item>
                                      <Typography id='course-info' variant='h6' fontWeight={700}>Course Information</Typography>
                                      <Box>
                                        <Button variant='outlined'>Class Search</Button>
                                    </Box>
                                        <Grid container spacing={4}>
                                        <Grid item xs={6} md={4} lg={3}>
                                <InputLabel required style={{ color: 'black' }} >Course Name</InputLabel>
                                    <Controller
                                    name='course_name'
                                    control={control}
                                    
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                        error={Boolean(errors.course_name)}
                                        helperText={errors.course_name}
                                        >
                                        <MenuItem value='Academic IELTS'>Academic IELTS</MenuItem>
                                        <MenuItem value='General IELTS'>General IELTS</MenuItem>
                                        </TextField>
                                    )}
                                    />
                                </Grid>
                                       

                                        <Grid item xs={6} md={4} lg={3}>
                                    <InputLabel style={{ color: 'black' }} >Delivery Mode</InputLabel>
                                    <Controller
                                    name='delivery_mode'
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                        error={Boolean(errors.delivery_mode)}
                                        helperText={errors.delivery_mode}
                                        onChange={(e) => handleDeliveryModeStatusChange(e.target.value)}
                                        >
                                         <MenuItem value="online">Online</MenuItem>
                                         <MenuItem value="offline">Offline</MenuItem>
                                         <MenuItem value="both">Both</MenuItem>
                                        </TextField>
                                    )}
                                    />
                                    </Grid>

                                    {deliveryMode === 'both' && (
                                        <Grid item xs={6} md={4} lg={3}>
                                        <InputLabel required style={{ color: 'black' }} >Branch Name</InputLabel>
                                            <Controller
                                            name='branch_name'
                                            control={control}
                                            
                                            render={({ field }) => (
                                                <TextField
                                                select
                                                {...field}
                                                InputProps={{
                                                    style: { borderRadius: '12px' },
                                                }}
                                                fullWidth
                                                error={Boolean(errors.branch_name)}
                                                helperText={errors.branch_name}
                                                >
                                                <MenuItem value='dehli'>Dehli</MenuItem>
                                                <MenuItem value='mumbai'>Mumbai</MenuItem>
                                                <MenuItem value='bangalore'>Bangalore</MenuItem>
                                                <MenuItem value='chandigarh'>Chandigarh</MenuItem>
                                                <MenuItem value='ludhiana'>Ludhiana</MenuItem>
                                                </TextField>
                                            )}
                                            />
                                        </Grid>
                                    )}

                                {deliveryMode === 'offline' && (
                                        <Grid item xs={6} md={4} lg={3}>
                                        <InputLabel required style={{ color: 'black' }} >Branch Name</InputLabel>
                                            <Controller
                                            name='branch_name'
                                            control={control}
                                            
                                            render={({ field }) => (
                                                <TextField
                                                select
                                                {...field}
                                                InputProps={{
                                                    style: { borderRadius: '12px' },
                                                }}
                                                fullWidth
                                                error={Boolean(errors.branch_name)}
                                                helperText={errors.branch_name}
                                                >
                                                <MenuItem value='dehli'>Dehli</MenuItem>
                                                <MenuItem value='mumbai'>Mumbai</MenuItem>
                                                <MenuItem value='bangalore'>Bangalore</MenuItem>
                                                <MenuItem value='chandigarh'>Chandigarh</MenuItem>
                                                <MenuItem value='ludhiana'>Ludhiana</MenuItem>
                                                </TextField>
                                            )}
                                            />
                                        </Grid>
                                    )}
                                     </Grid>
                                  
                                      </Item>
*/
