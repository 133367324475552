import React, { useEffect, useState } from "react";
import MainCard from "../../components/Maincard";
import {
  Box,
  Button,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  Chip,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../utils/token";
import HeadingCard from "../../newTestPrep/newComponent/NewCardsComp/HeadingCard";
import SearchButton from "../../newTestPrep/newComponent/NewButtonComp/SearchButton";
import ClearButtonComp from "../../newTestPrep/newComponent/NewButtonComp/ResetButtonComp";
import CustomNoRowsOverlay from "../../newTestPrep/newComponent/TableCustomeComp/CustomNoRowsOverlay";
import CustomAutocompleteComp from "../../newTestPrep/newComponent/AutocompleteComp";
import StyledTableContainer from "../../newTestPrep/newComponent/TableContainer";

export default function AdvisorReport() {
  const navigate = useNavigate();

  const getTokenOrLogout = useGetTokenOrLogout();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allbranchList = [{ branch_name: "Select All" }, ...branchlist];
  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  const handleSearch = async () => {
    const token = getTokenOrLogout();
    console.log("token", token);
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    try {
      setLoading(true);
      const response = await fetch(`${API_ENDPOINT}route2.0/advisorbybranch`, {
        method: "POST",
        headers,
        body: JSON.stringify({ branch_id: branch.map((i) => i.id) }),
      });

      if (response.status === 200) {
        const data = await response.json();
        // Handle successful response
        setData(data);
        console.log(data);
        setLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/page500");
    }
  };

  const handleclick = (row) => {
    const url = `/advisorrevenue/${row.user_id}`;
    const newTab = window.open(url, "_blank");
    if (newTab) {
      newTab.focus();
    }
  };

  const handleReset = () => {
    setData([]);
    setBranch([]);
  };
  const columns = [
    {
      field: "name",
      headerName: "Name of Advisor",
      flex: 2,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
      renderCell: (params) => (
        <>
          <Typography
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={() => handleclick(params.row)}
          >
            {params.row?.name}
          </Typography>
        </>
      ),
    },

    {
      field: "branch",
      headerName: " Branch",
      flex: 1,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
      renderCell: (params) => (
        <>
          <Typography>{params.row?.branch}</Typography>
        </>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2.5} lg={2}>
          <HeadingCard label="Advisor Report" />
        </Grid>
        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <FormControl sx={{ mb: 1, width: "100%" }}>
                  <Typography variant="h6"> Center / Branch</Typography>

                  <CustomAutocompleteComp
                    size="small"
                    value={branch}
                    multiple
                    onChange={handelbranchchange}
                    options={allbranchList}
                    getOptionLabel={(option) => option.branch_name}
                    tooltipContent={(option) => option.branch_name}
                    placeholder="Please Select Branches"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4} lg={6} mt={2} container gap={1}>
                <SearchButton
                  size="small"
                  showIcon={true}
                  onClick={handleSearch}
                />

                <ClearButtonComp size="small" onClick={handleReset} />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>

        <Grid item xs={12}>
          <MainCard>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : data?.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No data available</Typography>
              </Grid>
            ) : (
              <StyledTableContainer component={Paper} borderLeft={true}>
                <Table
                  sx={{ minWidth: 650, tableLayout: "fixed" }}
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name Of Advisor</TableCell>
                      <TableCell>Branch</TableCell>
                    </TableRow>
                  </TableHead>

                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableBody>
                        <TableRow
                          key={row.name}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "inherit",
                          }}
                        >
                          <TableCell sx={{ borderLeft: "0.1px solid #C3C3C3" }}>
                            <Box
                              color="#2f96f5"
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleclick(row)}
                            >
                              {" "}
                              {row?.name}
                            </Box>
                          </TableCell>
                          <TableCell>{row?.branch}</TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </StyledTableContainer>
            )}
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
}
