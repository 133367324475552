import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { fDate } from '../../../utils/formatTime';
import { Box, Chip, Button, CircularProgress } from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
//import EnquiriesWidget from './EnquiriesWidget';
import { AddCircle } from '@mui/icons-material';
import '../../advisor/leadpages/enquiryDataGrid.css'
import { Link, useNavigate } from 'react-router-dom';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
 
const AdminDemos = () => {
    //const { advisorId } = useSelector((state) => state.user)
    const navigate = useNavigate()
    const [originalRows, setOriginalRows] = useState([]);
    const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const trainerId = localStorage.getItem('trainerId');
  //const [filteredEnquiries, setFilteredEnquiries] = useState(enquiriesData);
  const [isEvaluationFormOpen, setEvaluationFormOpen] = useState(false);
  const [isCompleted, setCompleted] = useState(false);

  const handleOpenEvaluationForm = () => {
    setEvaluationFormOpen(true);
  };

  const handleCloseEvaluationForm = () => {
    setEvaluationFormOpen(false);
  };

  const handleChipClick = (status) => {
    if (status === 'All') {
      setRows(originalRows);
    } else {
      const filtered = originalRows.filter((enquiry) => enquiry.status === status);
      setRows(filtered);
    }
  };

  const handleViewMore = (row) => {
    // Handle the "View More" action here
    

    navigate(`/admin/enquirydetails/${row.student_id}`, {
        state: { enquiryDetails: row}
    });
  };

  const handleFormSubmit = (postData) => {
    


    const apiUrl = 'https://example.com/api/your-endpoint';
      // Simulate a POST request using axios (this is for demonstration purposes only)
      fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', 
          },
          body: JSON.stringify(postData), 
      })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json(); 
          })
          .then((data) => {
           
         
            setCompleted(true);
            alert('Demo marked complete successfully');
            //onClose();
          })
          .catch((error) => {
            
            console.error('POST request error', error);
          })
      
    handleCloseEvaluationForm();
  };

  const columns = [
    {
        field: 'student_name',
        headerName: 'Enquiry Name',
        width: 200,
        renderCell: (params) => (
          <div>
            <Button size='small' onClick={() => handleViewMore(params.row)}>
            <PersonIcon /> {params.row.student_name}
            </Button>
          </div>
        ),
      },
  {
    field: 'trainer_id.',
    headerName: 'Trainer',
    width: 150,
    headerClassName: 'bold-header',
    renderCell: (params) => (
      <div>
        {params.row.trainer_id.full_name}
      </div>
    ),
  },
  {
    field: 'available_demo_date',
    headerName: 'Demo Date',
    width: 100,
   // renderCell: (params) => <div>{fDate(params.value)}</div>,
  },
  {
    field: 'slot',
    headerName: 'Demo time slot ',
    width: 100,
    //renderCell: (params) => <div>{fDate(params.value)}</div>,
  },
  {
    field: 'test_type_id',
    headerName: 'Test Type',
    width: 100,
    renderCell: (params) => (
      <div>
        {params.row.test_type_id.name}
      </div>
    ),
  },
  {
    field: 'delivery_mode',
    headerName: 'Delivery Mode',
    width: 100,
  },
  {
    field: 'branch_location',
    headerName: 'Branch',
    width: 150,
  },
  {
    field: 'mark_demo_complete',
    headerName: 'Action',
    width: 200,
    renderCell: (params) => (
      <div>
        <Button
          size='small'
          //onClick={() => handleOpenEvaluationPanel(params.row)}
          onClick={handleOpenEvaluationForm}
          disabled={isCompleted}
        >
           {isCompleted ? 'Completed' : 'Mark Complete'}
        </Button>
      </div>
    ),
  },
    
];

      const boldHeaderCellStyle = {
        fontWeight: 'bold', // Apply bold font weight to header cells
      };

      
      useEffect(() => {
        setIsLoading(true);

        fetch(`${API_ENDPOINT}admindashboard/getalldemo`)
          .then((response) => response.json())
          .then((data) => {
    
            setOriginalRows(data);
            setRows(data);
            setIsLoading(false)
          
          })
          .catch((error) => {
            console.error('Error fetching student data:', error);
            setIsLoading(false)
          })
          .finally(() => {
            setIsLoading(false); 
          });
      }, []); 
    
      if (isLoading) {
        return (
          <Box display="flex" justifyContent="center" alignItems="center" height="400px">
            <CircularProgress color="primary" />
          </Box>
        );
      }
      
      
  return (
    <div style={{  width: '100%', height: 400 }}>
        <Box mt={2} marginBottom={2}>
        {/* <EnquiriesWidget enquiriesData={rows} /> */}
        <Chip label={`Total Demos: ${originalRows.length}`} onClick={() => handleChipClick('All')} />
        <Chip label={`New Demos: ${rows.filter((demo) => demo.status === 'new lead').length}`} color="info" onClick={() => handleChipClick('new lead')} />
        <Chip label={`Demos Scheduled Today: ${rows.filter((demo) => demo.status === 'in progress').length}`} color="secondary" onClick={() => handleChipClick('in progress')} />
      </Box>

      <DataGrid
        rows={rows} 
        columns={columns}
        autoHeight
        getRowId={(row) => row.id}
        pageSize={5}
        pagination
        components={{
          Toolbar: GridToolbar,
        }}
      />

    
  </div>
  );
};

export default AdminDemos;