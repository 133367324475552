import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/ui-component/card/MainCard";
import { DataGrid } from "@mui/x-data-grid";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import CampaignForm from "./CampaignForm";
import CampainsFilter from "./CampainsFilter";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CampaignEditForm from "./CampaignEditForm";
import CampaignInfo from "./CampaignInfo";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import AllBranches from "./AllBranches";
import CustomPopup from "../../../components/CustomPopup";
import { useGetTokenOrLogout } from "../../../utils/token";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";
import SearchTextFieldComp from "../../newComponent/newtextfieldComp/SearchTextFieldComp";
import FilterComp from "../../newComponent/iconsComp/FilterComp";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CustomNoRowsOverlay from "../../newComponent/TableCustomeComp/CustomNoRowsOverlay";
import EditIconcomp from "../../newComponent/iconsComp/EditIconcomp";
import VeiwIcon from "../../newComponent/iconsComp/VeiwIcon";

export default function Campaign() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [sortedCampaignData, setSortedCampaignData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openeditpopup, setOpeneditpopup] = useState(false);
  const [infoPopup, setInfopopup] = useState(false);
  const [campaigndata, setCapaigndata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [selectedid, setSelectedid] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [openFilter, setOpenFilter] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [branchPopup, setBranchpopup] = useState(false);
  const [selectedbranhes, setseletedbranches] = useState([]);
  const [gridKey, setGridKey] = useState(0);

  const openbranchpopup = (branches) => {
    setBranchpopup(true);
    setseletedbranches(branches);
  };

  const closeBranchpopup = () => {
    setBranchpopup(false);
    setseletedbranches([]);
    // setGridKey((prevKey) => prevKey + 1)
  };

  const handleChangeNmae = (e) => {
    const value = e.target.value;
    setNameFilter(value);

    // Filter data immediately
    let filteredData = sortedCampaignData.filter((campaign) =>
      campaign.campaign_name.toLowerCase().includes(value.toLowerCase())
    );

    setCapaigndata(filteredData);
  };
  const handleToggle = () => {
    setOpenFilter(!openFilter);
  };
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleApplyFilter = (criteria) => {
    setFilterCriteria(criteria);
  };
  const handleResetFilter = () => {
    setFilterCriteria(null);
    setCapaigndata(sortedCampaignData);
  };
  const handelopen = () => {
    setOpen(true);
  };

  const handeelclose = () => {
    setOpen(false);
  };

  const handelviewmore = (id) => {
    setSelectedid(id);

    setInfopopup(true);
  };

  const handelcloseview = () => {
    setInfopopup(false);
  };

  const handelopenedit = (row) => {
    setSelectedCampaign(row);

    setOpeneditpopup(true);
  };

  const handelcloseedit = () => {
    setOpeneditpopup(false);
  };
  useEffect(() => {
    setLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}campaign`, { headers });
        if (response.ok) {
          const data = await response.json();

          if (data.length === 0) {
            showSnackbar("No data Available", "error");
            setLoading(false);
          } else {
            const sortedData = data.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );

            setCapaigndata(sortedData);
            setSortedCampaignData(sortedData);
            setLoading(false);
          }
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/page500");
      }
    };

    fetchData();
  }, []);

  
  const columns = [
    {
      headerName: "Action",
      width: 100,
      headerClassName: "header-cell",
      headerAlign:"center",
      align:"center",
      renderCell: (params) => (
        <Box display='flex' gap={2}>
          <EditIconcomp
            tooltipTitle="Edit"
            fontSize='0.9rem'
            onClick={() => handelopenedit(params?.row)}
          />

          <VeiwIcon
            tooltipTitle="View"
             fontSize='0.9rem'
            onClick={() => handelviewmore(params.row?.id)}
          />
        </Box>
      ),
    },
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      headerClassName: "header-cell",
      width: 450,
      renderCell: (params) => (
        <Typography>{params.row.campaign_name}</Typography>
      ),
    },

    {
      field: "campaign_type_id",
      headerName: "Campaign Type",
      headerClassName: "header-cell",
      width: 100,
      renderCell: (params) => (
        <Typography>{params.row.campaign_type_id?.name}</Typography>
      ),
    },
    {
      field: "campaign_code",
      headerName: "Campaign Code",
      headerClassName: "header-cell",
      width: 100,
      renderCell: (params) => (
        <Typography>{params.row?.campaign_code}</Typography>
      ),
    },
    {
      field: "campaign_start_date",
      headerName: "Start Date",
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>{params.row?.campaign_start_date}</Typography>
      ),
    },
    {
      field: "campaign_end_date",
      headerName: "End Date",
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>{params.row?.campaign_end_date}</Typography>
      ),
    },
    {
      field: "paid_status",
      headerName: "Paid Status",
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>{params.row?.paid_status}</Typography>
      ),
    },
    {
      field: "branch_id",
      headerName: "Branch",
      headerClassName: "header-cell",
      width: 200,
 

       renderCell: (params) => {
             
              const branches = params?.row?.branch_id || [];
              const visibleCount = 1; // Set the number of visible branches
              const visiblebranch = branches.slice(0, visibleCount);
              const extrabranch = branches.slice(visibleCount);
      
              return (
                <>
                  {visiblebranch.map((branches, index) => (
                    <div key={index}>{branches}</div>
                  ))}
                  {extrabranch.length  >0 && (
                    <Typography
                      variant="h6"
                      style={{ cursor: "pointer", marginLeft: "5px",color:"#46b1ff" }}
                      onClick={() => openbranchpopup(extrabranch)}
                    >
                      {`+${extrabranch.length}`}
                    </Typography>
                  )}
                </>
              );
            },
    },
    {
      field: "initiated_from",
      headerName: "Created From",
      headerClassName: "header-cell",
      width: 110,
      renderCell: (params) => (
        <Typography>{params.row?.initiated_from}</Typography>
      ),
    },
    {
      field: "created_by",
      headerName: "Created By",
      headerClassName: "header-cell",
      width: 150,
      renderCell: (params) => (
        <Typography>
          {params.row?.created_by?.first_name}{" "}
          {params.row?.created_by?.last_name}
        </Typography>
      ),
    },
    // {
    //   field: "state_id",
    //   headerName: "State",

    //   renderCell: (params) => (
    //     <Typography>{params.row?.state_id}</Typography>
    //   ),

    // },
    // {
    //   field: "city",
    //   headerName: "city",

    // },
  ];

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container mb={2}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <HeadingCard label="Master Campaigns"></HeadingCard>
        </Grid>

        <Grid item xs={4} lg={10} textAlign="end">
          <Button
            onClick={handelopen}
            variant="contained"
            size="medium"
            style={{ borderRadius: "7px", background: "#46b1ff" }}
            startIcon={
              <ControlPointIcon fontSize="small" style={{ fontSize: "1rem" }} />
            }
          >
            {" "}
            Add Campaign
          </Button>
        </Grid>
      </Grid>

      <MainCard>
        <Box
          textAlign="end"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <SearchTextFieldComp value={nameFilter} onChange={handleChangeNmae} />
          <Tooltip title="filter" placement="bottom">
            <ButtonBase>
              <FilterComp onClick={handleToggle} />
            </ButtonBase>
          </Tooltip>
        </Box>
        <CampainsFilter
          setCapaigndata={setCapaigndata}
          sortedCampaignData={sortedCampaignData}
          open={open}
          setOpen={setOpen}
          openFilter={openFilter}
          setLoading={setLoading}
          setGridKey={setGridKey}
        />

        <Box>
          <DataGrid
            key={gridKey}
            rows={campaigndata}
            columns={columns}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            autoHeight
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            sx={{ marginTop: "20px" }}
            pageSizeOptions={[25, 50, 100]}
          />
        </Box>
      </MainCard>

      <CustomPopup
        open={open}
        onClose={handeelclose}
        component={<CampaignForm onClose={handeelclose} />}
        title="Add Campaign"
      />

      <CustomPopup
        open={openeditpopup}
        onClose={handelcloseedit}
        component={
          <CampaignEditForm
            selectedCampaign={selectedCampaign}
            onClose={handelcloseedit}
          />
        }
        title="Edit Campaign"
      />

      <CustomPopup
        open={infoPopup}
        onClose={handelcloseview}
        component={
          <CampaignInfo selectedid={selectedid} onClose={handelcloseview} />
        }
        title=" Campaign Details"
        maxWidth="md"
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <CustomPopup
        open={branchPopup}
        onClose={closeBranchpopup}
        component={
          <AllBranches
            onClose={closeBranchpopup}
            selectedbranhes={selectedbranhes}
          />
        }
        title="Branches"
        showDivider="true"
        maxWidth="xs"
      />
    </>
  );
}
