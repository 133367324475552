import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import MainCard from "../../../../components/ui-component/card/MainCard";
import { CSVExport } from "../../../../components/ui-component/table/CSVExport";
import CreateClassDialog from "../../../../../pages/adminPages/classes/AddClass";
import CustomSnackbar from "../../../../components/ui-component/snackbar/CustomSnackbar";
import { TrainRounded } from "@mui/icons-material";
import EditTrainerForm from "./EditTrainerForm";
import CustomPopup from "../../../../../components/CustomPopup";
import ViewClassDetails from "./ViewClassDetails";
import AddButton from "../../../../newComponent/NewButtonComp/AddButton";
import StyledTableContainer from "../../../../newComponent/TableContainer";
import EditIconcomp from "../../../../newComponent/iconsComp/EditIconcomp";
import VeiwIcon from "../../../../newComponent/iconsComp/VeiwIcon";
import DenyIcon from "../../../../newComponent/iconsComp/DenyIcon";
import DeleteClass from "./DeleteClass";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const RoomScheduleTable = ({
  roomName,
  data,
  roomId,
  branchId,
  roomTeachingMode,
}) => {
  const theme = useTheme();
  console.log(data);
  // Fetch room schedule data based on roomName
  const userId = localStorage.getItem("userId");
  const [roomSchedule, setRoomSchedule] = useState(data);
  const [isViewSlotsOpen, setViewSlotsOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [editPopup, setEditPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [deletePopup, setDeletePopup] = useState(false);

  const room_Id = roomId;
  const branch_Id = branchId;
  const room_Name = roomName;
  const room_Teaching_mode = roomTeachingMode;

  const handleViewSlots = () => {
    setViewSlotsOpen(true);
    // Add any other logic you want to execute when "View Slots" is clicked
  };

  // Handler for closing the ViewSlotsComponent
  const handleCloseViewSlots = () => {
    setViewSlotsOpen(false);
  };

  const handleOpenCreateDialog = () => {
    // Check if the room ID is available
    if (!room_Id) {
      // Display a popup or show an alert to inform the user to add a room first
      showSnackbar("Please add a room before creating a slot.");
    } else {
      setIsCreateDialogOpen(true);
    }
  };

  const updateRoomSchedule = (newClass) => {
    setRoomSchedule([...roomSchedule, newClass]);
    window.location.reload();
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    setRoomSchedule(data);
  }, [data]);

  const handleopenEditPopup = (row) => {
    setEditPopup(true);
    setSelectedData(row);
  };

  const handlecloseEditPopup = () => {
    setEditPopup(false);
  };

  const handleopenViewPopup = (id) => {
    setViewPopup(true);
    setSelectedId(id);
  };

  const handlecloseViewPopup = () => {
    setViewPopup(false);
  };

  const handleopenDeleteslote = (id) => {
    setDeletePopup(true);
    setSelectedId(id);
  };

  const handleCloseDeleteslote = () => {
    setDeletePopup(false);
  };

  const handleDeleteslote = async () => {
    const requestData = {
      class_id: parseInt(selectedId),
      user_id: parseInt(userId),
    };

    try {
      const response = await fetch(`${API_ENDPOINT}class/deactivateclass`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        showSnackbar("Class Deactivate Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else if (response.status === 400) {
        showSnackbar("Class id cannot be null", "error");
      } else if (response.status === 405) {
        showSnackbar("Class has active students", "error");
      } else if (response.status === 403) {
        showSnackbar("Class not found/ No active class found", "error");
      } else {
        showSnackbar("Server error", "error");
      }
    } catch (error) {
      console.error("Network or Server Error:", error);
      showSnackbar("Server error", "error");
    }
  };
  return (
    <>
      <MainCard>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">Class Schedule</Typography>
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="end" gap={1}>
            <AddButton label="Add Slot" onClick={handleOpenCreateDialog} />
          </Grid>
          <Grid item xs={12}>
            <StyledTableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: "#90ee90" }}>
                      Slot
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "#90ee90" }}>
                      Test Type
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "#90ee90" }}>
                      Trainer
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "#90ee90" }}>
                      Class Capacity
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "#90ee90" }}>
                      Active Students
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "#90ee90" }}>
                      Seats Available
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "#90ee90" }}>
                      Delivery Mode
                    </TableCell>
                    <TableCell> Status</TableCell>
                    <TableCell sx={{ backgroundColor: "#90ee90" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roomSchedule.length === 0 ? (
                    <TableRow>
                      <TableCell sx={{ pl: 3 }} />
                      <TableCell colSpan={9} align="center">
                        <Typography variant="h5">No data available</Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    roomSchedule.map((row, index) => (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#f0f0f0" : "inherit",
                        }}
                      >
                        <TableCell>
                          {row.start_time} - {row.end_time}
                        </TableCell>
                        <TableCell>{row.test_type_id?.name}</TableCell>
                        <TableCell>{row.trainer_id?.first_name}</TableCell>
                        <TableCell>{row.capacity}</TableCell>
                        <TableCell>
                          {row.enrolled_student !== null
                            ? row.enrolled_student
                            : 0}
                        </TableCell>
                        <TableCell>{row.seat_availability}</TableCell>

                        <TableCell>{row?.mode_of_delivery}</TableCell>
                        <TableCell>
                          {row?.class_status === "active" ? (
                            <Chip
                              variant="contained"
                              size="small"
                              color="secondary"
                              label="Active"
                            />
                          ) : (
                            <Chip
                              label="Inactive"
                              variant="contained"
                              size="small"
                              color="error"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton>
                            <EditIconcomp
                              tooltipTitle="Edit"
                              onClick={() => handleopenEditPopup(row)}
                            />
                          </IconButton>

                          <IconButton>
                            <VeiwIcon
                              tooltipTitle="View"
                              onClick={() => handleopenViewPopup(row?.id)}
                            />
                          </IconButton>
                          <IconButton>
                            <DenyIcon
                              tooltipTitle="Deactivate"
                              onClick={() => handleopenDeleteslote(row?.id)}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Grid>
        </Grid>
        <Dialog
          open={isViewSlotsOpen}
          onClose={handleCloseViewSlots}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle variant="h4">Class Schedule</DialogTitle>
          <DialogContent></DialogContent>
        </Dialog>
      </MainCard>

      <CreateClassDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        roomId={room_Id}
        branchId={branch_Id}
        room_Name={room_Name}
        room_Teaching_mode={room_Teaching_mode}
        updateRoomSchedule={updateRoomSchedule}
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <CustomPopup
        open={editPopup}
        onClose={handlecloseEditPopup}
        title="Update Trainer"
        component={
          <EditTrainerForm
            onClose={handlecloseEditPopup}
            selectedData={selectedData}
          />
        }
        maxWidth="xs"
        showDivider={true}
      />

      <CustomPopup
        open={viewPopup}
        onClose={handlecloseViewPopup}
        title="Previous Trainer Details"
        component={
          <ViewClassDetails
            onClose={handlecloseViewPopup}
            selectedId={selectedId}
          />
        }
        maxWidth="sm"
        showDivider={true}
      />

      <CustomPopup
        open={deletePopup}
        onClose={handleCloseDeleteslote}
        title="Deactivate Class"
        component={
          <DeleteClass
            onClose={handleCloseDeleteslote}
            handleDeleteslote={handleDeleteslote}
          />
        }
        maxWidth="xs"
        showDivider={true}
      />
    </>
  );
};

export default RoomScheduleTable;
