// Author : Ayesha Shaikh
//  Date : 12 Nov 2024

import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import MainCard from "../../../components/Maincard";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import CustomPopup from "../../../components/CustomPopup";
import AddLeaveRequest from "./AddLeaveRequest";
import { fDate } from "../../../utils/formatTime";
import ViewLeaveRequest from "./ViewLeaveRequest";
import DeleteLeaveRequest from "./DeleteLeaveRequest";
import EditLeaveRequest from "./EditLeaveRequest";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FilterLeaveRequest from "./FilterLeaveRequest";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";
import FilterButtonComponent from "../../newComponent/NewButtonComp/FilterButtonComponent";
import SearchTextFieldComp from "../../newComponent/newtextfieldComp/SearchTextFieldComp";
import StyledTableContainer from "../../newComponent/TableContainer";
import VeiwIcon from "../../newComponent/iconsComp/VeiwIcon";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AllholydayRequest() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const [leaveData, setLeaveData] = useState([]);
  const [originalLeaveData, setOriginalLeaveData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [openAddPoup, setOpenAddPopup] = useState(false);
  const [openViewPopup, setOpenViewPoup] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [criteria, setCriteria] = useState("all");
  const [todaysLeaveRquest, setTodaysLeaveRequest] = useState([]);
  const [tommorrowLeaveRequest, setTommorrowLeaveRequest] = useState([]);
  const [isDownloadAccess, setIsDownloadAccess] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [searchData, setSearchData] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedLeaves = leaveData.slice(startIndex, endIndex);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  // Fetch All
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const token = getTokenOrLogout();
  //     if (!token) return;
  //     let headers = {
  //       Authorization: `Bearer ${token}`,
  //     };

  //     try {
  //       const response = await fetch(
  //         `${API_ENDPOINT}route2.0/advisorleave/${userId}`,
  //         { headers }
  //       );
  //       if (response.ok) {
  //         const data = await response.json();

  //         if (data.length === 0) {
  //           showSnackbar("No data available", "error");
  //           setLoading(false);
  //         } else {
  //           setLeaveData(data);
  //           setOriginalLeaveData(data);
  //           setSearchData(data);
  //           setLoading(false);
  //         }
  //       } else if (response.status === 404) {
  //         navigate("/page404");
  //       } else if (response.status === 401) {
  //         navigate("/page401");
  //       } else if (response.status === 403) {
  //         navigate("/page403");
  //       } else if (response.status === 440) {
  //         navigate("/page440");
  //       } else {
  //         navigate("/page500");
  //       }
  //     } catch (error) {
  //       navigate("/page500");
  //     }
  //   };

  //   fetchData();
  // }, [userId]);

  const ActionButton = styled(IconButton)(({ theme }) => ({
    border: "0.1px solid #E5E4E2",
    width: "20px", // adjust width as needed
    height: "20px", // adjust height as needed
    padding: "5px", // reduce padding for a smaller button
    fontSize: "small", // smaller font size for icons/text
  }));

  // Fetch api for  downloadAceess

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
      .then((response) => {
        if (response.ok) {
          setIsDownloadAccess(true);
        } else {
          setIsDownloadAccess(false);
        }
      })
      .catch((err) => {
        setIsDownloadAccess(false);
      });
  }, [userId]);

  const handleOpenAddPopup = () => {
    setOpenAddPopup(true);
  };

  const handleCloseAddPopup = () => {
    setOpenAddPopup(false);
  };

  const handleOpenViewPopup = (id) => {
    setOpenViewPoup(true);
    setSelectedId(id);
  };

  const handleCloseViewPoup = () => {
    setOpenViewPoup(false);
  };

  const handleOpenDeletePopup = (id) => {
    setOpenDeletePopup(true);
    setSelectedId(id);
  };

  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false);
  };

  const handleOpenEditPoup = (row) => {
    console.log("selectedRowData", row);
    setOpenEditPopup(true);
    setSelectedRowData(row);
  };

  const handleCloseEditPopup = () => {
    setOpenEditPopup(false);
  };
  

  const handleSearchByName = (e) => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, ""); // Normalize search value (lowercase and remove spaces)

    let filteredData1 = searchData.filter((data) => {
      // Check each field for a match with the search value
      const fieldsToSearch = [
        `${data?.employee_name?.first_name || ""} ${
          data?.employee_name?.last_name || ""
        }`,
        `${data?.start_date || ""}`,
        `${data?.end_date || ""}`,
        `${data?.leave_type_id || ""}`,
        `${data?.status || ""}`,
        `${data?.backup_emp_name?.first_name || ""} ${
          data?.backup_emp_name?.last_name || ""
        }`,
        `${fDate(data?.createdAt || "")}`,
      ];

      // Check if any of the fields contain the search value
      return fieldsToSearch.some((field) =>
        field.toLowerCase().replace(/\s+/g, "").includes(value)
      );
    });

    setLeaveData(filteredData1); // Update the filtered data
  };

  const handleFilterToggel = () => {
    setFilterOpen(!filterOpen);
  };

  // Fetch Bydefault all data
  useEffect(() => {
    handleFetchData();
  }, []);

  //  set filterCriteria
  const handleFilterCriteria = (criteria) => {
    setCriteria(criteria);
    handleFetchData(criteria);
    setFilterOpen(false);
  };

  //  fetch data base on filtercriteria
  const handleFetchData = async (criteria) => {
    setLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const requestData = {
      user_id: parseInt(userId),
      request_for: criteria,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/advisorleave`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const data = await response.json();
        setLeaveData(data);
        setOriginalLeaveData(data);
        setSearchData(data);
        setLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} md={3} lg={2.5}>
          <HeadingCard label="Advisor's Leave Request" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={9}
          lg={9.5}
          textAlign="end"
          container
          direction="row"
          justifyContent="end"
          gap={1}
        >
          <Button
            size="small"
            variant="contained"
            style={{
              borderRadius: "8px",
              background: "#46b1ff",
            }}
            startIcon={<ControlPointIcon fontSize="small" />}
            onClick={handleOpenAddPopup}
          >
            Add Leave Request
          </Button>

          <FilterButtonComponent
            text="Filter"
            showendIcon={true}
            open={filterOpen}
            onClick={handleFilterToggel}
          />
        </Grid>

        {filterOpen && (
          <Grid item xs={12}>
            <FilterLeaveRequest
              setLoading={setLoading}
              setLeaveData={setLeaveData}
              setOriginalLeaveData={setOriginalLeaveData}
              originalLeaveData={originalLeaveData}
              setSearchData={setSearchData}
              setCriteria={setCriteria}
              setPage={setPage}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <MainCard>
            <Grid
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              mt={2}
              mb={2}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" gap={1}>
                <Chip
                  size="small"
                  onClick={() => handleFilterCriteria("all")}
                  label="All Leave"
                  variant={criteria === "all" ? "contained" : "outlined"}
                  style={{ background: criteria === "all" ? "#00b13f" : "" }}
                />
                <Chip
                  size="small"
                  onClick={() => handleFilterCriteria("today")}
                  label="Today's Leave"
                  variant={criteria === "today" ? "contained" : "outlined"}
                  style={{ background: criteria === "today" ? "#00b13f" : "" }}
                />
                <Chip
                  size="small"
                  onClick={() => handleFilterCriteria("tomorrow")}
                  label="Tomorrow's Leave"
                  variant={criteria === "tomorrow" ? "contained" : "outlined"}
                  style={{
                    background: criteria === "tomorrow" ? "#00b13f" : "",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <SearchTextFieldComp
                  // value={value}
                  onChange={handleSearchByName}
                />
              </Box>
            </Grid>

            <StyledTableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Action</TableCell>
                    <TableCell>Employee Name</TableCell>
                    <TableCell> Start Date</TableCell>
                    <TableCell> End Date</TableCell>
                    <TableCell>Leave Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Backup User</TableCell>
                    <TableCell>Created Date</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <Backdrop open={loading} style={{ zIndex: 9999 }}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        variant="h4"
                        color="secondary"
                        fontWeight={800}
                        marginRight={2}
                      >
                        Fetching
                      </Typography>
                      <CircularProgress color="primary" />
                    </Stack>
                  </Backdrop>
                ) : paginatedLeaves?.length === 0 ? (
                  <Box
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h5">No data available</Typography>
                  </Box>
                ) : (
                  paginatedLeaves.map((row, index) => (
                    <>
                      <TableBody>
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            background: index % 2 === 0 ? "#f0f0f0" : "inherit",
                          }}
                        >
                          <TableCell sx={{ color: "#2196F3" }}>
                            <VeiwIcon
                              tooltipTitle="View"
                              fontSize="0.9rem"
                              onClick={() => handleOpenViewPopup(row?.id)}
                            />

                            {isDownloadAccess && (
                              <>
                                <ActionButton
                                  style={{ marginLeft: "7px" }}
                                  onClick={() => handleOpenEditPoup(row)}
                                >
                                  <Tooltip title="Edit">
                                    <EditIcon
                                      fontSize="small"
                                      style={{
                                        color: "#2196F3",
                                        fontSize: "0.85rem",
                                      }}
                                    />
                                  </Tooltip>
                                </ActionButton>

                                <ActionButton
                                  style={{ marginLeft: "7px" }}
                                  onClick={() => handleOpenDeletePopup(row?.id)}
                                >
                                  <Tooltip title="Delete">
                                    <CloseIcon
                                      fontSize="small"
                                      sx={{
                                        color: "#D22B2B",
                                        fontSize: "0.85rem",
                                      }}
                                    />
                                  </Tooltip>
                                </ActionButton>
                              </>
                            )}
                          </TableCell>
                          <TableCell>
                            {row?.employee_name?.first_name}{" "}
                            {row.employee_name?.last_name}
                          </TableCell>
                          <TableCell>{row?.start_date}</TableCell>
                          <TableCell>{row?.end_date}</TableCell>
                          <TableCell>{row?.leave_type_id}</TableCell>
                          <TableCell>
                            {" "}
                            {row.status ? (
                              <Chip
                                variant="contained"
                                size="small"
                                label={row?.status}
                                style={{ background: "#00b13f" }}
                              />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>
                            {row?.backup_emp_name?.first_name}{" "}
                            {row?.backup_emp_name?.last_name}
                          </TableCell>
                          <TableCell>{fDate(row?.createdAt)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </>
                  ))
                )}
              </Table>
            </StyledTableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={leaveData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <CustomPopup
        open={openAddPoup}
        onClose={handleCloseAddPopup}
        component={<AddLeaveRequest onClose={handleCloseAddPopup} />}
        title="Add Leave Request"
        maxWidth="sm"
        showDivider={true}
      />

      <CustomPopup
        open={openViewPopup}
        onClose={handleCloseViewPoup}
        component={
          <ViewLeaveRequest
            onClose={handleCloseViewPoup}
            selectedId={selectedId}
          />
        }
        title="Leave Details"
        maxWidth="sm"
        showDivider={true}
      />

      <CustomPopup
        open={openDeletePopup}
        onClose={handleCloseDeletePopup}
        component={
          <DeleteLeaveRequest
            onClose={handleCloseDeletePopup}
            selectedId={selectedId}
            showSnackbar={showSnackbar}
            leaveData={leaveData}
            setLeaveData={setLeaveData}
          />
        }
        maxWidth="xs"
        showDivider={false}
      />

      <CustomPopup
        open={openEditPopup}
        onClose={handleCloseEditPopup}
        component={
          <EditLeaveRequest
            onClose={handleCloseEditPopup}
            selectedRowData={selectedRowData}
            setSelectedRowData={setSelectedRowData}
            showSnackbar={showSnackbar}
          />
        }
        title="Edit Leave Request"
        maxWidth="sm"
        showDivider={false}
      />
    </>
  );
}
