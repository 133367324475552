export default function componentStyleOverrides(theme) {
  const bgColor = theme.colors?.grey50;
  const colors = {
    primary: "#46b1ff", // Primary color
    secondary: "#00b13f", // Secondary color for chips and buttons
    grey: "#7c7c7c", // Grey color (used for borders or other elements)
    error: "#ff4b4b",
    warning :"#f7c600",
    orange:"#f79400",
    chipOrange: "#f79400" 
   
  };
  return {
    // Mui Button
    MuiButton: {
      styleOverrides: {
        root: {
          // borderRadius: "8px", // Common style
          textTransform: "none", // Disable uppercase text
        },
      },
      variants: [
        // Contained Variants
        {
          props: { variant: "contained", size: "small" },
          style: {
            height: "23px",
            fontSize: "0.65rem",
            padding: "3px 6px",
          },
        },
        {
          props: { variant: "contained", size: "medium" },
          style: {
            height: "27px",
            fontSize: "0.7rem",
            padding: "2px 10px",
          },
        },
        {
          props: { variant: "contained", size: "large" },
          style: {
            height: "50px",
            fontSize: "1rem",
            padding: "8px 20px",
          },
        },

        // Outlined Variants
        {
          props: { variant: "outlined", size: "small" },
          style: {
            height: "23px",
            fontSize: "0.65rem",
            padding: "3px 6px",
          },
        },
        {
          props: { variant: "outlined", size: "medium" },
          style: {
            height: "25px",
            fontSize: "0.7rem",
            padding: "2px 10px",
          },
        },
        {
          props: { variant: "outlined", size: "large" },
          style: {
            height: "50px",
            fontSize: "1rem",
            padding: "8px 20px",
            // border: `1px solid ${colors.grey}`,
          },
        },

        // Text Variants
        {
          props: { variant: "text", size: "small" },
          style: {
            height: "23px",
            fontSize: "0.7rem",
            padding: "3px 6px",
          },
        },
        {
          props: { variant: "text", size: "medium" },
          style: {
            height: "40px",
            fontSize: "0.875rem",
            padding: "6px 12px",
            // color: colors.primary,
          },
        },
        {
          props: { variant: "text", size: "large" },
          style: {
            height: "50px",
            fontSize: "1rem",
            padding: "8px 16px",
            // color: colors.primary,
          },
        },
        {
          props: { color: "primary", variant: "contained" },
          style: {
            backgroundColor: colors.primary,
            color: "#fff",
          },
        },
        {
          props: { color: "secondary", variant: "contained" },
          style: {
            backgroundColor: colors.secondary,
            color: "#fff",
          },
        },
        {
          props: { color: "error", variant: "contained" },
          style: {
            backgroundColor: colors.error,
            color: "#fff",
          },
        },
        {
          props: { color: "secondary", variant: "outlined" },
          style: {
            borderColor: colors.secondary,
            color: colors.secondary,
          },
        },
      
        {
          props: { color: "secondary", variant: "text" },
          style: {
            borderColor: colors.secondary,
            color: colors.secondary,
          },
        },
        
       
      ],
    },

    // Datagrid
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.colors?.grey400}`,
          fontSize: "0.75rem",
          "& .MuiDataGrid-columnHeaders": {
            fontWeight: 600,
            fontSize: "0.7rem",
            minHeight: "25px",
            color: "black",
          },
          " & .MuiDataGrid-columnHeaderTitle ": {
            fontWeight: 500,
          },

          "& .MuiDataGrid-cell": {
            borderBottom: `1px solid ${theme.colors?.grey300}`,
            fontSize: "0.68rem",
          },
          "& .MuiDataGrid-row": {
            "&:nth-of-type(odd)": {
              backgroundColor: "rgb(240, 240, 240)", // Light gray for odd rows

              "&:hover": {
                backgroundColor: "rgb(240, 240, 240)", // Keep original color on hover
              },
            },
            "&:nth-of-type(even)": {
              backgroundColor: "#FFFFFF", // White for even rows

              "&:hover": {
                backgroundColor: "#FFFFFF", // Keep original color on hover
              },
            },
          },

          "& .MuiDataGrid-selectedRowCount": {
            color: theme.colors?.primaryDark,
          },

          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            height: "10px", // Custom scrollbar height for viewport
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
            backgroundColor: theme.colors?.grey400, // Custom scrollbar color
            borderRadius: "4px",
          },
        
          // "& .MuiDataGrid-footerContainer": {
          //   // height:'60px',
          //   // position: "sticky",
          //   bottom: 0,
          //   zIndex: 1,
          //   backgroundColor: "#fff", // Match background color
          // },
        },

        columnHeaders: {
          fontSize: "0.7rem",

          background: "#abf7b1",
        },
        cell: {
          padding: "8px",
        },
      },

      defaultProps: {
        rowHeight: 27, // Default row height for all DataGrids
        columnHeaderHeight: 25, // Default header height for all DataGrids
        disableColumnFilter: true, // Disables the filter functionality globally
        disableColumnSelector: true, // Disables the column selector globally
      },
    },

    // Checkbox
    MuiCheckbox: {
      styleOverrides: {
        root: {
          // color: colors.primary, // Customize the checkbox color
          fontSize: "1rem", // Set custom font size (adjust as needed)
          "& .MuiSvgIcon-root": {
            fontSize: "1rem",
          },
        },
      },
    },

    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          paddingTop: "10px",
          paddingBottom: "10px",
          "&.Mui-selected": {
            color: theme.menuSelected,
            backgroundColor: theme.menuSelectedBack,
            "&:hover": {
              backgroundColor: theme.menuSelectedBack,
            },
            "& .MuiListItemIcon-root": {
              color: theme.menuSelected,
            },
          },
          "&:hover": {
            backgroundColor: theme.menuSelectedBack,
            color: theme.menuSelected,
            "& .MuiListItemIcon-root": {
              color: theme.menuSelected,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: "36px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          "&::placeholder": {
            color: theme.darkTextSecondary,
            fontSize: "0.875rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: bgColor,
          borderRadius: `${theme?.customization?.borderRadius}px`,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.colors?.grey400,
          },
          "&:hover $notchedOutline": {
            borderColor: theme.colors?.primaryLight,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },
        input: {
          fontWeight: 500,
          background: bgColor,
          padding: "15.5px 14px",
          borderRadius: `${theme?.customization?.borderRadius}px`,
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: theme.colors?.grey300,
          },
        },
        mark: {
          backgroundColor: theme.paper,
          width: "4px",
        },
        valueLabel: {
          color: theme?.colors?.primaryLight,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200,
        },
      },
    },

    // Chip

    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
      variants: [
        // Contained Variants
        {
          props: { variant: "contained", size: "normal" },
          style: {
            color: "#fff",
            fontSize: "0.55rem",
            height: "15px",
            
            borderRadius: "25px",
            lineHeight: "20px", // Ensure text vertically aligns within the height
            textAlign: "center",
            display: "inline-flex", // Keep natural size without stretching
            alignItems: "center",
          },
        },
        {
          props: { variant: "contained", size: "small" },
          style: {
            color: "#fff",
            fontSize: "0.6rem",
            height: "20px",
            padding: "0 2px", // Horizontal padding for better spacing
            borderRadius: "25px",
            lineHeight: "20px", // Ensure text vertically aligns within the height
            textAlign: "center",
            display: "inline-flex", // Keep natural size without stretching
            alignItems: "center",
          },
        },
        {
          // Updated
          props: { variant: "contained", size: "medium" },
          style: {
            //  background: "#00b13f",
            color: "#fff",
            fontSize: "0.7rem",
            height: "25px",
            padding: "1px 1px",
          },
        },
        {
          props: { variant: "contained", size: "large" },
          style: {
            color: "#fff",
            fontSize: "1rem",
            height: "40px",
            padding: "6px 16px",
          },
        },

        // Outlined Variants
        {
          props: { variant: "outlined", size: "small" },
          style: {
            fontSize: "0.6rem",
            height: "20px",
            padding: "0 2px", // Horizontal padding for better spacing
            borderRadius: "25px",
            lineHeight: "20px", // Ensure text vertically aligns within the height
            textAlign: "center",
            display: "inline-flex", // Keep natural size without stretching
            alignItems: "center",
          },
        },
        {
          // Updated
          props: { variant: "outlined", size: "medium" },
          style: {
            fontSize: "0.7rem",
            height: "25px",
            padding: "1px 2px",
          },
        },
        {
          props: { variant: "outlined", size: "large" },
          style: {
            fontSize: "1rem",
            height: "40px",
            padding: "6px 16px",
          },
        },
        {
          props: { color: "secondary" },
          style: {
            backgroundColor: "#00b13f",
            color: "#fff",
            "&.MuiChip-outlined": {
              borderColor: "#00b13f",
              color: "#00b13f",
            },
          },
        },
        
        {
          props: { color: "default", variant: "contained" },
          style: {
            backgroundColor: colors.grey,
            color: "#fff"
          },
        },
        {
          props: { color: "warning", variant: "contained" },
          style: {
            backgroundColor: colors.warning,
            color: "#fff"
          },
        },

        {
          props: { color: "orange", variant: "contained" },
          style: {
            backgroundColor: colors.orange,
            color: "#fff"
          },

        },
     
          
        
// Outlined Color


      ],
    },

    // Select

    MuiSelect: {
      styleOverrides: {
        select: {
          lineHeight: "normal", // Keeps vertical alignment clean
        },
      },
      variants: [
        {
          props: { size: "normal" },
          style: {
            fontSize: "0.7rem !important", // Add !important to enforce styling
            height: "27px !important", // Add !important to enforce height
            padding: "6px 12px !important", // Add !important to padding
          },
        },
        {
          props: { size: "small" },
          style: {
            padding: "2px 6px", // Adjust for small size
            fontSize: "0.75rem",
            height: "30px",
            minHeight: "30px",
          },
        },
        {
          props: { size: "medium" },
          style: {
            padding: "6px 12px", // Adjust for medium size
            fontSize: "0.7rem",
            height: "27px",
            minHeight: "27px",
          },
        },
        {
          props: { size: "large" },
          style: {
            padding: "10px 16px", // Adjust for large size
            fontSize: "1rem",
            height: "50px",
            minHeight: "50px",
          },
        },
      ],
    },

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&.MuiSelect-select": {
            borderRadius: `${theme?.customization?.borderRadius}px`,
            "&[size='small']": {
              padding: "2px 6px",
            },
            "&[size='medium']": {
              padding: "6px 12px",
            },
            "&[size='large']": {
              padding: "10px 16px",
            },
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          // Conditionally set font size based on the select's size prop
          "&[data-size='normal']": {
            fontSize: "0.7rem", // Adjust the font size for small
          },
          "&[data-size='small']": {
            fontSize: "0.7rem", // Adjust the font size for small
          },
          "&[data-size='medium']": {
            fontSize: "0.75rem", // Adjust the font size for medium
          },
          "&[data-size='large']": {
            fontSize: "1rem", // Adjust the font size for large
          },
        },
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
        // Adjusting the size of the inner radio circle (icon)
     
      },
      variants: [
        // Small Radio Button
        {
          props: { size: "small" },
          style: {
            fontSize: "0.7rem", // Small size font
            width: "25px", // Small radio button width
            height: "18px", // Small radio button height
            padding: "6px", // Padding for better alignment
            '& .MuiSvgIcon-root': {
              fontSize: "15px", // Smaller circle size for the small variant
            },
          
          },
        },
        // Medium Radio Button
        {
          props: { size: "medium" },
          style: {
            fontSize: "1rem", // Medium size font
            width: "22px", // Medium radio button width
            height: "22px", // Medium radio button height
            padding: "6px", // Padding for better alignment
            '& .MuiSvgIcon-root': {
              fontSize: "17px", // Smaller circle size for the medium variant
            },
            
          },
        },
        // Large Radio Button
        {
          props: { size: "large" },
          style: {
            fontSize: "1.25rem", // Large size font
            width: "28px", // Large radio button width
            height: "28px", // Large radio button height
            padding: "8px", // Padding for better alignment
            '& .MuiSvgIcon-root': {
              fontSize: "20px", // Smaller circle size for the large variant
            },
          },
        },
      ],
    },
    
    

  }
}

