import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import EditLessonplan from "./EditLessonplanpopup";
import EditlessonplanForm from "./EditlessonplanForm";
import { useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser";
import LessonplanForm from "./LessonplanForm";
import CreateLessonPopup from "./LessonPopup";
import HeadingCard from "../../newTestPrep/newComponent/NewCardsComp/HeadingCard";
import CustomPopup from "../../components/CustomPopup";
import NewCardcomp from "../../components/card/NewCardcomp";
export default function LessonPlanList() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const { classId } = useParams();
  console.log("classid is ", classId);
  const [selectedButton, setSelectedButton] = useState("this");
  const [selectedDayContent, setSelectedDayContent] = useState("");
  const [planlist, setplanlist] = useState([]);
  const [selectedDayName, setSelectedDayName] = useState("");
  const [fromDate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState("");

  const location = useLocation();
  const selectedClass = location.state?.selectedClass;
  console.log(selectedClass);

  useEffect(() => {
    // Fetch lesson data based on selected button and classId

    fetchLessonData(selectedButton);
  }, [ ]);

  const fetchLessonData = async (selectedButton) => {
    try {
      // Make API request
      setLoading(true);
      const response = await fetch(`${API_ENDPOINT}route/getlessonPlan`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          week_type: selectedButton,
          class_id: parseInt(classId),
        }),
      });
      if (response.ok) {
        const data = await response.json();
        // Update lessonData state with fetched data
        console.log(data);
        setplanlist(data);
      } else {
        setplanlist([]);
      
      }
    } catch (error) {
      console.error("Error fetching lesson data:", error);
  
      // Handle error
    } finally {
      setLoading(false); // Set loading to false after receiving response or encountering an error
    }
  };

  console.log(planlist);

  const [open, setOpen] = useState(false);
  const handleClosePopup = () => {
    setOpen(false);
  };
  const handleOpenPopup = (dayName, dayContent, date1, date2, id) => {
    setSelectedDayName(dayName);
    setSelectedDayContent(dayContent);
    setFromdate(date1);
    setTodate(date2);
    setId(id);
    setOpen(true);

    // Pass date and id to the popup component
    // You can use them in EditlessonplanForm component as needed

    console.log("ID:", id);
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    setplanlist([])
    fetchLessonData(buttonName)
  };

  const uploadopenpopup = () => {
    setOpenPopup(true);
  };

  const uploadclosepopup = () => {
    setOpenPopup(false);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={2}>
       <HeadingCard label="Lesson Plan"/>
        </Grid>

        <>
          <Grid item xs={6} lg={7} >
            { !loading && planlist.length === 0 && (
              <Button
               variant="contained"
               size="small"
               color="secondary"
                onClick={() => {
                  // Set the selected class
                  uploadopenpopup();
                }}
              >
                Upload Lesson Plan
              </Button>
            )}
          </Grid>
        </>

        <Grid item xs={12} lg={3}>
          <Button
          size="small"
          variant={selectedButton==="prev"?"contained":"text"}
            style={{
              backgroundColor: selectedButton === "prev" ? "#00b13f" : "",
              color: selectedButton === "prev" ? "white" : "#808080",
            }}
            onClick={() => handleButtonClick("prev")}
          >
            Previous Week
          </Button>
          <Button
            size="small"
            variant={selectedButton==="this"?"contained":"text"}
            style={{
              backgroundColor: selectedButton === "this" ? "#00b13f" : "",
              color: selectedButton === "this" ? "white" : "#808080",
            }}
            onClick={() => handleButtonClick("this")}
          >
            This Week
          </Button>
          <Button
            size="small"
            variant={selectedButton==="next"?"contained":"text"}
            style={{
              backgroundColor: selectedButton === "next" ? "#00b13f" : "",
              color: selectedButton === "next" ? "white" : "#808080",
            }}
            onClick={() => handleButtonClick("next")}
          >
            Next Week
          </Button>
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12} mt={2} container gap={3}>
          <Typography variant="h5">
            {selectedClass.name}
          </Typography>
       
       
          <Typography variant="h5">
            Trainer : {selectedClass.trainer_id?.first_name}{" "}
            {selectedClass.trainer_id?.last_name}
          </Typography>
      
      
          <Typography variant="h5">
            Branch : {selectedClass.branch_id.branch_name}
          </Typography>
        </Grid>

        {loading ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
           
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {planlist.length === 0 ? (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                 
                }}
              >
                <Typography variant="h4">No Lesson Plan Uploaded</Typography>
              </Box>
            ) : (
              <>
                <Grid item xs={12} lg={12} mt={1}>
                  <NewCardcomp borderRadius='25px' padding='15px'>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h4">
                        Monday
                      </Typography>
                      <Button
                      size="small"
                        color="primary"
                       
                        onClick={() =>
                          handleOpenPopup(
                            "Monday",
                            planlist.day_1,
                            planlist.date_from,
                            planlist.date_to,
                            planlist.id
                          )
                        }
                      >
                        Edit
                      </Button>
                    </Stack>

                    {planlist.day_1 ? (
                    
                    < >
                    {parse(planlist.day_1)}
                  </>
                      
                    ) : (
                      <Typography>No Content</Typography>
                    )}
                  </NewCardcomp>
                </Grid>

                <Grid item xs={12} lg={12} mt={1}>
                  <NewCardcomp borderRadius='25px' padding='15px'>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h4">
                        Tuesday
                      </Typography>
                      <Button
                      Button
                           size="small"
                        color="primary"
                        onClick={() =>
                          handleOpenPopup(
                            "Tuesday",
                            planlist.day_2,
                            planlist.date_from,
                            planlist.date_to,
                            planlist.id
                          )
                        }
                      >
                        Edit
                      </Button>
                    </Stack>

                    {planlist.day_2 ? (
                      <Typography >
                        {parse(planlist.day_2)}
                      </Typography>
                    ) : (
                      <Typography>No Content</Typography>
                    )}
                  </NewCardcomp>
                </Grid>

                <Grid item xs={12} lg={12} mt={1}>
                  <NewCardcomp borderRadius='25px' padding='15px'>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h4">
                        Wednesday
                      </Typography>
                      <Button
                      size="small"
                        color="primary"
                        onClick={() =>
                          handleOpenPopup(
                            "Wednesday",
                            planlist.day_3,
                            planlist.date_from,
                            planlist.date_to,
                            planlist.id
                          )
                        }
                      >
                        Edit
                      </Button>
                    </Stack>

                    {planlist.day_3 ? (
                      <Typography>
                        {parse(planlist.day_3)}
                      </Typography>
                    ) : (
                      <Typography>No Content</Typography>
                    )}
                  </NewCardcomp>
                </Grid>

                <Grid item xs={12} lg={12} mt={1}>
                  <NewCardcomp borderRadius='25px' padding='15px'>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h4">
                        Thursday
                      </Typography>
                      <Button
                      size="small"
                        color="primary"
                        onClick={() =>
                          handleOpenPopup(
                            "Thursday",
                            planlist.day_4,
                            planlist.date_from,
                            planlist.date_to,
                            planlist.id
                          )
                        }
                      >
                        Edit
                      </Button>
                    </Stack>

                    {planlist.day_4 ? (
                      <Typography >
                        {parse(planlist.day_4)}
                      </Typography>
                    ) : (
                      <Typography>No Content</Typography>
                    )}
                  </NewCardcomp>
                </Grid>

                <Grid item xs={12} lg={12} mt={1}>
                  <NewCardcomp borderRadius='25px' padding='15px'> 
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h4">
                        Friday
                      </Typography>
                      <Button
                           size="small"
                        color="primary"
                        onClick={() =>
                          handleOpenPopup(
                            "Friday",
                            planlist.day_5,
                            planlist.date_from,
                            planlist.date_to,
                            planlist.id
                          )
                        }
                      >
                        Edit
                      </Button>
                    </Stack>

                    {planlist.day_5 ? (
                      <Typography >
                        {parse(planlist.day_5)}
                      </Typography>
                    ) : (
                      <Typography>No Content</Typography>
                    )}
                  </NewCardcomp>
                </Grid>

                <Grid item xs={12} lg={12} mt={1}>
                  <NewCardcomp borderRadius='25px' padding='15px'>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h4">
                        Saturday
                      </Typography>
                      <Button
                           size="small"
                        color="primary"
                        onClick={() =>
                          handleOpenPopup(
                            "Saturday",
                            planlist.day_6,
                            planlist.date_from,
                            planlist.date_to,
                            planlist.id
                          )
                        }
                      >
                        Edit
                      </Button>
                    </Stack>

                    {planlist.day_6 ? (
                      <Typography >
                        {parse(planlist.day_6)}
                      </Typography>
                    ) : (
                      <Typography>No Content</Typography>
                    )}
                  </NewCardcomp>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>

      <CustomPopup
        open={open}
       
        onClose={handleClosePopup}
        component={
          <EditlessonplanForm
            content={selectedDayContent}
            selectedfromdate={fromDate}
            selectedtodate={todate}
            selectedid={id}
            selectedDayName={selectedDayName}
            handleClosePopup={handleClosePopup}
          />

        }
              maxWidth="sm"
      />

      <CustomPopup
        onClose={uploadclosepopup}
        open={openPopup}
        // title="Upload Lesson Plan"
        component={
          <LessonplanForm
            selectedClass={selectedClass}
            selectedButton={selectedButton}
            onClose={uploadclosepopup}
            planlist={planlist}
          />
        }
           maxWidth="md"
      />
    </>
  );
}
