import React, { useState } from "react";
import MainCard from "../../../newTestPrep/components/ui-component/card/MainCard";
import { Box, Button, Typography, Span, Stack } from "@mui/material";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDateTime } from "../../../utils/formatTime";
export default function NotificationComp({
  selectedNotification,
  setNotifications,
  notifications,
  onClose,
}) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const dateString = selectedNotification.createdAt;
  const date = new Date(dateString);
  const dayName = date.toLocaleDateString("en-US", { weekday: "long" });

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleMarkAsRead = () => {
    fetch(`${API_ENDPOINT}notification/${selectedNotification.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: parseInt(userId),
        notification_read_by: parseInt(userId),
      }),
    })
      .then((response) => {
        if (response.ok) {
          // Notification marked as read successfully, you can update your UI accordingly
          setNotifications(
            notifications.filter(
              (notification) => notification.id !== selectedNotification.id
            )
          );
         
          showSnackbar("Notification marked as read");
          setTimeout(() => {
            onClose();
          }, 1500);
        } else {
          // Handle error response from the server
          console.error("Failed to mark notification as read");
          showSnackbar("Failed to mark notification as read", "error");
        }
      })
      .catch((error) => {
        // Handle network error
        console.error("Network error:", error);
        showSnackbar("Network Error", "error");
      });
  };
  const handleView = (id) => {
    if (selectedNotification.notification_type === 1) {
      const url = `/demodetails/${id}`;
      window.open(url, "_blank");
    } else if (selectedNotification.notification_type === 2) {
      const url = `/enquirydetails/${id}`;
      window.open(url, "_blank");
    }
    else if (selectedNotification.notification_type === 3) {
      const url = "/declinepayment";
      window.open(url, "_blank"); 
      
    }
  };

  return (
    <>
      <Box style={{ maxWidth: "100%" }}>
        <MainCard>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ width: "80px", height: "50px" }}>
              <img src="/bell.png" width="100%" />
            </Box>
            <Typography
              sx={{ marginTop: "45px", fontWeight: 600, fontSize: "22px" }}
            >
              {selectedNotification.notification_title}
            </Typography>
            {selectedNotification.notification_type === 1 && (
              <>
                <Stack sx={{ marginTop: "10px" }}></Stack>
                <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                  {" "}
                  {selectedNotification.created_by?.first_name}{" "}
                  {selectedNotification.created_by?.last_name} has created a
                  demo request.{" "}
                </Typography>
                <Button
                  sx={{ fontSize: "18px", fontWeight: 500 }}
                  onClick={() => handleView(selectedNotification.demo_id)}
                >
                  View details
                </Button>
              </>
            )}

            {selectedNotification.notification_type === 2 && (
              <>
                <Stack sx={{ marginTop: "10px" }}></Stack>
                <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                  {" "}
                  {selectedNotification.created_by?.first_name}{" "}
                  {selectedNotification.created_by?.last_name} has assigned an enquiry to you.{" "}
                </Typography>
                <Button
                  sx={{ fontSize: "18px", fontWeight: 500 }}
                  onClick={() => handleView(selectedNotification.enquiry_id)}
                >
                  View details
                </Button>
              </>
            )}

{selectedNotification.notification_type === 3 && (
              <>
                <Stack sx={{ marginTop: "10px" }}></Stack>
                <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                  {" "}
                  Payment are declined by  {selectedNotification.created_by?.first_name}{" "}
                  {selectedNotification.created_by?.last_name}
                </Typography>
                <Button
                  sx={{ fontSize: "18px", fontWeight: 500 }}
                  onClick={() => handleView()}
                >
                  View details
                </Button>
              </>
            )}
            
            <Typography
              sx={{ marginTop: "10px", fontWeight: 600, fontSize: "18px" }}
            >
              {dayName} , {fDateTime(selectedNotification.createdAt)}
            </Typography>
            <Button
              variant="contained"
              color="error"
              style={{
                borderRadius: "24px",
                marginTop: "50px",
                fontSize: "18px",
              }}
              onClick={handleMarkAsRead}
            >
              Mark As Read
            </Button>
          </Box>
        </MainCard>
      </Box>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
