import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import { useGetTokenOrLogout } from "../../../utils/token";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";
import MainCard from "../../../components/Maincard";
import CustomNoRowsOverlay from "../../newComponent/TableCustomeComp/CustomNoRowsOverlay";
import { DataGrid } from "@mui/x-data-grid";
import VeiwIcon from "../../newComponent/iconsComp/VeiwIcon";
import DenyIcon from "../../newComponent/iconsComp/DenyIcon";
import ApproveIcon from "../../newComponent/iconsComp/ApproveIcon";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function StudentLeaveRequests() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();

  const userId = localStorage.getItem("userId");
  const [isLoading, setIsLoading] = useState(false);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleUpdateStatus = (leaveId, newStatus) => {
    // Update the status locally in the state
    const updatedPayments = leaveRequests.map((leave) =>
      leave.id === leaveId ? { ...leave, leave_status: newStatus } : leave
    );
    setLeaveRequests(updatedPayments);

    // Send a request to the server to update the status
    fetch(`${API_ENDPOINT}route2.0/updateleaverequest/${leaveId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        leave_status: newStatus,
        user_id: parseInt(userId),
      }),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Leave status updated successfully", "success");
          // Remove the row from state if the status is updated successfully
          // const updatedPayments = leaveRequests.filter(payment => payment.id !== leaveId);
          // setLeaveRequests(updatedPayments);
        } else {
          showSnackbar("Failed to update leave status", "error");
          throw new Error("Failed to update leave status");
        }
        // Handle successful response if needed
      })
      .catch((error) => {
        console.error("Error updating leave status:", error);
        showSnackbar("Error updating leave status", "error");
        // Revert the status locally if the server update fails
        setLeaveRequests(leaveRequests);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/showleaverequest/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();

          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setLeaveRequests(sortedData);
          console.log(sortedData);
          setIsLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch {
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row?.leave_status === "pending" && (
            <Box display='flex' gap={1}>
              <ApproveIcon
                tooltipTitle="Approve Request"
                onClick={() => handleUpdateStatus(params?.row.id, "accepted")}
              />

              <DenyIcon
                tooltipTitle="deny Request"
                onClick={() => handleUpdateStatus(params?.row.id, "denied")}
              />
            </Box>
          )}
        </div>
      ),
    },
    {
      field: "enquiry_name",
      headerName: "Enquiry Name",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row.enquiry_id?.first_name}{" "}
          {params?.row.enquiry_id?.last_name}
        </div>
      ),
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row.enquiry_id?.mobile} </div>,
    },
    {
      field: "class",
      headerName: "Class Details",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row.class_id?.name} </div>,
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params?.row.related_branch?.branch_name} </div>
      ),
    },

    {
      field: "n_o_d",
      headerName: "Number of Days",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row.no_of_days} </div>,
    },
    {
      field: "l_f_d",
      headerName: "Leave From Date",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params?.row?.leave_date_from)} </div>,
    },
    {
      field: "l_t_d",
      headerName: "Leave To Date",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params?.row?.leave_date_to)} </div>,
    },
    {
      field: "request_date",
      headerName: "Request Date",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params?.row?.createdAt)} </div>,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {" "}
          {params?.row?.leave_status === "accepted" && (
            <Chip
              variant="contained"
              size="small"
              label="Accepted"
              color="secondary"
            />
          )}
          {params?.row?.leave_status === "denied" && (
            <Chip
              variant="contained"
              size="small"
              label="Denied"
              color="primary"
            />
          )}
          {params?.row?.leave_status === "pending" && (
            <Chip
              variant="contained"
              size="small"
              label="Pending"
              color="warning"
            />
          )}
          {params?.row?.leave_status === "cancelled" && (
            <Chip
              variant="contained"
              size="small"
              label="Cancelled"
              color="error"
            />
          )}{" "}
        </div>
      ),
    },
    {
      field: "approve_by",
      headerName: "Approved/Denied By",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params?.row.approved_by?.first_name}{" "}
          {params?.row.approved_by?.last_name}
        </div>
      ),
    },
  ];
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} lg={3} sm={5} md={4}>
          <HeadingCard label="Student Leave Requests" />
        </Grid>
        <Grid item xs={12}>
          <MainCard>
            <DataGrid
              rows={leaveRequests}
              columns={columns}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              autoHeight
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
            />
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default StudentLeaveRequests;
