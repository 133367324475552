import { Card, useMediaQuery } from "@mui/material";
import React from "react";

export default function NewCardcomp({
  children,
  height,
  width,
  borderRadius,
  padding,
  onClick,
  bgColor,
  hoverBgColor,
  hoverEffect,
  background,
  textAlign
}) {
  // Define breakpoints for medium, large, and extra-large screens
  const isMdLgXl = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <>
      <Card
        onClick={onClick}
        sx={{
          textAlign:{textAlign},
          background:background?background :"white",
          borderRadius: borderRadius ? borderRadius : "10px",
          padding: padding ? padding : "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          height: isMdLgXl ? height : "auto",
          width: width,
          cursor:hoverEffect? "pointer" :"",
          transition: "background-color 0.3s", // Smooth transition for background color
          "&:hover": {
            boxShadow: hoverEffect ? "0px  4px 8px rgba(0, 0, 0, 0.5)" :"",
            backgroundColor: hoverBgColor ? hoverBgColor : "", // Default hover background color
          },
        }}
      >
        {children}
      </Card>
    </>
  );
}
