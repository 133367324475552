import React from 'react'
import {
   
    Typography,
   
  } from "@mui/material";
export default function Branches({selectedbranhes}) {
  return (
    // <Typography variant='h5'>{selectedbranhes.join(' , ')}</Typography>

     <Typography variant="h5">
            <ul>
              {selectedbranhes.map((pkg, index) => (
                <li key={index} style={{padding:'5px'}}>{pkg}</li>
              ))}
            </ul>
          </Typography>
  )
}
