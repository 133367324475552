// Author : Ayesha Shaikh
//  Date : 17 sep 2024

import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MainCard from "../../../components/Maincard";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import CloseIcon from "@mui/icons-material/Close";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";
import CustomAutocompleteComp from "../../newComponent/AutocompleteComp";
import SecondaryButton from "../../newComponent/NewButtonComp/SubmitButton";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EditEmployeeProfile() {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 4;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
        overflow: "auto",
        zIndex: 1300,
      },
    },
  };
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const { id } = useParams();
  const state = JSON.parse(localStorage.getItem("employeeState"));

  const [employeedata, setEmployeeData] = useState(state?.employeedetails);

  const [downloadAccess, setdownloadAccess] = useState(false);

  const [codeList, setCodeList] = useState([]);
  const [BranchList, setBranchList] = useState([]);
  const [branchName, setBranchName] = useState(null);

  const [roleList, setRoleList] = useState([]);
  const [role, setrole] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [service, setService] = useState([]);
  const [serviceIds, setServiceIds] = useState([]);
  const [subServiceList, setSubserviceList] = useState([]);
  const [subService, setSubService] = useState([]);
  const [workBranch, setWorkBranch] = useState([]);
  const [userList, setUserList] = useState([]);
  const [branchManager, setBranchManager] = useState([]);
  const [reportingManagerList, setReportingManagerList] = useState([]);
  const [reportingManager, setReportingManager] = useState([]);
  const [reportUser, setReportUser] = useState([]);

  const [reportUsers, setReportUsers] = useState([{ id: 1, value: "" }]);
  const [branchHandled, setBranchhandled] = useState([]);
  const [backupUser, setBackupUser] = useState(null);
  const [isAutoAssign, setIsautoassign] = useState(
    employeedata?.is_auto_assigner 
  );

  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const ccField = reportUser.map((i) => i.email);
  const ccField1 = reportUsers.map((i) => i.value);

  const onSubmit = async (data) => {
    try {
      const requestData = {
        ...data,
        role: role.map((i) => i.id),
        branch: branchName?.id,
        branch_manager: branchManager?.first_name,
        reporting_manager: reportingManager?.id,
        add_report_cc_user: [...ccField, ...ccField1],
        user_id: parseInt(userId),
      };
      const response = await fetch(`${API_ENDPOINT}employee/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      if (response.ok) {
        showSnackbar("Employee details updated Successfully", "success");

        // setTimeout(() => {
        //  window.location.reload()
        // }, 1500);
      } else if (response.status === 401) {
        showSnackbar("Unknown User", "error");
      } else if (response.status === 404) {
        showSnackbar("Error to Adding Employee", "error");
      } else if (response.status === 401) {
        showSnackbar("Unathorized User", "error");
      } else if (response.status === 409) {
        showSnackbar("Already active user existing with this email", "error");
      } else {
        showSnackbar("Error to Adding Employee", "error");
        throw new Error("Error to Adding Employee");
      }
    } catch (error) {
      showSnackbar("Error to Adding Employee", "error");
    }
  };

  // download Access api

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
      .then((response) => {
        if (response.ok) {
          setdownloadAccess(true);
        } else {
          setdownloadAccess(false);
        }
      })
      .catch((err) => {
        setdownloadAccess(false);
      });
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}country`);
        if (response.ok) {
          const data = await response.json();
          setCountryList(data);
          const sortedData = [...data].sort(
            (a, b) => a.phone_code - b.phone_code
          );
          const uniqueArray = sortedData.filter(
            (obj, index, self) =>
              index === self.findIndex((t) => t.phone_code === obj.phone_code)
          );

          setCodeList(uniqueArray);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          setBranchList(data);
          const defaultBranch = data.find(
            (i) => i.id === employeedata.branch?.id
          );
          // default branch
          if (defaultBranch) {
            const defaultBranchIds = defaultBranch?.id;
            setBranchName(defaultBranch);
            setValue("branch", defaultBranchIds);
          }

          // default handled Branch
          const defaulthandledBranch = data.filter((i) =>
            employeedata.handling_branch_id.includes(i.branch_name)
          );

          const defaultBranchIds = defaulthandledBranch.map((i) => i.id);
          setBranchhandled(defaulthandledBranch);
          setValue("handling_branch_id", defaultBranchIds);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}role`);
        if (response.ok) {
          const data = await response.json();
          const filterdData = data.filter(
            (i) => i.role_name !== "center_admin"
          );
          setRoleList(filterdData);
          const defaultRole = data.filter((i) =>
            employeedata?.role.includes(i.role_name)
          );

          if (defaultRole) {
            const defaultRoleids = defaultRole.map((i) => i.id);
            setrole(defaultRole);
            setValue("role", defaultRoleids);
          }
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          setServiceList(data);
          const selectedServices = data.filter((i) =>
            employeedata?.service_id.includes(i.service_name)
          );

          const selectedServiceids = selectedServices.map((i) => i.id);
          setService(selectedServices);
          setServiceIds(selectedServiceids);

          setValue("service_id", selectedServiceids);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {}
    };
    fetchData();
  }, [employeedata]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          const subserviceDatalist = data.filter((i) =>
            serviceIds.includes(i.service_id)
          );
          setSubserviceList(subserviceDatalist);

          // default subservice
          const selectedSubServices = subserviceDatalist.filter((i) =>
            employeedata?.sub_service_id.includes(i.name)
          );

          const selectedsubServiceids = selectedSubServices.map((i) => i.id);
          setSubService(selectedSubServices);

          setValue("sub_service_id", selectedsubServiceids);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {}
    };
    fetchData();
  }, [serviceIds]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setUserList(data);
          const defaultRole = data.find(
            (i) =>
              i.first_name.toLowerCase() ===
              employeedata?.branch_manager.toLowerCase()
          );

          if (defaultRole) {
            setBranchManager(defaultRole);
          }

          // const defaultReporcc = data.find(
          //   (i) =>
          //     i.first_name.toLowerCase() ===
          //     state?.employeedetails?.reporting_manager?.toLowerCase()
          // );

          // if (defaultReporcc) {
          //   setReportingManager(defaultReporcc);
          // }

          const defaultBackup = data.find(
            (i) => i.id === employeedata?.backup_user?.id
          );

          if (defaultBackup) {
            setBackupUser(defaultBackup);
            setValue("backup_user", defaultBackup?.id);
          }
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}reportingmanager`);
        if (response.ok) {
          const data = await response.json();
          setReportingManagerList(data);
          const defaultReportingManager = data.filter((i) =>
            employeedata?.reporting_manager.includes(
              `${i.first_name} ${i.last_name}`
            )
          );

          if (defaultReportingManager.length > 0) {
            setReportingManager(defaultReportingManager);
          }
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handleBranchChange = (event, value) => {
    setBranchName(value);
    setValue("branch", value);
  };

  useEffect(() => {
    const value = branchName?.admin_id?.user_id;
    const defaultBranchManager = userList.find((i) => i.id === value);
    setBranchManager(defaultBranchManager);
  }, [branchName]);

  const handleRoleChange = (event, value) => {
    setrole(value);
    setValue("role", value);
  };

  const handleCountryChange = (event, value) => {
    setCountry(value);
  };

  const handleServiceChange = (event, value) => {
    setService(value);
    const selectedname = value.map((i) => i.service_name);
    setEmployeeData({
      ...employeedata, // Spread the existing data
      service_id: selectedname, // Update the service_id
    });

    const selectedids = value.map((i) => i.id);
    setValue("service_id", selectedids);
  };

  const handleSubServiceChange = (event, value) => {
    setSubService(value);
    const selectedids = value.map((i) => i.id);
    setValue("sub_service_id", selectedids);
  };

  const handleWorkBranchChange = (event, value) => {
    setWorkBranch(value);
  };

  const handleBranchManagerChange = (event, value) => {
    setBranchManager(value);
  };

  const handlereportManagerChange = (event, value) => {
    setReportingManager(value);
  };

  const alluserList = [{ first_name: "Other", email: null }, ...userList];
  const handlereportCCChange = (event, value) => {
    setReportUser(value);
  };

  const CustomPaper = (props) => {
    return (
      <Paper
        {...props}
        style={{
          maxHeight: 200,
          overflow: "auto",
          zIndex: 1500, // Adjust z-index as needed
          // Add any other custom styles here
        }}
      />
    );
  };

  // Change add cc filed value
  const selectedccUser = reportUser.map((i) => i.first_name);
  const handlereportCCChange1 = (index, event) => {
    const newReportUsers = [...reportUsers];
    newReportUsers[index].value = event.target.value;
    setReportUsers(newReportUsers);
  };

  //  Add More cc Field
  const handleAddMore = () => {
    setReportUsers([...reportUsers, { id: reportUsers.length + 1, value: "" }]);
  };

  // Removed TextField  cc
  const handleRemoveField = (index) => {
    const newReportUsers = reportUsers.filter((_, i) => i !== index);
    // Ensure there's always at least one field
    if (newReportUsers.length === 0) {
      setReportUsers([{ id: 1, value: "" }]);
    } else {
      setReportUsers(newReportUsers);
    }
  };

  const handleWorkbranch = (event, value) => {
    setBranchhandled(value);
    const selectedeId = value.map((i) => i.id);
    setValue("handling_branch_id", selectedeId);
  };

  const handleBackupUserchange = (event, value) => {
    setBackupUser(value);
    setValue("backup_user", value?.id);
  };

  const handleChangestatus = (event) => {
    setIsautoassign(event.target.checked);
    console.log(event.target.checked, "event.target.checked");

    setService([]);
    setServiceIds([]);
    setBackupUser(null);
    setBranchhandled([]);
    setValue("is_auto_assigner", event.target.checked);
    setValue("service_id", []);
    setValue("sub_service_id", []);
    setValue("handling_branch_id", []);
    setValue("backup_user", null);
  };
  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2} sm={3} md={2.5}>
          <HeadingCard label="Edit Employee" />
        </Grid>
        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ color: "#e50000" }}>
                  Mandatory Information
                </Typography>
              </Grid>

              {/* Employee Name  */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">
                  First Name{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.2em",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Controller
                  name="first_name"
                  control={control}
                  defaultValue={employeedata?.first_name}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      size="normal"
                      {...field}
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      fullWidth
                      error={errors.first_name}
                      helperText={errors.first_name && "First name is required"}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">Last Name </Typography>
                <Controller
                  name="last_name"
                  control={control}
                  defaultValue={employeedata?.last_name}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      size="normal"
                      {...field}
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">
                  Email{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.2em",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Controller
                  name="email"
                  control={control}
                  defaultValue={employeedata?.email}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Please enter a valid email address",
                    },
                  }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      size="normal"
                      {...field}
                      InputProps={{
                        style: { borderRadius: "10px" },
                        readOnly: true,
                      }}
                      fullWidth
                      error={errors.email}
                      helperText={errors.email && errors.email.message}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "#b2b2b4", // Keep the border color gray on mouseover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#b2b2b4", // Keep the border color gray even on focus
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              {/* Mobile */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Grid container>
                  <Grid item xs={12} lg={4} md={4} sm={6}>
                    <Typography variant="h6">ISD Code </Typography>
                    <Controller
                      name="mobile_country_code"
                      control={control}
                      defaultValue={employeedata?.mobile_country_code}
                      render={({ field }) => (
                        <CustomTextFieldComp
                          {...field}
                          select
                          fullWidth
                          size="normal"
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                              height: "37px",
                            },
                          }}
                          SelectProps={{
                            MenuProps: {
                              disableScrollLock: true,
                              sx: { maxHeight: "50%" },
                            },
                          }}
                        >
                          {codeList.map((code) => (
                            <MenuItem
                              key={code?.phone_code}
                              value={code?.phone_code}
                            >
                              {code?.phone_code}
                            </MenuItem>
                          ))}
                        </CustomTextFieldComp>
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} lg={8} md={8} sm={6}>
                    <Typography variant="h6">Mobile No </Typography>
                    <Controller
                      name="mobile"
                      control={control}
                      defaultValue={employeedata?.mobile}
                      rules={{
                        // required: "Mobile number is required",
                        pattern: {
                          value: /^[0-9]{10}$/, // Example regex for 10-digit mobile numbers
                          message:
                            "Please enter a valid 10-digit mobile number",
                        },
                      }}
                      render={({ field }) => (
                        <CustomTextFieldComp
                          {...field}
                          fullWidth
                          size="normal"
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                              height: "37px",
                            },
                          }}
                          error={!!errors.mobile}
                          helperText={
                            errors.mobile ? errors.mobile.message : ""
                          }
                        />
                      )}
                    ></Controller>
                  </Grid>
                </Grid>
              </Grid>

              {/* Gender */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <FormControl fullWidth>
                  <Typography variant="h6">Gender </Typography>
                  <Controller
                    name="gender"
                    control={control}
                    defaultValue={employeedata?.gender}
                    render={({ field }) => (
                      <CustomTextFieldComp
                        {...field}
                        fullWidth
                        size="normal"
                        select
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                            height: "37px",
                          },
                        }}
                      >
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </CustomTextFieldComp>
                    )}
                  ></Controller>{" "}
                </FormControl>
              </Grid>

              {/*Date of Birth */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">Date of Birth </Typography>
                <Controller
                  name="dob"
                  control={control}
                  defaultValue={employeedata?.dob}
                  rules={{
                    validate: (value) => {
                      const today = new Date();
                      const birthDate = new Date(value);
                      const age = today.getFullYear() - birthDate.getFullYear();
                      const monthDiff = today.getMonth() - birthDate.getMonth();
                      const dayDiff = today.getDate() - birthDate.getDate();

                      // Check if user is less than 18 years old
                      if (
                        age < 18 ||
                        (age === 18 &&
                          (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)))
                      ) {
                        return "You must be at least 18 years old";
                      }
                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      {...field}
                      fullWidth
                      size="normal"
                      type="date"
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          height: "37px",
                        },
                      }}
                      error={!!errors.dob}
                      helperText={errors.dob ? errors.dob.message : ""}
                    />
                  )}
                >
                  {" "}
                </Controller>
              </Grid>

              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">Date of Joining </Typography>
                <Controller
                  name="joining_date"
                  control={control}
                  defaultValue={employeedata?.joining_date}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      {...field}
                      fullWidth
                      size="normal"
                      type="date"
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          height: "37px",
                        },
                      }}
                    />
                  )}
                ></Controller>
              </Grid>

              {/* Designation */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">
                  Designation{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.2em",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Controller
                  name="designation"
                  control={control}
                  defaultValue={employeedata?.designation}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      {...field}
                      fullWidth
                      size="normal"
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          height: "37px",
                        },
                      }}
                      error={errors.designation}
                      helperText={
                        errors.designation && "Designation is required"
                      }
                    />
                  )}
                ></Controller>
              </Grid>

              {/* Select Role */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">
                  Select Role{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.2em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="role"
                  control={control}
                  defaultValue={employeedata?.role?.id}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomAutocompleteComp
                      {...field}
                      size="normal"
                      readOnly={userRole !== "data_entry"}
                      multiple
                      components={{
                        Paper: CustomPaper, // Use your custom Paper component here
                      }}
                      disablePortal
                      value={role}
                      onChange={handleRoleChange}
                      options={roleList}
                      getOptionLabel={(option) => option.role_name}
                      tooltipContent={(option) => option.role_name}
                      error={errors.role}
                      helperText={errors.role && "Role is required"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "10px" }, // Add borderRadius here
                          }}
                          sx={
                            userRole !== "data_entry"
                              ? {
                                  "& .MuiOutlinedInput-root": {
                                    "&:hover fieldset": {
                                      borderColor: "#b2b2b4", // Keep the border color gray on mouseover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#b2b2b4", // Keep the border color gray even on focus
                                    },
                                  },
                                }
                              : {}
                          }
                        />
                      )}
                    />
                  )}
                ></Controller>
              </Grid>
              {/* Branch  */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">
                  Branch{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.2em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="branch"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomAutocompleteComp
                      {...field}
                      disablePortal
                      readOnly={userRole !== "data_entry"}
                      value={branchName}
                      onChange={handleBranchChange}
                      options={BranchList}
                      getOptionLabel={(option) => option.branch_name}
                      error={errors.branch}
                      helperText={errors.role && "Branch is required"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "10px" }, // Add borderRadius here
                          }}
                          sx={
                            userRole !== "data_entry"
                              ? {
                                  "& .MuiOutlinedInput-root": {
                                    "&:hover fieldset": {
                                      borderColor: "#b2b2b4", // Keep the border color gray on mouseover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#b2b2b4", // Keep the border color gray even on focus
                                    },
                                  },
                                }
                              : {}
                          }
                        />
                      )}
                    />
                  )}
                ></Controller>
              </Grid>

              {/* Branch  Manager   */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">Branch Manager </Typography>

                <Controller
                  name="branch_manager"
                  control={control}
                  render={({ field }) => (
                    <CustomAutocompleteComp
                      {...field}
                      readOnly={userRole !== "data_entry"}
                      disablePortal
                      value={branchManager}
                      onChange={handleBranchManagerChange}
                      options={userList}
                      getOptionLabel={(option) =>
                        `${option.first_name ? option.first_name : " "} ${
                          option.last_name ? option.last_name : " "
                        }`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "10px" }, // Add borderRadius here
                          }}
                          sx={
                            userRole !== "data_entry"
                              ? {
                                  "& .MuiOutlinedInput-root": {
                                    "&:hover fieldset": {
                                      borderColor: "#b2b2b4", // Keep the border color gray on mouseover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#b2b2b4", // Keep the border color gray even on focus
                                    },
                                  },
                                }
                              : {}
                          }
                        />
                      )}
                    />
                  )}
                ></Controller>
              </Grid>

              {/* Reporting  Manager  */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">
                  Reporting Manager{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.2em",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Controller
                  name="reporting_manager"
                  control={control}
                  render={({ field }) => (
                    <CustomAutocompleteComp
                      {...field}
                      size="normal"
                      multiple
                      disablePortal
                      value={reportingManager}
                      onChange={handlereportManagerChange}
                      options={reportingManagerList}
                      getOptionLabel={(option) =>
                        `${option.first_name ? option.first_name : " "} ${
                          option.last_name ? option.last_name : " "
                        }`
                      }
                      tooltipContent={(option) =>
                        `${option.first_name ? option.first_name : " "} ${
                          option.last_name ? option.last_name : " "
                        }`
                      }
                    />
                  )}
                ></Controller>
              </Grid>

              {/* Work On Country */}
              {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">
                  Work On Country{" "}
                </Typography>

                <Autocomplete
                  size="normal"
                  multiple
                  disablePortal
                  value={country}
                  onChange={handleCountryChange}
                  options={countryList}
                  getOptionLabel={(option) => option.country_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "10px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

              {/* Work On Service */}
              {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">
                  Work On Service{" "}
                </Typography>
               
                <Autocomplete
                  size="normal"
                  multiple
                  disablePortal
                  value={service}
                  onChange={handleServiceChange}
                  options={serviceList}
                  getOptionLabel={(option) => option.service_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "10px",  }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

              {/* Work On SubService */}
              {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">
                  Work On SubService{" "}
                </Typography>
                
                <Autocomplete
                  size="normal"
                  multiple
                  disablePortal
                  value={subService}
                  onChange={handleSubServiceChange}
                  options={subServiceList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "10px",  }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

              {/*  Branch Type */}
              {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">
                  Branch Type{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="normal"
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid> */}

              {/*Work On Branch */}
              {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">
                  Work On Branch{" "}
                </Typography>
                
                  
                <Autocomplete
                  size="normal"
                  multiple
                  disablePortal
                  value={workBranch}
                  onChange={handleWorkBranchChange}
                  options={BranchList}
                  getOptionLabel={(option) => option.branch_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "10px",  }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

              {/*Report CC User */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography variant="h6">Select Report CC User </Typography>

                <Controller
                  name="add_report_cc_user"
                  control={control}
                  render={({ field }) => (
                    <CustomAutocompleteComp
                      {...field}
                      size="normal"
                      disablePortal
                      multiple
                      value={reportUser}
                      onChange={handlereportCCChange}
                      options={alluserList}
                      getOptionLabel={(option) =>
                        `${option.first_name ? option.first_name : " "} ${
                          option.last_name ? option.last_name : " "
                        } ${option.email ? `:- ${option.email}` : " "}`
                      }
                      tooltipContent={(option) =>
                        `${option.first_name ? option.first_name : " "} ${
                          option.last_name ? option.last_name : " "
                        } ${option.email ? `:- ${option.email}` : " "}`
                      }
                      renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps } = props;
                        return (
                          <li key={key} {...optionProps}>
                            {`${option.first_name ? option.first_name : " "} ${
                              option.last_name ? option.last_name : " "
                            } ${option.email ? `:- ${option.email}` : " "}`}
                          </li>
                        );
                      }}
                    />
                  )}
                ></Controller>
              </Grid>
              {/* {selectedccUser.includes("Other") && (
                < >
                <Grid item xs={12} lg={3} md={3} sm={4}>
                  <Typography variant="h6">
                    Add Report CC User{" "}
                  </Typography>
                  <Controller
                  name="add_report_cc_user"
                  control={control}
                
               
                  render={({ field }) => (
                  <TextField
                  {...field}
                    fullWidth
                    size="normal"
                    value={reportUser1}
                    onChange={handlereportCCChange1}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        height: "37px",
                      },
                    }}
                  />
                  )}></Controller>
                </Grid>
                <Grid item xs={12} lg={3} md={3} sm={4}>
                  <Typography sx={{ visibility:'hidden'}}> Spacing Purpose</Typography>
                <Button variant="contained" color="secondary" size="normal">Add More</Button>
                </Grid>
                </>
              )} */}

              {selectedccUser.includes("Other") &&
                reportUsers.map((reportUser, index) => (
                  <React.Fragment key={reportUser.id}>
                    <Grid item xs={12} lg={3} md={3} sm={4}>
                      <Typography variant="h6">Add Report CC User</Typography>
                      <Controller
                        name={`add_report_cc_user_${reportUser.id}`}
                        control={control}
                        render={({ field }) => (
                          <CustomTextFieldComp
                            {...field}
                            fullWidth
                            size="normal"
                            value={reportUser.value}
                            onChange={(e) => handlereportCCChange1(index, e)}
                            InputProps={{
                              style: {
                                borderRadius: "10px",
                                height: "37px",
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={() => handleRemoveField(index)}
                                  >
                                    <CloseIcon style={{ fontSize: "1rem" }} />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {index === reportUsers.length - 1 && (
                      <Grid item>
                        <Typography sx={{ visibility: "hidden" }}>
                          Spacing Purpose
                        </Typography>

                        <SecondaryButton
                          size="small"
                          label="Add More"
                          onClick={handleAddMore}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}

              <>
                <Grid item xs={12}>
                  <Typography variant="h4" style={{ color: "#00b13f" }}>
                    {" "}
                    Auto Assign Information
                  </Typography>
                </Grid>

                {/* Auto assign  */}
                <Grid item xs={12} container gap={1} alignItems="center">
                  <Typography variant="h5">Auto Assign Lead </Typography>
                  <Controller
                    name="is_auto_assigner"
                    control={control}
                    // rules={{ required: true }}

                    render={({ field }) => (
                      <Switch
                        {...field}
                        disabled={!downloadAccess}
                        checked={isAutoAssign}
                        onChange={handleChangestatus}
                        size="small"
                        color="secondary"
                        sx={{
                          "& .MuiSwitch-switchBase": {
                            padding: 1,
                            height: 1,
                          },
                          "& .MuiSwitch-thumb": {
                            width: 12, // Thumb size
                            height: 12,
                            background: isAutoAssign ? "#00b13f" : "#f44336", // Green when active, Red when inactive
                          },
                          "& .MuiSwitch-track": {
                            backgroundColor: isAutoAssign
                              ? "#e0f7e0"
                              : "#ff9fad", // Light green for active, light red for inactive
                            opacity: 1, // Ensure visibility
                          },
                        }}
                      />
                    )}
                  ></Controller>
                </Grid>
                {isAutoAssign && (
                  <>
                    {/* Service Field  */}
                    <Grid item xs={12} lg={3} md={4} sm={6}>
                      <Typography variant="h6">
                        Service{" "}
                        <span
                          style={{
                            color: "red",
                            position: "absolute",
                            marginLeft: "4px",
                            fontSize: "0.7em",
                          }}
                        >
                          *
                        </span>
                      </Typography>

                      <Controller
                        name="service_id"
                        control={control}
                        rules={{ required: isAutoAssign ? true : false }}
                        render={({ field }) => (
                          <CustomAutocompleteComp
                            {...field}
                            size="small"
                            readOnly={!downloadAccess}
                            multiple
                            disablePortal
                            value={service}
                            onChange={handleServiceChange}
                            options={serviceList}
                            getOptionLabel={(option) => option.service_name}
                            error={errors.service_id}
                            helperText={
                              errors.service_id && "Service is required"
                            }
                            tooltipContent={(option) => option.service_name}
                          />
                        )}
                      ></Controller>
                    </Grid>

                    {/* Subservice  */}
                    {service.length > 0 && (
                      <Grid item xs={12} lg={3} md={4} sm={6}>
                        <Typography variant="h6">
                          Sub Service{" "}
                          <span
                            style={{
                              color: "red",
                              position: "absolute",
                              marginLeft: "4px",
                              fontSize: "0.7em",
                            }}
                          >
                            *
                          </span>
                        </Typography>

                        <Controller
                          name="sub_service_id"
                          control={control}
                          rules={{ required: isAutoAssign ? true : false }}
                          render={({ field }) => (
                            <CustomAutocompleteComp
                              {...field}
                              size="small"
                              multiple
                              readOnly={!downloadAccess}
                              disablePortal
                              value={subService}
                              onChange={handleSubServiceChange}
                              options={subServiceList}
                              getOptionLabel={(option) => option.name}
                              error={errors.sub_service_id}
                              helperText={
                                errors.sub_service_id &&
                                "Sub Service is required"
                              }
                              tooltipContent={(option) => option.name}
                            />
                          )}
                        ></Controller>
                      </Grid>
                    )}

                    {/* Branch Handled */}
                    <Grid item xs={12} lg={3} md={4} sm={6}>
                      <Typography variant="h6">
                        Branch Handled{" "}
                        <span
                          style={{
                            color: "red",
                            position: "absolute",
                            marginLeft: "4px",
                            fontSize: "0.7em",
                          }}
                        >
                          *
                        </span>
                      </Typography>

                      <Controller
                        name="handling_branch_id"
                        control={control}
                        rules={{ required: isAutoAssign ? true : false }}
                        render={({ field }) => (
                          <CustomAutocompleteComp
                            {...field}
                            size="small"
                            readOnly={!downloadAccess}
                            multiple
                            disablePortal
                            value={branchHandled}
                            onChange={handleWorkbranch}
                            options={BranchList}
                            getOptionLabel={(option) => option.branch_name}
                            error={errors.handling_branch_id}
                            helperText={
                              errors.handling_branch_id && "Branch is required"
                            }
                            tooltipContent={(option) => option.branch_name}
                          />
                        )}
                      ></Controller>
                    </Grid>

                    {/* Backup Field */}
                    <Grid item xs={12} lg={3} md={4} sm={6}>
                      <Typography variant="h6">
                        Backup{" "}
                        <span
                          style={{
                            color: "red",
                            position: "absolute",
                            marginLeft: "4px",
                            fontSize: "0.7em",
                          }}
                        >
                          *
                        </span>
                      </Typography>

                      <Controller
                        name="backup_user"
                        control={control}
                  
                        rules={{ required: true }}
                        render={({ field }) => (
                          <CustomAutocompleteComp
                            {...field}
                            size="small"
                            disablePortal
                            readOnly={!downloadAccess}
                            value={backupUser}
                            onChange={handleBackupUserchange}
                            options={userList}
                            getOptionLabel={(option) =>
                              `${option.first_name ? option.first_name : " "} ${
                                option.last_name ? option.last_name : " "
                              }`
                            }
                            error={errors.backup_user}
                            helperText={
                              errors.backup_user && "Backup user is required"
                            }
                            tooltipContent={(option) =>
                              `${option.first_name ? option.first_name : " "} ${
                                option.last_name ? option.last_name : " "
                              }`
                            }
                          />
                        )}
                      ></Controller>
                    </Grid>
                  </>
                )}
              </>

              <Grid item xs={12} textAlign="end">
                <SecondaryButton
                  size="medium"
                  onClick={handleSubmit(onSubmit)}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
