import React from 'react'
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Tooltip } from '@mui/material';

export default function VeiwIcon({onClick, fontSize, tooltipTitle}) {
  return (
   <>
    <Tooltip title={tooltipTitle}>
    <VisibilityOutlinedIcon
            
                style={{
                  color: "#00b13f",
                  fontSize: fontSize || "1.2rem",
                  cursor: "pointer",
                }}
                onClick={ onClick
                }
              />
                </Tooltip>
   </>
  )
}
