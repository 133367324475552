import { Tooltip } from '@mui/material'
import React from 'react'
import EditIcon from "@mui/icons-material/Edit";
export default function EditIconcomp({tooltipTitle,onClick, fontSize}) {
  return (
 <>
   <Tooltip title={tooltipTitle}>
                  <EditIcon
                    style={{ fontSize: fontSize || "1.2rem", cursor: "pointer" , color:"#00b13f"}}
                    variant="contained"
               
                    onClick={onClick}
                  />
                </Tooltip>
 </>
  )
}
