// Author : Ayesha Shaikh
//  Date : 29 Aug 2024

import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import { DataGrid } from "@mui/x-data-grid";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import DeclinedPaymentFilter from "./DeclinedPaymentFilter";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
import CustomNoRowsOverlay from "../../newComponent/TableCustomeComp/CustomNoRowsOverlay";
import { fDate } from "../../../utils/formatTime";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import UploadIconcomp from "../../newComponent/iconsComp/UoloadIconcomp";
import SearchTextFieldComp from "../../newComponent/newtextfieldComp/SearchTextFieldComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function DeclinedPayment() {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const [declinedPaymentData, setDeclinedPaymentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [originalData, setOriginalData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openUploadDepositDialog, setOpenUploadDepositDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openRows, setOpenRows] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  //   Fetch Decline Payment Data
  useEffect(() => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}route/fechdeclinedpayments/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();

          const sortedData = data.sort(
            (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
          );

          setDeclinedPaymentData(sortedData);
          setOriginalData(sortedData);
          setSearchData(sortedData);
          setIsLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch {
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId]);

  //   Cancel Payment

  const handleCancelPayment = async (id) => {
    const formdata = {
      provesional_payment_id: id,
      user_id: parseInt(userId),
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route/canceldeclinedpayment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      );

      if (response.ok) {
        showSnackbar("Payment Cancelled Successfully");
        const data = declinedPaymentData.filter((item) => {
          return item.id != id;
        });
        setDeclinedPaymentData(data);
      } else if (response.status === 400) {
        showSnackbar("Provesional payment id not found ", "error");
      } else if (response.status === 401) {
        showSnackbar("Unathorized User", "error");
      } else {
        showSnackbar("Error Posting Data", "error");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      showSnackbar("Error Posting Data", "error");
    }
  };

  // Change File
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  //   Upload Payment Screenshot

  const handleOpenUploadDialog = (id) => {
    setOpenUploadDialog(true);
    setSelectedId(id);
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setSelectedFile(null);
  };

  const handleAddUploadPaymentScrrenshot = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("user_id", userId);
      formData.append("provesional_payment_id", selectedId);

      console.log(formData);

      try {
        const response = await fetch(
          `${API_ENDPOINT}route/reuploadpaymentscreenshot`,
          {
            method: "POST",

            body: formData,
          }
        );

        if (response.ok) {
          showSnackbar("Payment screenshot uploaded successfully");

          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else if (response.status === 400) {
          showSnackbar("Provesional payment id not found ", "error");
        } else if (response.status === 401) {
          showSnackbar("Unathorized User", "error");
        } else {
          showSnackbar("Error Posting Data", "error");
        }
      } catch (error) {
        console.error("Error posting data:", error);
        showSnackbar("Error Posting Data", "error");
      }
    }
  };

  //   Upload Deposit Slip

  const handleOpenUploadDepositDialog = (id) => {
    setOpenUploadDepositDialog(true);
    setSelectedId(id);
  };

  const handleCloseUploadDepositDialog = () => {
    setOpenUploadDepositDialog(false);
    setSelectedFile(null);
  };

  const handleAddUploadPaymentDeposit = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("user_id", userId);
      formData.append("provesional_payment_id", selectedId);

      console.log(formData);

      try {
        const response = await fetch(
          `${API_ENDPOINT}route/reuploaddepositslip`,
          {
            method: "POST",

            body: formData,
          }
        );

        if (response.ok) {
          showSnackbar("Deposit slip uploaded successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else if (response.status === 400) {
          showSnackbar("Provesional payment id not found ", "error");
        } else if (response.status === 401) {
          showSnackbar("Unathorized User", "error");
        } else {
          showSnackbar("Error Posting Data", "error");
        }
      } catch (error) {
        console.error("Error posting data:", error);
        showSnackbar("Error Posting Data", "error");
      }
    }
  };

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (rowId) => {
    setOpenRows((prevOpenRows) =>
      prevOpenRows.includes(rowId)
        ? prevOpenRows.filter((id) => id !== rowId)
        : [...prevOpenRows, rowId]
    );
  };

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 70,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {(params?.row?.created_by?.id === parseInt(userId) ||
            userRole === "center_admin") && (
            <>
              <Tooltip title="Cancel Payment">
                <CancelPresentationIcon
                  style={{ fontSize: "1.2rem" }}
                  variant="contained"
                  color="error"
                  onClick={() => handleCancelPayment(params?.row?.id)}
                />
              </Tooltip>

              {params?.row?.payment_mode_id?.payment_mode === "Cash" ? (
                <Tooltip title="Upload New Deposit Slip">
                  <span>
                    <UploadIconcomp
                      variant="contained"
                      color="secondary"
                      style={{ marginLeft: "10px" }}
                      onClick={() =>
                        handleOpenUploadDepositDialog(params?.row?.id)
                      }
                    />
                  </span>
                </Tooltip>
              ) : (
                <Tooltip title="Upload New Payment Screenshot">
                  <span>
                    <UploadIconcomp
                      variant="contained"
                      color="secondary"
                      style={{ marginLeft: "10px" }}
                      onClick={() =>
                        handleOpenUploadDialog(
                          params?.row?.id,
                          "Upload New Payment Screenshot"
                        )
                      }
                    />
                  </span>
                </Tooltip>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.enquiry_id?.first_name || ""} ${
          params?.row?.enquiry_id?.last_name || ""
        }`,
      renderCell: (params) => (
        <div
          style={{
            color: "#0096FF",

            cursor: "pointer",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          onClick={() =>
            window.open(
              `/enquirydetails/${params?.row.enquiry_id.id}`,
              "_blank"
            )
          }
        >
          {" "}
          {`${
            params?.row?.enquiry_id?.first_name
              ? params?.row?.enquiry_id?.first_name
              : ""
          } ${
            params?.row?.enquiry_id?.last_name
              ? params?.row?.enquiry_id?.last_name
              : ""
          }`}{" "}
        </div>
      ),
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 100,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row?.enquiry_id?.mobile || "",
      renderCell: (params) => <div>{params?.row.enquiry_id?.mobile}</div>,
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 200,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        params?.row?.enquiry_id?.branch_id?.branch_name || "",
      renderCell: (params) => (
        <div>{params?.row?.enquiry_id?.branch_id?.branch_name}</div>
      ),
    },
    {
      field: "payment_date",
      headerName: "Date",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params?.row?.payment_date)}</div>,
    },
    {
      field: "payment_mode_id",
      headerName: "Payment Mode",
      width: 100,
      headerClassName: "header-cell",
      valueGetter: (params) => params?.row?.payment_mode_id?.payment_mode || "",
      renderCell: (params) => (
        <div>{params?.row?.payment_mode_id?.payment_mode}</div>
      ),
    },
    {
      field: "amount_paid",
      headerName: "Amount",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.amount_paid}</div>,
    },
    {
      field: "collected_by",
      headerName: "Collected By",
      width: 150,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.created_by?.first_name || ""} ${
          params?.row?.created_by?.last_name || ""
        }`,
      renderCell: (params) => (
        <div>
          {" "}
          {params?.row.created_by?.first_name}{" "}
          {params?.row?.created_by?.last_name}
        </div>
      ),
    },
    {
      field: "declined_by",
      headerName: "Declined By",
      width: 150,
      headerClassName: "header-cell",
      valueGetter: (params) =>
        `${params?.row?.declined_by?.first_name || ""} ${
          params?.row?.declined_by?.last_name || ""
        }`,
      renderCell: (params) => (
        <div>
          {" "}
          {params?.row.declined_by?.first_name}{" "}
          {params?.row?.declined_by?.last_name}
        </div>
      ),
    },
    {
      field: "declined_date",
      headerName: "Declined Date",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params?.row?.declined_date)}</div>,
    },
    {
      field: "declined_remarks",
      headerName: "Remarks",
      width: 350,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params?.row?.declined_remarks}</div>,
    },
  ];

  // Search All column data
  const handleSearchByName = (e) => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, ""); // Normalize search value (lowercase and remove spaces)

    let filteredData1 = searchData.filter((data) => {
      // Check each field for a match with the search value
      const fieldsToSearch = [
        `${data?.enquiry_id?.first_name || ""} ${
          data?.enquiry_id?.last_name || ""
        }`,

        `${data?.enquiry_id?.mobile || ""}`,
        `${data?.enquiry_id?.branch_id?.branch_name || ""}`,
        `${data?.payment_mode_id?.payment_mode || ""}`,

        `${fDate(data?.payment_date || "")}`,
        `${data?.amount_paid || ""}`,
        `${data?.created_by?.first_name || ""} ${
          data?.created_by?.last_name || ""
        }`,
        `${data?.approved_by?.first_name || ""} ${
          data?.approved_by?.last_name || ""
        }`,
        `${fDate(data?.declined_date || "")}`,

        `${
          data?.collected_status === "received"
            ? "generated"
            : data?.collected_status || ""
        }`,

        `${data?.declined_remarks || ""}`,
      ];

      // Check if any of the fields contain the search value
      return fieldsToSearch.some((field) =>
        field.toLowerCase().replace(/\s+/g, "").includes(value)
      );
    });

    setDeclinedPaymentData(filteredData1); // Update the filtered data
  };
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DeclinedPaymentFilter
            setPayments={setDeclinedPaymentData}
            originalData={originalData}
            isLoading={isLoading}
            showSnackbar={showSnackbar}
            setIsLoading={setIsLoading}
            setSearchData={setSearchData}
          />
        </Grid>

        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="end">
                <SearchTextFieldComp onChange={handleSearchByName} />
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={declinedPaymentData}
                  columns={columns}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[25, 50, 100]}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {/* Upload Payment Screenshot */}
      <Dialog open={openUploadDialog} onClose={handleCloseUploadDialog}>
        <DialogTitle variant="h4"> Upload New Payment Screenshot </DialogTitle>
        <DialogContent>
          <TextField type="file" onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseUploadDialog}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddUploadPaymentScrrenshot}
            variant="contained"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      {/* Upload Deposit Slip */}

      <Dialog
        open={openUploadDepositDialog}
        onClose={handleCloseUploadDepositDialog}
      >
        <DialogTitle variant="h4"> Upload New Deposit Slip </DialogTitle>
        <DialogContent>
          <TextField type="file" onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseUploadDepositDialog}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button onClick={handleAddUploadPaymentDeposit} variant="contained">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
