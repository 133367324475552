import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { fDate } from "../../../../../utils/formatTime";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentIcon from "@mui/icons-material/Payment";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PaymentPopup from "./PaymentPopup";
import PaymentForm from "./PaymentForm";
import CustomSnackbar from "../../../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { Link } from "react-router-dom";
import StyledTableContainer from "../../../../../newTestPrep/newComponent/TableContainer";
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import PlanStatusForm from "./PlanStatusForm";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function EnquiryPayments({ payments, enquiryId, loading, isReadOnly }) {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [data, setData] = useState(payments);

  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const [refundType, setRefundType] = useState("full");
  const [refundAmount, setRefundAmount] = useState("");
  const [refundReason, setRefundReason] = useState("");
  const [open, setOpen] = useState(false);
  const [openPlanStatus, setOpenPlanStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [newLinkdialogOpen, setnewLinkdialogOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOpen = (row) => {
    if (userRole !== "advisor") {
      showSnackbar(
        "Please login as an advisor role,to generate new pending payment request",
        "error"
      );
      return;
    }
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenPlanStatus = (row) => {
    if (userRole !== "advisor") {
      showSnackbar(
        "Please login as an advisor role to change plan status",
        "error"
      );
      return;
    }
    setSelectedRow(row);
    setOpenPlanStatus(true);
  };

  const handleClosePlanStatus = () => {
    setOpenPlanStatus(false);
  };

  const handleInitiateRefund = (row) => {
    setRefundDialogOpen(true);
  };

  const handleRefundDialogClose = () => {
    setRefundDialogOpen(false);
    // Reset refund-related state variables
    setRefundType("full");
    setRefundAmount("");
    setRefundReason("");
  };

  const handleRefundSubmit = () => {
    // Handle refund submission logic here
    console.log(`Refund type: ${refundType}`);
    console.log(`Refund amount: ${refundAmount}`);
    console.log(`Refund reason: ${refundReason}`);
    // Close the dialog after handling the refund
    handleRefundDialogClose();
  };

  const handleCancelPayment = (id) => {
    fetch(`${API_ENDPOINT}lmprovesionpayment/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payment_status: "cancelled",
        is_active: 0,
        user_id: userId,
        is_cancelled: true,
      }),
    })
      .then((response) => {
        if (response.ok) {
          // Handle success, e.g., show a success message
          console.log("Payment cancelled successfully");
          // alert('Payment Details sent successfully')
          showSnackbar("Payment cancelled successfully", "success");
        } else {
          // Handle failure, e.g., show an error message
          console.error("Failed to cancel request");
          showSnackbar("Failed to cancel request", "error");
          //alert('Error sending payment details')
        }
      })
      .catch((error) => {
        console.error("Error cancelling request:", error);
        showSnackbar("Error cancelling request", "error");
        // alert('Error sending payment details')
      });
  };

  const handleGenerateNewPaymentLink = (row) => {
    setSelectedRow(row);
    setnewLinkdialogOpen(true);
  };

  const handleGeneratePaymentLink = () => {
    const id = selectedRow.id;
    const data = {
      amount_paid: selectedRow.amount_paid,
      enquiry_id: selectedRow.enquiry_id,
      class_id: selectedRow.class_id.id,
      package_id: selectedRow.package_id.id,
      user_id: userId,
    };
    fetch(`${API_ENDPOINT}lmprovesionpayment/recreaterazorpaylink/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          // Handle success, e.g., show a success message
          console.log("Payment Link created successfully");
          // alert('Payment Details sent successfully')
          handlenewLinkCloseDialog();
          showSnackbar("Payment Link created successfully", "success");
        } else {
          // Handle failure, e.g., show an error message
          console.error("Failed to create link");
          showSnackbar("Failed to create payment link", "error");
          //alert('Error sending payment details')
        }
      })
      .catch((error) => {
        console.error("Error creating payment link:", error);
        showSnackbar("Error creating payment link", "error");
        // alert('Error sending payment details')
      });
  };

  const handlenewLinkCloseDialog = () => {
    setSelectedRow(null);
    setnewLinkdialogOpen(false);
  };

  const copyLinkToClipboard = (row) => {
    const link = row?.payment_link_object?.short_url;
    console.log(link);
    navigator.clipboard
      .writeText(link)
      .then(() => {
        console.log("Link copied to clipboard:", link);
        showSnackbar("Link copied to clipboard", "success");
      })
      .catch((error) => {
        console.error("Error copying link to clipboard:", error);
        showSnackbar("Error copying link to clipboard:", "error");
      });
  };

  console.log(selectedRow);

  return (
    <>
      <Typography textAlign="left" variant="h4" fontWeight={700}>
        Payment History
      </Typography>
      {loading ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {payments.length === 0 ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">No payment history available</Typography>
            </Grid>
          ) : (
            <StyledTableContainer
              component={Paper}
              sx={{ borderRadius: "15px", padding: "10px" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Class Details</TableCell>
                    <TableCell>Package</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Discount</TableCell>
                    <TableCell>Pending Amount</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Payment Mode</TableCell>
                    <TableCell>Payment Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((row, index) => (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#f0f0f0" : "inherit",
                        width: "6%",
                      }}
                    >
                      <TableCell>{row?.class_id?.name}</TableCell>
                      <TableCell
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {row?.is_class_extension ? (
                          "Class Extension"
                        ) : (
                          <>
                            <strong>{row?.package_id?.package_name}</strong>
                            <Typography variant="h6" color="textSecondary">
                              {row?.package_id?.testType?.name || ""}{" "}
                              {row?.package_id?.subTestType?.name
                                ? `(${row?.package_id?.subTestType?.name})`
                                : ""}
                            </Typography>
                          </>
                        )}
                      </TableCell>
                      <TableCell>{row?.amount_paid}</TableCell>
                      <TableCell>
                        {row?.discount_amount !== null
                          ? row.discount_amount
                          : 0}
                      </TableCell>
                      <TableCell>
                        {row?.pending_amount !== null ? row.pending_amount : 0}
                      </TableCell>
                      <TableCell>{fDate(row?.payment_date)}</TableCell>
                      <TableCell>
                        {row?.payment_mode_id?.payment_mode}
                      </TableCell>
                      <TableCell>
                        <Chip
                        size="small"
                        variant="contained"
                          label={row?.payment_status}
                          color={
                            row?.payment_status === "paid"
                              ? "secondary"
                              : "error"
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {row.payment_status === "pending" && (
                          <>
                            <Tooltip title="Cancel Payment Request" arrow>
                              <IconButton
                                color="error"
                                onClick={() => handleCancelPayment(row.id)}
                              > 
                                <CancelIcon  fontSize="small" style={{fontSize:'0.7rem'}}/>
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                        {row.payment_status === "pending" &&
                          row?.payment_link_object?.status === "expired" && (
                            <>
                              <Tooltip title="Generate New Link" arrow>
                                <IconButton
                                  color="secondary"
                                  onClick={() =>
                                    handleGenerateNewPaymentLink(row)
                                  }
                                >
                                  <PaymentIcon fontSize="small" style={{fontSize:'0.7rem'}}/>
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        {row.payment_status === "pending" &&
                          row?.payment_link_object?.short_url && (
                            <>
                              <Tooltip title="Copy Payment Link" arrow>
                                <IconButton
                                  color="primary"
                                  onClick={() => copyLinkToClipboard(row)}
                                >
                                  <ContentCopyIcon fontSize="small" style={{fontSize:'0.7rem'}}/>
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        {row?.payment_status === "paid" &&
                          row?.receipt_id !== null && (
                            <Link
                              to={`/receipt/${row?.receipt_id?.ref_number}`}
                              target="_blank"
                            >
                              <Tooltip title="Download Receipt" arrow>
                                <IconButton
                                  color="primary"
                                  disabled={isReadOnly}
                                >
                                  <ReceiptIcon fontSize="small" style={{fontSize:'0.9rem'}}/>
                                </IconButton>
                              </Tooltip>
                            </Link>
                          )}
                         

                        {row.payment_status === "paid" &&
                          row?.pending_amount>0 && (
                            <>
                              <Tooltip
                                title="Generate new pending payment request"
                                arrow
                              >
                                <IconButton
                                  color="secondary"
                                  onClick={() => handleOpen(row)}
                                  disabled={isReadOnly}
                                >
                                  <PaymentIcon fontSize="small" style={{fontSize:'0.9rem'}}/>
                                </IconButton>
                              </Tooltip>

                              <PaymentPopup
                                open={open}
                                title="Generate Payment"
                                handleClose={handleClose}
                                rowData={selectedRow}
                                // content={<PaymentForm rowData={selectedRow} />}
                              />
                            </>
                          )}

                      {row.payment_status === "paid" &&
                          row?.pending_amount>0 && (
                            <>
                              <Tooltip
                                title="Plan Status"
                                arrow
                              >
                                <IconButton
                                  color="error"
                                  onClick={() => handleOpenPlanStatus(row)}
                                  disabled={isReadOnly}
                                >
                                  <ArrowDropDownCircleIcon fontSize="small" style={{fontSize:'0.9rem'}} />
                                </IconButton>
                              </Tooltip>

                              <PlanStatusForm
                                open={openPlanStatus}
                                title="Pending Payment Status"
                                handleClose={handleClosePlanStatus}
                                rowData={selectedRow}
                              />
                            </>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          )}
        </>
      )}

      <Dialog open={newLinkdialogOpen} onClose={handlenewLinkCloseDialog}>
        <DialogTitle variant="h4">Generate New Payment Link</DialogTitle>
        <DialogContent>
          <Typography>
            Would you like to generate a new payment link for{" "}
            {selectedRow?.class_id?.name} for payment of Rs{" "}
            {selectedRow?.amount_paid}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={handlenewLinkCloseDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGeneratePaymentLink}
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={refundDialogOpen}
        onClose={handleRefundDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle variant="h2">Initiate Refund</DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="refund-type"
                  name="refund-type"
                  value={refundType}
                  onChange={(e) => setRefundType(e.target.value)}
                >
                  <FormControlLabel
                    value="full"
                    control={<Radio />}
                    label="Full Refund"
                  />
                  <FormControlLabel
                    value="partial"
                    control={<Radio />}
                    label="Partial Refund"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {refundType === "partial" && (
              <>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Refund Amount (INR)"
                    type="number"
                    fullWidth
                    value={refundAmount}
                    onChange={(e) => setRefundAmount(e.target.value)}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} md={12}>
              <InputLabel id="refund-reason-label">Refund Reason</InputLabel>
              <Select
                labelId="refund-reason-label"
                id="refund-reason"
                fullWidth
                value={refundReason}
                onChange={(e) => setRefundReason(e.target.value)}
              >
                <MenuItem value="product-defect">Unavailable</MenuItem>
                <MenuItem value="change-mind">Change of Mind</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRefundDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleRefundSubmit}
            color="secondary"
            variant="contained"
          >
            Submit Refund Request
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default EnquiryPayments;
