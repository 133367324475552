import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";

export default function InfoCard({ name, value, backGround }) {
  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: "10px",
          padding: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#f0f2f4",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: backGround,
            boxShadow: "0px  4px 8px rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" sx={{ color: "#848080" }}>
            {name}
          </Typography>
          <Typography
            variant="h4"
            component="div"
            sx={{ color: "black" }}
            mt={1.5}
          >
            {value}
          </Typography>
        </Box>
      </Card>
    </>
  );
}
