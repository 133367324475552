// Author : Ayesha Shaikh
//  Date : 29 Aug 2024

import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import {
  Autocomplete,
  Button,
  Collapse,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
import CustomAutocompleteComp from "../../newComponent/AutocompleteComp";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";
import ApplyFilterButton from "../../newComponent/NewButtonComp/ApplyFilterButton";
import ResetFilterButton from "../../newComponent/NewButtonComp/ResetFilterButton";
import FilterComp from "../../newComponent/iconsComp/FilterComp";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function DeclinedPaymentFilter({
  setPayments,
  isLoading,
  showSnackbar,
  setIsLoading,
  originalData,
  setSearchData
}) {
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [createdbylist, setCreatedbylist] = useState([]);
  const [createdby, setCreatedby] = useState([]);
  const [declinedBy, setDeclinedBy] = useState([]);
  const [amount, setAmount] = useState("");
  const [paymentStartDate, setPaymentStartDate] = useState("");
  const [paymentEndDate, setPaymentEndDate] = useState("");
  const [declinedStartDate, setdeclinedStartDate] = useState("");
  const [declinedEndDate, setdeclinedEndDate] = useState("");
  const [paymentmodelist, setPaymentModeslist] = useState([]);
  const [paymentmode, setPaymentmode] = useState([]);
  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allbranchList = [{ branch_name: "Select All" }, ...branchlist];

  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setCreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}paymentmode`);
        if (response.ok) {
          const data = await response.json();
          const activePayments = data.filter((item) => item.is_active === true);
          console.log(activePayments);
          setPaymentModeslist(activePayments);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handlepaymentmode = (event, value) => {
    setPaymentmode(value);
  };

  const allcreatedByList = [{ first_name: "Select All" }, ...createdbylist];
  const handelcretedby = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setCreatedby(createdbylist);
    } else {
      setCreatedby(value);
    }
  };

  const handelDeclinedby = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setDeclinedBy(createdbylist);
    } else {
      setDeclinedBy(value);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleamountChange = (e) => {
    setAmount(e.target.value);
  };

  const handlePaymentFromdatechange = (e) => {
    console.log(e.target.value);
    setPaymentStartDate(e.target.value);
  };

  const handlePaymentTodatechange = (e) => {
    console.log(e.target.value);
    setPaymentEndDate(e.target.value);
  };

  const handledDeclinedFromdatechange = (e) => {
    console.log(e.target.value);
    setdeclinedStartDate(e.target.value);
  };

  const handleDeclinedTodatechange = (e) => {
    console.log(e.target.value);
    setdeclinedEndDate(e.target.value);
  };

  const newBranch = branch.map((i) => i.id);
  const newcreatedby = createdby.map((i) => i.id);
  const newDeclinedby = declinedBy.map((i) => i.id);
  const newPaymentMode = paymentmode.map((i) => i.id);
  const handleApplyFilter = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json" // Make sure to keep the content type
    };

    const requestData = {
      payment_mode_id: newPaymentMode,

      collected_by: newcreatedby,
      collected_status: [],
      from_date: paymentStartDate || null,
      to_date: paymentEndDate || null,
      user_id: parseInt(userId),

      is_partial_payment: [],
      payment_status: [],
      class_id: [],
      is_class_extension: [],
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/provisionalpaymentfilter`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        const sortedData = result.sort(
          (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
        );
        const filterdData = sortedData.filter(
          (item) => item.collected_status === "declined"
        );
        setPayments(filterdData);
        setSearchData(filterdData)
        setIsLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }}
        catch{
          navigate("/page500");
        }
  };

  const resetfilter = () => {
    setPayments(originalData);
    setName("");
    setAmount("");
 
    setdeclinedStartDate("");
    setdeclinedEndDate("");
    setBranch([]);
    setCreatedby([]);
    setDeclinedBy([]);
    setPaymentmode([])
    setSearchData(originalData)
  };

      // Add default 1 month start and end date
const formatDate = (date) => date.toISOString().split('T')[0];
useEffect(() => {
  let oneMonthAgo = new Date();
  oneMonthAgo.setDate(oneMonthAgo.getDate()-30);
  
  
  let today=new Date()
 
  setPaymentEndDate(formatDate(today));
  setPaymentStartDate(formatDate(oneMonthAgo));
}, []);
  return (
    <>
      <MainCard>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
           variant="h3"
          >
            Declined Payments
          </Typography>
          <FilterComp onClick={handleToggle} fontSize="medium" />
        </Stack>

        <Collapse in={open} sx={{ marginTop: open? "5px" :0 }}>
          <Grid container spacing={2}>
            {/* <Grid item xs={3} lg={3} md={6} sm={6}>
              <Typography variant="h6">Enquiry Name </Typography>
              <TextField
                size="small"
                value={name}
                onChange={handleNameChange}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
              />
            </Grid> */}

            {/* <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography variant="h6">Branch</Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={branch}
                multiple
                onChange={handelbranchchange}
                options={allbranchList}
                getOptionLabel={(option) => option.branch_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid> */}

            {(userRole === "admin" ||
              userRole === "center_admin" ||
              userRole === "admin") && (
              <Grid item xs={12} lg={3} sm={6} md={6}>
                <Typography variant="h6">Collected By </Typography>

                <CustomAutocompleteComp
                  
                  fullWidth
                  value={createdby}
                  multiple
                  onChange={handelcretedby}
                  options={allcreatedByList}
                  getOptionLabel={(option) =>
                    `${option.first_name ? option.first_name : ""} ${
                      option.last_name ? option.last_name : ""
                    }`
                  }
                 tooltipContent={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                />
              </Grid>
            )}
            {/* <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography variant="h6">Declined By </Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={declinedBy}
                multiple
                onChange={handelDeclinedby}
                options={allcreatedByList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography variant="h6">
                Payment From Date {" "}
              </Typography>
              <CustomTextFieldComp
                size="normal"
                value={paymentStartDate}
                onChange={handlePaymentFromdatechange}
                type="date"
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography variant="h6">Payment To Date  </Typography>
              <CustomTextFieldComp
                size="normal"
                value={paymentEndDate}
                onChange={handlePaymentTodatechange}
                type="date"
                InputProps={{
                  style: { borderRadius: "10px"},
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography variant="h6">Payment Mode</Typography>

              <CustomAutocompleteComp
               
                value={paymentmode}
                multiple
                onChange={handlepaymentmode}
                options={paymentmodelist}
                getOptionLabel={(option) => option.payment_mode}
              tooltipContent={(option) => option.payment_mode}
              />
            </Grid>
            {/* 
            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography variant="h6">
                Declined Date From{" "}
              </Typography>
              <TextField
                size="small"
                value={declinedStartDate}
                onChange={handledDeclinedFromdatechange}
                type="date"
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography variant="h6">
                Declined Date To{" "}
              </Typography>
              <TextField
                size="small"
                value={declinedEndDate}
                onChange={handleDeclinedTodatechange}
                type="date"
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
              />
            </Grid> */}

            {/* <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography variant="h6"> Amount Paid </Typography>
              <TextField
                size="small"
                value={amount}
                onChange={handleamountChange}
                type="number"
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
              />
            </Grid> */}

            <Grid item xs={12} lg={12} container gap={1} justifyContent="end">
            <ApplyFilterButton
               
               onClick={handleApplyFilter}
             />
              <ResetFilterButton
                
                onClick={resetfilter}
              />
            

            
              
            </Grid>
          </Grid>
        </Collapse>
      </MainCard>
    </>
  );
}
