import React, { useState } from "react";
import MainCard from "../../../../components/ui-component/card/MainCard";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { CSVExport } from "../../../../components/ui-component/table/CSVExport";
import CustomSnackbar from "../../../../components/ui-component/snackbar/CustomSnackbar";
import CreatePopup from "../../../../../components/dialog/CreatePopup";
import AddRoomForm from "../rooms/AddRoom";
import RoomScheduleDialog from "../RoomSchedule";
import AddTrainer from "./AddTrainer";
import StyledTableContainer from "../../../../newComponent/TableContainer";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function Row({ row, onEdit, index }) {
  console.log("row", row);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleViewSchedule = () => {
    setOpen(true);
  };

  return (
    <>
      <TableRow
        sx={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "inherit" }}
      >
        <TableCell>
          {row.first_name} {row.last_name}
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row?.mobile}</TableCell>
        <TableCell>
          {Array.isArray(row?.trainer_expertise) &&
          row?.trainer_expertise.length > 0 ? (
            row?.trainer_expertise.join(", ") // Join the array with commas
          ) : (
            <div>No expertise available</div> // Fallback if trainer_expertise is not available or empty
          )}
        </TableCell>
        <TableCell>{row.gender}</TableCell>
        <TableCell>{row.is_active ? (  <Chip
                              variant="contained"
                              size="small"
                              color="secondary"
                              label="Active"
                            />
                          ) : (
                            <Chip
                              label="Inactive"
                              variant="contained"
                              size="small"
                              color="error"
                            />)}</TableCell>
        {/*}   <TableCell >
               <Button
                                    color="secondary"
                                    variant='contained'
                                    size='small'
                                    sx={{
                                        color: theme.palette.dark,
                                        borderColor: theme.palette.orange.main,
                                        '&:hover ': { background: theme.palette.orange.light }
                                    }}
                                    onClick={() => onEdit(row)} 
                                >  
                                    Edit
                                </Button>
                </TableCell> */}
      </TableRow>
    </>
  );
}

function TrainerTable({ trainers, branchId, branchName }) {
  const userId = localStorage.getItem("userId");
  const [rows, setRows] = useState(trainers);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddRoomPopupOpen, setIsAddRoomPopupOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [editTrainerInfo, setEditTrainerInfo] = useState(null);

  console.log(rows);
  const handleEditTrainer = (trainerInfo) => {
    setEditTrainerInfo(trainerInfo);
    handleAddRoomClick(); // Open the AddTrainer dialog
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleAddRoomClick = () => {
    setIsAddRoomPopupOpen(true);
  };

  const handleAddRoomClose = () => {
    setIsAddRoomPopupOpen(false);
    setEditTrainerInfo(null);
  };

  const handleAddEmployee = (u) => {
    console.log(editTrainerInfo);

    u.branch_id = branchId;
    u.user_id = userId;

    const editMode = !!editTrainerInfo;

    const trainerId = editTrainerInfo?.id;

    console.log(u);

    const apiUrl = editMode
      ? `${API_ENDPOINT}trainer/${trainerId}`
      : `${API_ENDPOINT}trainer`;

    fetch(apiUrl, {
      method: editMode ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(u),
    })
      .then((response) => {
        if (response.status === 200 || response.status == 201) {
          showSnackbar(
            editMode
              ? "Trainer updated successfully"
              : "Trainer created successfully",
            "success"
          );
          if (editMode) {
            // If in edit mode, update the specific row
            setRows((prevRows) =>
              prevRows.map((row) => (row.id === editTrainerInfo.id ? u : row))
            );
          } else {
            setRows((prevRows) => [...prevRows, u]);
          }

          setEditTrainerInfo(null);
          window.location.reload();
        } else if (response.status === 409) {
          showSnackbar("Trainer with Email Id Exists", "error");
          // setProfile({});
        } else {
          console.log("Error:", response);
          showSnackbar(
            `Error ${editMode ? "updating" : "creating"} trainer`,
            "error"
          );
        }
      })
      .catch((error) => {
        console.error(
          `Error ${editMode ? "updating" : "creating"} trainer`,
          error
        );
        showSnackbar(
          `Error ${editMode ? "updating" : "creating"} trainer`,
          "error"
        );
      });
  };
  return (
    <>
      <MainCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">Trainers</Typography>
          </Grid>

          {/* table */}
          <Grid item xs={12}>
            <StyledTableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Expertise</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length === 0 ? (
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={4} align="center">
                        <Typography variant="h4">No data available</Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    rows.map((room, index) => (
                      <Row
                        key={room?.id}
                        row={room}
                        onEdit={handleEditTrainer}
                        index={index}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Grid>
        </Grid>
      </MainCard>

      {/*}  <CreatePopup 
        open={isAddRoomPopupOpen} 
        onClose={handleAddRoomClose}
        component={<AddTrainer onSubmit={handleAddEmployee} branchId={branchId} branchName={branchName} editMode={!!editTrainerInfo} trainerInfo={editTrainerInfo} />}
        >
      </CreatePopup> */}

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default TrainerTable;
