import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
 
  Box,
  CircularProgress
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import EmailPopup from "./EmailPopup";
import Mailform from "./Mailform";
import { fDate } from "../../../../../utils/formatTime";
import EmailDetails from "./EmailDetails";
import CustomNoRowsOverlay from "../../../../../newTestPrep/newComponent/TableCustomeComp/CustomNoRowsOverlay";

export default function Email({ enquiryData }) {
  console.log(enquiryData);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
  const enqid=enquiryData.id

  const [emaildata, setEmaildata] =useState([])
  const [loading , setLoading] =useState(false)
  const [open, setOpen] = useState(false);
  const [emailinfopopup,setEmailinfopopup] =useState(false)
  const [selectedid, setSelectedid] =useState(null)
  useEffect(() => {
    setLoading(true);
    fetch(`${API_ENDPOINT}sentemail/getemailbyenq/${enqid}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 403) {
          // Handle 403 error by showing Snackbar
        
          setLoading(false);
          return; // No need to continue further
        }  else if (response.status === 404) {
          setLoading(false);
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .then((data) => {
        if (!data) return; // Stop further execution if data is not present
      
        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      setEmaildata(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
       
      });
  }, [enqid]);

  
   
  const columns = [
    {
      field: "subject",
      headerName: "Subject",
      // width: 350,
      headerAlign: "center",
      align: "center",
      
      renderCell: (params) => (
        <div   style={{cursor:'pointer' , color:"#2196f3"}} onClick={() => handelopenemail(params.row.id)}>
      {(params.row?.email_subject===null || params.row?.email_subject==="null") ? "No Subject" :params.row.email_subject  }
    </div>
      ),
      flex:2
    },
    {
      field: "send_by",
      headerName: "Send By",
      // width: 150,
      headerAlign: "center",
      align: "center",
      
      flex:1,
      renderCell: (params) => (
        <>{ params.row.sent_by_id?.first_name} {params.row.sent_by_id?.last_name} </>
      ),
    },
    {
      field: "send_date",
      headerName: "Send Date",
      // width: 200,
      headerAlign: "center",
      align: "center",
      
      flex:1,
      renderCell: (params) => (
        <>{fDate( params.row?.createdAt)}</>
      ),
    },
    
  ];

  





  const handelopen = () => {
    setOpen(true);
  };

  const handelclose = () => {
    setOpen(false);
  };

  const handelopenemail = (id) => {
   setEmailinfopopup(true);
   setSelectedid(id)
  };

  const handelcloseemail = () => {
  setEmailinfopopup(false);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "5px",
        }}
      >
        <Button
          startIcon={<EmailOutlinedIcon />}
          variant="contained"
          color="secondary"
          size="small"
          onClick={handelopen}
          disabled={enquiryData.is_active===0}
        >
          Send Email
        </Button>
      </div>

      <div style={{ width: "100%", marginTop: "10px" }}>
        
        </div>

        {loading ?  (
 <Box
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   height: "100px",
 }}
>
 <CircularProgress />
</Box>
        ): (!loading && emaildata.length===0) ?(
          <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
           
          }}
        >
          <Typography variant="h4">No Email Data  </Typography>
          </Box>
        ) :(
        <DataGrid
          rows={emaildata}
          columns={columns}
       
          slots={{
            noRowsOverlay: () => (
              <CustomNoRowsOverlay message="No Data Available" />
            ),
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          sx={{ marginTop: "20px" ,height: emaildata.length > 6 ? "400px" : "auto"}}
          pageSizeOptions={[25, 50, 100]}
        />

        )}

      <EmailPopup
        handelopen={handelopen}
        open={open}
        handelclose={handelclose}
        component={<Mailform enquiryData={enquiryData}   handelclose={handelclose} />}
    title="Send An Email"
    maxWidth='md'
      />

<EmailPopup
        handelopen={handelopenemail}
        open={emailinfopopup}
        handelclose={handelcloseemail}
        component={<EmailDetails enquiryData={enquiryData}   handelclose={handelcloseemail} selectedid={selectedid} />}
        title="View Email"
        maxWidth='sm'
      />
    </>



  );
}
