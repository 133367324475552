import { Box, Button, FormControl, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";


export default function Categoryform({ addCategory, onCloseTab, onClose }) {
  const [categoryName, setCategoryName] = useState(null);

  const handleAddCategory = () => {
    if (!categoryName) {
      alert("Please add Category name");
      return;
    }

    addCategory(categoryName);
    setCategoryName("");
    // onCloseTab();
    // onClose();
  };

  return (
    <>
      <FormControl>
        <Typography>Add Category</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          type="text"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />
        <Box sx={{ textAlign: "center" }} mt={1}>
          <Button
            onClick={handleAddCategory}
        variant="contained"
        color="secondary"
        size="small"
          >
            Save
          </Button>
        </Box>
      </FormControl>
    </>
  );
}
