import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import StyledTableContainer from "../../../newTestPrep/newComponent/TableContainer";
import CustomPopup from "../../../components/CustomPopup";

export default function ShoAllProductDetails({ data }) {
  return (
    <>
      <Box>
        <StyledTableContainer component={Paper}>
          <Table style={{ background: "#F9F9F9" }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>No. of Enrollment</TableCell>
                <TableCell>Online Enrollment</TableCell>
                <TableCell>Offline Enrollment</TableCell>
                <TableCell>Total Revenue</TableCell>
                <TableCell>Collected</TableCell>
                <TableCell>Pending</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length === 0 ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "11px",
                      color: "#000000",
                    }}
                  >
                    No data available
                  </Typography>
                </Box>
              ) : (
                data?.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      width: "6%",
                    }}
                  >
                    <TableCell>{row?.test_type_id?.name}</TableCell>
                    <TableCell>{row?.total_enrollment}</TableCell>
                    <TableCell>{row?.online_enrollment}</TableCell>
                    <TableCell>{row?.offline_enrollment}</TableCell>
                    <TableCell>{row?.total_revenue}/-</TableCell>
                    <TableCell>{row?.collected_revenue}/-</TableCell>
                    <TableCell>{row?.pending_revenue}/-</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </>
  );
}
