import { Box, ButtonBase, Tooltip } from '@mui/material'
import React from 'react'

export default function FilterComp({onClick , width}) {
  return (
    <Box onClick={onClick} sx={{cursor:"pointer"}}>
          <Tooltip title="Filter" placement="bottom">
          <ButtonBase>
   <img src='Group 8922 (5).png' alt="filter"  width={width}/>
   </ButtonBase>
   </Tooltip>
   </Box>
  )
}
