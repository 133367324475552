import React, { useEffect, useState } from "react";
import MainCard from "../../components/ui-component/card/MainCard";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DataGrid } from "@mui/x-data-grid";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import CustomTextFieldComp from "../../newComponent/customTextFieldComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function TakeAttendance() {
  const userId = localStorage.getItem("userId");
  const [isFilterOpen, setFilterOpen] = useState(true);
  const [selectedClass, setSelectedClass] = useState("");
  const [trainerName, setTrainerName] = useState("");
  const [deliveryMode, setDeliveryMode] = useState("");
  const [branch, setBranch] = useState("");
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(getTodayDate());
  const [trainers, setTrainers] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState("");
  const [classes, setClasses] = useState([]);
  const [attendanceStatus, setAttendanceStatus] = useState({});

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const toggleFilterSection = () => {
    setFilterOpen(!isFilterOpen);
  };

  function getTodayDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  const fetchTrainers = async () => {
    try {
      const response = await fetch(
        `${API_ENDPOINT}route/getalltrainer/${userId}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching trainers:", error);
      throw error;
    }
  };

  useEffect(() => {
    // Fetch trainers when the component mounts
    fetchTrainers()
      .then((trainerData) => {
        setTrainers(trainerData);
      })
      .catch((error) => {
        console.error("Error fetching trainers:", error);
      });
  }, []);

  const handleTrainerChange = (event) => {
    const selectedTrainerId = event.target.value;

    // Find the selected trainer from the trainers data
    const selectedTrainer = trainers.find(
      (trainer) => trainer.id === selectedTrainerId
    );

    // Update the state with the selected trainer's classes
    setClasses(selectedTrainer ? selectedTrainer.classes : []);

    // If there are classes, prefill delivery mode and branch with the first class data
    if (selectedTrainer && selectedTrainer.classes.length > 0) {
      setDeliveryMode(selectedTrainer.classes[0].delivery_mode);
      setBranch(selectedTrainer.branch_id?.branch_name || ""); // Assuming branch_name is the property you want for the branch
    } else {
      // Reset delivery mode and branch if no classes are available
      setDeliveryMode("");
      setBranch("");
    }

    // Reset selected class
    setSelectedClass("");
    setSelectedTrainer(selectedTrainerId);
  };



  const fetchClassesFromAPI = async (date, trainerId, classId) => {
    try {
      const response = await fetch(`${API_ENDPOINT}route/markattendancelist`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          date,
          trainer_id: trainerId,
          class_id: classId,
          user_id: userId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else if (response.status === 400) {
        showSnackbar("Can not take attendance for Sunday", "error");
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        showSnackbar("Error feching student Data", "error");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
      showSnackbar("Error feching student Data", "error");
      throw error;
    }
  };

  useEffect(() => {
    // Fetch classes when the user selects a date, trainer, and class
    if (selectedDate && selectedTrainer && selectedClass) {
      setLoading(true);
      fetchClassesFromAPI(selectedDate, selectedTrainer, selectedClass)
        .then((students) => {
   
          setLoading(false);
          setStudents(students);
          // Proceed with other logic, e.g., fetching and displaying students data
        })
        .catch((error) => {
          if (error.message.includes("401")) {
            showSnackbar("Unauthorized to fetch students", "error");
            setLoading(false);
            setStudents([]);
          } else if (error.message.includes("403")) {
            showSnackbar("No Students in this class on this day", "error");
            setLoading(false);
            setStudents([]);
          } else if (error.message.includes("400")) {
            showSnackbar("Can not take attendance on Sunday", "error");
            setLoading(false);
            setStudents([]);
          } else {
            console.error("Error fetching student data:", error);
            showSnackbar("Error fetching enquiries data:", "error");
            setLoading(false);
            setStudents([]);
          }
        });
    }
  }, [selectedDate, trainerName, selectedClass]);

  const columns = [
    {
      field: "lead_no",
      headerName: "Enroll ID",
      flex: 0.2,
      sortable: false,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.student_id?.lead_no}</div>,
    },
    {
      field: "studentName",
      headerClassName: "header-cell",
      headerName: "Student Name",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: (params) => (
        <div>
          {params.row?.enquiry_id?.first_name}{" "}
          {params.row?.enquiry_id?.last_name}
        </div>
      ),
    },
    {
      field: "enr_date",
      headerClassName: "header-cell",
      headerName: "Enrollment Date",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: (params) => <div>{params.row?.enrollment_date}</div>,
    },

    {
      field: "class_name",
      headerClassName: "header-cell",
      headerName: "Class",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div>{params.row?.class_id?.name}</div>,
    },
    {
      field: "package",
      headerClassName: "header-cell",
      headerName: "Package",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div>{params.row?.package_id?.package_name}</div>,
    },
    {
      field: "action",
      headerClassName: "header-cell",
      headerName: "Mark Attendance",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          {attendanceStatus[params.row.id] || params?.row.is_mark ? (
            <div>Attendance marked</div>
          ) : (
            <>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={() =>
                  handleMarkAttendance(
                    params.row.student_id.id,
                    "1",
                    params.row.id
                  )
                }
                disabled={params.row.attendanceStatus === "Present"}
              >
                Present
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() =>
                  handleMarkAttendance(
                    params.row.student_id.id,
                    "0",
                    params.row.id
                  )
                }
                disabled={params.row.attendanceStatus === "Absent"}
              >
                Absent
              </Button>
              <Button
                variant="contained"
                color="warning"
                size="small"
                onClick={() =>
                  handleMarkAttendance(
                    params.row.student_id.id,
                    "-1",
                    params.row.id
                  )
                }
                disabled={params.row.attendanceStatus === "Absent"}
              >
                Absent with permission
              </Button>
            </>
          )}
        </Box>
      ),
    },
  ];



  const handleMarkAttendance = async (
    studentId,
    attendanceStatus,
    studentClassDetailsId
  ) => {
  
    try {
      const response = await fetch(`${API_ENDPOINT}attendance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          student_class_details_id: studentClassDetailsId,
          student_id: studentId,
          class_id: selectedClass,
          date: selectedDate,
          attendance_val: attendanceStatus,
          user_id: userId,
        }),
      });

      if (!response.ok) {
        showSnackbar("Error marking attendance", "error");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setAttendanceStatus((prevStatus) => ({
        ...prevStatus,
        [studentClassDetailsId]: true,
      }));



      // You can add additional logic here after successfully marking attendance
    } catch (error) {
      if (error.message.includes("400")) {
        showSnackbar("Cannot mark attendance for future date", "error");
      } else if (error.message.includes("405")) {
        showSnackbar("Cannot mark attendance for Sunday", "error");
      } else {
        console.error("Error marking attendance:", error);
        showSnackbar("Error marking attendance", "error");
      }

      // Handle error if needed
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="300px"
        >
          <CircularProgress />
        </Grid>
      );
    }

    if (students.length === 0) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="300px"
        >
          <Typography variant="h4">
            No students data. Select class and date.
          </Typography>
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <MainCard>
          <DataGrid
            rows={students}
            columns={columns}
            autoHeight
            pageSize={10}
            noRowsOverlay={
              <Typography variant="h6" color="textSecondary">
                No students available for the selected criteria.
              </Typography>
            }
          />
        </MainCard>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MainCard>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="h3">Take Attendance</Typography>
              </Box>
              <Box>
                <IconButton onClick={toggleFilterSection}>
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Box>
            </Box>
            <Collapse in={isFilterOpen}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomTextFieldComp
                    size="normal"
                    label="Please Select Date to Mark Attendance"
                    variant="outlined"
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        max: new Date().toISOString().split("T")[0],
                      },
                      style: { borderRadius: "10px" },
                    }}
                  />
                </Grid>

                {/* Other text fields */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomTextFieldComp
                    size="normal"
                    select
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Select Trainer"
                    variant="outlined"
                    value={selectedTrainer}
                    onChange={handleTrainerChange}
                    fullWidth
                    InputProps={{
                      style: { borderRadius: "10px" }, // Add borderRadius here
                    }}
                  >
                    {trainers.length > 0 ? (
                      trainers.map((trainer) => (
                        <MenuItem key={trainer.id} value={trainer.id}>
                          {trainer.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No Trainers Found.
                      </MenuItem>
                    )}
                  </CustomTextFieldComp>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomTextFieldComp
                    size="normal"
                    select
                    label="Select Class"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={selectedClass}
                    onChange={(e) => setSelectedClass(e.target.value)}
                    fullWidth
                    InputProps={{
                      style: { borderRadius: "10px" }, // Add borderRadius here
                    }}
                  >
                    {classes.length > 0 ? (
                      classes.map((classItem) => (
                        <MenuItem key={classItem.id} value={classItem.id}>
                          {classItem.slot} - {classItem.test_type?.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No Classes Found. Please Select Trainer First.
                      </MenuItem>
                    )}
                  </CustomTextFieldComp>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomTextFieldComp
                    size="normal"
                    label="Delivery Mode"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={deliveryMode}
                    onChange={(e) => setDeliveryMode(e.target.value)}
                    fullWidth
                    InputProps={{
                      style: { borderRadius: "10px" }, // Add borderRadius here
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomTextFieldComp
                    size="normal"
                    label="Branch"
                    variant="outlined"
                    value={branch}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setBranch(e.target.value)}
                    fullWidth
                    InputProps={{
                      style: { borderRadius: "10px" }, // Add borderRadius here
                    }}
                  />
                </Grid>
                {/* Filter button */}
              </Grid>
            </Collapse>
          </MainCard>
        </Grid>

        <Grid item xs={12}>
          {renderContent()}
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default TakeAttendance;
