import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";

import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import ApproveCashDepositeFilter from "./ApproveCashDepositeFilter";
import CustomPopup from "../../../components/CustomPopup";
import ApproveOnlinePaymentInstallation from "./PaymentInstallation";
import { useGetTokenOrLogout } from "../../../utils/token";
import StyledTableContainer from "../../newComponent/TableContainer";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import AprroveEnquiryData from "./AprroveEnquiryData";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PATH_URL = process.env.REACT_APP_API_UPLOADS;

function ApproveCashDeposits() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();

  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openRows, setOpenRows] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [openPopup, setOpenPopUp] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [opendeclined, setOpenDeclined] = useState(false);
  const [errors, setErrors] = useState(false);
  const [remark, setRemark] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [openEnquiryData, setOpenEnquiryData] = useState(false);
  const [selectedEnquiryData, setSelectedEnquiryData] = useState([]);

  const handleOpenDeclinedPopup = (id) => {
    setOpenDeclined(true);
    setSelectedId(id);
    console.log(id);
  };

  const handleCloseDeclinedPopup = () => {
    setOpenDeclined(false);
  };
  const handleOpenPopup = () => {
    setOpenPopUp(true);
  };

  const handleClosePopup = () => {
    setOpenPopUp(false);
  };
  const handleRowClick = (rowId) => {
    setOpenRows((prevOpenRows) =>
      prevOpenRows.includes(rowId)
        ? prevOpenRows.filter((id) => id !== rowId)
        : [...prevOpenRows, rowId]
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleUpdateStatus = (paymentId) => {
    // Update the status locally in the state
    const updatedPayments = payments.map((payment) =>
      payment.paymentId === paymentId
        ? { ...payment, payment_status: "Confirmed" }
        : payment
    );
    setPayments(updatedPayments);

    // Send a request to the server to update the status
    fetch(`${API_ENDPOINT}route/approvedepositslip/${paymentId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id: parseInt(userId) }),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Payment status updated successfully", "success");
          // Remove the row from state if the status is updated successfully
          const updatedPayments = payments.filter(
            (payment) => payment.id !== paymentId
          );
          setPayments(updatedPayments);
        } else {
          showSnackbar("Failed to update payment status", "error");
          throw new Error("Failed to update payment status");
        }
        // Handle successful response if needed
      })
      .catch((error) => {
        console.error("Error updating payment status:", error);
        showSnackbar("Error updating payment status", "error");
        // Revert the status locally if the server update fails
        setPayments(payments);
      });
  };

  useEffect(() => {
    setIsLoading(true);

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    // Fetch expenses data
    fetch(`${API_ENDPOINT}uploaddepositslip/fetchdepositslip/${userId}`, {
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        const sortedPayments = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        ); // Sort by createdAt date in descending order

        setPayments(sortedPayments);
        setIsLoading(false);
        setOriginalData(sortedPayments);
      })
      .catch((error) => {
        if (error.message.includes("401")) {
          navigate("/page401");
        } else if (error.message.includes("403")) {
          navigate("/page403");
        } else if (error.message.includes("404")) {
          navigate("/page404");
        } else if (error.message.includes("440")) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
        setIsLoading(false);
      });
  }, [userId]);

  const handleViewDepositSlip = (filePath) => {
    window.open(`${PATH_URL}depositSlip/${filePath}`, "_blank");
  };

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
    setErrors({ ...errors, remark: !e.target.value });
  };

  const handleDeclinedPayment = async () => {
    if (!remark) {
      setErrors({ remark: !remark });
      return;
    }

    const formdata = {
      id: selectedId,
      remarks: remark,
      user_id: parseInt(userId),
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route/declinecashpayments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      });

      if (response.ok) {
        showSnackbar("Payment Declined Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else if (response.status === 400) {
        showSnackbar("Id not found", "error");
      } else if (response.status === 401) {
        showSnackbar("User not found", "error");
      } else {
        showSnackbar("Error Posting Data", "error");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      showSnackbar("Error Posting Data", "error");
    }
  };

  const handleOpenEnquiry = (data) => {
    setOpenEnquiryData(true);
    setSelectedEnquiryData(data);
  };

  const handleCloseEnquiry = () => {
    setOpenEnquiryData(false);
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Backdrop open={detailsLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching Installment Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <ApproveCashDepositeFilter
            setPayments={setPayments}
            originalData={originalData}
            isLoading={isLoading}
            showSnackbar={showSnackbar}
            setIsLoading={setIsLoading}
          />
        </Grid>
      </Grid>

      <StyledTableContainer
        component={Paper}
        sx={{ borderRadius: "15px", padding: "10px" }}
      >
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Payment Details</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Uploaded By</TableCell>
              {/* <TableCell
               
              >
                Branch
              </TableCell> */}
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No data available</Typography>
              </Grid>
            ) : (
              payments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#f0f0f0" : "inherit",
                        width: "6%",
                      }}
                    >
                      <TableCell
                        sx={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          style={{ borderRadius: "6px", height: "20px" }}
                          onClick={() =>
                            handleOpenEnquiry(row?.provesional_payment_id)
                          }
                        >
                          View Enquiries
                        </Button>
                      </TableCell>

                      <TableCell>{fDate(row?.createdAt)}</TableCell>

                      <TableCell>{row?.uploaded_by?.first_name}</TableCell>

                      <TableCell>
                        {row?.is_active === 1 && (
                          <Chip
                            variant="contained"
                            size="small"
                            label="Uploaded"
                            color="error"
                          />
                        )}
                        {row?.is_active === 0 && (
                          <Chip
                            variant="contained"
                            size="small"
                            label="Confirmed"
                            color="primary"
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <div>
                          <Tooltip title="View Deposit Slip">
                            <VisibilityOutlinedIcon
                              variant="contained"
                              fontSize="small"
                              style={{
                                color: "#00b13f",
                                fontSize: "1rem",
                                cursor: "pointer",
                                marginRight: "8px", // Add space
                              }}
                              onClick={() =>
                                handleViewDepositSlip(row?.file_uploaded)
                              }
                            />
                          </Tooltip>

                          {userRole === "accountant" && (
                            <>
                              {row?.is_active === 1 && (
                                <Tooltip title="change status to confirmed">
                                  <CheckCircleIcon
                                    style={{
                                      color: "#00b13f",
                                      fontSize: "1rem",
                                      cursor: "pointer",
                                      marginRight: "8px", // Add space
                                    }}
                                    variant="contained"
                                    size="small"
                                    onClick={() => handleUpdateStatus(row.id)}
                                  />
                                </Tooltip>
                              )}
                              {row?.is_active === 1 && (
                                <Tooltip title="Decline">
                                  <CancelPresentationIcon
                                    style={{
                                      fontSize: "1rem",
                                      cursor: "pointer",
                                    }}
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                      handleOpenDeclinedPopup(row?.id)
                                    }
                                  />
                                </Tooltip>
                              )}
                            </>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={payments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledTableContainer>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <CustomPopup
        open={openPopup}
        onClose={handleClosePopup}
        component={
          <ApproveOnlinePaymentInstallation
            onClose={handleClosePopup}
            data={detailsData}
          />
        }
        title="Installment Details"
        showDivider="true"
        maxWidth="lg"
      />

      {/* Declined Dialog */}
      <Dialog
        open={opendeclined}
        onClose={handleCloseDeclinedPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography sx={{ fontWeight: 600 }}>
              Remark{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1em",
                }}
              >
                *
              </span>
            </Typography>
            <TextField
              placeholder="Type anything…"
              value={remark}
              fullWidth
              multiline
              rows={4}
              onChange={handleRemarkChange}
              InputProps={{ style: { borderRadius: "15px" } }}
              error={errors.remark}
              helperText={errors.remark && "Remark is required"}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeclinedPopup} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleDeclinedPayment}
            autoFocus
            variant="contained"
            color="error"
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>

      <CustomPopup
        open={openEnquiryData}
        onClose={handleCloseEnquiry}
        component={
          <AprroveEnquiryData
            onClose={handleCloseEnquiry}
            selectedEnquiryData={selectedEnquiryData}
          />
        }
        title="Enquiry Details"
        maxWidth="md"
      />
    </>
  );
}

export default ApproveCashDeposits;
