import React from "react";
import Chip from "@mui/material/Chip";

const CustomSmallChip = ({ label, isSelected, onClick }) => {
    
  return (
    <Chip
      size="small"
      label={label}
      onClick={onClick}
      variant="outlined"
      style={{
        borderRadius: "25px",
        cursor: "pointer",
        border: isSelected ? "2px solid green" : "",
        color: isSelected ? "green" : "black",
        margin: "5px",
        fontSize: "0.7rem",
        fontWeight: 500,
      }}
    />
  );
};

export default CustomSmallChip;
