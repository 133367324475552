// Author: Ayesha Shaikh
// Date : 13 aug 2024

import { Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EditServiceDetails({ data, counsellingId }) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 4;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [serviceList, setServiceList] = useState([]);
  const [serviceName, setServiceName] = useState(data?.service_id?.id || null);
  const [subServiceList, setSubServiceList] = useState([]);
  const [subServiceName, setSubServiceName] = useState(
    data?.sub_service_id?.id || null
  );
  const [examClassTypeList, setExamClassTypeList] = useState([]);
  const [examClassTypeName, setExamClassTypeName] = useState(
    data?.test_type_id?.id || null
  );
  const [selectedClassType, setSelectedclassType] = useState(
    data?.test_type_id?.name
  );

  const [purposeList, setPurposeList] = useState([]);
  const [purposeName, setPurposename] = useState(
    data?.purpose_of_test_id?.id || null
  );

  const [countryList, setCountryList] = useState([]);
  const [countryName, setCountryName] = useState(data?.target_country_id?.id);

  const [subTestTypeList, setSubTestTypeList] = useState([]);
  const [subTestTyeName, setSubtestTypeName] = useState(
    data?.sub_test_type_id?.id || null
  );
  const [testdeliveryformatList, setTestdeliveryformatList] = useState([]);
  const [testdeliveryformatName, setTestdeliveryformatName] = useState(
    data?.test_delivery_format_id?.id || null
  );

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setServiceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setSubServiceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalltesttype`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setExamClassTypeList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}purposeoftest`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setPurposeList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}targetcountries`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setCountryList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (examClassTypeList.length > 0) {
      const testType = examClassTypeList.find(
        (test) => test.id === examClassTypeName
      );
      setSelectedclassType(testType?.name);
    }
  }, [examClassTypeName]);

  console.log("selectedClassType", selectedClassType);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/getsubtesttype/${examClassTypeName}`
        );
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setSubTestTypeList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [examClassTypeName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}testdeliveryformat`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setTestdeliveryformatList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSerciceChange = (e) => {
    setServiceName(e.target.value);
  };

  const handlesubServiceChange = (e) => {
    setSubServiceName(e.target.value);
  };

  const handleexamtypeChange = (e) => {
    setExamClassTypeName(e.target.value);
    setSubtestTypeName(null)
    setTestdeliveryformatName(null)
  };

  const handlePurposeChange = (e) => {
    setPurposename(e.target.value);
  };

  const handleCountryChabnge = (e) => {
    setCountryName(e.target.value);
  };

  const handleSubtestTypeChange = (e) => {
    setSubtestTypeName(e.target.value);
  };

  const handleTestDeliveryTypeChange = (e) => {
    setTestdeliveryformatName(e.target.value);
  };


  const handleSave = async () => {
    try {
      const method = data?.id ? "PUT" : "POST";
      const endpoint = data?.id 
        ? `${API_ENDPOINT}eventcounsellingenquirydetails/${data.id}` 
        : `${API_ENDPOINT}eventcounsellingenquirydetails`;
  
      const response = await fetch(`${API_ENDPOINT}eventcounsellingenquirydetails/updatewithcounsellingid`, {
        method : "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          event_counselling_id: counsellingId,
          service_id: serviceName,
          sub_service_id: subServiceName,
          sub_test_type_id: subTestTyeName,
          test_delivery_format_id: testdeliveryformatName,
          test_type_id: examClassTypeName,
          purpose_of_test_id: purposeName,
          target_country_id: countryName,
        }),
      });
  
      if (response.ok) {
        showSnackbar("Data saved successfully");
        setTimeout(() => window.location.reload(), 1500);
      } else {
        const errorMessage = response.status === 404 ? "Not updated" : "Server error";
        showSnackbar(errorMessage, "error");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      showSnackbar("Server error", "error");
    }
  };
  return (
    <>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6} lg={4} sm={6} md={6}>
          <Typography>Service</Typography>
          <Select
            MenuProps={MenuProps}
            fullWidth
            size="small"
            sx={{ borderRadius: "10px" }}
            value={serviceName}
            onChange={handleSerciceChange}
          >
            {serviceList.map((service) => (
              <MenuItem key={service.id} value={service.id}>
                {service.service_name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6} lg={4} sm={6} md={6}>
          <Typography>Sub Service</Typography>
          <Select
            MenuProps={MenuProps}
            fullWidth
            size="small"
            sx={{ borderRadius: "10px" }}
            value={subServiceName}
            onChange={handlesubServiceChange}
          >
            {subServiceList.map((service) => (
              <MenuItem key={service.id} value={service.id}>
                {service.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6} lg={4} sm={6} md={6}>
          <Typography>Exam Class Type</Typography>
          <Select
            MenuProps={MenuProps}
            fullWidth
            size="small"
            sx={{ borderRadius: "10px" }}
            value={examClassTypeName}
            onChange={handleexamtypeChange}
          >
            {examClassTypeList.map((exam) => (
              <MenuItem key={exam.id} value={exam.id}>
                {exam.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {selectedClassType === "IELTS" && (
          <>
            <Grid item xs={6} lg={4} sm={6} md={6}>
              <Typography>Sub Test Type</Typography>
              <Select
                fullWidth
                size="small"
                sx={{ borderRadius: "10px" }}
                value={subTestTyeName}
                onChange={handleSubtestTypeChange}
              >
                {subTestTypeList.map((test) => (
                  <MenuItem key={test.id} value={test.id}>
                    {test.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={6} lg={4} sm={6} md={6}>
              <Typography>Delivery Format</Typography>
              <Select
                fullWidth
                size="small"
                sx={{ borderRadius: "10px" }}
                value={testdeliveryformatName}
                onChange={handleTestDeliveryTypeChange}
              >
                {testdeliveryformatList.map((test) => (
                  <MenuItem key={test.id} value={test.id}>
                    {test.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        )}

        {selectedClassType === "PTE" && (
          <>
            <Grid item xs={6} lg={4} sm={6} md={6}>
              <Typography>Sub Test Type</Typography>
              <Select
                fullWidth
                size="small"
                sx={{ borderRadius: "10px" }}
                value={subTestTyeName}
                onChange={handleSubtestTypeChange}
              >
                {subTestTypeList.map((test) => (
                  <MenuItem key={test.id} value={test.id}>
                    {test.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        )}

        <Grid item xs={6} lg={4} sm={6} md={6}>
          <Typography>Purpose Of Test</Typography>
          <Select
            fullWidth
            size="small"
            sx={{ borderRadius: "10px" }}
            value={purposeName}
            onChange={handlePurposeChange}
          >
            {purposeList.map((purpose) => (
              <MenuItem key={purpose.id} value={purpose.id}>
                {purpose.purpose_name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6} lg={4} sm={6} md={6}>
          <Typography>First Country Of Interest</Typography>
          <Select
            fullWidth
            size="small"
            sx={{ borderRadius: "10px" }}
            value={countryName}
            onChange={handleCountryChabnge}
          >
            {countryList.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.country_name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} lg={12} sm={12} md={12} textAlign="end">
          <Button variant="contained" style={{background:"#00b13f"}} onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
