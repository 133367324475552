import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

// project imports

//import useConfig from 'hooks/useConfig';
import MainCard from "../../../components/ui-component/card/MainCard";
import { gridSpacing } from "../../../store/constant";

// assets
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import CreditCardTwoToneIcon from "@mui/icons-material/CreditCardTwoTone";
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone";
import StudentInfo from "./StudentInfo";
import ClassDetails from "./ClassDetails";
import DatePickerWithAvailability from "./DateEnrollment";
import DateEnrolled2 from "./DateEnrolled2";
import PaymentDetails from "./PaymentInfo";
import { useLocation } from "react-router-dom";
import HeadingCard from "../../../newComponent/NewCardsComp/HeadingCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// tabs
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// tabs option
const tabsOption = [
  {
    label: "Student Details",
    icon: <PersonOutlineTwoToneIcon />,
    caption: "Personal Information",
    clickable: true,
  },
  {
    label: "Class Details",
    icon: <DescriptionTwoToneIcon />,
    caption: "Class and Package Details",
    clickable: false,
  },
  {
    label: "Enrollment Details",
    icon: <VpnKeyTwoToneIcon />,
    caption: "Date of Enrollment",
    clickable: false,
  },
  {
    label: "Payment",
    icon: <CreditCardTwoToneIcon />,
    caption: "Payment Amount",
    clickable: false,
  },
];

// ==============================|| PROFILE 2 ||============================== //

const EnrollStudent = () => {
  const theme = useTheme();
  // const { borderRadius } = useConfig();
  const location = useLocation();
  const enquiryData = location.state?.enquiryData || null;
  const [value, setValue] = React.useState(0);
  const [isTabEnabled, setIsTabEnabled] = React.useState([
    true,
    false,
    false,
    false,
  ]); // Array to track tab enable/disable state
  const [studentId, setStudentId] = React.useState(null);
  const [demoHistory, setDemoHistory] = React.useState([]);
  const [studentEnrollmentDetails, setStudentEnrollmentDetails] =
    React.useState({
      class_id: null,
      enquiry_id: null,
      package_id: null,
      enrollment_date: "",
      installment: null,
      discount: null,
    });

  const [studentDetails, setStudentDetails] = useState({
    studentId: null,
    testTypeId: null,
    deliveryMode: "",
    branchId: null,
    subServiceId: null,

    // Add other properties as needed
  });


  const [confirmationDetails, setConfirmationDetails] = useState({
    studentName: "",
    studentMobile: "",
    studentEmail: "",
    testType: "",
    trainerName: "",
    slot: "",
    deliveryMode: "",
    branch: "",
    branch_id: null,
    enrollment_date: "",
    package_name: "",
    installments: null,
    price: "",
    discounted_price: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleContinue = (index) => {
    // Add form submission logic for each tab here
    if (index === 0) {
      // Submit form for tab 1
    
    } else if (index === 1) {
      // Submit details for tab 2
    
    }

    // Enable the next tab
    const updatedTabsState = isTabEnabled.map((tab, i) => i === index + 1);
    setIsTabEnabled(updatedTabsState);

    // Move to the next tab
    handleChange(null, index + 1);
  };

  const handleStudentInfoSubmit = (
    studentId,
    testTypeId,
    deliveryMode,
    branchId,
    responseData,
    studentInfo,
    subServiceId
  ) => {
    // Enable the next tab and move to it
    const updatedTabsState = isTabEnabled.map((tab, i) => i === value + 1);
    setIsTabEnabled(updatedTabsState);
    handleChange(null, value + 1);


    setStudentId(studentId);

    setStudentEnrollmentDetails({
      ...studentEnrollmentDetails,
      enquiry_id: studentId,
    });

    setStudentDetails({
      ...studentDetails,
      studentId: studentId,
      testTypeId: testTypeId || null,
      deliveryMode: deliveryMode,
      branchId: branchId || null,
      subServiceId: subServiceId || null,
    });

    setConfirmationDetails({
      ...confirmationDetails,
      studentName: responseData?.first_name || studentInfo?.first_name,
      studentEmail: responseData?.email || studentInfo?.email,
      studentMobile: responseData.mobile || studentInfo?.mobile,
    });
  };

  // Callback function to update selected class, package, and installment
  const handleClassDetailsSubmit = (
    selectedClass,
    selectedPackage,
    selectedInstallment,
    selectedClassData,
    selectedPackageData
  ) => {
    // Update the state with the selected class, package, and installment
    // You may need to modify the state structure based on your requirements

    setStudentEnrollmentDetails({
      ...studentEnrollmentDetails,
      class_id: selectedClass,
      package_id: selectedPackage,
      installment: selectedInstallment,
    });

    setConfirmationDetails({
      ...confirmationDetails,

      trainerName: selectedClassData.trainerName,
      slot: selectedClassData.slot,
      deliveryMode: selectedClassData.deliveryMode,
      branch: selectedClassData.branch,
      branch_id: selectedClassData.branch_id,
      package_name: selectedPackageData.package_name,
      installments: selectedPackageData.payable_installment,
      price: selectedPackageData.original_price,
      discountedPrice: selectedPackageData.discounted_price,
      testType: selectedPackageData.test_type?.name,
      test_type_id: selectedPackageData.test_type?.id,
    });

    // Move to the next tab (Enrollment Date tab)
    handleChange(null, 2);
  };

  const handleEnrollmentDateSubmit = (
    isEnrolNow,
    selectedEnrollmentDate,
    selectedTentativeDate,
    selectedEnrollmentType,
    selectedPastDate
  ) => {
    let selectedDate;

    if (selectedEnrollmentType == "now") {
      selectedDate = selectedEnrollmentDate;
    }

    if (selectedEnrollmentType == "past") {
      selectedDate = selectedPastDate;
    }

    setStudentEnrollmentDetails({
      ...studentEnrollmentDetails,
      enrollment_date: selectedDate,
      is_tentative_enroll_date:
        selectedEnrollmentType == "future" ? true : false,
      tentative_enroll_date: selectedTentativeDate,
      is_past_enroll_date: selectedEnrollmentType == "past" ? true : false,
    });

    setConfirmationDetails({
      ...confirmationDetails,
      enrollment_date: selectedDate,
      is_tentative_enroll_date:
        selectedEnrollmentType == "future" ? true : false,
      tentative_enroll_date: selectedTentativeDate,
      is_past_enroll_date: selectedEnrollmentType == "past" ? true : false,
    });

    handleChange(null, 3);
  };

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/demohistory/${studentId}`)
      .then((response) => response.json())
      .then((data) => {
        setDemoHistory(data);
      })
      .catch((error) => {
        console.error("Error fetching demo items:", error);
      });
  }, [studentId]);


  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <HeadingCard label="Enroll Students" />
      </Grid>
      <Grid item xs={12}>
        <MainCard content={false}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
            {value > 0 && (
                    <Button
                      variant="test"
                      size="medium"
                      onClick={(e) => handleChange(e, value - 1)}
                      startIcon={<ArrowBackIcon/>}
                    >
                      Back
                    </Button>
                  )}
              <CardContent>
                {/* <Grid item xs={12}>
                 
                </Grid> */}
                <Tabs
                  value={value}
                  onChange={handleChange}
                  orientation="vertical"
                  variant="scrollable"
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      borderBottom: "none",
                    },
                    "& button": {
                      color:
                        theme.palette.mode === "dark" ? "grey.600" : "grey.900",
                      minHeight: "auto",
                      minWidth: "100%",
                      py: 1.5,
                      px: 2,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      textAlign: "left",
                      justifyContent: "flex-start",
                      borderRadius: `20px`,
                    },
                    "& button.Mui-selected": {
                      color: theme.palette.secondary.main,
                      background:
                        theme.palette.mode === "dark"
                          ? theme.palette.dark.main
                          : theme.palette.grey[50],
                    },
                    "& button > svg": {
                      marginBottom: "0px !important",
                      marginRight: 1.25,
                      marginTop: 1.25,
                      height: 20,
                      width: 20,
                    },
                    "& button > div > span": {
                      display: "block",
                    },
                    "& > div > span": {
                      display: "none",
                    },
                  }}
                >
                  {tabsOption.map((tab, index) => (
                    <Tab
                      key={index}
                      icon={tab.icon}
                      label={
                        <Grid container direction="column">
                          <Typography variant="h5" color="inherit">
                            {tab.label}
                          </Typography>
                          <Typography
                            component="div"
                            variant="caption"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {tab.caption}
                          </Typography>
                        </Grid>
                      }
                      {...a11yProps(index)}
                      disabled={!tab.clickable}
                    />
                  ))}
                </Tabs>
              </CardContent>
            </Grid>
            <Grid item xs={12} lg={9}>
              <CardContent
                sx={{
                  borderLeft: "1px solid",
                  borderColor:
                    theme.palette.mode === "dark"
                      ? theme.palette.background.default
                      : theme.palette.grey[200],
                  height: "100%",
                }}
              >
                <TabPanel value={value} index={0}>
                  <StudentInfo
                    enquiryData={enquiryData}
                    onNext={handleStudentInfoSubmit}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ClassDetails
                    demoHistory={demoHistory}
                    studentDetails={studentDetails}
                    onNext={handleClassDetailsSubmit}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <DateEnrolled2
                    classId={studentEnrollmentDetails.class_id}
                    onContinue={handleEnrollmentDateSubmit}
                  />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <PaymentDetails
                    confirmationDetails={confirmationDetails}
                    studentEnrollmentDetails={studentEnrollmentDetails}
                  />
                </TabPanel>
              </CardContent>
            </Grid>
          </Grid>
          <Divider />
          <CardActions>
            <Grid container justifyContent="space-between" spacing={0}>
              {/* <Grid item>
                {value > 0 && (
                  <Button
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    onClick={(e) => handleChange(e, value - 1)}
                  >
                    Back
                  </Button>
                )}
              </Grid> */}
              {/*} <Grid item>
                                {value < 3 && (
                                    
                                        <Button variant="contained" color='secondary' size="large" onClick={(e) => handleChange(e, 1 + value)}>
                                            Continue
                                        </Button>
                                   
                                )}
                            </Grid>
                            <Grid item>
                                {value === 3 && (
                                    
                                        <Button variant="contained" color='secondary' size="large" onClick={(e) => handleChange(e, 1 + value)}>
                                            Send Payment Details
                                        </Button>
                                   
                                )}
                                </Grid>*/}
            </Grid>
          </CardActions>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default EnrollStudent;
