import React, { useEffect, useState } from "react";
import NewCardcomp from "../../../components/card/NewCardcomp";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createTheme,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import DetailsStats from "./DetailsStats";

import CustomPopup from "../../../components/CustomPopup";

import zIndex from "@mui/material/styles/zIndex";
import RevenueStat from "./RevenueStat";
import EnrolmentStat from "./EnrolmentStat";
import ToggleButtontheme from "../Components/TogglebuttonTheme";
import FilterButtonComp from "../Components/FilterButtonComp";
import FilterButtonComponent from "../../../newTestPrep/newComponent/NewButtonComp/FilterButtonComponent";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function CentersStatAndData({
  centerRevenueData,
  isCenterLoading,
  isPopup = false,
  statsFilteris,
  selectedFilter,
  fromDate,
  toDate
}) {
  const [statsFilter, setStatsFilter] = useState(
    !isPopup ? "details" : statsFilteris
  );
  const [expandPopup, setExpandPopup] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterdData, setFilterdData] = useState([]);

  console.log("selectedBranches", centerRevenueData);
  const handleApplyStatsFilter = (criteria) => {
    setStatsFilter(criteria);
  };

  const handlopenExpand = () => {
    setExpandPopup(!expandPopup);
  };
  const handlCloseExpand = () => {
    setExpandPopup(!expandPopup);
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}branch`)
      .then((response) => response.json())
      .then((data) => {
        setBranchList(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  const handleFilterButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const allBranchList = [
    {
      id: 0,
      branch_name:
        selectedBranches?.length === branchList?.length &&
        branchList?.length > 0
          ? "Deselect All"
          : "Select All",
    },
    ...branchList,
  ];

  const handleBranchSelection = (branchId) => {
    if (branchId === 0) {
      // "Select All" logic
      if (selectedBranches.length === branchList.length) {
        // Deselect all if already all selected
        setSelectedBranches([]);
      } else {
        // Select all branches
        const branchIds = branchList.map((branch) => branch.id);
        setSelectedBranches(branchIds);
      }
    } else {
      // Select/Deselect individual branch
      setSelectedBranches((prevSelectedBranches) => {
        if (prevSelectedBranches.includes(branchId)) {
          return prevSelectedBranches.filter((id) => id !== branchId);
        } else {
          return [...prevSelectedBranches, branchId];
        }
      });
    }
  };

  useEffect(() => {
    const filterdData = centerRevenueData.filter((item) =>
      selectedBranches.includes(item.id)
    );
    setFilterdData(filterdData);
  }, [selectedBranches, centerRevenueData]);



  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" gap={1} mt={1} ml={1}>
            <Box>
              <img src="/Group (4).png" />
            </Box>

            <Typography
              variant="subtitle1"
              sx={{
                color: "#181818",
              }}
            >
              Total No. of Centres: {centerRevenueData.length}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} textAlign="end">
          <Stack direction="row" justifyContent="flex-end" mt={1} gap={1}>
            <ThemeProvider theme={ToggleButtontheme}>
              <ToggleButtonGroup
                size="small"
                exclusive
                style={{ height: "20px" }}
              >
                <ToggleButton
                  onClick={() => handleApplyStatsFilter("revenuestat")}
                  style={{
                    background: statsFilter === "revenuestat" ? "#00B13F" : "",
                    color:
                      statsFilter === "revenuestat" ? "#FFFFFF" : "#4b4b4b",
                  }}
                >
                  Revenue Stats
                </ToggleButton>
                <ToggleButton
                  onClick={() => handleApplyStatsFilter("enrolstat")}
                  style={{
                    background: statsFilter === "enrolstat" ? "#00B13F" : "",
                    color: statsFilter === "enrolstat" ? "#FFFFFF" : "#4b4b4b",
                  }}
                >
                  Enrolment Stats
                </ToggleButton>
                <ToggleButton
                  onClick={() => handleApplyStatsFilter("details")}
                  style={{
                    background: statsFilter === "details" ? "#00B13F" : "",
                    color: statsFilter === "details" ? "#FFFFFF" : "#4b4b4b",
                  }}
                >
                  Details
                </ToggleButton>
              </ToggleButtonGroup>
            </ThemeProvider>

            {!isPopup && (
              <img
                src="/dashboardimages/expand1.png"
                alt="Expand"
                style={{ cursor: "pointer" }}
                onClick={handlopenExpand}
              />
            )}
          </Stack>
        </Grid>

        {isCenterLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
            width="100%"
          >
            <CircularProgress size="2rem" />
          </Box>
        ) : (
          <Box
            sx={{
              background: "#F9F9F9",
              width: "100%",
              borderRadius: "14px",
            }}
            mt={1}
          >
            <Grid item xs={12} textAlign="end" mt={1} mb={1} mr={1}>
              <div style={{ position: "relative" }}>
              
                <FilterButtonComponent  onClick={handleFilterButtonClick} text="Show All Centers"/>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleFilterClose}
                  sx={{
                    "& .MuiPaper-root": {
                      maxHeight: "300px", // Maximum height for scrolling
                      overflowY: "auto", // Enable scrolling
                      minWidth: "200px", // Ensure menu width is reasonable
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom", // Align below the button
                    horizontal: "left", // Align left edge of the menu to the button
                  }}
                  transformOrigin={{
                    vertical: "top", // Start menu alignment from the top
                    horizontal: "left", // Align left edge of the menu
                  }}
                >
                  {allBranchList.map((branch) => (
                    <MenuItem
                      key={branch.id}
                      onClick={() => handleBranchSelection(branch.id)}
                      sx={{
                        fontSize: "10px",
                        height: "40px",
                      }}
                    >
                      <Checkbox
                        size="small"
                        checked={
                          branch.id === 0
                            ? selectedBranches.length === branchList.length &&
                              branchList.length > 0
                            : selectedBranches.includes(branch.id)
                        }
                        indeterminate={
                          branch.id === 0 &&
                          selectedBranches.length > 0 &&
                          selectedBranches.length < branchList.length
                        }
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent MenuItem click from firing
                          handleBranchSelection(branch.id);
                        }}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: "16px" },
                        }}
                      />
                      <ListItemText
                        primary={branch.branch_name}
                        primaryTypographyProps={{ style: { fontSize: "10px" } }}
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </Grid>

            <Grid item xs={12}>
              {statsFilter === "details" && (
                <DetailsStats
                selectedFilter={selectedFilter}
                fromDate={fromDate}
                toDate={toDate}
                  data={
                    selectedBranches.length === 0
                      ? centerRevenueData
                      : filterdData
                  }
                  isPopup={isPopup}
                />
              )}
              {statsFilter === "revenuestat" && (
                <RevenueStat
                  data={
                    selectedBranches.length === 0
                      ? centerRevenueData
                      : filterdData
                  }
                />
              )}
              {statsFilter === "enrolstat" && (
                <EnrolmentStat
                  data={
                    selectedBranches.length === 0
                      ? centerRevenueData
                      : filterdData
                  }
                />
              )}
            </Grid>
          </Box>
        )}
      </Grid>

      <CustomPopup
        open={expandPopup}
        onClose={handlCloseExpand}
        component={
          <CentersStatAndData
            onClose={handlCloseExpand}
            centerRevenueData={centerRevenueData}
            isPopup={true}
            statsFilteris={statsFilter}
          />
        }
        maxWidth="lg"
        showDivider={false}
      />
    </>
  );
}
