import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import SearchButton from "../../../newTestPrep/newComponent/NewButtonComp/SearchButton";
import CustomTextFieldComp from "../../../newTestPrep/newComponent/customTextFieldComp";
import CustomAutocompleteComp from "../../../newTestPrep/newComponent/AutocompleteComp";
import HeadingCard from "../../../newTestPrep/newComponent/NewCardsComp/HeadingCard";
import MainCard from "../../../components/Maincard";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function AddWalkIn() {
  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");

  const [role, setRole] = useState(userRole);
  const { advisorId } = useSelector((state) => state.user);
  const [enquiryDetails, setEnquiryDetails] = useState({});
  const [enquiryPersonalDetails, setEnquiryPersonalDetails] = useState({});
  const [searching, setSearching] = useState(false);
  const [enquiryFound, setEnquiryFound] = useState(true); // Set this based on your search logic
  const [showForm, setShowForm] = useState(false);
  const [showNoEnquiryPopup, setShowNoEnquiryPopup] = useState(false);
  const [advisors, setAdvisors] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [subLeadSources, setSubLeadSources] = useState([]);
  const [branches, setBranches] = useState([]);
  const [users, setUsers] = useState([]);
  const [testTypes, setTestTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [services, setServices] = useState([]);
  const [eventReasons, setEventReasons] = useState([]);
  const [subServicePrograms, setSubServicePrograms] = useState([]);

  const [selectedService, setSelectedService] = useState(1);
  const [selectedSourceType, setSelectedSourceType] = useState(null);

  const [selectedSubSourceType, setSelectedSubSourceType] = useState(null);
  const [selectedSubSourceTypeID, setSelectedSubSourceTypeID] = useState(null);
  const [referenceByList, setReferenceByList] = useState([]);
  const [referenceBy, setReferenceBy] = useState(null);
  const [subServices, setSubServices] = useState([]);

  const [closestTime, setClosestTime] = useState("");

  const [emailField, setEmailField] = useState("");

  const [walkInStatus, setWalkInStatus] = useState("direct");

  const [isSavingLoading, setIsSavingLoading] = useState(false);
  const [showOtherField, setShowOtherField] = useState(false);
  const [referenceEmployee, setReferenceEmployee] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedPremiumSubService, setSelectedPremiumSubService] =
    useState(null);

  console.log("selectedSubSourceType", enquiryDetails);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [isEvent, setIsEvent] = useState(false);
  const [duration, setDuration] = useState("today");
  const [events, setEvents] = useState([]);

  const handleTypeOfWalkingChange = (event) => {
    setIsEvent(event.target.value === "event");
  };

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const fetchEvents = async (duration) => {
    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/showcampaign`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ filter: duration }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setEvents(data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    if (isEvent) {
      fetchEvents(duration);
    }
  }, [duration, isEvent]);

  //handle search functionality

  const handleSearch = async () => {
    setSearching(true);

    // await new Promise(resolve => setTimeout(resolve, 1000));

    //const emailOrPhone = data.emailField;

    const requestData = {
      email: emailField,
    };

    try {
      // Make a POST request to your API endpoint
      const response = await fetch(
        `${API_ENDPOINT}advisordashboard/registerwalkin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      console.log(response);

      if (response.status === 200) {
        const responseData = await response.json();

        console.log("API Response:", responseData);

        setShowForm(true);
        setEnquiryDetails(responseData.enquiry);
        setEnquiryPersonalDetails(responseData.enquiry_personal_details);
        setWalkInStatus(responseData.walkInStatus);
        setSelectedSourceType(responseData?.enquiry?.lead_source_type_id);
      } else if (response.status === 404) {
        const responseData = await response.json();
        setWalkInStatus(responseData.walkInStatus);
        setShowNoEnquiryPopup(true);
        setShowForm(true);
      } else if (response.status === 400) {
        showSnackbar("Email/ Mobile Required", "error");
      } else {
        console.error(
          "API Request Failed:",
          response.status,
          response.statusText
        );
        showSnackbar("API Request Failed:", "error");
        setShowNoEnquiryPopup(true);
      }
    } catch (error) {
      console.error("An error occurred during the API request:", error);
      showSnackbar("An error occurred during the API request", "error");
      // Handle the error, show an error message, or take other actions as needed
    }
    setSearching(false);
  };

  console.log("enquiryDetails", enquiryDetails?.lead_source_type_id);
  console.log(enquiryDetails?.last_name);

  //fetches the assign_to input- all advisors

  useEffect(() => {
    fetch(`${API_ENDPOINT}advisor`)
      .then((response) => response.json())
      .then((data) => {
        setAdvisors(data);
      })
      .catch((error) => {
        console.error("Error fetching advisor items:", error);
      });
  }, []);

  // useEffect(() => {
  //   // Fetch menu items using the fetch function
  //   fetch(`${API_ENDPOINT}leadsource`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setLeadSources(data);
  //       const deffaultvalue=data.find((id)=>id.id===enquiryDetails?.lead_source_type_id)
  //       setSelectedSourceType(deffaultvalue?.id)
  //       console.log(data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching leadsource:", error);
  //     });
  // }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalltesttype`)
      .then((response) => response.json())
      .then((data) => {
        setTestTypes(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalluser`)
      .then((response) => response.json())
      .then((data) => {
        let filteredData = data;

        /*  if (role === 'advisor') {
                // If the role is advisor, filter the list to show only the logged-in advisor
                filteredData = data.filter(user => user.id === parseInt(userId));
            }*/

        const sortedData = filteredData.sort((a, b) => {
          const nameA = (a.first_name + " " + a.last_name).toUpperCase();
          const nameB = (b.first_name + " " + b.last_name).toUpperCase();
          return nameA.localeCompare(nameB);
        });
        setUsers(sortedData);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}branch`)
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}country`)
      .then((response) => response.json())
      .then((data) => {
        const sortedData = [...data].sort(
          (a, b) => a.phone_code - b.phone_code
        );
        const uniqueArray = sortedData.filter(
          (obj, index, self) =>
            index === self.findIndex((t) => t.phone_code === obj.phone_code)
        );
        setCountriesList(data);
        setCountries(uniqueArray);

        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching country:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}service`)
      .then((response) => response.json())
      .then((data) => {
        setServices(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedServices.length > 0) {
      fetch(`${API_ENDPOINT}subservice`)
        .then((response) => response.json())
        .then((data) => {
          const filteredSubServices = data.filter((sub) =>
            selectedServices.includes(sub.service_id)
          );
          setSubServices(data);
        })
        .catch((error) => {
          console.error("Error fetching sub-services:", error);
        });
    } else {
      setSubServices([]);
    }
  }, [selectedServices]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}subservice-program`)
      .then((response) => response.json())
      .then((data) => {
        setSubServicePrograms(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching sub service programs:", error);
      });
  }, [selectedPremiumSubService]);

  useEffect(() => {
    fetch(`${API_ENDPOINT}leadsource`)
      .then((response) => response.json())
      .then((data) => {
        const filteredLeadSources = data.filter((sub) => sub.is_active === 1);
        //setSubSourceTypes(filteredLeadSources);
        setLeadSources(filteredLeadSources);
      })
      .catch((error) => {
        console.error("Error fetching lead sources:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedSourceType) {
      fetch(`${API_ENDPOINT}subleadsource`)
        .then((response) => response.json())
        .then((data) => {
          const filteredLeadSources = data.filter(
            (sub) => sub.lead_source_type_id === selectedSourceType
          );
          setSubLeadSources(filteredLeadSources);
          const selectedSubleadsourcetype = filteredLeadSources.find(
            (i) => i.id === enquiryDetails.sub_lead_source_type_id
          );
          setSelectedSubSourceType(selectedSubleadsourcetype);

          console.log("selectedSubleadsourcetype", selectedSubleadsourcetype);
        })
        .catch((error) => {
          console.error("Error fetching sub lead sources:", error);
        });
    }
  }, [selectedSourceType]);

  // // get alredy subleadsorce type return in api
  //   useEffect(() => {
  //     const selectedSubleadsourcetype=subLeadSources.find((i)=>i.id===enquiryDetails.sub_lead_source_type_id)
  //     setSelectedSubSourceType(selectedSubleadsourcetype)

  //     console.log("selectedSubleadsourcetype",selectedSubleadsourcetype)
  //   }, [enquiryDetails])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}referencewalkin`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setReferenceByList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (enquiryDetails?.service_id) {
      const defaultSelected = services.filter((service) =>
        Array.isArray(enquiryDetails.service_id)
          ? enquiryDetails.service_id.includes(service.id)
          : service.id === enquiryDetails.service_id
      );
      setSelectedServices(defaultSelected);
      setValue(
        "service_id",
        defaultSelected.map((s) => s.id)
      );
    }
  }, [enquiryDetails?.service_id, services, setValue]);

  const handleServiceChange2 = (event) => {
    setSelectedService(event.target.value);
    setValue("service_id", event.target.value);
  };

  const handleServiceChange = (_, values) => {
    setSelectedServices(values);
    const selectedServiceIds = values.map((service) => service.id);
    setValue("service_id", selectedServiceIds);

    if (values.some((service) => service.id === 2)) {
      setValue("assign_to", null);
    }

    // Reset sub-service fields if their main service is not selected
    if (!selectedServiceIds.includes(1)) {
      setValue("test_preparation_sub_service_id", null);
    }
    if (!selectedServiceIds.includes(2)) {
      setValue("premium_admission_sub_service_id", null);
      setValue("premium_admission_programs_id", null);
    }
  };

  const handleSourceTypeChange = (event) => {
    setSelectedSourceType(event.target.value);
    setValue("lead_source_type_id", event.target.value);
    setSelectedSubSourceType(null);
    setSelectedSubSourceTypeID(null);
  };

  const handleSubSourceTypeChange = (event, value) => {
    const ids = value?.source_type;
    setSelectedSubSourceType(value);
    setSelectedSubSourceTypeID(ids);
    setValue("sub_lead_source_type_id", value?.id);
  };

  const handleReferenceEmployeeChange = (event, value) => {
    setReferenceEmployee(value);
    setValue("reference_employee_id", value?.id);
  };

  const handleReferenceChange = (event, value) => {
    if (value && value.name === "Other") {
      setShowOtherField(true); // Show the additional text field
      setReferenceBy(value); // Clear the selection
      //setValue('reference_walkin_id', 'other');
      setValue("reference_walkin_id", value?.id || null);
      // Reset the controlled value
    } else {
      setShowOtherField(false); // Hide the additional text field
      setReferenceBy(value);
      setValue("reference_walkin_id", value?.id || null);
      setValue("reference_employee_id", null);
    }
  };

  useEffect(() => {
    fetch(`${API_ENDPOINT}eventreason`)
      .then((response) => response.json())
      .then((data) => {
        setEventReasons(data);
      })
      .catch((error) => {
        console.error("Error fetching events reason items:", error);
      });
  }, []);

  //handles the submit form

  const onSubmit = (data) => {
    console.log(data);
    setIsSavingLoading(true);

    if (referenceBy?.id === "Other") {
      data.reference_walkin_id = null;
    }

    data.walk_in_status = walkInStatus;
    data.user_id = userId;

    fetch(`${API_ENDPOINT}route/bookappointment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          //alert('Enquiry sent successfully');
          setIsSavingLoading(false);
          showSnackbar("Walk in appointment sent successfully", "success");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (response.status === 403) {
          setIsSavingLoading(false);
          showSnackbar(
            "Error assign to this user.Please Assign to either admin, advisor or trainer",
            "error"
          );
        } else {
          // API call failed, show error message
          //alert('Error submitting enquiry. Please try again.');
          setIsSavingLoading(false);
          showSnackbar(
            "Error submitting walk in enquiry. Please try again",
            "error"
          );
        }
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error("Error:", error);
        // alert('An error occurred. Please try again later.');
        setIsSavingLoading(false);
        showSnackbar("An error occurred. Please try again later.", "error");
      });
  };

  // const selectedOption = watch('email', 'phone');

  const CenteredBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "100vh", // Adjust as needed
    padding: "1rem", // Adjust as needed
  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: `2px solid #2196F3`,
    borderRadius: "10px",
  }));

  // Get current time in HH:mm format adjusted to local time zone
  const currentTime = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  console.log("currentTime", currentTime);

  // const theme = createTheme({
  //   components: {
  //     // Name of the component
  //     MuiAutocomplete: {
  //       styleOverrides: {
  //         // Name of the slot
  //         noOptions: {
  //           // Some CSS
  //           color: "red",
  //           fontSize: "0.7rem",
  //         },
  //       },
  //     },
  //   },
  // });

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <HeadingCard label="Register Walk In" />
        </Grid>
        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  value={emailField}
                  onChange={(e) => setEmailField(e.target.value)}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                  fullWidth
                  label="Email/Phone"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SearchButton showIcon={true} onClick={handleSearch}>
                  Search
                </SearchButton>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      {showForm && (
        <Item>
          <Grid container spacing={3}>
            <Grid item xs={6} md={4} lg={3}>
              <Typography variant="h6">
                Walk in Status
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1em",
                  }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="walk_in_status"
                control={control}
                defaultValue={walkInStatus}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    select
                    {...field}
                    InputProps={{
                      style: { borderRadius: "10px" },
                      readOnly: true,
                    }}
                    fullWidth
                    error={Boolean(errors.walk_in_status)}
                    disabled
                    // helperText={errors.walk_in_status}
                  >
                    <MenuItem value="direct">Direct Walk In</MenuItem>
                    <MenuItem value="indirect">Indirect Walk In</MenuItem>
                    <MenuItem value="existing">Existing Walk In</MenuItem>
                  </CustomTextFieldComp>
                )}
              />
              {errors.walk_in_status && (
                <span style={{ color: "red" }}>
                  {errors.walk_in_status.message}
                </span>
              )}
            </Grid>

            <Grid item xs={6} md={4} lg={3}>
              <Typography variant="h6">
                Type of Walk In
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1em",
                  }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="type_of_walking"
                control={control}
                rules={{ required: "Field is required" }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    select
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      handleTypeOfWalkingChange(e);
                    }}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    fullWidth
                    error={Boolean(errors.type_of_walking)}
                    helperText={
                      errors.type_of_walking && errors.type_of_walking.message
                    }
                  >
                    <MenuItem value="normal">Normal Walk In</MenuItem>
                    <MenuItem value="event">Event Walk In</MenuItem>
                  </CustomTextFieldComp>
                )}
              />
            </Grid>

            {isEvent && (
              <>
                <Grid item xs={6} md={4} lg={3}>
                  <Typography variant="h6">
                    Duration
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        marginLeft: "4px",
                        fontSize: "1em",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <Select
                    size="normal"
                    labelId="event-filter-select-label"
                    id="event-filter-select"
                    value={duration}
                    onChange={handleDurationChange}
                    fullWidth
                    style={{ borderRadius: "10px" }}
                  >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="week">Last One Week</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={6} md={4} lg={3}>
                  <Typography variant="h6">
                    Select Event
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        marginLeft: "4px",
                        fontSize: "1em",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <Controller
                    name="event_id"
                    control={control}
                    rules={{ required: "Field is required" }}
                    render={({ field }) => (
                      <CustomTextFieldComp
                        size="normal"
                        select
                        {...field}
                        InputProps={{
                          style: { borderRadius: "10px" },
                        }}
                        fullWidth
                        error={Boolean(errors.event_id)}
                        helperText={errors.event_id && errors.event_id.message}
                      >
                        {events.map((event, index) => (
                          <MenuItem key={index} value={event.id}>
                            {event.campaign_name}
                          </MenuItem>
                        ))}
                      </CustomTextFieldComp>
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid item xs={12} textAlign="center" mt={3}>
            <Typography id="personal-info" variant="h5" fontWeight={700}>
              Personal Information
            </Typography>
          </Grid>

          <Grid item xs={12} mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={3}>
                <Typography variant="h6">
                  First Name
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="first_name"
                  control={control}
                  defaultValue={enquiryPersonalDetails?.first_name}
                  rules={{ required: "First Name is required" }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      size="normal"
                      {...field}
                      fullWidth
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      error={Boolean(errors.first_name)}
                      helperText={
                        errors.first_name && errors.first_name.message
                      }
                      //value={enquiryDetails?.first_name || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <Typography variant="h6">Last Name</Typography>
                <Controller
                  name="last_name"
                  control={control}
                  defaultValue={enquiryPersonalDetails?.last_name}
                  // rules={{ required: 'Last Name is required' }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      size="normal"
                      {...field}
                      fullWidth
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      error={Boolean(errors.last_name)}
                      //helperText={errors.last_name && 'Last name is required'}
                      //value={enquiryDetails?.last_name || ''}
                    />
                  )}
                />
                {errors.last_name && (
                  <span style={{ color: "red" }}>
                    {errors.last_name.message}
                  </span>
                )}
              </Grid>

              {/* Contact Information */}

              <Grid item xs={6} md={4} lg={3} container>
                <Grid item xs={4} md={4} lg={5}>
                  <Typography variant="h6">
                    ISD Code
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        marginLeft: "4px",
                        fontSize: "1em",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <Controller
                    name="mobile_country_code"
                    control={control}
                    defaultValue={
                      enquiryPersonalDetails?.mobile_country_code || 91
                    }
                    rules={{ required: "Country code is required" }}
                    render={({ field }) => (
                      <CustomTextFieldComp
                        select
                        size="normal"
                        {...field}
                        InputProps={{
                          style: { borderRadius: "10px" },
                        }}
                        fullWidth
                        defaultValue={91}
                        error={Boolean(errors.mobile_country_code)}
                        //helperText={errors.country && 'Country is required'}
                      >
                        {countries.map((country) => (
                          <MenuItem
                            key={country.phone_code}
                            value={country.phone_code}
                          >
                            {country.phone_code}
                          </MenuItem>
                        ))}
                      </CustomTextFieldComp>
                    )}
                  />
                  {errors.mobile_country_code && (
                    <span style={{ color: "red" }}>
                      {errors.mobile_country_code.message}
                    </span>
                  )}
                </Grid>

                <Grid item xs={8} md={8} lg={7}>
                  <Typography variant="h6">
                    Mobile
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        marginLeft: "4px",
                        fontSize: "1em",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <Controller
                    name="mobile"
                    control={control}
                    //defaultValue='+91'
                    defaultValue={enquiryPersonalDetails?.mobile}
                    rules={{
                      required: "Contact number is required",
                      pattern: {
                        value: /^[1-9][0-9]{9}$/,
                        message: "Invalid mobile number.",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextFieldComp
                        size="normal"
                        {...field}
                        InputProps={{
                          style: { borderRadius: "10px" },
                        }}
                        error={!!errors.mobile}
                        helperText={errors.mobile && errors.mobile.message}
                        fullWidth
                      />
                    )}
                  ></Controller>
                </Grid>
              </Grid>

              <Grid item xs={6} md={4} lg={3}>
                <Typography variant="h6">
                  Email
                  {/* <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span> */}
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={enquiryPersonalDetails?.email}
                  rules={{
                    //required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      size="normal"
                      {...field}
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      variant="outlined"
                      error={Boolean(errors.email)}
                      //helperText={errors.email}
                      fullWidth
                      //value={enquiryDetails?.email || ''}
                    />
                  )}
                ></Controller>
                {errors.email && (
                  <span style={{ color: "red" }}>{errors.email.message}</span>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={3}>
            <Typography id="personal-info" variant="h5" fontWeight={700}>
              Mandatory Information
            </Typography>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={3}>
                <Typography variant="h6">
                  Branch Visited
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="branch_visited"
                  control={control}
                  defaultValue={enquiryDetails?.branch_id}
                  rules={{ required: "Field is required" }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      size="normal"
                      select
                      {...field}
                      // rules={{ required: "Field is required" }}
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      fullWidth
                      error={Boolean(errors.branch_visited)}
                      helperText={
                        errors.branch_visited && errors.branch_visited?.message
                      }
                    >
                      {branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          {branch.branch_name}
                        </MenuItem>
                      ))}
                    </CustomTextFieldComp>
                  )}
                />
              </Grid>

              <Grid item xs={6} md={4} lg={3}>
                <Typography variant="h6">
                  Service Name
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="service_id"
                  control={control}
                  defaultValue={
                    enquiryDetails?.service_id
                      ? services.filter((service) =>
                          Array.isArray(enquiryDetails.service_id)
                            ? enquiryDetails.service_id.includes(service.id)
                            : service.id === enquiryDetails.service_id
                        )
                      : []
                  }
                  rules={{ required: "Service name is required" }}
                  render={({ field }) => (
                    <CustomAutocompleteComp
                      multiple
                      options={services}
                      getOptionLabel={(option) => option.service_name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox size="small" checked={selected} />
                          {option.service_name}
                        </li>
                      )}
                      onChange={handleServiceChange}
                      value={selectedServices}
                      error={Boolean(errors.service_id)}
                      helperText={
                        errors.service_id && errors.service_id.message
                      }
                      tooltipContent={(option) => option.service_name}
                    />
                  )}
                />
              </Grid>

              {selectedServices.some((s) => s.id === 1) && (
                <Grid item xs={6} md={4} lg={3}>
                  <Typography variant="h6">
                    Test Preparation Sub Service
                    <span
                      style={{
                        color: "red",
                        marginLeft: "4px",
                        fontSize: "1em",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <Controller
                    name="test_preparation_sub_service_id"
                    control={control}
                    defaultValue={enquiryDetails?.sub_service_id || null}
                    rules={{
                      required: "Test Preparation Sub Service is required",
                    }}
                    render={({ field }) => (
                      <CustomTextFieldComp
                        size="normal"
                        select
                        {...field}
                        fullWidth
                        sx={{
                          borderRadius: "10px",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                        }}
                        error={Boolean(errors.test_preparation_sub_service_id)}
                      >
                        {subServices
                          .filter((s) => s.service_id === 1)
                          .map((service) => (
                            <MenuItem key={service.id} value={service.id}>
                              {service.name}
                            </MenuItem>
                          ))}
                      </CustomTextFieldComp>
                    )}
                  />
                  {errors.test_preparation_sub_service_id && (
                    <span style={{ color: "red", fontSize: "0.7rem" }}>
                      {errors.test_preparation_sub_service_id.message}
                    </span>
                  )}
                </Grid>
              )}

              {selectedServices.some((s) => s.id === 2) && (
                <>
                  <Grid item xs={6} md={4} lg={3}>
                    <Typography variant="h6">
                      Premium Admission Sub Service
                      <span
                        style={{
                          color: "red",
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                    </Typography>
                    <Controller
                      name="premium_admission_sub_service_id"
                      control={control}
                      defaultValue={enquiryDetails?.sub_service_id || null}
                      rules={{
                        required: "Premium Admission Sub Service is required",
                      }}
                      render={({ field }) => (
                        <CustomTextFieldComp
                          size="normal"
                          select
                          {...field}
                          fullWidth
                          sx={{
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                          }}
                          error={Boolean(
                            errors.premium_admission_sub_service_id
                          )}
                          helperText={
                            errors.premium_admission_sub_service_id &&
                            errors.premium_admission_sub_service_id.message
                          }
                          onChange={(e) => {
                            field.onChange(e);
                            setSelectedPremiumSubService(e.target.value);
                          }}
                        >
                          {subServices
                            .filter((s) => s.service_id === 2)
                            .map((service) => (
                              <MenuItem key={service.id} value={service.id}>
                                {service.name}
                              </MenuItem>
                            ))}
                        </CustomTextFieldComp>
                      )}
                    />
                  </Grid>

{selectedPremiumSubService !==null && (
                  <Grid item xs={6} md={4} lg={3}>
                    <Typography variant="h6">
                    {selectedPremiumSubService === 13
                                    ? "Undergraduate Admission Program"
                                    : "Postgraduate Admission Program"}
                    </Typography>
                    <Controller
                      name="premium_admission_programs_id"
                      control={control}
                      defaultValue={
                        enquiryDetails?.premium_admission_programs_id || null
                      }
                      render={({ field }) => (
                        <CustomTextFieldComp
                          size="normal"
                          select
                          {...field}
                          fullWidth
                          sx={{
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                          }}
                        >
                          {subServicePrograms
                            .filter(
                              (s) =>
                                s.sub_service_id === selectedPremiumSubService
                            )
                            .map((program) => (
                              <MenuItem key={program.id} value={program.id}>
                                {program.name}
                              </MenuItem>
                            ))}
                        </CustomTextFieldComp>
                      )}
                    />
                  </Grid>
                  )}
                </>
              )}

              {/*}  <Grid item xs={6} md={4} lg={3}>
              <Typography variant="h6">
                Service Name
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1em",
                  }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="service_id"
                control={control}
                defaultValue={enquiryDetails?.service_id || 1}
                rules={{ required: "Field is required" }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    select
                    {...field}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    fullWidth
                    error={Boolean(errors.service_name)}
                    value={selectedService}
                    onChange={handleServiceChange}
                    // helperText={errors.service_name}
                  >
                    {services.map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                        {service.service_name}
                      </MenuItem>
                    ))}
                  </CustomTextFieldComp>
                )}
              />
              {errors.service_name && (
                <span style={{ color: "red" }}>
                  {errors.service_name.message}
                </span>
              )}
            </Grid>

            <Grid item xs={6} md={4} lg={3}>
              <Typography variant="h6">
                Sub Service
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1em",
                  }}
                >
                  *
                </span>
              </Typography>
              <Controller
                name="sub_service_id"
                control={control}
                defaultValue={enquiryDetails?.sub_service_id}
                rules={{ required: "Field is required" }}
                render={({ field }) => (
                  <CustomTextFieldComp
                    size="normal"
                    select
                    {...field}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    fullWidth
                    error={Boolean(errors.sub_service)}
                    //  helperText={errors.sub_service}
                  >
                    {subServices.map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                        {service.name}
                      </MenuItem>
                    ))}
                  </CustomTextFieldComp>
                )}
              />
              {errors.sub_service && (
                <span style={{ color: "red" }}>
                  {errors.sub_service.message}
                </span>
              )}
            </Grid>*/}
              {!selectedServices.some((s) => s.id === 2) && (
                <Grid item xs={6} md={4} lg={3}>
                  <Typography variant="h6">
                    Assign To
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        marginLeft: "4px",
                        fontSize: "1em",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <Controller
                    name="assign_to"
                    control={control}
                    rules={{ required: "Field is required" }}
                    defaultValue={enquiryDetails?.assign_to || parseInt(userId)}
                    render={({ field }) => (
                      <CustomTextFieldComp
                        select
                        size="normal"
                        {...field}
                        InputProps={{
                          style: { borderRadius: "10px" },
                        }}
                        fullWidth
                        error={Boolean(errors.assign_to)}
                        // helperText={errors.assign_to}
                        //value={ advisorId || ''}
                      >
                        {users.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.first_name}
                          </MenuItem>
                        ))}
                      </CustomTextFieldComp>
                    )}
                  />
                  {errors.assign_to && (
                    <span style={{ color: "red" }}>
                      {errors.assign_to.message}
                    </span>
                  )}
                </Grid>
              )}

              <Grid item xs={6} md={4} lg={3}>
                <Typography variant="h6">
                  Preferred Appointment Date
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="preferred_appointment_date"
                  control={control}
                  defaultValue={new Date().toISOString().split("T")[0]}
                  rules={{ required: "Field is required" }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      size="normal"
                      type="date"
                      {...field}
                      InputProps={{
                        style: { borderRadius: "10px" },
                        inputProps: {
                          min: new Date().toISOString().split("T")[0],
                        },
                      }}
                      fullWidth
                      error={Boolean(errors.preferred_appointment_date)}
                      // helperText={errors.preferred_appointment_date}
                    ></CustomTextFieldComp>
                  )}
                />
                {errors.preferred_appointment_date && (
                  <span style={{ color: "red" }}>
                    {errors.preferred_appointment_date.message}
                  </span>
                )}
              </Grid>

              <Grid item xs={6} md={4} lg={3}>
                <Typography variant="h6">
                  Time
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="time"
                  control={control}
                  defaultValue={currentTime}
                  // value={currentTime}
                  rules={{ required: "Field is required" }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      size="normal"
                      type="time"
                      {...field}
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      fullWidth
                      error={Boolean(errors.time)}
                      // helperText={errors.time}
                    ></CustomTextFieldComp>
                  )}
                />
                {errors.time && (
                  <span style={{ color: "red" }}>{errors.time.message}</span>
                )}
              </Grid>

              <Grid item xs={6} md={4} lg={3}>
                <Typography variant="h6">
                  Lead Source Type
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="lead_source_type_id"
                  control={control}
                  defaultValue={enquiryDetails.lead_source_type_id}
                  rules={{ required: "Lead Source is required" }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      size="normal"
                      select
                      {...field}
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      fullWidth
                      error={Boolean(errors.lead_source_type_id)}
                      /*onChange={e => {
                                            field.onChange(e);
                                            handleLeadSourceTypeChange(e.target.value);
                                          }}*/
                      value={selectedSourceType}
                      onChange={handleSourceTypeChange}
                      helperText={
                        errors.lead_source_type_id &&
                        errors.lead_source_type_id.message
                      }
                    >
                      {leadSources.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </CustomTextFieldComp>
                  )}
                />
              </Grid>

              <Grid item xs={6} md={4} lg={3}>
                <Typography variant="h6">
                  Sub Lead Source Type
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="sub_lead_source_type_id"
                  control={control}
                  rules={{ required: "Sub Lead Source is required" }}
                  render={({ field }) => (
                    <CustomAutocompleteComp
                      disablePortal
                      {...field}
                      value={selectedSubSourceType}
                      onChange={handleSubSourceTypeChange}
                      noOptionsText={
                        selectedSourceType === null
                          ? "Select Source Type First"
                          : subLeadSources.length === 0
                          ? "No Data Available"
                          : ""
                      }
                      options={subLeadSources}
                      getOptionLabel={(option) => option.source_type}
                      error={Boolean(errors.sub_lead_source_type_id)}
                      helperText={
                        errors.sub_lead_source_type_id &&
                        errors.sub_lead_source_type_id.message
                      }
                    />
                  )}
                />
              </Grid>

              {/* Referece By  */}
              {selectedSubSourceTypeID === "Reference" && (
                <Grid item xs={6} md={4} lg={3}>
                  <Typography variant="h6">
                    Reference By
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        marginLeft: "4px",
                        fontSize: "1em",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <Controller
                    name="reference_walkin_id"
                    control={control}
                    rules={{ required: "Reference by is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        size="normal"
                        value={referenceBy}
                        onChange={handleReferenceChange}
                        options={[
                          ...referenceByList,
                          { id: "Other", name: "Other" },
                        ]}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <CustomTextFieldComp
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { borderRadius: "10px" },
                            }}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                  {errors.reference_walkin_id && (
                    <span style={{ color: "red" }}>
                      {errors.reference_walkin_id.message}
                    </span>
                  )}
                </Grid>
              )}

              {showOtherField && (
                <Grid item xs={6} md={4} lg={3}>
                  <Typography variant="h6">
                    Employee Reference List
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        marginLeft: "4px",
                        fontSize: "1em",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <Controller
                    name="reference_employee_id"
                    control={control}
                    //rules={{ required: "Reference by is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        size="normal"
                        value={referenceEmployee}
                        onChange={handleReferenceEmployeeChange}
                        options={users}
                        getOptionLabel={(option) => option.first_name}
                        renderInput={(params) => (
                          <CustomTextFieldComp
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { borderRadius: "10px" },
                            }}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={6} md={4} lg={3}>
                <Typography variant="h6">
                  Reason for visit
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="event_reason_id"
                  control={control}
                  rules={{ required: "Reason for visit is required" }}
                  render={({ field }) => (
                    <CustomTextFieldComp
                      size="normal"
                      select
                      {...field}
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      fullWidth
                      error={Boolean(errors.event_reason_id)}
                      helperText={
                        errors.event_reason_id && errors.event_reason_id.message
                      }
                    >
                      {eventReasons.map((subSource) => (
                        <MenuItem key={subSource.id} value={subSource.id}>
                          {subSource.reason_name}
                        </MenuItem>
                      ))}
                    </CustomTextFieldComp>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Box mt={2} display="flex" justifyContent="right">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </Box>
        </Item>
      )}

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {/* <Dialog open={showForm}>
                <DialogTitle>Pick Personal Details</DialogTitle>
                <DialogContent>
                   
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowForm(false)}>Cancel</Button>
                    <Button onClick={() => setShowForm(false)}>Save</Button>
                </DialogActions>
            </Dialog> */}

      <Dialog open={showNoEnquiryPopup}>
        <DialogTitle variant="h4">No Enquiry Found</DialogTitle>
        <DialogContent>
          No enquiry exists with the provided details.
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowNoEnquiryPopup(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={searching} style={{ zIndex: 999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Backdrop open={isSavingLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Saving Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
}

export default AddWalkIn;
