// Author : Ayesha Shaikh
//  Date : 30 Des 2024

import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  createTheme,
  Grid,
  Stack,
  TextField,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import RevenueCards from "./NewRevenueCards";
import MainCard from "../../../components/Maincard";
import { useDateUtils } from "../../../utils/formatTime";
import DisplayCurrentDateTime from "../Components/DisplayCurrentDateTime";
import LeadCalandar from "../../LeadConversation/LeadCalandar";
import DetailsStats from "./DetailsStats";
import MonthlyRevenuPichart from "./MonthlyRevenuPichart";
import NewCardcomp from "../../../components/card/NewCardcomp";
import UpcommingEvents from "../Components/UpcommingEvents";

import { useGetTokenOrLogout } from "../../../utils/token";
import CentersStatAndData from "./CentersStatAndData";
import Content from "../Components/Content";
import CustomThemeDashboard from "../Components/CustomThemeDashboard";
import FilterButtonComp from "../Components/FilterButtonComp";
import CustomPopup from "../../../components/CustomPopup";
import MoreCardDetails from "./MoreCardDetails";
import EnrollDetails from "./EnrollDetails";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import LoaderComp from "../../../newTestPrep/newComponent/LoaderComp/Loader";


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function CenterAdminDashboard() {
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const getTokenOrLogout = useGetTokenOrLogout();
  const [selectedFilter, setSelectedFilter] = useState("today");

  const { currentMonthName, lastMonthName, weekRange } = useDateUtils();
  const [dailyData, setDailyData] = useState({});
  const [chosenDate, setChosenDate] = useState(new Date());
  const [revenueLoading, setRevenueLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [centerRevenueData, setCenterRevenueData] = useState([]);
  const [isCenterLoading, setIsCenterLoading] = useState(false);
  const [upcommigevent, setUpcommingEvent] = useState([]);
  const [upcommingLoading, setUpcommingLoding] = useState(false);
  const [isPieChartLoading, setIsPieChartLoading] = useState(false);
  const [pieChartData, setPieChartData] = useState({});
  const [opendetailPopup, setOpenDetailPopup] = useState(false);
  const [selectedCardName, setSelectedCardName] = useState("");

  const [SelectedCardData, setSelectedCardData] = useState([]);
  const [selectedcardFilter, setSelectedcardfilter] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };


  const handleClickFilter = (criteria, cardFilter) => {
    setSelectedFilter(criteria);

    if (criteria !== "custom") {
      handleApplyRevenuFilter(criteria, null, null);
      getRevenueForCenters(criteria, null, null);
      setFromDate(null);
      setToDate(null);
    }
    if (criteria === "custom" && fromDate !== null && toDate !== null) {
      handleApplyRevenuFilter(criteria, fromDate, toDate);
      getRevenueForCenters(criteria, fromDate, toDate);
    }
  };

  const handleDateChange = (date) => {
    setChosenDate(date);

    // getPieChartData(date)
  };

  const handleApplyRevenuFilter = async (criteria, fromDate, toDate) => {
    setRevenueLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),

      filter: criteria,
      to_date: toDate || null,
      from_date: fromDate || null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route/getcenteradminrevenuebyfilter`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setDailyData(result);

        setRevenueLoading(false);
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error, "Error");
      setRevenueLoading(false);
    }
  };

  const getRevenueForCenters = (criteria, fromDate, toDate) => {
    setIsCenterLoading(true);

    const requestData = {
      user_id: userId,
      filter: criteria,
      to_date: toDate || null,
      from_date: fromDate || null,
    };

    fetch(`${API_ENDPOINT}route/highestrevenuecenters`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        setCenterRevenueData(data);
        setIsCenterLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching daily data:", error);
        setIsCenterLoading(false);
      });
  };

  // Fetch Upcomming Event
  useEffect(() => {
    setUpcommingLoding(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/ongoing-upcomingevent/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          setUpcommingEvent(data);
          setUpcommingLoding(false);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // PiechartData
  const getPieChartData = (date) => {
    setIsPieChartLoading(true);

    const requestData = {
      user_id: userId,
      date: date,
    };

    fetch(`${API_ENDPOINT}route/piechart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPieChartData(data);
        setIsPieChartLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching daily data:", error);
        setIsPieChartLoading(false);
      });
  };

  useEffect(() => {
    if (selectedFilter !== "custom") {
      handleApplyRevenuFilter(selectedFilter);
      getRevenueForCenters(selectedFilter);
    }
  }, []);

  const handleFilterDateChange = (e) => {
    setToDate(e.target.value);
  };

  useEffect(() => {
    getPieChartData(chosenDate);
  }, [chosenDate]);

  // detailPopup
  const handleOpenDetailPopup = (cardName, cardFilter) => {
    setOpenDetailPopup(true);
    setSelectedCardName(cardName);
    setSelectedcardfilter(cardFilter);
  };

  const handleCloseDetailPopup = () => {
    setOpenDetailPopup(false);
  };
  console.log("fromDate", fromDate);

  const getCardMoreInfo = async (cardName, cardFilter) => {
    setDetailsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const requestData = {
      user_id: parseInt(userId),
      filter: selectedFilter,
      to_date: toDate,
      from_date: fromDate,
      filter_type: cardFilter,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route/getcenteradminrevenuebyfilter-with-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setSelectedCardData(result);
        setDetailsLoading(false);
        handleOpenDetailPopup(cardName, cardFilter);
      } else {
        console.log("Error");
        showSnackbar("Server Error", "error");
        setDetailsLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      showSnackbar("Server Error", "error");
      setDetailsLoading(false);
    }
  };

  return (
    <>
     <LoaderComp isLoading={detailsLoading}/>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={9} sm={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Content />
            </Grid>

            {/* Filter Button */}
            <Grid item xs={12} sm={12} mt={2}>
              <FilterButtonComp
                selectedFilter={selectedFilter}
                handleClickFilter={handleClickFilter}
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
              />
            </Grid>

            <Grid item xs={12} container spacing={2} gap={1}>
              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#D5F6E1"
                  title="Total Revenue"
                  iconSrc="/Group 1000002878.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                      dailyData?.total_revenue
                    )
                  }
                  onClick={() =>
                    getCardMoreInfo("Total Revenue", "total_revenue")
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#E7F3FF"
                  title="Collected Revenue"
                  iconSrc="/Group 1000002854.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                      dailyData?.total_collected_revenue
                    )
                  }
                  onClick={() =>
                    getCardMoreInfo("Collected Revenue", "collected_revenue")
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#FFEDFB"
                  title="Pending Revenue"
                  iconSrc="/Group 1000002879.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                      dailyData?.total_pending_revenue
                    )
                  }
                  onClick={() =>
                    getCardMoreInfo("Pending Revenue", "pending_revenue")
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#FFF6E2"
                  title="New Enrolments"
                  iconSrc="/Group 1000002880.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                      dailyData?.total_new_enrollment
                    )
                  }
                  onClick={() =>
                    getCardMoreInfo("New Enrollments", "new_enrollments")
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <NewCardcomp height="415px">
                <CentersStatAndData
                  centerRevenueData={centerRevenueData}
                  isCenterLoading={isCenterLoading}
                  selectedFilter={selectedFilter}
                  fromDate={fromDate}
                  toDate={toDate}
                />
              </NewCardcomp>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={3} sm={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DisplayCurrentDateTime />
            </Grid>
            <Grid item xs={12}>
              <UpcommingEvents
                data={upcommigevent}
                loading={upcommingLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <NewCardcomp>
                <LeadCalandar onDateChange={handleDateChange} />
                {isPieChartLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <MonthlyRevenuPichart
                    loading={isPieChartLoading}
                    pieChartData={pieChartData}
                  />
                )}
              </NewCardcomp>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <CustomPopup
        open={opendetailPopup}
        onClose={handleCloseDetailPopup}
        component={
          selectedcardFilter === "new_enrollments" ? (
            <EnrollDetails
              onClose={handleCloseDetailPopup}
              selectedFilter={selectedFilter}
              SelectedCardData={SelectedCardData}
              fromDate={fromDate}
              toDate={toDate}
            />
          ) : (
            <MoreCardDetails
              onClose={handleCloseDetailPopup}
              selectedFilter={selectedFilter}
              SelectedCardData={SelectedCardData}
              selectedCardName={selectedCardName}
              selectedCountFilter={selectedcardFilter}
              fromDate={fromDate}
              toDate={toDate}
            />
          )
        }
        title={`${selectedCardName} Details`}
        maxWidth="lg"
        showDivider={true}
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
