import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../../../components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function EditTrainerForm({ selectedData }) {
 
  const userId = localStorage.getItem("userId");
  const [alltrainers, setAllTrainers] = useState([]);
  const [trainerName, setTrainerName] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  //fetch all trainers
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}trainer`
        );
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          const defaultTrainer = sortedData.find(
            (trainer) => trainer.id === selectedData?.trainer_id?.id
          );
          setTrainerName(defaultTrainer);
          setAllTrainers(sortedData);
       
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleTrainerNameChange = (event, value) => {
    setTrainerName(value);
  };

  const handlesubmit = async () => {
    if (trainerName === null) {
        showSnackbar("Trainer name is required", "error");
        return;
    }

    const requestData = {
        class_id: parseInt(selectedData?.id),
        user_id: parseInt(userId),
        trainer_id: trainerName?.id
    };

    try {
        const response = await fetch(
            `${API_ENDPOINT}class/updatingtrainer/${selectedData?.id}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestData),
            }
        );

        if (response.ok) {
            showSnackbar("Trainer updated Successfully");
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        } else if (response.status === 400) {
          showSnackbar("Class not found or inactive", "error");
      } else if (response.status === 409) {
          showSnackbar("Conflict: Trainer schedule overlaps", "error");
      } else if (response.status === 405) {
        showSnackbar("Branch Conflict: Class branch conflicting with trainer branch", "error");
    } else if (response.status === 403) {
        showSnackbar("Trainer not found or inactive", "error");
    }  else {
          showSnackbar("Error updating trainer", "error");
      }
       
    } catch (error) {
        console.error("Network or Server Error:", error);
        showSnackbar("Error in updating data", "error");
    }
};



  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>Select Trainer</Typography>
          <Autocomplete
            size="small"
            value={trainerName}
            onChange={handleTrainerNameChange}
            options={alltrainers}
            getOptionLabel={(option) =>
              `${option.first_name ? option.first_name : ""} ${
                option.last_name ? option.last_name : ""
              }`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: "15px" }, // Add borderRadius here
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} textAlign="end">
          <Button
            onClick={handlesubmit}
            variant="contained"
            color="secondary"
            size="small"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
