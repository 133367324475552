import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ReportCards from "./ReportCards";
import { Country, State, City } from "country-state-city";
import DownloadIcon from "@mui/icons-material/Download";
import { useGetTokenOrLogout } from "../../utils/token";
import { useLocation, useNavigate } from "react-router-dom";
import { useDateUtils } from "../../utils/formatTime";
import HeadingCard from "../../newTestPrep/newComponent/NewCardsComp/HeadingCard";
import CustomTextFieldComp from "../../newTestPrep/newComponent/customTextFieldComp";
import ApplyFilterButton from "../../newTestPrep/newComponent/NewButtonComp/ApplyFilterButton";
import FilterComp from "../../newTestPrep/newComponent/iconsComp/FilterComp";
import FilterButtonComponent from "../../newTestPrep/newComponent/NewButtonComp/FilterButtonComponent";
import MainCard from "../../newTestPrep/components/ui-component/card/MainCard";
import ResetFilterButton from "../../newTestPrep/newComponent/NewButtonComp/ResetFilterButton";
import CustomAutocompleteComp from "../../newTestPrep/newComponent/AutocompleteComp";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function Report() {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const { currentMonthName, lastMonthName, weekRange } = useDateUtils();
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const stateData = location?.state?.stateData;
  console.log("stateData", stateData);

  const [selectedOption, setSelectedOption] = useState(
    stateData?.selectedFilter || 'today'
  );

  const [isCenterLoading, setIsCenterLoading] = useState(false);
  const [centerRevenueData, setCenterRevenueData] = useState([]);
  const [fromDate, setFromDate] = useState(stateData?.fromDate || null);
  const [toDate, setToDate] = useState(stateData?.toDate || null);

  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState(stateData?.selctedBranch || []);

  const [errors, setErrors] = useState({ fromDate: false });

  const handleClick = (e) => {
 
    setFromDate(null);
    setToDate(null);
    setSelectedOption(e.target.value);
    
  };

  console.log("selectedOption", selectedOption);

  const [showFilter, setShowFilter] = useState(true);
  const [selectedState, setSelectedState] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedstateid, setselectedStateid] = useState([]);
  const [selectedcity, setselectedcity] = useState([]);
  const [selectedcityid, setselectedcityid] = useState([]);

  useEffect(() => {
    fetch(`${API_ENDPOINT}oldstate`)
      .then((response) => response.json())
      .then((data) => {
        const filteredState = data.filter((state) => state.country_id === 101);
        setStates(filteredState);
      })
      .catch((error) => {
        console.error("Error fetching state:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedstateid) {
      fetch(`${API_ENDPOINT}oldcity`)
        .then((response) => response.json())
        .then((data) => {
          const filteredCity = data.filter((city) =>
            selectedstateid.includes(city.state)
          );
          setCities(filteredCity);
          //console.log(filteredCity)
        })
        .catch((error) => {
          console.error("Error fetching city:", error);
        });
    }
  }, [selectedstateid]);

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setSelectedState(selectedState);
    const selectedStateIds = states
      .filter((state) => selectedState.includes(state.state_name))
      .map((state) => state.id);
    //console.log(selectedStateIds)
    setselectedStateid(selectedStateIds);
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const filteredData = centerRevenueData.filter((card) => {
    if (selectedState.length > 0 && !selectedstateid.includes(card.state_id)) {
      return false;
    }
    if (selectedcity.length > 0 && !selectedcityid.includes(card.city_id)) {
      return false;
    }
    return true;
  });

  console.log("filteredData", filteredData);

  const handleFromdateChange = (e) => {
    setFromDate(e.target.value);
    setErrors({ ...errors, fromDate: !e.target.value });
  };
  const handleTodateChange = (e) => {
    setToDate(e.target.value);
    setErrors({ ...errors, toDate: !e.target.value });
  };

  const getRevenueForCenters = async () => {
    if (
      (selectedOption === "custom" && !fromDate) ||
      (selectedOption === "custom" && !toDate)
    ) {
      setErrors({ fromDate: !fromDate, toDate: !toDate });
      return;
    }
    setIsCenterLoading(true);

    const token = getTokenOrLogout();
    console.log("token", token);
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),
      filter: selectedOption,
      to_date: toDate,
      from_date: fromDate,
      branch_id: branch.map((i) => i.id),
    };

    try {
      setIsCenterLoading(true);
      const response = await fetch(`${API_ENDPOINT}route/getcenterreport`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.status === 200) {
        const data = await response.json();
        // Handle successful response
        setCenterRevenueData(data);
        setIsCenterLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/page500");
    }
  };

  useEffect(() => {
    getRevenueForCenters();
  }, []);

  const handleViewreport = (id) => {
    if (
      (selectedOption === "custom" && !fromDate) ||
      (selectedOption === "custom" && !toDate)
    ) {
      setErrors({ fromDate: !fromDate, toDate: !toDate });
      return;
    }
    const url = `/view-center-report`;
    window.open(url, "_blank");
    localStorage.setItem(
      "details",
      JSON.stringify({
        filteredData,
        selectedState,
        selectedOption,
        fromDate,
        toDate,
      })
    );
  };

  // Filter
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          setBranchlist(data);
          setBranch(data);
          if(stateData?.selctedBranch.length>0 ) {
            const branchIds = stateData?.selctedBranch?.map((i) => i.id);
            const FilteredBranch = data.filter((i) => branchIds.includes(i.id));
            console.log("FilteredBranch", FilteredBranch);
      
            setBranch(FilteredBranch);
          }
        
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allbranchList = [{ branch_name: "Select All" }, ...branchlist];
  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

 // data which have to pass with state
 const passedStateData = {
  selectedFilter: selectedOption,
  fromDate: fromDate,
  toDate: toDate,

};

  return (
    <Box
      className="box"
      sx={{ height: "100%", width: "100%", position: "relative", color: "" }}
    >
      {/* Gradient Background */}
      <Box
        className="rectangle"
        sx={{
          background:
            "linear-gradient(180deg, rgb(255, 246.42, 240.12) 0%, rgba(255, 246.42, 240.12, 0) 100%)",

          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: -1,
        }}
      />

      <Box style={{ textAlign: "left" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={2} md={3} sm={2}>
            <HeadingCard label="Centers Report" />
          </Grid>

          <Grid item xs={12} lg={10} md={9} sm={10}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: 2,
              }}
            >
              <FilterButtonComponent
                open={showFilter}
                showendIcon={true}
                onClick={toggleFilter}
              />

              {userRole === "center_admin" && (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={{
                    marginLeft: "10px",

                    borderRadius: "10px",
                  }}
                  startIcon={<DownloadIcon />}
                  onClick={handleViewreport}
                >
                  Download Report
                </Button>
              )}
            </Box>
          </Grid>
          {showFilter && (
            <Grid item xs={12}>
              <MainCard>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={3}>
                    <Typography variant="h6">Select Branch</Typography>
                    <CustomAutocompleteComp
                      size="small"
                      value={branch}
                      multiple
                      onChange={handelbranchchange}
                      options={allbranchList}
                      getOptionLabel={(option) => option.branch_name}
                      tooltipContent={(option) => option.branch_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "15px" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <Typography variant="h6">Select Date</Typography>
                    <Select
                      size="normal"
                      fullWidth
                      value={selectedOption}
                      style={{ borderRadius: "10px" }}
                      onChange={handleClick}
                    >
                      <MenuItem value="today">Today</MenuItem>
                      <MenuItem value="currentweek">
                        This Week ({weekRange})
                      </MenuItem>
                      <MenuItem value="currentmonth">
                        This Month({currentMonthName})
                      </MenuItem>

                      <MenuItem value="pastmonth">
                        Last Month({lastMonthName})
                      </MenuItem>
                      <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                  </Grid>

                  {selectedOption === "custom" && (
                    <>
                      <Grid item xs={12} sm={4} md={2}>
                        <Typography variant="h6">From Date</Typography>
                        <CustomTextFieldComp
                          size="small"
                          fullWidth
                          type="date"
                          value={fromDate}
                          InputProps={{
                            style: { borderRadius: "8px" },
                            inputProps: {
                              max: new Date().toISOString().split("T")[0],
                            },
                          }}
                          onChange={handleFromdateChange}
                        />
                        {errors.fromDate && (
                          <Typography color="error" variant="h6">
                            From date required
                          </Typography>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={4} md={2}>
                        <Typography variant="h6">To Date</Typography>
                        <CustomTextFieldComp
                          size="small"
                          fullWidth
                          type="date"
                          value={toDate}
                          InputProps={{
                            style: { borderRadius: "8px" },
                            inputProps: {
                              max: new Date().toISOString().split("T")[0],
                            },
                          }}
                          onChange={handleTodateChange}
                        />
                        {errors.toDate && (
                          <Typography color="error" variant="h6">
                            To date required
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid item md={2}>
                    <Typography visibility="hidden">Spacing Purpose</Typography>
                    <Grid container gap={1}>
                      <ApplyFilterButton onClick={getRevenueForCenters} />
                      {/* <ResetFilterButton onClick={handleResetFilter}/> */}
                    </Grid>
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>
          )}

          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography
              variant="h5"
              sx={{
                color: "#858585",
              }}
            >
              {selectedState.length > 0
                ? `Showing All ${filteredData.length} SIEC Institutes in ${selectedState}`
                : `Showing All ${filteredData.length} SIEC Institutes`}
            </Typography>
          </Grid>

          <Grid xs={12} lg={12} marginTop={2} sx={{ marginLeft: "15px" }}>
            <Backdrop open={isCenterLoading} style={{ zIndex: 9999 }}>
              <Typography
                variant="h4"
                color="error"
                fontWeight={800}
                marginRight={2}
              >
                Fetching
              </Typography>
              <CircularProgress color="primary" />
            </Backdrop>
            <ReportCards
              filteredData={filteredData}
              selectedState={selectedstateid}
              selectedcity={selectedcityid}
              passedStateData={passedStateData}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
