import { Button, Grid, TextField, Typography } from "@mui/material";
import JoditEditor from "jodit-react";
import React, { useRef, useState } from "react";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import SubmitButton from "../../newComponent/NewButtonComp/SubmitButton";

const PATH_URL = process.env.REACT_APP_API_UPLOADS;

export default function AddTemplateForm({ onClose }) {
  const editorRef = useRef(null);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const [name, setName] = useState(null);
  const [subject, setSubject] = useState(null);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [errors, setErrors] = useState({
    name: false,
    subject: false,
    content: false,
  });
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  /*const config = {
    readonly: false,
    toolbarAdaptive: false,
    buttons: [
      "undo",
      "redo",
      "font",
      "fontsize",
      "bold",
      "italic",
      "underline",
      "eraser",
      "brush",
      "link",
      "ul",
      "ol",
      "indent",
      "outdent",
      "left",
      "fullsize",
      "source",
    ],
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };*/

  const config = {
    readonly: false,
    toolbarAdaptive: false,
    buttons: [
      "undo",
      "redo",
      "font",
      "fontsize",
      "bold",
      "italic",
      "underline",
      "eraser",
      "brush",
      "link",
      "ul",
      "ol",
      "indent",
      "outdent",
      "left",
      "fullsize",
      "source",
      "image",
    ],
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    
   
  language: 'en', 
  
  // Image upload configuration
  uploader: {
    url: `${API_ENDPOINT}emailtemplate/uploadimage`, 
    insertImageAsBase64URI: true, 
    format: 'json', 
    method: "POST", 
    isSuccess: function (resp) {
      console.log("Uploader Response:", resp);
      return !resp.error;
    },
    process: function (resp) {
      console.log("Processing Response:", resp);
      if (resp && resp.file) {
        const fileUrl = `${PATH_URL}${resp.file}`; 
        console.log("url:", fileUrl);

        if (editorRef.current && editorRef.current.editor) {
          editorRef.current.editor.selection.insertImage(fileUrl); // Ensure correct method
        }

        return {
          files: [resp.file],
          path: fileUrl, 
          baseurl: PATH_URL, 
          error: resp.error,
          msg: resp.message 
        };
      } else {
        return {
          files: []
        };    
      };
    },
    error: function (e) {
      console.error("Upload error", e); // Handle upload errors
    },
  },
  };
  

  const handleNamechange = (e) => {
    setName(e.target.value);
    setErrors({ ...errors, name: !e.target.value });
  };
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setErrors({ ...errors, subject: !e.target.value });
  };
  const handleContentChange = (newContent) => {
    setContent(newContent);
    setErrors({ ...errors, content: !newContent });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  /*const handleSubmit = () => {
    if (!name || !subject || !content) {
      setErrors({
        name: !name,
        subject: !subject,
        content: !content,
      });
      showSnackbar("Please fill out all fields", "error");
      return;
    }
    const formdata = {
      user_id: parseInt(userId),

      template_title: name || null,
      email_subject: subject || null,
      description: content || null,
    };

    fetch(`${API_ENDPOINT}emailtemplate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formdata),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Template Created Successfully.");
          setTimeout(() => {
          window.location.reload()
          }, 1500);
        } else {
          console.error("Error: Unknown Error");
          showSnackbar("Error: Unknown Error", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showSnackbar("Error: Unknown Error", "error");
      });
  };*/

  const handleSubmit = () => {
    if (!name || !subject || !content) {
      setErrors({
        name: !name,
        subject: !subject,
        content: !content,
      });
      showSnackbar("Please fill out all fields", "error");
      return;
    }

    const formdata = new FormData();
    formdata.append("user_id", parseInt(userId));
    formdata.append("template_title", name || null);
    formdata.append("email_subject", subject || null);
    formdata.append("description", content || null);

    // Append files to formdata
    selectedFiles.forEach((file) => {
      formdata.append("attachments", file);
    });

    fetch(`${API_ENDPOINT}emailtemplate`, {
      method: "POST",
      body: formdata,
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Template Created Successfully.");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          console.error("Error: Unknown Error");
          showSnackbar("Error: Unknown Error", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showSnackbar("Error: Unknown Error", "error");
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={12} md={12}>
          <Typography variant="h5">
            {" "}
            Template Name <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
          size="small"
            fullWidth
            value={name}
            onChange={handleNamechange}
            InputProps={{
              style: { borderRadius: "10px" },
            }}
          />
          {errors.name && (
            <Typography color="error" variant="caption">
              Template name is required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={12} md={12}>
          <Typography variant="h5">
            {" "}
            Template Subject <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
          size="small"
            fullWidth
            value={subject}
            onChange={handleSubjectChange}
            InputProps={{
              style: { borderRadius: "10px" },
            }}
          />
          {errors.subject && (
            <Typography color="error" variant="caption">
              Template subject is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} lg={12} sm={12} md={12}>
          <Typography variant="h5">
            {" "}
            Template Body <span style={{ color: "red" }}>*</span>
          </Typography>
          <JoditEditor
            fullWidth
            ref={editorRef}
            value={content}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={handleContentChange} // preferred to use only this option to update the content for performance reasons
            onChange={(newContent) => {}}
          />
          {errors.content && (
            <Typography color="error" variant="caption">
              Template Body is required
            </Typography>
          )}
        </Grid>

        {/* Display selected files */}
        <Grid item xs={12}>
          <Typography variant="h5">
            Selected Files:
          </Typography>
          {selectedFiles.length > 0 ? (
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          ) : (
            <Typography variant="caption">No files selected</Typography>
          )}
        </Grid>

        {/* File Uploader */}
    <Grid item xs={12} sm={12} lg={12} md={12}>
      <Typography variant="h5">
        Attach Files
      </Typography>
      <input
        type="file"
        multiple // Optional: to allow multiple files
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="file-upload"
      />
      <label htmlFor="file-upload">
        <Button
          variant="outlined"
          component="span"
          color="primary"
          style={{ borderRadius: "15px", fontWeight: 600 }}
        >
          Upload Files
        </Button>
      </label>
    </Grid>

        <Grid item xs={12} lg={12} sm={12} md={12} textAlign="end">
          <SubmitButton
        
            onClick={handleSubmit}
          />
    
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
