import React from 'react'
import FilterIcon from '../../../components/IconComp/FilterIcon'
import { Button } from '@mui/material'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
export default function FilterButtonComponent({text, onClick , open,showendIcon}) {
  return (
  <>
<Button
                  variant="contained"
                  size="small"
                  style={{
                    // fontSize: "9px",
                    fontWeight: 400,
                    fontFamily: "Roboto",
                    color: "#1F1F1F",
                    background: "white",

                    zIndex: 1,
                    borderRadius: "6px",
                  }}
                  startIcon={<FilterIcon/>}
                  onClick={onClick}
                
                  endIcon={showendIcon && (open ? (
                    <KeyboardArrowUpIcon fontSize="small" />
                  ) : (
                    <KeyboardArrowDownIcon fontSize="small" />
                  ))}
                >
             {text ?text:"Filter"}
                </Button>

  </>
  )
}
