import { Box, Typography } from "@mui/material";
import React from "react";

export default function AllBranches({ selectedbranhes }) {
  return (
    // <Typography variant='h5'>{selectedbranhes.join(' , ')}</Typography>

    <Box sx={{ height: selectedbranhes.length > 9 ? "300px" : "auto" }}>
      <Typography variant="h5">
        <ul>
          {selectedbranhes.map((branch, index) => (
            <li key={index} style={{ padding: "5px" }}>
              {branch}
            </li>
          ))}
        </ul>
      </Typography>
    </Box>
  );
}
