import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Paper, Chip, CircularProgress, Backdrop, Typography, Select, MenuItem, TextField, Button, ListItemText, Checkbox, FormControl, Grid, Autocomplete } from '@mui/material';
import DataTable from '../../components/table/DataTable';
import CustomizedTables from '../../components/table/TasksTable';
import { useNavigate } from 'react-router-dom';
import { useGetTokenOrLogout } from '../../utils/token';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;


function TaskReportTabs({onClose}) {

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const userId = localStorage.getItem('userId');
  const userRole = localStorage.getItem('userRole');
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  
  const [reportType, setReportType] = useState('today');
  const [filterType, setFilterType] = useState('today');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [todayTaskReportData, setTodayTaskReportData] = useState([]);
  const [monthlyTaskReportData, setMonthlyTaskReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [createdby, setCreatedby] = useState(null);
  const [createdbylist, setCreatedbylist] = useState([]);

  const [filterData, setFilterData] = useState({
    filterType: 'today',
    fromDate: null,
    toDate: null,
    filterFor: null
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setCreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const handelcretedby = (event,value) => {
   setCreatedby(value)
  };
  const fetchTodayTaskReport = async () => {
    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json" // Make sure to keep the content type
    };

    // Simulate API fetch or set your actual data
   
      const apiUrl = `${API_ENDPOINT}route/todaytask`;
  
      try {
       // const response = await fetch(apiUrl);
       const response = await fetch(apiUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          user_id: parseInt(userId),
          filter: filterType,
          to_date: fromDate,
          from_date: toDate,
          filter_for: createdby?.id ?createdby?.id :null
        }),
      })
    
        if (response.ok) {
          const data = await response.json();
    
         
          setTodayTaskReportData(data);
          setFilterData({
            filterType: filterType,
            fromDate: fromDate,
            toDate: toDate,
            filterFor: createdby?.id ?createdby?.id :null,
  
          });
          setIsLoading(false);
        }
    
        else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
       // return data;
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
        navigate('/page404')
        throw new Error('Failed to fetch data');
      }
    setIsLoading(false);
  };
  

  // Simulate fetching data for today's and monthly task reports
  useEffect(() => {
    fetchTodayTaskReport();
  }, []);

  const handleChipClick = (type) => {
    setReportType(type);
  };

  const handleClose = () => {
    onClose()
  }

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleApplyFilter = () => {
    fetchTodayTaskReport();
  };

  

  return (
    <div>
     {/*} <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>Fetching</Typography>
        <CircularProgress color="primary" />
  </Backdrop>*/}
      <Box display="flex" justifyContent="center" mt={2}>
      {/*}  <Chip
          label="Today Task Report"
          color={reportType === 'today' ? 'primary' : 'default'}
          onClick={() => handleChipClick('today')}
          sx={{ marginRight: 2 }}
        />
        <Chip
          label="Monthly Task Report"
          color={reportType === 'monthly' ? 'primary' : 'default'}
          onClick={() => handleChipClick('monthly')}
  />*/}


            
        


        <Typography variant="h4" sx={{ marginRight: 2 }}>
          Filter:
        </Typography>
        <Select value={filterType} onChange={handleFilterChange} sx={{ marginLeft: 2, width: '150px' }}>
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="1week">Last 7 Days</MenuItem>
          <MenuItem value="1month">Last 30 days</MenuItem>
          <MenuItem value="custom">Custom</MenuItem>
        </Select>
        {filterType === 'custom' && (
          <>
            <TextField
              type="date"
              label="From"
              value={fromDate}
              onChange={handleFromDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ width: '150px', marginRight: 2, marginLeft: 2 }}
             // inputProps={{ max: new Date().toISOString().split('T')[0] }}
            />
            <TextField
              type="date"
              label="To"
              value={toDate}
              onChange={handleToDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ width: '150px', marginRight: 2, marginLeft: 2 }}
             // inputProps={{ max: new Date().toISOString().split('T')[0] }}
            />
          </>
        )}

        {(userRole === 'center_admin' || userRole === 'data_entry') && (
          <FormControl >
          <Typography sx={{ fontWeight: 600 }}>Select Task Owner </Typography>
          {/* <Select
         
            value={createdby}
            onChange={handelcretedby}
            fullWidth
             MenuProps={MenuProps}
            style={{ borderRadius: "15px" ,width: '150px'}}
          
          >
            
            {createdbylist.map((name) => (
              <MenuItem key={name.id} value={name.id}>
               {name.first_name} {name.last_name}
              </MenuItem>
            ))}
          </Select> */}

<Autocomplete

                     size="small"
                     value={createdby}
                     onChange={handelcretedby}
                    options={createdbylist}
                    getOptionLabel={(option) => `${option.first_name?option.first_name:""} ${option.last_name?option.last_name:""}`}
                    renderInput={(params) => <TextField {...params}    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: '15px', width:"200px" }, // Add borderRadius here
                    }} />}
                  />
        </FormControl>
        )}

      
         <Button variant="contained" color="secondary" onClick={handleApplyFilter} sx={{ marginLeft: 2 }}>
          Apply Filter
         </Button>
      </Box>

      <Box mt={2}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {reportType === 'today' ? (
              <CustomizedTables data={todayTaskReportData} reportType={reportType} onClose={handleClose} filterData={filterData}  />
            ) : (
              <CustomizedTables data={monthlyTaskReportData} reportType={reportType}/>
            )}
          </>
        )}
      </Box>
    </div>
  );
}

export default TaskReportTabs;
