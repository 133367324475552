import { Button } from '@mui/material'
import React from 'react'
import DownloadIcon from '@mui/icons-material/Download';

export default function DownLoadButton({onClick,}) {
  return (
    <Button
    color="secondary"
    size="small"
    variant="contained"
    style={{ borderRadius: "25px" }}
    startIcon={<DownloadIcon />}
     onClick={onClick}
  >
    Download Report
  </Button>
  )
}
