import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
import { fDate, fDateTime } from "../../../utils/formatTime";
import DemoCancellationDialog from "./DemoCancellationDialog";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import EvaluationPDF from "../demos/EvaluationPDF";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const getDragWrapper = (theme, radius) => {
  const bgcolor =
    theme.palette.mode === "dark"
      ? theme.palette.background.paper + 90
      : theme.palette.grey[50];
  return {
    userSelect: "none",
    margin: `0 0 ${8}px 0`,
    padding: 16,
    border: "1px solid",
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.background.default
        : theme.palette.primary[200] + 75,
    backgroundColor: bgcolor,
    borderRadius: 20,
    // ...draggableStyle
  };
};

function DemoHistoryItem({ item, onCancelClick }) {
  const userId = localStorage.getItem("userId");
  console.log(item);
  const theme = useTheme();

  const [openCancellationDialog, setOpenCancellationDialog] = useState(false);
  const [openEvaluationPdfDialog, setOpenEvaluationPdfDialog] = useState(false);
  const [evaluationData, setEvaluationData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOpenCancellationDialog = () => {
    setOpenCancellationDialog(true);
  };

  const handleCloseCancellationDialog = () => {
    setOpenCancellationDialog(false);
  };

  const handleConfirmCancellation = (reason) => {
    // Make API call to update demo status here
    updateDemoStatus(item.id, "cancelled", reason);
    console.log(`Cancellation Reason: ${reason}`);
    handleCloseCancellationDialog();
  };

  const updateDemoStatus = async (demoId, newStatus, cancellationReason) => {
    try {
      const response = await fetch(`${API_ENDPOINT}demo/${demoId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: newStatus,
          cancel_reason: cancellationReason,
          user_id: userId,
        }),
      });

      if (response.ok) {
        showSnackbar("Demo cancelled successfully", "success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        console.error("Failed to update demo status");
        showSnackbar("Failed to update demo status", "error");
      }
    } catch (error) {
      console.error("Error updating demo status:", error);
      showSnackbar("Failed to update demo status", "error");
    }
  };

  const handleViewEvaluation = async () => {
    try {
      // Fetch evaluation data
      const response = await fetch(
        `${API_ENDPOINT}studentstatus/${item.evaluation_id}`
      );
      if (response.ok) {
        const data = await response.json();
        setEvaluationData(data);
        setOpenEvaluationPdfDialog(true);
      } else {
        console.error("Failed to fetch evaluation data");
        showSnackbar("Failed to fetch evaluation data", "error");
      }
    } catch (error) {
      console.error("Error fetching evaluation data:", error);
      showSnackbar("Failed to fetch evaluation data", "error");
    }
  };

  return (
    <>
      <div style={getDragWrapper(theme)}>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box alignItems="center">
            <Typography
              // onClick={() => handlerDetails(item.id)}
              variant="h5"
              sx={{
                display: "inline-block",
                // width: 'calc(100% - 34px)',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
            >
              {item.test_type_id?.name} Demo with {item.trainer_id?.first_name}
            </Typography>
          </Box>
          <Box alignItems="center">
            <Typography
              //onClick={() => handlerDetails(item.id)}
              variant="h5"
              sx={{
                display: "inline-block",
                // width: 'calc(100% - 34px)',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
            >
              {item.delivery_mode} {item?.branch_id?.branch_name}
            </Typography>
          </Box>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Tooltip title="By">
              <MenuBookTwoToneIcon
                color="secondary"
                sx={{ fontSize: "0.875rem", color: "#00b13f" }}
              />
            </Tooltip>
            <Tooltip title="Demo">
              <Typography
                variant="caption"
                color="secondary"
                underline="hover"
                sx={{ cursor: "pointer", pt: 0.5, color: "#00b13f" }}
              >
                {fDate(item.available_demo_date)} {item.slot}
              </Typography>
            </Tooltip>
          </Stack>

          <Box alignItems="center">
            <Stack direction="column" alignItems="center" spacing={0.5}>
              {item?.note && (
                <Typography variant="h6">Note: {item?.note}</Typography>
              )}
              {item?.cancel_reason && (
                <Typography variant="h6" color="error">
                  Cancellation Reason: {item.cancel_reason}
                </Typography>
              )}
              {(item?.reschedule_reason || item?.reschedule_reason_id) && (
                <Typography variant="h6">
                  Reschedule Reason:{" "}
                  {item.reschedule_reason ||
                    item?.reschedule_reason_id?.reschedule_reason}
                </Typography>
              )}
            </Stack>
          </Box>

          <Stack direction="row" spacing={1} alignItems="center">
            <Chip
              color="error"
              size="small"
              variant="contained"
              label={item?.status}
            />

            {["pending", "confirmed"].includes(item?.status) && (
              <>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={handleOpenCancellationDialog}
                >
                  Cancel
                </Button>

                <DemoCancellationDialog
                  open={openCancellationDialog}
                  onClose={handleCloseCancellationDialog}
                  onConfirmCancellation={handleConfirmCancellation}
                  studentName={item.student_name}
                />
              </>
            )}

            {item?.status === "completed" && (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleViewEvaluation}
              >
                View Evaluation
              </Button>
            )}
          </Stack>
        </Stack>
      </div>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {openEvaluationPdfDialog && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={openEvaluationPdfDialog}
          onClose={() => setOpenEvaluationPdfDialog(false)}
        >
          <DialogTitle variant="h4">Evaluation PDF</DialogTitle>
          <DialogContent>
            {/* Render the EvaluationPDF component */}
            <EvaluationPDF
              demoId={item.id}
              studentName={item?.enquiry_name}
              trainerName={item?.trainer_id.first_name}
              evaluationData={evaluationData}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenEvaluationPdfDialog(false)}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default DemoHistoryItem;
