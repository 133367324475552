import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  MenuItem,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import CustomSnackbar from "../../../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function PlanStatusForm({ rowData, open, handleClose, content, title }) {
    const userId = localStorage.getItem("userId");
    const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [reasonsForDrop, setReasonsForDrop] = useState([]);
  const { control, handleSubmit, watch, formState: { errors }, setValue, reset } = useForm();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };

  const handleRadioChange = (event) => {
    setSelectedType(event.target.value);
    setValue("newDueDate", null);
    setValue("dropReason", null);
  };

   useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_ENDPOINT}reasonfordrop`);
          if (response.ok) {
            const data = await response.json();
            console.log(data)
            setReasonsForDrop(data)
          } else {
            throw new Error(`Request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, []);

  const onSubmit = (data) => {
    setIsLoading(true);

    const response = {
        payment_id: rowData.id,
        plan_status: selectedType,
        new_due_date: data.newDueDate,
        reason_for_drop: data.dropReason,
        note: data.note,
        user_id: userId
    }

    fetch(`${API_ENDPOINT}pendingpayment/updatependingpaymentstatus`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(response)
    })
    .then((response) => {
        if(response.ok){
            setIsLoading(false);
            handleClose();
          showSnackbar("Pending payment status updated successfully", "success")
          reset()
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }else if(response.status == 400){
            setIsLoading(false);
            showSnackbar("Please select a pending payment plan status", "error")
        } else if(response.status == 401){
            setIsLoading(false);
            showSnackbar("Unauthorized user", "error")
            navigate("/page401")
        } else if(response.status == 403){
            setIsLoading(false);
            showSnackbar("No pending payment found", "error")
        } else if(response.status == 405){
            setIsLoading(false);
            showSnackbar("Payment ID cannot be null", "error")
        } else {
            setIsLoading(false);
            showSnackbar("Error updating pending payment status", "error")
        }
    })
    .catch((error) => {
        console.error("Error updating pending payment status:", error);
        showSnackbar("Server error", "error")
        setIsLoading(false);
      });
    
  };

  return (
    <>
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      {/* Loading Backdrop */}
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="secondary" fontWeight={800} marginRight={2}>
          Submitting Plan Details ....
        </Typography>
        <CircularProgress color="secondary" />
      </Backdrop>

      {/* Dialog Title */}
      <DialogTitle
        sx={{
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "600",
          paddingRight: "40px",
          fontFamily: "Poppins",
        }}
      >
        {title}
        <IconButton
          aria-label="close"
          style={{ position: "absolute", right: "8px", top: 0 }}
          onClick={handleClose}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </DialogTitle>

      {/* Form */}
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {/* Plan Type Selection */}
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "20px", fontWeight: 800, fontFamily: "Poppins" }}>
                Plan Type
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup row value={selectedType} onChange={handleRadioChange} spacing={2}>
                  <FormControlLabel value="newplan" control={<Radio />} label="New Follow Up Date Required" />
                  <FormControlLabel value="dropped" control={<Radio />} label="Drop Plan" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Conditional Fields */}
            {selectedType === "newplan" && (
              <>
                {/* New Due Date */}
                <Grid item xs={12}>
                  <Controller
                    name="newDueDate"
                    control={control}
                    rules={{ required: "New Due Date is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="date"
                        label="New Due Date"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            style: { borderRadius: "15px" },
                            inputProps: {
                              min: new Date().toISOString().split("T")[0],
                            },
                          }}
                        error={!!errors.newDueDate}
                        helperText={errors.newDueDate?.message}
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            {selectedType === "dropped" && (
            <>
                {/* Reason for Dropping Plan */}
                <Grid item xs={12}>
                <Controller
                    name="dropReason"
                    control={control}
                    rules={{ required: "Reason is required" }}
                    render={({ field }) => (
                    <TextField
                        {...field}
                        select
                        fullWidth
                        label="Reason for Dropping Plan"
                        InputProps={{
                        style: { borderRadius: "15px" },
                        }}
                        error={!!errors.dropReason}
                        helperText={errors.dropReason?.message}
                    >
                        {reasonsForDrop.map((reason) => {
                        // Add the return statement here
                        return (
                            <MenuItem key={reason.id} value={reason.id}>
                            {reason.reason_for_drop}
                            </MenuItem>
                        );
                        })}
                    </TextField>
                    )}
                />
                </Grid>
            </>
            )}


            <Grid item xs={12}>
                  <Controller
                    name="note"
                    control={control}
                    rules={{ required: "Note is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        multiline
                        rows={3}
                        label="Note"
                        InputProps={{
                            style: { borderRadius: "15px" },
                          }}
                        error={!!errors.note}
                        helperText={errors.note?.message}
                      />
                    )}
                  />
                </Grid>

            {/* Submit Button */}
            <Grid item xs={12} display="flex" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                    !selectedType ||
                  (selectedType === "newPlan" && (!watch("newDueDate") || !watch("note"))) ||
                  (selectedType === "dropped" && (!watch("dropReason") || !watch("note")))
                }
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
    <CustomSnackbar
    open={snackbarOpen}
    onClose={() => setSnackbarOpen(false)}
    message={snackbarMessage}
    severity={snackbarSeverity}
  />
  </>
  );
}

export default PlanStatusForm;
