import React, { useState} from 'react'
import { Tabs, Tab, Typography, Box, Grid, Button } from '@mui/material';
//import MainClass from '../../pages/mainClass/MainClass';
//import TrainerClasswork from '../trainerClasswork/TrainerClasswork';
//import ListPeoplePage from '../ListPeoplePage/ListPeoplePage';
import { useLocation } from 'react-router-dom';
import MainClass from '../../views/trainer/classes/mainClass/MainClass';
import StudentDataGrid from '../../views/trainer/classes/StudentDataGrid';

function TrainerTab() {
    const [currentTab, setCurrentTab] = useState(0);
    const location = useLocation()
    const classData = location.state?.classData;
    const userRole = localStorage.getItem('userRole')
  
    

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const tabContent = [
    {
      label: 'Stream',
      content: (<MainClass classData={classData} />)
    },
    {
      label: 'ClassWork',
      //content: (<TrainerClasswork classData={classData}/>)
    },
    {
      label: 'Students',
      content: (<StudentDataGrid />)
    }
  ];
  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
    <Tabs value={currentTab} onChange={handleTabChange} centered>
      {tabContent.map((tab, index) => (
        <Tab key={index} label={tab.label} />
      ))}
    </Tabs>
    <Box sx={{ p: 3 }}>
    {userRole === 'admin' && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              // Handle the edit class details action here
              // Redirect or open a dialog for editing class details
            }}
          >
            Edit Class Details
          </Button>
        )}
      {tabContent.map((tab, index) => (
        <Typography
          key={index}
          component="div"
          role="tabpanel"
          hidden={currentTab !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
        >
          {currentTab === index && <Box>{tab.content}</Box>}
        </Typography>
      ))}
    </Box>
  </Box>
  )
}

export default TrainerTab