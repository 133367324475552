import { Button } from "@mui/material";
import React from "react";

export default function OutlinedButtonComp({
  text,
  label, // Button text
  value,
  onClick, // Click handler
  variant = "outlined", // Default to "contained", can be overridden
 color,
 fontWeight,
 borderRadius,
  startIcon = null, // Optional start icon
  endIcon = null, // Optional end icon
  disabled = false, // Disable state (default to false)
  sx = {}, // Responsive styles using the `sx` prop
  borderColor,
  ...props
}) {
  return (
    <>
      <Button
        variant={variant} // Button variant (contained, outlined, etc.)
        size="medium"
       
        startIcon={startIcon} // Optional start icon
        endIcon={endIcon} // Optional end icon
        onClick={onClick} // Button click handler
        disabled={disabled} // Disable the button when true
        sx={sx} // Additional responsive styles
        style={{borderRadius:borderRadius,color:color, borderColor:borderColor, fontWeight:fontWeight}}
        {...props} // Spread additional props (for example, "id", "type")
      >
        {label} {value}
      </Button>
    </>
  );
}
