import {
  Box,
  Button,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import StyledTableContainer from "../../../newTestPrep/newComponent/TableContainer";

export default function AdminAllMonthDetails({ data, choosenYear }) {
 

  return (
    <>
      <Typography
        textAlign="center"
        variant="h4"
       
      >
        Enrollment & Revenue Details of Year {choosenYear}
      </Typography>

      <Box mt={2}>
        <StyledTableContainer component={Paper}>
          <Table style={{ background: "#F9F9F9", borderRadius: "14px" }}>
            <TableHead>
              <TableRow>
                <TableCell>Month Name</TableCell>
                <TableCell>No. of Enrollment</TableCell>
                <TableCell>Online Enrollment</TableCell>
                <TableCell>Offline Enrollment</TableCell>
                <TableCell>Total Revenue</TableCell>
                <TableCell>Collected</TableCell>
                <TableCell>Pending</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length === 0 ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "11px",
                      color: "#000000",
                    }}
                  >
                    No data available
                  </Typography>
                </Box>
              ) : (
                data.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      width: "6%",
                    }}
                  >
                    <TableCell>{row?.month_short_name}</TableCell>
                    <TableCell>{row?.total_enrollment}</TableCell>
                    <TableCell>{row?.online_enrollment}</TableCell>
                    <TableCell>{row?.offline_enrollment}</TableCell>
                    <TableCell>{row?.total_revenue}/-</TableCell>
                    <TableCell>{row?.collected_revenue}/-</TableCell>
                    <TableCell>{row?.pending_revenue}/-</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </>
  );
}
