import { Box, Card, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Content from "../Components/Content";
import FilterButtonComp from "../Components/FilterButtonComp";
import RevenueCards from "../CenterAdminDashboard/NewRevenueCards";
import DisplayCurrentDateTime from "../Components/DisplayCurrentDateTime";
import UpcommingEvents from "../Components/UpcommingEvents";
import NewCardcomp from "../../../components/card/NewCardcomp";
import LeadCalandar from "../../LeadConversation/LeadCalandar";
import MonthlyRevenuPichart from "../CenterAdminDashboard/MonthlyRevenuPichart";
import { useGetTokenOrLogout } from "../../../utils/token";
import AdminRevenueAndEnrolData from "./AdminRevenueAndEnrolData";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AdminNewDashBoardUi() {
     const userId = localStorage.getItem("userId");
  
      const getTokenOrLogout = useGetTokenOrLogout();
     
     const [selectedFilter, setSelectedFilter] = useState("today");
      const [fromDate, setFromDate] = useState(null);
      const [toDate, setToDate] = useState(null);
      const [cardRevenueData, setCardRevenueData] =useState([])
      const [revenueLoading,setRevenueLoading] =useState(false)
        const [upcommigevent, setUpcommingEvent] = useState([]);
        const [upcommingLoading, setUpcommingLoding] = useState(false);
          const [chosenDate, setChosenDate] = useState(new Date());
            const [isPieChartLoading, setIsPieChartLoading] = useState(false);
            const [pieChartData, setPieChartData] = useState({});
             const [centerRevenueData, setCenterRevenueData] = useState([]);
              const [isCenterLoading, setIsCenterLoading] = useState(false);

     const handleClickFilter = (criteria) => {
        setSelectedFilter(criteria);
        setFromDate("");
        setToDate("");
        if (criteria !== "custom") {
          handleApplyRevenuFilter(criteria, null, null);
        
        }
      };

       // Fetch Upcomming Event
        useEffect(() => {
          setUpcommingLoding(true);
          const token = getTokenOrLogout();
          if (!token) return;
          let headers = {
            Authorization: `Bearer ${token}`,
          };
          const fetchData = async () => {
            try {
              const response = await fetch(
                `${API_ENDPOINT}route2.0/ongoing-upcomingevent/${userId}`,
                { headers }
              );
              if (response.ok) {
                const data = await response.json();
                setUpcommingEvent(data);
                setUpcommingLoding(false);
              } else {
                throw new Error(`Request failed with status: ${response.status}`);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
      
          fetchData();
        }, []);
      

        const handleDateChange = (date) => {
            setChosenDate(date);
        
        };

       

// Fetch Card Data
          const handleApplyRevenuFilter = async (criteria, fromDate, toDate) => {
            setRevenueLoading(true);
            const token = getTokenOrLogout();
            if (!token) return;
            let headers = {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Make sure to keep the content type
            };
            const requestData = {
              user_id: parseInt(userId),
        
              filter: criteria,
              to_date: toDate || null,
              from_date: fromDate || null,
            };
        
            try {
              const response = await fetch(
                `${API_ENDPOINT}admindashboard/chip-data`,
                {
                  method: "POST",
                  headers,
                  body: JSON.stringify(requestData),
                }
              );
        
              if (response.ok) {
                const result = await response.json();
        
                setCardRevenueData(result);
        
                setRevenueLoading(false);
              } else {
                console.log("Error");
                setRevenueLoading(false);
              }
            } catch (error) {
              console.log(error, "Error");
              setRevenueLoading(false);
            }
          };
        
           useEffect(() => {
              if (selectedFilter !== "custom") {
                handleApplyRevenuFilter(selectedFilter);
                // getRevenueForCenters(selectedFilter);
              }
          
              if (selectedFilter === "custom" && fromDate !== "" && toDate !== "") {
                handleApplyRevenuFilter(selectedFilter, fromDate, toDate);
                // getRevenueForCenters(selectedFilter, fromDate, toDate);
              }
            }, [fromDate, toDate]);


              // PiechartData
  const getPieChartData = (date) => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    setIsPieChartLoading(true);

    const requestData = {
      user_id: userId,
      date: date,
    };

    fetch(`${API_ENDPOINT}admindashboard/donut-chart`, {
      method: "POST",
      headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPieChartData(data);
        setIsPieChartLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching daily data:", error);
        setIsPieChartLoading(false);
      });
  };
          
  useEffect(() => {
    getPieChartData(chosenDate);
  }, [chosenDate]);


  return (
    <>
      <Grid container spacing={3}>
        {/* Left Side */}
        <Grid item xs={12} lg={9} sm={12} md={9}>
          <Grid container spacing={2}>
            {/* content */}
            <Grid item xs={12}>
              <Content />
            </Grid>

            {/* Filter Button */}
            <Grid item xs={12} sm={12} mt={2}>
              <FilterButtonComp
                selectedFilter={selectedFilter}
                handleClickFilter={handleClickFilter}
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
              />
            </Grid>

            {/* Revenue Cards */}

  <Grid item xs={12} container spacing={2} gap={1}>
              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#D5F6E1"
                  title="Total Revenue"
                  iconSrc="/Group 1000002878.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                        cardRevenueData?.total_revenue
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#E7F3FF"
                  title="Collected Revenue"
                  iconSrc="/Group 1000002854.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                        cardRevenueData?.collected_revenue
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#FFEDFB"
                  title="Pending Revenue"
                  iconSrc="/Group 1000002879.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                        cardRevenueData?.pending_revenue
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.7} lg={2.9}>
                <RevenueCards
                  backGround="#FFF6E2"
                  title="New Enrollments"
                  iconSrc="/Group 1000002880.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                        cardRevenueData?.new_enrollment
                    )
                  }
                />
              </Grid>
            </Grid>

{/* Month Wise Data */}
            <Grid item xs={12}>
                <NewCardcomp   height= "397px" >
                <AdminRevenueAndEnrolData
                  centerRevenueData={centerRevenueData}
                  isCenterLoading={isCenterLoading}
                />
              </NewCardcomp>
              </Grid>

          </Grid>
        </Grid>

{/* Right Part */}
        <Grid item xs={12} lg={3} sm={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DisplayCurrentDateTime />
            </Grid>
            <Grid item xs={12}>
              <UpcommingEvents
                data={upcommigevent}
                loading={upcommingLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <NewCardcomp>
                <LeadCalandar onDateChange={handleDateChange} />
                {isPieChartLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <MonthlyRevenuPichart
                    loading={isPieChartLoading}
                    pieChartData={pieChartData}
                  />
                )}
              </NewCardcomp>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
