import {
  Autocomplete,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";

export default function RecycleMaskTaskAssign({
  selectedId,
  onClose,
  setSelectedIds,
  selectedEnquiryids,
  setSelectedEnquiryids,
}) {
  const today = new Date();
  const formattedToday = new Date(
    today.getTime() - today.getTimezoneOffset() * 60000
  )
    .toISOString()
    .slice(0, -8);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const [servicelist, setServiceList] = useState([]);
  const [service, setService] = useState(null);
  const [postTaskList, setPostTasklist] = useState([]);
  const [posttask, setPostTask] = useState(null);
  const [taskOwnerList, setTaskOwnerList] = useState([]);
  const [taskowner, setTaskowner] = useState(null);
  const [dateTime, setDateTime] = useState("");
  const [followupReason, setFollowupReason] = useState(null);
  const [FollowUpReasonList, setFollowupReasonList] = useState([]);
  const [selectedFolloupId, setSelectedFollowupId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handledatetimechange = (e) => {
    setDateTime(e.target.value);
    console.log("date time is", e.target.value);
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setServiceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleServiceChange = (e) => {
    setService(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}typeoftask`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setPostTasklist(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleTaskChange = (event, value) => {
    setPostTask(value);
    const id = value?.id;
    setSelectedFollowupId(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}taskfollowupreason`);
        if (response.ok) {
          const data = await response.json();
          setFollowupReasonList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleTypeOfReasonChange = (event, value) => {
    setFollowupReason(value);
  };

  console.log("posttask", posttask);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setTaskOwnerList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChangeTaskOwner = (event, value) => {
    setTaskowner(value);
  };

  // Submit the Data to api

  const handleSubmit = () => {
    // if(service===null){
    //   showSnackbar("Service is required", 'error')
    //   return
    // }
    if (posttask === null) {
      showSnackbar("Post Task is required", "error");
      return;
    }
    if (
      posttask?.name_of_task_type === "Follow up call" &&
      followupReason === null
    ) {
      showSnackbar("Followup Reason is required", "error");
      return;
    }

    if (dateTime === "") {
      showSnackbar("DateTime is required", "error");
      return;
    }

    if (taskowner === null) {
      showSnackbar("Task Owner is required", "error");
      return;
    }

    setIsLoading(true);
    const formdata = {
      enquiry_id: selectedEnquiryids || null,
      service_id: service || null,
      type_of_task_id: posttask?.id || null,
      date_time: dateTime || null,
      task_owner: taskowner?.id || null,
      follow_up_reason_id: followupReason?.id || null,
      note: note,
      user_id: parseInt(userId),
      request_id: selectedId,
    };

    fetch(`${API_ENDPOINT}route2.0/bulktaskassignrecycledleads`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formdata),
    })
      .then((response) => {
        if (response.ok) {
          setIsLoading(false);
          console.log("Task Assign successfully.");
          showSnackbar("Task Assign successfully");

          setTimeout(() => {
            onClose();
          }, 1000);

          setTimeout(() => {
            setSelectedIds([]);
          }, 1200);
        } else {
          setIsLoading(false);
          console.error("Error: Unknown Error");
          showSnackbar("Error: Unknown Error", "error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
        // Handle network errors or other issues
        showSnackbar("Error", "error");
      });
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Assigning Tasks....
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Grid container spacing={2}>
        {/* <Grid item xs={6} lg={3} md={6} sm={6}>
            <FormControl sx={{ mb: 1, width: "100%" }}>
              <Typography sx={{ fontWeight: 600 }}>Select Service <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span></Typography>
              <Select
                size="small"
                MenuProps={MenuProps}
                value={service}
                onChange={handleServiceChange}
                style={{ borderRadius: "15px" }}
              >
                {servicelist.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.service_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
        <Grid item xs={6} lg={4} md={6} sm={6}>
          <FormControl sx={{ mb: 1, width: "100%" }}>
            <Typography sx={{ fontWeight: 600 }}>
              Post Task{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1em",
                }}
              >
                *
              </span>
            </Typography>
            {/* <Select
                MenuProps={MenuProps}
                value={posttask}
                onChange={handleTaskChange}
                style={{ borderRadius: "15px" }}
              >
                {postTaskList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name_of_task_type}
                  </MenuItem>
                ))}
              </Select> */}
            <Autocomplete
              size="small"
              value={posttask}
              onChange={handleTaskChange}
              options={postTaskList}
              getOptionLabel={(option) => option.name_of_task_type}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {selectedFolloupId === 2 && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Typography>
              Follow Up Reasons{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1em",
                }}
              >
                *
              </span>
            </Typography>

            <Autocomplete
              value={followupReason}
              size="small"
              onChange={handleTypeOfReasonChange}
              options={FollowUpReasonList}
              getOptionLabel={(option) => option.follow_up_reason}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={6} lg={4} md={6} sm={6}>
          <Typography sx={{ fontWeight: 600 }}>
            Due Date and Time{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <TextField
            size="small"
            type="datetime-local"
            onChange={handledatetimechange}
            fullWidth
            InputProps={{
              style: {
                borderRadius: "15px",
              },
              inputProps: { min: formattedToday },
            }}
          />
        </Grid>

        <Grid item xs={6} lg={4} md={6} sm={6}>
          <FormControl sx={{ mb: 1, width: "100%" }}>
            <Typography sx={{ fontWeight: 600 }}>
              Task Owner{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1em",
                }}
              >
                *
              </span>
            </Typography>
            {/* <Select
                MenuProps={MenuProps}
                value={taskowner}
                onChange={handleChangeTaskOwner}
                style={{ borderRadius: "15px" }}
              >
                {taskOwnerList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.first_name} {item.last_name}
                  </MenuItem>
                ))}
              </Select> */}
            <Autocomplete
              size="small"
              value={taskowner}
              onChange={handleChangeTaskOwner}
              options={taskOwnerList}
              getOptionLabel={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>Note</Typography>
          <TextField
            size="small"
            multiline
            rows={4}
            onChange={(e) => setNote(e.target.value)}
            fullWidth
            InputProps={{
              style: {
                borderRadius: "15px",
              },
            }}
          />
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12} textAlign="end">
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
