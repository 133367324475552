import {
  Button,
  Grid,
  Paper,
  styled,
  TableContainer,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Backdrop,
  Stack,
  CircularProgress,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/Maincard";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DownloadIcon from "@mui/icons-material/Download";
import HeadingCard from "../../newTestPrep/newComponent/NewCardsComp/HeadingCard";
import SearchButton from "../../newTestPrep/newComponent/NewButtonComp/SearchButton";
import ClearButtonComp from "../../newTestPrep/newComponent/NewButtonComp/ResetButtonComp";
import CustomAutocompleteComp from "../../newTestPrep/newComponent/AutocompleteComp";
import StyledTableContainer from "../../newTestPrep/newComponent/TableContainer";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AdvisorTargetRevenue() {
  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [branchList, setBranchList] = useState([]);
  const [advisorList, setAdvisorList] = useState([]);
  const [advisorName, setAdvisorName] = useState([
    { user_id: "all", first_name: "Select All" },
  ]);
  const [branchName, setBranchName] = useState([
    { id: "all", branch_name: "Select All" },
  ]);
  const [selectedYear, setSelectedYear] = useState({
    year: new Date().getFullYear(),
  });
  const [targetData, setTargetTData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState(["all"]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          setBranchList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [allBranchList, setAllBranchlist] = useState([
    { id: "all", branch_name: "Select All" },
    ...(branchList || []),
  ]);

  useEffect(() => {
    if (Array.isArray(branchList)) {
      setAllBranchlist([
        { id: "all", branch_name: "Select All" },
        ...branchList,
      ]);
    }
  }, [branchList]);

  const handleBranchChange = (event, value) => {
    console.log("valueis", value);

    if (value.some((item) => item.id === "all")) {
      // If "Select All" is selected, set all branches
      setBranchName([{ id: "all", branch_name: "Select All" }]);
      setSelectedBranchId(value.map((i) => i.id));
    } else {
      // If individual branches are selected, remove "Select All"
      setBranchName(value);
      setSelectedBranchId(value.map((i) => i.id));
    }
  };

  console.log("allBranchList", allBranchList);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}advisor`);
        if (response.ok) {
          const data = await response.json();

          setAdvisorList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [allAdvisorList, setAllAdvisorList] = useState([
    { user_id: "all", first_name: "Select All" },
    ...(advisorList || []),
  ]);

  useEffect(() => {
    if (Array.isArray(advisorList)) {
      setAllAdvisorList([
        { user_id: "all", first_name: "Select All" },
        ...advisorList,
      ]);
    }
  }, [advisorList]);

  const handleUserChange = (event, value) => {
    setAdvisorName(value);

    if (value.some((item) => item.user_id === "all")) {
      // If "Select All" is selected, set all branches
      setAdvisorName([{ user_id: "all", first_name: "Select All" }]);
    } else {
      // If individual branches are selected, remove "Select All"
      setAdvisorName(value);
    }
  };

  // Select Advisor Automatic base on Branch
  useEffect(() => {
    if (!Array.isArray(advisorList)) {
      console.error("advisorList is not an array:", advisorList);
      return;
    }

    if (selectedBranchId.includes("all")) {
      setAdvisorName([{ first_name: "Select All", user_id: "all" }]);
    } else {
      const branchViseAdvisor = advisorList.filter((advisor) =>
        selectedBranchId.includes(advisor.branch_id)
      );
      setAdvisorName(branchViseAdvisor);
    }
  }, [selectedBranchId, advisorList]);

  const years = Array.from({ length: 5 }, (_, index) => ({
    year: new Date().getFullYear() - 3 + index,
  }));

  console.log(years);

  const allYearList = [{ year: "Select All" }, ...years];
  const handleselectedYear = async (value) => {
    setSelectedYear(value);
  };

  const selectedAdvisor = advisorName.map((i) => i.user_id);
  const handleSearchData = async (event) => {
    setLoading(true);

    const requestData = {
      user_id: parseInt(userId),
      year: selectedYear?.year,
      report_for:
        userRole === "center_admin" && selectedAdvisor.includes("all")
          ? "all"
          : userRole === "center_admin" && !selectedAdvisor.includes("all")
          ? selectedAdvisor
          : null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}advisor-rev-target/getyeartargetsandrevenuecollected`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        // const reversedData = result.slice().reverse();
        setTargetTData(result);
        setLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        showSnackbar("Please select user", "error");
        setTargetTData([]);
        setLoading(false);
      } else if (response.status === 440) {
        navigate("/page440");
      } else if (response.status === 400) {
        showSnackbar("Some fields are missing", "error");
        setTargetTData([]);
        setLoading(false);
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  useEffect(() => {
    handleSearchData();
  }, []);

  const handleclear = () => {
    setBranchName([]);
    setSelectedBranchId([]);
    setAdvisorName([]);
    setTargetTData([]);
    setSelectedYear(null);
  };

  const determineStatus = (row, currentMonth, currentYear) => {
    if (row?.targetRevenue === row?.collectedRevenue) {
      return "Achieved";
    } else if (
      currentMonth === row?.month &&
      row?.targetRevenue > row?.collectedRevenue
    ) {
      return "In Progress";
    } else if (row?.targetRevenue === "No Target Found") {
      return "Not Given";
    } else if (row?.year >= currentYear) {
      if (currentMonth < row?.month && row?.year == currentYear) {
        return "Upcoming";
      } else {
        return "Upcoming";
      }
    } else if (row?.year <= currentYear) {
      if (row?.year == currentYear && currentMonth > row?.month) {
        return "Incompleted";
      } else {
        return "Incompleted";
      }
    }

    return "Not Given";
  };

  const getStatusChipProps = (status) => {
    switch (status) {
      case "Achieved":
        return { label: "Achieved", color: "secondary", variant: "contained" };
      case "Incompleted":
        return { label: "Incomplete", color: "error", variant: "contained" };
      case "In Progress":
        return { label: "In-Progress", color: "warning", variant: "contained" };
      case "Upcoming":
        return { label: "Upcoming", color: "primary", variant: "contained" };
      case "Not Given":
        return { label: "Not Given", color: "default", variant: "contained" };
      default:
        return { label: "Not Given", color: "default", variant: "contained" };
    }
  };

  console.log("advisorName", advisorName);
  const handleViewReport = () => {
    const url = `/advisor-target-report-pdf`;
    window.open(url, "_blank");

    const targetDataObject = {
      targetData,
      advisorName,
      selectedYear,
    };
    localStorage.setItem("targetdata", JSON.stringify(targetDataObject));
  };

  const handleViewtestReport = () => {
    const url = `/test-type-wise-revenue`;
    window.open(url, "_blank");
  };

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fetching
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2.5} lg={2}>
          <HeadingCard
            label={
              userRole === "center_admin" ? "Advisor's Target" : "Your Target"
            }
          />
        </Grid>
        {userRole === "center_admin" && (
          <Grid item xs={12} sm={9} md={9.5} lg={10} textAlign="end">
            <Button
              size="small"
              variant="contained"
              color="secondary"
              style={{ borderRadius: "25px" }}
              startIcon={<DownloadIcon />}
              onClick={handleViewReport}
            >
              Download Report
            </Button>
          </Grid>
        )}

        {userRole === "advisor" && (
          <Grid item xs={12} sm={6} textAlign="end">
            <Button
              size="small"
              color="secondary"
              variant="contained"
              style={{ borderRadius: "25px" }}
              onClick={handleViewtestReport}
            >
              View Test Wise Revenue Report
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              {userRole === "center_admin" && (
                <>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h6">Select Branch</Typography>
                    <CustomAutocompleteComp
                      size="small"
                      value={branchName}
                      multiple
                      onChange={handleBranchChange}
                      options={allBranchList}
                      getOptionLabel={(option) =>
                        option.id === "all"
                          ? "All Selected"
                          : option.branch_name
                      }
                      tooltipContent={(option) =>
                        option.id === "all"
                          ? "All Selected"
                          : option.branch_name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "14px" }, // Add borderRadius here
                          }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            backgroundColor: selected ? "#e0f7fa" : "inherit",
                          }}
                        >
                          {option.branch_name}
                        </li>
                      )}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={3}>
                    <Typography>Select Advisor</Typography>
                    <CustomAutocompleteComp
                      size="small"
                      value={advisorName}
                      multiple
                      onChange={handleUserChange}
                      options={allAdvisorList}
                      getOptionLabel={(option) =>
                        `${option.first_name ? option.first_name : ""} ${
                          option.last_name ? option.last_name : ""
                        }`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "14px" }, // Add borderRadius here
                          }}
                        />
                      )}
                    />
                  </Grid> */}

                  <Grid item xs={12} sm={3}>
                    <Typography variant="h6">Select Advisor</Typography>
                    <CustomAutocompleteComp
                      size="small"
                      value={advisorName}
                      multiple
                      onChange={handleUserChange}
                      options={allAdvisorList}
                      getOptionLabel={(option) =>
                        option.user_id === "all"
                          ? "All Selected"
                          : `${option.first_name ? option.first_name : ""} ${
                              option.last_name ? option.last_name : ""
                            }`
                      }
                      tooltipContent={(option) =>
                        option.user_id === "all"
                          ? "All Selected"
                          : `${option.first_name ? option.first_name : ""} ${
                              option.last_name ? option.last_name : ""
                            }`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "14px" }, // Add borderRadius here
                          }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.user_id === value.user_id
                      }
                      renderOption={(props, option, { selected }) => {
                        console.log("selected", selected);
                        return (
                          <li
                            {...props}
                            style={{
                              backgroundColor: selected ? "#e0f7fa" : "inherit",
                            }}
                          >
                            {option.first_name} {option.last_name}
                          </li>
                        );
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={3}>
                <Typography variant="h6">Select Year</Typography>
                <CustomAutocompleteComp
                  value={selectedYear}
                  onChange={(event, value) => handleselectedYear(value)}
                  size="small"
                  options={years}
                  getOptionLabel={(option) => option.year}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Typography variant="h6" sx={{ visibility: "hidden" }}>
                  Spacing purpose
                </Typography>
                <Grid container gap={1}>
                  <SearchButton
                    size="small"
                    showIcon={true}
                    onClick={handleSearchData}
                  />

                  <ClearButtonComp size="small" onClick={handleclear} />
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>

        <Grid item xs={12}>
          <MainCard>
            <StyledTableContainer component={Paper} borderLeft={true}>
              <Table
                sx={{ minWidth: 650, tableLayout: "fixed" }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell sx={{}}>Target</TableCell>
                    <TableCell sx={{}}>Collected</TableCell>
                    <TableCell>Achieved Percentage</TableCell>
                    <TableCell sx={{}}>Status</TableCell>
                  </TableRow>
                </TableHead>

                {targetData?.length === 0 ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">No data available</Typography>
                  </Grid>
                ) : (
                  targetData?.map((row, index) => {
                    const currentDate = new Date();
                    const currentMonth = currentDate.getMonth() + 1;
                    const currentYear = currentDate.getFullYear();
                    console.log("currentYear", currentYear);
                    // let status = "";
                    // if (
                    //   currentMonth === row?.month &&
                    //   row?.targetRevenue > row?.collectedRevenue
                    // ) {
                    //   status = "In Process";
                    // } else if (
                    //   currentMonth > row?.month &&
                    //   row?.targetRevenue > row?.collectedRevenue
                    // ) {
                    //   status = "Incompleted";
                    // } else if (row?.targetRevenue === row?.collectedRevenue) {
                    //   status = "Completed";
                    // }
                    // if (row?.year === currentYear + 1) {
                    //   status = "Upcomming";
                    // } else {
                    //   status = "Not Given";
                    // }
                    const status = determineStatus(
                      row,
                      currentMonth,
                      currentYear
                    );

                    return (
                      <TableBody>
                        <TableRow
                          key={row.name}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "inherit",
                          }}
                        >
                          <TableCell sx={{ borderLeft: "0.1px solid #C3C3C3" }}>
                            {row?.monthName}
                          </TableCell>
                          <TableCell>
                            {row.targetRevenue === "No Target Found"
                              ? "N/A"
                              : `${row?.targetRevenue}`}{" "}
                          </TableCell>
                          <TableCell>{row?.collectedRevenue} </TableCell>

                          <TableCell>
                            {row?.percentageAchieved !== "N/A"
                              ? `${row?.percentageAchieved} %`
                              : row?.percentageAchieved}
                          </TableCell>
                          <TableCell
                            sx={{ borderRight: "0.1px solid #C3C3C3" }}
                          >
                            {" "}
                            <Chip
                              size="small"
                              {...getStatusChipProps(status)}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })
                )}
              </Table>
            </StyledTableContainer>
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
