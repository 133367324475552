// Author: Ayesha Shaikh
// Date : 14 aug 2024

import { Grid, Typography } from "@mui/material";
import React from "react";

export default function ViewServiceDetails({ data }) {
  return (
    <>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6} lg={4} sm={6} md={6}>
          <Typography variant="h5">Service</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {data?.service_id?.service_name}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={4} sm={6} md={6}>
          <Typography variant="h5">Sub Service</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {data?.sub_service_id?.name}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={4} sm={6} md={6}>
          <Typography variant="h5">Exam Class Type</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {data?.test_type_id?.name}
          </Typography>
        </Grid>
        {data?.test_type_id?.name === "IELTS" && (
          <>
            <Grid item xs={6} lg={4} sm={6} md={6}>
              <Typography variant="h5">Sub Test Type</Typography>
              <Typography sx={{ fontWeight: 600 }}>{data?.sub_test_type_id?.name} </Typography>
            </Grid>

            <Grid item xs={6} lg={4} sm={6} md={6}>
              <Typography variant="h5">Delivery Format</Typography>
              <Typography sx={{ fontWeight: 600 }}>{data?.test_delivery_format_id?.name} </Typography>
            </Grid>
          </>
        )}

        {data?.test_type_id?.name === "PTE" && (
          <>
            <Grid item xs={6} lg={4} sm={6} md={6}>
              <Typography variant="h5">Sub Test Type</Typography>
              <Typography sx={{ fontWeight: 600 }}>{data?.sub_test_type_id?.name} </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={6} lg={4} sm={6} md={6}>
          <Typography variant="h5">Purpose Of Test</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {data?.purpose_of_test_id?.purpose_name}
          </Typography>
        </Grid>

        <Grid item xs={6} lg={4} sm={6} md={6}>
          <Typography variant="h5">First country of Interest</Typography>
          <Typography sx={{ fontWeight: 600 }}>{data?.target_country_id?.country_name} </Typography>
        </Grid>
      </Grid>
    </>
  );
}
