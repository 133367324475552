import { Box, Button, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { tableCellClasses } from '@mui/material/TableCell';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';
import StyledTableContainer from '../../../../../newTestPrep/newComponent/TableContainer';
import { fDate } from '../../../../../utils/formatTime';


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;



function WhatsappMessages({enquiryData}) {
    console.log("enquiryData",enquiryData)
    
    const theme = useTheme()
    const [open, setOpen] = useState(false);
    const [messageBody, setMessageBody] = useState('');
    const [error, setError] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [whatsappData, setWhatsAppdata] =useState([])
    const [isLoading,setIsloading] =useState(false)

    // Function to handle button click
    const handleWhatsAppClick = () => {
        if (!phoneNumber) {
            return;
        }
        const whatsappURL = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}&text=hi`;
        window.open(whatsappURL, '_blank');
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setError('');
        setMessageBody('');
    };

    const sendWhatsAppMessage = () => {
       
        if (!messageBody.trim()) {
            setError('Message body cannot be empty');
            return;
        }
        // Implement sending WhatsApp message here
        handleClose();
    };

    useEffect(() => {
       
        if (enquiryData && enquiryData.whatsapp_country_code && enquiryData.whatsapp_number) {
            setPhoneNumber(`${enquiryData.whatsapp_country_code}${enquiryData.whatsapp_number}`);
        }
    }, [enquiryData]);

      useEffect(() => {
        const fetchData = async () => {
            setIsloading(true);
          try {
            const response = await fetch(`${API_ENDPOINT}sentwhatsapp/getsendwhatsapp/byenquiry/${enquiryData?.id}`);
            if (response.ok) {
              const data = await response.json();
              console.log("data", data);
             
              setWhatsAppdata(data);
              setIsloading(false);
    console.log("whatappdata",data)
  
            } else {
                setIsloading(false);
              throw new Error(`Request failed with status: ${response.status}`);
            }
          } catch (error) {
            setIsloading(false);
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData();
      }, []);
    
    
  return (
    <>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
        <Button
            startIcon={<WhatsAppIcon />}
            variant="contained"
            color="secondary"
            size='small'
            onClick={handleWhatsAppClick}
            disabled={enquiryData.is_active===0}
        >
            Send WhatsApp
        </Button>
    </div>
    {whatsappData.length<=0 ? ( 
                   
                        <Typography variant='h5'>No Whatsapp Data Available</Typography>
    ) :( 
                  
          <StyledTableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                        <TableCell align='center'>Sent By</TableCell>
                            <TableCell align='center'>Message Body</TableCell>
                            <TableCell align='center'>Sent Date</TableCell>
                            <TableCell align='center'>Source</TableCell>
                            
                        </TableRow>
                    </TableHead>
                    {whatsappData.map(( row) => (
                    <TableBody>
                 
                            <TableRow hover>
                                <TableCell align='center'>
                                    {row?.send_by}
                                </TableCell>
                                <TableCell align='center'>
                              {row?.message_body}
                              
                                    </TableCell>
                                    <TableCell align='center'>
                            {fDate (row?.createdAt)}
                                    </TableCell>
                                    <TableCell align='center'>
                                <Typography>
                                    </Typography>
                                    </TableCell>
                                
                                
                            </TableRow>
                         
                    </TableBody>
                 ))}
                </Table>
              
            </StyledTableContainer>
    )}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <h2 id="modal-modal-title">Send WhatsApp Message</h2>
                        <IconButton onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <TextField
                        label="Message Body"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={messageBody}
                        onChange={(e) => setMessageBody(e.target.value)}
                        error={!!error}
                        helperText={error}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={sendWhatsAppMessage}
                    >
                        Send
                    </Button>
                </Box>
            </Modal>

    </>
  )
}

export default WhatsappMessages