import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import CustomAutocompleteComp from "../../newTestPrep/newComponent/AutocompleteComp";
import CustomTextFieldComp from "../../newTestPrep/newComponent/customTextFieldComp";
import CustomNoRowsOverlay from "../../newTestPrep/newComponent/TableCustomeComp/CustomNoRowsOverlay";
export default function RepresentativeForm({ onClose }) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [eventName, setEventName] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userName, setUserName] = useState(null);
  const [deskTypeList, setDeskTypeList] = useState([]);
  const [deskType, setdeskType] = useState(null);
  const [deskNumber, setDeskNumber] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [countryName, setCountryName] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [serViceName, setserviceName] = useState(null);
  const [subServiceList, setSubserviceList] = useState([]);
  const [subserviceName, setSubserviceName] = useState([]);
  const [subserviceIds, setSubserviceIds] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});

  const [representativeData, setRepresentativeData] = useState([]);
  const [idCounter, setIdCounter] = useState(0);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [eventLoading, setEventLoading] = useState(false);

  console.log("subserviceName",subserviceName)
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      setEventLoading(true);
      try {
        const response = await fetch(`${API_ENDPOINT}campaign/event/withname`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          const filteredcampaign = data.filter(
            (campaign) => campaign.campaign_type_id?.id === 1
          );
          setEventList(filteredcampaign);
          setEventLoading(false);
          console.log("data1", filteredcampaign);
        } else {
          setEventLoading(false);
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        setEventLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handleEventChange = (event, value) => {
    setEventName(value);
    setErrorMessage((prev) => ({ ...prev, eventName: "" }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleuserChange = (event, value) => {
    setUserName(value);
    setErrorMessage((prev) => ({ ...prev, userName: "" }));
  };

  const handleDeskTypeChange = (e) => {
    setdeskType(e.target.value);
    setErrorMessage((prev) => ({ ...prev, deskType: "" }));
  };

  const handleDeskNumberChange = (e) => {
    setDeskNumber(e.target.value);
    setErrorMessage((prev) => ({ ...prev, deskNumber: "" }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setServiceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleServiceChange = (e) => {
    setserviceName(e.target.value);
    setErrorMessage((prev) => ({ ...prev, serViceName: "" }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          const subserviceData = sortedData.filter((i)=>i.service_id===serViceName)
          setSubserviceList(subserviceData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [serViceName]);

  const allSubserviceList = [{ name: "Select All" }, ...subServiceList];
  const handleSubServiceChange = (event, value) => {
 console.log("value",value)
      if (value.some((option) => option.name === "Select All")) {
      const subServiceIds = subServiceList.map((i) => i.id);
      setSubserviceName(subServiceList);
      setSubserviceIds(subServiceIds)
    } else {
      const subServiceId = value?.map((i) => i.id);
      setSubserviceName(value);
      setSubserviceIds(subServiceId)
    }
    setErrorMessage((prev) => ({ ...prev, subserviceName: "" }));
  };

  const handleDelete = (id) => {
    setRepresentativeData(representativeData.filter((data) => data.id !== id));
  };

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <DeleteIcon fontSize="small"
            color="error"
            onClick={() => handleDelete(params.row?.id)}
          />{" "}
        </div>
      ),
    },
    {
      field: "event",
      headerName: "Event",
      width: 450,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "user",
      headerName: "User",
      width: 170,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "deskType",
      headerName: "Desk Type",
      width: 170,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "deskNumber",
      headerName: "Desk Number",
      width: 170,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "service",
      headerName: "Service",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "subService",
      headerName: "Sub Service",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },

  
  ];

  // const StyledGridOverlay = styled("div")(({ theme }) => ({
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   height: "100%",
  //   "& .ant-empty-img-1": {
  //     fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  //   },
  //   "& .ant-empty-img-2": {
  //     fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  //   },
  //   "& .ant-empty-img-3": {
  //     fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  //   },
  //   "& .ant-empty-img-4": {
  //     fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  //   },
  //   "& .ant-empty-img-5": {
  //     fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
  //     fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  //   },
  // }));

  // function CustomNoRowsOverlay() {
  //   return (
  //     <StyledGridOverlay>
  //       <Box sx={{ mt: 1 }}>No Data Available</Box>
  //     </StyledGridOverlay>
  //   );
  // }

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: "20px",
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",
      fontSize: "16px",
      color: "#000",
      textAlign: "center",
      fontFamily: "Roboto",
      //  borderRight: "0.1px solid #C3C3C3",
    },
    "& .MuiTableCell-body": {
      textAlign: "center",
      padding: "8px 16px",
      overflow: "hidden", // Hide overflowing content
      textOverflow: "ellipsis", // Show ellipsis when content overflows

      // borderRight: "0.1px solid #C3C3C3",
      whiteSpace: "normal", // Allow text to wrap inside the cell
      wordWrap: "break-word",
      fontFamily: "Roboto",
    },
  }));

  console.log("eventName", eventName);

  const handleAdd = () => {
    let errors = {};
    if (!eventName) errors.eventName = "Please select an event.";
    if (!userName) errors.userName = "Please select a user.";
    if (!deskType) errors.deskType = "Please enter desk type.";
    if (!deskNumber) errors.deskNumber = "Please enter desk number.";

    if (!serViceName) errors.serViceName = "Please select a service.";
    if (subserviceName.length === 0) errors.subserviceName = "Please select a sub-service.";

    if (Object.keys(errors).length > 0) {
      setErrorMessage(errors);
      return;
    }
    const selectedEvent = eventList.find((event) => event.id === eventName.id);

    const selectedUser = userList.find((user) => user.id === userName.id);
    const selectedService = serviceList.find(
      (service) => service.id === serViceName
    );
    const selectedSubService = subserviceName.map((i)=>i.name)

   console.log("selectedSubService",selectedSubService) 
    const newRepresentative = {
      id: idCounter, // Use idCounter as the unique ID
      event: selectedEvent ? selectedEvent.campaign_name : null,
      eventId: eventName.id,
      user: selectedUser
        ? `${selectedUser.first_name} ${selectedUser.last_name}`
        : "",
      userId: userName.id,
      deskType: deskType,
      deskNumber: deskNumber,
      service: selectedService ? selectedService.service_name : null,
      serviceId: serViceName,
      subService: selectedSubService ? selectedSubService : [],
      subServiceId: subserviceIds,
      country: countryName,
    };
    setRepresentativeData([...representativeData, newRepresentative]);
    // Reset form fields if needed
    setIdCounter(idCounter + 1);
    setEventName(null);
    setUserName(null);
    setDeskNumber("");
    setdeskType(null);
    setserviceName(null);
    setSubserviceName([]);
  };

  console.log("representativeData", representativeData);

  const handleSubmit = async () => {
    const dataToSubmit = representativeData.map((data) => ({
      event_id: data.eventId,
      counsellor_id: data.userId,
      registration_desk: data.deskType,
      desk_number: parseInt(data.deskNumber),
      service_id: data.serviceId,
      sub_service_id: data.subServiceId,
      user_id: parseInt(userId),
    }));
    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/createeventrep`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSubmit),
      });
      if (response.ok) {
        const result = await response.json();
        console.log("Data submitted successfully:", result);
        showSnackbar("Data submitted successfully");
        setTimeout(() => {
          onClose();
        }, 1500);
      } else if (response.status === 403) {
        showSnackbar("User is  already registered for the  event ", "error");
      } else if (response.status === 404) {
        showSnackbar("Failed To Create ", "error");
        setTimeout(() => {
          onClose();
        }, 1500);
      } else if (response.status === 500) {
        showSnackbar("Please Select Event ", "error");
      } else {
        setTimeout(() => {
          onClose();
        }, 1500);
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      showSnackbar("Error submitting data", "error");
    }
  };

  const now = new Date();
  const firstDateOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

  // Format the date in 'YYYY-MM-DD' using local time
  const year = firstDateOfMonth.getFullYear();
  const month = String(firstDateOfMonth.getMonth() + 1).padStart(2, "0"); // Add leading zero
  const day = String(firstDateOfMonth.getDate()).padStart(2, "0"); // Add leading zero

  const formattedDate = `${year}-${month}-${day}`;
  console.log("formattedDate", formattedDate); // Example output: "2025-01-01"

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} md={8} sm={8}>
          <FormControl sx={{ width: "100%" }}>
            <Typography variant="h6">
              Event <span style={{ color: "red" }}>*</span>{" "}
            </Typography>
            <CustomAutocompleteComp
              size="small"
              value={eventName}
              noOptionsText={
                eventLoading && eventList.length === 0 ? "Loading...." : ""
              }
              onChange={handleEventChange}
              options={eventList}
              getOptionLabel={(option) => option.campaign_name}
              endAdornment={
                eventLoading ? (
                                   <CircularProgress color="inherit" size={20} />
                                 ) : null
                               }
            />
          </FormControl>
          <Typography variant="caption" color="error">
            {errorMessage.eventName}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={4} md={4} sm={4}>
          <FormControl sx={{ width: "100%" }}>
            <Typography variant="h6">
              User <span style={{ color: "red" }}>*</span>
            </Typography>
            {/* <Select
              value={userName}
              onChange={handleuserChange}
              MenuProps={MenuProps}
              sx={{ borderRadius: "15px" }}
            >
              {userList.map((name) => (
                <MenuItem key={name.id} value={name.id}>
                  {name.first_name} {name.last_name}
                </MenuItem>
              ))}
            </Select> */}
            <CustomAutocompleteComp
              size="small"
              value={userName}
              onChange={handleuserChange}
              options={userList}
              getOptionLabel={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
              
            />
          </FormControl>
          <Typography variant="caption" color="error">
            {errorMessage.userName}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} md={6} sm={6}>
          <FormControl sx={{ width: "100%" }}>
            <Typography variant="h6">
              Desk Type <span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
              size="normal"
              value={deskType}
              onChange={handleDeskTypeChange}
              MenuProps={MenuProps}
              sx={{ borderRadius: "10px" }}
            >
              <MenuItem value="General Counselling">
                General Counselling
              </MenuItem>
            </Select>
          </FormControl>
          <Typography variant="caption" color="error">
            {errorMessage.deskType}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} md={6} sm={6}>
          <Typography variant="h6">
            Desk Number <span style={{ color: "red" }}>*</span>
          </Typography>
          <CustomTextFieldComp
            size="normal"
            type="number"
            InputProps={{
              style: { borderRadius: "10px" },
            }}
            fullWidth
            value={deskNumber}
            onChange={handleDeskNumberChange}
          />

          <Typography variant="caption" color="error">
            {errorMessage.deskNumber}
          </Typography>
        </Grid>

        {/* <Grid item xs={12} md={6} sm={6} lg={6}>
          <FormControl sx={{ width: "100%" }}>
            <Typography sx={{ fontWeight: 600 }}>Country</Typography>
            <Select
              value={countryName}
              onChange={handleCountryChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              sx={{ borderRadius: "15px" }}
            >
              {countryList.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={countryName.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item xs={12} lg={3} sm={6} md={6}>
          <FormControl sx={{ width: "100%" }}>
            <Typography variant="h6">
              Service <span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
              size="normal"
              value={serViceName}
              onChange={handleServiceChange}
              MenuProps={MenuProps}
              sx={{ borderRadius: "10px" }}
            >
              {serviceList.map((name) => (
                <MenuItem key={name.name} value={name.id}>
                  {name.service_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="caption" color="error">
            {errorMessage.serViceName}
          </Typography>
        </Grid>

        {serViceName !== null && (
          <Grid item xs={12} sm={12} md={12} lg={3}>
           
              <Typography variant="h6">
                Sub Service <span style={{ color: "red" }}>*</span>
              </Typography>
              {/* <Select
              size="small"
              multiple
                value={subserviceName}
                onChange={handleSubServiceChange}
                MenuProps={MenuProps}
                sx={{ borderRadius: "15px" }}
              >
                {subServiceList.map((name) => (
                  <MenuItem key={name.id} value={name.id}>
                    {name.name}
                  </MenuItem>
                ))}
              </Select> */}

              <CustomAutocompleteComp
                multiple
                limitTags={1}
                size="small"
                value={subserviceName}
                onChange={handleSubServiceChange}
                options={allSubserviceList}
                getOptionLabel={(option) => option.name}
                tooltipContent={(option) => option.name}
                
              />
    
            <Typography variant="caption" color="error">
              {errorMessage.subserviceName}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} md={12} lg={12} sm={12} textAlign="end">
          <Button
            variant="contained"
            size="small"
            endIcon={<AddCircleIcon  fontSize="small"/>}
            style={{ borderRadius: "25px", fontWeight: 600 }}
            onClick={handleAdd}
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          
          {/* <StyledTableContainer>
            <Table
              sx={{ minWidth: 650, tableLayout: "fixed" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell>Action</TableCell>
                  <TableCell>Event</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Desk Type</TableCell>
                  <TableCell>Desk Number</TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell>Sub Service</TableCell>
                </TableRow>
              </TableHead>
              {representativeData.length === 0 ? (
                <TableRow style={{ marginTop: "10px" }}>
                  <Typography style={{ marginTop: "10px" }} variant="h5">
                    No data available
                  </Typography>
                </TableRow>
              ) : (
                representativeData.map((row, index) => (
                  <TableBody>
                    <TableRow
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#f0f0f0" : "inherit",
                      }}
                    >
                      <TableCell>
                        <DeleteIcon
                          color="error"
                          onClick={() => handleDelete(row?.id)}
                        />{" "}
                      </TableCell>
                      <TableCell>{row?.event}</TableCell>
                      <TableCell>{row?.user}</TableCell>
                      <TableCell>{row?.deskType}</TableCell>
                      <TableCell>{row?.deskNumber}</TableCell>
                      <TableCell>{row?.service}</TableCell>
                      <TableCell>{row?.subService.join(', ')}</TableCell>
                    </TableRow>
                  </TableBody>
                ))
              )}
            </Table>
          </StyledTableContainer> */}
               <DataGrid
                        disableColumnMenu
                        disableColumnSorting={true}
                        rows={representativeData}
                        columns={columns}
                        slots={{
                          noRowsOverlay: () => (
                            <CustomNoRowsOverlay message="No Data Available" />
                          ),
                        }}
                        autoHeight
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 25,
                            },
                          },
                        }}
                        sx={{ marginTop: "20px" }}
                        pageSizeOptions={[25, 50, 100]}
                      />
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12} textAlign="end">
          <Button
            variant="contained"
        size="medium"
            style={{  background:"#00b13f"}}
      
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
