import React, { useEffect } from 'react';
import Navbar from './Navbar/Navbar';
import './newlayout.css'; // Create a CSS file for styling
import Sidebar from './Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MENU } from '../../store/actions';


function NewLayout({ children }) {
   const leftDrawerOpened = useSelector((state) => state.customization.opened);
   const dispatch = useDispatch();

   useEffect(() => {
    const handleResize = () => {
      dispatch({ type: SET_MENU, opened: window.innerWidth >= 768 });
    };
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);

  return (
    <div className={`layout ${leftDrawerOpened ? 'drawer-open' : 'drawer-closed'}`}>
      {/* Navbar */}
      <header className="layout-navbar">
        <Navbar />
      </header>
      
      {/* Sidebar and Main Content */}
      <div className="layout-body">
        <aside className="layout-sidebar">
        <Sidebar />
        </aside>
        
        <main className="layout-content">
          {children}
        </main>
      </div>
    </div>
  );
}

export default NewLayout;
