import { Backdrop, Typography } from '@mui/material'
import React from 'react'

export default function LoaderComp({isLoading}) {
  return (
    <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
    <Typography
      variant="h4"
      color="secondary"
      fontWeight={800}
      marginRight={2}
    >
      Fetching
    </Typography>
    <img src="/dashboardimages/Animation - 1741781871306.gif" />
  </Backdrop>
  )
}
