import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/ui-component/card/MainCard";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomPopup from "../../../components/CustomPopup";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { fDate, fDateTime } from "../../../utils/formatTime";
import NoenquiryForm from "../../components/DataTransferComp.jsx/NoenquiryForm";
import AssignAdvisorToMissedLeads from "./AssignAdvisorToMissedLeads";
import HeadingCard from "../../newComponent/NewCardsComp/HeadingCard";
import EditIcon from "@mui/icons-material/Edit";
import EditMissedLeadsForm from "./EditMissedLeadsForm";
import EditIconcomp from "../../newComponent/iconsComp/EditIconcomp";

export default function MissedAutoAssignLeads() {
  const userId = localStorage.getItem("userId");
  console.log(userId);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate();

  const [openpopup, setOpenpopup] = useState(false);
  const [openAssignPopup, setOpenAssignPopup] = useState(false);
  const [registerdata, setRegisterdate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedid, setSelectedid] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handelopen = (rowId) => {
    setSelectedid([rowId]);
    setOpenpopup(true);
  };

  const handelclose = () => {
    setOpenpopup(false);
  };

  const handleEditOpen = (data) => {
    setSelectedData(data);
    setOpenEditPopup(true)
  }

  const handleEditClose = () => {
    setOpenEditPopup(false);
  }

  //select one checkbox
  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedid([...selectedid, id]);
    } else {
      setSelectedid(selectedid.filter((selectedId) => selectedId !== id));
    }
  };

  const handleEdit = (data) => {

  }

  const handleAssignToAdvisor = () => {
    setOpenpopup(true);
  };

  const handleTaskAssign = () => {
    setOpenAssignPopup(true);
  };
  //fetch enquiry api
  useEffect(() => {
    setLoading(true);
    fetch(`${API_ENDPOINT}route2.0/unassigned-enquiry/${userId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 403) {
          // Handle 403 error by showing Snackbar
          showSnackbar("No enq found between yeterday and today", "error");
          setLoading(false);
          return; // No need to continue further
        } else if (response.status === 401) {
          navigate("/");
          return showSnackbar("Unknown user:", "error");
        } else if (response.status === 404) {
          navigate("/page404");
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .then((data) => {
        if (!data) return; // Stop further execution if data is not present

        console.log(data);
        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        console.log(sortedData);
        setRegisterdate(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        navigate("/page404");
      });
  }, [userId]);

  const columns = [
    {
      field: "selection",
      headerName: "Select",
      width: 50,
      renderCell: (params) => {
        return (
          <>
            <Checkbox
              size="small"
              type="checkbox"
              onChange={(event) => handleCheckboxChange(event, params.row.id)}
              checked={selectedid.includes(params.row.id)}
            />
          </>
        );
      },
    },
    
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              style={{
              fontSize:'0.55rem',
                borderRadius: "24px",
                background: "#46b1ff",
                height: "20px",
              }}
              onClick={() => handelopen(params.row.id)}
            >
              Assign To Advisor
            </Button>
          
            <EditIconcomp  onClick={() => handleEditOpen(params.row)} tooltipTitle="Edit"/>
            
       
          </div>
        );
      },
    },

    {
      field: "name",
      headerName: "Name",
      width: 150,
      renderCell: (params) => (
        <Typography>
          {params.row?.first_name} {params.row?.last_name}
        </Typography>
      ),
    },

    {
      field: "mobile",
      headerName: "Mobile",
      width: 150,
      renderCell: (params) => (
        <Typography>
          {params.row?.mobile_country_code}
          {params.row?.mobile}
        </Typography>
      ),
    },

    {
      field: "enquired_service",
      headerName: "Enquired Service",
      width: 150,
      renderCell: (params) => (
        <Typography>{params.row.service_id?.service_name}</Typography>
      ),
    },

    {
      field: "sub_service_id",
      headerName: "Sub Service",
      width: 150,
      renderCell: (params) => (
        <Typography>{params.row.sub_service_id?.name}</Typography>
      ),
    },
    {
      field: "branch_id",
      headerName: "STM Nearest Branch",
      width: 200,
      renderCell: (params) => (
        <Typography>{params.row.nearest_branch_id?.branch_name} </Typography>
      ),
    },
    {
      field: "permanent_assignee",
      headerName: "Permanent Assignee",
      width: 150,
      renderCell: (params) => (
        <Typography>{params.row.assign_to?.first_name} </Typography>
      ),
    },

    {
      field: "email_id",
      headerName: "Email ID",
      width: 220,
      renderCell: (params) => <Typography>{params.row?.email} </Typography>,
    },

    {
      field: "lead_source_type_id",
      headerName: "Lead Source",
      width: 150,
      renderCell: (params) => (
        <Typography>{params.row.lead_source_type_id?.name} </Typography>
      ),
    },
    {
      field: "sub_lead_source_type_name",
      headerName: "Sub Lead Source",
      width: 150,
      renderCell: (params) => (
        <Typography>
          {params.row?.sub_lead_source_type_id?.source_type}{" "}
        </Typography>
      ),
    },

    {
      field: "campaign_id",
      headerName: "Campaign",
      width: 150,
      renderCell: (params) => (
        <Typography>{params.row?.campaign_id?.campaign_name} </Typography>
      ),
    },

    {
      field: "first_country",
      headerName: "First Country Of Interest",
      width: 150,
      renderCell: (params) => (
        <Typography>{params.row.target_country_id?.country_name} </Typography>
      ),
    },

    {
      field: "created_time",
      headerName: "Created Time",
      width: 150,
      renderCell: (params) => (
        <Typography>{fDateTime(params.row?.createdAt)} </Typography>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4} lg={3}>
          <HeadingCard label="Missed Auto Assign Leads" />
        </Grid>

        <Grid item xs={12} lg={9} textAlign="end">
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{
              borderRadius: "8px",
              marginLeft: "10px",
           
            }}
            onClick={handleAssignToAdvisor}
          >
            Assign To Advisor
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <Box
          style={{
            minHeight: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : registerdata.length === 0 ? (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">No data available</Typography>
        </Grid>
      ) : (
        <Box mt={2}>
          <MainCard>
            <div style={{ width: "100%", maxWidth: "100%" }}>
              <DataGrid
                rows={registerdata}
                columns={columns}
                autoHeight
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                  },
                }}
                sx={{ marginTop: "20px" }}
                pageSizeOptions={[25, 50, 100]}
              />
            </div>
          </MainCard>
        </Box>
      )}

      {/* Assign to Advisor */}
      {selectedid.length === 0 ? (
        <CustomPopup
          open={openpopup}
          onClose={handelclose}
          component={<NoenquiryForm onClose={handelclose} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openpopup}
          onClose={handelclose}
          component={
            <AssignAdvisorToMissedLeads
              selectedid={selectedid}
              onClose={handelclose}
            />
          }
          title="Assign Enquiry To JCA"
          maxWidth="md"
          showDivider={true}
        />
      )}

      {/*Edit*/}
      <CustomPopup
              open={openEditPopup}
              onClose={handleEditClose}
              component={
                <EditMissedLeadsForm
                  onClose={handleEditClose}
                  selectedRowData={selectedData}
                />
              }
              title="Edit Missed Auto Assign Lead"
              maxWidth="sm"
              showDivider={true}
            />


      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
