import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import CustomPopup from "../../../components/CustomPopup";

import BranchRevenueTargetBreakDown from "./BranchRevenueTargetBreakDown";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import LoaderComp from "../../../newTestPrep/newComponent/LoaderComp/Loader";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function BranchRevenueTarget({
  targetData,
  chosenDate,
  branchId,
}) {
  const userId = localStorage.getItem("userId");
  const getTokenOrLogout = useGetTokenOrLogout();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [breakDownData, setBreakDownData] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [state, setState] = React.useState({
    series: [0],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5,
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#444",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          gradientToColors: ["#01BE85"],
          stops: [0, 50, 53, 91],
        },
      },
      colors: ["#01BE85"],
      labels: ["Average Results"],
    },
  });

  useEffect(() => {
    const percentage = Math.round(
      ((targetData?.achieved_revenue || 0) /
        (targetData?.target_revenue || 1)) *
        100
    );

    setState((prevState) => ({
      ...prevState,
      series: [percentage],
    }));
  }, [targetData]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Product wise revenue target breakdown
  const getProductWiseRevenueTarget = async () => {
    setLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),

      branch_id: parseInt(branchId),
      date: chosenDate,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route/branch-revenue-target-achievement-with-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setBreakDownData(result);
        setLoading(false);
        handleOpen();
      } else {
        console.log("Error");
        showSnackbar("Server Error", "error");
        setLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      showSnackbar("Server Error", "error");
      setLoading(false);
    }
  };

  return (
    <>
      <LoaderComp isLoading={loading} />
      {targetData?.target_revenue === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography fontWeight={600}>No Revenue Target Available</Typography>
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="radialBar"
              height={280}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} container spacing={1} mt={1}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Typography variant="h6" sx={{ color: "#4B5563" }}>
                  Achieved Revenue Target{" "}
                </Typography>
                <Typography variant="h6" sx={{ color: "#4B5563" }}>
                  {" "}
                  {targetData?.achieved_revenue}/-
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Typography variant="h6" sx={{ color: "#4B5563" }}>
                  Total Revenue Target{" "}
                </Typography>
                <Typography variant="h6" sx={{ color: "#4B5563" }}>
                  {" "}
                  {targetData?.target_revenue}/-
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                size="small"
                color="secondary"
                variant="text"
                onClick={getProductWiseRevenueTarget}
              >
                {" "}
                View Breakdown
              </Button>
            </Grid>{" "}
          </Grid>
        </Grid>
      )}

      <CustomPopup
        open={open}
        onClose={handleClose}
        component={<BranchRevenueTargetBreakDown data={breakDownData} chosenDate={chosenDate}/>}
        title="Product Wise Revenue Target Breakdown"
        maxWidth="md"
        showDivider={true}
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
