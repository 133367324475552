import { Button } from '@mui/material'
import React from 'react'

export default function MassEnquiryTransferButton({onClick, size }) {
  return (
   <>
     <Button
        variant="contained"
        size={size?size:"medium"}
        style={{ background: "#2196f3", borderRadius:'8px' }}
        onClick={onClick}
      >
       Mass Enquiry Transfer
      </Button>
   </>
  )
}
